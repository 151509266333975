import {
  InterviewAnswer,
  InterviewEntry,
} from "@/components/examinee/interview/Types";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError } from "axios";
import { useCallback } from "react";

/** useDailyInterview getEntry 型宣言 */
type GetDailyInterviewEntry = (
  surveyInfoUid: string,
  announceId: number
) => Promise<InterviewEntry>;

/** useDailyInterview postAnswer 型宣言 */
type PostDailyInterviewAnswer = (
  surveyInfoUid: string,
  announceId: number,
  answer: InterviewAnswer
) => Promise<InterviewEntry>;

/**
 * DailyInterviewの最初の質問を取得するクロージャーを返すカスタムフック。カスタムフックで実装している理由は、useAxios(カスタムフック)を利用するため。
 */
export function useDailyInterview() {
  const axios = useAxios();

  /**
   * 毎日問診のメタ情報(InterviewEntry)の取得
   * InterviewEntryは、毎日問診の質問を得るために必要。質問を得るにあたって、InterviewEntryに含まれるInterviewのIDやScreenのIDが必要。
   * @param surveyInfoUid サーベイ情報のUUID
   * @returns 毎日問診のメタ情報
   */
  const getEntry: GetDailyInterviewEntry = useCallback(
    async (surveyInfoUid, announceId) => {
      try {
        const response = await axios.get(getApiPath(surveyInfoUid, announceId));
        return response.data;
      } catch (e) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  /**
   * 回答を送信
   * @param surveyInfoUid サーベイ情報のUUID
   * @param announceId 毎日問診メール送信履歴のID
   * @param answer 回答
   * @returns 事前問診のメタ情報(次の質問画面のID)
   */
  const postAnswer: PostDailyInterviewAnswer = useCallback(
    async (
      surveyInfoUid: string,
      announceId: number,
      answer: InterviewAnswer
    ) => {
      try {
        const response = await axios.post(
          getApiPath(surveyInfoUid, announceId),
          answer
        );
        return response.data;
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  return { getEntry, postAnswer };
}

/**
 * 毎日問診のメタ情報取得API/回答APIのパスを返す
 * @param surveyInfoUid サーベイ情報のUID
 * @returns メタ情報取得API/回答APIのパス
 */
function getApiPath(surveyInfoUid: string, announceId: number): string {
  return `/api/lite/${surveyInfoUid}/daily-interview/${announceId}/`;
}
