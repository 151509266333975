import { RemindInfo } from "@/components/examinee/daily_interview_entrance/DailyInterviewInfo";
import { paths } from "@/components/examinee/interview/Path";
import {
  DailyInterviewRemind,
  SleepCheckupInfo,
} from "@/components/examinee/sleep_checkup/Types";

type CompletionType = "Standard" | "NavigateNext" | "NavigateElearning";

export interface CompletionInfo {
  readonly firstViewInfo: FirstViewInfo | null;
  readonly messageInfo: MessageInfo;
  readonly buttonInfo: ButtonInfo | null;
}

class CompletionInfoBasic implements CompletionInfo {
  readonly firstViewInfo: FirstViewInfo | null;
  readonly messageInfo: MessageInfo;
  readonly buttonInfo: ButtonInfo | null;

  constructor(
    firstViewInfo: FirstViewInfo,
    messageInfo: MessageInfo,
    buttonInfo: ButtonInfo | null
  ) {
    this.firstViewInfo = firstViewInfo;
    this.messageInfo = messageInfo;
    this.buttonInfo = buttonInfo;
  }
}

class CompletionInfoRemind implements CompletionInfo {
  private readonly remind: RemindInfo;
  readonly buttonInfo: ButtonInfo | null;

  get firstViewInfo(): FirstViewInfo | null {
    return null;
  }

  get messageInfo(): MessageInfo {
    return {
      main: "その調子！",
      sub: `${this.remind.date}分の問診は以上です\n続けて本日の問診にご回答ください`,
    };
  }

  constructor(remind: RemindInfo, buttonInfo: ButtonInfo | null) {
    this.remind = remind;
    this.buttonInfo = buttonInfo;
  }
}

export type FirstViewInfo = {
  image: string;
  alt: string;
};

export type MessageInfo = {
  main: string;
  sub: string;
};

export type ButtonInfo = {
  title: string;
  to: string;
};

function getCompletionType(
  reminds: DailyInterviewRemind[],
  answeredRemind: DailyInterviewRemind,
  referenceRemind: DailyInterviewRemind
): CompletionType {
  if (reminds[reminds.length - 1].id === answeredRemind.id) {
    // 最終日の問診を完了
    return "NavigateElearning";
  } else if (referenceRemind.id === answeredRemind.id) {
    // 本日の問診を完了
    return "Standard";
  } else {
    // 前日の問診を完了
    return "NavigateNext";
  }
}

function getTargetRemind(
  reminds: DailyInterviewRemind[],
  targetDate: Date
): DailyInterviewRemind {
  const index = reminds.findIndex((r) => {
    const remindDate = new Date(r.remind_datetime);
    return targetDate <= remindDate;
  });

  if (index === 0) {
    return reminds[0];
  }

  if (index === -1) {
    return reminds[reminds.length - 1];
  }

  return reminds[index - 1];
}

export function getCompletionInfo(
  remindId: number,
  sleepCheckupInfo: SleepCheckupInfo,
  referenceDate: Date
): CompletionInfo {
  const reminds = sleepCheckupInfo.daily_interview_reminds;
  const index = reminds.findIndex((r) => r.id === remindId);
  if (index === -1) {
    throw new Error("remind is not found");
  }

  // 本日のリマインドを取得
  const referenceRemind = getTargetRemind(reminds, referenceDate);

  const type: CompletionType = getCompletionType(
    reminds,
    reminds[index],
    referenceRemind
  );

  switch (type) {
    case "Standard":
      return new CompletionInfoBasic(
        {
          image: "image_interview_completion_01.png",
          alt: "おつかれさまでした！",
        },
        {
          main: "おつかれさまでした！",
          sub: "ご回答ありがとうございました\n本日の問診は以上です",
        },
        null
      );
    case "NavigateNext":
      return new CompletionInfoRemind(new RemindInfo(reminds[index], index), {
        title: " 回答をはじめる",
        to: paths.getFullPath("DailyInterviewEntrance", {
          ":sleepCheckupId": sleepCheckupInfo.id,
          ":remindId": String(referenceRemind.id),
        }),
      });
    case "NavigateElearning":
      return new CompletionInfoBasic(
        {
          image: "image_interview_completion_01.png",
          alt: "おつかれさまでした！",
        },
        {
          main: "おつかれさまでした！",
          sub: `以上で${reminds.length}日間の測定が完了しました\nデバイスは実施機関へ返却をお願いします`,
        },
        {
          title: "Eラーニングを始める",
          to: `/elearning/quiz_start?id=${sleepCheckupInfo.id}`,
        }
      );
  }
}
