import { ExternalLinkProps } from "@/components/examinee/ExternalLink";
import { Footer } from "@/components/examinee/Footer";
import { LITE_GOOGLE_FORM_LINK } from "@/sleep_compass_lite/constants";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import { SxProps, Theme } from "@mui/material";

type TargetFooterProps = {
  /** フッターに適用するスタイル */
  sx?: SxProps<Theme>;
};
/**
 * 対象者用フッターコンポーネント
 */
export function TargetFooter({ sx }: TargetFooterProps) {
  const FOOTER_LINKS: ExternalLinkProps[] = [
    {
      children: "利用規約",
      href: authenticationPath.getFullPath("TermsOfService"),
      target: "_blank",
    },
    {
      children: "お問合せ",
      href: LITE_GOOGLE_FORM_LINK,
      target: "_blank",
    },
  ];

  return <Footer sx={sx} links={FOOTER_LINKS} />;
}
