import { ScrollToTop } from "@/components/common/ScrollToTop";
import { TermsOfService as Terms } from "@/components/examinee/TermsOfService";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

interface TermsOfServiceAgreementParam {
  hideAgreeButton?: boolean;
}
export function TermsOfServiceAgreement({
  hideAgreeButton,
}: TermsOfServiceAgreementParam) {
  const location = useLocation();
  const nextPath = (location.state as string) || "/examinee/signin2";

  return (
    <>
      <ScrollToTop />
      <Stack spacing={10} sx={{ mt: 8, mb: hideAgreeButton ? 16 : 10, mx: 4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            スリープコンパス会員向け利用規約
          </Typography>
          <Terms />
        </Stack>
        {!hideAgreeButton && (
          <Stack position="sticky" bottom={0} sx={{ textAlign: "center" }}>
            <Stack
              sx={{
                pt: 6,
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);",
              }}
            >
              <Button
                component={Link}
                to={nextPath}
                variant="contained"
                size="large"
              >
                同意する
              </Button>
            </Stack>
            <Box sx={{ background: "white", pt: 2, pb: 6 }}>
              <Typography variant="caption">
                ※第9条等に個人情報の第三者提供に関する規定を含みます。
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </>
  );
}
