import { CustomUser } from "@/components/sleep_checkup_v1/MedicalFacilityUser";
import CONSTANTS from "@/utils/constants";

/**
 * CustomUserクラスのプロパティ
 */
export type CustomUserFields =
  | "phone"
  | "username"
  | "email"
  | "last_name"
  | "first_name"
  | "groups";

/**
 * CustomUserクラスのプロパティリスト
 */
export const CUSTOM_USER_FIELDS: CustomUserFields[] = [
  "phone",
  "username",
  "email",
  "last_name",
  "first_name",
  "groups",
];

/**
 * グループ（権限）とユーザー種別がセットされたCustomUserを返す
 * @returns CustomUser
 */
export function create(): CustomUser {
  const user = new CustomUser();
  // Note: グループ（権限）の初期値にメンバーをセット
  user.groups = [2];
  user.user_type = CONSTANTS.USERTYPE_MEDICAL_FACILITY_USER;
  return user;
}

/**
 * 引数で指定されたCustomUserがコピーされた、新しいCustomUserを返す
 * @param user コピー元CustomUser
 * @returns コピー先CustomUser
 */
export function clone(user: CustomUser): CustomUser {
  const orgUser = user as any;
  const newUser = new CustomUser() as any;

  for (const key of Object.keys(newUser)) {
    if (key === "groups") {
      newUser.groups = [...orgUser.groups];
    } else {
      newUser[key] = orgUser[key];
    }
  }

  return newUser as CustomUser;
}

/**
 * CustomUserに値をセットし、新しいCustomUserを返す
 * @param key 対処パラメータ
 * @param value 対象パラメータにセットする値
 * @param user 対象のCustomUser
 * @returns 引数userがコピー（ディープコピー）された新しいCustomUserオブジェクト
 */
export function set(
  key: CustomUserFields,
  value: any,
  user: CustomUser
): CustomUser {
  const newUser = clone(user) as any;
  newUser[key] = value;
  return newUser as CustomUser;
}

/**
 * CustomUserのlast_nameとfirst_nameを連結した文字列を返す
 * @param user 対象のCustomUser
 * @returns last_nameとfirst_nameを連結した文字列
 */
export function fullName(user: CustomUser): string {
  return `${user.last_name} ${user.first_name}`;
}

/**
 * 施設ユーザー用の権限ラベル
 */
export const PRIVILEGE_LABELS: Record<number, string> = {
  1: "管理者",
  2: "メンバー",
  3: "レポート確認用",
};

/**
 * CustomUserの権限を表す文字列返す
 * @param user 対象のCustomUser
 * @returns CustomUserの権限を表す文字列
 */
export function privilege(user: CustomUser): string {
  // Note: 表示文言をIDから決める。一方、実装方法としては、グループのリストをBackendから取得して、IDからグループ名を割り出して、グループ名から表示文言を決める、方法も考えられる。
  // グループ名は表示文言ではない（e.g. facility_admin）ので、グループ名から表示文言を決めることのメリットを判断しきれなかった。そのため、簡単に実装できるIDから決める方法を採用した。
  return PRIVILEGE_LABELS[getPrivilege(user)];
}

/**
 * CustomUserの権限を数値(値)で返す
 * @param user 対象のCustomUser
 * @returns CustomUserの権限
 */
export function getPrivilege(user: CustomUser): number {
  if (user.groups == null) {
    return 2;
  }

  return Math.min(...user.groups);
}
