import { KeyboardArrowRight } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";

/**
 * {@link RecommendForMedicalExamination} のプロパティ
 */
export interface RecommendForMedicalExaminationProps {
  /**
   * クリックされた際に呼び出されるリスナ
   * @returns
   */
  onClick?: () => void;
}

/**
 * 受診勧奨エリアを表示します。
 *
 * @returns
 */
export default function RecommendForMedicalExamination(
  props: RecommendForMedicalExaminationProps
) {
  return (
    <Stack
      sx={{
        borderRadius: "8px",
        padding: "24px 16px",
        gap: "24px",
        background: 'url("/img/image_top_recommend.png") 50% / cover no-repeat',
        backgroundBlendMode: "luminosity, normal",
        boxShadow: "0px 0px 0px 1px #E0E0E0",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          color: "#FFFFFF",
          textAlign: "justify",
          fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
        }}
      >
        医療機関の受診について
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: "#FFFFFF",
          textAlign: "justify",
          fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
        }}
      >
        今回の測定の結果、疾患リスクがあると判定された項目があります。詳細は疾患リスクレポートをご確認いただき、必要に応じて医療機関の受診をご検討ください。
      </Typography>
      <Typography
        variant="caption"
        sx={{
          color: "rgba(255, 255, 255, 0.70)",
          textAlign: "justify",
          fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
        }}
      >
        ※ 受診の際は、リンク先のページを印刷し、医療機関にご提出ください。
      </Typography>
      <Box>
        <Button
          size="small"
          sx={{
            px: "16px",
            borderRadius: "20px",
            color: "#017453",
            backgroundColor: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#FFFFFF",
              boxShadow: "none",
            },
            "&:active": {
              backgroundColor: "#FFFFFF",
              boxShadow: "none",
            },
          }}
          endIcon={<KeyboardArrowRight />}
          onClick={() => {
            props.onClick?.();
          }}
        >
          疾患リスクレポートを見る
        </Button>
      </Box>
    </Stack>
  );
}
