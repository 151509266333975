import { NavigateNext } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { LifestyleAndHealthConditionResponse, LifestyleAndHealthConditionType } from "./types";
import { LIFESTYLE_AND_HEALTH_CONDITION_CONFIG } from "./constants";

export function LifestyleAndHealthCondition(props: {
  data: LifestyleAndHealthConditionResponse;
  type: LifestyleAndHealthConditionType;
  userType: "examinee" | "facility" | "accelstars";
  reportUid: string;
  article?: {
    title: string;
    link: string;
  };
}) {
  const breadcrumbs = [
    <Link
      key="1"
      href={`/${props.userType}/report/${props.reportUid}`}
      style={{ textDecoration: "none" }}
    >
      <Typography variant="body2">レポートTOP</Typography>
    </Link>,
    <Link
      key="2"
      href={`/${props.userType}/report/${props.reportUid}/lifestyle_and_health_conditions`}
      style={{ textDecoration: "none" }}
    >
      <Typography variant="body2">生活習慣と健康状態</Typography>
    </Link>,
    <Typography key="3" variant="body2">
      {LIFESTYLE_AND_HEALTH_CONDITION_CONFIG[props.type].title}
    </Typography>,
  ];

  return (
    <Stack spacing="40px">
      <Stack justifyContent="center">
        <img
          src={LIFESTYLE_AND_HEALTH_CONDITION_CONFIG[props.type].image}
          alt="レポート"
          loading="lazy"
          style={{
            width: "100%",
          }}
        />
      </Stack>
      <Container>
        <Stack mx={2}>
          <Breadcrumbs
            maxItems={2}
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
          <Typography variant="h5" color="primary.main" mt="16px" mb="32px">
            {LIFESTYLE_AND_HEALTH_CONDITION_CONFIG[props.type].title}
          </Typography>
          <Stack direction="row" alignItems="center" mb="16px">
            <Box
              sx={{
                height: "20px",
                width: "4px",
                backgroundColor: "primary.main",
                borderRadius: "2px",
                marginRight: "6px",
              }}
            />
            <Typography variant="h6">分析コメント</Typography>
          </Stack>
          <Typography mb="40px">{props.data.comment}</Typography>
          <Stack direction="row" alignItems="center" mb="16px">
            <Box
              sx={{
                height: "20px",
                width: "4px",
                backgroundColor: "primary.main",
                borderRadius: "2px",
                marginRight: "6px",
              }}
            />
            <Typography variant="h6">あなたの傾向</Typography>
          </Stack>
          <Stack spacing="40px" mb="80px">
            {props.data.stats.map((stats, index) => {
              return (
                <Stack key={index} spacing="8px">
                  <Typography variant="h6" color="text.secondary" fontSize="16px">
                    {stats.question_text}
                  </Typography>
                  {stats.answers.every(
                    (answer) => answer.count !== undefined
                  ) ? (
                    <Stack
                      border="1px solid"
                      borderRadius="12px"
                      borderColor="black.12"
                      sx={{
                        background:
                          "linear-gradient(to right, #F6F5F4 0%, #F6F5F4 50%, transparent 50%, transparent 100%)",
                      }}
                    >
                      {stats.answers.map((answer, index) => {
                        return (
                          <Stack key={index} direction="row">
                            <Stack width="50%">
                              <Typography px="16px" py="12px" fontSize="14px">
                                {answer.text}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing="2px"
                              alignItems="center"
                              width="calc(50% - 8px)"
                              mr="8px"
                              my="12px"
                            >
                              <Stack width="100%" direction="row" alignItems="center">
                                <Box
                                  sx={{
                                    // NOTE: answer.countがundefinedになる場合は除外している為、割り当てアサーションを使用
                                    // NOTE: answer.countの最大値は一週間の日数の7となる
                                    width: `${(answer.count! / 7) * 100}%`,
                                    height: "16px",
                                    marginRight: "8px",
                                    backgroundColor: "primary.main",
                                  }}
                                />
                                <Typography
                                  textAlign="center"
                                  whiteSpace="nowrap"
                                  fontSize="14px"
                                >
                                  {answer.count}回
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Stack>
                  ) : (
                    <Stack key={index} spacing={4}>
                      {stats.answers
                        .map((answer) => ({
                          attrs: [
                            {
                              borderColor: "#ffe7e6",
                              bgcolor: "#ffe7e6",
                              color: "error.main",
                              tends: "傾向あり",
                            },
                            {
                              borderColor: "#ebf2f8",
                              bgcolor: "#ebf2f8",
                              color: "#0056a0",
                              tends: "傾向小",
                            },
                          ][answer.text === "注意してください" ? 0 : 1],
                          ...answer,
                        }))
                        .map((answer, index) => (
                          <Stack
                            key={index}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            border="1px solid"
                            borderRadius="30px"
                            borderColor={answer.attrs.borderColor}
                            bgcolor={answer.attrs.bgcolor}
                          >
                            <Typography
                              variant="h6"
                              fontFamily="Noto Sans JP"
                              px="28px"
                              py="16px"
                              color={answer.attrs.color}
                            >
                              {answer.attrs.tends}
                            </Typography>
                            <Typography
                              variant="body1"
                              fontFamily="Noto Sans JP"
                              px="28px"
                              py="16px"
                              color={answer.attrs.color}
                            >
                              {answer.text}
                            </Typography>
                          </Stack>
                        ))}
                    </Stack>
                  )}
                </Stack>
              );
            })}
          </Stack>
          {/* TODO: アイコンのsrcの追加が必要等、category.articleの型が要変更になる可能性ありうる。eラーニングのページ完成待ち **/}
          {props.article && (
            <>
              <Divider />
              <Typography
                variant="h6"
                color="text.secondary"
                mt="30px"
                mb="40px"
              >
                あわせて読みたい
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                spacing="4px"
                mb="60px"
                justifyContent="space-between"
                onClick={() => {
                  /* TODO: 動画が存在するページへ遷移する **/
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <Stack direction="row">
                  <img
                    src="/img/icon_movie.svg"
                    alt="movie"
                    loading="lazy"
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <Typography>{props.article.title}</Typography>
                </Stack>
                <Stack>
                  <img
                    src="/img/icon_right_arrow.svg"
                    alt="right_arrow"
                    loading="lazy"
                    style={{
                      margin: "auto",
                      width: "12px",
                      height: "12px",
                    }}
                  />
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
