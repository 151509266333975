import {
  createLabelContainedAnswer,
  createLabelContainedValue,
} from "@/components/examinee/interview/AnswerService";
import {
  NumberSelectItem,
  getNumberSelectItems,
  hasNumberChoice,
} from "@/components/examinee/interview/NumberSelectChoiceService";
import {
  AnswerChangeHandler,
  Choice,
  Question,
  QuestionAnswer,
} from "@/components/examinee/interview/Types";
import { Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import React from "react";

type NumberSelectChoiceProps = {
  question: Question;
  noSelect: string;
  questionAnswer: QuestionAnswer;
  onChange: AnswerChangeHandler;
};

export function NumberSelectChoice({
  question,
  noSelect,
  questionAnswer,
  onChange,
}: NumberSelectChoiceProps) {
  // 数値選択では、選択肢がひとつしかないタイプ（selection_type が 1/1）のみ対応。また、ラジオボタンやチェックボックスとの併用も非対応。
  const choice = question.choices[0];
  const answerText = getAnswerText(questionAnswer, choice, noSelect);

  const onSelectChange: NumberSelectChangeHandler = (
    itemIndex: number,
    value: string
  ) => {
    const ans = createLabelContainedAnswer(
      question,
      choice,
      itemIndex,
      value,
      answerText
    );

    onChange(ans);
  };

  // 「寝床に入った」「寝床から出た」のようなブロックに分ける。ブロックのセパレーターは2つ続きの改行
  const blockItems = choice.choice_label.split("\n\n");
  const answerItems = answerText.split("\n\n");

  // 数値選択フォームをカウントする
  let counter = 0;

  return (
    <Stack spacing={6}>
      {blockItems.map((block, i) => {
        const answerLines = answerItems[i].split("\n");
        return (
          <Stack key={i} spacing={1}>
            {/* ブロックを１行づつコンポーネントに置き換える */}
            {block.split("\n").map((line, j) => {
              if (hasNumberChoice(line)) {
                const numberSelectItems = getNumberSelectItems(
                  line,
                  noSelect,
                  answerLines[j],
                  counter
                );

                counter += numberSelectItems.length;

                return (
                  <NumberSelectComponent
                    key={j}
                    items={numberSelectItems}
                    onChange={onSelectChange}
                  />
                );
              } else {
                return (
                  <Typography key={j} variant="subtitle2">
                    {line}
                  </Typography>
                );
              }
            })}
          </Stack>
        );
      })}
    </Stack>
  );
}

export type NumberSelectChangeHandler = (index: number, value: string) => void;

type NumberSelectComponentProps = {
  items: NumberSelectItem[];
  onChange: NumberSelectChangeHandler;
};

export function NumberSelectComponent({
  items,
  onChange,
}: NumberSelectComponentProps) {
  return (
    <Stack direction="row" alignItems="baseline" spacing={1}>
      {items.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <Typography variant="subtitle2">{item.leftMessage}</Typography>
            <Select
              native
              value={item.optionValues[item.selectedValueIndex]}
              size="small"
              sx={{
                "& .MuiNativeSelect-select": {
                  pl: "12px !important",
                  pr: "12px !important",
                },
                borderRadius: 2,
                backgroundColor: "white",
              }}
              IconComponent={() => null}
              onChange={(event: SelectChangeEvent) =>
                onChange(item.index, event.target.value)
              }
            >
              {item.optionValues.map((v) => (
                <option value={v} key={v}>
                  {v}
                </option>
              ))}
            </Select>
            <Typography variant="subtitle2">{item.rightMessage}</Typography>
          </React.Fragment>
        );
      })}
    </Stack>
  );
}

function getAnswerText(
  questionAnswer: QuestionAnswer,
  choice: Choice,
  noSelect: string
): string {
  return (
    questionAnswer.answers[0]?.answer ??
    createLabelContainedValue(choice.choice_label, noSelect)
  );
}
