import { Stack, Typography } from "@mui/material";
import { DiseaseRiskTag } from "../disease_risk/commons";

/**
 * {@link DiseaseRiskRow} のプロパティ
 */
export interface DiseaseRiskRowProps {
  /** 疾患リスク名 */
  title: string;
  /** 疾患リスク内容 */
  risk: boolean | null;
}

/**
 * 疾患リスクの行
 * @param param0 {@link DiseaseRiskRowProps}
 * @returns
 */
export function DiseaseRiskRow({ title, risk }: DiseaseRiskRowProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ border: "1px solid #e0e0e0", borderRadius: "8px", p: "16px" }}
    >
      <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
        {title}
      </Typography>
      <Stack direction="row" alignItems="center" spacing="8px">
        <DiseaseRiskTag risk={risk} />
      </Stack>
    </Stack>
  );
}
