import { SleepCharacteristicsDetail } from "@/components/report/SleepCharacteristicsDetail";
import {
  ReportPageBaseProps,
  SleepCharacteristicsDetailResponse,
} from "@/components/report/types";
import { useAxios } from "@/utils/axios";
import { usePageUserType } from "@/utils/router";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function SleepCharacteristicsDetailPage({
  setIndicator,
}: ReportPageBaseProps) {
  const { uuid } = useParams();
  const userType = usePageUserType();
  const axios = useAxios();
  const [data, setData] = useState<SleepCharacteristicsDetailResponse | null>(
    null
  );
  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `/api/report/${uuid}/sleep_characteristics_detail`
      );
      setData(response.data);
      setIndicator(false);
    })();
  }, [axios, uuid, setIndicator]);

  if (uuid == null || data == null) return <></>;

  return (
    <SleepCharacteristicsDetail
      data={data}
      userType={userType}
      reportUid={uuid}
    />
  );
}
