import { useAxios } from "@/utils/axios";
import { useMemo } from "react";
import {
  FactAndComment,
  FactAndCommentUpdateData,
  FactAndCommentUpdateResult,
} from "./commons/fact_and_comments";
import { Plan, PlanUpdateData, PlanUpdateResult } from "./commons/plans";
import {
  Retrospective,
  RetrospectiveUpdateData,
  RetrospectiveUpdateResult,
} from "./commons/retrospectives";
import { SleepSummary } from "./commons/sleep_summaries";
import { UserInfo } from "./commons/user_infos";

/**
 * 睡眠評価
 * @returns 睡眠評価 API
 */
export function useSleepSummary() {
  const axios = useAxios();
  return useMemo(
    () => ({
      /**
       * 睡眠評価取得API
       *
       * @see https://www.notion.so/accelstars/API-Interface-9cc9f3444d7a435494fd3e3431048572?pvs=4#f39f3e842acf41d6896892791fcf4160
       * @param uuid sleep_checkup_info_uuid
       * @returns Promise<AxiosResponse<SleepSummary, any>>
       */
      get: (uuid: string) =>
        axios.get<SleepSummary>(`/api/worksheet/sleep_summary/${uuid}`),
    }),
    [axios]
  );
}

/**
 * 原因分析 API
 * @returns 原因分析 API
 */
export function useRetrospective() {
  const axios = useAxios();
  const getURL = (uuid: string) => `/api/worksheet/retrospective/${uuid}`;
  return useMemo(
    () => ({
      /**
       * 原因分析取得API
       *
       * @see https://www.notion.so/accelstars/API-Interface-9cc9f3444d7a435494fd3e3431048572?pvs=4#ab3570c130b44ad0b80ec7ab7afb3824
       * @param uuid sleep_checkup_info_uuid
       * @returns Promise<AxiosResponse<Retrospective, any>>
       */
      get: (uuid: string) => axios.get<Retrospective>(getURL(uuid)),
      /**
       * 原因分析更新API
       *
       * @see https://www.notion.so/accelstars/API-Interface-9cc9f3444d7a435494fd3e3431048572?pvs=4#5ea6f1d309fe4170873ee3dc8af90d37
       * @param uuid sleep_checkup_info_uuid
       * @param data {@link RetrospectiveUpdateData}
       * @returns Promise<AxiosResponse<RetrospectiveUpdateResult, any>>
       */
      post: (uuid: string, data: RetrospectiveUpdateData) =>
        axios.post<RetrospectiveUpdateResult>(getURL(uuid), data),
    }),
    [axios]
  );
}

/**
 * 週間グラフ API
 * @returns 週間グラフ API
 */
export function useFactAndComment() {
  const axios = useAxios();
  const getURL = (uuid: string) => `/api/worksheet/fact_and_comment/${uuid}`;
  return useMemo(
    () => ({
      /**
       * 週間グラフ取得API
       *
       * @see https://www.notion.so/accelstars/API-Interface-9cc9f3444d7a435494fd3e3431048572?pvs=4#568934dfd7a0405196b08bdd12e563ff
       * @param uuid sleep_checkup_info_uuid
       * @returns Promise<AxiosResponse<FactAndComment[], any>>
       */
      get: (uuid: string) => axios.get<FactAndComment[]>(getURL(uuid)),
      /**
       * 週間グラフ更新API
       * @see https://www.notion.so/accelstars/API-Interface-9cc9f3444d7a435494fd3e3431048572?pvs=4#e54d9abbe2d741ad8db1f96dcb9e5d1f
       * @param uuid sleep_checkup_info_uuid
       * @param data {@link FactAndCommentUpdateData}
       * @returns Promise<AxiosResponse<FactAndCommentUpdateResult, any>>
       */
      post: (uuid: string, data: FactAndCommentUpdateData) =>
        axios.post<FactAndCommentUpdateResult>(getURL(uuid), data),
    }),
    [axios]
  );
}

/**
 * 改善目標 API
 * @returns 改善目標 API
 */
export function usePlan() {
  const axios = useAxios();
  const getURL = (uuid: string) => `/api/worksheet/plan/${uuid}`;
  return useMemo(
    () => ({
      /**
       * 改善目標取得API
       *
       * @see https://www.notion.so/accelstars/API-Interface-9cc9f3444d7a435494fd3e3431048572?pvs=4#eac6898e360e40849711b2fd623659fe
       * @param uuid sleep_checkup_info_uuid
       * @returns Promise<AxiosResponse<Plan, any>>
       */
      get: (uuid: string) => axios.get<Plan>(getURL(uuid)),
      /**
       * 改善目標更新API
       * @param uuid sleep_checkup_info_uuid
       * @param data {@link PlanUpdateData}
       * @returns Promise<AxiosResponse<PlanUpdateResult, any>>
       */
      post: (uuid: string, data: PlanUpdateData) =>
        axios.post<PlanUpdateResult>(getURL(uuid), data),
    }),
    [axios]
  );
}

/**
 * 受診者情報 API
 * @returns 受診者情報 API
 */
export function useUserInfo() {
  const axios = useAxios();
  const getURL = (uuid: string) => `/api/worksheet/user_info/${uuid}/`;
  return useMemo(
    () => ({
      /**
       * 受診者情報取得API
       *
       * @see https://www.notion.so/accelstars/API-Interface-9cc9f3444d7a435494fd3e3431048572?pvs=4#52a1799f6ec64589806ece3b2d7892ea
       * @param uuid sleep_checkup_info_uuid
       * @returns Promise<AxiosResponse<UserInfo, any>>
       */
      get: (uuid: string) => axios.get<UserInfo>(getURL(uuid)),
    }),
    [axios]
  );
}
