export function getQuizPagePath(queryString: string) {
  const quizPath = "/elearning/quiz_start";

  const query = new URLSearchParams(queryString);
  const id = query.get("id");
  if (id == null) {
    return quizPath;
  }

  const quizQuery = new URLSearchParams();
  quizQuery.set("id", id);

  return `${quizPath}?${quizQuery.toString()}`;
}
