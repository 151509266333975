import elearningConstants from "@/components/elearning/common/constants";
import TeacherImage from "@/components/elearning/quiz/TeacherImage";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useElearningSearchParams } from "../ElearningLayout";

function QuizStart() {
  const elearningQuery = useElearningSearchParams();

  const BUTTON_HEIGHT = 42;

  return (
    <Stack>
      <Stack
        alignItems="center"
        sx={{
          pt: 6,
          backgroundColor: elearningConstants.IMAGE_BACKGROUND,
        }}
      >
        <TeacherImage width="260px" />
      </Stack>
      <Box sx={{ mt: 8, mx: 4, mb: 10 }}>
        <Stack spacing={12} sx={{ mb: 6 }}>
          <Stack spacing={6}>
            <Typography variant="h5" color="primary.main">
              睡眠クイズ
            </Typography>
            <Stack spacing={4}>
              <Typography variant="body2">
                睡眠健康測定おつかれさまでした。
                <br />
                これから快適な睡眠で健康な毎日を送るための秘訣を簡単に説明します！
              </Typography>
              <Typography variant="body2">
                まずは、皆さんの睡眠に関する知識を確認するための簡単なクイズに回答してください。
              </Typography>
            </Stack>
          </Stack>
          <Button
            component={Link}
            to={`/elearning/quiz?${elearningQuery}`}
            sx={{ height: BUTTON_HEIGHT, borderRadius: BUTTON_HEIGHT / 2 }}
            variant="contained"
          >
            クイズスタート
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
export default QuizStart;
