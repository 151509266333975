import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function BathHelpDialog() {
  return (
    <HelpButton dialogTitle="入浴">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "睡眠への影響",
            content:
              "眠気は深部体温が低下するときに出現しやすいため、就寝前に入浴によって体温を一時的に上げてから下げることで眠気が出て入眠しやすくなります。",
          },
          {
            title: "おすすめ",
            content:
              "シャワーではなく湯船につかることが深部体温を上げることにつながります。熱いお湯の場合は深部体温があがりすぎてしまうため、寝る直前ではなく就寝の2～3時間前までに入るようにしましょう。",
          },
        ]}
      />
    </HelpButton>
  );
}
