import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
/**
 * エラーからメッセージを取得する
 *
 * @param err エラー
 * @param defaultMessage {@link ApiError} かつ、{@link ApiError#isClientError 400系エラー}かつ
 * {@link ApiError#isClientError エラーメッセージ}が設定されていない場合、このメッセージを返却します。
 * デフォルト値は *申し訳ありませんが、少し時間をおいてから再度お試しください。* です。
 * @returns エラーメッセージ
 */
export function getErrorMessage(
  err: unknown,
  defaultMessage: string = "申し訳ありませんが、少し時間をおいてから再度お試しください。"
): string {
  if (err instanceof ApiError) {
    return err.isClientError && err.message ? err.message : defaultMessage;
  } else {
    return defaultMessage;
  }
}

export function getEmailErrorMessages(err: unknown): string[] {
  if (err instanceof ApiError && err.response?.data?.email) {
    return err.response.data.email;
  }
  return [];
}

export function getPasswordErrorMessages(err: unknown): string[] {
  if (err instanceof ApiError && err.response?.data?.password) {
    return err.response.data.password;
  }
  return [];
}
