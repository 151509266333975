import { Chapter01 } from "@/pages/elearning/knowledge_and_tips/Chapter01";
import { Chapter02 } from "@/pages/elearning/knowledge_and_tips/Chapter02";
import { Chapter03 } from "@/pages/elearning/knowledge_and_tips/Chapter03";
import { Chapter04 } from "@/pages/elearning/knowledge_and_tips/Chapter04";
import { Chapter05 } from "@/pages/elearning/knowledge_and_tips/Chapter05";
import Index from "@/pages/elearning/knowledge_and_tips/Index";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";

export default function ElearningContentsLayout() {
  return (
    <Box>
      <Routes>
        <Route path="/knowledge_and_tips">
          <Route path="" element={<Index />} />
          <Route path="chapter01" element={<Chapter01 />} />
          <Route path="chapter02" element={<Chapter02 />} />
          <Route path="chapter03" element={<Chapter03 />} />
          <Route path="chapter04" element={<Chapter04 />} />
          <Route path="chapter05" element={<Chapter05 />} />
        </Route>
      </Routes>
    </Box>
  );
}
