import { ScrollToTop } from "@/components/common/ScrollToTop";
import { paths } from "@/components/examinee/interview/Path";
import { Button, Stack, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  sleepCheckupUuid: string;
};

export const RegistrationCompleted = () => {
  const location = useLocation();

  const { sleepCheckupUuid } = (location.state as StateType) || {};

  return (
    <>
      <ScrollToTop />
      <Stack spacing={8} sx={{ mt: 8, mb: 12, mx: 4 }}>
        <Stack spacing={6} alignItems="center">
          <img
            src="/img/image_registration_completed_01.png"
            alt="登録完了"
            loading="lazy"
            style={{ width: 260, height: "100%" }}
          />
          <Typography variant="h6">登録完了</Typography>
        </Stack>
        <Stack>
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ color: "text.secondary" }}
          >
            以上でアカウント登録は完了です
          </Typography>
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ color: "text.secondary" }}
          >
            続いて「事前問診」にご回答ください
          </Typography>
        </Stack>

        <Button
          component={Link}
          to={paths.getFullPath("PrimaryInterviewIntroduction", {
            ":sleepCheckupId": sleepCheckupUuid,
          })}
          variant="contained"
          size="large"
        >
          事前問診に回答する
        </Button>
      </Stack>
    </>
  );
};
