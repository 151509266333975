/**
 * Sleep Compassロゴ
 */

export type imageColorType = "normal" | "white";
type SleepCompassLogoProps = {
  width: number;
  imageColor?: imageColorType;
};
const IMAGES: Readonly<Record<imageColorType, string>> = {
  normal: "/img/logo_sleep_compass.png",
  white: "/img/logo_sleep_compass_white.svg",
};
export function SleepCompassLogo({
  width,
  imageColor = "normal",
}: SleepCompassLogoProps) {
  return (
    <img
      src={IMAGES[imageColor]}
      alt="SLEEP COMPASS"
      loading="lazy"
      style={{ width: width, height: "auto" }}
    />
  );
}
