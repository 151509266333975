import {
  getNewCheckboxAnswer,
  updateFreeTextAnswer,
} from "@/components/examinee/interview/AnswerService";
import {
  getFreeTextAnswer,
  isChecked,
} from "@/components/examinee/interview/CheckboxChoiceService";
import { CHOICE_TYPE_LABEL_WITH_FREE_TEXT } from "@/components/examinee/interview/Constants";
import {
  AnswerChangeHandler,
  Choice,
  Question,
  QuestionAnswer,
} from "@/components/examinee/interview/Types";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const BlankCheckIcon = styled("span")(({ theme }) => ({
  width: 20,
  height: 20,
  backgroundImage: `url("/img/icon_checkbox_blank.png")`,
  backgroundSize: "20px 20px",
}));

const FilledCheckIcon = styled(BlankCheckIcon)({
  backgroundImage: `url("/img/icon_checkbox_filled.png")`,
});

type CheckboxChoiceProps = {
  question: Question;
  questionAnswer: QuestionAnswer;
  onChange: AnswerChangeHandler;
};

export function CheckboxChoice({
  question,
  questionAnswer,
  onChange,
}: CheckboxChoiceProps) {
  const answers = [...questionAnswer.answers];
  const freeTextAnswer = getFreeTextAnswer(question.choices, answers);
  const [text, setText] = useState<string>(freeTextAnswer?.answer ?? "");

  // チェックボックスタイプの選択肢が変更されたとき
  const onCheckboxChange = (choice: Choice) => {
    const ans = getNewCheckboxAnswer(question, questionAnswer, choice, text);
    onChange(ans);
  };

  // フリーテキストが変更されたときの処理
  const onTextChange = (
    question: Question,
    choice: Choice,
    newText: string
  ) => {
    setText(newText);

    const ans = updateFreeTextAnswer(question, questionAnswer, choice, newText);
    if (ans === null) {
      return;
    }

    onChange(ans);
  };

  return (
    <FormGroup>
      {question.choices.map((choice: Choice) => {
        return (
          <React.Fragment key={choice.id}>
            <FormControlLabel
              sx={{ mt: 1 }}
              control={<SCCheckbox onChange={() => onCheckboxChange(choice)} />}
              label={
                <Typography variant="body1">{choice.choice_label}</Typography>
              }
              checked={isChecked(choice, answers)}
            />
            {choice.choice_type === CHOICE_TYPE_LABEL_WITH_FREE_TEXT && (
              <TextField
                sx={{ backgroundColor: "white" }}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => onTextChange(question, choice, event.target.value)}
                disabled={!(freeTextAnswer !== undefined)}
                placeholder="詳細をご記入ください"
                defaultValue={text}
              />
            )}
          </React.Fragment>
        );
      })}
    </FormGroup>
  );
}

type SCCheckboxProps = {
  onChange: CheckboxProps["onChange"];
};
function SCCheckbox({ onChange }: SCCheckboxProps) {
  return (
    <Checkbox
      checkedIcon={<FilledCheckIcon />}
      icon={<BlankCheckIcon />}
      onChange={onChange}
    />
  );
}
