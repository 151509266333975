import {
  EmphasisTypography,
  SubtitleTypography,
} from "@/components/common/Typographies";
import { ImageBox } from "@/components/elearning/chapter/Boxes";
import { Layout } from "@/components/elearning/chapter/Layout";
import { SubChapterStack } from "@/components/elearning/chapter/Stacks";
import { Typography } from "@mui/material";

export function Chapter03() {
  return (
    <Layout title="セロトニンとメラトニン">
      <SubChapterStack>
        <SubtitleTypography>セロトニンとは</SubtitleTypography>
        <Typography variant="body2">
          セロトニンは、必須アミノ酸トリプトファンから生合成される脳内の神経伝達物質のひとつ。他の神経伝達物質であるドパミン（喜び、快楽など）やノルアドレナリン（恐怖、驚きなど）などをコントロールし、精神を安定させ頭の回転をよくします。ストレスに対して効能が高く、体のリズムを整えるために重要な脳内物質です。
        </Typography>
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>
          セロトニンを増やすトリプトファンを含む食品
        </SubtitleTypography>
        <Typography variant="body2">
          セロトニンは脳内で必須アミノ酸のトリプトファンから作られます。
          しかし、
          <EmphasisTypography>
            トリプトファンは体内で生成できないため、食事から摂る必要があります。
          </EmphasisTypography>
        </Typography>
        <Typography variant="body2">
          トリプトファンが含まれる食品としては、肉類、牛乳やチーズなどの乳製品、納豆や豆腐などの大豆製品、ナッツ類やバナナがあります。また、ビタミンB6、マグネシウム、ナイアシンを含む食品もセロトニン生成に関係しています。
        </Typography>
        <ImageBox
          src="/img/image_chapter3_1.png"
          alt="セロトニンを増やすトリプトファンを含む食品"
        />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>天然の入眠剤「メラトニン」</SubtitleTypography>
        <Typography variant="body2">
          脳から分泌される天然の入眠剤であるメラトニンは、夜間セロトニンから作られます。
        </Typography>
        <Typography variant="body2">
          覚醒と睡眠を切り替え眠りを誘うため「睡眠ホルモン」とも呼ばれます。
        </Typography>
        <Typography variant="body2">
          メラトニンは眠りを誘うほかに、抗酸化作用により細胞の新陳代謝を促進し、疲労回復、病気の予防、老化防止などの効果を持つと考えられています。
        </Typography>
        <Typography variant="body2">
          メラトニンは起床から14～15時間後に分泌されますが、夜遅くまで明るい環境にいると光によってメラトニンが出にくくなリ、睡眠が得られなくなります。
          寝る1時間前は、パソコン・携帯電話・スマホ等の使用を減らし、明るい場所への外出は控えましょう。ブルーライト（白色・青色LED照明）を控え、暖色照明・間接照明等で部屋の明かりを半分ほどに抑えましょう。
        </Typography>
        <Typography variant="body2">
          日中に、日光を浴びてセロトニンを作り、メラトニンが分泌されやすくなるようにしましょう。
        </Typography>
        <ImageBox
          src="/img/image_chapter3_2.png"
          alt="天然の入眠剤「メラトニン」"
        />
      </SubChapterStack>
    </Layout>
  );
}
