import { ScrollToTop } from "@/components/common/ScrollToTop";
import { Interview } from "@/components/examinee/interview/Interview";
import { postDailyInterviewAnswer } from "@/components/examinee/interview/InterviewAPI";
import { DailyInterviewPageSummary } from "@/components/examinee/interview/PageSummary";
import { paths } from "@/components/examinee/interview/Path";
import {
  InterviewAnswer,
  InterviewEntry,
} from "@/components/examinee/interview/Types";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import { Params } from "react-router-dom";

const PAGE_TYPE = "DailyInterview";

export function DailyInterview({ headerHeight }: ExamineePageProps) {
  return (
    <>
      <ScrollToTop />
      <Interview
        pageProps={{ headerHeight: headerHeight }}
        context={{
          pageSummaryCreator: (questionScreen) =>
            new DailyInterviewPageSummary(questionScreen),
          postAnswer: postAnswer,
          getNextScreen: getNextScreen,
          getNextPage: getNextPage,
        }}
      />
    </>
  );
}

function postAnswer(
  params: Readonly<Params<string>>,
  interviewAnswer: InterviewAnswer,
  currentPath: string
) {
  if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
    throw new Error("sleepCheckupId is not assigned");
  }
  if (params.remindId == null || params.remindId === undefined) {
    throw new Error("remindId is not assigned");
  }

  return postDailyInterviewAnswer(
    params.sleepCheckupId,
    params.remindId,
    interviewAnswer,
    currentPath
  );
}

function getNextScreen(
  entry: InterviewEntry,
  params: Readonly<Params<string>>
) {
  if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
    throw new Error("sleepCheckupId is not assigned");
  }
  if (params.interviewId == null || params.interviewId === undefined) {
    throw new Error("interviewId is not assigned");
  }
  if (params.remindId == null || params.remindId === undefined) {
    throw new Error("remindId is not assigned");
  }

  return paths.getFullPath(PAGE_TYPE, {
    ":sleepCheckupId": params.sleepCheckupId,
    ":remindId": params.remindId,
    ":interviewId": params.interviewId,
    ":screen": String(entry.screen.id),
  });
}

function getNextPage(params: Readonly<Params<string>>) {
  if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
    throw new Error("sleepCheckupId is not assigned");
  }
  if (params.remindId == null || params.remindId === undefined) {
    throw new Error("remindId is not assigned");
  }

  return paths.getFullPath("DailyInterviewCompletion", {
    ":sleepCheckupId": params.sleepCheckupId,
    ":remindId": params.remindId,
  });
}
