import { DeleteOutlined } from "@mui/icons-material/";
import { Button, ButtonProps, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

/**
 * 削除ボタン
 */
type DeleteButtonProps = {
  children?: ReactNode;
  sx?: SxProps<Theme>;
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
  disabled?: ButtonProps["disabled"];
  onClick: ButtonProps["onClick"];
};
export function DeleteButton({
  children,
  sx,
  size,
  variant,
  disabled,
  onClick,
}: DeleteButtonProps) {
  return (
    <Button
      sx={sx}
      variant={variant || "contained"}
      startIcon={<DeleteOutlined />}
      size={size || "small"}
      onClick={onClick}
      disabled={disabled}
    >
      {children || "削除"}
    </Button>
  );
}
