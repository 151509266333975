import { Tooltip, Typography, TypographyProps } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export function TooltipTypography(props: TypographyProps) {
  const [truncated, setTruncated] = useState<boolean>(false);

  const ref = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (ref?.current == null) {
      return;
    }

    setTruncated(ref.current.scrollWidth > ref.current.clientWidth);
  }, [ref]);

  const typography = (
    <Typography ref={ref} {...props}>
      {props.children}
    </Typography>
  );

  if (truncated) {
    return (
      <Tooltip title={props.children as string} placement="right-start">
        {typography}
      </Tooltip>
    );
  } else {
    return typography;
  }
}
