import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { BackdropIndicator } from "./common/BackdropIndicator";

const ScreenLockContext = createContext<
  [boolean, Dispatch<SetStateAction<boolean>>] | null
>(null);

/**
 * ScreenLock コンポーネントで共通の画面ロックを操作する hook です。
 * このhookを使用するためには、ScreenLockProvider コンポーネントで囲む必要があります。
 *
 * @returns  [boolean, Dispatch<SetStateAction<boolean>>]
 */
export function useScreenLock() {
  const context = useContext(ScreenLockContext);
  if (context == null) {
    throw new Error("This hook must use ScreenLockProvider component");
  }
  return context;
}

type ScreenLockProps = {
  /** 子要素 */
  children: ReactNode;
};

/**
 * 画面のロックを提供するための Context Provider です。
 * {@link useScreenLock} を使用するためには、このコンポーネントで囲むようにしてください。
 *
 * @param param {@link ScreenLockProps}
 * @returns ReactNode
 */
export function ScreenLockProvider({ children }: ScreenLockProps) {
  const [isLocked, setLock] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // location の変更があった場合、画面遷移が起こったと判断して Lock を解除する。
    setLock(false);
  }, [location]);
  return (
    <>
      <BackdropIndicator open={isLocked} />
      <ScreenLockContext.Provider value={[isLocked, setLock]}>
        {children}
      </ScreenLockContext.Provider>
    </>
  );
}
