import {
  FormControl,
  FormControlProps,
  InputLabel,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { ReactNode } from "react";

// Note: onChangeプロパティにも、SelectProps["onChange"] 型を使いたいが、使うと、valueとdefaultValueの型に関するコンパイルエラーが起きるので、使わない。
type SCSelectChangeHandler = (
  event: SelectChangeEvent,
  child: ReactNode
) => void;

/**
 * ドロップダウンメニュー。MUIのSelectを簡潔に書くためのコンポーネントで、FormControl,InputLabel,Selectがまとまっている。
 */
type SCSelectProps = {
  children: ReactNode;
  sx?: SxProps<Theme>;
  variant: FormControlProps["variant"];
  required?: FormControlProps["required"];
  disabled?: FormControlProps["disabled"];
  label: string;
  multiple?: SelectProps["multiple"];
  defaultValue?: SelectProps["defaultValue"];
  value?: SelectProps["value"];
  onChange?: SCSelectChangeHandler;
  id?: SelectProps["id"];
};
export function SCSelect({
  children,
  sx,
  variant,
  required,
  disabled,
  label,
  multiple,
  defaultValue,
  value,
  onChange,
  id,
}: SCSelectProps) {
  const selectChangeHandler: SelectProps["onChange"] = (event, child) => {
    if (onChange == null) {
      return;
    }
    onChange(event as SelectChangeEvent, child);
  };

  const requiredValue = required ?? false;
  const disabledValue = disabled ?? false;

  return (
    <FormControl
      sx={sx}
      variant={variant}
      required={requiredValue}
      disabled={disabledValue}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        defaultValue={defaultValue}
        multiple={multiple}
        value={value}
        onChange={selectChangeHandler}
        id={id}
      >
        {children}
      </Select>
    </FormControl>
  );
}
