import {
  getLeftMessage,
  getRightMessage,
} from "@/components/examinee/interview/ChoiceLabelService";
import {
  LABEL_CONTAINED_VALUE_REGEX,
  RETRIEVE_VALUE_REGEX,
} from "@/components/examinee/interview/Constants";

export class FreeTextItem {
  leftMessage: string | null;
  rightMessage: string | null;
  value: string;
  index: number;

  get hasMessage(): boolean {
    return this.leftMessage || this.rightMessage ? true : false;
  }

  constructor(
    leftMessage: string | null,
    rightMessage: string | null,
    value: string,
    index: number
  ) {
    this.leftMessage = leftMessage;
    this.rightMessage = rightMessage;
    this.value = value;
    this.index = index;
  }
}

export function getFreeTextItems(
  label: string,
  answerText: string,
  startIndex: number
): FreeTextItem[] {
  const selectors: RegExpMatchArray[] = [
    ...label.matchAll(RETRIEVE_VALUE_REGEX),
  ];

  const answerSelectors: RegExpMatchArray[] = [
    ...answerText.matchAll(RETRIEVE_VALUE_REGEX),
  ];

  return selectors.map((s, i, selectors) => {
    return new FreeTextItem(
      getLeftMessage(label, s, i, selectors),
      getRightMessage(label, s, i, selectors),
      getValue(answerSelectors[i]),
      startIndex + i
    );
  });
}

function getValue(selector: RegExpMatchArray): string {
  return selector[0].slice(1, -1);
}

export function hasFreeText(label: string): boolean {
  return label.match(LABEL_CONTAINED_VALUE_REGEX) != null;
}
