import { SCAppBar } from "@/components/examinee/SCAppBar";
import { LiteLogo } from "@/sleep_compass_lite/components/target/LiteLogo";
import { ReactNode } from "react";

type TargetAppBarProps = {
  /** ヘッダーの高さ */
  height: number;
  /** 中央に表示される内容 */
  logo?: ReactNode;
  /** 戻るボタンが押下された際のハンドラー */
  onClickBack?(): void;
};
/**
 * 対象者用ヘッダーコンポーネント
 */
export function TargetAppBar({ height, logo, onClickBack }: TargetAppBarProps) {
  return (
    <SCAppBar
      height={height}
      logo={logo ?? <LiteLogo width={236} />}
      onClickBack={onClickBack}
    />
  );
}
