import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { AssessmentStars } from "@/sleep_compass_lite/components/target/AssessmentStars";
import {
  DetailAssessmentRadarChart,
  DetailAssessmentRadarChartItem,
  DetailAssessmentRadarChartProps,
} from "@/sleep_compass_lite/components/target/DetailAssessmentRadarChart";
import { ReportContentTitle } from "@/sleep_compass_lite/components/target/ReportContentTitle";
import { PX6 } from "@/sleep_compass_lite/components/target/StackStyles";
import { StickyStack } from "@/sleep_compass_lite/components/target/StickyStack";
import { LiteReportHeaderProps } from "@/sleep_compass_lite/components/target/report/LiteReportMenuContext";
import {
  PARAM_KEY_OPEN_DESCRIBE_DIALOG,
  reportPath,
} from "@/sleep_compass_lite/domain_models/target/ReportPath";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { useSurveyInfo } from "@/sleep_compass_lite/use_cases/target/interview/UseSurveyInfo";
import { useComprehensiveEvaluation } from "@/sleep_compass_lite/use_cases/target/report/UseComprehensiveEvaluation";
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

/**
 * Lite レポートのトップページ
 */
export function Top({ setLiteReportHeaderContext }: LiteReportHeaderProps) {
  useEffect(() => {
    // メニューバーの制御（ロゴ、戻るボタンなし）
    setLiteReportHeaderContext(null);
  }, [setLiteReportHeaderContext]);

  const location = useLocation();
  const { surveyInfoUid } = useParams();

  const [dialogOpen, setDialogOpen] = useState<boolean>(
    getDescribeDialogOpenFlag(new URLSearchParams(location.search))
  );
  const [isNextButtonFloating, setIsNextButtonFloating] = useState<
    boolean | null
  >(null);

  const [surveyInfo, surveyInfoError] = useSurveyInfo();
  const [evaluation, evaluationError] = useComprehensiveEvaluation();

  if (surveyInfoUid == null) {
    return (
      <NonFieldError>
        URLが間違っている可能性があります。ご確認ください。
      </NonFieldError>
    );
  }
  if (surveyInfoError != null) {
    return <NonFieldError>{getErrorMessage(surveyInfoError)}</NonFieldError>;
  }
  if (evaluationError != null) {
    return <NonFieldError>{getErrorMessage(evaluationError)}</NonFieldError>;
  }

  // 項目別評価に表示するデータ
  // 睡眠の状態
  const sleepConditionAssessmentItems: AssessmentTableRowProps[] = [
    {
      rowTitle: "睡眠の長さ",
      starsCount:
        evaluation?.detailAssessments.sleepCondition.quantityStarsCount ?? 0,
    },
    {
      rowTitle: "睡眠の質",
      starsCount:
        evaluation?.detailAssessments.sleepCondition.qualityStarsCount ?? 0,
    },
    {
      rowTitle: "睡眠のリズム",
      starsCount:
        evaluation?.detailAssessments.sleepCondition.rhythmStarsCount ?? 0,
    },
  ];
  // 日中の状態
  const daytimeConditionAssessmentItems: AssessmentTableRowProps[] = [
    {
      rowTitle: "日中の眠気",
      starsCount:
        evaluation?.detailAssessments.daytimeCondition.sleepinessStarsCount ??
        0,
    },
    {
      rowTitle: "日中の疲労感",
      starsCount:
        evaluation?.detailAssessments.daytimeCondition.tirednessStarsCount ?? 0,
    },
  ];
  // レーダーチャートに表示するデータ
  // 項目別評価とレーダーチャートで並び順が違うので注意。レーダーチャートの項目は、中心の真上の頂点から右回りの順で表示される。
  const radarChartTitles: string[] = [
    "睡眠の質",
    "睡眠のリズム",
    "日中の疲労感",
    "日中の眠気",
    "睡眠の長さ",
  ];
  const radarChartItems: DetailAssessmentRadarChartItem[] = [
    ...sleepConditionAssessmentItems,
    ...daytimeConditionAssessmentItems,
  ]
    .sort(
      (p, c) =>
        radarChartTitles.indexOf(p.rowTitle) -
        radarChartTitles.indexOf(c.rowTitle)
    )
    .map((item) => {
      return {
        chartItemTitle: item.rowTitle,
        chartItemValue: item.starsCount,
      };
    });

  const nextButtonSX: ButtonProps["sx"] = { my: 6 };
  if (isNextButtonFloating === false) {
    // ボタンの影は、フローティングしていないときは非表示(フローティングしているときは表示)
    nextButtonSX["boxShadow"] = "none";
  }

  return (
    <Stack sx={{ pb: 14 }}>
      <ScrollToTop />
      <MainImage
        username={
          surveyInfo != null
            ? `${surveyInfo.target.userName.fullName()}さん`
            : ""
        }
        title="睡眠評価"
      />
      <Stack spacing={10} sx={{ px: PX6, pt: 6, pb: 10 }}>
        <Stack spacing={10}>
          <ReportPageStepper />
          <Stack spacing={20}>
            <TotalGrade
              title="総合評価"
              caption="睡眠評価"
              result={evaluation?.totalAssessment.result ?? ""}
              buttonTitle="評価の仕組み"
              onClick={() => setDialogOpen(true)}
            />
            <DetailAssessment
              title="項目別評価"
              sleepConditionTitle="睡眠の状態"
              sleepConditionAssessmentText={
                evaluation?.detailAssessments.sleepCondition.result ?? ""
              }
              sleepConditionAssessmentItems={sleepConditionAssessmentItems}
              daytimeConditionTitle="日中の状態"
              daytimeConditionAssessmentText={
                evaluation?.detailAssessments.daytimeCondition.result ?? ""
              }
              daytimeConditionAssessmentItems={daytimeConditionAssessmentItems}
              radarChartTitle="評価の分布"
              radarChartItems={radarChartItems}
            />
            <TotalAssessment
              title="総合コメント"
              summary={evaluation?.totalAssessment.summary ?? ""}
              detail={evaluation?.totalAssessment.detail ?? ""}
              note="※本レポートで提供される情報や結果は、病気の診断や治療を意図するものではありません。レポートの結果に関わらず、気になることがある場合には、かかりつけ医・睡眠の専門医に相談することをお勧めします"
            />
          </Stack>
        </Stack>
      </Stack>
      <StickyStack
        sx={{
          px: PX6,
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);",
        }}
        onChangeFloatingState={(isFloating) =>
          setIsNextButtonFloating(isFloating)
        }
      >
        <Button
          variant="contained"
          sx={nextButtonSX}
          component={Link}
          to={reportPath.getFullPath("SleepCondition", {
            ":surveyInfoUid": surveyInfoUid,
          })}
        >
          次へ
        </Button>
      </StickyStack>
      <SleepEvaluationDescribeDialog
        open={dialogOpen}
        dialogTitle="睡眠評価の仕組み"
        mainMessage="睡眠評価では、あなたの睡眠を「睡眠」と「日中」の2つの状態から分析します。"
        mainImage={<SleepEvaluationStructureImage />}
        detailMessage="「睡眠の状態」では、睡眠の長さ、質、リズムの3つの指標を評価し、「日中の状態」では前日の睡眠の結果、翌日の日中に眠気と疲労、2つの指標が見られたかを評価しています。"
        buttonTitle="OK"
        onClose={() => setDialogOpen(false)}
      />
    </Stack>
  );
}

type MainImageProps = {
  /** レポートの対象者の名前 */
  username: string;
  /** 画像に重ねて表示するタイトル文言 */
  title: string;
};
/**
 * レポートトップのメイン画像コンポーネント
 */
function MainImage({ username, title }: MainImageProps) {
  const FONT_WEIGHT = 700;

  return (
    <Stack spacing={10} alignItems="center" sx={{ p: 10 }}>
      <Stack>
        <Typography
          variant="h5"
          fontWeight={FONT_WEIGHT}
        >{`${username}の`}</Typography>
        <Typography variant="h3" fontWeight={FONT_WEIGHT} color="primary">
          {title}
        </Typography>
      </Stack>
      <Box sx={{ px: "7.5px" }}>
        <img
          src="/img/image_report_top_main_visual.png"
          alt="Sleep Compass Light Report"
          loading="lazy"
          style={{ width: "100%", height: "auto", maxWidth: "390px" }}
        />
      </Box>
    </Stack>
  );
}

/**
 * トップページのステッパーコンポーネント
 */
function ReportPageStepper() {
  return (
    <img
      src="/img/image_report_stepper_01.svg"
      alt="Sleep Compass Light Report"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  );
}

type TotalGradeProps = {
  /** 項目のタイトル */
  title: string;
  /** 評価文言に付記するキャプション */
  caption: string;
  /** 評価文言 */
  result: string;
  /** 項目のタイトルに並べて表示するボタンのタイトル */
  buttonTitle: string;
  /** 項目のタイトルに並べて表示するボタンのクリックハンドラー */
  onClick: ButtonProps["onClick"];
};
/**
 * 総合評価の項目を表示するコンポーネント
 */
function TotalGrade({
  title,
  caption,
  result,
  buttonTitle,
  onClick,
}: TotalGradeProps) {
  return (
    <Stack spacing={4}>
      <ReportContentTitle buttonTitle={buttonTitle} onClick={onClick}>
        {title}
      </ReportContentTitle>
      <Stack
        alignItems="center"
        spacing={2}
        sx={{
          py: 6,
          border: 2,
          borderColor: "primary.main",
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="subtitle1"
          color="text.secondary"
          textAlign="center"
        >
          {caption}
        </Typography>
        <Typography variant="h5" color="primary" textAlign="center">
          {result}
        </Typography>
      </Stack>
    </Stack>
  );
}

type DetailAssessmentProps = DetailAssessmentRadarChartProps & {
  /** 項目のタイトル */
  title: string;
  /** 睡眠の状態テーブルのヘッダのタイトル */
  sleepConditionTitle: string;
  /** 睡眠の状態の評価文言 */
  sleepConditionAssessmentText: string;
  /** 睡眠の状態テーブルに表示するアイテム */
  sleepConditionAssessmentItems: AssessmentTableRowProps[];
  /** 日中の状態テーブルのヘッダのタイトル */
  daytimeConditionTitle: string;
  /** 日中の状態の評価文言 */
  daytimeConditionAssessmentText: string;
  /** 日中の状態テーブルに表示するアイテム */
  daytimeConditionAssessmentItems: AssessmentTableRowProps[];
};
/**
 * 項目別評価の項目を表示するコンポーネント
 */
function DetailAssessment({
  title,
  sleepConditionTitle,
  sleepConditionAssessmentText,
  sleepConditionAssessmentItems,
  daytimeConditionTitle,
  daytimeConditionAssessmentText,
  daytimeConditionAssessmentItems,
  radarChartTitle,
  radarChartItems,
}: DetailAssessmentProps) {
  return (
    <Stack spacing={4}>
      <ReportContentTitle>{title}</ReportContentTitle>
      <AssessmentTable
        headTitle={sleepConditionTitle}
        assessmentText={sleepConditionAssessmentText}
        assessmentItems={sleepConditionAssessmentItems}
      />
      <AssessmentTable
        headTitle={daytimeConditionTitle}
        assessmentText={daytimeConditionAssessmentText}
        assessmentItems={daytimeConditionAssessmentItems}
      />
      <DetailAssessmentRadarChart
        radarChartTitle={radarChartTitle}
        radarChartItems={radarChartItems}
      />
    </Stack>
  );
}

type AssessmentTableProps = AssessmentTableHeadProps & {
  /** テーブルに表示するアイテム */
  assessmentItems: AssessmentTableRowProps[];
};
/**
 * 評価を表示するテーブルコンポーネント
 */
function AssessmentTable({
  headTitle,
  assessmentText,
  assessmentItems,
}: AssessmentTableProps) {
  return (
    <Paper sx={{ boxShadow: "0px 0px 0px 1px #E0E0E0;" }}>
      <TableContainer>
        <Table sx={{ width: "100%" }}>
          <AssessmentTableHead
            headTitle={headTitle}
            assessmentText={assessmentText}
          />
          <TableBody>
            {assessmentItems.map((assessmentItem) => (
              <AssessmentTableRow
                key={assessmentItem.rowTitle}
                rowTitle={assessmentItem.rowTitle}
                starsCount={assessmentItem.starsCount}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

type AssessmentTableHeadProps = {
  /** ヘッダのタイトル */
  headTitle: string;
  /** 評価文言 */
  assessmentText: string;
};
/**
 * 評価を表示するテーブルのヘッダコンポーネント
 */
function AssessmentTableHead({
  headTitle,
  assessmentText,
}: AssessmentTableHeadProps) {
  return (
    <TableHead
      sx={{
        "&.MuiTableHead-root .MuiTableCell-root": {
          backgroundColor: "#F6F8FB",
        },
      }}
    >
      <TableRow sx={{ th: { p: 3 }, td: { p: 3 } }}>
        <TableCell>
          <Typography variant="subtitle2">{headTitle}</Typography>
        </TableCell>
        <TableCell>
          <AssessmentText assessmentLabel="評価:">
            {assessmentText}
          </AssessmentText>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

type AssessmentTableRowProps = {
  /** テーブル行のタイトル */
  rowTitle: string;
  /** スターの数 */
  starsCount: number;
};
/**
 * 評価を表示するテーブルのrowコンポーネント
 */
function AssessmentTableRow({ rowTitle, starsCount }: AssessmentTableRowProps) {
  return (
    <TableRow
      sx={{
        td: { pl: 6, pr: 3, py: 3 },
        th: { pl: 6, pr: 3, py: 3 },
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        <Typography variant="body2" color="text.primary">
          {rowTitle}
        </Typography>
      </TableCell>
      <TableCell>
        <AssessmentStars
          starsLabel="評価:"
          starsCount={starsCount}
          starSize="small"
        />
      </TableCell>
    </TableRow>
  );
}

type AssessmentTextProps = {
  /** ラベル文言 */
  assessmentLabel: string;
  /** 評価文言 */
  children: ReactNode;
};
/**
 * ラベルと評価文言を並べて表示するコンポーネント
 */
function AssessmentText({ assessmentLabel, children }: AssessmentTextProps) {
  return (
    <Stack direction="row" spacing={2} justifyContent="flex-end">
      <Typography variant="subtitle2">{assessmentLabel}</Typography>
      <Typography variant="subtitle2">{children}</Typography>
    </Stack>
  );
}

type TotalAssessmentProps = {
  /** 項目のタイトル */
  title: string;
  /** 端的な評価コメント */
  summary: string;
  /** 詳細な評価コメント */
  detail: string;
  /** レポートに関する注記 */
  note: string;
};
/**
 * 総合コメントの項目を表示するコンポーネント
 */
function TotalAssessment({
  title,
  summary,
  detail,
  note,
}: TotalAssessmentProps) {
  return (
    <Stack spacing={4}>
      <ReportContentTitle>{title}</ReportContentTitle>
      <Typography variant="h6" color="primary">
        {summary}
      </Typography>
      <Typography variant="body1">{detail}</Typography>
      <Typography variant="caption">{note}</Typography>
    </Stack>
  );
}

type SleepEvaluationDescribeDialogProps = {
  /** 表示制御フラグ */
  open: DialogProps["open"];
  /** タイトル */
  dialogTitle: string;
  /** ダイアログ上部に表示されるメッセージ */
  mainMessage: string;
  /** ダイアログ下部に表示されるメッセージ */
  detailMessage: string;
  /** ダイアログ中央に表示される画像 */
  mainImage: ReactNode;
  /** ダイアログ下部に配置されるボタンのタイトル */
  buttonTitle: string;
  /** ダイアログ下部のボタンのクリックハンドラー */
  onClose: () => void;
};
/**
 * 「睡眠評価の仕組み」ダイアログ
 */
function SleepEvaluationDescribeDialog({
  open,
  dialogTitle,
  mainMessage,
  detailMessage,
  mainImage,
  buttonTitle,
  onClose,
}: SleepEvaluationDescribeDialogProps) {
  return (
    <Dialog open={open} maxWidth="xs" onClose={onClose}>
      <DialogTitle sx={{ p: 4 }}>{dialogTitle}</DialogTitle>
      <DialogContent sx={{ px: 4, pt: 0, pb: 6, mt: 0 }}>
        <Stack spacing={4}>
          <Typography variant="body2">{mainMessage}</Typography>
          {mainImage}
          <Typography variant="body2">{detailMessage}</Typography>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 0 }}>
        <Button
          sx={{ p: 4, lineHeight: "30px", fontSize: "13px" }}
          onClick={onClose}
          fullWidth
        >
          {buttonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * 睡眠評価の構造を示す画像コンポーネント
 */
function SleepEvaluationStructureImage() {
  return (
    <img
      src="/img/image_sleep_evaluation_structure.png"
      alt="sleep evaluation structure"
      style={{ width: "100%", height: "auto" }}
    />
  );
}

/**
 * URLパラメータから「睡眠評価の仕組み」ダイアログを表示するか否かを示すフラグを取り出す
 * @param urlParams URLパラメータ {@link URLSearchParams}
 * @returns 「睡眠評価の仕組み」ダイアログを表示するか否かを示す真偽値
 */
function getDescribeDialogOpenFlag(urlParams: URLSearchParams): boolean {
  const value = urlParams.get(PARAM_KEY_OPEN_DESCRIBE_DIALOG);
  if (value == null) {
    return false;
  }
  return value === "true";
}
