import { ReportImg } from "./ReportImg";

/**
 * {@link Stepper} のプロパティ
 */
export interface StepperProps {
  /** 表示ステップ */
  step: number;
  /** 現在のステップ */
  activeStep: number;
}
/**
 * ステップを表示します。
 *
 * @param param0 {@link StepperProps}
 */
export default function Stepper({ step, activeStep }: StepperProps) {
  const images = [];
  for (let i = 0; i < step; i++) {
    images.push(
      <ReportImg
        key={i}
        style={{ width: "8px", height: "8px", textAlign: "center" }}
        image_name={`image_stepper_${
          i === activeStep - 1 ? "" : "in"
        }active.svg`}
      />
    );
  }
  return <>{images}</>;
}
