import {
  CHOICE_TYPE_LABEL,
  CHOICE_TYPE_LABEL_WITH_FREE_TEXT,
  CHOICE_TYPE_LABEL_WITH_NUMBER_CHOICES,
  CHOICE_TYPE_NO_LABEL,
  LABEL_CONTAINED_VALUE_REGEX,
  RETRIEVE_VALUE_REGEX,
  SELECTION_TYPE_1_1,
  SELECTION_TYPE_1_N,
  SELECTION_TYPE_N_N,
} from "@/components/examinee/interview/Constants";
import {
  Answer,
  Choice,
  InterviewAnswer,
  Question,
  QuestionAnswer,
  QuestionGroup,
  QuestionGroupAnswer,
  Screen as QuestionScreen,
} from "@/components/examinee/interview/Types";

export function createInterviewAnswer(
  interviewEntryId: number,
  questionScreen: QuestionScreen
): InterviewAnswer {
  const replies: QuestionAnswer[] = questionScreen.question_group.questions.map(
    (q: Question) => createQuestionAnswer(q, [])
  );

  return {
    id: interviewEntryId,
    screen: {
      id: questionScreen.id,
      question_group: {
        id: questionScreen.question_group.id,
        questions: replies,
      },
    },
  };
}

export function getNewInterviewAnswer(
  orgAns: InterviewAnswer,
  questionAnswer: QuestionAnswer
): InterviewAnswer {
  const newAns: InterviewAnswer = copyInterviewAnswer(orgAns);

  const index: number = newAns.screen.question_group.questions.findIndex(
    (a: QuestionAnswer) => a.question_code === questionAnswer.question_code
  );
  if (index === -1) {
    throw new Error("questionAnswer is not found");
  }

  newAns.screen.question_group.questions[index] = questionAnswer;

  return newAns;
}

export function validate(
  questionGroup: QuestionGroup,
  questionAnswer: QuestionGroupAnswer,
  noSelect: string
): boolean {
  for (const q of questionGroup.questions) {
    const a = questionAnswer.questions.find(
      (a: QuestionAnswer) => a.question_code === q.question_code
    );
    if (a === undefined) {
      return false;
    }

    switch (q.selection_type) {
      case SELECTION_TYPE_1_1:
      case SELECTION_TYPE_1_N:
        if (a.answers.length === 0) {
          // 1つ以上の選択が必要なのに、何も選ばれていない
          return false;
        }

        if (!validateForAnswers(a.answers, q.choices, noSelect)) {
          return false;
        }

        continue;
      case SELECTION_TYPE_N_N:
        if (!validateForCheckboxAnswers(a.answers, q.choices)) {
          return false;
        }

        continue;
      default:
        // 未対応
        return false;
    }
  }

  return true;
}

export function createRadioAnswer(
  question: Question,
  choice: Choice,
  text?: string
): QuestionAnswer {
  const answers: Answer[] = [];

  const ans = createAnswerWithChoice(choice, text);
  if (ans !== null) {
    answers.push(ans);
  }

  return createQuestionAnswer(question, answers);
}

export function getNewCheckboxAnswer(
  question: Question,
  questionAnswer: QuestionAnswer,
  choice: Choice,
  text?: string
): QuestionAnswer {
  const answers: Answer[] = [...questionAnswer.answers];

  const index = answers.findIndex((a: Answer) => a.id === choice.id);
  if (index === -1) {
    // 未選択の選択肢が選択された場合には回答を追加
    const ans = createAnswerWithChoice(choice, text);
    if (ans !== null) {
      answers.push(ans);
    }
  } else {
    // 選択済の選択肢が解除された場合には回答から削除
    answers.splice(index, 1);
  }

  return createQuestionAnswer(question, answers);
}

export function updateFreeTextAnswer(
  question: Question,
  questionAnswer: QuestionAnswer,
  choice: Choice,
  text: string
): QuestionAnswer | null {
  if (choice.choice_type !== CHOICE_TYPE_LABEL_WITH_FREE_TEXT) {
    return null;
  }

  const answers: Answer[] = [...questionAnswer.answers];
  const index = answers.findIndex((a) => a.id === choice.id);
  if (index === -1) {
    return null;
  }

  const newAns = createAnswerForFreeText(choice, text);
  answers[index] = newAns;

  return createQuestionAnswer(question, answers);
}

export function createLabelContainedValue(
  orgText: string,
  noSelect: string
): string {
  return orgText.replace(LABEL_CONTAINED_VALUE_REGEX, `[${noSelect}]`);
}

export function createLabelContainedAnswer(
  question: Question,
  choice: Choice,
  itemIndex: number,
  value: string,
  answerText: string
): QuestionAnswer {
  const newAns = getNewLabelContainedAnswer(
    choice,
    itemIndex,
    value,
    answerText
  );

  return createQuestionAnswer(question, [newAns]);
}

export function getAppendedNumberSelectAnswer(
  question: Question,
  choice: Choice,
  noSelect: string,
  answers: Answer[]
): QuestionAnswer {
  const newAnswer = createNumberSelectAnswerWithChoice(choice, noSelect);

  const ans = [...answers];
  ans.push(newAnswer);

  return createQuestionAnswer(question, ans);
}

export function getRemovedSelectAnswer(
  question: Question,
  index: number,
  answers: Answer[]
): QuestionAnswer {
  const ans = [...answers];
  ans.splice(index, 1);

  return createQuestionAnswer(question, ans);
}

export function createNumberSelectAnswerWithChoice(
  choice: Choice,
  noSelect: string
): Answer {
  const text = createLabelContainedValue(choice.choice_label, noSelect);
  return createAnswerWithText(choice, text);
}

export function createAppendableNumberSelectAnswer(
  question: Question,
  choice: Choice,
  valueIndex: number,
  value: string,
  answerIndex: number,
  answers: Answer[]
): QuestionAnswer {
  const newAns = getNewLabelContainedAnswer(
    choice,
    valueIndex,
    value,
    answers[answerIndex].answer
  );

  const newAnswers = [...answers];
  newAnswers[answerIndex] = newAns;

  return createQuestionAnswer(question, newAnswers);
}

function copyInterviewAnswer(target: InterviewAnswer): InterviewAnswer {
  return JSON.parse(JSON.stringify(target));
}

export function validateForAnswers(
  answers: Answer[],
  choices: Choice[],
  noSelect: string
): boolean {
  if (answers.length === 0) {
    // 1つ以上の選択が必要なのに、何も選ばれていない
    return false;
  }

  for (const a of answers) {
    const c = choices.find((c: Choice) => c.id === a.id);
    if (c === undefined) {
      return false;
    }

    switch (c.choice_type) {
      case CHOICE_TYPE_LABEL:
      case CHOICE_TYPE_NO_LABEL:
        if (a.answer.length === 0) {
          return false;
        }
        continue;
      case CHOICE_TYPE_LABEL_WITH_FREE_TEXT:
        if (!validateForValue(a.answer, "")) {
          return false;
        }
        continue;
      case CHOICE_TYPE_LABEL_WITH_NUMBER_CHOICES:
        if (!validateForValue(a.answer, noSelect)) {
          return false;
        }
        continue;
      default:
        // 未対応
        return false;
    }
  }

  return true;
}

function validateForCheckboxAnswers(
  answers: Answer[],
  choices: Choice[]
): boolean {
  for (const a of answers) {
    const c = choices.find((c: Choice) => c.id === a.id);
    if (c === undefined) {
      return false;
    }

    switch (c.choice_type) {
      case CHOICE_TYPE_LABEL_WITH_FREE_TEXT:
        // フリーテキストタイプの選択肢がチェックされていたら、フリーテキストが未入力か否かを確認
        if (!validateForValue(a.answer, "")) {
          return false;
        }
        continue;
      default:
        // フリーテキストタイプの選択肢以外はチェックする必要がない。何もチェックされていなくても有効な回答。
        continue;
    }
  }

  return true;
}

/**
 * フリーテキストや数値選択タイプの選択肢の回答をvalidateする
 * @param value 回答（入力値/選択値）
 * @param empty 未入力/未選択のときの値
 * @returns 検証結果。入力済み/選択済みであればtrue、未入力/未選択であればfalse。
 *
 * NOTE:
 * フリーテキストが未入力の場合、[]のみの値がanswerに保持される（s.groups.valueが空文字になる）。入力済であれば、[xxx]という形式で、[]で入力値が囲まれる。空白のみの入力の場合は無効とみなす。
 * 数値選択が未選択の場合、[--]がanswerに保持される（s.groups.valueが--になる）。入力済であれば、[4]という形式で、[]で入力値が囲まれる。
 */
function validateForValue(value: string, empty: string): boolean {
  for (const s of value.matchAll(RETRIEVE_VALUE_REGEX)) {
    if (s.groups?.value.trim() === empty) {
      return false;
    }
  }

  return true;
}

function createQuestionAnswer(
  question: Question,
  answers: Answer[]
): QuestionAnswer {
  return {
    id: question.id,
    question_code: question.question_code,
    answers: answers,
  };
}

function createAnswerWithChoice(choice: Choice, text?: string): Answer | null {
  switch (choice.choice_type) {
    case CHOICE_TYPE_LABEL:
    case CHOICE_TYPE_NO_LABEL:
      return createAnswer(choice);
    case CHOICE_TYPE_LABEL_WITH_FREE_TEXT:
      return createAnswerForFreeText(choice, text ?? "");
    case CHOICE_TYPE_LABEL_WITH_NUMBER_CHOICES:
      // 未対応
      return null;
    default:
      return null;
  }
}

function createAnswer(choice: Choice): Answer {
  return {
    id: choice.id,
    answer: choice.choice_label,
  };
}

function createAnswerForFreeText(choice: Choice, text: string): Answer {
  return createAnswerWithText(choice, `${choice.choice_label}[${text}]`);
}

function createAnswerWithText(choice: Choice, text: string): Answer {
  return {
    id: choice.id,
    answer: text,
  };
}

function getNewLabelContainedAnswer(
  choice: Choice,
  itemIndex: number,
  value: string,
  answerText: string
): Answer {
  const newAnswerText = getLabelContainedValue(answerText, itemIndex, value);
  return createAnswerWithText(choice, newAnswerText);
}

function getLabelContainedValue(
  text: string,
  itemIndex: number,
  value: string
): string {
  // Choice.labelに回答を含める形で、テキストを保持する。例えば、選択/入力された値が10のときの保持されるテキストは以下
  // 選択前: []時[]分頃〜[]時[]分頃
  // 選択後: [10]時[]分頃~[]時[]分頃
  const selectors: RegExpMatchArray[] = [
    ...text.matchAll(LABEL_CONTAINED_VALUE_REGEX),
  ];

  const s = selectors[itemIndex];

  if (s.index === undefined) {
    return "";
  }

  return (
    text.slice(0, s.index) + `[${value}]` + text.slice(s.index + s[0].length)
  );
}
