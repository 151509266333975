import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError } from "axios";
import { useMemo } from "react";

/**
 * surveyInfoUid が使用可能か確認するAPIを提供します。
 *
 * @returns SurveyInfoRegisterable API
 */
export function useSurveyInfoRegisterable() {
  const axios = useAxios();
  return useMemo(
    () => ({
      /**
       * 指定した surveyInfoUid が期限切れかどうか確認します。
       *
       * レスポンスコードが以下の場合、期限切れのIDと判断します。
       * * 409 Conflict
       * * 410 Gone
       *
       * @param surveyInfoUid 指定ID
       * @returns 期限切れの場合 true
       */
      async isExpired(surveyInfoUid: string) {
        try {
          await axios.get(
            `/api/lite/survey_info_registerable/${surveyInfoUid}/`
          );
          return false;
        } catch (e) {
          if (e instanceof AxiosError) {
            switch (e.response?.status) {
              case 409: // 409 (Conflict) の場合、登録済みのURL
              case 410: // 410 (Gone) の場合、期限切れのURL
                return true;
              default: // 上記以外はエラー
                throw new ApiError(e.response);
            }
          } else {
            throw e;
          }
        }
      },
    }),
    [axios]
  );
}
