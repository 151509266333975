import Authenticated from "@/components/auth/Authenticated";
import { getEncodedCurrentPath } from "@/components/common/LocationService";
import { NonFieldError } from "@/components/common/SCAlert";
import { MessageDialog } from "@/components/examinee/MessageDialog";
import {
  ErrorContext,
  createErrorContext,
} from "@/components/examinee/interview/InterviewEntryLoader";
import { paths } from "@/components/examinee/interview/Path";
import { getSleepCheckupInfo } from "@/components/examinee/sleep_checkup/SleepCheckupAPI";
import constants from "@/utils/constants";
import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

type SleepCheckupInfoStatus =
  | "profile_not_registered"
  | "profile_registered"
  | "primary_interview_completed"
  | "report_sent";

interface SleepCheckupInfo {
  readonly id: string;
  readonly medical_examinee_last_name: string;
  readonly medical_examinee_first_name: string;
  readonly status: SleepCheckupInfoStatus;
}

export function PrimaryInterviewEntrance() {
  const [info, setInfo] = useState<SleepCheckupInfo | null>(null);
  const [examineeName, setExamineeName] = useState("");
  const [error, setError] = useState<ErrorContext | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (params.sleepCheckupId == null) {
      throw new Error("sleepCheckupId is not assigned");
    }

    getSleepCheckupInfo(params.sleepCheckupId, getEncodedCurrentPath(location))
      .then((res: any) => {
        setInfo(res.data);
        setExamineeName(
          res.data.medical_examinee_last_name +
            " " +
            res.data.medical_examinee_first_name
        );

        if (
          ["primary_interview_completed", "report_sent"].includes(
            res.data.status
          )
        ) {
          setDialogOpen(true);
        }
      })
      .catch((err) => {
        setError(createErrorContext(err));
      });
  }, [params.sleepCheckupId, location]);

  return (
    <Authenticated redirectTo={constants.SIGNIN_URL_EXAMINEE}>
      <Stack spacing={8} sx={{ mx: 4, mt: 8, mb: 14 }} alignItems="center">
        {error != null && <NonFieldError>{error.message}</NonFieldError>}
        <FirstViewImage />
        {info != null && (
          <Stack spacing={6} sx={{ width: "100%" }}>
            <GreetingMessage
              examineeName={examineeName}
              caption="「事前問診」にご回答ください"
            />
            {params.sleepCheckupId != null && (
              <Button
                variant="contained"
                size="large"
                component={Link}
                to={paths.getFullPath("PrimaryInterviewIntroduction", {
                  ":sleepCheckupId": params.sleepCheckupId,
                })}
                disabled={error != null}
              >
                事前問診に回答する
              </Button>
            )}
          </Stack>
        )}
      </Stack>
      <MessageDialog
        open={dialogOpen}
        message={
          "この問診は既に回答済みです。再度回答すると以前の回答は上書きされます。回答の上書きを希望しない場合は、このページを閉じてください。"
        }
        action={{
          title: "OK",
          onClick: () => {
            setDialogOpen(false);
          },
        }}
      />
    </Authenticated>
  );
}

function FirstViewImage() {
  return (
    <img
      src="/img/image_interview_entrance_02.png"
      alt="こんにちは"
      loading="lazy"
      style={{ width: 343, height: "auto" }}
    />
  );
}

type GreetingMessageProps = {
  examineeName: string;
  caption: string;
};
function GreetingMessage({ examineeName, caption }: GreetingMessageProps) {
  return (
    <Stack alignItems="center">
      <Typography variant="h6">こんにちは</Typography>
      <Stack direction="row" spacing={1}>
        <Typography variant="h6" color="primary">
          {examineeName}
        </Typography>
        <Typography variant="h6">さん</Typography>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ mt: 4 }}>
        <Typography
          variant="caption"
          sx={{ fontSize: "14px", fontWeight: 500 }}
        >
          {caption}
        </Typography>
      </Stack>
    </Stack>
  );
}
