import {
  PAGE_TITLE,
  paths,
  RootPages,
} from "@/components/sleep_checkup_v1/Path";
import Face4OutlinedIcon from "@mui/icons-material/Face4Outlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import { Button, ButtonProps, Stack } from "@mui/material";
import { Link } from "react-router-dom";

/**
 * 施設ユーザーヘッダ用のページ切り替えスイッチ
 */
type PageSwitchProps = {
  pages: RootPages[];
  selected: RootPages;
};
export function PageSwitch({ pages, selected }: PageSwitchProps) {
  const ICONS: Record<RootPages, ButtonProps["startIcon"]> = {
    SleepCheckupList: <HotelOutlinedIcon />,
    UserList: <Face4OutlinedIcon />,
  };

  return (
    <Stack direction="row" spacing={4}>
      {pages.map((page, i) => {
        const icon = ICONS[page];
        const color = page === selected ? "primary" : "text.secondary";
        return (
          <Button
            key={i}
            startIcon={icon}
            sx={{ color: color }}
            component={Link}
            to={paths.getFullPath(page)}
          >
            {PAGE_TITLE[page]}
          </Button>
        );
      })}
    </Stack>
  );
}
