import { SleepCompassLogo } from "@/components/sleep_checkup_v1/SleepCompassLogo";
import Shadow from "@/utils/shadow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuBookIcon from "@mui/icons-material/MenuBookOutlined";
import {
  AppBar,
  Button,
  ButtonProps,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuProps,
  Stack,
  Toolbar,
} from "@mui/material";
import { ReactNode, useRef } from "react";

/**
 * 施設ユーザー用のヘッダ
 */
type SCAppBarProps = {
  toolbarItem?: ReactNode;
  accountMenuButton?: ReactNode;
  minWidth: number;
};
export function SCAppBar({
  toolbarItem,
  accountMenuButton,
  minWidth,
}: SCAppBarProps) {
  // 同じスタイルでToolbarを2つ設置するため、スタイルを定義する。最下部のToolbarはAppBar(position="fixed")の下にコンポーネントが隠れないようにするためのスペーサーのため、空で設置。
  const TOOLBAR_STYLE = { mx: 7, height: 68 };

  return (
    <>
      <AppBar
        sx={{
          boxShadow: Shadow.appBar,
          backgroundColor: "common.white",
        }}
      >
        <Toolbar sx={TOOLBAR_STYLE}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", minWidth: minWidth }}
          >
            <SleepCompassLogo width={256} />
            {toolbarItem}
            {accountMenuButton}
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar sx={TOOLBAR_STYLE} />
    </>
  );
}

type AccountMenuButtonProps = {
  children: ReactNode;
  accountMenuList: ReactNode;
  openMenu: MenuProps["open"];
  onClick: ButtonProps["onClick"];
  onClose: MenuProps["onClose"];
};
export function AccountMenuButton({
  children,
  accountMenuList,
  openMenu,
  onClick,
  onClose,
}: AccountMenuButtonProps) {
  const anchor = useRef(null);

  const endIcon = openMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;

  return (
    <>
      <Button
        ref={anchor}
        variant="text"
        endIcon={endIcon}
        onClick={onClick}
        sx={{ color: "text.secondary" }}
      >
        {children}
      </Button>
      <AccountMenu anchorEl={anchor.current} open={openMenu} onClose={onClose}>
        {accountMenuList}
      </AccountMenu>
    </>
  );
}

type AccountMenuProps = {
  children: ReactNode;
  anchorEl: MenuProps["anchorEl"];
  open: MenuProps["open"];
  onClose: MenuProps["onClose"];
};
function AccountMenu({ children, anchorEl, open, onClose }: AccountMenuProps) {
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      open={open}
      onClose={onClose}
    >
      {children}
    </Menu>
  );
}

type AccountMenuListProps = {
  onClickLogout: MenuItemProps["onClick"];
};
export function AccountMenuList({ onClickLogout }: AccountMenuListProps) {
  return (
    <MenuList>
      <AccountMenuItem
        startIcon={<MenuBookIcon />}
        onClick={() =>
          window
            .open(
              "https://drive.google.com/drive/folders/1BZlazIgLrKH5qm54DINYRzt1VgeWY9w1",
              "_blank"
            )
            ?.focus()
        }
      >
        操作マニュアル
      </AccountMenuItem>
      <AccountMenuItem
        startIcon={<HelpOutlineOutlinedIcon />}
        onClick={() =>
          window
            .open(
              "https://docs.google.com/forms/d/e/1FAIpQLSfFP5qmDil0qpYu7Fjsy7Iw9-GrH7e-2vOLykBw5tRhzcGSnQ/viewform",
              "_blank"
            )
            ?.focus()
        }
      >
        お問い合わせ
      </AccountMenuItem>
      <Divider />
      <AccountMenuItem startIcon={<LogoutIcon />} onClick={onClickLogout}>
        ログアウト
      </AccountMenuItem>
    </MenuList>
  );
}

type AccountMenuItemProps = {
  children: ReactNode;
  startIcon: ReactNode;
  onClick: MenuItemProps["onClick"];
};
export function AccountMenuItem({
  children,
  startIcon,
  onClick,
}: AccountMenuItemProps) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon sx={{ color: "text.primary" }}>{startIcon}</ListItemIcon>
      <ListItemText sx={{ color: "text.primary" }}>{children}</ListItemText>
    </MenuItem>
  );
}
