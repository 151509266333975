import { Check } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { ReactNode } from "react";

/**
 * 変更を保存ボタン
 */
type SaveButtonProps = {
  children?: ReactNode;
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  disabled?: ButtonProps["disabled"];
  onClick: ButtonProps["onClick"];
};
export function SaveButton({
  children,
  variant,
  size,
  disabled,
  onClick,
}: SaveButtonProps) {
  return (
    <Button
      startIcon={<Check />}
      variant={variant || "contained"}
      size={size || "small"}
      disabled={disabled}
      onClick={onClick}
    >
      {children || "変更を保存"}
    </Button>
  );
}
