import { Box, BoxProps } from "@mui/material";

/**
 * 登録完了ページ/問診接続ページのファーストビューコンポーネント
 */
type RegistrationCompletedImageProps = {
  sx: BoxProps["sx"];
};
export function RegistrationCompletedImage({
  sx,
}: RegistrationCompletedImageProps) {
  return (
    <Box sx={sx}>
      <img
        src="/img/image_registration_completed_01.png"
        alt="registration completed"
        loading="lazy"
        style={{ width: "100%", height: "auto", maxWidth: "390px" }}
      />
    </Box>
  );
}
