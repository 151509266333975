import { MessageAlert } from "@/components/common/MessageAlert";
import { ITEMS_WITH_TITLE } from "@/components/elearning/chapter/constants";
import { ElearningChapterList } from "@/components/elearning/common/ElearningChapterList";
import elearningConstants from "@/components/elearning/common/constants";
import TeacherImage from "@/components/elearning/quiz/TeacherImage";
import { QuizResultBox } from "@/components/elearning/quiz_result/QuizResultBox";
import { getQuizPagePath } from "@/components/elearning/quiz_result/UrlService";
import { QuestionAnswer } from "@/components/examinee/interview/Types";
import { useElearningSearchParams } from "@/pages/elearning/ElearningLayout";
import { Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function QuizResult() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [answers, setAnswers] = useState<QuestionAnswer[]>([]);

  const location = useLocation();
  const elearningQuery = useElearningSearchParams();

  useEffect(() => {
    const setStates = (answers: QuestionAnswer[], message: string | null) => {
      setAnswers(answers);
      setErrorMessage(message);
    };

    if (location.state == null) {
      setStates([], "Error");
      return;
    }

    const answers = location.state as QuestionAnswer[];
    setAnswers(answers);
  }, [location]);

  return (
    <>
      {errorMessage != null && (
        <MessageAlert severity="error" message={errorMessage} sx={{ mb: 1 }} />
      )}
      <Stack
        alignItems="center"
        sx={{
          pt: 4,
          pb: 6,
          background: elearningConstants.IMAGE_BACKGROUND,
        }}
      >
        <TeacherImage width="168px" />
        <QuizResultBox
          answers={answers}
          retryTo={getQuizPagePath(location.search)}
        />
      </Stack>
      <Stack sx={{ px: 4, pt: 6, pb: 20 }} spacing={6}>
        <Typography variant="subtitle2">
          より良い睡眠を摂るための{answers.length}
          つの睡眠クイズはいかがでしたか？
        </Typography>
        <Typography variant="subtitle2">
          快適な睡眠で健康な毎日を送るための秘訣を簡単にまとめました。かんたんに実践できることばかりですので、一読ください。
        </Typography>
      </Stack>
      <Stack sx={{ px: 4 }} spacing={8}>
        <Divider />
        <ElearningChapterList
          items={ITEMS_WITH_TITLE}
          elearningSearchParams={elearningQuery}
        />
      </Stack>
    </>
  );
}

export default QuizResult;
