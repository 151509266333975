import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function OtherHelpDialog() {
  return (
    <HelpButton dialogTitle="その他">
      <SimpleParagraphs
        paragraphs={[
          {
            content:
              "その他に受診者の方に確認したい内容や質問の回答をこちらに自由に記載してください。",
          },
        ]}
      />
    </HelpButton>
  );
}
