import { PasswordCheckListItem } from "@/pages/examinee/activation/SignUp";
import {
  callAuthChallengeAPI,
  CognitoChallengeParametersStore,
  CognitoSession,
} from "@/utils/auth";
import { Box, Button, Typography } from "@mui/material";
import { AxiosInstance } from "axios";
import { FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PASSWORD_SYMBOL_REGEX } from "../activation/Constants";
import {
  NEW_PASSWORD_CONFIRM_VALIDATORS,
  NEW_PASSWORD_WITHOUT_CHECKLIST_VALIDATORS,
  ValidatableTextField,
} from "./ValidatableTextField";

type NewPasswordChallengedProps = {
  axios: AxiosInstance;
  setErrorMessage: (e: any) => void;
  defaultSuccessTo: string;
  setSignInType: (message: any) => void;
};

function NewPasswordChallenge(props: NewPasswordChallengedProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const next = query.get("next") ?? props.defaultSuccessTo;
  const [newPasswordSubmittable, setNewPasswordSubmittable] = useState(false);
  const [reNewPasswordSubmittable, setReNewPasswordSubmittable] =
    useState(false);
  const [password, setPassword] = useState("");

  const validators = {
    passwordLength: () => {
      if (password.length < 8 || 24 < password.length) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordAlphabetLowerAndUpper: () => {
      if (!(password.match(/[a-z]/) && password.match(/[A-Z]/))) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordDigit: () => {
      if (!password.match(/\d/)) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordSymbol: () => {
      if (!password.match(PASSWORD_SYMBOL_REGEX)) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.setErrorMessage("");
    const data = new FormData(event.currentTarget);
    const newPassword = data.get("newPassword")?.toString() ?? "";
    const reEnterPassword = data.get("reEnterPassword")?.toString() ?? "";
    if (newPassword !== reEnterPassword) {
      props.setErrorMessage(
        "The new password and the re-entered password do not match."
      );
      return;
    }

    try {
      const session: string | null = CognitoSession.getItem();
      const userIdForSRP: string | undefined =
        CognitoChallengeParametersStore.getItem()?.USER_ID_FOR_SRP;
      if (userIdForSRP == null || session == null) {
        props.setErrorMessage("Incorrect username or password.");
        props.setSignInType("init");
        return;
      }

      const signInType = await callAuthChallengeAPI(
        props.axios,
        userIdForSRP,
        newPassword,
        session
      );
      props.setSignInType(signInType);
      if (signInType !== "success") {
        props.setErrorMessage("Incorrect username or password.");
        props.setSignInType("init");

        return;
      }
      navigate(next);
    } catch (e: any) {
      const res: any = e.response?.data;
      if (res == null) {
        props.setErrorMessage("Incorrect username or password.");
        props.setSignInType("init");
        return;
      }

      if (res.error_messages) {
        props.setErrorMessage(res.error_messages[0]);
        props.setSignInType("init");
      } else {
        props.setErrorMessage("Incorrect username or password.");
        props.setSignInType("init");
      }
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0px",
        gap: "40px",
        width: "432px",
        height: "352px",
        flex: "none",
        order: "0",
        flexGrow: "0",
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px",
          gap: "16px",
        }}
      >
        <Typography
          variant="h3"
          color="text.primary"
          width="384px;"
          height="38px"
          fontStyle="normal"
        >
          新しいパスワードを設定しましょう
        </Typography>
        <Typography variant="body2">
          パスワードは大文字、小文字を含む半角英字、数字、記号を組み合わせた8文字以上で入力してください
        </Typography>
      </Box>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="3px"
        width="432px"
        height="144}px"
      >
        <ValidatableTextField
          validators={NEW_PASSWORD_WITHOUT_CHECKLIST_VALIDATORS}
          margin="normal"
          fullWidth
          name="newPassword"
          label="新しいパスワード"
          type="password"
          id="newPassword"
          autoComplete="current-newPassword"
          autoFocus
          onChangeErrorMessage={(msg) =>
            setNewPasswordSubmittable(
              msg === "" &&
                !validators.passwordLength() &&
                !validators.passwordAlphabetLowerAndUpper() &&
                !validators.passwordDigit() &&
                !validators.passwordSymbol()
            )
          }
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 1 }}
        />
        <PasswordCheckListItem
          text="8文字以上24文字以下にしてください"
          validator={validators.passwordLength}
        />
        <PasswordCheckListItem
          text="大文字と小文字の半角英字を含めてください"
          validator={validators.passwordAlphabetLowerAndUpper}
        />
        <PasswordCheckListItem
          text="半角数字を含めてください"
          validator={validators.passwordDigit}
        />
        <PasswordCheckListItem
          text="記号を含めてください"
          validator={validators.passwordSymbol}
        />
        <ValidatableTextField
          validators={NEW_PASSWORD_CONFIRM_VALIDATORS}
          margin="normal"
          fullWidth
          name="reEnterPassword"
          label="新しいパスワード(確認)"
          type="password"
          id="reEnterNewPassword"
          autoComplete="current-reEnterNewPassword"
          onChangeErrorMessage={(msg) =>
            setReNewPasswordSubmittable(msg === "")
          }
        />
      </Box>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={!(newPasswordSubmittable && reNewPasswordSubmittable)}
      >
        設定する
      </Button>
    </Box>
  );
}
export default NewPasswordChallenge;
