import { useFactAndComment } from "@/components/worksheet/apis";
import { formatDisplayTime } from "@/utils/date";
import { Circle, ErrorOutline, Square } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Button,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Axios from "axios";
import { format, parse } from "date-fns";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BorderlessTableContainer } from ".";
import { SubtitleTypography } from "../common/Typographies";
import { HowToViewGraphsDialogForPC } from "../report/commons/HowToViewGraphsDialog";
import SleepDiaryChart from "../report/commons/SleepDiaryChart";
import AlcoholHelpDialog from "./commons/dialogs/AlcoholHelpDialog";
import BathHelpDialog from "./commons/dialogs/BathHelpDialog";
import BeforeSleepMindHelpDialog from "./commons/dialogs/BeforeSleepMind";
import BluelightHelpDialog from "./commons/dialogs/BluelightHelpDialog";
import BodyConditionHelpDialog from "./commons/dialogs/BodyConditionHelpDialog";
import CaffeineHelpDialog from "./commons/dialogs/CaffeineHelpDialog";
import ExerciseHelpDialog from "./commons/dialogs/ExerciseHelpDialog";
import LightHelpDialog from "./commons/dialogs/LightHelpDialog";
import NapHelpDialog from "./commons/dialogs/NapHelpDialog";
import NightmealHelpDialog from "./commons/dialogs/NightmealHelpDialog";
import {
  FactAndComment,
  FactAndCommentUpdateData,
  ValueAndWarning,
} from "./commons/fact_and_comments";
import HelpButton from "./commons/HelpButton";

/**
 * 週間グラフページ
 * @returns 週間グラフページ
 */
export default function FactAndComments() {
  const { uuid } = useParams<{ uuid: string }>();
  const api = useFactAndComment();
  const [factAndComments, setFactAndComments] = useState<FactAndComment[]>();

  useEffect(() => {
    if (uuid == null) return;
    (async () => {
      const res = await api.get(uuid);
      setFactAndComments(res.data);
    })();
  }, [uuid, api]);

  const handleChange = (newData: FactAndComment[]) => {
    setFactAndComments(newData);
  };

  return (
    <>
      <Stack sx={{ gap: 20 }}>
        <GraphSection data={factAndComments} />
        <MeasuredDataSection data={factAndComments} onChange={handleChange} />
      </Stack>
    </>
  );
}

/**
 * グラフ
 */
function GraphSection({ data }: { data?: FactAndComment[] }) {
  const [howToViewGraphs, setHowToViewGraphs] = useState(false);
  return (
    <>
      <HowToViewGraphsDialogForPC
        open={howToViewGraphs}
        setOpen={setHowToViewGraphs}
      />
      <Stack>
        <SubtitleTypography>睡眠グラフ</SubtitleTypography>
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
          display="flex"
          alignItems="top"
          sx={{ gap: 8 }}
        >
          <Stack
            sx={{
              backgroundColor: "black.1",
              boxSizing: "border-box",
              p: 6,
              height: "559px",
              width: "78%",
            }}
          >
            {data && (
              <>
                <Stack direction="row" justifyContent="end" sx={{ pb: "16px" }}>
                  <Button
                    startIcon={
                      <ErrorOutline sx={{ transform: "rotateX(180deg)" }} />
                    }
                    onClick={() => {
                      setHowToViewGraphs(true);
                    }}
                    sx={{ fontSize: "13px" }}
                  >
                    グラフの見方
                  </Button>
                </Stack>
                <SleepDiaryChart
                  data={data.map((d) => ({
                    date: d.measured_data.date,
                    bedtime:
                      d.measured_data.bedtime == null
                        ? null
                        : new Date(d.measured_data.bedtime),
                    ariseTime:
                      d.measured_data.arise_time == null
                        ? null
                        : new Date(d.measured_data.arise_time),
                    sleepData: d.measured_data.chart_data.map((c) => ({
                      from: new Date(c.from),
                      to: new Date(c.to),
                      type: c.type,
                    })),
                  }))}
                  hasWidthLimit
                />
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

/**
 * 日別データ
 */
function MeasuredDataSection({
  data,
  onChange,
}: {
  data?: FactAndComment[];
  onChange: (newData: FactAndComment[]) => void;
}) {
  return (
    <>
      <Stack gap={6}>
        <SubtitleTypography>日別データ</SubtitleTypography>
        <Stack gap={10}>
          <SleepRecordsSection data={data?.map((d) => d.sleep_records)} />
          <YourOwnRecordsSection data={data?.map((d) => d.your_own_records)} />
          <SleepQualityTroublesSection
            data={data?.map((d) => d.sleep_quality_troubles)}
          />
          <LifestyleAndHealthConditionsSection
            data={data?.map((d) => d.lifestyle_and_health_conditions)}
          />
          {/* TODO: 各Sectionでdataの渡し方を揃えたい */}
          <MemoSection data={data} onChange={onChange} />
        </Stack>
      </Stack>
    </>
  );
}

/**
 * 測定データ => 客観データ
 */
function SleepRecordsSection({
  data,
}: {
  data?: FactAndComment["sleep_records"][];
}) {
  const fmt = useCallback(
    (time: string | null) =>
      time == null
        ? null
        : format(parse(time, "HH:mm:ss", new Date()), "HH:mm"),
    []
  );
  function Row({ title, cells }: { title: string; cells: ReactNode }) {
    return (
      <TableRow>
        <HeaderCellInBody>
          <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
            {title}
          </Typography>
        </HeaderCellInBody>
        {cells}
      </TableRow>
    );
  }
  return (
    <AccordionDiaryTable
      sectionTitle="客観データ"
      tableTitle1="項目"
      numDays={data?.length}
    >
      <Row
        title="睡眠時間"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[
              d.sleeping_time.value == null
                ? "-"
                : formatDisplayTime(d.sleeping_time.value),
            ]}
            isWarning={d.sleeping_time.is_warning}
          />
        ))}
      />
      <Row
        title="睡眠効率"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[d.sleep_efficiency.value]}
            isWarning={d.sleep_efficiency.is_warning}
            converter={(x) => `${x}%`}
          />
        ))}
      />
      <Row
        title="消灯時刻"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[fmt(d.bedtime.value)]}
            isWarning={d.bedtime.is_warning}
          />
        ))}
      />
      <Row
        title="起床時刻"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[fmt(d.arise_time.value)]}
            isWarning={d.arise_time.is_warning}
          />
        ))}
      />
    </AccordionDiaryTable>
  );
}

/**
 * 測定データ => 問診データ
 */
function YourOwnRecordsSection({
  data,
}: {
  data?: FactAndComment["your_own_records"][];
}) {
  function Row({ title, cells }: { title: string; cells: ReactNode }) {
    return (
      <TableRow>
        <HeaderCellInBody>
          <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
            {title}
          </Typography>
        </HeaderCellInBody>
        {cells}
      </TableRow>
    );
  }
  return (
    <AccordionDiaryTable
      sectionTitle="問診データ"
      tableTitle1="項目"
      numDays={data?.length}
    >
      <Row
        title="途中で起きた（自覚）"
        cells={data?.map((d, i) => (
          <ColoredCell key={i} values={[d.awakening]} />
        ))}
      />
      <Row
        title="起きた理由"
        cells={data?.map((d, i) => (
          <ColoredCell key={i} values={[d.awakening_reason]} />
        ))}
      />
      <Row
        title="日中の眠気"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[d.daytime_sleepiness.value]}
            isWarning={d.daytime_sleepiness.is_warning}
          />
        ))}
      />
      <Row
        title="日中の疲労"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[d.daytime_tiredness.value]}
            isWarning={d.daytime_tiredness.is_warning}
          />
        ))}
      />
      <Row
        title="デバイスを外したか"
        cells={data?.map((d, i) => (
          <ColoredCell key={i} values={[d.device_wearing]} />
        ))}
      />
    </AccordionDiaryTable>
  );
}

/**
 * 測定データ => 睡眠に関して困ったこと
 */
function SleepQualityTroublesSection({
  data,
}: {
  data?: FactAndComment["sleep_quality_troubles"][];
}) {
  /**
   * 主観値と客観値にズレがあるかどうかを判定する関数。
   * ただし少なくとも一方がnullである場合、falseを返す。
   *
   * 2024-07-10時点において、無回答のケースにおいてis_warningの値はnullにはならず、
   *   device = {is_warning: false, value: null}
   * のような値がAPIから返ってくる。
   * そのためis_warningの値だけで処理を完結することはできないので、
   * valueの値も考慮して処理を行っている。
   */
  const hasDifferentWarnings = ({
    device,
    answer,
  }: {
    device: ValueAndWarning<number>;
    answer: ValueAndWarning<string>;
  }) => {
    const v1 = device.value == null ? 0 : device.is_warning ? 2 : 1;
    const v2 = answer.value == null ? 0 : answer.is_warning ? 2 : 1;
    return v1 + v2 === 3;
  };

  /** 凡例の1つの要素 */
  function LegendItem({ mark, text }: { mark: ReactNode; text: string }) {
    return (
      <Stack flexDirection="row" alignItems="center" gap={2}>
        {mark}
        <Typography variant="caption">{text}</Typography>
      </Stack>
    );
  }

  /** 指標部分（●アイコンとテキスト） */
  function Indicator({
    isObjective,
    text,
  }: {
    isObjective?: boolean; // 客観値の場合にtrue
    text: string;
  }) {
    const color = isObjective ? "#0056A0" : "#01A676";
    return (
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Circle sx={{ color: color, fontSize: "12px" }} />
        <Typography variant="caption">{text}</Typography>
      </Stack>
    );
  }

  function Row({
    title,
    indicatorTitle,
    cells,
  }: {
    title?: string;
    indicatorTitle: string;
    cells: ReactNode;
  }) {
    const isObjective = title != null; // titleが無ければ客観値の行とみなす
    return (
      <TableRow>
        {isObjective && (
          // 客観値の行の場合のみ、rowSpan=2のヘッダセルを表示する
          <HeaderCellInBody rowSpan={2}>
            <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
              {title}
            </Typography>
          </HeaderCellInBody>
        )}
        <TableCell>
          <Indicator text={indicatorTitle} isObjective={isObjective} />
        </TableCell>
        {cells}
      </TableRow>
    );
  }

  function HelpContents() {
    function HelpContentsBlock({
      title,
      description,
      tableTitle,
      cell1,
      cell2,
      legend,
    }: {
      title: string;
      description: string;
      tableTitle: string;
      cell1: ReactNode;
      cell2: ReactNode;
      legend?: ReactNode;
    }) {
      return (
        <Stack sx={{ gap: 6 }}>
          <Stack sx={{ gap: 1 }}>
            <Typography variant="subtitle1" sx={{ color: "text.primary" }}>
              {title}
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {description}
            </Typography>
          </Stack>
          <Stack alignItems="center" sx={{ gap: 2 }}>
            <Typography variant="caption" sx={{ color: "text.primary" }}>
              {tableTitle}
            </Typography>
            <BorderlessTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ color: "#7A7A7A", fontSize: "12px" }}>
                        項目
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: "#7A7A7A", fontSize: "12px" }}>
                        指標
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: "#7A7A7A", fontSize: "12px" }}>
                        ○○日目
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <HeaderCellInBody rowSpan={2}>
                      <Typography sx={{ fontSize: "12px" }}>項目名</Typography>
                    </HeaderCellInBody>
                    <TableCell sx={{ width: "33%" }}>
                      <Indicator text="客観的な指標" isObjective />
                    </TableCell>
                    {cell1}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Indicator text="主観的な指標" />
                    </TableCell>
                    {cell2}
                  </TableRow>
                </TableBody>
              </Table>
            </BorderlessTableContainer>
            {legend && (
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ width: "100%" }}
              >
                {legend}
              </Stack>
            )}
          </Stack>
        </Stack>
      );
    }
    return (
      <HelpButton dialogTitle="睡眠に関して困ったこと">
        <Stack sx={{ gap: 10 }}>
          <HelpContentsBlock
            title="主観指標として困っていて、客観指標も基準値を超えている場合"
            description="その項目を優先的に改善する目標を設定すると良いでしょう。"
            tableTitle="例：主観および客観指標が基準値外の場合"
            cell1={
              <ColoredCell
                values={["基準値外"]}
                isWarning
                sx={{ width: "33%" }}
              />
            }
            cell2={
              <ColoredCell
                values={["基準値外"]}
                isWarning
                sx={{ width: "33%" }}
              />
            }
          />
          <HelpContentsBlock
            title="主観指標としては困っていて、客観指標が基準値を超えていない場合"
            description="睡眠は、主観的な認識と客観的な指標がずれやすいことが知られています。例えば、「寝付くまでの時間は客観的には15分」（基準値未満のため正常）であるにもかかわらず、本人は「寝付けなくて困った」と回答している場合があります。このような場合、客観的な指標が基準値内であることを前提に、困っている理由を確認してみましょう。"
            tableTitle="例：客観指標は基準値内だが、主観指標が基準値外の場合"
            cell1={
              <ColoredCell
                values={["基準値内"]}
                hasColoredBackground
                sx={{ width: "33%" }}
              />
            }
            cell2={
              <ColoredCell
                values={["基準値外"]}
                isWarning
                hasColoredBackground
                sx={{ width: "33%" }}
              />
            }
            legend={
              <LegendItem
                mark={<Square sx={{ color: "#FF3A304D", fontSize: "12px" }} />}
                text="主観と客観にズレがある日"
              />
            }
          />
        </Stack>
      </HelpButton>
    );
  }

  return (
    <AccordionDiaryTable
      sectionTitle="睡眠に関して困ったこと"
      sectionHelp={<HelpContents />}
      legend={
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={4}
          sx={{ mb: 3.375, mr: 4 }}
        >
          <LegendItem
            mark={<Circle sx={{ color: "#0056A0", fontSize: "12px" }} />}
            text="客観値"
          />
          <LegendItem
            mark={<Circle sx={{ color: "#01A676", fontSize: "12px" }} />}
            text="主観値"
          />
          <LegendItem
            mark={<Square sx={{ color: "#FF3A304D", fontSize: "12px" }} />}
            text="主観と客観にズレがある日"
          />
        </Stack>
      }
      tableTitle1="項目"
      tableTitle2="指標"
      numDays={data?.length}
      defaultExpanded={false}
    >
      <Row
        title="寝付けない"
        indicatorTitle="寝付くまでの時間"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[
              d.initiating_sleep_difficulty.device.value == null
                ? "-"
                : formatDisplayTime(d.initiating_sleep_difficulty.device.value),
            ]}
            isWarning={d.initiating_sleep_difficulty.device.is_warning}
            hasColoredBackground={hasDifferentWarnings(
              data[i].initiating_sleep_difficulty
            )}
          />
        ))}
      />
      <Row
        indicatorTitle="寝付けなくて困ったか"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[d.initiating_sleep_difficulty.answer.value]}
            isWarning={d.initiating_sleep_difficulty.answer.is_warning}
            hasColoredBackground={hasDifferentWarnings(
              data[i].initiating_sleep_difficulty
            )}
          />
        ))}
      />
      <Row
        title="途中で起きる"
        indicatorTitle="覚醒時間（合計）"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[
              d.awakening.device.value == null
                ? "-"
                : formatDisplayTime(d.awakening.device.value),
            ]}
            isWarning={d.awakening.device.is_warning}
            hasColoredBackground={hasDifferentWarnings(data[i].awakening)}
          />
        ))}
      />
      <Row
        indicatorTitle="途中で起きて困ったか"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[d.awakening.answer.value]}
            isWarning={d.awakening.answer.is_warning}
            hasColoredBackground={hasDifferentWarnings(data[i].awakening)}
          />
        ))}
      />
      <Row
        title="早く目が覚める"
        indicatorTitle="目が覚めてから起床までの時間"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[
              d.premature_morning_awakening.device.value == null
                ? "-"
                : formatDisplayTime(d.premature_morning_awakening.device.value),
            ]}
            isWarning={d.premature_morning_awakening.device.is_warning}
            hasColoredBackground={hasDifferentWarnings(
              data[i].premature_morning_awakening
            )}
          />
        ))}
      />
      <Row
        indicatorTitle="早く目が覚めて困ったか"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={[d.premature_morning_awakening.answer.value]}
            isWarning={d.premature_morning_awakening.answer.is_warning}
            hasColoredBackground={hasDifferentWarnings(
              data[i].premature_morning_awakening
            )}
          />
        ))}
      />
    </AccordionDiaryTable>
  );
}

/**
 * 測定データ => 生活習慣と健康状態
 */
function LifestyleAndHealthConditionsSection({
  data,
}: {
  data?: FactAndComment["lifestyle_and_health_conditions"][];
}) {
  function Row({
    rowSpan = 1,
    help,
    title,
    indicatorTitle,
    cells,
  }: {
    rowSpan?: number;
    help?: ReactNode;
    title?: string;
    indicatorTitle: string;
    cells: ReactNode;
  }) {
    return (
      <TableRow>
        {rowSpan != null && 0 < rowSpan && (
          <HeaderCellInBody rowSpan={rowSpan}>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
                {title}
              </Typography>
              {help}
            </Stack>
          </HeaderCellInBody>
        )}
        <TableCell>
          <Typography variant="caption">{indicatorTitle}</Typography>
        </TableCell>
        {cells}
      </TableRow>
    );
  }

  return (
    <AccordionDiaryTable
      sectionTitle="生活習慣と健康状態"
      tableTitle1="項目"
      tableTitle2="指標"
      numDays={data?.length}
    >
      <Row
        title="運動"
        help={<ExerciseHelpDialog />}
        indicatorTitle="運動をした？"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.exercise.data[0].answers}
            isWarning={d.exercise.is_warning}
          />
        ))}
      />
      <Row
        title="夜食"
        help={<NightmealHelpDialog />}
        indicatorTitle="寝る前に夕食・夜食を食べた？"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.nightmeal.data[0].answers}
            isWarning={d.nightmeal.is_warning}
          />
        ))}
      />
      <Row
        title="アルコール"
        help={<AlcoholHelpDialog />}
        indicatorTitle="お酒を飲んだ？"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.alcohol.data[0].answers}
            isWarning={d.alcohol.is_warning}
          />
        ))}
      />
      <Row
        title="ブルーライト"
        help={<BluelightHelpDialog />}
        indicatorTitle="寝る直前、パソコンやスマホ、ゲーム機を使用した？"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.bluelight.data[0].answers}
            isWarning={d.bluelight.is_warning}
          />
        ))}
      />
      <Row
        title="部屋の明かり"
        help={<LightHelpDialog />}
        indicatorTitle="寝る直前（おおよそ１時間以内）、部屋の明かりはどれくらいでしたか？"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.light.data[0].answers}
            isWarning={d.light.is_warning}
          />
        ))}
      />
      <Row
        rowSpan={2}
        title="仮眠"
        help={<NapHelpDialog />}
        indicatorTitle="仮眠をとりましたか？"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.nap.data[0].answers}
            isWarning={d.nap.is_warning}
          />
        ))}
      />
      <Row
        rowSpan={0}
        indicatorTitle="仮眠をとった時間帯"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.nap.data[1].answers}
            isWarning={d.nap.is_warning}
          ></ColoredCell>
        ))}
      />
      <Row
        title="カフェイン"
        help={<CaffeineHelpDialog />}
        indicatorTitle="カフェインを含む飲料を飲んだ？"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.caffeine.data[0].answers}
            isWarning={d.caffeine.is_warning}
          />
        ))}
      />
      <Row
        rowSpan={2}
        title="入浴"
        help={<BathHelpDialog />}
        indicatorTitle="お風呂（湯船）に入りましたか？"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.bath.data[0].answers}
            isWarning={d.bath.is_warning}
          />
        ))}
      />
      <Row
        rowSpan={0}
        indicatorTitle="湯船の温度はどれくらい？"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.bath.data[1].answers}
            isWarning={d.bath.is_warning}
          />
        ))}
      />
      <Row
        title="体の調子"
        help={<BodyConditionHelpDialog />}
        indicatorTitle="体の調子で気になることがあった？"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.body_condition.data[0].answers}
            isWarning={d.body_condition.is_warning}
          />
        ))}
      />
      <Row
        title="寝る前の気分"
        help={<BeforeSleepMindHelpDialog />}
        indicatorTitle="寝床に入ってからの気分は？"
        cells={data?.map((d, i) => (
          <ColoredCell
            key={i}
            values={d.before_sleep_mind.data[0].answers}
            isWarning={d.before_sleep_mind.is_warning}
          />
        ))}
      />
    </AccordionDiaryTable>
  );
}

/**
 * 測定データ => 日別メモ
 */
function MemoSection({
  data,
  onChange,
}: {
  data?: FactAndComment[];
  onChange: (newData: FactAndComment[]) => void;
}) {
  const { uuid } = useParams<{ uuid: string }>();
  const api = useFactAndComment();
  const [memoTexts, setMemoTexts] = useState<string[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>(
    data != null ? data.map((d) => "") : []
  );
  const { t } = useTranslation();

  useEffect(() => {
    setMemoTexts(data != null ? data.map((d) => d.memo.text ?? "") : []);
  }, [data]);

  const update = useCallback(
    async (updatedData: FactAndCommentUpdateData, index: number) => {
      if (uuid == null || data == null) return;
      try {
        const res = await api.post(uuid, updatedData);
        const copiedErrorMessages = [...errorMessages];
        copiedErrorMessages[index] = "";
        setErrorMessages(copiedErrorMessages);

        const copiedData = structuredClone(data);
        copiedData[index].memo.text = updatedData.memo;
        copiedData[index].memo.updated_at = res.data.updated_at;
        onChange(copiedData);
      } catch (e: unknown) {
        if (Axios.isAxiosError(e)) {
          if (e.response == null) return;
          const copiedErrorMessages = [...errorMessages];
          const data = e.response.data as Record<string, string[]>;
          for (const [, messages] of Object.entries(data)) {
            copiedErrorMessages[index] = t(messages[0]);
            setErrorMessages(copiedErrorMessages);
            break;
          }
        } else {
          throw e;
        }
      }
    },
    [uuid, api, t, data, errorMessages, onChange]
  );

  return (
    <AccordionDiaryTable sectionTitle="日別メモ" numDays={data?.length}>
      <TableRow sx={{ height: "160px" }}>
        <HeaderCellInBody />
        {memoTexts.map((memoText, i) => (
          <TableCell key={i}>
            <TextField
              fullWidth
              multiline
              rows={4}
              error={Boolean(errorMessages[i]?.length)}
              helperText={errorMessages[i]}
              label="メモを追加"
              variant="standard"
              value={memoTexts[i]}
              onChange={(e) => {
                const copiedMemoTexts = [...memoTexts];
                copiedMemoTexts[i] = e.target.value;
                setMemoTexts(copiedMemoTexts);
              }}
              onBlur={(e) => {
                if (data == null || data.length <= i) return;
                if (
                  data[i].memo.text === memoTexts[i] ||
                  (!data[i].memo.text && !memoTexts[i])
                ) {
                  // 変化がない場合はPOSTしない
                  return;
                }
                update(
                  {
                    memo: memoText,
                    date: data[i].measured_data.date,
                    updated_at: data[i].memo.updated_at,
                  },
                  i
                );
              }}
            />
          </TableCell>
        ))}
      </TableRow>
    </AccordionDiaryTable>
  );
}

/**
 * アコーディオンで開閉可能なテーブル
 */
function AccordionDiaryTable({
  sectionTitle,
  sectionHelp,
  legend,
  tableTitle1,
  tableTitle2,
  numDays,
  defaultExpanded = true,
  children,
}: {
  sectionTitle: string;
  sectionHelp?: ReactNode;
  legend?: ReactNode;
  tableTitle1?: string;
  tableTitle2?: string;
  numDays?: number;
  defaultExpanded?: boolean;
  children?: ReactNode;
}) {
  return (
    <>
      <Accordion
        defaultExpanded={defaultExpanded}
        disableGutters
        elevation={0}
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: "8px",
          "&:first-of-type": {
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          },
          "&:last-of-type": {
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          },
          "&::before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1rem" }} />}
          sx={{
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper": {
              padding: "8px",
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(270deg)",
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: "4px",
            },
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography variant="subtitle1">{sectionTitle}</Typography>
            {sectionHelp}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {legend}
          <BorderlessTableContainer
            sx={{
              borderTop: "1px solid #E0E0E0",
              borderRight: "none",
              borderBottom: "none",
              borderLeft: "none",
              borderTopLeftRadius: "0",
              borderTopRightRadius: "0",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={tableTitle2 != null ? "9%" : "22%"}>
                    <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
                      {tableTitle1}
                    </Typography>
                  </TableCell>
                  {tableTitle2 != null && (
                    <TableCell width="13%">
                      <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
                        {tableTitle2}
                      </Typography>
                    </TableCell>
                  )}
                  {[...Array(numDays).keys()].map((i) => (
                    <TableCell key={i}>
                      <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
                        {i + 1}日目
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{children}</TableBody>
            </Table>
          </BorderlessTableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

/**
 * TableBody内のRowにおけるヘッダーセル
 */
function HeaderCellInBody(props: TableCellProps) {
  return (
    <TableCell
      {...props}
      sx={{ backgroundColor: "#FAFAFA", borderRight: "1px dashed #E0E0E0" }}
    />
  );
}

/**
 * エラー時に赤で塗りつぶされるセル
 * @param values セルに表示する値の配列
 * @param isWarning trueを指定すると赤い文字色で表示される
 * @param hasColoredBackground trueを指定するとセルの背景色が赤くなる
 * @param converter valuesの要素が非nullの場合に適用する変換関数
 */
function ColoredCell({
  values,
  isWarning,
  hasColoredBackground = false,
  converter,
  sx,
}: {
  values: Array<number | string | null>;
  isWarning?: boolean | null;
  hasColoredBackground?: boolean | null;
  converter?: (value: number | string) => string;
  sx?: SxProps;
}) {
  const newValues = values.map((value) => {
    if (value == null) {
      return "-";
    }
    if (converter != null) {
      return converter(value);
    }
    return value?.toString();
  });
  return (
    <TableCell
      sx={{
        backgroundColor: hasColoredBackground ? "#FF3A3014" : null,
        width: "11%",
        ...sx,
      }}
    >
      <Stack>
        {newValues.map((newValue, i) => (
          <Typography
            key={i}
            variant="caption"
            sx={{
              color: isWarning && newValue !== "-" ? "#FF3A30" : "primary",
            }}
          >
            {newValue}
          </Typography>
        ))}
      </Stack>
    </TableCell>
  );
}
