import CopyToClipboardButton, {
  DEFAULT_TOOLTIP_MESSAGES,
} from "@/components/CopyToClipboardButton";
import ErrorFallback from "@/components/ErrorFallback";
import { SCAppBar } from "@/components/sleep_checkup_v1/SCAppBar";
import { facilityTheme } from "@/theme/themeV1";
import { getAxios, getFacilityApiServerUrl } from "@/utils/axios";
import {
  Box,
  CircularProgress,
  Table,
  TableCell,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Axios from "axios";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

const SERVER_URL = getFacilityApiServerUrl();
const REQUEST_URL = "/api/facility/group/";
const MIN_WIDTH = 1098;

type ConnectivityStatus = "init" | "success" | "failed";

// 未認証状態で認証が必要な URL に対してリクエストを行い、 401 が返った場合 django までは request が通っていると判断するため、token を設定していない axios を使用する。
const axios = getAxios(SERVER_URL);

function ConnectivityCheck() {
  const [status, setStatus] = useState<ConnectivityStatus>("init");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      try {
        await axios.get(REQUEST_URL);
        setErrorMessage(
          "認証が必要なURLに対し、認証情報がないにも関わらずリクエストが成功しました。"
        );
        return setStatus("failed");
      } catch (e: any) {
        if (Axios.isAxiosError(e) && e.response?.status === 401) {
          // ひとまずレスポンスコードだけの確認
          return setStatus("success");
        }
        setErrorMessage(JSON.stringify(e, null, 2));
        return setStatus("failed");
      }
    })();
  }, []);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ThemeProvider theme={facilityTheme}>
        <SCAppBar minWidth={MIN_WIDTH} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "80px 0",
            position: "absolute",
            width: "100%",
            left: "0px",
            top: "72px",
          }}
        >
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="432px"
            gap="24px"
          >
            <Typography variant="h3" color="text.primary">
              Backendサーバー
            </Typography>
            <Typography variant="subtitle2">{SERVER_URL}</Typography>
            <Typography variant="h3" color="text.primary">
              確認に使用したURL
            </Typography>
            <Typography variant="subtitle2">
              {`${SERVER_URL}${REQUEST_URL}`}
            </Typography>
            <Typography variant="h3" color="text.primary">
              リクエスト結果
            </Typography>
            {status === "init" && (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <CircularProgress />
              </Box>
            )}
            {status === "success" && (
              <Typography variant="subtitle2">成功しました！</Typography>
            )}
            {status === "failed" && (
              <Table>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">失敗しました！</Typography>
                  </TableCell>
                  <TableCell>
                    <CopyToClipboardButton
                      writeText={errorMessage}
                      tooltipProps={{ placement: "bottom" }}
                      tooltipMessages={{
                        ...DEFAULT_TOOLTIP_MESSAGES,
                        copy: "ログをクリップボードにコピー",
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Box component="pre">{errorMessage}</Box>
                  </TableCell>
                </TableRow>
              </Table>
            )}
            <Typography variant="h3" color="text.primary">
              Version
            </Typography>
            <Typography variant="subtitle2">
              {process.env.REACT_APP_VERSION || "-"}
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default ConnectivityCheck;
