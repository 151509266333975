import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { Copyright } from "@/components/examinee/Copyright";
import {
  GuideCard,
  GuideCardProps,
} from "@/components/examinee/interview/GuideCard";
import { InterviewAppBar } from "@/components/examinee/interview/InterviewAppBar";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import Expired from "@/sleep_compass_lite/components/target/Expired";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { StickyStack } from "@/sleep_compass_lite/components/target/StickyStack";
import { TargetAppBar } from "@/sleep_compass_lite/components/target/TargetAppBar";
import { TargetAuthenticated as Authenticated } from "@/sleep_compass_lite/components/target/TargetAuthenticated";
import { TargetFooter } from "@/sleep_compass_lite/components/target/TargetFooter";
import { interviewPath } from "@/sleep_compass_lite/domain_models/target/InterviewPath";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { usePrimaryInterview } from "@/sleep_compass_lite/use_cases/target/interview/UsePrimaryInterview";
import { useSurveyInfo } from "@/sleep_compass_lite/use_cases/target/interview/UseSurveyInfo";
import {
  Box,
  Button,
  Container,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

/**
 * 事前問診の説明ページ
 */
export function PrimaryInterviewIntroduction({
  headerHeight,
}: ExamineePageProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const [isStartButtonFloating, setIsStartButtonFloating] = useState<
    boolean | null
  >(null);

  const { surveyInfoUid } = useParams();
  const navigate = useNavigate();
  const { getEntry } = usePrimaryInterview();

  const [liteSurveyInfo, surveyInfoError] = useSurveyInfo();

  // 「回答を始めるボタン」のクリックイベントのハンドラー
  const handleClickStart = async () => {
    try {
      if (surveyInfoUid == null) {
        throw new Error("surveyInfoUid is null");
      }

      const entry = await getEntry(surveyInfoUid);
      const primaryInterviewPath = interviewPath.getPrimaryInterviewPath(
        surveyInfoUid,
        entry
      );
      // 問診画面から戻るために、現在の pathname を渡して遷移
      navigate(primaryInterviewPath, {
        state: {
          prevPathname: window.location.pathname,
        },
      });
    } catch (e: unknown) {
      setErrorMessage(getErrorMessage(e));
      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    }
  };

  const FOOTER_HEIGHT = "85px";
  const BACKGROUND_COLOR = "background.default";

  if (surveyInfoError != null) {
    return <NonFieldError>{getErrorMessage(surveyInfoError)}</NonFieldError>;
  }
  if (
    !["Registered", "PrimaryInterviewCompleted"].includes(
      liteSurveyInfo?.status ?? ""
    )
  ) {
    // アカウント登録済または事前問診完了以外のステータスの場合、エラー画面を表示
    return <PrimaryInterviewExpired headerHeight={headerHeight} />;
  }
  return (
    <Authenticated>
      <ScrollToTop />
      <Box
        sx={{
          minHeight: "100vh",
          position: "relative",
          backgroundColor: "background.blankSpace",
        }}
      >
        <InterviewAppBar height={headerHeight} title="はじめに" />
        <Container
          maxWidth="sm"
          sx={{
            pt: 0,
            pl: { xs: 0, sm: 0 },
            pr: { xs: 0, sm: 0 },
            pb: `calc(${FOOTER_HEIGHT} + 40px)`,
            backgroundColor: BACKGROUND_COLOR,
          }}
        >
          {errorMessage && (
            <Stack
              sx={{
                pt: 8,
                px: PX4,
                backgroundColor: "white",
              }}
            >
              <NonFieldError>{errorMessage}</NonFieldError>
            </Stack>
          )}
          <IntroductionMessage
            sx={{
              pt: 8,
              pb: 6,
              px: PX4,
              backgroundColor: "white",
            }}
            text="これから事前問診をはじめます。以下の説明を読んで、問診に回答してください。回答完了までには5分ほどかかります。一度回答を始めた場合、中断せず最後まで回答をお願いします。"
            note="※途中で画面を閉じてしまうと、最初からやり直しとなります。"
          />
          <IntroductionGuideCards
            sx={{
              pt: 8,
              pb: 6,
              px: PX4,
              backgroundColor: BACKGROUND_COLOR,
            }}
            cards={[
              {
                image: {
                  src: "/img/image_interview_introduction_01.png",
                  alt: "事前問診の操作",
                },
                text: "画面上部に質問が表示されます。回答を入力したら、画面下部の[次へ]ボタンを押して次の質問に進んでください。",
              },
              {
                image: {
                  src: "/img/image_interview_introduction_02.png",
                  alt: "事前問診の操作",
                },
                text: "[次へ]ボタンの表示がないページでは、表示された選択肢のうち最も当てはまるものにタッチしてください。選択すると自動的に次の質問に進みます。",
              },
              {
                image: {
                  src: "/img/image_interview_introduction_03.png",
                  alt: "事前問診の操作",
                },
                text: "間違えて回答してしまったときや、回答を変更したいときは画面左上の[戻る]をタッチ。ひとつ前の質問に戻れます。",
              },
            ]}
          />
          <StickyStack
            sx={{
              px: PX4,
              py: 6,
              background:
                "linear-gradient(180deg, rgba(246, 245, 244, 0.00) 0%, #F6F5F4 100%);",
            }}
            onChangeFloatingState={(isFloating) =>
              setIsStartButtonFloating(isFloating)
            }
          >
            <Button
              variant="contained"
              size="large"
              // ボタンの影は、フローティングしていないときは非表示(フローティングしているときは表示)
              sx={isStartButtonFloating ? undefined : { boxShadow: "none" }}
              onClick={handleClickStart}
            >
              回答をはじめる
            </Button>
          </StickyStack>
        </Container>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: FOOTER_HEIGHT,
          }}
        >
          <Copyright />
        </Stack>
      </Box>
    </Authenticated>
  );
}

type IntroductionMessageProps = {
  /** 説明文のコンテナに適用するスタイル。paddingやbackgroundColorの指定を想定。 */
  sx: SxProps<Theme>;
  /** 説明文 */
  text: string;
  /** 注記 */
  note: string;
};
/**
 * 事前問診の説明文を表示するコンポーネント
 */
function IntroductionMessage({ sx, text, note }: IntroductionMessageProps) {
  return (
    <Stack spacing={2} sx={sx}>
      <Typography variant="subtitle1" textAlign="justify">
        {text}
      </Typography>
      <Typography variant="caption">{note}</Typography>
    </Stack>
  );
}

type IntroductionGuideCardsProps = {
  /** コンポーネントのコンテナに適用するスタイル。paddingやbackgroundColorの指定を想定。 */
  sx: SxProps<Theme>;
  cards: GuideCardProps[];
};
/**
 * 事前問診の回答方法を記載するためのコンポーネント
 */
function IntroductionGuideCards({ sx, cards }: IntroductionGuideCardsProps) {
  return (
    <Stack spacing={6} sx={sx}>
      {cards.map((c, i) => (
        <GuideCard
          cardType="SleepCompassLite"
          key={i}
          image={c.image}
          text={c.text}
        />
      ))}
    </Stack>
  );
}

/**
 * 事前問診回答済みの画面のコンポーネント
 *
 * @returns 事前問診回答済みの画面
 */
function PrimaryInterviewExpired({ headerHeight }: ExamineePageProps) {
  const FOOTER_HEIGHT = "151px";

  return (
    <Box
      sx={{
        minHeight: "100vh",
        position: "relative",
        backgroundColor: "background.blankSpace",
      }}
    >
      <TargetAppBar height={headerHeight} />
      <Container
        maxWidth="sm"
        sx={{
          pt: 0,
          pl: { xs: 0, sm: 0 },
          pr: { xs: 0, sm: 0 },
          pb: FOOTER_HEIGHT,
          backgroundColor: "white",
          minHeight: `calc(100vh - ${headerHeight}px - ${FOOTER_HEIGHT})`,
        }}
      >
        <Expired
          title="事前問診は回答済みです"
          message="再回答はできないため、ページを閉じてください。"
        />
      </Container>
      <TargetFooter
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: FOOTER_HEIGHT,
        }}
      />
    </Box>
  );
}
