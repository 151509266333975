import { NavigateNext } from "@mui/icons-material";
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

import { Link as RouterLink } from "react-router-dom";

export interface ReportNavigateProps {
  noMarginIcon?: boolean;
  navigates: {
    icon: {
      src: string;
      alt: string;
    };
    text: string;
    link: string;
  }[];
  reportUid: string | undefined;
}
export default function ReportNavigate(props: ReportNavigateProps) {
  if (props.reportUid == null) return <></>;
  return (
    <List component="nav">
      {props.navigates.map((n, i) => (
        <React.Fragment key={i}>
          {i !== 0 && <Divider />}
          <ListItemButton
            sx={{ p: "40px 0px" }}
            component={RouterLink}
            to={n.link}
          >
            <ListItemIcon sx={{ minWidth: "0px", mr: "12px" }}>
              <img
                src={n.icon.src}
                alt={n.icon.alt}
                loading="lazy"
                style={{
                  margin: props.noMarginIcon ? "0px" : "auto",
                  width: "24px",
                  height: "24px",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="subtitle2">{n.text}</Typography>}
            />
            <NavigateNext />
          </ListItemButton>
        </React.Fragment>
      ))}
    </List>
  );
}
