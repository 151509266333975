import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { LIFESTYLE_AND_HEALTH_CONDITION_CONFIG } from "../constants";
import { LifestyleAndHealthConditionType } from "../types";
import { Tag } from "./Tag";

function ImproveMidAwakeningTag() {
  return (
    <Tag
      sx={{
        backgroundColor: "#EDE7F6",
        color: "#673AB7",
        display: "inline-block",
        margin: "4px",
      }}
    >
      睡眠中の覚醒を改善
    </Tag>
  );
}

function ImproveWakingUpTooEarlyTag() {
  return (
    <Tag
      sx={{
        backgroundColor: "#F3E5F5",
        color: "#9C27B0",
        display: "inline-block",
        margin: "4px",
      }}
    >
      早すぎる目覚めを改善
    </Tag>
  );
}

function ImproveFallingAsleep() {
  return (
    <Tag
      sx={{
        backgroundColor: "#E8EAF6",
        color: "#3F51B5",
        display: "inline-block",
        margin: "4px",
      }}
    >
      寝付きを改善
    </Tag>
  );
}

function ImproveOverallSleepQuality() {
  return (
    <Tag
      sx={{
        backgroundColor: "#E0F2F1",
        color: "#009688",
        display: "inline-block",
        margin: "4px",
      }}
    >
      睡眠全体の質を改善
    </Tag>
  );
}

function LifestyleAndHealthConditionCardTags(props: {
  type: LifestyleAndHealthConditionType;
}) {
  switch (props.type) {
    case "bath":
    case "before_sleep_mind":
    case "bluelight":
    case "caffeine":
    case "light":
      return <ImproveFallingAsleep />;
    case "alcohol":
      return (
        <>
          <ImproveMidAwakeningTag />
          <ImproveWakingUpTooEarlyTag />
        </>
      );
    case "exercise":
    case "body_condition":
    case "mind_condition":
    case "nap":
    case "nightmeal":
      return <ImproveOverallSleepQuality />;
    default:
      return <></>;
  }
}

export default function LifestyleAndHealthConditionCard(props: {
  type: LifestyleAndHealthConditionType;
  comment: string;
  link?: string;
}) {
  return (
    <Card sx={{ boxShadow: "0px 0px 0px 1px #E0E0E0" }}>
      <CardMedia
        component="img"
        alt={props.type}
        height="160"
        image={LIFESTYLE_AND_HEALTH_CONDITION_CONFIG[props.type].image}
      />
      <CardContent sx={{ p: "16px" }}>
        <Typography variant="h6" sx={{ fontSize: "20px" }}>
          {LIFESTYLE_AND_HEALTH_CONDITION_CONFIG[props.type].title}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            fontSize: "16px",
            whiteSpace: "pre-line",
            marginBottom: "16px",
          }}
        >
          {props.comment}
        </Typography>
        <LifestyleAndHealthConditionCardTags type={props.type} />
      </CardContent>
      {/* nothingの場合はリンクは存在し得ない為、除外する **/}
      {props.type !== "nothing" && props.link && (
        <CardActions sx={{ pt: "0px" }}>
          <Button
            component={RouterLink}
            to={props.link}
            size="small"
            endIcon={<KeyboardArrowRight />}
            sx={{ marginLeft: "auto", mb: "16px" }}
          >
            詳しく見る
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
