import { useThrowError } from "@/sleep_compass_lite/components/target/LiteErrorBoundary";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import { getApiServerUrl, getAxiosWithInterceptors } from "@/utils/axios";
import Axios from "axios";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * {@link useLocation} を使用してLiteターゲットユーザー用のaxiosインスタンスを取得
 *
 * @returns axiosインスタンス
 */
export function useTargetAxios() {
  const location = useLocation();
  const throwError = useThrowError();

  // useExamineeAxios()に倣って、useMemoを使用
  const axios = useMemo(
    () =>
      // 認証情報をヘッダにセットするなど、認証系の処理が組み込まれたaxiosインスタンスを取得
      getAxiosWithInterceptors(
        getApiServerUrl(),
        `${authenticationPath.getFullPath("SignIn")}?next=${location.pathname}${
          location.search
        }`
      ),
    [location]
  );

  // 500系のエラー時に汎用エラー画面を表示するため、axiosインスタンスに500系エラーをスローする処理を追加
  // (useMemoの中で追加すると、無限ループを起こすので、useMemoの外で追加する)
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (Axios.isAxiosError(error) && (error.response?.status ?? 0) >= 500) {
        throwError(error);
      }
      return Promise.reject(error);
    }
  );

  return axios;
}
