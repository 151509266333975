import { getRightCount } from "@/components/elearning/quiz_result/QuizResultService";
import { QuestionAnswer } from "@/components/examinee/interview/Types";
import { Cached } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressProps,
  Paper,
  Stack,
  Typography,
  circularProgressClasses,
} from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { QuizResultCount } from "./QuizResultCount";

function LineCircularProgress(
  props: CircularProgressProps & { children?: ReactNode }
) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "rgba(0, 86, 160, 0.12)",
        }}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: "primary",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.children != null && props.children}
      </Box>
    </Box>
  );
}

type QuizResultBoxProps = {
  answers: QuestionAnswer[];
  retryTo: string;
};
export function QuizResultBox({ answers, retryTo }: QuizResultBoxProps) {
  const rightCount = getRightCount(answers);
  const totalCount = answers.length;
  const rate = rightCount === 0 ? 0 : (rightCount / totalCount) * 100;
  return (
    <Paper sx={{ borderRadius: "16px", width: "calc(100% - 32px)" }}>
      <Stack alignItems="center" sx={{ my: 6, mx: 4 }}>
        <Typography variant="h5" color="primary.main">
          睡眠クイズの結果
        </Typography>
        <Stack alignItems="center" sx={{ marginTop: "24px" }}>
          <LineCircularProgress size="250px" thickness={4} value={rate}>
            <Stack alignItems="center">
              <Typography variant="subtitle1">あなたの正解数</Typography>
              <QuizResultCount
                numerator={rightCount}
                denominator={totalCount}
              />
            </Stack>
          </LineCircularProgress>
        </Stack>
        <Button
          component={Link}
          to={retryTo}
          sx={{ marginTop: "16px" }}
          variant="text"
          startIcon={<Cached />}
        >
          もう一度クイズに挑戦
        </Button>
      </Stack>
    </Paper>
  );
}
