import { Stack, Typography } from "@mui/material";

/**
 * {@link ReportQuestionTitle} のプロパティ
 */
export type ReportQuestionTitleProps = {
  /** プレフィックス */
  prefix?: string;
  /** タイトル */
  title?: string;
};
/**
 * レポートの[Q.]のタイトルを表示します。
 *
 * @param param0 {@link ReportQuestionTitleProps}
 * @returns レポートの[Q.]のタイトル
 */
export function ReportQuestionTitle({
  prefix = "Q.",
  title,
}: ReportQuestionTitleProps) {
  return (
    <Stack direction="row" sx={{ gap: 1 }}>
      <Typography
        sx={{
          color: "primary.main",
          fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
          fontFamily: "Helvetica Neue",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "100%",
          letterSpacing: "0.1px",
        }}
      >
        {prefix}
      </Typography>
      {title && (
        <Typography
          sx={{
            color: "text.primary",
            fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
            fontFamily: "Noto Sans JP",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "100%",
            letterSpacing: "0.1px",
          }}
        >
          {title}
        </Typography>
      )}
    </Stack>
  );
}
