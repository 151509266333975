import { Stack } from "@mui/material";
import React, { ReactNode } from "react";
import { Section, SectionLayout } from ".";
import {
  RiskComparison,
  RisksJudgment,
  SleepSummary,
} from "../commons/sleep_summaries";

function toSectionSection(
  title: string,
  judgment: RisksJudgment,
  comparison: { title: ReactNode } & RiskComparison
) {
  const toScoreLabel = (n: number | null | undefined) =>
    n == null ? "-" : `${n}/${comparison.max_value}点`;
  return (
    <Section
      title={title}
      standardLabel="基準値"
      result={{
        result: judgment.result,
        last_time_result: judgment.last_time_result,
      }}
      data={[
        {
          title: comparison.title,
          standard_value: toScoreLabel(comparison.standard_value),
          result: toScoreLabel(comparison.score),
          is_warning: comparison.is_warning,
          last_time_result: toScoreLabel(comparison.last_time_result),
        },
      ]}
      captions={judgment.basis_annotations.map((b) => `※ ${b}`)}
    />
  );
}

interface DiseaseRisksSectionProps {
  disease_risks?: SleepSummary["disease_risks"];
}
/**
 * 疾患リスク
 * @param props {@link DiseaseRisksSectionProps}
 * @returns 疾患リスク
 */
export default function DiseaseRisksSection({
  disease_risks,
}: DiseaseRisksSectionProps) {
  if (disease_risks == null) return <></>;
  const sections = [];
  if (disease_risks.SAS_risk != null) {
    sections.push(
      toSectionSection("睡眠時無呼吸症候群のリスク", disease_risks.SAS_risk, {
        title: (
          <>
            STOP-BANG尺度<sup>※</sup>
          </>
        ),
        ...disease_risks.SAS_risk.stopbang,
      })
    );
  }
  if (disease_risks.insomnia_risk != null) {
    sections.push(
      toSectionSection("不眠症のリスク", disease_risks.insomnia_risk, {
        title: (
          <>
            アテネ不眠尺度<sup>※</sup>
          </>
        ),
        ...disease_risks.insomnia_risk.AIS,
      })
    );
  }
  if (sections.length === 0) return <></>;

  return (
    <SectionLayout
      title="疾患のリスク"
      caption="代表的な睡眠障害である「睡眠時無呼吸症候群」「不眠症」について、問診回答を各診断ガイドラインに基づいて評価し、それぞれの疾患リスクを表示しています。"
    >
      <Stack sx={{ gap: 10 }}>
        {sections.map((s, i) => (
          <React.Fragment key={i}>{s}</React.Fragment>
        ))}
      </Stack>
    </SectionLayout>
  );
}
