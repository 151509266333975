import { getDailyInterview } from "@/components/examinee/interview/InterviewAPI";
import { InterviewEntryLoader } from "@/components/examinee/interview/InterviewEntryLoader";
import { paths } from "@/components/examinee/interview/Path";
import { InterviewEntry } from "@/components/examinee/interview/Types";
import { Params } from "react-router-dom";

export function DailyInterviewEntryLoader() {
  return <InterviewEntryLoader getEntry={getEntry} getPath={getPath} />;
}

function getEntry(params: Readonly<Params<string>>, currentPath: string) {
  if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
    throw new Error("sleepCheckupId is not assigned");
  }
  if (params.remindId == null || params.remindId === undefined) {
    throw new Error("remindId is not assigned");
  }

  return getDailyInterview(params.sleepCheckupId, params.remindId, currentPath);
}

function getPath(entry: InterviewEntry, params: Params) {
  if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
    throw new Error("sleepCheckupId is not assigned");
  }
  if (params.remindId == null) {
    throw new Error("remindId is null");
  }
  if (entry.screen.id == null) {
    throw new Error("screen.id is null");
  }

  return paths.getFullPath("DailyInterview", {
    ":sleepCheckupId": params.sleepCheckupId,
    ":remindId": params.remindId,
    ":interviewId": String(entry.id),
    ":screen": String(entry.screen.id),
  });
}
