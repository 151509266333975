import React from "react";

type LineBreakTextProps = {
  text: string;
};

export function LineBreakText({ text }: LineBreakTextProps) {
  return (
    <>
      {text
        .split(/(\n)/g)
        .map((t, i) =>
          t === "\n" ? (
            <br key={i} />
          ) : (
            <React.Fragment key={i}>{t}</React.Fragment>
          )
        )}
    </>
  );
}
