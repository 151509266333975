export function getDays(length: number): Date[] {
  const now = new Date();
  return Array.from(Array(length)).map((_, i) => {
    // 今日の0時を取得
    const d = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // i日ずれた日時をセット
    d.setDate(d.getDate() + (i + 1));
    return d;
  });
}

export function toString(date: Date): string {
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const dow = ["日", "月", "火", "水", "木", "金", "土"][date.getDay()]; // day of weak
  return `${m}月${d}日 (${dow})`;
}

export function toColor(date: Date): string {
  switch (date.getDay()) {
    case 0:
      // sunday
      return "error.main";
    case 6:
      // saturday
      return "info.main";
    default:
      return "text.primary";
  }
}
