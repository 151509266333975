import { Rating, RatingProps, Stack, Typography } from "@mui/material";

type AssessmentStarsProps = {
  /** ラベル文言 */
  starsLabel: string;
  /** スターのサイズ(単位: px) */
  starSize: RatingProps["size"];
  /** スターの数 */
  starsCount: number;
};
/**
 * ラベルとスターを並べて表示するコンポーネント
 */
export function AssessmentStars({
  starsLabel,
  starsCount,
  starSize,
}: AssessmentStarsProps) {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Typography variant="caption">{starsLabel}</Typography>
      <Rating
        size={starSize}
        value={starsCount}
        precision={0.1}
        max={3}
        readOnly
      />
    </Stack>
  );
}
