import {
  Remind,
  RemindItem,
  SimpleTime,
} from "@/components/examinee/daily_interview_remind/Types";
import { padding, toStringYYYYMMdd } from "@/utils/date";

export const NOT_SELECTED = "--";

const DATE_SEPARATOR = "/";

export function getTerm(remindItems: RemindItem[]): string | null {
  if (remindItems.length === 0) {
    return null;
  }

  if (remindItems.length === 1) {
    return toStringYYYYMMdd(remindItems[0].date, DATE_SEPARATOR);
  }

  const [start, end] = [
    toStringYYYYMMdd(remindItems[0].date, DATE_SEPARATOR),
    toStringYYYYMMdd(remindItems[remindItems.length - 1].date, DATE_SEPARATOR),
  ];

  return `${start} ~ ${end}`;
}

export function updateHour(
  remindItems: RemindItem[],
  index: number,
  value: string
): RemindItem[] {
  if (index < 0 || remindItems.length <= index) {
    return remindItems;
  }

  return remindItems.map((item, i) => {
    if (i !== index) {
      return item;
    }

    return new RemindItem(item.date, new SimpleTime(value, item.time.minutes));
  });
}

export function updateMinutes(
  remindItems: RemindItem[],
  index: number,
  value: string
): RemindItem[] {
  if (index < 0 || remindItems.length <= index) {
    return remindItems;
  }

  return remindItems.map((item, i) => {
    if (i !== index) {
      return item;
    }

    return new RemindItem(item.date, new SimpleTime(item.time.hour, value));
  });
}

export function validate(remindItems: RemindItem[]): boolean {
  for (const item of remindItems) {
    if (hasInvalidValue(item.time)) {
      return false;
    }
  }
  return true;
}

function hasInvalidValue(time: SimpleTime): boolean {
  return time.hour === NOT_SELECTED || time.minutes === NOT_SELECTED;
}

export function createRemindItem(remind: Remind): RemindItem {
  const d = new Date(remind.remind_datetime);
  return new RemindItem(d, createSimpleTime(d));
}

function createSimpleTime(date: Date): SimpleTime {
  return new SimpleTime(padding(date.getHours()), padding(date.getMinutes()));
}
