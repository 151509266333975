import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { InterviewAppBar } from "@/components/examinee/interview/InterviewAppBar";
import { ProgressBar } from "@/components/examinee/interview/ProgressBar";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import { canBack } from "@/sleep_compass_lite/components/target/Interview";
import {
  CompletionImage,
  CompletionMessage,
} from "@/sleep_compass_lite/components/target/InterviewCompleted";
import { InterviewRecordCard } from "@/sleep_compass_lite/components/target/InterviewRecordCard";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { TargetAuthenticated as Authenticated } from "@/sleep_compass_lite/components/target/TargetAuthenticated";
import { TargetFooter as Footer } from "@/sleep_compass_lite/components/target/TargetFooter";
import {
  PARAM_KEY_OPEN_DESCRIBE_DIALOG,
  reportPath,
} from "@/sleep_compass_lite/domain_models/target/ReportPath";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { createInterviewRecordInfoArray } from "@/sleep_compass_lite/presentation_lib/InterviewRecordInfo";
import {
  DailyInterviewItem,
  useDailyInterviewInfo,
} from "@/sleep_compass_lite/use_cases/target/interview/UseDailyInterviewInfo";
import { usePrimaryInterviewInfo } from "@/sleep_compass_lite/use_cases/target/interview/UsePrimaryInterviewInfo";
import {
  SurveyInfo,
  useSurveyInfo,
} from "@/sleep_compass_lite/use_cases/target/interview/UseSurveyInfo";
import { Box, Button, Container, Stack } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

/** 画面タイプ */
type DayType = "Holiday" | "BusinessDay" | "LastDailyInterviewDay";
/** 画像 */
const COMPLETION_IMAGES: Record<DayType, ReactNode> = {
  Holiday: <PleaseAnswerAtBusinessDayImage />,
  BusinessDay: <CompletionImage sx={{ pt: 12, pb: 6, px: "41.5px" }} />,
  LastDailyInterviewDay: (
    <CompletionImage sx={{ pt: 12, pb: 6, px: "41.5px" }} />
  ),
} as const;
/** 完了メッセージ */
const COMPLETION_MESSAGES: Record<
  DayType,
  {
    completionMessageTitle: string;
    completionMessage: string;
  }
> = {
  Holiday: {
    completionMessageTitle: "勤務日にご回答ください",
    completionMessage: `今回の調査は休日の回答は不要です。\n改めて勤務日に問診に回答してください。`,
  },
  BusinessDay: {
    completionMessageTitle: "おつかれさまでした！",
    completionMessage: `ご回答ありがとうございました\n本日の問診は以上です`,
  },
  LastDailyInterviewDay: {
    completionMessageTitle: "おつかれさまでした！",
    completionMessage: `今回の調査は以上で終了です\n以下から今回のレポートを確認できます`,
  },
} as const;
/** 問診回答の記録の注記 */
const INTERVIEW_RECORD_NOTE: Record<DayType, string> = {
  Holiday: `3日間分の回答をいただいた時点で、\n今回の調査は完了です。`,
  BusinessDay: `3日間分の回答をいただいた時点で、\n今回の調査は完了です。`,
  LastDailyInterviewDay: `今回の調査は完了しました。`,
};

/**
 * 休日用の画像コンポーネント
 * @returns 休日用の画像コンポーネント
 */
function PleaseAnswerAtBusinessDayImage() {
  return (
    <Box sx={{ py: 6, px: "41.5px" }}>
      <img
        src="/img/image_lite_interview_not_business_day.png"
        alt="not business day"
        loading="lazy"
        style={{ width: "100%", height: "auto", maxWidth: "390px" }}
      />
    </Box>
  );
}

/**
 * 毎日問診完了ページ
 */
export function DailyInterviewCompleted({ headerHeight }: ExamineePageProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { surveyInfoUid, announceId } = useParams();
  const [primaryInterviewInfo, primaryInterviewError] =
    usePrimaryInterviewInfo();
  const [dailyInterviewInfo, dailyInterviewError] = useDailyInterviewInfo();
  const [surveyInfo, surveyInfoError] = useSurveyInfo();
  const [dayType, setDayType] = useState<DayType>();

  useEffect(() => {
    if (dailyInterviewInfo == null || announceId == null) return;
    const currentId = parseInt(announceId);
    const currentInfo = dailyInterviewInfo.dailyInterviews.find(
      (i) => i.id === currentId
    );
    setDayType(detectDayType(currentInfo, surveyInfo));
  }, [dailyInterviewInfo, announceId, surveyInfo]);

  // エラー確認
  const error = [
    primaryInterviewError,
    dailyInterviewError,
    surveyInfoError,
  ].find((e) => e != null);
  if (error != null) {
    return <NonFieldError>{getErrorMessage(error)}</NonFieldError>;
  }

  const handleBack = () => {
    navigate(-1);
  };

  const FOOTER_HEIGHT = "151px";
  const BACKGROUND_COLOR = "background.default";
  const DAILY_INTERVIEW_LEN = 3; // 毎日問診の規定の回答回数

  const reportTopUrlParams = new URLSearchParams();
  reportTopUrlParams.append(PARAM_KEY_OPEN_DESCRIBE_DIALOG, "true");

  // 問診の記録の表示用データを作成する(APIレスポンスを取得できていないときは空のデータをセットする)
  const interviewRecord =
    primaryInterviewInfo != null && dailyInterviewInfo != null
      ? createInterviewRecordInfoArray(
          primaryInterviewInfo,
          dailyInterviewInfo,
          DAILY_INTERVIEW_LEN
        )
      : [];

  return (
    <Authenticated>
      <ScrollToTop />
      <Box
        sx={{
          minHeight: "100vh",
          position: "relative",
          backgroundColor: "background.blankSpace",
        }}
      >
        <InterviewAppBar
          height={headerHeight}
          title="回答完了"
          onClickBack={canBack(location) ? handleBack : undefined}
        />
        <Container
          maxWidth="sm"
          sx={{
            p: 0,
            pt: 0,
            pl: { xs: 0, sm: 0 },
            pr: { xs: 0, sm: 0 },
            pb: FOOTER_HEIGHT,
            backgroundColor: BACKGROUND_COLOR,
          }}
        >
          <Box sx={{ backgroundColor: "white" }}>
            <Stack sx={{ py: 6, px: PX4 }} alignItems="center">
              <ProgressBar sx={{ width: "100%" }} value={100} />
              {dayType != null && (
                <>
                  {COMPLETION_IMAGES[dayType]}
                  <CompletionMessage {...COMPLETION_MESSAGES[dayType]} />
                  {dayType === "LastDailyInterviewDay" &&
                    surveyInfoUid != null && (
                      <Button
                        sx={{ mt: 6, mb: 8 }}
                        variant="contained"
                        size="large"
                        fullWidth
                        component={Link}
                        to={reportPath.getFullPath(
                          "ReportTop",
                          {
                            ":surveyInfoUid": surveyInfoUid,
                          },
                          reportTopUrlParams
                        )}
                      >
                        レポートを見る
                      </Button>
                    )}
                </>
              )}
            </Stack>
          </Box>
          {dayType != null && (
            <InterviewRecordCard
              sx={{ mx: PX4, mt: 8, mb: 16 }}
              interviewRecordCardTitle="問診回答の記録"
              interviewRecordInfo={interviewRecord}
              interviewRecordNote={INTERVIEW_RECORD_NOTE[dayType]}
            />
          )}
        </Container>
        <Footer
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: FOOTER_HEIGHT,
          }}
        />
      </Box>
    </Authenticated>
  );
}

function detectDayType(
  currentDailyInterview: DailyInterviewItem | undefined,
  surveyInfo: SurveyInfo | undefined
): DayType | undefined {
  if (currentDailyInterview == null || surveyInfo == null) {
    return undefined;
  }

  if (
    surveyInfo.status === "ReportSent" ||
    surveyInfo.status === "AttitudeEnqueteCompleted"
  ) {
    return "LastDailyInterviewDay";
  } else {
    // 問診の回答が完了せずに、完了画面を表示した場合、「勤務日ですか？」の問いに「いいえ」と答えた
    // と判断して、休日用の画面を表示する。
    return currentDailyInterview.isCompleted ? "BusinessDay" : "Holiday";
  }
}
