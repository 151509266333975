import { DailyInterviewInfo as DailyInterviewInfoObject } from "@/sleep_compass_lite/data_transfer_objects/target/DailyInterviewInfo";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import {
  DailyInterviewInfo,
  DailyInterviewItem,
} from "@/sleep_compass_lite/domain_models/target/DailyInterviewInfo";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export { DailyInterviewInfo, DailyInterviewItem };

/** useLiteDailyInterviewInfo getInfo 型宣言 */
type GetDailyInterviewInfo = (
  surveyInfoUid: string
) => Promise<DailyInterviewInfo>;

/***
 * 毎日問診情報を取得するクロージャーを返すカスタムフック。カスタムフックで実装している理由は、useAxios(カスタムフック)を利用するため。
 */
export function useLiteDailyInterviewInfo() {
  const axios = useAxios();

  /**
   * 毎日問診情報の取得
   * @param surveyInfoUid サーベイ情報のUUID
   * @returns 毎日問診情報 {@link DailyInterviewInfo}
   */
  const getInfo: GetDailyInterviewInfo = useCallback(
    async (surveyInfoUid) => {
      try {
        const response: AxiosResponse<DailyInterviewInfoObject> =
          await axios.get(`/api/lite/daily_interview_info/${surveyInfoUid}`);
        return DailyInterviewInfo.create(response.data);
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  return { getInfo };
}

/**
 * URLパラメータから取得した surveyInfoUid を使用して {@link DailyInterviewInfo 毎日問診情報} を取得するカスタムフック
 */
export function useDailyInterviewInfo(): [
  DailyInterviewInfo | undefined,
  unknown
] {
  const { surveyInfoUid } = useParams();
  const { getInfo } = useLiteDailyInterviewInfo();
  const [dailyInterviewInfo, setDailyInterviewInfo] =
    useState<DailyInterviewInfo>();
  const [dailyInterviewError, setDailyInterviewError] = useState<unknown>();

  useEffect(() => {
    (async () => {
      if (surveyInfoUid == null) return;
      try {
        const interviewInfo = await getInfo(surveyInfoUid);
        setDailyInterviewInfo(interviewInfo);
      } catch (e: unknown) {
        setDailyInterviewError(e);
      }
    })();
  }, [getInfo, surveyInfoUid]);

  return [dailyInterviewInfo, dailyInterviewError];
}
