import { isInvalidDate, toStringYYYYMMdd } from "@/utils/date";
import { SxProps, TextField, TextFieldProps, Theme } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { parse } from "date-fns";

const DATE_FORMAT = "yyyy-MM-dd";

/**
 * 文字列の日付を {@link Date} に変換します。
 * 指定した文字列が null または 日付に変換できなかった場合、null を返却します。
 * @returns Date | null
 */
function parseStringDate(value: string | null) {
  if (value == null) return null;
  const dt = parse(value, DATE_FORMAT, new Date());
  return isInvalidDate(dt) ? null : dt;
}

type SCDesktopDatePickerProps = {
  sx?: SxProps<Theme>;
  variant: TextFieldProps["variant"];
  label: string;
  value: string | null;
  error?: TextFieldProps["error"];
  required?: TextFieldProps["required"];
  disabled?: TextFieldProps["disabled"];
  helperText?: TextFieldProps["helperText"];
  disableOpenPicker?: boolean;
  onChange: (value: string | null) => void;
  id?: TextFieldProps["id"];
};
export function SCDesktopDatePicker({
  sx,
  variant,
  label,
  value,
  error,
  required,
  disabled,
  helperText,
  disableOpenPicker,
  onChange,
  id,
}: SCDesktopDatePickerProps) {
  const handleValueChange = (
    dateValue: Date | null,
    keyboardInputValue: string | undefined
  ) => {
    if (dateValue != null && !isInvalidDate(dateValue)) {
      onChange(toStringYYYYMMdd(dateValue, "-"));
    } else {
      onChange(keyboardInputValue ?? null);
    }
  };
  return (
    <DesktopDatePicker
      label={label}
      inputFormat={DATE_FORMAT}
      mask="____-__-__"
      value={parseStringDate(value)}
      disabled={disabled}
      onChange={handleValueChange}
      disableOpenPicker={disableOpenPicker}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          sx={sx}
          error={error}
          required={required}
          disabled={disabled}
          helperText={helperText}
          id={id}
        />
      )}
    />
  );
}
