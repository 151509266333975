import { ScrollToTop } from "@/components/common/ScrollToTop";
import { Interview } from "@/components/examinee/interview/Interview";
import { postPrimaryInterviewAnswer } from "@/components/examinee/interview/InterviewAPI";
import { PrimaryInterviewPageSummary } from "@/components/examinee/interview/PageSummary";
import { paths } from "@/components/examinee/interview/Path";
import {
  InterviewAnswer,
  InterviewEntry,
} from "@/components/examinee/interview/Types";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import { Params } from "react-router-dom";

export function PrimaryInterview({ headerHeight }: ExamineePageProps) {
  return (
    <>
      <ScrollToTop />
      <Interview
        pageProps={{ headerHeight: headerHeight }}
        context={{
          pageSummaryCreator: (questionScreen) =>
            new PrimaryInterviewPageSummary(questionScreen),
          postAnswer: postAnswer,
          getNextScreen: getNextScreen,
          getNextPage: getNextPage,
        }}
      />
    </>
  );
}

function postAnswer(
  params: Readonly<Params<string>>,
  interviewAnswer: InterviewAnswer,
  currentPath: string
) {
  if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
    throw new Error("sleepCheckupId is not assigned");
  }

  return postPrimaryInterviewAnswer(
    params.sleepCheckupId,
    interviewAnswer,
    currentPath
  );
}

function getNextScreen(
  entry: InterviewEntry,
  params: Readonly<Params<string>>
) {
  if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
    throw new Error("sleepCheckupId is not assigned");
  }
  if (params.interviewId == null || params.interviewId === undefined) {
    throw new Error("interviewId is not assigned");
  }

  return paths.getFullPath("PrimaryInterview", {
    ":sleepCheckupId": params.sleepCheckupId,
    ":interviewId": params.interviewId,
    ":screen": String(entry.screen.id),
  });
}

function getNextPage(params: Readonly<Params<string>>) {
  if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
    throw new Error("sleepCheckupId is not assigned");
  }

  return paths.getFullPath("DailyInterviewRemind", {
    ":sleepCheckupId": params.sleepCheckupId,
  });
}
