import { ScrollToTop } from "@/components/common/ScrollToTop";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { StickyStack } from "@/sleep_compass_lite/components/target/StickyStack";
import { TermsOfServiceBase as Terms } from "@/sleep_compass_lite/components/target/TermsOfServiceBase";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import { Button, ButtonProps, Stack } from "@mui/material";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  surveyInfo: string;
  nextPath: string;
};

/**
 * 利用規約同意ページ
 */
export function TermsOfServiceAgreement() {
  const location = useLocation();

  const [isAgreeButtonFloating, setIsAgreeButtonFloating] = useState<
    boolean | null
  >(null);

  const { surveyInfo, nextPath } = (location.state as StateType) || {};
  if (!surveyInfo || !nextPath) {
    return (
      <GeneralError
        to={authenticationPath.getFullPath("SignIn")}
        buttonText="アカウント登録をやり直す"
      />
    );
  }
  const nextPageState = { surveyInfo: surveyInfo };

  const agreeButtonSX: ButtonProps["sx"] = { my: 6 };
  if (isAgreeButtonFloating === false) {
    // ボタンの影は、フローティングしていないときは非表示(フローティングしているときは表示)
    agreeButtonSX["boxShadow"] = "none";
  }

  return (
    <>
      <ScrollToTop />
      <Stack spacing={10} sx={{ pt: 8, pb: 4, px: PX4 }}>
        <Terms />
        <StickyStack
          sx={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);",
          }}
          onChangeFloatingState={(isFloating) =>
            setIsAgreeButtonFloating(isFloating)
          }
        >
          <Button
            variant="contained"
            size="large"
            component={Link}
            to={nextPath}
            state={nextPageState}
            sx={agreeButtonSX}
          >
            同意する
          </Button>
        </StickyStack>
      </Stack>
    </>
  );
}
