import { Typography } from "@mui/material";
import Markdown, { Options } from "react-markdown";
import remarkGfm from "remark-gfm";
import { SpeechBalloon } from "./SpeechBalloon";

export type ReportMarkdownProps = Readonly<Options>;

export function ReportMarkdown(props: ReportMarkdownProps) {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        p: ({ children }) => (
          <SpeechBalloon style={{ marginBottom: "8px" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "text.primary" }}
              component="p"
            >
              {children}
            </Typography>
          </SpeechBalloon>
        ),
        strong: ({ children }) => (
          <Typography
            variant="subtitle1"
            sx={{
              color: "primary.main",
              display: "inline",
            }}
            component="strong"
          >
            {children}
          </Typography>
        ),
      }}
      {...props}
    />
  );
}
