import { Card, CardContent, CardProps, Stack, Typography } from "@mui/material";

type GuidImageProps = {
  /** 問診説明用画像のパス */
  src: string;
  /**  問診説明用画像の代替文 */
  alt: string;
};
type CardType = "SleepCompass" | "SleepCompassLite";
export type GuideCardProps = {
  /** 問診説明用画像のプロパティ */
  image: GuidImageProps;
  /** 問診説明文 */
  text: string;
  /**
   * カード種別
   * @see CardType
   */
  cardType?: CardType;
};

/**
 * {@link CardType} に対応する {@link Card} のオプション
 */
const CARD_OPTIONS: Record<CardType, CardProps> = {
  SleepCompass: {},
  SleepCompassLite: {
    variant: "outlined",
    sx: { borderRadius: "8px" },
  },
};

/**
 * 問診の説明を表示するためのコンポーネント
 */
export function GuideCard({
  image,
  text,
  cardType = "SleepCompass", // デフォルトはSleepCompass
}: GuideCardProps) {
  return (
    <Card {...CARD_OPTIONS[cardType]}>
      <CardContent sx={{ p: 0 }}>
        <Stack sx={{ width: "100%" }}>
          <img
            src={image.src}
            alt={image.alt}
            loading="lazy"
            style={{ width: "100%", height: "auto" }}
          />
          <Typography sx={{ p: 4 }} textAlign="justify">
            {text}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
