import { BackdropIndicator } from "@/components/common/BackdropIndicator";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { DrowsinessPage } from "./DrowsinessPage";
import { FatiguePage } from "./FatiguePage";
import { LifestyleAndHealthConditionAlcoholPage } from "./LifestyleAndHealthConditionAlcoholPage";
import { LifestyleAndHealthConditionBathPage } from "./LifestyleAndHealthConditionBathPage";
import { LifestyleAndHealthConditionBeforeSleepMindPage } from "./LifestyleAndHealthConditionBeforeSleepMindPage";
import { LifestyleAndHealthConditionBluelightPage } from "./LifestyleAndHealthConditionBluelightPage";
import { LifestyleAndHealthConditionBodyConditionPage } from "./LifestyleAndHealthConditionBodyConditionPage";
import { LifestyleAndHealthConditionCaffeinePage } from "./LifestyleAndHealthConditionCaffeinePage";
import { LifestyleAndHealthConditionExercisePage } from "./LifestyleAndHealthConditionExercisePage";
import { LifestyleAndHealthConditionLightPage } from "./LifestyleAndHealthConditionLightPage";
import { LifestyleAndHealthConditionMindConditionPage } from "./LifestyleAndHealthConditionMindConditionPage";
import { LifestyleAndHealthConditionNapPage } from "./LifestyleAndHealthConditionNapPage";
import { LifestyleAndHealthConditionNightmealPage } from "./LifestyleAndHealthConditionNightmealPage";
import { LifestyleAndHealthConditionsPage } from "./LifestyleAndHealthConditionsPage";
import { MeasurementDetailsPage } from "./MeasurementDetailsPage";
import { QualityPage } from "./QualityPage";
import { QuantityPage } from "./QuantityPage";
import { ReportPage } from "./ReportPage";
import { RhythmPage } from "./RhythmPage";
import { SleepCharacteristicsDetailPage } from "./SleepCharacteristicsDetailPage";

export default function ReportRouterConfig() {
  const [indicator, setIndicator] = useState(true);
  return (
    <>
      <ScrollToTop />
      <BackdropIndicator open={indicator} />
      <Routes>
        <Route
          path="/:uuid"
          element={
            <ReportPage setIndicator={setIndicator} indicator={indicator} />
          }
        />

        <Route
          path="/:uuid/measurement_details"
          element={<MeasurementDetailsPage setIndicator={setIndicator} />}
        />
        <Route
          path="/:uuid/quality"
          element={<QualityPage setIndicator={setIndicator} />}
        />
        <Route
          path="/:uuid/quantity"
          element={<QuantityPage setIndicator={setIndicator} />}
        />
        <Route
          path="/:uuid/rhythm"
          element={<RhythmPage setIndicator={setIndicator} />}
        />
        <Route
          path="/:uuid/drowsiness"
          element={<DrowsinessPage setIndicator={setIndicator} />}
        />
        <Route
          path="/:uuid/fatigue"
          element={<FatiguePage setIndicator={setIndicator} />}
        />
        <Route
          path="/:uuid/sleep_characteristics_detail"
          element={
            <SleepCharacteristicsDetailPage setIndicator={setIndicator} />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions"
          element={
            <LifestyleAndHealthConditionsPage setIndicator={setIndicator} />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions/alcohol"
          element={
            <LifestyleAndHealthConditionAlcoholPage
              setIndicator={setIndicator}
            />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions/caffeine"
          element={
            <LifestyleAndHealthConditionCaffeinePage
              setIndicator={setIndicator}
            />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions/exercise"
          element={
            <LifestyleAndHealthConditionExercisePage
              setIndicator={setIndicator}
            />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions/nightmeal"
          element={
            <LifestyleAndHealthConditionNightmealPage
              setIndicator={setIndicator}
            />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions/nap"
          element={
            <LifestyleAndHealthConditionNapPage setIndicator={setIndicator} />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions/bath"
          element={
            <LifestyleAndHealthConditionBathPage setIndicator={setIndicator} />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions/bluelight"
          element={
            <LifestyleAndHealthConditionBluelightPage
              setIndicator={setIndicator}
            />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions/before_sleep_mind"
          element={
            <LifestyleAndHealthConditionBeforeSleepMindPage
              setIndicator={setIndicator}
            />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions/light"
          element={
            <LifestyleAndHealthConditionLightPage setIndicator={setIndicator} />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions/mind_condition"
          element={
            <LifestyleAndHealthConditionMindConditionPage
              setIndicator={setIndicator}
            />
          }
        />
        <Route
          path="/:uuid/lifestyle_and_health_conditions/body_condition"
          element={
            <LifestyleAndHealthConditionBodyConditionPage
              setIndicator={setIndicator}
            />
          }
        />
      </Routes>
    </>
  );
}
