import { Rating, RatingProps } from "@mui/material";

/**
 * {@link ReportSleepRating} のプロパティです。
 * @see ReportSleepRating
 */
export type ReportSleepRatingProps = Omit<
  RatingProps,
  "max" | "readonly" | "precision" | "value"
> & {
  score: number;
};
/**
 * Lite のレポートの評価を表示します。
 * 指定する score は星の数 × 60 の値を指定する必要があります。
 *
 * @param param0 {@link ReportSleepRatingProps}
 * @returns 評価
 */
export function ReportSleepRating({ score, ...props }: ReportSleepRatingProps) {
  return (
    <Rating {...props} value={score / 60} precision={0.1} max={3} readOnly />
  );
}
