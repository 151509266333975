import {
  SurveyInfo as SurveyInfoObject,
  Target as TargetObject,
} from "@/sleep_compass_lite/data_transfer_objects/target/SurveyInfo";
import { UserName } from "@/sleep_compass_lite/domain_models/target/UserName";

/**
 * 対象者情報ドメインモデル
 */
class Target {
  constructor(
    /** 対象者の氏名 */
    readonly userName: UserName
  ) {}

  /**
   * Targetオブジェクト{@link TargetObject DTO}からSurveyInfoモデルを生成する
   * @param obj Targetオブジェクト
   * @returns Targetモデルのインスタンス
   */
  static create(obj: TargetObject) {
    return new Target(new UserName(obj.first_name, obj.last_name));
  }
}

type SurveyStatus =
  | "NotRegistered"
  | "Registered"
  | "PrimaryInterviewCompleted"
  | "DailyInterviewCompletedOne"
  | "DailyInterviewCompletedTwo"
  | "DailyInterviewCompletedAll"
  | "ReportSent"
  | "AttitudeEnqueteCompleted";

/**
 * サーベイ情報ドメインモデル
 */
export class SurveyInfo {
  constructor(
    /** サーベイ情報のUUID */
    readonly uid: string,
    /** サーベイのステータス */
    readonly status: SurveyStatus,
    /** 対象者情報 */
    readonly target: Target
  ) {}

  /**
   * SurveyInfoオブジェクト{@link SurveyInfoObject DTO}からSurveyInfoモデルを生成する
   * @param obj SurveyInfoオブジェクト
   * @returns SurveyInfoモデルのインスタンス
   */
  static create(obj: SurveyInfoObject) {
    return new SurveyInfo(
      obj.unique_id,
      SurveyInfo.convertToSurveyStatus(obj.status),
      Target.create(obj.target)
    );
  }

  static convertToSurveyStatus(status: number): SurveyStatus {
    switch (status) {
      case 100:
        return "NotRegistered";
      case 200:
        return "Registered";
      case 300:
        return "PrimaryInterviewCompleted";
      case 400:
        return "DailyInterviewCompletedOne";
      case 500:
        return "DailyInterviewCompletedTwo";
      case 600:
        return "DailyInterviewCompletedAll";
      case 700:
        return "ReportSent";
      case 800:
        return "AttitudeEnqueteCompleted";
      default:
        throw Error(`unknown status(${status}) is assigned`);
    }
  }
}
