import { InterviewInfo as InterviewInfoObject } from "@/sleep_compass_lite/data_transfer_objects/target/InterviewInfo";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { InterviewInfo } from "@/sleep_compass_lite/domain_models/target/InterviewInfo";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export { InterviewInfo };

/** useLitePrimaryInterviewInfo getInfo 型宣言 */
type GetPrimaryInterviewInfo = (
  surveyInfoUid: string
) => Promise<InterviewInfo>;

/***
 * 事前問診情報を取得するクロージャーを返すカスタムフック。カスタムフックで実装している理由は、useAxios(カスタムフック)を利用するため。
 */
export function useLitePrimaryInterviewInfo() {
  const axios = useAxios();

  /**
   * 事前問診情報の取得
   * @param surveyInfoUid サーベイ情報のUUID
   * @returns 事前問診情報 {@link InterviewInfo}
   */
  const getInfo: GetPrimaryInterviewInfo = useCallback(
    async (surveyInfoUid) => {
      try {
        const response: AxiosResponse<InterviewInfoObject> = await axios.get(
          `/api/lite/primary_interview_info/${surveyInfoUid}`
        );
        return InterviewInfo.create(response.data);
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  return { getInfo };
}

/**
 * URLパラメータから取得した surveyInfoUid を使用して {@link PrimaryInterviewInfo 事前問診情報} を取得するカスタムフック
 */
export function usePrimaryInterviewInfo(): [
  InterviewInfo | undefined,
  unknown
] {
  const { surveyInfoUid } = useParams();
  const { getInfo } = useLitePrimaryInterviewInfo();
  const [primaryInterviewInfo, setPrimaryInterviewInfo] =
    useState<InterviewInfo>();
  const [primaryInterviewError, setPrimaryInterviewError] = useState<unknown>();

  useEffect(() => {
    (async () => {
      if (surveyInfoUid == null) return;
      try {
        const interviewInfo = await getInfo(surveyInfoUid);
        setPrimaryInterviewInfo(interviewInfo);
      } catch (e: unknown) {
        setPrimaryInterviewError(e);
      }
    })();
  }, [getInfo, surveyInfoUid]);

  return [primaryInterviewInfo, primaryInterviewError];
}
