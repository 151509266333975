import { EditOutlined } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { ReactNode } from "react";

/**
 * 編集ボタン
 */
type EditButtonProps = {
  children?: ReactNode;
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  disabled?: ButtonProps["disabled"];
  onClick: ButtonProps["onClick"];
};
export function EditButton({
  children,
  variant,
  size,
  disabled,
  onClick,
}: EditButtonProps) {
  return (
    <Button
      startIcon={<EditOutlined />}
      variant={variant || "contained"}
      size={size || "small"}
      disabled={disabled}
      onClick={onClick}
    >
      {children || "編集"}
    </Button>
  );
}
