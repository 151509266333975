import {
  SleepCompassLogo,
  imageColorType,
} from "@/components/sleep_checkup_v1/SleepCompassLogo";
import { ArrowBack } from "@mui/icons-material";
import { AppBar, Box, Button, Divider, Stack } from "@mui/material";
import { Property } from "csstype";
import { ReactNode } from "react";

type SCAppBarColor = "normal" | "reverse";

class ColorSet {
  appBarBackground: Property.BackgroundColor;
  imageColorType: imageColorType;

  constructor(
    appBarBackground: Property.BackgroundColor,
    imageColorType: imageColorType
  ) {
    this.appBarBackground = appBarBackground;
    this.imageColorType = imageColorType;
  }
}

const APPBAR_COLORSET: Record<SCAppBarColor, ColorSet> = {
  normal: new ColorSet("common.white", "normal"),
  reverse: new ColorSet("primary.dark", "white"),
};

/**
 * 受診者ユーザー用のヘッダ
 */
type SCAppBarProps = {
  height: number;
  color?: SCAppBarColor;
  logo?: ReactNode;
  menuButton?: ReactNode;
  /**
   * 戻るボタンが押下された際に実行されるハンドラー
   */
  onClickBack?(): void;
};
export function SCAppBar({
  height,
  color,
  logo,
  menuButton,
  onClickBack,
}: SCAppBarProps) {
  const c = color ?? "normal";
  const colorSet = APPBAR_COLORSET[c];
  const logoImage = logo ?? (
    <SleepCompassLogo width={170} imageColor={colorSet.imageColorType} />
  );

  return (
    <>
      <AppBar sx={{ backgroundColor: colorSet.appBarBackground }} elevation={0}>
        {onClickBack != null && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "absolute",
              top: 4,
              left: 16,
              height: 48,
              width: 48,
            }}
          >
            <Button
              variant="text"
              startIcon={<ArrowBack />}
              onClick={() => onClickBack()}
              sx={{ whiteSpace: "nowrap" }}
            >
              戻る
            </Button>
          </Stack>
        )}
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: height }}
        >
          {logoImage}
        </Stack>
        {menuButton != null && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "absolute",
              top: 4,
              right: 4,
              height: 48,
              width: 48,
            }}
          >
            {menuButton}
          </Stack>
        )}
        {c === "normal" && <Divider />}
      </AppBar>
      {/* Note: BoxはAppBarの下にコンポーネントが入り込むのを防止するためのスペーサー。AppBarと同じ高さにする。 */}
      <Box sx={{ height: height }} />
    </>
  );
}
