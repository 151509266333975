import { Box } from "@mui/material";

type ImageBoxProps = {
  src: string;
  alt: string;
};
export function ImageBox({ src, alt }: ImageBoxProps) {
  return (
    <Box display="flex" width={1} justifyContent="center">
      <Box
        component="img"
        src={src}
        alt={alt}
        loading="lazy"
        sx={{
          width: "100%",
          height: "auto",
        }}
      />
    </Box>
  );
}
