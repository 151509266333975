import { Question, QuestionGroup } from "@/components/examinee/interview/Types";

type SingleQuestionGroupType =
  | "FreeText"
  | "NumberChoice"
  | "AppendableNumberChoice"
  | "SelectOne"
  | "SelectMany"
  | "SkippableNumberChoice";

export class SingleQuestionGroup {
  private questionGroup: QuestionGroup;
  private isLeadingQuestion: boolean;

  get question(): Question {
    return this.questionGroup.questions[0];
  }

  get leadText(): string | null {
    return this.isLeadingQuestion
      ? this.questionGroup.question_group_text
      : null;
  }

  get text(): string {
    return this.question.question_text;
  }

  get detail(): string {
    return this.question.question_detail;
  }

  get type(): SingleQuestionGroupType {
    switch (this.question.selection_type) {
      case "1/N":
        return "SelectOne";
      case "N/N":
        if (
          this.question.choices.length === 1 &&
          this.question.choices.every(
            (c) => c.choice_type === "LABEL_WITH_NUMBER_CHOICE"
          )
        ) {
          return "SkippableNumberChoice";
        } else {
          return "SelectMany";
        }
      case "1/1":
        if (
          this.question.choices.every(
            (c) => c.choice_type === "LABEL_WITH_FREE_TEXT"
          )
        ) {
          return "FreeText";
        } else if (
          this.question.choices.every(
            (c) => c.choice_type === "LABEL_WITH_NUMBER_CHOICE"
          )
        ) {
          if (this.question.choices.every((c) => c.appendable)) {
            return "AppendableNumberChoice";
          } else {
            return "NumberChoice";
          }
        }
        throw new Error(`${this.question.selection_type} is not implemented`);
      default:
        throw new Error(`${this.question.selection_type} is not implemented`);
    }
  }

  constructor(questionGroup: QuestionGroup, prev?: QuestionGroup) {
    this.questionGroup = questionGroup;
    this.isLeadingQuestion = prev == null ? true : prev.id !== questionGroup.id;
  }
}
