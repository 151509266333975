import { BackdropIndicator } from "@/components/common/BackdropIndicator";
import { clone } from "@/components/common/ObjectService";
import {
  InterviewResultBreadcrumbType,
  InterviewResultBreadcrumbs,
  PrimaryInterviewResultLink,
} from "@/components/sleep_checkup_v1/BreadcrumbLink";
import {
  Answer,
  InterviewResult,
  TabInfo,
} from "@/components/sleep_checkup_v1/InterviewResult";
import { PAGE_TITLE } from "@/components/sleep_checkup_v1/Path";
import {
  getPrimaryInterviewResult,
  getSleepCheckupInfoById,
} from "@/components/sleep_checkup_v1/SleepCheckupInfoAPI";
import { SleepCheckupInfo } from "@/components/sleep_checkup_v1/Types";
import { Stack } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const TAB_NAMES = [
  "基本情報",
  "睡眠の状態",
  "生活習慣",
  "こころの状態",
  "ストレスの状態",
];
const TAB_NAMES_INDEX = new Map(TAB_NAMES.map((s, i) => [s, i]));
const getTabName = (obj: any): string => {
  return obj.category;
}; // APIレスポンスからタブ名への変換

type PrimaryInterviewResultProps = {
  minWidth: number;
  breadcrumbType?: InterviewResultBreadcrumbType;
};
export function PrimaryInterviewResult({
  minWidth,
  breadcrumbType = "detail",
}: PrimaryInterviewResultProps) {
  const location = useLocation();
  const params = useParams();

  const [tabs, setTabs] = useState<TabInfo[]>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);
  const [sleepCheckupInfo, setSleepCheckupInfo] =
    useState<SleepCheckupInfo | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  const toShowBackdrop =
    !initialized && // 初期化が完了していない場合はバックドロップを表示する
    !isError; // ただし、エラーになった場合はバックドロップを表示しない

  const failed = (err: any) => {
    // 睡眠健診情報や問診の回答取得に失敗したケース等
    console.error(err);
    setIsError(true);
    setInitialized(true);
  };

  useEffect(() => {
    const getInfo = async (): Promise<SleepCheckupInfo> => {
      if (location.state) {
        // sleepCheckupInfoが前画面から渡されてきた場合はそれを使う
        const info = clone(location.state as SleepCheckupInfo);
        return info;
      }

      // sleepCheckupInfoが未取得の場合は取得を試みる
      if (!params.sleepCheckupId) {
        throw new Error("params.sleepCheckupId is null");
      }
      const res: any = await getSleepCheckupInfoById(params.sleepCheckupId);
      const info = res.data as SleepCheckupInfo;
      return info;
    };

    const initialize = async () => {
      const info: SleepCheckupInfo = await getInfo();
      setSleepCheckupInfo(info);

      const res: any = await getPrimaryInterviewResult(info);

      // 情報が出揃ったらタブ毎に掲載する情報を構築する
      const newTabs: TabInfo[] = TAB_NAMES.map((s) => {
        return { tabName: s, items: [] };
      });
      let minTabIndex = newTabs.length; // 中身が空ではないタブの中で最小インデックスのもの
      for (const x of res.data as Answer[]) {
        const tabIndex = TAB_NAMES_INDEX.get(getTabName(x));
        if (tabIndex !== undefined) {
          newTabs[tabIndex].items.push(x);
          minTabIndex = Math.min(minTabIndex, tabIndex);
        }
      }
      setTabs(newTabs);
      if (minTabIndex < newTabs.length) {
        setCurrentTabIndex(minTabIndex);
      }

      setInitialized(true);
    };

    initialize().catch(failed);
  }, [location.state, params.sleepCheckupId]);

  // タブ遷移時の処理
  const handleTabChange = (
    event: SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setCurrentTabIndex(value);
  };

  return (
    <>
      <BackdropIndicator open={toShowBackdrop} />
      <Stack spacing={6} sx={{ mx: 10, my: 6, minWidth: minWidth }}>
        <PrimaryInterviewResultBreadcrumbs
          sleepCheckupId={sleepCheckupInfo?.id ?? ""}
          breadcrumbType={breadcrumbType}
        />
        {sleepCheckupInfo && (
          <InterviewResult
            title={PAGE_TITLE["PrimaryInterviewResult"]}
            sleepCheckupInfo={sleepCheckupInfo}
            tabInfoList={tabs}
            currentTabIndex={currentTabIndex}
            disabled={!initialized} // 初期化が完了するまでは「現在表示できる項目がありません」というエラーメッセージを表示しない
            handleTabChange={handleTabChange}
          />
        )}
      </Stack>
    </>
  );
}

type PrimaryInterviewResultBreadcrumbsProps = {
  sleepCheckupId: string;
  breadcrumbType: InterviewResultBreadcrumbType;
};

function PrimaryInterviewResultBreadcrumbs({
  sleepCheckupId,
  breadcrumbType,
}: PrimaryInterviewResultBreadcrumbsProps) {
  return (
    <InterviewResultBreadcrumbs
      sleepCheckupId={sleepCheckupId}
      breadcrumbType={breadcrumbType}
    >
      <PrimaryInterviewResultLink
        sleepCheckupId={sleepCheckupId}
        disabled={true}
      />
    </InterviewResultBreadcrumbs>
  );
}
