import { Stack, Typography } from "@mui/material";
import { ReportSleepRating } from "./ReportSleepRating";

type SleepDaytimeReportTitleType =
  | "quantity"
  | "quality"
  | "rhythm"
  | "sleepiness"
  | "tiredness"
  | "performance";

const CONFIG: Readonly<
  Record<
    SleepDaytimeReportTitleType,
    { title: string; src: string; alt: string; width: string; height: string }
  >
> = {
  quantity: {
    title: "睡眠の長さ",
    src: "/img/image_lite_report_sleep_condition_quantity.svg",
    alt: "sleep quantity",
    width: "21.67px",
    height: "20px",
  },
  quality: {
    title: "睡眠の質",
    src: "/img/image_lite_report_sleep_condition_quality.svg",
    alt: "sleep quality",
    width: "23.058px",
    height: "20px",
  },
  rhythm: {
    title: "睡眠のリズム",
    src: "/img/image_lite_report_sleep_condition_rhythm.svg",
    alt: "sleep rhythm",
    width: "17.163px",
    height: "20px",
  },
  sleepiness: {
    title: "日中の眠気",
    src: "/img/image_lite_report_daytime_condition_sleepiness.svg",
    alt: "daytime sleepiness",
    width: "30px",
    height: "30px",
  },
  tiredness: {
    title: "日中の疲労感",
    src: "/img/image_lite_report_daytime_condition_tiredness.svg",
    alt: "daytime tiredness",
    width: "30px",
    height: "30px",
  },
  performance: {
    title: "パフォーマンス",
    src: "/img/image_lite_report_daytime_condition_performance.svg",
    alt: "performance",
    width: "30px",
    height: "30px",
  },
};
type SleepDaytimeReportTitleProps = {
  /** レポートのセクション種別 */
  type: SleepDaytimeReportTitleType;
  /** 評価値（星の数×60） */
  score: number;
};
/**
 * レポートのセクションのタイトルを表示します。
 *
 * @param param0 {@link SleepDaytimeReportTitleProps}
 * @returns レポートのタイトル
 */
export function SleepDaytimeReportTitle({
  type,
  score,
}: SleepDaytimeReportTitleProps) {
  const { title, src, alt, width, height } = CONFIG[type];
  return (
    <Stack direction="row">
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <img src={src} alt={alt} loading="lazy" style={{ width, height }} />
        <Typography variant="h6" sx={{ pl: 2 }}>
          {title}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{ marginLeft: "auto", alignItems: "center", gap: 1 }}
      >
        <Typography variant="caption">評価：</Typography>
        <ReportSleepRating score={score} />
      </Stack>
    </Stack>
  );
}
