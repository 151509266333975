import { getEncodedCurrentPath } from "@/components/common/LocationService";
import { NonFieldError } from "@/components/common/SCAlert";
import { Copyright } from "@/components/examinee/Copyright";
import {
  createInterviewAnswer,
  getNewInterviewAnswer,
} from "@/components/examinee/interview/AnswerService";
import { getScreen } from "@/components/examinee/interview/InterviewAPI";
import { InterviewAppBar } from "@/components/examinee/interview/InterviewAppBar";
import { PageSummary } from "@/components/examinee/interview/PageSummary";
import { ProgressBar } from "@/components/examinee/interview/ProgressBar";
import { SingleQuestionGroupPage } from "@/components/examinee/interview/SingleQuestionGroupPage";
import {
  AnswerChangeHandler,
  InterviewAnswer,
  InterviewEntry,
  Screen as QuestionScreen,
} from "@/components/examinee/interview/Types";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import { Box, Container, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Location,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

// Error messages
const MSG_0001 =
  "エラーが発生しました。\nリマインドメールに記載されたURLから、問診ページに再アクセスしてください。";
const MSG_0002 = "エラーが発生しました。しばらく経ってから、再試行してください。"

type PostAnswerHandler = (
  params: Readonly<Params<string>>,
  interviewAnswer: InterviewAnswer,
  currentPath: string
) => Promise<any>;

type GetNextScreenHandler = (
  entry: InterviewEntry,
  params: Readonly<Params<string>>
) => string;

type GetNextPageHandler = (params: Readonly<Params<string>>) => string;

type InterviewPageState = {
  prevScreen: QuestionScreen | null;
};

type PageSummaryCreator = (questionScreen: QuestionScreen) => PageSummary;

type InterviewPageProps = {
  pageProps: ExamineePageProps;
  context: InterviewContext;
};
type InterviewContext = {
  pageSummaryCreator: PageSummaryCreator;
  postAnswer: PostAnswerHandler;
  getNextScreen: GetNextScreenHandler;
  getNextPage: GetNextPageHandler;
};
export function Interview({ pageProps, context }: InterviewPageProps) {
  const [pageSummary, setPageSummary] = useState<PageSummary | null>(null);
  const [answer, setAnswer] = useState<InterviewAnswer | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const FOOTER_HEIGHT = "85px";
  const BACKGROUND_COLOR = "background.default";

  useEffect(() => {
    if (params.interviewId == null || params.screen == null) {
      throw new Error(
        `invalid params, ${params.interviewId}, ${params.screen}`
      );
    }

    // getScreenで質問を切り替えるが、その前に、直前の質問へのエラーメッセージ表示を消去する
    setErrorMessage("");

    getScreen(params.screen, getEncodedCurrentPath(location))
      .then((res) => {
        const questionScreen = res.data as QuestionScreen;

        setPageSummary(context.pageSummaryCreator(questionScreen));
        setAnswer(
          createInterviewAnswer(parseInt(params.interviewId!), questionScreen)
        );
      })
      .catch((err) => {
        setErrorMessage(MSG_0001);
      });
  }, [params.interviewId, params.screen, location, context]);

  const postAnswer = (answer: InterviewAnswer) => {
    if (params.sleepCheckupId == null || params.interviewId == null) {
      throw new Error(
        `invalid params, ${params.sleepCheckupId}, ${params.interviewId}`
      );
    }

    context
      .postAnswer(params, answer, getEncodedCurrentPath(location))
      .then((res) => {
        const interviewEntry = res.data as InterviewEntry;

        const getNextPath = (interviewEntry: InterviewEntry) => {
          // Note: 問診の終了判定
          if (interviewEntry.screen.id != null) {
            // 終了していなかったら、次の問題へ
            return context.getNextScreen(res.data as InterviewEntry, params);
          } else {
            // 終了していたら、次の画面へ
            return context.getNextPage(params);
          }
        };

        const path = getNextPath(interviewEntry);

        navigate(path, {
          state: { prevScreen: pageSummary?.screen },
        });
      })
      .catch((err) => {
        setErrorMessage(MSG_0002);
      });
  };

  const handleBack = () => {
    navigate(-1);
  };

  // 質問への回答が変更されたとき
  const onChange: AnswerChangeHandler = (questionAnswer) => {
    if (answer == null) {
      throw new Error("answer is null");
    }

    setAnswer(getNewInterviewAnswer(answer, questionAnswer));
  };

  const onNext = () => {
    if (answer == null) {
      throw new Error("answer is null");
    }

    postAnswer(answer);
  };

  const onSelect: AnswerChangeHandler = (questionAnswer) => {
    if (answer == null) {
      throw new Error("answer is null");
    }

    postAnswer(getNewInterviewAnswer(answer, questionAnswer));
  };

  const state =
    location.state != null ? (location.state as InterviewPageState) : undefined;

  return (
    <Box
      sx={{
        minHeight: "100vh",
        position: "relative",
        backgroundColor: BACKGROUND_COLOR,
      }}
    >
      <InterviewAppBar
        height={pageProps.headerHeight}
        title={pageSummary?.pageTitle ?? ""}
        onClickBack={canBack(location) ? handleBack : undefined}
      />
      <NonFieldError>{errorMessage}</NonFieldError>
      <Container
        maxWidth="sm"
        sx={{
          p: 0,
          pt: 0,
          pl: 0,
          pr: 0,
          pb: FOOTER_HEIGHT,
        }}
      >
        {answer != null &&
          pageSummary != null &&
          pageSummary.pageType === "Single" && (
            <SingleQuestionGroupPage
              progress={<ProgressBar value={pageSummary.progress} />}
              questionGroup={pageSummary.questionGroup}
              questionGroupAnswer={answer.screen.question_group}
              prevQuestionGroup={state?.prevScreen?.question_group}
              backgroundColor={BACKGROUND_COLOR}
              onChange={onChange}
              onNext={onNext}
              onSelect={onSelect}
            />
          )}
      </Container>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: FOOTER_HEIGHT,
          backgroundColor: BACKGROUND_COLOR,
        }}
      >
        <Copyright />
      </Stack>
    </Box>
  );
}

export function canBack(location: Location): boolean {
  // このページ間で画面遷移した場合には、location.state.prevScreenに直前のScreenEntryが格納されている。
  // 理想は、BrowserのHistory Stackから一つ前のURLを取得し、バックの可否を判定したいが、URLにアクセスできないので、location.stateを使う
  if (location.state == null) {
    return false;
  }

  const state = location.state as InterviewPageState;
  if (state.prevScreen == null) {
    return false;
  }

  return true;
}
