/**
 * オブジェクトのプロパティにセットされている値が等しいか否かを返す
 * @param lhs 比較対象オブジェクト
 * @param rhs 比較対象オブジェクト
 * @returns 等しければtrue,そうでなければfalse
 */
export function equals(lhs: any, rhs: any): boolean {
  for (const [key, value] of Object.entries(lhs)) {
    if (rhs[key] !== value) {
      return false;
    }
  }

  return true;
}

/**
 * Objectのコピーを返す。引数のoriginalのプロパティに、プリミティブ型以外（e.g. Object, Array）が含まれると、一部、シャローコピーになるので、注意。
 * @param org コピー元
 * @returns コピー元のフィールドがコピーされた新しいオブジェクト
 */
export function clone<T>(original: T): T {
  return { ...original };
}
