import { Button, Typography, TypographyProps } from "@mui/material";
import { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

/**
 * リンクテキスト。react-router-domのLinkを使っているため、外部サイトへのリンクはできないので注意。
 */
type SCLinkProps = {
  children: ReactNode;
  variant: TypographyProps["variant"];
  to: LinkProps["to"];
  state?: LinkProps["state"];
  sx?: TypographyProps["sx"];
};
export function SCLink({ children, variant, to, state, sx }: SCLinkProps) {
  const typographySx = { textDecoration: "underline", ...sx };

  return (
    <Button variant="text" component={Link} to={to} state={state} sx={{ p: 0 }}>
      <Typography variant={variant} sx={typographySx}>
        {children}
      </Typography>
    </Button>
  );
}
