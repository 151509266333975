import {
  InputBaseComponentProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ReactNode, useState } from "react";

type TextFormProps = {
  title?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  inputProps?: InputBaseComponentProps;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validator?: () => string[] | null; // フォーカスを失った時に実行されるvalidation
  helperText?: string | ReactNode;
  type?: string; // TextFieldにそのまま渡す
  givenErrors?: string[] | null; // 親から渡されるエラー
};
export const TextForm = ({
  title,
  placeholder,
  value,
  disabled,
  inputProps,
  onChange,
  validator,
  helperText,
  type,
  givenErrors,
}: TextFormProps) => {
  // errorsはvalidator()を実行した結果得られるエラー
  const [errors, setErrors] = useState<string[] | null>(null);

  const mergedErrors = [...(givenErrors || []), ...(errors || [])];

  return (
    <Stack spacing={1}>
      {title && <Typography variant="subtitle2">{title}</Typography>}
      <TextField
        error={0 < mergedErrors.length}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        inputProps={inputProps}
        onChange={onChange}
        onBlur={() => {
          // validator()はフォーカスを失ったタイミングで実行される
          if (validator) {
            setErrors(validator());
          }
        }}
        type={type}
      />
      {0 < mergedErrors.length &&
        mergedErrors.map((error) => {
          return (
            <Typography
              key={error}
              variant="body2"
              sx={{ px: 3, color: "error.main" }}
            >
              {error}
            </Typography>
          );
        })}

      {/* helperTextが文字列の場合 */}
      {helperText && typeof helperText === "string" && (
        <Typography variant="body2" sx={{ px: 3, color: "text.secondary" }}>
          {/* FIXME: variantをhelperTextに変更したい */}
          {helperText}
        </Typography>
      )}

      {/* helperTextがReactNodeの場合 */}
      {helperText && typeof helperText !== "string" && helperText}
    </Stack>
  );
};
