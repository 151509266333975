import { Typography, TypographyProps } from "@mui/material";

/**
 * {@link ReportCardComment} のデフォルトのスタイル
 */
export const DEFAULT_STYLE = { color: "text.secondary" };

/**
 * {@link ReportCardComment} のプロパティ
 */
export type ReportCardCommentProps = TypographyProps;

/**
 * Lite のカードコメントを表示します。
 *
 * @see Typography
 * @param props {@link ReportCardCommentProps}
 * @returns Lite のカードコメント
 */
export function ReportCardComment(props: ReportCardCommentProps) {
  return <Typography variant="body1" sx={DEFAULT_STYLE} {...props} />;
}
