import {
  DiseaseRiskCommonProps,
  RecommendationExistenceInfo,
  hasDiseaseRiskOption
} from "./commons";
import AthensInsomniaScalePage from "./medical_facility_report/AthensInsomniaScale";
import ReferenceInfoPage from "./medical_facility_report/ReferenceInfo";
import StopBangPage from "./medical_facility_report/StopBang";

/**
 * {@link MedicalFacilityReport} 用のプロパティ
 */
export interface MedicalFacilityReportProps extends DiseaseRiskCommonProps {
  /** {@link RecommendationExistenceInfo} */
  existence: RecommendationExistenceInfo;
}
/**
 * 医療機関用レポート
 *
 * @param param0 {@link MedicalFacilityReportProps}
 * @returns 医療機関用レポート
 */
export default function MedicalFacilityReport({
  data,
  existence,
}: MedicalFacilityReportProps) {
  // 疾患リスクのオプションが存在しない場合、または疾患リスクがない場合、レポートを表示しない。
  if (!hasDiseaseRiskOption(existence) || data.disease_risks == null)
    return <></>;

  const opt = existence.recommendation_option;

  const maxPage = 1 + (opt.SAS ? 1 : 0) + (opt.insomnia ? 1 : 0);
  const pages = [
    <ReferenceInfoPage
      key={0}
      data={data}
      existence={existence}
      maxPage={maxPage}
    />,
  ];
  // オプションにSASが含まれる場合、StopBangの表示を行う。
  if (opt.SAS) {
    pages.push(
      <StopBangPage
        data={data}
        key={pages.length}
        maxPage={maxPage}
        page={pages.length + 1}
      />
    );
  }
  // オプションに不眠症が含まれる場合、アテナ不眠尺度の表示を行う。
  if (opt.insomnia) {
    pages.push(
      <AthensInsomniaScalePage
        data={data}
        key={pages.length}
        maxPage={maxPage}
        page={pages.length + 1}
      />
    );
  }
  return <>{pages}</>;
}
