import {
  callRefreshTokenAPI,
  CognitoAuthenticationResultStore,
  CognitoRefreshTokenStore,
  signOut,
  SleepCheckupUserStore,
} from "@/utils/auth";
import constants from "@/utils/constants";
import Axios from "axios";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { usePageUserType } from "./router";

export function getAxios(baseUrl: string) {
  const axios = Axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (["dev", "test"].includes(process.env.REACT_APP_ENV ?? "")) {
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  }
  return axios;
}

export function getAxiosWithInterceptors(baseUrl: string, signInUrl: string) {
  const axios = getAxios(baseUrl);
  axios.interceptors.request.use((request) => {
    const CognitoAuthenticationResult =
      CognitoAuthenticationResultStore.getItem();
    if (CognitoAuthenticationResult != null && request?.headers) {
      request.headers[
        "Authorization"
      ] = `Bearer ${CognitoAuthenticationResult.IdToken}`;
    }
    return request;
  });
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!error.config) {
        return Promise.reject(error);
      }
      if (error.response?.status !== 401) {
        // 401エラーではない場合
        return Promise.reject(error);
      }
      const cognitoRefreshTokenStore = CognitoRefreshTokenStore.getItem();
      const refreshToken = cognitoRefreshTokenStore?.RefreshToken;
      if (refreshToken == null) {
        // refresh tokenが存在しなかった場合
        return signOut(signInUrl);
      }

      try {
        const sleepCheckupUserStore = SleepCheckupUserStore.getItem();

        if (sleepCheckupUserStore?.username) {
          let username = null;
          let examinee_username = null;
          if (
            sleepCheckupUserStore?.user_type === constants.USERTYPE_EXAMINEE
          ) {
            examinee_username = sleepCheckupUserStore?.username;
          } else {
            username = sleepCheckupUserStore?.username;
          }
          const res = await callRefreshTokenAPI(
            getAxios(baseUrl),
            username,
            examinee_username,
            refreshToken
          );
          CognitoAuthenticationResultStore.setItem(
            res.data.AuthenticationResult
          );
          error.config.headers["Authorization"] = `Bearer ${
            CognitoAuthenticationResultStore.getItem()?.IdToken
          }`;
          return Axios.request(error.config);
        }
        return signOut(signInUrl);
      } catch (e) {
        return signOut(signInUrl);
      }
    }
  );
  return axios;
}

export function getFrontendServerURL() {
  return process.env.REACT_APP_FRONTEND_SERVER ?? "";
}

export function getApiServerUrl() {
  return process.env.REACT_APP_API_SERVER ?? "";
}

export function getFacilityApiServerUrl() {
  const apiServerURL = getApiServerUrl();
  // Note: URLで施設コードが指定されていないときは、施設コードなしのURLを返す
  const frontendServerURL = `${window.location.protocol}//${window.location.host}`;
  if (frontendServerURL === getFrontendServerURL()) {
    return apiServerURL;
  }
  const facilityCode = window.location.hostname.split(/\./)[0];
  return getServerUrl(apiServerURL, facilityCode);
}

function getServerUrl(apiServerURL: string, facilityCode: string) {
  const url = new URL(apiServerURL);
  let facilityUrl = `${url.protocol}//${facilityCode}.admin.${url.host}`;
  if (url.pathname !== "/") {
    // Note: 末尾の/はAPI呼び出し時につけるので、ここではつけない
    facilityUrl += url.pathname;
  }
  return facilityUrl;
}

export const facilityAxios = (() => {
  const axios = getAxiosWithInterceptors(
    getFacilityApiServerUrl(),
    constants.SIGNIN_URL_MEDICAL_FACILITY_USER
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 403) {
        // 403 Forbiddenの場合、signinへ遷移
        return signOut(constants.SIGNIN_URL_MEDICAL_FACILITY_USER);
      }
      return Promise.reject(error);
    }
  );
  return axios;
})();

/**
 * {@link useLocation} を使用して施設ユーザー用の {@link axios} を取得します。
 *
 * @returns AxiosInstance
 */
export function useFacilityAxios() {
  const location = useLocation();
  const signInUrl = `${
    constants.SIGNIN_URL_MEDICAL_FACILITY_USER
  }?next=${encodeURIComponent(location.pathname)}`;
  const axios = useMemo(
    () => getAxiosWithInterceptors(getFacilityApiServerUrl(), signInUrl),
    [signInUrl]
  );
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status >= 500) {
        return signOut(signInUrl);
      }
      return Promise.reject(error);
    }
  );
  return axios;
}

export const accelStarsAxios = getAxiosWithInterceptors(
  getApiServerUrl(),
  constants.SIGNIN_URL_ACCELSTARS_USER
);
export function getExamineeAxios(currentPath: string) {
  return getAxiosWithInterceptors(
    getApiServerUrl(),
    `${constants.SIGNIN_URL_EXAMINEE}?next=${currentPath}`
  );
}

/**
 * {@link useLocation} を使用して受診者用の {@link axios} を取得します。
 *
 * @returns AxiosInstance
 */
export function useExamineeAxios() {
  const location = useLocation();
  const axios = useMemo(
    () =>
      getExamineeAxios(
        encodeURIComponent(`${location.pathname}${location.search}`)
      ),
    [location]
  );
  return axios;
}

/**
 * {@link useLocation} を使用してACCELStars/施設社/受診者用の {@link axios} を取得します。
 * デフォルトは受診者用のAxiosInstance
 *
 * @returns AxiosInstance
 */
export function useAxios() {
  const userType = usePageUserType();
  const examineeAxios = useExamineeAxios();
  const facilityAxios = useFacilityAxios();

  const axios = useMemo(() => {
    if (userType === "accelstars") return accelStarsAxios;
    else if (userType === "facility") return facilityAxios;
    else return examineeAxios;
  }, [facilityAxios, examineeAxios, userType]);

  return axios;
}
