import { Group, SleepCheckupUserStore } from "@/utils/auth";

/**
 * 2つの文字列型の日付を「~」で連結して、期間を表す表現にして返す
 * @param from 開始日
 * @param to 終了日
 * @returns 「~」で連結された期間を表す文字列
 */
export function periodText(from: string, to: string): string {
  if (from === "" && to === "") {
    return "";
  }

  const f = from == null ? "" : displayFormat(from);
  const t = to == null ? "" : displayFormat(to);
  return `${f} ～ ${t}`;
}

/**
 * 文字列型の日付の形式をUI表示のための形式にして返す
 * @param date 文字列型の日付
 * @returns 「/」で区切られた形式の日付
 */
export function displayFormat(date: string): string {
  return date.replaceAll("-", "/");
}

/**
 * 文字列型の日付の形式をシステム内部で保持するための形式にして返す
 * @param date 文字列型の日付
 * @returns 「-」で区切られた形式の日付
 */
export function internalFormat(date: string): string {
  return date.replaceAll("/", "-");
}

/**
 * 事前問診の進捗
 */
export const PRIMARY_INTERVIEW_STATUS_MAPPING: ReadonlyMap<string, string> =
  new Map([
    ["unanswered", "未回答"],
    ["inprogress", "回答中"],
    ["done", "回答済み"],
  ]);

/**
 * 毎日問診の進捗
 */
export const DAILY_INTERVIEW_STATUS_MAPPING: ReadonlyMap<string, string> =
  new Map([
    ["unanswered", "未回答"],
    ["inprogress", "回答中"],
    ["done", "回答済み"],
    ["incompleted", "未達成"],
  ]);

/**
 * ユーザーが指定したグループを持っているか判定します。
 *
 * @param group {@link Group グループ}
 * @returns boolean グループを持っている場合、true を返却する
 */
export function hasGroup(group: Group) {
  const user = SleepCheckupUserStore.getItem();
  return user?.group_names.includes(group) === true;
}
