import { clone } from "@/components/common/ObjectService";
import {
  CHECKUP_PROJECT_FIELDS,
  CheckupProjectFields,
  setProjectName as _setProjectName,
} from "@/components/sleep_checkup_v1/CheckupProjectService";
import {
  CheckupProject,
  MedicalFacility,
  SleepCheckupInfo,
  SleepCheckupInfoEdit,
} from "@/components/sleep_checkup_v1/Types";
import { internalFormat } from "@/components/sleep_checkup_v1/util";

/**
 * SleepCheckupInfoEditのパラメータのうち、読み取り専用のパラメータ
 */
export type ReadOnlyInfoFields = "id";

/**
 * SleepCheckupInfoのパラメータのうち、編集可能なパラメータ
 */
export type InfoFields =
  | "medical_examinee_id_in_facility"
  | "medical_examinee_last_name"
  | "medical_examinee_first_name"
  | "medical_examinee_last_name_kana"
  | "medical_examinee_first_name_kana"
  | "device_id"
  | "corporate_name"
  | "department_name"
  | "medical_facility"
  | "project";

/**
 * SleepCheckupInfoに値をセットし、新しいSleepCheckupInfoオブジェクトを返す
 * @param key 対象パラメータ
 * @param value 対象パラメータにセットする値
 * @param params 対象のSleepCheckupInfo
 * @returns 引数paramsがコピー（ディープコピー）された新しいSleepCheckupInfoオブジェクト
 */
export function setValue(
  key: InfoFields | InfoDateStringFields,
  value: string | null,
  info: SleepCheckupInfo
): SleepCheckupInfo {
  const newInfo = clone(info) as any;
  newInfo[key] = value;
  return newInfo as SleepCheckupInfo;
}

/**
 * SleepCheckupInfoの値を取得する
 * @param key 対象パラメータ
 * @param params 対象のSleepCheckupInfo
 * @returns 引数keyで指定されたパラメータにセットされている値
 */
export function value(key: InfoEditFields, info: SleepCheckupInfo): string {
  const obj = info as any;
  return obj[key] ?? "";
}

/**
 * SleepCheckupInfoのパラメータのうち、日付で、型がstring型のパラメーター
 */
export type InfoDateStringFields =
  | "medical_examinee_birthday"
  | "date_device_sent"
  | "date_device_returned";

/**
 * SleepCheckupInfoのパラメータのうち、日付で、型がstring型のパラメーターの値をセットする
 * @param key 対象パラメータ
 * @param value 対象パラメータにセットする値
 * @param info 対象のSleepCheckupInfo
 * @returns 引数paramsがコピー（ディープコピー）された新しいSleepCheckupInfoオブジェクト
 */
export function setDateString(
  key: InfoDateStringFields,
  value: string | null,
  info: SleepCheckupInfo
): SleepCheckupInfo {
  // Note: ユーザーが入力する日付とAPIパラメータの日付はセパレーターが異なる。APIパラメータのセパレーターに変換する。
  const v = value ? internalFormat(value) : null;
  return setValue(key, v, info);
}

/**
 * SleepCheckupInfoのパラメータ
 */
export type InfoEditFields =
  | ReadOnlyInfoFields
  | InfoFields
  | InfoDateStringFields;

const READ_ONLY_FIELDS: ReadOnlyInfoFields[] = ["id"];

const INFO_FIELDS: InfoFields[] = [
  "medical_examinee_id_in_facility",
  "medical_examinee_last_name",
  "medical_examinee_first_name",
  "medical_examinee_last_name_kana",
  "medical_examinee_first_name_kana",
  "device_id",
  "corporate_name",
  "department_name",
  "medical_facility",
  "project",
];

const INFO_DATE_STRING_FIELDS: InfoDateStringFields[] = [
  "date_device_sent",
  "date_device_returned",
  "medical_examinee_birthday",
];

export type ErrorFields =
  | ReadOnlyInfoFields
  | InfoFields
  | InfoDateStringFields
  | "total_error";

const TOTAL_ERROR_FIELD = "total_error";

const INFO_EDIT_FIELDS: InfoEditFields[] = [
  ...READ_ONLY_FIELDS,
  ...INFO_FIELDS,
  ...INFO_DATE_STRING_FIELDS,
];

const ERROR_FIELDS: ErrorFields[] = [
  ...READ_ONLY_FIELDS,
  ...INFO_FIELDS,
  ...INFO_DATE_STRING_FIELDS,
  TOTAL_ERROR_FIELD,
];

/**
 * 初期化された新しいSleepCheckupInfoオブジェクトを返す
 */
export function createInfo(medicalFacility: MedicalFacility): SleepCheckupInfo {
  const info = new SleepCheckupInfo();
  info.medical_facility = parseInt(medicalFacility.id);
  return info;
}

/**
 * SleepCheckupInfoから作成されたSleepCheckupInfoEditオブジェクトを返す
 */
export function createInfoEdit(info: SleepCheckupInfo): SleepCheckupInfoEdit {
  const infoEdit = new SleepCheckupInfoEdit() as any;
  const infoOrg = info as any;

  for (const key of INFO_EDIT_FIELDS) {
    if (key === "medical_facility") {
      infoEdit[key] = infoOrg[key].toString();
    } else {
      infoEdit[key] = infoOrg[key];
    }
  }

  return infoEdit as SleepCheckupInfoEdit;
}

export type CheckupProjectErrorMessage = Map<CheckupProjectFields, string>;

export type InfoEditErrorMessage = Map<
  ErrorFields,
  string | CheckupProjectErrorMessage
>;

/**
 * SleepCheckupInfo編集APIからのエラーを保持するMapを生成し、返す
 * @param errorData エラー(APIのエラーレスポンス)
 * @returns SleepCheckupInfo編集APIからのエラーを保持するMap
 */
export function createErrorMessages(errorData: any): InfoEditErrorMessage {
  const messages = new Map<ErrorFields, string | CheckupProjectErrorMessage>();
  for (const key of ERROR_FIELDS) {
    switch (key) {
      case "project":
        const projectErrorMessage = createProjectErrorMessage(
          errorData != null ? errorData[key] : null
        );
        messages.set(key, projectErrorMessage);
        break;
      default:
        messages.set(key, getErrorMessage(errorData, key));
        break;
    }
  }

  return messages;
}

function createProjectErrorMessage(errorData: any): CheckupProjectErrorMessage {
  const messages = new Map<CheckupProjectFields, string>();
  for (const key of CHECKUP_PROJECT_FIELDS) {
    messages.set(key, getErrorMessage(errorData, key));
  }

  return messages;
}

function getErrorMessage(
  errorData: any,
  key: ErrorFields | CheckupProjectFields
): string {
  if (
    errorData == null ||
    errorData[key] == null ||
    errorData[key].length === 0
  ) {
    return "";
  }
  return errorData[key][0];
}

/**
 * SleepCheckupInfo編集処理で不明なエラーが起きたときに表示するエラーメッセージを返す
 * @returns 不明なエラー用のエラーメッセージ(Map)
 */
export function createUnknownErrorMessages(): InfoEditErrorMessage {
  return new Map<ErrorFields, string>([
    ["total_error", "エラーが発生しました。しばらく経ってから、再試行してください。"]
  ]);
}

/**
 * SleepCheckupInfoにprojectをセットし、新しいSleepCheckupInfoオブジェクトを返す
 * @param project CheckupProject
 * @param info 対象のSleepCheckupInfo
 * @returns 引数infoがコピー（ディープコピー）された新しいSleepCheckupInfoオブジェクト
 */
function setProject(
  project: CheckupProject | null,
  info: SleepCheckupInfo
): SleepCheckupInfo {
  const newInfo = clone(info);
  newInfo.project = project;
  return newInfo;
}

/**
 * SleepCheckupInfoにプロジェクト名をセットし、新しいSleepCheckupInfoオブジェクトを返す
 * @param value プロジェクト名
 * @param info 対象のSleepCheckupInfo
 * @returns 引数infoがコピー（ディープコピー）された新しいSleepCheckupInfoオブジェクト
 */
export function setProjectName(
  value: string | null,
  info: SleepCheckupInfo
): SleepCheckupInfo {
  if (!value || value.trim() === "") {
    return setProject(null, info);
  }

  const project = _setProjectName(value, info.project);
  return setProject(project, info);
}
