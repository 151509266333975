import { Stack, Typography } from "@mui/material";

type QuizResultCountProps = {
  numerator: number;
  denominator: number;
};
export function QuizResultCount({
  numerator,
  denominator,
}: QuizResultCountProps) {
  return (
    <Stack direction="row" alignItems="baseline">
      <Typography fontSize="60px" color="primary">
        {numerator}
      </Typography>
      <Typography
        fontSize="24px"
        color="#707070"
      >{`/${denominator}`}</Typography>
    </Stack>
  );
}
