import WorksheetDialog from "@/components/worksheet/commons/WorksheetDialog";
import { Button, Divider, Stack, Typography } from "@mui/material";
import ReportDialog from "./ReportDialog";
import { ReportImg } from "./ReportImg";

/**
 * グラフの見方ダイアログ
 */
export function HowToViewGraphsDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  return (
    <ReportDialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <Stack sx={{ width: "320px" }}>
        <Stack sx={{ alignItems: "center", p: "16px" }}>
          <Typography variant="h6" sx={{ color: "primary.dark" }}>
            グラフの見方（凡例）
          </Typography>
        </Stack>
        <Stack sx={{ p: "0 16px 24px 16px" }}>
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" sx={{ gap: "4px" }}>
                <ReportImg image_name="image_report_how_to_view_graphs_light_off.svg" />
                <Typography variant="body2">消灯</Typography>
              </Stack>

              <Stack direction="row" sx={{ gap: "4px" }}>
                <ReportImg image_name="image_report_how_to_view_graphs_wake_up.svg" />
                <Typography variant="body2">起床</Typography>
              </Stack>

              <Stack direction="row" sx={{ gap: "4px" }}>
                <ReportImg image_name="image_report_how_to_view_graphs_sleep.svg" />
                <Typography variant="body2">睡眠</Typography>
              </Stack>

              <Stack direction="row" sx={{ gap: "4px" }}>
                <ReportImg image_name="image_report_how_to_view_graphs_mid-awakening.svg" />
                <Typography variant="body2">睡眠中の覚醒</Typography>
              </Stack>
            </Stack>
            <Stack direction="row">
              <Stack direction="row" sx={{ gap: "4px" }}>
                <ReportImg image_name="image_report_how_to_view_graphs_nap.svg" />
                <Typography variant="body2">仮眠</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Typography variant="caption" mt="8px" mb="16px">
            ※測定データが欠損している場合、問診によって得られたデータ(消灯/起床/仮眠)のみが表示されます。
          </Typography>
          <Stack
            sx={{
              backgroundColor: "background.default",
              p: "16px",
              gap: "16px",
            }}
          >
            <ReportImg image_name="image_report_how_to_view_graphs.svg" />
            <Typography variant="caption">
              18時〜20時まで仮眠をとった後、 22時に消灯し、翌朝6時に起床した例
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack sx={{ p: "16px" }}>
          <Button
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            閉じる
          </Button>
        </Stack>
      </Stack>
    </ReportDialog>
  );
}

/**
 * グラフの見方ダイアログ（PC用）
 */
export function HowToViewGraphsDialogForPC({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  function LegendItem({
    imageName,
    text,
  }: {
    imageName: string;
    text: string;
  }) {
    return (
      <Stack direction="row" sx={{ gap: 1 }}>
        <ReportImg image_name={imageName} />
        <Typography variant="body1" sx={{ color: "text.primary" }}>
          {text}
        </Typography>
      </Stack>
    );
  }

  return (
    <WorksheetDialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      title="グラフの見方"
    >
      <Stack>
        <Stack sx={{ gap: 2 }}>
          <Typography variant="subtitle1" sx={{ color: "primary.black" }}>
            凡例
          </Typography>
          <Stack sx={{ gap: 2 }}>
            <Stack direction="row" sx={{ gap: 4 }}>
              <LegendItem
                imageName="image_report_how_to_view_graphs_light_off.svg"
                text="消灯"
              />
              <LegendItem
                imageName="image_report_how_to_view_graphs_wake_up.svg"
                text="起床"
              />
              <LegendItem
                imageName="image_report_how_to_view_graphs_sleep.svg"
                text="睡眠"
              />
              <LegendItem
                imageName="image_report_how_to_view_graphs_mid-awakening.svg"
                text="睡眠中の覚醒"
              />
              <LegendItem
                imageName="image_report_how_to_view_graphs_nap.svg"
                text="仮眠"
              />
            </Stack>
          </Stack>
          <Typography variant="body2" color="text.secondary">
            ※測定データが欠損している場合、問診によって得られたデータ（消灯/起床/仮眠）のみが表示されます。
          </Typography>
        </Stack>
        <Stack
          sx={{
            backgroundColor: "background.default",
            gap: 4,
            p: 4,
            mt: 6,
          }}
        >
          <ReportImg
            image_name="image_report_how_to_view_graphs.svg"
            height="164px"
          />
          <Typography
            variant="body2"
            sx={{ color: "text.primary", textAlign: "center" }}
          >
            18時〜20時まで仮眠をとった後、
            <br />
            22時に消灯し、翌朝6時に起床した例
          </Typography>
        </Stack>
      </Stack>
    </WorksheetDialog>
  );
}
