import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function BeforeSleepMindHelpDialog() {
  return (
    <HelpButton dialogTitle="寝る前の気分">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "睡眠への影響",
            content:
              "眠りにつく時は、自律神経の中で体をリラックスさせる作用のある副交感神経が優位になります。しかし、ストレスや不安を感じていたり、興奮・緊張状態だと交感神経が優位となり、スムーズな入眠を妨げてしまいます。",
          },
          {
            title: "おすすめ",
            content:
              "寝る前に落ち着いた気持ちになるように、自分に合った音楽・アロマ・体操・腹式呼吸・入浴・マインドフルネス・瞑想などでリラックスしましょう。また、寝床では考え事をしないようにしましょう。",
          },
        ]}
      />
    </HelpButton>
  );
}
