import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function SleepTimeHelpDialog() {
  return (
    <HelpButton dialogTitle="睡眠時間">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "必要な睡眠時間",
            content:
              "適切な睡眠時間は個人差がありますが、18〜64歳では7〜9時間が推奨とされています。理想的には毎日7時間以上確保できると良いですが、仕事などで忙しい日は少なくとも6時間確保できると良いです。",
          },
          {
            title: "おすすめ",
            content:
              "個人差があるため、日中の眠気を感じない程度に時間を確保するのがおすすめです。ただし、慢性的な寝不足だと眠気を感じづらくなるため、眠気は感じていなくても日中の疲労を感じている場合は睡眠不足を疑ってみましょう。毎日の睡眠時間を長くすることが難しい場合は、ノー残業デーで早く帰った日は早く寝るようにする、などから睡眠時間を確保しましょう。",
          },
        ]}
      />
    </HelpButton>
  );
}
