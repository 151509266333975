import { clone } from "@/components/common/ObjectService";
import { SimpleMedicalFacilityUser as FacilityUser } from "@/components/sleep_checkup_v1/MedicalFacilityUser";
import { fullName as facilityUserFullName } from "@/components/sleep_checkup_v1/SimpleMedicalFacilityUserService";
import {
  omitTextIfNeeded,
  searchParamsText,
  TextBuilder,
} from "@/components/sleep_checkup_v1/TextService";
import {
  boolString,
  ReportStatus,
  SearchParams,
} from "@/components/sleep_checkup_v1/Types";
import { internalFormat, periodText } from "@/components/sleep_checkup_v1/util";

/**
 * 検索パラメータの設定値をテキストにする
 * @param params 検索パラメータ
 * @param calendarType 「日付で探す」の設定値
 * @returns 検索パラメータの設定値をテキストにした文字列
 */
export function text(
  facilityUsers: FacilityUser[],
  params?: SearchParams,
  calendarType?: CalendarSearchType | null
): string | null {
  if (params == null) {
    return null;
  }

  const SEPARATOR = ",";

  const text = paramsAndCalendarText(
    SEPARATOR,
    facilityUsers,
    params,
    calendarType
  );
  if (!text) {
    return null;
  }

  // Note: テキストが長すぎる場合は三点リーダーをつけて、省略する
  return omitTextIfNeeded(text, 18, "…", SEPARATOR);
}

function paramsAndCalendarText(
  separator: string,
  facilityUsers: FacilityUser[],
  params: SearchParams,
  calendarType?: CalendarSearchType | null
): string {
  const paramsText: string = sleepCheckupInfoSearchParamsText(
    facilityUsers,
    params,
    separator
  );
  const calendarText: string = calendarSearchText(params, calendarType);
  const statusText: string = reportStatusText(params);

  const text = [paramsText, calendarText, statusText].reduce(
    (prev, current) => {
      const s = prev && current ? separator : "";
      return `${prev}${s}${current}`;
    }
  );

  return text;
}

function sleepCheckupInfoSearchParamsText(
  facilityUsers: FacilityUser[],
  params: SearchParams,
  separator: string
): string {
  const builder: TextBuilder = (key, label, value) => {
    switch (key) {
      case "accepted_by":
        // Note: accepted_byはvalueにIDがセットされている。が、IDをユーザーに見せても分からないので、IDでなく氏名を表示する
        const fullName = getFacilityUserFullName(
          facilityUsers,
          parseInt(value)
        );
        return `${label}:${fullName}`;
      default:
        // Note: パラメータ名(label)と設定値(value)を表示
        return `${label}:${value}`;
    }
  };

  return searchParamsText(params, PARAMS_LABEL, separator, builder);
}

function getFacilityUserFullName(
  facilityUsers: FacilityUser[],
  id: number
): string {
  const user = facilityUsers.find((u) => u.id === id);
  if (user == null) {
    return String(id);
  }

  return facilityUserFullName(user);
}

/**
 * 検索パラメータキーをUI用の文言に変換するテーブル
 */
export const PARAMS_LABEL: Record<string, string> = {
  medical_examinee_id_in_facility: "施設内受診者ID",
  medical_examinee_last_name: "姓",
  medical_examinee_first_name: "名",
  medical_examinee_last_name_kana: "セイ",
  medical_examinee_first_name_kana: "メイ",
  medical_examinee_birthday: "生年月日",
  corporate_name: "企業名",
  department_name: "部署名",
  device_id: "デバイスID",
  accepted_by: "受付担当者",
};

export const NULL_VALUE = "NULL_VALUE";
export type NullValue = "NULL_VALUE";

/**
 * 測定検索の「日付で探す」のラベル文言
 */
export const CALENDAR_SEARCH_SELECTOR_LABEL = "項目名";

/**
 * 測定検索の「日付で探す」の選択肢
 */
export const CALENDAR_SEARCH_LABEL = new Map<
  CalendarSearchType | NullValue,
  string
>([
  ["ACCEPTED_AT", "受付日"],
  [NULL_VALUE, "指定しない"],
]);

function calendarSearchText(
  params: SearchParams,
  calendarType?: CalendarSearchType | null
): string {
  if (calendarType == null) {
    return "";
  }

  const label = CALENDAR_SEARCH_LABEL.get(calendarType);

  const from = periodFromString(calendarType, params);
  const to = periodToString(calendarType, params);
  const period = periodText(from, to);

  return `${label}:${period}`;
}

/**
 * 測定検索の「レポート状況で探す」のラベル文言
 */
export const REPORT_STATUS_SELECTOR_LABEL = "レポート状況";

/**
 * レポートステータスを測定検索の「レポート状況で探す」の選択肢
 */
export const REPORT_STATUS_LABEL = new Map<ReportStatus | NullValue, string>([
  ["CHECKINGUP", "測定中 (未作成)"],
  ["APPROVED", "レポート送付待ち"],
  ["SENT", "レポート送付済み"],
  [NULL_VALUE, "指定しない"],
]);

function reportStatusText(params: SearchParams): string {
  const status = reportStatus(params);
  if (status == null) {
    return "";
  }
  const value = REPORT_STATUS_LABEL.get(status);
  return `${REPORT_STATUS_SELECTOR_LABEL}:${value}`;
}

/**
 * SearchParamsに設定されている値が等しいかどうかを返す
 * @param lhs 比較対象のSearchParams
 * @param rhs 比較対象のSearchParams
 * @returns 設定されている値が全て等しい場合 true / ひとつでも異なる値がある場合 false
 */
export function equals(lhs: SearchParams, rhs: SearchParams): boolean {
  const l = lhs as any;
  const r = rhs as any;

  for (const [key, value] of Object.entries(l)) {
    if (r[key] !== value) {
      return false;
    }
  }

  return true;
}

/**
 * 文字列タイプの検索パラメータ
 */
export type StringValues =
  | "medical_examinee_id_in_facility"
  | "medical_examinee_last_name"
  | "medical_examinee_first_name"
  | "medical_examinee_last_name_kana"
  | "medical_examinee_first_name_kana"
  | "corporate_name"
  | "department_name"
  | "device_id"
  | "accepted_by";

/**
 * 日付タイプの検索パラメータ
 */
export type DateValues =
  | "medical_examinee_birthday"
  | "accepted_at_from"
  | "accepted_at_to";

/**
 * SearchParamsに値をセットし、新しいSearchParamsオブジェクトを返す
 * @param key 対象パラメータ
 * @param value 対象パラメータにセットする値
 * @param params 対象のSearchParams
 * @returns 引数paramsがコピー（ディープコピー）された新しいSearchParamsオブジェクト
 */
export function setValue(
  key: StringValues | DateValues,
  value: string | null,
  params: SearchParams
): SearchParams {
  const p = clone(params) as any;
  // Note: 空文字のときは、入力無しを意味するnullをセットする
  p[key] = value ? value : null;
  return p as SearchParams;
}

/**
 * SearchParamsのvalueから、NULL_VALUEをnullに置き換える
 * @param params 対象のSearchParams
 * @returns NULL_VALUEがnullに置き換えられたSearchParams
 */
export function replaceNullValue(params: SearchParams): SearchParams {
  const p = clone(params) as any;
  for (const key of Object.keys(p)) {
    if (p[key] === NULL_VALUE) {
      p[key] = null;
    }
  }

  return p as SearchParams;
}

/**
 * SearchParamsの値を取得する
 * @param key 対象パラメータ
 * @param params 対象のSearchParams
 * @returns 引数keyで指定されたパラメータにセットされている値
 */
export function value(
  key: StringValues | DateValues,
  params: SearchParams
): string {
  const p = params as any;
  // Note: valueがnullのときは、表示時に入力無しを意味する空文字を返す
  return p[key] ?? "";
}

/**
 * SearchParamsのパラメータのうち、日付で、型がstring型のパラメーターの値をセットする
 * @param key 対象パラメータ
 * @param value 対象パラメータにセットする値
 * @param params 対象のSearchParams
 * @returns 引数paramsがコピー（ディープコピー）された新しいSearchParamsオブジェクト
 */
export function setDateString(
  key: DateValues,
  value: string | null,
  params: SearchParams
): SearchParams {
  // Note: ユーザーが入力する日付とAPIパラメータの日付はセパレーターが異なる。APIパラメータのセパレーターに変換する。
  const v = value ? internalFormat(value) : null;
  return setValue(key, v, params);
}

/**
 * 測定検索の「日付で探す」の選択肢
 */
export type CalendarSearchType = "ACCEPTED_AT";

/**
 * 測定検索の「日付で探す」に関するパラメータの値を、SearchParamsから削除する
 * @param params 対象のSearchParams
 * @returns 引数paramsがコピー（ディープコピー）された新しいSearchParamsオブジェクト
 */
export function clearCalendarType(params: SearchParams): SearchParams {
  const p = clone(params);
  p.date_device_returned_from = null;
  p.date_device_returned_to = null;
  p.date_device_sent_from = null;
  p.date_device_sent_to = null;
  p.accepted_at_from = null;
  p.accepted_at_to = null;
  return p;
}

type Period = {
  from: DateValues | "";
  to: DateValues | "";
};

const PERIOD_TABLE: Record<CalendarSearchType, Period> = {
  ACCEPTED_AT: {
    from: "accepted_at_from",
    to: "accepted_at_to",
  },
};

export function periodFromString(
  calendarType: CalendarSearchType,
  params: SearchParams
): string {
  const key = PERIOD_TABLE[calendarType].from;
  if (key === "") {
    return "";
  }

  return value(key, params);
}

export function periodToString(
  calendarType: CalendarSearchType,
  params: SearchParams
): string {
  const key = PERIOD_TABLE[calendarType].to;
  if (key === "") {
    return "";
  }

  return value(key, params);
}

/**
 * 測定検索の「日付で探す」のStartに対応する値を、SearchParamsにセットする
 * @param from セットする値
 * @param calendarType 「日付で探す」の値
 * @param params 対象のSearchParams
 * @returns 引数paramsがコピー（ディープコピー）された新しいSearchParamsオブジェクト
 */
export function setPeriodFrom(
  from: string | null,
  calendarType: CalendarSearchType | null,
  params: SearchParams
): SearchParams {
  if (calendarType == null) {
    return clearCalendarType(params);
  }

  const p = clone(params) as any;
  const key = PERIOD_TABLE[calendarType].from;
  p[key] = from != null ? internalFormat(from) : null;
  return p as SearchParams;
}

export function setPeriodTo(
  to: string | null,
  calendarType: CalendarSearchType | null,
  params: SearchParams
): SearchParams {
  if (calendarType == null) {
    return clearCalendarType(params);
  }

  const p = clone(params) as any;
  const key = PERIOD_TABLE[calendarType].to;
  p[key] = to != null ? internalFormat(to) : null;
  return p as SearchParams;
}

export function getPeriodFrom(
  calendarType: CalendarSearchType | null,
  params: SearchParams
): string | null {
  if (calendarType == null) {
    return null;
  }

  const key: string = PERIOD_TABLE[calendarType].from;
  const value: string | null = (params as any)[key];
  return value ?? null;
}

export function getPeriodTo(
  calendarType: CalendarSearchType | null,
  params: SearchParams
): string | null {
  if (calendarType == null) {
    return null;
  }

  const key: string = PERIOD_TABLE[calendarType].to;
  const value: string | null = (params as any)[key];
  return value ?? null;
}

type ReportStatusParam = {
  reportApproved: boolString;
  sentReport: boolString;
};

const REPORT_STATUS_TABLE: Record<ReportStatus, ReportStatusParam> = {
  CHECKINGUP: { reportApproved: "0", sentReport: "" },
  APPROVED: { reportApproved: "1", sentReport: "0" },
  SENT: { reportApproved: "", sentReport: "1" },
};

/**
 * 測定検索の「レポート状況で探す」に対応する値を、SearchParamsから判定して、返す
 * @param params 対象のSearchParams
 * @returns 「レポート状況で探す」に対応する値
 */
export function reportStatus(params: SearchParams): ReportStatus | null {
  for (const [status, values] of Object.entries(REPORT_STATUS_TABLE)) {
    const { reportApproved, sentReport } = values;
    if (
      params.report_approved === reportApproved &&
      params.sent_report === sentReport
    ) {
      return status as ReportStatus;
    }
  }

  return null;
}

/**
 * 測定検索の「レポート状況で探す」に対応する値を、SearchParamsにセットする
 * @param value セットする値
 * @param params 対象のSearchParams
 * @returns 引数paramsがコピー（ディープコピー）された新しいSearchParamsオブジェクト
 */
export function setReportStatus(
  value: ReportStatus | null,
  params: SearchParams
): SearchParams {
  const { reportApproved, sentReport }: ReportStatusParam =
    value != null
      ? REPORT_STATUS_TABLE[value]
      : { reportApproved: "", sentReport: "" };

  const p = clone(params);
  p.report_approved = reportApproved;
  p.sent_report = sentReport;
  return p;
}

export function createDateErrorMessages(
  key: DateValues,
  value: string,
  org: Map<DateValues, string>
): Map<DateValues, string> {
  const messages = new Map<DateValues, string>([...org]);
  messages.set(key, value);
  return messages;
}
