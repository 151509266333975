import TimeUnit from "@/utils/TimeUnit";
import { Circle, Line, Rect, SVG, Svg, Text } from "@svgdotjs/svg.js";
import { parse } from "date-fns";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

/** グラフの種類 */
export type PlotType = "sleep" | "awake" | "no_data" | "nap";

/** グラフの色の種類 */
type ColorType = "active" | "inactive";

/** 座標 */
export interface Coordinate {
  /** x座標 */
  x: number;
  /** y座標 */
  y: number;
}

/**
 * グラフの情報
 */
export interface SleepData {
  from: Date;
  to: Date;
  type: PlotType;
}

/**
 * 寝床に入った日時、寝床から出た日時、グラフの情報
 */
export interface SleepDiaryData {
  date: string;
  bedtime: Date | null;
  ariseTime: Date | null;
  sleepData: SleepData[];
}

/**
 * グラフの属性
 */
export type SleepBarAttributes = Readonly<
  Record<ColorType, Readonly<Record<PlotType, object>>>
>;

/**
 * グラフの属性のデフォルト値
 */
export const DEFAULT_SLEEP_BAR_ATTR: SleepBarAttributes = {
  active: {
    sleep: { fill: "#0056A0" },
    awake: { fill: "#ff3a30" },
    no_data: { fill: "#ffeaca" },
    nap: { fill: "#0056A04D", "fill-opacity": "0.3" },
  },
  inactive: {
    sleep: { fill: "#444444" },
    awake: { fill: "#747474" },
    no_data: { fill: "#ededed" },
    nap: { fill: "#c8c8c8", "fill-opacity": "0.3" },
  },
};

/**
 * 寝床から出た時間の属性のデフォルト値
 */
export const DEFAULT_ARISE_TIME_LINE_ATTR: BedLineAttributes = {
  active: {
    fill: "#ffffff",
    "stroke-width": 1,
    stroke: "#eea50d",
  },
  inactive: {
    fill: "#ffffff",
    "stroke-width": 1,
    stroke: "#b6b6b6",
  },
};
/**
 * 寝床に入った時間の属性のデフォルト値
 */
export const DEFAULT_BEDTIME_LINE_ATTR: BedLineAttributes = {
  active: {
    fill: "#eea50d",
    "stroke-width": 1,
    stroke: "#eea50d",
  },
  inactive: {
    fill: "#b6b6b6",
    "stroke-width": 1,
    stroke: "#b6b6b6",
  },
};
/**
 * 寝床から出た／寝床に入った時間の属性
 */
export type BedLineAttributes = Readonly<Record<ColorType, object>>;

/**
 * 水平線を描画します。
 *
 * @param param0
 * @returns
 */
function drawHorizontalLine({
  svg,
  x,
  y,
  length,
}: {
  svg: Svg;
  x: number;
  y: number;
  length: number;
}) {
  return svg.line(x, y, x + length, y);
}

/**
 * 寝床に入った／出たの線を表現するクラス
 */
class BedLine {
  readonly radius = 5;

  protected line: Line;
  protected circle: Circle;

  constructor(
    protected svg: Svg,
    protected x: number,
    protected y: number,
    protected length: number,
    protected lineAttribute: any,
    protected circleAttribute: any
  ) {
    this.line = drawHorizontalLine({
      svg,
      x,
      y,
      length,
    }).attr(lineAttribute);
    this.circle = svg
      .circle(this.radius * 2)
      .move(x + length, y - this.radius)
      .attr(circleAttribute);
  }
  /**
   * 移動を行います。
   * @param x
   * @param y
   * @returns
   */
  move(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.line.move(x, y);
    this.circle.move(x + this.length, y - this.radius);
    return this;
  }
  /**
   * 長さを設定します。
   *
   * @param width
   * @returns
   */
  width(length: number) {
    this.length = length;
    this.line.width(length);
    return this;
  }
  /**
   * 属性を設定します。
   * @param attr
   * @returns
   */
  attr(lineAttr: object, circleAttr: object) {
    this.line.attr(lineAttr);
    this.circle.attr(circleAttr);
    return this;
  }
}
/**
 * 睡眠のグラフを描画します。
 *
 */
export class Chart {
  private readonly svg = SVG();

  /** 測定期間 */
  private period: Date[] = [];

  /** 睡眠グラフ */
  private chartBars: (Rect[] | undefined)[] = [];

  /** 寝床に入った線 */
  private bedtimeLines: (BedLine | null)[] = [];

  /** 寝床から出た線 */
  private ariseTimeLines: (BedLine | null)[] = [];

  /** 目盛線 */
  private scaleLines: Line[] = [];

  /** X軸ラベル */
  private axisXLabels: Text[] = [];

  /** Y軸ラベル */
  private axisYLabels: Text[] = [];

  /** グラフの描画座標 */
  private chartArea: {
    leftTop: Coordinate;
    rightBottom: Coordinate;
  } = {
    leftTop: {
      x: 0,
      y: 0,
    },
    rightBottom: {
      x: 0,
      y: 0,
    },
  };

  /** グラフの目盛の間隔 */
  private space: {
    /** 横軸の間隔 */
    horizontal: number;
    /** 縦軸の間隔  */
    vertical: number;
  } = { horizontal: 0, vertical: 0 };

  /**
   * activeになるグラフのindex
   */
  private activeIndex?: number;

  /**
   * バーの幅に上限を設定するか
   */
  private hasWidthLimit?: boolean = false;

  /**
   * コンストラクタ
   * @param parent SVGを追加する親エレメント
   * @param config
   */
  constructor(
    readonly parent: HTMLElement | null,
    readonly config: {
      /** グラフのデータ */
      data: SleepDiaryData[];
      /**
       * 睡眠グラフの属性
       * 指定しない場合 {@link DEFAULT_SLEEP_BAR_ATTR} が使用されます。
       */
      sleepBarAttributes?: SleepBarAttributes;
      /**
       * 寝床に入った時間の属性
       * 指定しない場合 {@link DEFAULT_BEDTIME_LINE_ATTR} が使用されます。
       */
      bedtimeLineAttributes?: BedLineAttributes;
      /**
       * 寝床に入った時間のCircleの属性
       * 指定しない場合 bedtimeLineAttributesが使用されます。
       */
      bedtimeCircleAttributes?: BedLineAttributes;
      /**
       * 寝床から出た時間の属性
       * 指定しない場合 {@link DEFAULT_ARISE_TIME_LINE_ATTR} が使用されます。
       */
      ariseTimeLineAttributes?: BedLineAttributes;
      /**
       * 寝床から出た時間のCircleの属性
       * 指定しない場合 ariseTimeLineAttributes が使用されます。
       */
      ariseTimeCircleAttributes?: BedLineAttributes;
      /**
       * グラフが押下された場合の処理
       * @param index
       * @returns
       */
      onChartClick?: (index: number) => void;
      /**
       * X軸のラベルが押下された場合の処理
       * @param index
       * @returns
       */
      onXAxisLabelClick?: (index: number) => void;
      /**
       * Y軸のラベルが押下された場合の処理
       * @param index
       * @returns
       */
      onYAxisLabelClick?: (index: number) => void;
      /**
       * バーの幅に上限を設定するか
       */
      hasWidthLimit?: boolean;
    }
  ) {
    this.draw();
  }
  /**
   * X軸のラベルを取得します。
   *
   * @param date
   * @returns
   */
  private getXLabel(date: Date) {
    return `${date.getMonth() + 1}/${date.getDate()}\n${
      "日月火水木金土"[date.getDay()]
    }`;
  }
  /**
   * X軸のラベルを作成します。
   *
   * @returns X軸のラベル
   */
  private createXAxisLabels() {
    this.axisXLabels = this.period.map((d, i) =>
      this.textOf(this.getXLabel(d))
        .font({ fill: "#707070" })
        .attr({ "text-anchor": "middle" })
        .click(() => {
          this.config.onXAxisLabelClick?.(i);
        })
    );
  }
  /**
   * Y軸のラベルを取得します。
   * @param n
   * @returns
   */
  private getYLabel(n: number) {
    return `${String((18 + n * 2) % 24)}:00`;
  }
  /**
   * Y軸のラベルを作成します。
   *
   * @returns Y軸のラベル
   */
  private createYAxisLabels() {
    const hours = [...Array(13)].map((_, i) => this.getYLabel(i));
    this.axisYLabels = hours.map((v, i) =>
      this.textOf(v)
        .font({ fill: "#707070" })
        .attr({ anchor: "middle" })
        .click(() => {
          this.config.onYAxisLabelClick?.(i);
        })
    );
  }
  /**
   * 水平方向に線を書きます。
   *
   * @param param0
   * @returns
   */
  private drawHorizontalLine({
    x,
    y,
    length,
  }: {
    x: number;
    y: number;
    length: number;
  }) {
    return drawHorizontalLine({
      svg: this.svg,
      x,
      y,
      length,
    });
  }
  /**
   * X軸ラベルを整列します。
   */
  private alignXAxisLabels() {
    for (const [i, label] of this.axisXLabels.entries()) {
      const { width } = label.node.getBoundingClientRect();
      const x =
        this.chartArea.leftTop.x +
        (this.space.horizontal - width) / 2 +
        i * this.space.horizontal;
      const y = 0;
      label.move(x, y);
    }
  }
  /**
   * Y軸ラベルを整列します。
   */
  private alignYAxisLabels() {
    for (const [i, label] of this.axisYLabels.entries()) {
      const { width, height } = label.node.getBoundingClientRect();
      const x = this.chartArea.leftTop.x - width;
      const y =
        this.chartArea.leftTop.y +
        (this.space.vertical - height) / 2 +
        i * this.space.vertical;
      label.move(x, y);
    }
  }
  /**
   * 目盛線を描画します。
   */
  private drawScaleLines() {
    this.scaleLines = [];
    for (const label of this.axisYLabels) {
      const line = this.drawHorizontalLine({
        x: this.chartArea.leftTop.x + 8,
        y: label.attr("y") - label.node.getBoundingClientRect().height / 4,
        length: this.chartArea.rightBottom.x - this.chartArea.leftTop.x - 8,
      }).attr({
        "stroke-width": 1,
        stroke: ["#e0e0e0", "#3d3d3d"][Number(label.text() === "0:00")],
      });
      this.scaleLines.push(line);
    }
  }
  /**
   * clearします。
   *
   */
  private clear() {
    while (this.parent?.firstChild)
      this.parent.removeChild(this.parent.firstChild);
    this.svg.clear();
  }
  /**
   * {@link Text} を作成します。
   *
   * @param text
   * @returns
   */
  private textOf(text: string) {
    return this.svg.text(text).font({
      family: "Noto Sans JP",
      size: "10px",
      style: "normal",
      weight: 500,
    });
  }

  /**
   * {@link HTMLElement} にSVGを追加します。
   * @param parent
   */
  private setUpSVG() {
    if (this.parent == null) return;
    this.svg.width("100%").height("100%").addTo(this.parent);
  }
  /**
   * 測定期間を設定します。
   */
  private setPeriod() {
    this.period = this.config.data.map((value) =>
      parse(value.date, "yyyy-MM-dd", new Date())
    );
  }
  /**
   * フラグの描画エリアを設定します。
   */
  private setChartArea() {
    this.chartArea = {
      leftTop: {
        x: Math.max(
          ...this.axisYLabels.map(
            (label) => label.node.getBoundingClientRect().width
          )
        ),
        y: Math.max(
          ...this.axisXLabels.map(
            (label) => label.node.getBoundingClientRect().height
          )
        ),
      },
      rightBottom: (({ width, height }): Coordinate => ({
        x: width,
        y: height,
      }))(this.svg.node.getBoundingClientRect()),
    };
  }
  /**
   * グラフの間隔を設定します。
   *
   */
  private setSpace() {
    this.space = {
      vertical: Math.floor(
        (this.chartArea.rightBottom.y - this.chartArea.leftTop.y) /
          this.axisYLabels.length
      ),
      horizontal: Math.floor(
        (this.chartArea.rightBottom.x - this.chartArea.leftTop.x) /
          this.axisXLabels.length
      ),
    };
  }
  /**
   * 基準となる日時を取得します。
   *
   * @param day
   * @returns
   */
  private getBaseDateTime(day: number) {
    const d = this.period[day];
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 18, 0, 0, 0);
  }
  /**
   * 時間に対する座標を取得します。
   * @param day
   * @param time
   * @returns
   */
  private getTimePosition({ day, time }: { day: number; time: Date }): {
    x: number;
    y: number;
  } | null {
    const baseDate = this.getBaseDateTime(day);
    const diff = TimeUnit.MILLISECONDS.toHours(
      time.getTime() - baseDate.getTime()
    );
    // 基準日時より前の場合
    if (diff < 0) {
      // 前日がある場合、前日の基準日時の座標を取得
      if (day > 0) {
        return this.getTimePosition({ day: day - 1, time });
      }
      // 前日がない場合、null を返却
      return null;
    }

    const oneHour = this.space.vertical / 2;
    return {
      x:
        this.chartArea.leftTop.x +
        (this.space.horizontal - this.getChartBarWidth()) / 2 +
        this.space.horizontal * day,
      y: this.chartArea.leftTop.y + (diff + 1) * oneHour,
    };
  }
  /**
   * グラフの横軸を取得します。
   *
   * @returns
   */
  private getChartBarWidth() {
    if (this.config.hasWidthLimit) {
      return Math.min(72, this.space.horizontal / 2);
    }
    return this.space.horizontal / 2;
  }
  /**
   * グラフの属性を取得します。
   */
  private getSleepBarAttribute({
    day,
    plotType,
  }: {
    day: number;
    plotType: PlotType;
  }) {
    const config = this.config.sleepBarAttributes ?? DEFAULT_SLEEP_BAR_ATTR;
    const colorType = this.getColorType({ day });
    return config[colorType][plotType];
  }
  /**
   * 寝床に入った、出たの線を描画します。
   *
   * @param param0
   * @returns
   */
  private drawBedLine({
    day,
    time,
    lineAttr,
    circleAttr,
  }: {
    day: number;
    time: Date;
    lineAttr: object;
    circleAttr: object;
  }) {
    const pos = this.getTimePosition({ day, time });
    if (pos == null) {
      return null;
    }
    const { x, y } = pos;
    const length = this.getChartBarWidth();
    return new BedLine(this.svg, x, y, length, lineAttr, circleAttr);
  }

  /**
   * 寝床に入った線を描画します。
   *
   * @param param0
   * @returns
   */
  private drawBedtimeLine({ day, time }: { day: number; time: Date }) {
    return this.drawBedLine({
      day,
      time,
      lineAttr: this.getBedtimeLineAttribute({ day }),
      circleAttr: this.getBedtimeCircleAttribute({ day }),
    });
  }
  /**
   * 寝床から出た線を描画します。
   *
   * @param param0
   * @returns
   */
  private drawAriseTimeLine({ day, time }: { day: number; time: Date }) {
    return this.drawBedLine({
      day,
      time,
      lineAttr: this.getAriseTimeLineAttribute({ day }),
      circleAttr: this.getAriseTimeCircleAttribute({ day }),
    });
  }
  private resizeChartBar({ rect }: { rect: Rect }) {
    const from = new Date(parseInt(rect.attr("data-from")));
    const to = new Date(parseInt(rect.attr("data-to")));
    const day = parseInt(rect.attr("data-day"));
    const fromPosition = this.getTimePosition({ day, time: from });
    const toPosition = this.getTimePosition({ day, time: to });

    if (fromPosition == null || toPosition == null) return;
    const height = toPosition.y - fromPosition.y;
    const width = this.getChartBarWidth();
    rect.width(width).height(height).move(fromPosition.x, fromPosition.y);
  }
  /**
   * グラフの箱をリサイズします。
   *
   * @param day
   * @param data
   */
  private resizeChartBars({ day }: { day: number }) {
    const chartBar = this.chartBars[day];
    if (chartBar == null) return;
    for (const rect of chartBar) {
      this.resizeChartBar({ rect });
    }
  }
  /**
   * 寝床に入った線をリサイズします。
   *
   * @param param0
   */
  private resizeBedtimeLines({
    day,
    bedtime,
  }: {
    day: number;
    bedtime: Date | null;
  }) {
    if (bedtime != null) {
      const bedtimeLine = this.bedtimeLines[day];
      const pos = this.getTimePosition({ day, time: bedtime });

      if (pos == null) return;
      const { x, y } = pos;
      const length = this.getChartBarWidth();
      bedtimeLine?.width(length).move(x, y);
    }
  }
  /**
   * 起床時間の線をリサイズします。
   * @param param0
   */
  private resizeAriseTimeLines({
    day,
    ariseTime,
  }: {
    day: number;
    ariseTime: Date | null;
  }) {
    if (ariseTime != null) {
      const ariseTimeLine = this.ariseTimeLines[day];
      const pos = this.getTimePosition({ day, time: ariseTime });
      if (pos == null) return;
      const { x, y } = pos;
      const length = this.getChartBarWidth();
      ariseTimeLine?.width(length).move(x, y);
    }
  }
  /**
   * グラフをリサイズします。
   */
  private resizeCharts() {
    for (const [day, data] of this.config.data.entries()) {
      // グラフをリサイズ
      this.resizeChartBars({
        day,
      });
      // 寝床に入った線をリサイズ
      this.resizeBedtimeLines({
        day,
        bedtime: data.bedtime,
      });
      // 寝床から出た線をリサイズ
      this.resizeAriseTimeLines({
        day,
        ariseTime: data.ariseTime,
      });
    }
  }
  /**
   * グラフの箱を描画します。
   *
   * @param param0
   * @returns
   */
  private drawChartBar({ day, data }: { day: number; data: SleepData }) {
    const fromPosition = this.getTimePosition({ day, time: data.from });
    const toPosition = this.getTimePosition({ day, time: data.to });

    // 呼び出し元のメソッド (drawChatBars) でグラフを分割している想定ため、ここでは
    // 描画領域から外れている場合、描画しない
    if (fromPosition == null || toPosition == null) return null;
    const height = toPosition.y - fromPosition.y;
    const width = this.getChartBarWidth();
    const attr = this.getSleepBarAttribute({
      day,
      plotType: data.type,
    });
    const rect = this.svg
      .rect(width, height)
      .attr({
        ...attr,
        "data-plotType": data.type,
        "data-from": data.from.getTime(),
        "data-to": data.to.getTime(),
        "data-day": day,
      })
      .click(() => {
        this.config.onChartClick?.(day);
      })
      .move(fromPosition.x, fromPosition.y);
    return rect;
  }
  /**
   * active か inactive を取得します。
   *
   * @param param0
   * @returns
   */
  private getColorType({ day }: { day: number }) {
    return day === (this.activeIndex ?? day) ? "active" : "inactive";
  }
  /**
   * グラフのバーを描画します。
   * @param param0
   * @returns
   */
  private drawChartBars({
    day,
    sleepData,
  }: {
    day: number;
    sleepData: SleepData[];
  }) {
    const push = (rect: Rect | null) => {
      if (rect != null) (this.chartBars[day] ??= []).push(rect)
    }
    const baseDateTimeFrom = this.getBaseDateTime(day);
    for (const data of sleepData) {
      const chartData = { ...data };
      // グラフデータの開始が基準日時開始よりも小さい
      if (chartData.from.getTime() < baseDateTimeFrom.getTime()) {
        // グラフデータの終了が基準日日時開始よりも小さい
        if (chartData.to.getTime() < baseDateTimeFrom.getTime()) {
          // 前日があれば前日の列に描画
          if (day > 0) {
            push(
              this.drawChartBar({
                day: day,
                data: chartData,
              })
            );
          }
        } else {
          // グラフデータの終了が基準日日時開始よりも大きい（前日からグラフが続いている状態）
          // グラフデータを前日と当日で２分割して描画する
          if (day > 0) {
            // 前日分を描画
            push(
              this.drawChartBar({
                day,
                data: {
                  from: chartData.from,
                  to: new Date(baseDateTimeFrom.getTime() - 1),
                  type: chartData.type,
                },
              })
            );
          }
          // 当日分を描画
          push(
            this.drawChartBar({
              day,
              data: {
                from: baseDateTimeFrom,
                to: chartData.to,
                type: chartData.type,
              },
            })
          );
        }
      } else {
        push(
          this.drawChartBar({
            day,
            data: chartData,
          })
        );
      }
    }
  }
  /**
   * 寝床へ入った時間の線を描画します。
   * @param param0
   * @returns
   */
  private drawBedtimeLines({
    day,
    bedtime,
  }: {
    day: number;
    bedtime: Date | null;
  }) {
    const line =
      bedtime == null ? null : this.drawBedtimeLine({ day, time: bedtime });
    this.bedtimeLines.push(line);
  }
  /**
   * 寝床から出た時間の線を描画します。
   *
   * @param param0
   * @returns
   */
  private drawAriseTimeLines({
    day,
    ariseTime,
  }: {
    day: number;
    ariseTime: Date | null;
  }) {
    const line =
      ariseTime == null
        ? null
        : this.drawAriseTimeLine({ day, time: ariseTime });
    this.ariseTimeLines.push(line);
  }
  /**
   * グラフをクリアします。
   */
  private clearChartBars() {
    this.chartBars = [];
  }
  /**
   * 就寝時間の線をクリアします。
   */
  private clearBedtimeLines() {
    this.bedtimeLines = [];
  }
  /**
   * 起床時間の線をクリアします。
   */
  private clearAriseTimeLines() {
    this.ariseTimeLines = [];
  }
  /**
   * グラフを描画します。
   *
   * @param param0
   */
  private drawCharts() {
    this.clearChartBars();
    this.clearBedtimeLines();
    this.clearAriseTimeLines();

    for (const [day, value] of this.config.data.entries()) {
      // グラフを描画
      this.drawChartBars({
        day,
        sleepData: value.sleepData,
      });
      // 寝床に入った線を描画
      this.drawBedtimeLines({
        day,
        bedtime: value.bedtime,
      });
      // 寝床から出た線を描画
      this.drawAriseTimeLines({
        day,
        ariseTime: value.ariseTime,
      });
    }
  }
  /**
   * 目盛線を再配置します。
   */
  private resizeScaleLines() {
    for (const [i, line] of this.scaleLines.entries()) {
      const label = this.axisYLabels[i];
      const { height } = label.node.getBoundingClientRect();
      line
        .width(this.chartArea.rightBottom.x - this.chartArea.leftTop.x - 8)
        .move(this.chartArea.leftTop.x + 8, label.attr("y") - height / 4);
    }
  }
  /**
   * 起床時間の線の属性を取得します。
   *
   * @param param0
   * @returns
   */
  private getAriseTimeLineAttribute({ day }: { day: number }) {
    const color =
      this.config.ariseTimeLineAttributes ?? DEFAULT_ARISE_TIME_LINE_ATTR;
    return color[this.getColorType({ day })];
  }
  /**
   * 起床時間の丸の属性を取得します。
   *
   * @param param0
   * @returns
   */
  private getAriseTimeCircleAttribute({ day }: { day: number }) {
    const color = this.config.ariseTimeCircleAttributes;
    if (color != null) return color[this.getColorType({ day })];
    return this.getAriseTimeLineAttribute({ day });
  }
  /**
   * 就寝時間の線の属性を取得します。
   *
   * @param param0
   * @returns
   */
  private getBedtimeLineAttribute({ day }: { day: number }) {
    const color =
      this.config.bedtimeLineAttributes ?? DEFAULT_BEDTIME_LINE_ATTR;
    return color[this.getColorType({ day })];
  }

  /**
   * 消灯時刻の丸の属性を取得します。
   *
   * @param param0
   * @returns
   */
  private getBedtimeCircleAttribute({ day }: { day: number }) {
    const color = this.config.bedtimeCircleAttributes;
    if (color != null) return color[this.getColorType({ day })];
    return this.getBedtimeLineAttribute({ day });
  }

  /**
   * active なグラフを変更します。
   */
  private changeActiveSleepBar() {
    for (const [day, chartBar] of this.chartBars.entries()) {
      if (chartBar == null) continue;
      for (const rect of chartBar) {
        rect.attr(
          this.getSleepBarAttribute({
            day,
            plotType: rect.attr("data-plotType"),
          })
        );
      }
    }
  }
  /**
   * active な就寝時間の線を変更します。
   */
  private changeActiveBedtimeLine() {
    for (const [day, line] of this.bedtimeLines.entries()) {
      line?.attr(
        this.getBedtimeLineAttribute({ day }),
        this.getBedtimeCircleAttribute({ day })
      );
    }
  }
  /**
   * active な起床時間の線を変更します。
   */
  private changeActiveAriseTimeLine() {
    for (const [day, line] of this.ariseTimeLines.entries()) {
      line?.attr(
        this.getAriseTimeLineAttribute({ day }),
        this.getAriseTimeCircleAttribute({ day })
      );
    }
  }
  /**
   * activeIndex を設定します。
   * @param index
   */
  private setActiveIndex(index: number) {
    this.activeIndex = index;
  }
  /**
   * active のグラフを変更します。
   *
   * @param index
   */
  changeActive(index: number) {
    this.setActiveIndex(index);
    this.changeActiveSleepBar();
    this.changeActiveBedtimeLine();
    this.changeActiveAriseTimeLine();
  }
  /**
   * グラフのリサイズを行います。
   */
  resize() {
    this.setChartArea();
    this.setSpace();
    this.alignXAxisLabels();
    this.alignYAxisLabels();
    this.resizeScaleLines();
    this.resizeCharts();
  }
  /**
   * 描画を行います。
   *
   * @param animate
   * @returns
   */
  draw() {
    this.clear();
    this.setUpSVG();
    this.setPeriod();
    this.createXAxisLabels();
    this.createYAxisLabels();
    this.setChartArea();
    this.setSpace();
    this.alignXAxisLabels();
    this.alignYAxisLabels();
    this.drawScaleLines();
    this.drawCharts();
  }
}

/**
 * 睡眠グラフのプロパティ
 */
export type SleepDiaryChartProps = {
  /**
   * 選択されているグラフのindex
   * 省略した場合、全てのグラフがactiveで表示されます。
   */
  activeIndex?: number;

  /**
   * グラフを描画するデータ
   */
  data: SleepDiaryData[];
  /**
   * 睡眠グラフの属性
   * 指定しない場合 {@link DEFAULT_SLEEP_BAR_ATTR} が使用されます。
   */
  sleepBarAttributes?: SleepBarAttributes;
  /**
   * 寝床に入った時間の属性
   * 指定しない場合 {@link DEFAULT_BEDTIME_LINE_ATTR} が使用されます。
   */
  bedtimeLineAttributes?: BedLineAttributes;
  /**
   * 寝床に入った時間のCircleの属性
   * 指定しない場合 {@link SleepDiaryChartProps#bedtimeLineAttributes} が使用されます。
   */
  bedtimeCircleAttributes?: BedLineAttributes;
  /**
   * 寝床から出た時間の属性
   * 指定しない場合 {@link DEFAULT_ARISE_TIME_LINE_ATTR} が使用されます。
   */
  ariseTimeLineAttributes?: BedLineAttributes;
  /**
   * 寝床から出た時間のCircleの属性
   * 指定しない場合 {@link SleepDiaryChartProps#ariseTimeLineAttributes} が使用されます。
   */
  ariseTimeCircleAttributes?: BedLineAttributes;
  /**
   * グラフが押下された場合の処理
   * @param index
   * @returns
   */
  onChartClick?: (index: number) => void;
  /**
   * X軸のラベルが押下された場合の処理
   * @param index
   * @returns
   */
  onXAxisLabelClick?: (index: number) => void;
  /**
   * Y軸のラベルが押下された場合の処理
   * @param index
   * @returns
   */
  onYAxisLabelClick?: (index: number) => void;
  /**
   * バーの幅に上限を設定するか
   */
  hasWidthLimit?: boolean;
};

/**
 * 睡眠グラフを描画します。
 *
 * @param param0 {@link SleepDiaryChartProps}
 * @returns
 */
export default function SleepDiaryChart({
  activeIndex,
  ...props
}: SleepDiaryChartProps) {
  const refDiv = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<Chart | null>(null);
  useEffect(() => {
    setChart(new Chart(refDiv.current, props));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resizeChart = useCallback(() => {
    chart?.resize();
  }, [chart]);

  useEffect(() => {
    if (activeIndex == null) return;
    // グラフをactive
    chart?.changeActive(activeIndex);
  }, [chart, activeIndex]);

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeChart);
    return () => {
      window.removeEventListener("resize", resizeChart);
    };
  }, [resizeChart]);
  return <div ref={refDiv} style={{ width: "100%", height: "100%" }}></div>;
}
