import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function AlcoholHelpDialog() {
  return (
    <HelpButton dialogTitle="アルコール">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "睡眠への影響",
            content:
              "アルコールは一時的には寝付きを促進しますが、睡眠後半の眠りの質が顕著に悪化し、飲酒量が増加するにつれて中途覚醒回数が増加します。また、アルコールは閉塞性睡眠時無呼吸症候群をはじめとしたさまざまな睡眠障害を憎悪させます。",
          },
          {
            title: "おすすめ",
            content:
              "酔いが醒めてから眠れるように晩酌は早め・量も控えめにしましょう。睡眠とお酒の関係は毎日影響するため休肝日を設けても休肝日以外の睡眠を改善するものではありませんが、休肝日に睡眠の改善を実感することで継続のきっかけになるのでそういった目標設定も良いでしょう。",
          },
        ]}
      />
    </HelpButton>
  );
}
