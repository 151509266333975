import { NonFieldError } from "@/components/common/SCAlert";
import Expired from "@/sleep_compass_lite/components/target/Expired";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { TargetAuthenticated as Authenticated } from "@/sleep_compass_lite/components/target/TargetAuthenticated";
import { interviewPath } from "@/sleep_compass_lite/domain_models/target/InterviewPath";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { useDailyInterview } from "@/sleep_compass_lite/use_cases/target/interview/UseDailyInterview";
import { useDailyInterviewInfo } from "@/sleep_compass_lite/use_cases/target/interview/UseDailyInterviewInfo";
import { useSurveyInfo } from "@/sleep_compass_lite/use_cases/target/interview/UseSurveyInfo";
import { toStringJP } from "@/utils/date";
import {
  Box,
  Button,
  Dialog,
  DialogProps,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

/**
 * 毎日問診開始ページ
 */
export function DailyInterviewEntrance() {
  const navigate = useNavigate();
  const { announceId } = useParams();

  const [surveyInfo, surveyInfoError] = useSurveyInfo();
  const [dailyInterviewInfo, dailyInterviewInfoError] = useDailyInterviewInfo();
  const { getEntry } = useDailyInterview();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [answered, setAnswered] = useState(false);
  const [expired, setExpired] = useState(false);
  const [surveyClosed, setSurveyClosed] = useState(false);

  const error = [surveyInfoError, dailyInterviewInfoError].find(
    (e) => e != null
  );

  useEffect(() => {
    if (announceId == null) {
      return;
    }

    // 調査の終了判定。レポート送付が済んでいたら、調査終了とする。
    const surveyClosed =
      surveyInfo != null
        ? ["ReportSent", "AttitudeEnqueteCompleted"].includes(surveyInfo.status)
        : false;

    // 回答しようとしている毎日問診の回答期限の判定
    const expired =
      dailyInterviewInfo?.currentDailyInterview?.id !== parseInt(announceId);

    // 回答しようとしている毎日問診に回答済みか否かの判定
    const isCompleted =
      dailyInterviewInfo?.currentDailyInterview?.isCompleted === true;

    setSurveyClosed(surveyClosed);
    setExpired(expired);
    setAnswered(isCompleted);
    // 毎日問診に回答済み、かつ、調査が終了していない場合に再回答の確認ダイアログを表示
    setOpenConfirm(isCompleted && !surveyClosed);
  }, [announceId, dailyInterviewInfo, surveyInfo]);

  if (error != null) {
    return <NonFieldError>{getErrorMessage(error)}</NonFieldError>;
  }

  const handleClickStartInterview = async () => {
    if (
      surveyInfo == null ||
      dailyInterviewInfo?.currentDailyInterview == null
    ) {
      return;
    }

    const uid = surveyInfo.uid;
    const announceId = dailyInterviewInfo.currentDailyInterview.id;
    const entry = await getEntry(uid, announceId);
    const path = interviewPath.getDailyInterviewPath(uid, announceId, entry);
    // 問診画面から戻るために、現在の pathname を渡して遷移
    navigate(path, {
      state: {
        prevPathname: window.location.pathname,
      },
    });
  };
  if (expired) {
    // 問診回答期限切れ画面表示
    return <DailyInterviewExpired />;
  }
  if (surveyClosed) {
    // 調査終了画面を表示。レポート送付済みで毎日問診に回答できない。
    return <SurveyClosed />;
  }
  return (
    <Authenticated>
      <Stack spacing={6} sx={{ px: PX4, pt: 10, pb: 14 }} alignItems="center">
        <ConfirmDialog
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
        />
        <DailyInterviewEntranceImage />
        {surveyInfo != null &&
          dailyInterviewInfo != null &&
          dailyInterviewInfo.currentDailyInterview != null &&
          dailyInterviewInfo.currentDailyInterview.date != null && (
            <Stack spacing={6} sx={{ width: "100%" }}>
              <Stack spacing={4}>
                <MorningMessage
                  message="こんにちは"
                  targetName={surveyInfo.target.userName.fullName()}
                />
                <TodaysMessage
                  date={toStringJP(
                    dailyInterviewInfo.currentDailyInterview.date
                  )}
                  ordinalNumber={`${
                    dailyInterviewInfo.completedCount + (answered ? 0 : 1) // 回答済みの場合、回数をカウントしない
                  }回目`}
                  message="毎日問診をはじめましょう"
                />
              </Stack>
              <Button
                variant="contained"
                size="large"
                onClick={handleClickStartInterview}
              >
                回答をはじめる
              </Button>
            </Stack>
          )}
      </Stack>
    </Authenticated>
  );
}

/**
 * 毎日問診開始ページのファーストビューコンポーネント
 */
function DailyInterviewEntranceImage() {
  return (
    <Box sx={{ px: "41.5px" }}>
      <img
        src="/img/image_interview_entrance_02.png"
        alt="daily interview entrance"
        loading="lazy"
        style={{ width: "100%", height: "auto", maxWidth: "390px" }}
      />
    </Box>
  );
}

type MorningMessageProps = {
  /** メッセージ */
  message: string;
  /** 対象者の名前 */
  targetName: string;
};
/**
 * 毎日問診開始ページのメインメッセージコンポーネント
 */
function MorningMessage({ message, targetName }: MorningMessageProps) {
  return (
    <Stack alignItems="center">
      <Typography variant="h6">{message}</Typography>
      <Stack direction="row" spacing={1}>
        <Typography variant="h6" color="primary">
          {targetName}
        </Typography>
        <Typography variant="h6">さん</Typography>
      </Stack>
    </Stack>
  );
}

type TodaysMessageProps = {
  /** 毎日問診の対象日 */
  date: string;
  /** X回目のメッセージ */
  ordinalNumber: string;
  /** メッセージ */
  message: string;
};
/**
 * 毎日問診開始ページの毎日問診についてのメッセージを表示するコンポーネント
 */
function TodaysMessage({ date, ordinalNumber, message }: TodaysMessageProps) {
  const today = `${date}(${ordinalNumber})`;

  return (
    <Stack alignItems="center">
      <Stack direction="row">
        <Typography variant="subtitle2" color="primary">
          {today}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          の
        </Typography>
      </Stack>
      <Typography variant="subtitle2" color="text.secondary">
        {message}
      </Typography>
    </Stack>
  );
}

/**
 * {@link ConfirmDialog} のプロパティ
 */
interface ConfirmDialogProps extends Omit<DialogProps, "onClose"> {
  /** 画面をとじる */
  onClose(): void;
}
/**
 * すでに回答済みの場合に、上書きされる旨のメッセージを出力するダイアログ
 *
 * @param0 {@link ConfirmDialogProps} 確認ダイアログのプロパティ
 * @returns 確認ダイアログコンポーネント
 */
function ConfirmDialog({ onClose, ...props }: ConfirmDialogProps) {
  return (
    <Dialog {...props}>
      <Stack sx={{ p: 0, m: 6, gap: 6 }}>
        <Typography variant="body1">
          この問診は既に回答済みです。再度回答すると以前の回答は上書きされます。回答の上書きを希望しない場合は、このページを閉じてください。
        </Typography>
        <Button
          variant="contained"
          fullWidth
          sx={{ borderRadius: "8px", boxShadow: "none" }}
          onClick={onClose}
        >
          OK
        </Button>
      </Stack>
    </Dialog>
  );
}

/**
 * 期限切れコンポーネント
 *
 * @returns 期限切れコンポーネント
 */
function DailyInterviewExpired() {
  return (
    <Expired
      title="この問診は回答期限切れです"
      message={`最新のメールに記載されたURLから\n回答時間内に回答してください。`}
    />
  );
}

function SurveyClosed() {
  return (
    <Expired
      title="毎日問診は回答済みです"
      message={`今回の調査はすでに完了しているため、\n問診に再回答することはできません。`}
    />
  );
}
