import SuggestTextField, { SuggestTextFieldProps } from "../SuggestTextField";
import { getCorporateName } from "./SleepCheckupInfoAPI";

export type SuggestCorporateNameTextFieldProps = Omit<
  SuggestTextFieldProps,
  "getOptions"
>;

/**
 * 睡眠健診情報から企業名の候補を取得し表示するTextFieldです。
 *
 * @param {SuggestCorporateNameTextFieldProps} props
 * @returns {JSX.Element}
 */
function SuggestCorporateNameTextField(
  props: SuggestCorporateNameTextFieldProps
): JSX.Element {
  const getOptions = async (value?: string) =>
    (await getCorporateName(value)).data;

  return <SuggestTextField {...props} getOptions={getOptions} />;
}
export default SuggestCorporateNameTextField;
