export function isTestTargetFacility(
  medical_facility_id: number | null
): boolean {
  if (medical_facility_id == null) {
    return false;
  }

  if (process.env.REACT_APP_TEST_TARGET_FACILITY == null) {
    return false;
  }

  const target_facilities =
    process.env.REACT_APP_TEST_TARGET_FACILITY.split(",").map(Number);
  return target_facilities.includes(medical_facility_id);
}
