import { LITE_TARGET_PATH } from "@/sleep_compass_lite/constants";
import { Path } from "@/utils/path";

/** 認証系ページの一覧 */
export type AuthenticationPages =
  | "Confirmation"
  | "ForgotPassword"
  | "Greetings"
  | "RegistrationCompleted"
  | "SetNewPassword"
  | "SignIn"
  | "SignIn2"
  | "SignInSucceeded"
  | "SignUp"
  | "TermsOfService"
  | "TermsOfServiceAgreement";

/**
 * 認証系ページのパスクラスのインスタンス
 * 各ページのパスの宣言やパスの取得で、このインスタンスを使用
 * パス変更時のメンテナンス性とコーディング効率(補完やtypo防止)の向上のために作成
 */
export const authenticationPath = new Path<AuthenticationPages>(
  LITE_TARGET_PATH,
  {
    Greetings: "/greetings/:surveyInfoUid",
    SignIn: "/signin",
    SignIn2: "/signin2",
    SignInSucceeded: "/signin_succeeded",
    SignUp: "/signup",
    ForgotPassword: "/forgot_password",
    SetNewPassword: "/set_new_password",
    TermsOfServiceAgreement: "/terms_of_service_agreement",
    TermsOfService: "/terms_of_service",
    Confirmation: "/confirmation",
    RegistrationCompleted: "/registration_completed",
  }
);
