import { AddButton } from "@/components/common/AddButton";
import { BackdropIndicator } from "@/components/common/BackdropIndicator";
import { clone, equals } from "@/components/common/ObjectService";
import { SCLink } from "@/components/common/SCLink";
import { getProjectErrorMessages } from "@/components/sleep_checkup_v1/CheckupProjectService";
import { DeleteButton } from "@/components/sleep_checkup_v1/DeleteButton";
import { EditButton } from "@/components/sleep_checkup_v1/EditButton";
import { getFileSize } from "@/components/sleep_checkup_v1/FileService";
import { getMedicalFacility } from "@/components/sleep_checkup_v1/MedicalFacilityAPI";
import { SimpleMedicalFacilityUser as FacilityUser } from "@/components/sleep_checkup_v1/MedicalFacilityUser";
import { OrderSelect } from "@/components/sleep_checkup_v1/OrderSelect";
import { PAGE_TITLE, Pages, paths } from "@/components/sleep_checkup_v1/Path";
import { SCDesktopDatePicker } from "@/components/sleep_checkup_v1/SCDesktopDatePicker";
import { SCDialogTitle } from "@/components/sleep_checkup_v1/SCDialogTitle";
import { SCSelect } from "@/components/sleep_checkup_v1/SCSelect";
import { SCSnackbar } from "@/components/sleep_checkup_v1/SCSnackbar";
import {
  Pagination,
  SCEmptyTable,
  SCTable,
} from "@/components/sleep_checkup_v1/SCTable";
import { SCTypography } from "@/components/sleep_checkup_v1/SCTypography";
import { SearchBar } from "@/components/sleep_checkup_v1/SearchBar";
import { fullName as facilityUserFullName } from "@/components/sleep_checkup_v1/SimpleMedicalFacilityUserService";
import {
  createSleepCheckupInfo,
  deleteSleepCheckupInfo,
  editSleepCheckupInfo,
  getAcceptedFacilityUsers,
  getRegistrationPDF,
  getSleepCheckupInfo,
  importCSV,
} from "@/components/sleep_checkup_v1/SleepCheckupInfoAPI";
import {
  CALENDAR_SEARCH_LABEL,
  CALENDAR_SEARCH_SELECTOR_LABEL,
  CalendarSearchType,
  DateValues,
  NULL_VALUE,
  NullValue,
  PARAMS_LABEL,
  REPORT_STATUS_LABEL,
  REPORT_STATUS_SELECTOR_LABEL,
  StringValues,
  clearCalendarType,
  createDateErrorMessages,
  getPeriodFrom,
  getPeriodTo,
  replaceNullValue,
  reportStatus as reportStatusSearchParams,
  value as searchParamsValue,
  setDateString,
  setPeriodFrom,
  setPeriodTo,
  setReportStatus as setReportStatusSearchParams,
  setValue as setSearchParamsValue,
  text,
} from "@/components/sleep_checkup_v1/SleepCheckupInfoSearchParamService";
import {
  CheckupProjectErrorMessage,
  InfoDateStringFields,
  InfoEditErrorMessage,
  InfoFields,
  createErrorMessages,
  createInfo,
  createInfoEdit,
  createUnknownErrorMessages,
  value as infoValue,
  setDateString as setInfoDateString,
  setValue as setInfoValue,
  setProjectName,
} from "@/components/sleep_checkup_v1/SleepCheckupInfoService";
import {
  deviceId,
  deviceLendingPeriod,
  examineeCorporateName,
  examineeDepartmentName,
  examineeFullName,
  examineeFullNameKana,
  medicalUserFullName,
  projectName,
  reportApprovedAt,
  reportStatus,
} from "@/components/sleep_checkup_v1/SleepCheckupService";
import {
  MessageClosure,
  MessageClosureHandler,
  SnackbarContext,
} from "@/components/sleep_checkup_v1/SnackbarContext";
import { SuggestCheckupProjectNameTextField } from "@/components/sleep_checkup_v1/SuggestCheckupProjectNameTextField";
import SuggestCorporateNameTextField from "@/components/sleep_checkup_v1/SuggestCorporateNameTextField";
import { TooltipTypography } from "@/components/sleep_checkup_v1/TooltipTypography";
import { Tutorial } from "@/components/sleep_checkup_v1/Tutorial";
import {
  MedicalFacility,
  ReportStatus,
  SearchParams,
  SleepCheckupInfo,
} from "@/components/sleep_checkup_v1/Types";
import { OVERFLOW_SX } from "@/components/sleep_checkup_v1/TypographySX";
import {
  DAILY_INTERVIEW_STATUS_MAPPING,
  PRIMARY_INTERVIEW_STATUS_MAPPING,
  displayFormat,
  hasGroup,
  internalFormat,
} from "@/components/sleep_checkup_v1/util";
import i18n from "@/i18n/configs";
import ByteUnit from "@/utils/ByteUnit";
import { isTestTargetFacility } from "@/utils/abtest";
import { isValidDateString } from "@/utils/date";
import {
  Add,
  Clear,
  FileUploadOutlined,
  MailOutline,
} from "@mui/icons-material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CheckIcon from "@mui/icons-material/Check";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import SchoolIcon from "@mui/icons-material/School";
import SearchIcon from "@mui/icons-material/Search";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import WatchOutlinedIcon from "@mui/icons-material/WatchOutlined";
import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonProps,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MUILink from "@mui/material/Link";
import { SxProps, Theme } from "@mui/material/styles";
import { AxiosError } from "axios";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { DropzoneState, useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";

/**
 * 睡眠健康測定一覧をAPIから取得する際のインプット/アウトプットをまとめたクラス。インプットはsearchSleepCheckup、アウトプットはsleepCheckupList。
 */
class GetSleepCheckupResult {
  readonly input: SleepCheckupListInput;
  readonly output: SleepCheckupListOutput;

  constructor(input: SleepCheckupListInput, output: SleepCheckupListOutput) {
    this.input = input;
    this.output = output;
  }
}

/**
 * 睡眠健康測定一覧を取得するためのデータをまとめたクラス
 */
class SleepCheckupListInput {
  readonly rowsPerPage: number;
  readonly page: number;
  readonly order: SleepCheckupOrder;
  readonly searchContext: SearchContext;

  constructor(
    rowsPerPage: number,
    page: number,
    order: SleepCheckupOrder,
    searchContext: SearchContext
  ) {
    this.rowsPerPage = rowsPerPage;
    this.page = page;
    this.order = order;
    this.searchContext = searchContext;
  }
}

/**
 * 取得した睡眠健康測定一覧に関するデータをまとめたクラス
 */
class SleepCheckupListOutput {
  readonly list: SleepCheckupInfo[];
  readonly count: number;

  constructor(list: SleepCheckupInfo[], count: number) {
    this.list = list;
    this.count = count;
  }
}

/**
 * 睡眠健康測定の検索パラメータをまとめたクラス
 */
class SearchContext {
  readonly params: SearchParams;
  readonly calendarType: CalendarSearchType | null;

  constructor(params: SearchParams, calendarType: CalendarSearchType | null) {
    this.params = params;
    this.calendarType = calendarType;
  }
}

/**
 * 新規作成や編集の対象になる睡眠健康測定と、それに関するデータ、処理をまとめるためのクラス
 */
class UpdateContext {
  readonly info: SleepCheckupInfo;
  readonly dialogTitle: string;
  readonly buttonProps: UpdateSleepCheckupDialogButtonProps;
  readonly medicalFacilities: MedicalFacility[];
  readonly promise: UpdateSleepCheckupHandler;
  readonly messageClosure: MessageClosure;
  readonly deleteHandler: SleepCheckupInfoHandler | null;

  constructor(
    info: SleepCheckupInfo,
    dialogTitle: string,
    buttonProps: UpdateSleepCheckupDialogButtonProps,
    medicalFacilities: MedicalFacility[],
    promise: UpdateSleepCheckupHandler,
    messageClosure: MessageClosure,
    deleteHandler: SleepCheckupInfoHandler | null
  ) {
    this.info = info;
    this.dialogTitle = dialogTitle;
    this.buttonProps = buttonProps;
    this.medicalFacilities = medicalFacilities;
    this.promise = promise;
    this.messageClosure = messageClosure;
    this.deleteHandler = deleteHandler;
  }
}

/**
 * 施設の情報をまとめたクラス
 */
class Organization {
  readonly facilities: MedicalFacility[];
  readonly users: FacilityUser[];

  constructor(facilities: MedicalFacility[] = [], users: FacilityUser[] = []) {
    this.facilities = facilities;
    this.users = users;
  }
}

/**
 * 測定削除時のエラー
 */
class DeleteSleepCheckupError extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "DeleteSleepCheckupError";
  }
}

type SleepCheckupOrder =
  | "accepted_at"
  | "-accepted_at"
  | "date_device_sent"
  | "-date_device_sent"
  | "date_device_returned"
  | "-date_device_returned"
  | "medical_examinee_id_in_facility"
  | "medical_examinee_last_name_kana,medical_examinee_first_name_kana"
  | "report_approved_at"
  | "-report_approved_at";

type SleepCheckupInfoHandler = (info: SleepCheckupInfo) => void;

type SearchSleepCheckupHandler = (
  params: SearchParams,
  calendarType: CalendarSearchType | null
) => void;

type ChangePageHandler = (newPage: number) => void;

type RowsPerPageChangeHandler = (newRowsPerPage: number) => void;

type UpdateSleepCheckupHandler = (info: SleepCheckupInfo) => Promise<any>;

// Note: DATE_INPUT_EXAMPLEは複数のコンポーネントで共有するので、コンポーネント外に定義する
const DATE_INPUT_EXAMPLE = "例：2022-03-09";

const createParams = () => new SearchParams();

const createInput = (
  rowsPerPage: number = 10,
  page: number = 1,
  order: SleepCheckupOrder = "-accepted_at",
  searchContext: SearchContext = new SearchContext(createParams(), null)
) => new SleepCheckupListInput(rowsPerPage, page, order, searchContext);

const createPagination = (
  input: SleepCheckupListInput,
  output: SleepCheckupListOutput
) => new Pagination(input.rowsPerPage, input.page, output.count);

type SleepCheckupListProps = {
  minWidth: number;
};
export function SleepCheckupList({ minWidth }: SleepCheckupListProps) {
  const [showIndicator, setShowIndicator] = useState(false);
  const [update, setUpdate] = useState<UpdateContext | null>(null);
  const [input, setInput] = useState<SleepCheckupListInput>(createInput());
  const [output, setOutput] = useState<SleepCheckupListOutput | null>(null);
  const [organization, setOrganization] = useState<Organization>(
    new Organization()
  );
  const [searchDialogContext, setSearchDialogContext] =
    useState<SearchContext | null>(null);
  const [snackbarContext, setSnackbarContext] =
    useState<SnackbarContext | null>(null);
  const [confirmationProps, setConfirmationProps] =
    useState<ConfirmationDialogProps | null>(null);
  const [toShowTutorial, setToShowTutorial] = useState<boolean | null>(null);
  const [showImportCSV, setShowImportCSV] = useState(false);

  const ROWS_PER_PAGE_OPTION = [10, 25, 50, 100];
  const ORDER_ITEM: Map<SleepCheckupOrder, string> = new Map([
    ["-accepted_at", "受付日が新しい順"],
    ["accepted_at", "受付日が古い順"],
    ["medical_examinee_id_in_facility", "施設内受診者ID順"],
    [
      "medical_examinee_last_name_kana,medical_examinee_first_name_kana",
      "受診者氏名(カナ)順",
    ],
    ["-date_device_sent", "デバイス貸出日が新しい順"],
    ["date_device_sent", "デバイス貸出日が古い順"],
    ["-date_device_returned", "デバイス返却日が新しい順"],
    ["date_device_returned", "デバイス返却日が古い順"],
    ["-report_approved_at", "レポート発行日が新しい順"],
    ["report_approved_at", "レポート発行日が古い順"],
  ]);
  const TABLE_HEADERS = [
    "",
    "施設内受診者ID",
    "受診者氏名",
    "デバイス貸出期間",
    "レポート状況",
    "操作",
  ];

  const getSleepCheckup = (input: SleepCheckupListInput) => {
    return getSleepCheckupInfo(
      input.searchContext.params,
      input.page,
      input.order,
      input.rowsPerPage
    ).then((res) => {
      const { count, results } = res.data;
      const output = new SleepCheckupListOutput(results, count);
      return new GetSleepCheckupResult(input, output);
    });
  };

  const succeeded = useCallback((result: GetSleepCheckupResult) => {
    setOutput(result.output);
  }, []);

  const failed = (err: any) => {
    setSnackbarContext(
      new SnackbarContext(`測定一覧の取得に失敗しました (${err})`, "error")
    );
  };

  useEffect(() => {
    Promise.all([
      getSleepCheckup(input),
      getMedicalFacility(),
      getAcceptedFacilityUsers(),
      getSleepCheckup(createInput(1)),
    ])
      .then((res) => {
        const [
          getSleepCheckupInfoResult,
          getMedicalFacilityResult,
          getAcceptedFacilityUsersResult,
          allSleepCheckupInfoResult,
        ] = res;
        succeeded(getSleepCheckupInfoResult);
        setOrganization(
          new Organization(
            getMedicalFacilityResult.data,
            getAcceptedFacilityUsersResult.data
          )
        );

        // 測定が0件であればチュートリアルを表示
        setToShowTutorial(allSleepCheckupInfoResult.output.list.length === 0);

        // ローディング表示（並び替えの時などに表示されている）を消す
        setShowIndicator(false);
      })
      .catch(failed);
  }, [succeeded, input]);

  useEffect(() => {
    (async () => {
      if (searchDialogContext == null) return;

      // 検索ダイアログを開く時に、再取得を行う(主に受付者)
      const res = await Promise.all([
        getMedicalFacility(),
        getAcceptedFacilityUsers(),
      ]);
      const [facilities, users] = res.map((r) => r.data);
      setOrganization({
        facilities,
        users,
      });
    })();
  }, [searchDialogContext]);

  const showSearchBarCancelButton =
    !equals(createParams(), input.searchContext.params) ||
    input.searchContext.calendarType != null;

  // 新しい睡眠健康測定を登録 ダイアログを開く
  const handleCreateSleepCheckupDialogOpen = () => {
    if (organization.facilities.length === 0) {
      throw new Error("facilities are empty");
    }

    const context = new UpdateContext(
      createInfo(organization.facilities[0]),
      "新しい睡眠健康測定を登録",
      createButtonProps,
      organization.facilities,
      (info) => {
        return createSleepCheckupInfo(createInfoEdit(info));
      },
      () => {
        setToShowTutorial(false); // 測定が登録されたらチュートリアルを非表示にする
        return new SnackbarContext("新しい測定が追加されました", "success");
      },
      null
    );
    setUpdate(context);
  };

  const handleEditSleepCheckupDialogOpen: SleepCheckupInfoHandler = (info) => {
    const context = new UpdateContext(
      clone(info),
      "睡眠健康測定を編集",
      editButtonProps,
      organization.facilities,
      (info) => {
        return editSleepCheckupInfo(createInfoEdit(info));
      },
      () => {
        return new SnackbarContext("測定が編集されました", "success");
      },
      info.activated ? null : handleDeleteConfirmationOpen
    );
    setUpdate(context);
  };

  const handleUpdateSleepCheckup: MessageClosureHandler = (closure) => {
    getSleepCheckup(input)
      .then((result) => {
        setUpdate(null);
        succeeded(result);
        setSnackbarContext(closure());
      })
      .catch(failed);
  };

  const handleDeleteConfirmationOpen: SleepCheckupInfoHandler = (info) => {
    const props = {
      title: "睡眠健康測定を削除",
      message: "本当にこの測定を削除しますか？",
      action: {
        title: "削除する",
        onClick: () => {
          handleDeleteSleepCheckup(info);
        },
      },
      onClickClose: () => {
        setConfirmationProps(null);
      },
    };

    setConfirmationProps(props);
  };

  const handleDeleteSleepCheckup: SleepCheckupInfoHandler = (info) => {
    deleteSleepCheckupInfo(info)
      .catch((err) => Promise.reject(new DeleteSleepCheckupError(`${err}`)))
      .then(() => getSleepCheckup(input))
      .then((result) => {
        succeeded(result);
        setSnackbarContext(
          new SnackbarContext(`測定が削除されました`, "success")
        );
      })
      .catch((err) => {
        if (err instanceof DeleteSleepCheckupError) {
          setSnackbarContext(
            new SnackbarContext(
              `測定を削除できませんでした。もう一度お試しください。${err.message}`,
              "error"
            )
          );
        } else {
          failed(err);
        }
      })
      .finally(() => {
        setConfirmationProps(null);
        setUpdate(null);
      });
  };

  const handleUpdateDialogClose = () => {
    setUpdate(null);
  };
  const handleImportCSVSuccess = async () => {
    try {
      setShowImportCSV(false);
      const result = await getSleepCheckup(input);
      succeeded(result);
      setSnackbarContext(
        new SnackbarContext("新しい測定が追加されました", "success")
      );
    } catch (e) {
      failed(e);
    }
  };

  const handleSearchDialogOpen = () => {
    setSearchDialogContext(
      new SearchContext(
        clone(input.searchContext.params),
        input.searchContext.calendarType
      )
    );
  };

  const handleSearchDialogClose = () => {
    setSearchDialogContext(null);
  };

  const handleClearSearchParam = () => {
    // inputを更新することで、useEffectを介して、測定一覧の更新をする
    setInput(createInput());
  };

  const handleSearchSleepCheckup: SearchSleepCheckupHandler = (
    params,
    calendarType
  ) => {
    // inputを更新することで、useEffectを介して、測定一覧の更新をする
    setInput(
      new SleepCheckupListInput(
        input.rowsPerPage,
        1,
        input.order,
        new SearchContext(params, calendarType)
      )
    );
    setSearchDialogContext(null);
  };

  const handleChangeOrder = (order: SleepCheckupOrder) => {
    // inputを更新することで、useEffectを介して、測定一覧の更新をする
    setShowIndicator(true);
    setInput(
      new SleepCheckupListInput(
        input.rowsPerPage,
        1,
        order,
        input.searchContext
      )
    );
  };

  const handlePrintQR: SleepCheckupInfoHandler = (info) => {
    setShowIndicator(true);

    const version = isTestTargetFacility(info.medical_facility) ? "1_1" : "1_0";

    getRegistrationPDF(info, version)
      .then((res) => {
        window.open(URL.createObjectURL(res.data), "_blank");
      })
      .finally(() => {
        setShowIndicator(false);
      });
  };

  const handlePageChange: ChangePageHandler = (newPage) => {
    // inputを更新することで、useEffectを介して、測定一覧の更新をする
    setInput(
      new SleepCheckupListInput(
        input.rowsPerPage,
        newPage,
        input.order,
        input.searchContext
      )
    );
  };

  const handleRowsPerPageChange: RowsPerPageChangeHandler = (
    newRowsPerPage
  ) => {
    // inputを更新することで、useEffectを介して、測定一覧の更新をする
    setInput(
      new SleepCheckupListInput(
        newRowsPerPage,
        1,
        input.order,
        input.searchContext
      )
    );
  };

  const handleSnackbarClose = () => {
    setSnackbarContext(null);
  };

  const searchBarText = text(
    organization.users,
    input.searchContext.params,
    input.searchContext.calendarType
  );
  const searchBarColor =
    searchBarText != null ? "black.default" : "text.disabled";

  return (
    <>
      <BackdropIndicator open={showIndicator} />
      {toShowTutorial === true && (
        <Tutorial
          minWidth={minWidth}
          onClickAddNewSleepCheckup={handleCreateSleepCheckupDialogOpen}
          onClickImportCSV={() => setShowImportCSV(true)}
        />
      )}
      {toShowTutorial === false && (
        <Stack spacing={6} sx={{ mx: 10, my: 6, minWidth: minWidth }}>
          <Typography variant="h3">{PAGE_TITLE["SleepCheckupList"]}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Stack direction="row" alignItems="center" spacing={4}>
              {!hasGroup("facility_report_confirmor") && (
                <>
                  <AddButton onClick={handleCreateSleepCheckupDialogOpen}>
                    新しい測定を登録
                  </AddButton>
                  <ImportCSVButton onClick={() => setShowImportCSV(true)} />
                </>
              )}
            </Stack>
            <Stack direction="row" spacing={3}>
              <SearchBar
                value={searchBarText ?? "氏名、ID等で検索"}
                color={searchBarColor}
                sx={{ width: "300px" }}
                onClick={handleSearchDialogOpen}
                onClickCancel={
                  showSearchBarCancelButton ? handleClearSearchParam : undefined
                }
              />
              <OrderSelect<SleepCheckupOrder>
                items={ORDER_ITEM}
                value={input.order}
                onChange={handleChangeOrder}
              />
            </Stack>
          </Box>
          {output !== null && output.list.length > 0 && (
            <SCTable<SleepCheckupInfo>
              headers={TABLE_HEADERS}
              list={output.list}
              pagination={createPagination(input, output)}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTION}
              tableRow={(info, i) => {
                return (
                  <SleepCheckupTableRow
                    sleepCheckupInfo={info}
                    key={i}
                    onClickPrintQR={handlePrintQR}
                    onClickEdit={handleEditSleepCheckupDialogOpen}
                  />
                );
              }}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              maxHeight="60vh"
            />
          )}
          {output !== null && output.list.length === 0 && (
            <SCEmptyTable
              headers={TABLE_HEADERS}
              message="検索条件に一致する結果が見つかりませんでした。"
            />
          )}
        </Stack>
      )}
      {update !== null && (
        <UpdateSleepCheckupDialog
          open={update !== null}
          context={update}
          onUpdateSleepCheckup={handleUpdateSleepCheckup}
          onClickClose={handleUpdateDialogClose}
        />
      )}
      {searchDialogContext != null && (
        <SearchSleepCheckupDialog
          open={true}
          params={searchDialogContext.params}
          facilityUsers={organization.users}
          calendar={searchDialogContext.calendarType}
          onClickSearch={handleSearchSleepCheckup}
          onClickClose={handleSearchDialogClose}
        />
      )}
      <ImportCSVDialog
        open={showImportCSV}
        onClickClose={() => setShowImportCSV(false)}
        onSuccess={handleImportCSVSuccess}
        organization={organization}
      />
      {confirmationProps && <ConfirmationDialog {...confirmationProps} />}
      {snackbarContext != null && (
        <SCSnackbar
          open={true}
          severity={snackbarContext.severity}
          onClose={() => handleSnackbarClose()}
        >
          {snackbarContext.message}
        </SCSnackbar>
      )}
    </>
  );
}

type ImportCSVButtonProps = {
  size?: ButtonProps["size"];
  onClick: ButtonProps["onClick"];
};
export function ImportCSVButton({ onClick, size }: ImportCSVButtonProps) {
  return (
    <Button
      variant="outlined"
      startIcon={<FileUploadOutlined />}
      size={size ?? "small"}
      onClick={onClick}
    >
      CSVインポート
    </Button>
  );
}

type ImportCSVDialogProps = {
  open: DialogProps["open"];
  onClickClose: () => void;
  onSuccess: () => void;
  organization: Organization;
};
function ImportCSVDialog({
  open,
  onClickClose,
  onSuccess,
  organization,
}: ImportCSVDialogProps) {
  const [file, setFile] = useState<File | null>(null);
  const [importing, setImporting] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      return;
    }

    // ダイアログが閉じられるタイミングで、stateを初期化する
    setFile(null);
    setImporting(false);
    setErrorMessages([]);
  }, [open]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return;
      }

      setFile(acceptedFiles[0]);
    },
    [setFile]
  );

  const handleImport = async () => {
    if (file == null || importing) {
      return;
    }
    if (organization.facilities.length === 0) {
      throw new Error("facilities are empty");
    }
    setImporting(true);
    try {
      await importCSV(organization.facilities[0].id, file);
      onSuccess();
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 400) {
          // 400 BadRequest の場合、入力エラー
          const data = err.response.data as (
            | string
            | Record<string, string[]>
          )[];
          const messages: string[] = [];
          data.forEach((d, i: number) => {
            if (typeof d === "string") {
              messages.push(d);
            } else {
              for (const [label, message] of Object.entries(d)) {
                if (label === "project") {
                  // projectキーのバリューはオブジェクトなので、オブジェクトからエラーメッセージを取り出す
                  for (const m of getProjectErrorMessages(message)) {
                    messages.push(`${i + 1} 行目: ${m}`);
                  }
                } else {
                  messages.push(`${i + 1} 行目: ${label}: ${message}`);
                }
              }
            }
          });
          setErrorMessages(messages);
        } else {
          // 入力エラー以外の場合
          setErrorMessages([err.toString()]);
        }
      } else {
        // Axiosエラー以外の場合
        setErrorMessages([(err as Error).message]);
      }
    } finally {
      setImporting(false);
    }
  };

  const handleClose = () => {
    setFile(null);
    setImporting(false);
    setErrorMessages([]);
  };

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    inputRef,
  } = useDropzone({
    onDrop,
    noClick: true,
    accept: { "text/csv": [".csv"] },
    maxSize: ByteUnit.BYTE.convert(3, "MiB"), // 最大3MiB
  });

  useEffect(() => {
    const errors: string[] = [];
    fileRejections.forEach((fileRejection) => {
      fileRejection.errors.forEach((error) => {
        errors.push(i18n.t(`dropzone-${error.code}`));
      });
      setFile(fileRejection.file);
    });
    setErrorMessages(errors);
  }, [fileRejections]);

  const border =
    errorMessages.length > 0
      ? `1px solid #FF3A30`
      : `1px dashed ${isDragActive ? "#0056A0" : "#E0E0E0"}`;
  const backgroundColor =
    errorMessages.length > 0 ? `rgba(255, 58, 48, 0.04)` : `inherit`;

  return (
    <Dialog open={open} maxWidth="md">
      <SCDialogTitle onClickClose={onClickClose} noCloseButton={importing}>
        CSVインポート
      </SCDialogTitle>
      <DialogContent sx={{ maxHeight: "100vh" }}>
        <Stack spacing={10} alignItems="flex-end" sx={{ mt: 6, mb: 6 }}>
          <Stack
            sx={{
              width: 448,
              border,
              backgroundColor,
              borderRadius: "4px",
            }}
            justifyContent="center"
            alignItems="center"
          >
            {file == null && (
              <CSVDropzone
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                inputRef={inputRef}
              />
            )}
            {file != null && (
              <ImportCSVPanel
                file={file}
                showProgress={importing}
                onClickClose={handleClose}
                errorMessages={errorMessages}
              />
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pt: 4 }}>
        <AddButton
          onClick={handleImport}
          disabled={file == null || importing || errorMessages.length !== 0}
        >
          登録する
        </AddButton>
      </DialogActions>
    </Dialog>
  );
}

type CSVDropzoneProps = {
  getRootProps: DropzoneState["getRootProps"];
  getInputProps: DropzoneState["getRootProps"];
  inputRef: DropzoneState["inputRef"];
};
function CSVDropzone({
  getRootProps,
  getInputProps,
  inputRef,
}: CSVDropzoneProps) {
  const FONT_WEIGHT = 400;
  const onClickLabel = () => {
    inputRef.current?.click();
  };
  return (
    <div {...getRootProps()} style={{ width: "100%", height: "100%" }}>
      <input {...getInputProps()} />
      <Stack alignItems="center" sx={{ my: 6 }} spacing={2}>
        <UploadFileIcon
          sx={{ width: 40, height: 40, color: "text.secondary" }}
        />
        <Stack direction="row" spacing={1}>
          <MUILink
            onClick={onClickLabel}
            variant="subtitle1"
            color="primary"
            fontWeight={FONT_WEIGHT}
            sx={{ cursor: "pointer" }}
          >
            ファイルを選択
          </MUILink>
          <Typography variant="subtitle1" fontWeight={FONT_WEIGHT}>
            もしくはドラッグ&ドロップ
          </Typography>
        </Stack>
        <Typography variant="body2" color="text.secondary">
          CSV (max. 3MB)
        </Typography>
      </Stack>
    </div>
  );
}

type ImportCSVPanelProps = {
  file: File;
  showProgress: boolean;
  onClickClose: () => void;
  errorMessages: string[];
};
function ImportCSVPanel({
  file,
  showProgress,
  onClickClose,
  errorMessages,
}: ImportCSVPanelProps) {
  return (
    <Stack
      direction="row"
      spacing={4}
      alignItems="flex-start"
      sx={{ margin: "24px" }}
    >
      <Avatar
        sx={{
          color: "#FFFFFF",
          backgroundColor: errorMessages.length
            ? "#FFB0AC"
            : "rgba(0, 86, 160, 0.12)",
        }}
      >
        <UploadFileIcon />
      </Avatar>

      <Stack sx={{ width: 264 }} spacing={5}>
        <Stack>
          <Typography
            variant="subtitle1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {file.name}
          </Typography>
          {errorMessages.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              {getFileSize(file)}
            </Typography>
          )}
          {errorMessages.length !== 0 && (
            <List
              sx={{
                overflowY: "auto",
                width: "100%",
                maxHeight: "calc(100vh - 460px)",
                padding: 0,
              }}
            >
              {errorMessages.map((e, i) => (
                <ListItem key={i} sx={{ padding: 0 }}>
                  <Typography sx={{ color: "error.main", fontSize: "14px" }}>
                    {e}
                  </Typography>
                </ListItem>
              ))}
            </List>
          )}
          {errorMessages.length > 0 && (
            <LinearProgress
              variant="determinate"
              value={0}
              sx={{
                backgroundColor: "#FFB0AC",
                marginTop: "16px",
              }}
            />
          )}
        </Stack>
        {showProgress && <LinearProgress sx={{ height: 4 }} />}
      </Stack>
      <Stack
        sx={{ width: 48, height: 48 }}
        justifyContent="center"
        alignItems="center"
      >
        {!showProgress && (
          <IconButton onClick={onClickClose}>
            <Clear sx={{ width: 24, height: 24 }} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}

type SleepCheckupTableRowProps = {
  sleepCheckupInfo: SleepCheckupInfo;
  onClickPrintQR: SleepCheckupInfoHandler;
  onClickEdit: SleepCheckupInfoHandler;
};
function SleepCheckupTableRow({
  sleepCheckupInfo,
  onClickPrintQR,
  onClickEdit,
}: SleepCheckupTableRowProps) {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(false);
  }, [sleepCheckupInfo]);

  const FIRST_COLUMN_WIDTH = 14;
  const MAX_CELL_WIDTH = "188px";
  const CARD_WIDTH = "256px";
  const TEXT_BUTTON_SIZE = "small";

  const status = reportStatus(sleepCheckupInfo);

  return (
    <>
      <TableRow sx={{ "& .MuiTableCell-body": { borderBottom: "unset" } }}>
        <TableCell width={FIRST_COLUMN_WIDTH}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ maxWidth: MAX_CELL_WIDTH }}>
          <TooltipTypography variant="body2" sx={OVERFLOW_SX}>
            {sleepCheckupInfo.medical_examinee_id_in_facility}
          </TooltipTypography>
        </TableCell>
        <TableCell sx={{ maxWidth: MAX_CELL_WIDTH }}>
          <TooltipTypography variant="body2" sx={OVERFLOW_SX}>
            {examineeFullName(sleepCheckupInfo)}
          </TooltipTypography>
        </TableCell>
        <TableCell>{deviceLendingPeriod(sleepCheckupInfo)}</TableCell>
        <TableCell>
          {status === "CHECKINGUP" && (
            <CheckingUpButton size={TEXT_BUTTON_SIZE} />
          )}
          {status === "APPROVED" && (
            <OpenReportButton
              startIcon={<MailOutline />}
              disabled={false}
              size={TEXT_BUTTON_SIZE}
              to={paths.getFullPath("SleepCheckupDetail", sleepCheckupInfo.id)}
              sleepCheckupInfo={sleepCheckupInfo}
            >
              送付待ち
            </OpenReportButton>
          )}
          {status === "SENT" && (
            <HaveSentReportButton size={TEXT_BUTTON_SIZE} />
          )}
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={4}>
            <PrintQRButton
              size={TEXT_BUTTON_SIZE}
              disabled={hasGroup("facility_report_confirmor")}
              onClick={() => onClickPrintQR(sleepCheckupInfo)}
            />
            <OpenReportButton
              startIcon={<DescriptionOutlinedIcon />}
              disabled={status === "CHECKINGUP"}
              size={TEXT_BUTTON_SIZE}
              to={paths.getFullPath("SleepCheckupDetail", sleepCheckupInfo.id)}
              sleepCheckupInfo={sleepCheckupInfo}
            >
              レポート確認
            </OpenReportButton>
            <OpenReportButton
              startIcon={<SchoolIcon />}
              disabled={!sleepCheckupInfo.is_worksheet_openable}
              size={TEXT_BUTTON_SIZE}
              to={paths.getFullPath(
                "SleepCheckupGuidance",
                sleepCheckupInfo.id
              )}
              sleepCheckupInfo={sleepCheckupInfo}
            >
              睡眠指導
            </OpenReportButton>
            {!hasGroup("facility_report_confirmor") && (
              <EditButton
                variant="text"
                size={TEXT_BUTTON_SIZE}
                onClick={() => onClickEdit(sleepCheckupInfo)}
              />
            )}
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={6}
          sx={{ pt: 0, pb: 0, backgroundColor: "black.1" }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack
              direction="row"
              spacing={8}
              sx={{ py: 6, px: FIRST_COLUMN_WIDTH }}
            >
              <ExamineeProfileCard
                sleepCheckupInfo={sleepCheckupInfo}
                sx={{ minWidth: CARD_WIDTH }}
              />
              <AdministrationInfoCard
                sleepCheckupInfo={sleepCheckupInfo}
                sx={{ minWidth: CARD_WIDTH }}
              />
              <InterviewCard
                sx={{ minWidth: CARD_WIDTH }}
                info={sleepCheckupInfo}
              />
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

type ExamineeProfileCardProps = {
  sx?: SxProps<Theme>;
  sleepCheckupInfo: any;
};
function ExamineeProfileCard({
  sx,
  sleepCheckupInfo,
}: ExamineeProfileCardProps) {
  const items = new Map([
    ["フリガナ", examineeFullNameKana(sleepCheckupInfo)],
    ["生年月日", displayFormat(sleepCheckupInfo.medical_examinee_birthday)],
    ["企業名", examineeCorporateName(sleepCheckupInfo)],
    ["部署名", examineeDepartmentName(sleepCheckupInfo)],
  ]);

  return <SCInfoCard title="受診者情報" items={items} sx={sx} />;
}

type AdministrationInfoCardProps = {
  sx?: SxProps<Theme>;
  sleepCheckupInfo: any;
};
function AdministrationInfoCard({
  sx,
  sleepCheckupInfo,
}: AdministrationInfoCardProps) {
  const items = new Map([
    ["デバイスID", deviceId(sleepCheckupInfo)],
    ["受付日", displayFormat(sleepCheckupInfo.accepted_at)],
    ["受付担当者", medicalUserFullName(sleepCheckupInfo)],
    ["レポート発行日", reportApprovedAt(sleepCheckupInfo)],
    ["プロジェクト名", projectName(sleepCheckupInfo)],
  ]);
  return <SCInfoCard title="管理情報" items={items} sx={sx} />;
}

type SCInfoCardProps = {
  sx?: SxProps<Theme>;
  title: string;
  items: Map<string, string>;
};
function SCInfoCard({ sx, title, items }: SCInfoCardProps) {
  const SPACING = 2;
  const captionMaxLen = Math.max(...[...items.keys()].map((c) => c.length));
  const FONT_SIZE = 12;
  const captionWidth = FONT_SIZE * captionMaxLen;

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack spacing={SPACING}>
          <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
            {title}
          </Typography>
          {[...items.entries()].map(([caption, value], i) => {
            return (
              <Stack direction="row" spacing={4} key={i}>
                <Typography
                  width={captionWidth}
                  variant="caption"
                  sx={{ color: "text.secondary" }}
                >
                  {caption}
                </Typography>
                <TooltipTypography
                  variant="caption"
                  sx={{
                    maxWidth: "18vw",
                    ...OVERFLOW_SX,
                  }}
                >
                  {value}
                </TooltipTypography>
              </Stack>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
}

type InterviewCardProps = {
  sx?: SxProps<Theme>;
  info: SleepCheckupInfo;
};
function InterviewCard({ sx, info }: InterviewCardProps) {
  const SPACING = 2;
  const FONT_SIZE = 12;
  const DETAIL_BUTTON_TITLE = "詳細を見る";

  const items = [
    {
      caption: "アカウント登録",
      status: info.activated ? "登録済み" : "未登録",
    },
    {
      caption: "事前問診",
      status:
        PRIMARY_INTERVIEW_STATUS_MAPPING.get(info.primary_interview.status) ??
        "不明",
      page:
        info.primary_interview.status === "unanswered"
          ? ""
          : "PrimaryInterviewResult",
    },
    {
      caption: "毎日問診",
      status:
        DAILY_INTERVIEW_STATUS_MAPPING.get(info.daily_interview.status) ??
        "不明",
      page:
        info.daily_interview.status === "unanswered"
          ? ""
          : "DailyInterviewResult",
    },
  ];

  const captionMaxLen = Math.max(...items.map(({ caption }) => caption.length));
  const captionWidth = captionMaxLen * FONT_SIZE;
  const statusMaxLen = Math.max(...items.map(({ status }) => status.length));
  const statusWidth = statusMaxLen * FONT_SIZE;

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack spacing={SPACING}>
          <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
            測定進捗
          </Typography>
          {items.map(({ caption, status, page }, i) => {
            return (
              <Stack direction="row" spacing={4} key={i}>
                <Typography
                  width={captionWidth}
                  variant="caption"
                  sx={{ color: "text.secondary" }}
                >
                  {caption}
                </Typography>
                <Typography width={statusWidth} variant="caption">
                  {status}
                </Typography>
                {page && (
                  <SCLink
                    variant="caption"
                    to={paths.getFullPath(page as Pages, info.id)}
                    state={info}
                  >
                    {DETAIL_BUTTON_TITLE}
                  </SCLink>
                )}
              </Stack>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
}

type CheckingUpButtonProps = {
  size: ButtonProps["size"];
};
function CheckingUpButton({ size }: CheckingUpButtonProps) {
  return (
    <Button startIcon={<WatchOutlinedIcon />} disabled size={size}>
      測定中
    </Button>
  );
}

type HaveSentReportButtonProps = {
  size: ButtonProps["size"];
};
function HaveSentReportButton({ size }: HaveSentReportButtonProps) {
  return (
    <Button startIcon={<CheckIcon />} disabled size={size}>
      送付済み
    </Button>
  );
}

type PrintQRButtonProps = ButtonProps;
function PrintQRButton({ startIcon, children, ...props }: PrintQRButtonProps) {
  return (
    <Button
      {...props}
      startIcon={startIcon ?? <LocalPrintshopOutlinedIcon />}
      children={children ?? "資料印刷"}
    />
  );
}

type OpenReportButtonProps = {
  startIcon: ButtonProps["startIcon"];
  disabled: boolean;
  size: ButtonProps["size"];
  to: string;
  sleepCheckupInfo: SleepCheckupInfo;
  children: ReactNode;
};
function OpenReportButton({
  startIcon,
  disabled,
  size,
  to,
  sleepCheckupInfo,
  children,
}: OpenReportButtonProps) {
  return (
    <Button
      startIcon={startIcon}
      disabled={disabled}
      size={size}
      component={Link}
      to={to}
      state={sleepCheckupInfo}
    >
      {children}
    </Button>
  );
}

type UpdateSleepCheckupDialogProps = {
  open: DialogProps["open"];
  context: UpdateContext;
  onUpdateSleepCheckup: MessageClosureHandler;
  onClickClose: () => void;
};
type UpdateSleepCheckupDialogButtonProps = {
  title: string;
  icon: ButtonProps["startIcon"];
};
function UpdateSleepCheckupDialog({
  open,
  context,
  onUpdateSleepCheckup,
  onClickClose,
}: UpdateSleepCheckupDialogProps) {
  const SUBTITLE_SPACING = 1;
  const ROW_SPACING = 4;
  const COLUMN_SPACING = 8;
  const FIELD_WIDTH = "200px";
  const FIELD_HEIGHT = "52px";
  const SX = {
    "&.MuiTextField-root": { height: "auto" },
    width: FIELD_WIDTH,
    height: FIELD_HEIGHT,
  };
  const TOTAL_ERROR_SX = {
    color: "error.main",
    fontSize: "14px",
    maxWidth: "366px",
  };
  const [info, setInfo] = useState<SleepCheckupInfo>(context.info);
  const [errorMessages, setErrorMessages] = useState<InfoEditErrorMessage>(
    createErrorMessages(null)
  );
  const [dateStringBirthday, setDateStringBirthday] = useState<string | null>(
    context.info.medical_examinee_birthday
  );
  const [dateStringDeviceSent, setDateStringDeviceSent] = useState<
    string | null
  >(context.info.date_device_sent);
  const [dateStringDeviceReturned, setDateStringDeviceReturned] = useState<
    string | null
  >(context.info.date_device_returned);

  const handleValueChange = (key: InfoFields, value: string) => {
    setInfo(setInfoValue(key, value, info));
  };

  const handleDateStringChange = (
    key: InfoDateStringFields,
    value: string | null
  ) => {
    setInfo(setInfoDateString(key, value, info));
  };

  const handleExamineeBirthdayChange = (
    key: InfoDateStringFields,
    value: string | null
  ) => {
    setInfo(setInfoDateString(key, value, info));
  };

  const handleProjectNameChange = (value: string | null) => {
    setInfo(setProjectName(value, info));
  };

  const handleDialogClose = () => {
    onClickClose();
  };

  const handleUpdateButtonClick = () => {
    context
      .promise(info)
      .then(() => onUpdateSleepCheckup(context.messageClosure))
      .catch((err) => {
        if (err.response?.status === 400) {
          const messages = createErrorMessages(err.response.data);
          setErrorMessages(messages);
        } else {
          setErrorMessages(createUnknownErrorMessages());
        }
      });
  };

  const getProjectErrorMessage = (
    errorMessages: InfoEditErrorMessage
  ): CheckupProjectErrorMessage | undefined => {
    const ret = errorMessages.has("project")
      ? (errorMessages.get("project") as CheckupProjectErrorMessage)
      : undefined;
    return ret;
  };

  const totalErrorMessage = errorMessages.get("total_error");

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <SCDialogTitle onClickClose={handleDialogClose}>
        {context.dialogTitle}
      </SCDialogTitle>
      <DialogContent>
        <Stack spacing={8}>
          <Stack spacing={SUBTITLE_SPACING}>
            {totalErrorMessage && (
              <Alert severity="error">
                <Typography sx={TOTAL_ERROR_SX}>{totalErrorMessage}</Typography>
              </Alert>
            )}
          </Stack>
          <Stack spacing={SUBTITLE_SPACING}>
            <SCTypography
              startIcon={<FaceOutlinedIcon color="primary" />}
              variant="subtitle2"
            >
              受診者情報
            </SCTypography>
            <Stack spacing={ROW_SPACING}>
              <Stack direction="row" spacing={COLUMN_SPACING}>
                <TextField
                  variant="standard"
                  label="姓"
                  sx={SX}
                  required={!info.activated}
                  disabled={info.activated}
                  value={infoValue("medical_examinee_last_name", info)}
                  error={
                    errorMessages.get("medical_examinee_last_name")
                      ? true
                      : false
                  }
                  helperText={errorMessages.get("medical_examinee_last_name")}
                  id="medical_examinee_last_name"
                  onChange={(event) => {
                    handleValueChange(
                      "medical_examinee_last_name",
                      event.target.value
                    );
                  }}
                />
                <TextField
                  variant="standard"
                  label="名"
                  sx={SX}
                  required={!info.activated}
                  disabled={info.activated}
                  value={infoValue("medical_examinee_first_name", info)}
                  error={
                    errorMessages.get("medical_examinee_first_name")
                      ? true
                      : false
                  }
                  helperText={errorMessages.get("medical_examinee_first_name")}
                  id="medical_examinee_first_name"
                  onChange={(event) => {
                    handleValueChange(
                      "medical_examinee_first_name",
                      event.target.value
                    );
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={COLUMN_SPACING}>
                <TextField
                  variant="standard"
                  label="セイ"
                  sx={SX}
                  required={!info.activated}
                  disabled={info.activated}
                  value={infoValue("medical_examinee_last_name_kana", info)}
                  error={
                    errorMessages.get("medical_examinee_last_name_kana")
                      ? true
                      : false
                  }
                  helperText={errorMessages.get(
                    "medical_examinee_last_name_kana"
                  )}
                  id="medical_examinee_last_name_kana"
                  onChange={(event) => {
                    handleValueChange(
                      "medical_examinee_last_name_kana",
                      event.target.value
                    );
                  }}
                />
                <TextField
                  variant="standard"
                  label="メイ"
                  sx={SX}
                  required={!info.activated}
                  disabled={info.activated}
                  value={infoValue("medical_examinee_first_name_kana", info)}
                  error={
                    errorMessages.get("medical_examinee_first_name_kana")
                      ? true
                      : false
                  }
                  helperText={errorMessages.get(
                    "medical_examinee_first_name_kana"
                  )}
                  id="medical_examinee_first_name_kana"
                  onChange={(event) => {
                    handleValueChange(
                      "medical_examinee_first_name_kana",
                      event.target.value
                    );
                  }}
                />
              </Stack>
              <SCDesktopDatePicker
                variant="standard"
                label={`生年月日 (${DATE_INPUT_EXAMPLE})`}
                sx={{ height: FIELD_HEIGHT }}
                required={!info.activated}
                disabled={info.activated}
                value={dateStringBirthday}
                error={
                  errorMessages.get("medical_examinee_birthday") ? true : false
                }
                helperText={errorMessages.get("medical_examinee_birthday")}
                disableOpenPicker
                id="medical_examinee_birthday"
                onChange={(value) => {
                  setDateStringBirthday(value);
                  handleExamineeBirthdayChange(
                    "medical_examinee_birthday",
                    value
                  );
                }}
              />

              <Stack direction="row" spacing={COLUMN_SPACING}>
                <SuggestCorporateNameTextField
                  variant="standard"
                  label="企業名"
                  required
                  sx={SX}
                  inputValue={infoValue("corporate_name", info)}
                  error={errorMessages.get("corporate_name") ? true : false}
                  helperText={errorMessages.get("corporate_name")}
                  autocompleteProps={{ id: "corporate_name" }}
                  onInputChange={(_, value) => {
                    handleValueChange("corporate_name", value);
                  }}
                />
                <TextField
                  variant="standard"
                  label="部署名"
                  sx={SX}
                  value={infoValue("department_name", info)}
                  error={errorMessages.get("department_name") ? true : false}
                  helperText={errorMessages.get("department_name")}
                  id="department_name"
                  onChange={(event) => {
                    handleValueChange("department_name", event.target.value);
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={SUBTITLE_SPACING}>
            <SCTypography
              startIcon={<ApartmentIcon color="primary" />}
              variant="subtitle2"
            >
              管理情報
            </SCTypography>
            <Stack spacing={ROW_SPACING}>
              <Stack direction="row" spacing={COLUMN_SPACING}>
                <TextField
                  variant="standard"
                  label="施設内受診者ID"
                  required
                  sx={SX}
                  value={infoValue("medical_examinee_id_in_facility", info)}
                  error={
                    errorMessages.get("medical_examinee_id_in_facility")
                      ? true
                      : false
                  }
                  helperText={errorMessages.get(
                    "medical_examinee_id_in_facility"
                  )}
                  id="medical_examinee_id_in_facility"
                  onChange={(event) => {
                    handleValueChange(
                      "medical_examinee_id_in_facility",
                      event.target.value
                    );
                  }}
                />
                <TextField
                  variant="standard"
                  label="デバイスID"
                  required
                  sx={SX}
                  value={infoValue("device_id", info)}
                  error={errorMessages.get("device_id") ? true : false}
                  helperText={errorMessages.get("device_id")}
                  disabled={info.device_id_related_report_context}
                  id="device_id"
                  onChange={(event) => {
                    handleValueChange("device_id", event.target.value);
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={COLUMN_SPACING}>
                <SCDesktopDatePicker
                  sx={SX}
                  variant="standard"
                  label="デバイス貸出日"
                  value={dateStringDeviceSent}
                  error={errorMessages.get("date_device_sent") ? true : false}
                  helperText={errorMessages.get("date_device_sent")}
                  id="date_device_sent"
                  onChange={(value) => {
                    setDateStringDeviceSent(value);
                    handleDateStringChange("date_device_sent", value);
                  }}
                />
                <SCDesktopDatePicker
                  sx={SX}
                  variant="standard"
                  label="デバイス返却日"
                  value={dateStringDeviceReturned}
                  error={
                    errorMessages.get("date_device_returned") ? true : false
                  }
                  helperText={errorMessages.get("date_device_returned")}
                  id="date_device_returned"
                  onChange={(value) => {
                    setDateStringDeviceReturned(value);
                    handleDateStringChange("date_device_returned", value);
                  }}
                />
              </Stack>
              <SuggestCheckupProjectNameTextField
                variant="standard"
                label="プロジェクト名"
                sx={SX}
                inputValue={info.project?.name ?? ""}
                onInputChange={(_, value) => {
                  handleProjectNameChange(value);
                }}
                error={
                  getProjectErrorMessage(errorMessages)?.get("name")
                    ? true
                    : false
                }
                autocompleteProps={{ id: "project_name" }}
                helperText={getProjectErrorMessage(errorMessages)?.get("name")}
              />
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent={context.deleteHandler ? "space-between" : "flex-end"}
          width="100%"
        >
          {context.deleteHandler && (
            <DeleteButton
              sx={{ width: "100px" }}
              variant="text"
              onClick={() => context.deleteHandler!(info)}
            >
              削除する
            </DeleteButton>
          )}
          <Button
            variant="contained"
            size="small"
            startIcon={context.buttonProps.icon}
            onClick={handleUpdateButtonClick}
          >
            {context.buttonProps.title}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

const createButtonProps: UpdateSleepCheckupDialogButtonProps = {
  title: "登録する",
  icon: <Add />,
};

const editButtonProps: UpdateSleepCheckupDialogButtonProps = {
  title: "変更を保存",
  icon: <CheckIcon />,
};

type SearchSleepCheckupDialogProps = {
  open: DialogProps["open"];
  params: SearchParams;
  facilityUsers: FacilityUser[];
  calendar: CalendarSearchType | null;
  onClickSearch: SearchSleepCheckupHandler;
  onClickClose: () => void;
};
function SearchSleepCheckupDialog({
  open,
  params,
  facilityUsers,
  calendar,
  onClickSearch,
  onClickClose,
}: SearchSleepCheckupDialogProps) {
  const LEFT_SUBTITLE_SPACING = 3.5;
  const RIGHT_SUBTITLE_SPACING = 3;
  const ROW_SPACING = 4;
  const COLUMN_SPACING = 8;
  const DATE_RANGE_SPACING = 2;
  const FIELD_WIDTH = "184px";
  const FIELD_HEIGHT = "48px";
  const SX = {
    width: FIELD_WIDTH,
    height: FIELD_HEIGHT,
  };
  const FIELD_WIDTH_FULL = "392px";
  const BUTTON_WIDTH = "120px";
  const LABEL_ADJUSTER = "-4px";

  const getCalendarTypeValue = (
    calendar: CalendarSearchType | null | NullValue
  ): CalendarSearchType | null => {
    return calendar === NULL_VALUE ? null : calendar;
  };

  const [searchParams, setSearchParams] = useState<SearchParams>(clone(params));
  const [calendarType, setCalendarType] = useState<
    CalendarSearchType | null | NullValue
  >(calendar);
  const [reportStatus, setReportStatus] = useState<
    ReportStatus | null | NullValue
  >(reportStatusSearchParams(params));
  const [dateStringBirthday, setDateStringBirthday] = useState<string | null>(
    params.medical_examinee_birthday
  );
  const [dateStringStart, setDateStringStart] = useState<string | null>(
    getPeriodFrom(getCalendarTypeValue(calendarType), params)
  );
  const [dateStringEnd, setDateStringEnd] = useState<string | null>(
    getPeriodTo(getCalendarTypeValue(calendarType), params)
  );
  const [dateErrorMessages, setDateErrorMessages] = useState<
    Map<DateValues, string>
  >(new Map<DateValues, string>());

  // Note: searchParams,calendarType, reportStatusはpropsで与えられるparams,calendarと同期させる
  useEffect(() => {
    setSearchParams(clone(params));
    setReportStatus(reportStatusSearchParams(params));
  }, [params]);
  useEffect(() => {
    setCalendarType(calendar);
  }, [calendar]);

  const handleValueChange = (key: StringValues, value: string) => {
    setSearchParams(setSearchParamsValue(key, value, searchParams));
  };

  const handleExamineeBirthdayChange = (
    key: DateValues,
    value: string | null
  ) => {
    setSearchParams(setDateString(key, value, searchParams));
  };

  const handleCalendarTypeChange = (value: string) => {
    setCalendarType(value as CalendarSearchType | NullValue);
    setSearchParams(clearCalendarType(searchParams));
  };

  const handleReportStatusChange = (value: string) => {
    setReportStatus(value as ReportStatus | NullValue);
  };

  const handlePeriodFromChange = (value: string | null) => {
    setSearchParams(
      setPeriodFrom(value, getCalendarTypeValue(calendarType), searchParams)
    );
  };

  const handlePeriodToChange = (value: string | null) => {
    setSearchParams(
      setPeriodTo(value, getCalendarTypeValue(calendarType), searchParams)
    );
  };

  const handleClearSearchParams = () => {
    setSearchParams(new SearchParams());
    setCalendarType(null);
    setReportStatus(null);

    // NOTE: dateStringXxxはsearchParamsとは結びついていないため、フィールドをクリアするためには別途クリアする必要がある
    setDateStringBirthday(null);
    setDateStringStart(null);
    setDateStringEnd(null);
  };

  const handleClose = () => {
    // Note: 閉じるボタンが押されたときは、各Stateを検索ダイアログ表示前の状態に戻す
    setSearchParams(params);
    setCalendarType(calendar);

    onClickClose();
  };

  const handleSearch = () => {
    const status = reportStatus !== NULL_VALUE ? reportStatus : null;

    onClickSearch(
      replaceNullValue(setReportStatusSearchParams(status, searchParams)),
      getCalendarTypeValue(calendarType)
    );
  };

  const hasErrorDate: boolean = [
    dateStringBirthday,
    dateStringStart,
    dateStringEnd,
  ].some((date) => isErrorDateField(date));

  const dateStringValidator = (key: DateValues, date: string | null) => {
    const message = isErrorDateField(date)
      ? "ありえない日付が入力されています。"
      : "";
    setDateErrorMessages(
      createDateErrorMessages(key, message, dateErrorMessages)
    );
  };

  // Note: 配列の先頭のnullは「指定しない」を表す
  const facilityUserItems: (FacilityUser | null)[] = [null, ...facilityUsers];

  return (
    <Dialog open={open} maxWidth="md" onClose={handleClose}>
      <SCDialogTitle onClickClose={handleClose}>
        睡眠健康測定を検索
      </SCDialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={COLUMN_SPACING}>
          <Stack spacing={LEFT_SUBTITLE_SPACING}>
            <SCTypography
              startIcon={<SearchIcon color="primary" />}
              variant="subtitle2"
            >
              キーワードで探す
            </SCTypography>
            <Stack spacing={ROW_SPACING}>
              <Stack direction="row" spacing={COLUMN_SPACING}>
                <TextField
                  id="search_medical_examinee_last_name"
                  variant="standard"
                  sx={SX}
                  label={PARAMS_LABEL["medical_examinee_last_name"]}
                  value={searchParamsValue(
                    "medical_examinee_last_name",
                    searchParams
                  )}
                  onChange={(event) =>
                    handleValueChange(
                      "medical_examinee_last_name",
                      event.target.value
                    )
                  }
                />
                <TextField
                  id="search_medical_examinee_first_name"
                  variant="standard"
                  sx={SX}
                  label={PARAMS_LABEL["medical_examinee_first_name"]}
                  value={searchParamsValue(
                    "medical_examinee_first_name",
                    searchParams
                  )}
                  onChange={(event) =>
                    handleValueChange(
                      "medical_examinee_first_name",
                      event.target.value
                    )
                  }
                />
              </Stack>
              <Stack direction="row" spacing={COLUMN_SPACING}>
                <TextField
                  id="search_medical_examinee_last_name_kana"
                  variant="standard"
                  sx={SX}
                  label={PARAMS_LABEL["medical_examinee_last_name_kana"]}
                  value={searchParamsValue(
                    "medical_examinee_last_name_kana",
                    searchParams
                  )}
                  onChange={(event) =>
                    handleValueChange(
                      "medical_examinee_last_name_kana",
                      event.target.value
                    )
                  }
                />
                <TextField
                  variant="standard"
                  sx={SX}
                  id="search_medical_examinee_first_name_kana"
                  label={PARAMS_LABEL["medical_examinee_first_name_kana"]}
                  value={searchParamsValue(
                    "medical_examinee_first_name_kana",
                    searchParams
                  )}
                  onChange={(event) =>
                    handleValueChange(
                      "medical_examinee_first_name_kana",
                      event.target.value
                    )
                  }
                />
              </Stack>
              <SCDesktopDatePicker
                id="search_medical_examinee_birthday"
                variant="standard"
                sx={{ height: FIELD_HEIGHT }}
                label={`${PARAMS_LABEL["medical_examinee_birthday"]} (${DATE_INPUT_EXAMPLE})`}
                value={dateStringBirthday}
                helperText={dateErrorMessages.get("medical_examinee_birthday")}
                error={isErrorDateField(dateStringBirthday)}
                disableOpenPicker
                onChange={(value) => {
                  setDateStringBirthday(value);
                  handleExamineeBirthdayChange(
                    "medical_examinee_birthday",
                    getInternalStringOrNull(value)
                  );
                  dateStringValidator("medical_examinee_birthday", value);
                }}
              />
              <Stack direction="row" spacing={COLUMN_SPACING}>
                <TextField
                  id="search_corporate_name"
                  variant="standard"
                  sx={SX}
                  label={PARAMS_LABEL["corporate_name"]}
                  value={searchParamsValue("corporate_name", searchParams)}
                  onChange={(event) =>
                    handleValueChange("corporate_name", event.target.value)
                  }
                />
                <TextField
                  id="search_department_name"
                  variant="standard"
                  sx={SX}
                  label={PARAMS_LABEL["department_name"]}
                  value={searchParamsValue("department_name", searchParams)}
                  onChange={(event) => {
                    handleValueChange("department_name", event.target.value);
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={COLUMN_SPACING}>
                <TextField
                  id="search_medical_examinee_id_in_facility"
                  variant="standard"
                  sx={SX}
                  label={PARAMS_LABEL["medical_examinee_id_in_facility"]}
                  value={searchParamsValue(
                    "medical_examinee_id_in_facility",
                    searchParams
                  )}
                  onChange={(event) =>
                    handleValueChange(
                      "medical_examinee_id_in_facility",
                      event.target.value
                    )
                  }
                />
                <TextField
                  id="search_device_id"
                  variant="standard"
                  sx={SX}
                  label={PARAMS_LABEL["device_id"]}
                  value={searchParamsValue("device_id", searchParams)}
                  onChange={(event) => {
                    handleValueChange("device_id", event.target.value);
                  }}
                />
              </Stack>
              <SCSelect
                id="search_accepted_by"
                label={PARAMS_LABEL["accepted_by"]}
                value={searchParamsValue("accepted_by", searchParams)}
                variant="standard"
                onChange={(event) =>
                  handleValueChange("accepted_by", event.target.value)
                }
              >
                {facilityUserItems.map((u, i) =>
                  u == null ? (
                    <MenuItem key={i} value={NULL_VALUE}>
                      指定しない
                    </MenuItem>
                  ) : (
                    <MenuItem key={i} value={u.id}>
                      {facilityUserFullName(u)}
                    </MenuItem>
                  )
                )}
              </SCSelect>
            </Stack>
          </Stack>
          <Stack spacing={8}>
            <Stack spacing={RIGHT_SUBTITLE_SPACING}>
              <SCTypography
                startIcon={<CalendarMonthOutlinedIcon color="primary" />}
                variant="subtitle2"
              >
                日付で探す
              </SCTypography>
              <Stack spacing={ROW_SPACING}>
                <SCSelect
                  id="search_calendar_search_selector_label"
                  label={CALENDAR_SEARCH_SELECTOR_LABEL}
                  value={calendarType ?? ""}
                  sx={{ width: FIELD_WIDTH_FULL }}
                  variant="outlined"
                  onChange={(event) =>
                    handleCalendarTypeChange(event.target.value)
                  }
                >
                  {[...CALENDAR_SEARCH_LABEL.entries()].map(([key, value]) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </SCSelect>
                {calendarType === "ACCEPTED_AT" && (
                  <Stack spacing={0.5}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={DATE_RANGE_SPACING}
                    >
                      <SCDesktopDatePicker
                        id="search_accepted_at_from"
                        variant="outlined"
                        label="開始日"
                        value={dateStringStart}
                        error={isErrorDateField(dateStringStart)}
                        helperText={dateErrorMessages.get("accepted_at_from")}
                        onChange={(value) => {
                          setDateStringStart(value);
                          handlePeriodFromChange(
                            getInternalStringOrNull(value)
                          );
                          dateStringValidator("accepted_at_from", value);
                        }}
                        sx={{
                          width: FIELD_WIDTH,
                          "& .MuiInputLabel-root": {
                            // ラベルを垂直方向中央にするためにマージンで表示位置を調整
                            mt: LABEL_ADJUSTER,
                          },
                        }}
                      />
                      <Typography variant="body2" color="black.36">
                        ~
                      </Typography>
                      <SCDesktopDatePicker
                        id="search_accepted_at_to"
                        variant="outlined"
                        label="終了日"
                        value={dateStringEnd}
                        error={isErrorDateField(dateStringEnd)}
                        helperText={dateErrorMessages.get("accepted_at_to")}
                        onChange={(value) => {
                          setDateStringEnd(value);
                          handlePeriodToChange(getInternalStringOrNull(value));
                          dateStringValidator("accepted_at_to", value);
                        }}
                        sx={{
                          width: FIELD_WIDTH,
                          "& .MuiInputLabel-root": {
                            // ラベルを垂直方向中央にするためにマージンで表示位置を調整
                            mt: LABEL_ADJUSTER,
                          },
                        }}
                      />
                    </Stack>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        // 開始日フィールドにエラーメッセージを表示するとき、エラーメッセージとDATE_INPUT_EXAMPLEが重ならないように、エラーメッセージの高さ分のpadding-topを指定する
                        pt: dateErrorMessages.get("accepted_at_from") && 10,
                        pl: 1,
                      }}
                    >
                      {DATE_INPUT_EXAMPLE}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack spacing={RIGHT_SUBTITLE_SPACING}>
              <SCTypography
                startIcon={<FilterAltOutlinedIcon color="primary" />}
                variant="subtitle2"
              >
                レポート状況で探す
              </SCTypography>
              <SCSelect
                id="search_report_status_selector_label"
                label={REPORT_STATUS_SELECTOR_LABEL}
                value={reportStatus ?? ""}
                variant="outlined"
                onChange={(event) => {
                  handleReportStatusChange(event.target.value);
                }}
              >
                {[...REPORT_STATUS_LABEL.entries()].map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  );
                })}
              </SCSelect>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={4}>
          <Button
            variant="text"
            size="small"
            sx={{ width: BUTTON_WIDTH }}
            onClick={handleClearSearchParams}
          >
            条件をクリア
          </Button>
          <Button
            variant="contained"
            size="small"
            startIcon={<SearchIcon />}
            sx={{ width: BUTTON_WIDTH }}
            disabled={hasErrorDate}
            onClick={handleSearch}
          >
            検索する
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

type ConfirmationButtonProps = {
  title: string;
  onClick: ButtonProps["onClick"];
};
type ConfirmationDialogProps = {
  title: string;
  message: string;
  action: ConfirmationButtonProps;
  onClickClose: () => void;
};
function ConfirmationDialog({
  title,
  message,
  action,
  onClickClose,
}: ConfirmationDialogProps) {
  const BUTTON_WIDTH = "92px";
  const BUTTON_SIZE = "small";

  return (
    <Dialog open={true} maxWidth="md" onClose={onClickClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={4}>
          <Button
            variant="text"
            size={BUTTON_SIZE}
            sx={{ width: BUTTON_WIDTH }}
            onClick={onClickClose}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            size={BUTTON_SIZE}
            sx={{ width: BUTTON_WIDTH }}
            onClick={action.onClick}
          >
            {action.title}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

const isErrorDateField = (s: string | null): boolean => {
  // 空欄または正しい日付以外の場合にtrueを返す
  if (s == null || s === "") {
    // 入力欄が空欄の時はエラーとしない
    return false;
  }
  if (isValidDateString(s)) {
    return false;
  }
  return true;
};

const getInternalStringOrNull = (value: string | null): string | null => {
  if (value != null && isValidDateString(value)) {
    return internalFormat(value);
  }
  return null;
};
