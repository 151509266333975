export type EmailVerificationCodeValidationError = "FormatError";

/**
 * ユーザーのメールアドレス検証コードのためのドメインモデル
 * バリデーションなど、検証コードに関する仕様をまとめる
 */
export class EmailVerificationCode {
  value: string;

  constructor(value: string) {
    this.value = value;
  }

  /**
   * 検証コードが要件を満たしているか確認する
   * @returns 確認結果の配列。配列の理由は、他のドメインモデルのvalidate関数とインターフェースを揃えるため、確認項目が増える可能性を考慮したため。
   */
  validate(): EmailVerificationCodeValidationError[] {
    return !this.value.match(/^\d{6}$/) ? ["FormatError"] : [];
  }
}
