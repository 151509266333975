import { Screen } from "@/components/examinee/interview/Types";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError } from "axios";
import { useCallback } from "react";

/** useInterviewScreen getInterviewScreen 型宣言 */
type GetInterviewScreen = (screenId: string) => Promise<Screen>;

/***
 * 質問を取得するカスタムフック。カスタムフックで実装している理由は、useAxios(カスタムフック)を利用するため。
 */
export function useInterviewScreen() {
  const axios = useAxios();

  /**
   * 質問画面を取得
   * 取得にあたって質問画面IDが必要。質問画面IDは、usePrimaryInterviewのgetEntryやpostAnswerで取得できる。
   * @param screenId 質問画面のID
   * @returns 質問画面
   */
  const getInterviewScreen: GetInterviewScreen = useCallback(
    async (screenId: string) => {
      try {
        const response = await axios.get(`/api/screen/${screenId}/`);
        return response.data;
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  return { getInterviewScreen };
}
