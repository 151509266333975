import { getValue, removeValue } from "@/components/elearning/quiz/QuizService";
import { SCREEN_TYPE_QUESTION } from "@/components/examinee/interview/Constants";
import { Question } from "@/components/examinee/interview/Types";

export type Mode = "Question" | "Explanation";

export class QuestionFormInfo {
  readonly mode: Mode;
  readonly question: Question;
  readonly nextScreenType: string | null;
  readonly questionButtonTitle: string;
  readonly explanationButtonTitle: string;
  readonly resultButtonTitle: string;

  constructor(
    mode: Mode,
    question: Question,
    nextScreenType: string | null = null
  ) {
    this.mode = mode;
    this.question = question;
    this.nextScreenType = nextScreenType;

    this.questionButtonTitle = "回答する";
    this.explanationButtonTitle = "次の問題";
    this.resultButtonTitle = "結果を見る";
  }

  getButtonTitle = () => {
    switch (this.mode) {
      case "Question":
        return this.questionButtonTitle;
      case "Explanation":
        switch (this.nextScreenType) {
          case SCREEN_TYPE_QUESTION:
            return this.explanationButtonTitle;
          default:
            return this.resultButtonTitle;
        }
    }
  };

  getExplanation = () => {
    switch (this.mode) {
      case "Question":
        return null;
      case "Explanation":
        return this.question.question_detail;
    }
  };

  getTrueText = () => {
    return removeValue(this.question.choices[0].choice_label);
  };

  getFalseText = () => {
    return removeValue(this.question.choices[1].choice_label);
  };

  getQuestionNumber = () => {
    return getValue(this.question.question_text);
  };

  getQuestionText = () => {
    return removeValue(this.question.question_text);
  };
}
