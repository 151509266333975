import { Box, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { UAParser } from "ua-parser-js";
import { Tag } from "../commons/Tag";

/**
 * 疾患情報
 */
export interface DiseaseInfo {
  /**
   * 疾患リスク名
   */
  disease_name: string;
  /**
   * 判定の根拠
   */
  basis_name: string;
  /**
   * 判定の根拠注釈
   */
  basis_annotations: string[];
  /**
   * 疾患の有無
   */
  is_risk: boolean | null;
  /**
   * 疾患リスクについて
   */
  comment: string;
  /**
   * 疾患リスクの最大値
   */
  max_score: number;
  /**
   * 疾患リスクの今回値
   */
  score: number;
  /**
   * 選択された項目のindex
   */
  choice_indices: (number | null)[];
}
/**
 * 参考情報のグラフ
 */
export interface ChartDataInfo {
  /** グラフ開始 */
  start: string;
  /** グラフ終了 */
  end: string;
  /** グラフ種別 */
  type: "sleep" | "awake" | "no_data";
}
/**
 * 疾患リスク情報
 * @see {DiseaseInfo}
 */
export interface DiseaseRisksInfo {
  /**
   * 睡眠時無呼吸症候群
   */
  SAS: DiseaseInfo | null;
  /**
   * 不眠症
   */
  insomnia: DiseaseInfo | null;
}
/**
 * 受診者情報
 */
export interface ExamineeInfo {
  /**
   * 受診者情報
   */
  name: string;
  /**
   * 性別
   */
  gender: string;
  /**
   * 誕生日
   */
  birthday: string;
  /**
   * 施設名
   */
  facility_name: string;
}
/**
 * 参考情報のまとめ
 */
export interface SleepSummaryInfo {
  /**
   * 総就床時間 ex) "7時間15分"
   */
  total_time_in_bed: string | null;
  /**
   * 睡眠時間 ex) "7時間10分"
   */
  sleep_time: string | null;
  /**
   * 総睡眠時間 ex) "6時間08分"
   */
  total_sleep_time: string | null;
  /**
   * 睡眠効率 ex) "85％"
   */
  sleep_efficiency: string | null;
  /**
   * 入眠潜時 ex) "2分"
   */
  sleep_latency: string | null;
  /**
   * 離床潜時 ex) "2分"
   */
  wakeup_latency: string | null;
  /**
   * 中途覚醒時間 ex) "1時間01分"
   */
  awakening_time: string | null;
  /**
   * 覚醒回数 ex) "1回"
   */
  awakening_count: string | null;
  /**
   * 1回あたり覚醒時間 ex) "65分"
   */
  awakening_average_time: string | null;
}
/**
 * 参考情報
 */
export interface DetailInfo {
  /** 対象日 */
  date: string;
  /** 消灯時間 */
  bedtime: string | null;
  /** 起床時間 */
  arise_time: string | null;
  /**
   * {@link SleepSummaryInfo}
   */
  sleep_summary: SleepSummaryInfo;
  /**
   * {@link ChartDataInfo}
   */
  chart_data: ChartDataInfo[];
}
/**
 * 受診勧奨情報
 */
export interface RecommendationInfo {
  /** バージョン情報 */
  publish_version: string;
  /** {@link ExamineeInfo} */
  examinee: ExamineeInfo;
  /** 測定期間 */
  measurement_period: {
    /** 測定開始(yyyy-MM-dd) */
    start: string;
    /** 測定終了(yyyy-MM-dd) */
    end: string;
  };
  /** {@link DiseaseRisksInfo} */
  disease_risks: DiseaseRisksInfo;
  /** {@link DetailInfo} */
  detail_info_list: DetailInfo[];
}
/**
 * 受診勧奨のオプション情報
 */
export interface RecommendationOptionDetailInfo {
  /** SAS */
  SAS: boolean;
  /** 不眠症 */
  insomnia: boolean;
}
/**
 * 結果としての受診勧奨の有無情報
 */
export interface RecommendationExistenceDetailInfo {
  /** SAS */
  SAS: boolean | null;
  /** SAS */
  insomnia: boolean | null;
}
/**
 * 受診勧奨レポート存在確認情報
 */
export interface RecommendationExistenceInfo {
  /** 受診勧奨オプションの有無 */
  recommendation_option: RecommendationOptionDetailInfo;
  /** 結果としての受診勧奨の有無 */
  recommendation_existence: RecommendationExistenceDetailInfo;
  /** 疾患リスク */
  total: boolean | null;
}

/**
 * 疾患リスク共通プロパティ
 */
export interface DiseaseRiskCommonProps {
  /** {@link RecommendationInfo} */
  data: RecommendationInfo;
}

/**
 * {@link DiseaseRiskTag}
 */
export interface DiseaseRiskTagProps {
  /** 疾患リスクの有無 */
  risk: boolean | null;
}

/**
 * 疾患リスクタグ
 *
 * @param risk
 * @returns
 */
export function DiseaseRiskTag({ risk }: { risk: boolean | null }) {
  const props =
    risk == null
      ? {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            color: "#A3A3A3",
          },
        }
      : risk
      ? {
          sx: {
            backgroundColor: "#FF3A301F",
            color: "#FF3A30",
          },
        }
      : {
          sx: {
            backgroundColor: "#e3e9f3",
            color: "#33529d",
          },
        };
  return <Tag {...props}>{getRiskLabel(risk)}</Tag>;
}

/**
 * 疾患リスクのラベルを取得します。
 * @param isRisk boolean | null
 * @returns
 */
export function getRiskLabel(isRisk: boolean | null) {
  if (isRisk == null) return "未判定";
  return `リスク${isRisk ? "あり" : "低"}`;
}

/**
 * {@link DiseaseRisksInfo} から有効な {@link DiseaseInfo} を取得します。
 * {@link RecommendationExistenceInfo} でオプションとして指定されていない疾患リスクは返却値に含まれません。
 * @param param0 取得対象の {@link DiseaseRisksInfo}
 * @returns 有効な {@link DiseaseInfo} の配列
 */
export function getDiseaseRisks({
  existence,
  info,
}: {
  existence: RecommendationExistenceInfo;
  info: DiseaseRisksInfo;
}) {
  const opt = existence.recommendation_option;
  const result = [];
  if (opt.SAS && info.SAS != null) {
    result.push(info.SAS);
  }
  if (opt.insomnia && info.insomnia != null) {
    result.push(info.insomnia);
  }
  return result;
}
/**
 * 疾患リスクがあるか判定します。
 *
 * @param info {@link RecommendationExistenceInfo}
 * @returns いずれかの疾患リスクが存在する場合、true
 */
export function hasDiseaseRisk(info: RecommendationExistenceInfo) {
  const { SAS, insomnia } = info.recommendation_existence;
  return [SAS, insomnia].some((x) => x === true);
}
/**
 * 疾患リスクレポートを表示するかどうか判定します。
 *
 * @param info
 * @returns 疾患リスクレポートを表示する場合、true
 */
export function hasDiseaseRiskOption(info: RecommendationExistenceInfo) {
  const { SAS, insomnia } = info.recommendation_option;
  return [SAS, insomnia].some((x) => x === true);
}
/**
 * {@link PrintPage} のプロパティ
 */
export interface PrintPageProps {
  /** 印刷内容 */
  children?: ReactNode;
}
/**
 * 疾患リスクレポート用のコンテナ
 */
export function PrintPage({ children }: PrintPageProps) {
  const parser = new UAParser(window.navigator.userAgent);
  const parserResults = parser.getResult();

  const opt =
    parserResults.os.name?.includes("iOS") ||
    (parserResults.os.name?.includes("Mac OS") &&
      parserResults.browser.name?.includes("Safari"))
      ? {
          // iOS または、Mac OS の Safari からの印刷の場合、margin が強制で入るため、レイアウトを調整するために専用のスタイルを当てる
          "@media print": {
            transform: "scale(0.8)",
            transformOrigin: "top left",
            height: "233mm",
            "& .bottom-note": {
              position: "absolute",
              bottom: 0,
            },
          },
        }
      : {};
  return (
    <Box
      sx={{
        "@page": {
          margin: 0,
          size: "A4 portrait",
        },
        "&:not(:last-child)": {
          breakAfter: "page",
        },
        "& .bottom-note": {
          position: "absolute",
          bottom: "48px",
        },
        position: "relative",
        boxSizing: "border-box",
        printColorAdjust: "exact",
        WebkitPrintColorAdjust: "exact",
        width: "210mm",
        height: "297mm",
        ...opt,
      }}
    >
      {children}
    </Box>
  );
}
/**
 * {@link PrintPageHeader} のプロパティ
 */
export interface PrintPageHeaderProps {
  /** ヘッダーの背景色 */
  color: string;
  /** ヘッダーのタイトル1 */
  title1: string;
  /** ヘッダーのタイトル2 */
  title2?: string;
  /** ヘッダーのサブタイトル */
  subtitle?: string;
}
/**
 * 疾患リスクレポート用のヘッダー
 *
 * @param param0 {@link PrintPageHeader}
 * @returns 疾患リスクレポート用のヘッダー
 */
export function PrintPageHeader({
  color,
  title1,
  title2,
  subtitle,
}: PrintPageHeaderProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        bgcolor: color,
        px: "27px",
        py: "27px",
      }}
    >
      <Stack direction="row" alignItems="baseline">
        <Typography
          sx={{
            color: "#FFFFFF",
            fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
            fontSize: "26px",
            fontStyle: "normal",
            fontWeight: "700",
            letterSpacing: "1.334px",
          }}
        >
          {title1}
          {title2 && `│${title2}`}
        </Typography>
        {subtitle && (
          <Typography
            sx={{
              color: "#FFFFFF",
              fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              letterSpacing: "1.334px",
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Stack>

      <img
        src="/img/sleep_compass_logo.svg"
        alt="SLEEP COMPASS"
        loading="lazy"
        style={{ width: "88.4px", height: "26px" }}
      />
    </Stack>
  );
}

/**
 * {@link PrintPageBody} のプロパティ
 */
export interface PrintPageBodyProps {
  /** 要素 */
  children?: ReactNode;
}
/**
 * 疾患リスクレポート用の内容
 * @param param0 {@link PrintPageBodyProps}
 * @returns 疾患リスクレポート用の内容
 */
export function PrintPageBody({ children }: PrintPageBodyProps) {
  return (
    <Stack
      sx={{
        p: "32px",
        gap: "32px",
      }}
    >
      {children}
    </Stack>
  );
}
/**
 * {@link SectionTitle} のプロパティ
 */
export interface SectionTitleProps {
  /** テキスト */
  text: string;
  /** サブテキスト */
  subtext?: string;
  /** セクションの色 */
  color?: string;
}
/**
 * セクションタイトル
 *
 * @param param0 {@link SectionTitleProps}
 * @returns セクションタイトル
 */
export function SectionTitle({
  text,
  subtext,
  color = "#01996D",
}: SectionTitleProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        borderLeft: `5px solid ${color}`,
        p: "0 11px",
        gap: "11px",
      }}
    >
      <Typography
        sx={{
          color: "#000000",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontSize: "18px",
          fontWeight: 700,
          lineHeight: "100%",
          letterSpacing: "0.2px",
        }}
      >
        {text}
      </Typography>
      {subtext && (
        <Typography
          sx={{
            color: "#000000",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontSize: "9px",
            fontStyle: "normal",
            fontWeight: 500,
            letterSpacing: "0.4px",
          }}
        >
          {subtext}
        </Typography>
      )}
    </Stack>
  );
}

/**
 * {@link Annotation} 用のプロパティ
 */
export interface AnnotationProps {
  /** テキスト */
  text: string;
  /** 右に縦線を表示するかどうか */
  showRightBorder?: boolean;
  /** 受信者用かどうか */
  forExamineeReport?: boolean;
}
/**
 * 注釈
 *
 * @param param0 {@link AnnotationProps}
 * @returns 注釈
 */
export function Annotation({
  text,
  showRightBorder = false,
  forExamineeReport,
}: AnnotationProps) {
  const partText = (
    <Typography
      sx={{
        color: "#707070",
        textAlign: "justify",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontSize: `${forExamineeReport ? "10px" : "8px"}`,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "170%",
        letterSpacing: "0.2px",
      }}
    >
      {text}
    </Typography>
  );
  return (
    <Stack direction="row" alignItems="center">
      {partText}
      {showRightBorder && (
        <Typography
          sx={{
            color: "#707070",
            fontSize: "7px",
            ml: "4px",
            mr: "8px",
          }}
        >
          |
        </Typography>
      )}
    </Stack>
  );
}

/**
 * Web レポート総合判定情報
 */
export interface TotalGradeInfo {
  /** 判定 */
  grade: string;
  /** 判定コメント */
  comment: string;
}
