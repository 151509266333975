const RETRIEVE_RIGHT_WRONG_REGEX = /\[(?<value>.*?)\]/;
const RIGHT_ANSWER = "正解";

export function removeValue(label: string): string {
  return label.replace(RETRIEVE_RIGHT_WRONG_REGEX, "");
}

export function isRightAnswer(answer: string): boolean {
  const value = getValue(answer);
  return value === RIGHT_ANSWER;
}

export function getValue(label: string): string | null {
  const match = label.match(RETRIEVE_RIGHT_WRONG_REGEX);
  if (match?.groups == null || match?.groups === undefined) {
    return null;
  }

  return match.groups.value;
}
