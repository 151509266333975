import { useAxios } from "@/utils/axios";
import { useMemo } from "react";
import { JudgementTable } from "./types/judgement_tables";

/**
 * 判定基準 API
 * @returns 判定基準 API
 */
export function useJudgementTable() {
  const axios = useAxios();
  const getURL = (uuid: string) => `/api/report/judgement_table/${uuid}/`;
  return useMemo(
    () => ({
      /**
       * 判定基準取得API
       *
       * @see https://www.notion.so/accelstars/API-Interface-9cc9f3444d7a435494fd3e3431048572?pvs=4#de315cf0ffaa497e82c37aa40cab5adb
       * @param uuid sleep_checkup_info_uuid
       * @returns Promise<AxiosResponse<JudgementTable, any>>
       */
      get: (uuid: string) => axios.get<JudgementTable>(getURL(uuid)),
    }),
    [axios]
  );
}
