import { getAxios, getFacilityApiServerUrl } from "@/utils/axios";
import { AxiosResponse } from "axios";

export function callMedicalFacilityUserUpdateAPI(
  token: string | null
): Promise<AxiosResponse> {
  return getAxios(getFacilityApiServerUrl()).patch(
    "/api/medical_facility_user_update/",
    {
      token: token,
    }
  );
}
