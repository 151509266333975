import { SurveyInfo as SurveyInfoObject } from "@/sleep_compass_lite/data_transfer_objects/target/SurveyInfo";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { SurveyInfo } from "@/sleep_compass_lite/domain_models/target/SurveyInfo";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export { SurveyInfo };

/** useLiteSurveyInfo getSurveyInfo 型宣言 */
type GetSurveyInfo = (surveyInfoUid: string) => Promise<SurveyInfo>;

/***
 * SurveyInfoを操作するクロージャーを返すカスタムフック。カスタムフックで実装している理由は、useAxios(カスタムフック)を利用するため。
 * @param surveyInfoUid サーベイ情報のUUID
 */
export function useLiteSurveyInfo() {
  const axios = useAxios();

  const getSurveyInfo: GetSurveyInfo = useCallback(
    async (surveyInfoUid) => {
      try {
        const response: AxiosResponse<SurveyInfoObject> = await axios.get(
          `/api/lite/survey_info/${surveyInfoUid}/`
        );
        return SurveyInfo.create(response.data);
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  return { getSurveyInfo };
}

/**
 * URLパラメータから取得した surveyInfoUid を使用して {@link LiteSurveyInfo サーベイ情報} を取得するカスタムフック
 *
 * @returns サーベイ情報
 */
export function useSurveyInfo(): [SurveyInfo | undefined, unknown] {
  const { surveyInfoUid } = useParams();
  const { getSurveyInfo: getLiteSurveyInfo } = useLiteSurveyInfo();
  const [liteSurveyInfo, setLiteSurveyInfo] = useState<SurveyInfo>();
  const [surveyInfoError, setSurveyInfoError] = useState<unknown>();

  useEffect(() => {
    (async () => {
      if (surveyInfoUid == null) return;
      try {
        const liteSurveyInfo = await getLiteSurveyInfo(surveyInfoUid);
        setLiteSurveyInfo(liteSurveyInfo);
      } catch (e: unknown) {
        setSurveyInfoError(e);
      }
    })();
  }, [getLiteSurveyInfo, surveyInfoUid]);

  return [liteSurveyInfo, surveyInfoError];
}
