import Authenticated, {
  PrivateRouteProps,
} from "@/components/auth/Authenticated";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";

type TargetAuthenticatedProps = Omit<PrivateRouteProps, "redirectTo">;
export function TargetAuthenticated({ children }: TargetAuthenticatedProps) {
  return (
    <Authenticated redirectTo={authenticationPath.getFullPath("SignIn")}>
      {children}
    </Authenticated>
  );
}
