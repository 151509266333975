import { InterviewRecord } from "@/sleep_compass_lite/components/target/InterviewRecord";
import { Card, CardContent, CardProps, Stack, Typography } from "@mui/material";

export type InterviewRecordInfo = {
  /** 問診回答の記録のタイトル。「事前問診」「X回目問診」が指定されることを想定 */
  title: string;
  /** 問診回答日 */
  completedDate: string | null;
};
type InterviewRecordCardProps = {
  /** コンテナに適応するスタイル。marginが指定されることを想定。 */
  sx: CardProps["sx"];
  /** カード上部に表示するタイトル */
  interviewRecordCardTitle: string;
  /** タイトル下に表示する説明文 */
  interviewRecordDescription?: string;
  /** 問診回答の記録の配列 */
  interviewRecordInfo: InterviewRecordInfo[];
  /** 問診回答の記録の下に表示する注記 */
  interviewRecordNote?: string;
};
/**
 * 問診の記録コンポーネント
 */
export function InterviewRecordCard({
  sx,
  interviewRecordCardTitle,
  interviewRecordDescription,
  interviewRecordInfo,
  interviewRecordNote,
}: InterviewRecordCardProps) {
  return (
    <Card sx={sx}>
      <CardContent sx={{ p: 6 }}>
        <Stack spacing={6}>
          <Stack spacing={2} alignItems="center">
            <Typography variant="subtitle1">
              {interviewRecordCardTitle}
            </Typography>
            {interviewRecordDescription != null && (
              <Typography variant="body2" textAlign="justify">
                {interviewRecordDescription}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            {interviewRecordInfo.map((info, i) => (
              <InterviewRecord
                key={i}
                title={info.title}
                completedDate={info.completedDate}
                recordIconWidth={{ xs: "54px", sm: "81px" }}
              />
            ))}
          </Stack>
          {interviewRecordNote != null && (
            <Typography
              variant="caption"
              textAlign="center"
              sx={{
                fontFeatureSettings: `'palt' on, 'clig' off, 'liga' off;`,
                whiteSpace: "pre-wrap",
              }}
            >
              {interviewRecordNote}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
