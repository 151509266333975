import { AddButton } from "@/components/common/AddButton";
import { ConfirmationDialog } from "@/components/examinee/ConfirmationDialog";
import {
  createAppendableNumberSelectAnswer,
  createNumberSelectAnswerWithChoice,
  getAppendedNumberSelectAnswer,
  getRemovedSelectAnswer,
} from "@/components/examinee/interview/AnswerService";
import { NumberSelectComponent } from "@/components/examinee/interview/NumberSelectChoice";
import { getNumberSelectItems } from "@/components/examinee/interview/NumberSelectChoiceService";
import {
  Answer,
  AnswerChangeHandler,
  Choice,
  Question,
  QuestionAnswer,
} from "@/components/examinee/interview/Types";
import CancelIcon from "@mui/icons-material/Cancel";
import { Card, CardContent, IconButton, Stack } from "@mui/material";
import { useState } from "react";

type AppendableNumberSelectChoiceProps = {
  question: Question;
  noSelect: string;
  questionAnswer: QuestionAnswer;
  onChange: AnswerChangeHandler;
};

export function AppendableNumberSelectChoice({
  question,
  noSelect,
  questionAnswer,
  onChange,
}: AppendableNumberSelectChoiceProps) {
  const [removalTarget, setRemovalTarget] = useState<number | null>(null);

  // 数値選択では、選択肢がひとつしかないタイプ（selection_type が 1/1）のみ対応。また、ラジオボタンやチェックボックスとの併用も非対応。
  const choice: Choice = question.choices[0];
  const answers: Answer[] =
    questionAnswer.answers.length > 0
      ? [...questionAnswer.answers]
      : [createNumberSelectAnswerWithChoice(choice, noSelect)];

  const onSelectChange = (
    answerIndex: number,
    valueIndex: number,
    value: string
  ) => {
    const newAns = createAppendableNumberSelectAnswer(
      question,
      choice,
      valueIndex,
      value,
      answerIndex,
      answers
    );
    onChange(newAns);
  };

  const onClickAppend = () => {
    const newAns = getAppendedNumberSelectAnswer(
      question,
      choice,
      noSelect,
      answers
    );
    onChange(newAns);
  };

  const onClickRemovalIcon = (index: number) => {
    setRemovalTarget(index);
  };

  const onClickRemove = () => {
    if (removalTarget == null) {
      return;
    }

    const newAns = getRemovedSelectAnswer(question, removalTarget, answers);
    onChange(newAns);
    setRemovalTarget(null);
  };

  return (
    <>
      <Stack spacing={4} alignItems="flex-start">
        {answers.map((ans: Answer, i: number) => {
          const showRemove = i !== 0;
          const answerLines = ans.answer.split("\n");
          let count = 0;
          return (
            <Card
              key={i}
              sx={{
                width: "100%",
              }}
            >
              <CardContent>
                <Stack direction="row">
                  <Stack spacing={4} sx={{ width: "100%" }}>
                    {choice.choice_label.split("\n").map((line, j) => {
                      const items = getNumberSelectItems(
                        line,
                        noSelect,
                        answerLines[j],
                        count
                      );

                      count += items.length;

                      return (
                        <Stack key={j} direction="row" alignItems="center">
                          <NumberSelectComponent
                            items={items}
                            onChange={(valueIndex, value) => {
                              onSelectChange(i, valueIndex, value);
                            }}
                          />
                        </Stack>
                      );
                    })}
                  </Stack>
                  {showRemove && (
                    <RemoveButton onClick={() => onClickRemovalIcon(i)} />
                  )}
                </Stack>
              </CardContent>
            </Card>
          );
        })}
        <AppendButton onClick={onClickAppend} />
      </Stack>
      <ConfirmationDialog
        open={removalTarget != null}
        message="入力欄を削除します。よろしいですか？"
        action={{ title: "削除する", onClick: onClickRemove }}
        onClickClose={() => {
          setRemovalTarget(null);
        }}
      />
    </>
  );
}

type AppendButtonProps = {
  onClick: () => void;
};
function AppendButton({ onClick }: AppendButtonProps) {
  return (
    <AddButton variant="text" onClick={onClick}>
      入力欄を追加
    </AddButton>
  );
}

type RemoveButtonProps = {
  onClick: () => void;
};
function RemoveButton({ onClick }: RemoveButtonProps) {
  return (
    <IconButton sx={{ color: "black.36" }} onClick={onClick}>
      <CancelIcon />
    </IconButton>
  );
}
