import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

/**
 * ヘルプダイアログの中で使う、タイトルと文章のペアをシンプルに列挙するコンポーネント
 */
export default function SimpleParagraphs({
  paragraphs,
}: {
  paragraphs: {
    title?: string;
    content: string;
    additionalContent?: ReactNode;
  }[];
}) {
  return (
    <Stack sx={{ gap: 10 }}>
      {paragraphs.map((p, i) => (
        <SimpleParagraph
          key={i}
          title={p.title}
          content={p.content}
          additionalContent={p.additionalContent}
        />
      ))}
    </Stack>
  );
}

export function SimpleParagraph({
  title,
  content,
  additionalContent,
}: {
  title?: string;
  content: string;
  additionalContent?: ReactNode;
}) {
  return (
    <Stack sx={{ gap: 1 }}>
      {title && (
        <Typography variant="subtitle1" sx={{ color: "text.primary" }}>
          {title}
        </Typography>
      )}
      <Typography
        variant="body1"
        sx={{ color: "text.secondary", whiteSpace: "pre-line" }}
      >
        {content}
      </Typography>
      {additionalContent}
    </Stack>
  );
}
