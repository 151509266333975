import HelpButton from "../HelpButton";
import { Reference } from "./commons/Reference";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function AthensInsomniaScaleHelpDialog() {
  return (
    <HelpButton dialogTitle="アテネ不眠尺度の説明">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "アテネ不眠尺度とは",
            content:
              "不眠の重症度を評価するための質問紙としてICD-10の診断基準に基づき作成された指標です。全8問で構成され、各問0~3点で合計得点を算出し、6点以上を不眠症のカットオフ点としています。",
            additionalContent: (
              <Reference>臨床睡眠検査マニュアル(日本睡眠学会)</Reference>
            ),
          },
        ]}
      />
    </HelpButton>
  );
}
