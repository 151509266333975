import { Divider, Stack, Typography, TypographyProps } from "@mui/material";
import { Property } from "csstype";
import { ReactNode } from "react";

export function TitleTypography({ children }: { children: ReactNode }) {
  return (
    <Typography variant="h5" sx={{ color: "primary.main" }}>
      {children}
    </Typography>
  );
}

/**
 * {@link SubtitleTypographyProps} のプロパティ
 */
export interface SubtitleTypographyProps extends TypographyProps {
  /**
   * サブタイトルの {@link Property.BorderColor}
   * デフォルトは `primary.main`を使用します。
   *
   * @see Property.BorderColor
   */
  borderColor?: Property.BorderColor;
};
export function SubtitleTypography({
  borderColor = "primary.main",
  ...props
}: SubtitleTypographyProps) {
  return (
    <Stack direction="row" spacing={2}>
      <Divider
        orientation="vertical"
        sx={{ my: "7px", borderRightWidth: 4, borderColor }}
        flexItem
      />
      <Typography variant="h6" {...props} />
    </Stack>
  );
}
export function SupervisionTypography({ children }: { children: ReactNode }) {
  return (
    <Typography
      variant="caption"
      component="div"
      sx={{
        fontSize: "12px",
        color: "text.primary",
        margin: "24px 0px 24px 0px",
      }}
    >
      {children}
    </Typography>
  );
}
export function EmphasisTypography({ children }: { children: ReactNode }) {
  return (
    <Typography
      component="span"
      variant="body2"
      sx={{
        fontWeight: 500,
        color: "#00A99D",
      }}
    >
      {children}
    </Typography>
  );
}
export function SupplementTypography({ children }: { children: ReactNode }) {
  return (
    <Typography
      component="span"
      sx={{
        fontSize: "12px",
        color: "text.primary",
      }}
    >
      {children}
    </Typography>
  );
}
