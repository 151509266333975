import AuthLayout from "@/components/auth/AuthLayout";
import {
  ErrorMessageTranslator,
  standardTranslator,
} from "@/components/auth/ErrorMessageTranslator";
import {
  callConfirmForgotPasswordAPI,
  callResetPasswordAPI,
} from "@/utils/auth";
import { Box, Button, TextField, Typography } from "@mui/material";
import Axios, { AxiosInstance } from "axios";
import { FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SignInState } from "./Types";

const toObject = (form: HTMLFormElement) =>
  Object.fromEntries(new FormData(form).entries());

type UsernameInputProps = {
  axios: AxiosInstance;
  textFieldLabel: string;
  descriptionLabel: string;
  onEnterUsername: (username: string) => void;
  setErrorMessage: (e: any) => void;
};
function UsernameInput(props: UsernameInputProps) {
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = toObject(event.currentTarget) as { username: string };
    try {
      await callResetPasswordAPI(props.axios, form.username);
      props.onEnterUsername(form.username);
      props.setErrorMessage(null);
    } catch (e: any) {
      if (Axios.isAxiosError(e)) {
        const res: any = e.response?.data;
        if (res?.username) {
          props.setErrorMessage(res.username[0]);
        }
      } else {
        props.setErrorMessage(e.message);
      }
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Typography>{props.descriptionLabel}</Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        name="username"
        label={props.textFieldLabel}
        id="username"
        autoComplete="username"
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        送信
      </Button>
    </Box>
  );
}

type ResetPasswordForm = {
  verificationCode: string;
  newPassword: string;
  reEnterNewPassword: string;
};
const verifyResetPasswordForm = (form: ResetPasswordForm) => {
  if (form.verificationCode.trim().length === 0) {
    throw new Error("確認コードは必須です。入力してください。");
  }
  if (form.newPassword !== form.reEnterNewPassword) {
    throw new Error(
      "The new password and the re-entered password do not match."
    );
  }
};
type ResetPasswordProps = {
  axios: AxiosInstance;
  verificationCodeLabel: string;
  passwordLabel: string;
  reEnterPasswordLabel: string;
  username: string;
  setErrorMessage: (e: any) => void;
  onSuccess: () => void;
};
function getResetPasswordErrorMessage(error: any): string {
  //エラーをハンドリングしてメッセージを返却
  if (!Axios.isAxiosError(error)) {
    return error.message;
  }
  const res: any = error.response?.data;
  if (res == null) {
    return error.message;
  }
  if (Object.hasOwn(res, "cognito-error")) {
    if (
      res["cognito-error"].name === "CodeMismatchException" ||
      res["cognito-error"].name === "ExpiredCodeException"
    ) {
      return "Invalid verification code provided, please try again.";
    } else {
      return "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$";
    }
  } else if (res?.confirmation_code) {
    return res.confirmation_code[0];
  } else if (res?.password) {
    return res.password[0];
  }
  return error.message;
}
function ResetPassword(props: ResetPasswordProps) {
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = toObject(event.currentTarget) as ResetPasswordForm;
    try {
      verifyResetPasswordForm(form);
      await callConfirmForgotPasswordAPI(
        props.axios,
        props.username,
        form.verificationCode,
        form.newPassword
      );
      props.onSuccess();
    } catch (e: any) {
      const message = getResetPasswordErrorMessage(e);
      props.setErrorMessage(message);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Typography>
        登録したメールアドレスにパスワード再設定用の確認コードをお送りしました。確認コードと新パスワードを入力してください。
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        name="verificationCode"
        label={props.verificationCodeLabel}
        id="verificationCode"
        autoComplete="verificationCode"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="newPassword"
        label={props.passwordLabel}
        helperText="大文字小文字を含む英字、数字、記号を組み合わせて8文字以上で設定してください。"
        id="New Password"
        type="password"
        autoComplete="newPassword"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="reEnterNewPassword"
        label={props.reEnterPasswordLabel}
        id="reEnterNewPassword"
        type="password"
        autoComplete="reEnterNewPassword"
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        再設定
      </Button>
    </Box>
  );
}

type ForgotPasswordProps = {
  axios: AxiosInstance;
  signInTo: string;
  usernameLabel?: string;
  verificationCodeLabel?: string;
  passwordLabel?: string;
  reEnterPasswordLabel?: string;
  descriptionLabel?: string;
  translator?: ErrorMessageTranslator;
};
function ForgotPassword(props: ForgotPasswordProps) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [confirmCode, setConfirmCode] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const next = query.get("next") ?? props.signInTo;

  const onEnterUsername = (username: string) => {
    setUsername(username);
    setConfirmCode(true);
  };

  const translator = props.translator ?? standardTranslator;
  const onErrorMessageHandler = (message: string) => {
    const translated = translator(message);
    setErrorMessage(translated);
  };

  const onSuccessHandler = () => {
    const state =
      location.state ??
      new SignInState({
        message:
          "パスワードの初期化に成功しました。設定したパスワードでログインしてください。",
      });

    navigate(next, { state: state });
  };

  return (
    <AuthLayout title="パスワードの再設定" errorMessage={errorMessage}>
      {confirmCode === false ? (
        <UsernameInput
          axios={props.axios}
          textFieldLabel={props.usernameLabel ?? "ユーザー名"}
          descriptionLabel={
            props.descriptionLabel ??
            "登録したメールアドレスにパスワード再設定用の確認コードをお送りします。ユーザー名を入力してください。"
          }
          onEnterUsername={onEnterUsername}
          setErrorMessage={onErrorMessageHandler}
        />
      ) : (
        <ResetPassword
          axios={props.axios}
          verificationCodeLabel={props.verificationCodeLabel ?? "確認コード"}
          passwordLabel={props.passwordLabel ?? "新しいパスワード"}
          reEnterPasswordLabel={
            props.reEnterPasswordLabel ?? "新しいパスワード(確認)"
          }
          username={username}
          setErrorMessage={onErrorMessageHandler}
          onSuccess={onSuccessHandler}
        />
      )}
    </AuthLayout>
  );
}
export default ForgotPassword;
