import SuggestTextField, { SuggestTextFieldProps } from "../SuggestTextField";
import { getCheckupProjects } from "./SleepCheckupInfoAPI";
import { CheckupProject } from "./Types";

export type SuggestCheckupProjectNameTextFieldProps = Omit<
  SuggestTextFieldProps,
  "getOptions"
>;

/**
 * 睡眠健診情報から企業名の候補を取得し表示するTextFieldです。
 *
 * @param {SuggestCheckupProjectNameTextFieldProps} props
 * @returns {JSX.Element}
 */
export function SuggestCheckupProjectNameTextField(
  props: SuggestCheckupProjectNameTextFieldProps
): JSX.Element {
  const getOptions = async (value?: string) =>
    await getCheckupProjects(value).then((res) =>
      res.data.results.map((project: CheckupProject) => project.name)
    );

  return <SuggestTextField {...props} getOptions={getOptions} />;
}
