import { formatDisplayTime } from "@/utils/date";
import {
  ErrorOutline,
  KeyboardArrowRight,
  NavigateNext,
} from "@mui/icons-material";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HowToViewGraphsDialog } from "./commons/HowToViewGraphsDialog";
import { ReportImg } from "./commons/ReportImg";
import { ResultListDialog } from "./commons/ResultListDialog";
import SleepDiaryChart from "./commons/SleepDiaryChart";
import SubtitleTypography from "./commons/SubtitleTypography";
import { Tag } from "./commons/Tag";
import {
  useRecommendationExistenceAPI,
  useSleepImprovementNeededListAPI,
} from "./disease_risk/apis";
import {
  RecommendationExistenceInfo,
  TotalGradeInfo,
  getRiskLabel,
  hasDiseaseRiskOption,
} from "./disease_risk/commons";
import { BeforeCheckReportDialog } from "./top/BeforeCheckReportDialog";
import { DiseaseRiskRow } from "./top/DiseaseRiskRow";
import RecommendForMedicalExamination from "./top/RecommendForMedicalExamination";
import WhatIsSASAndInsomniaButton from "./top/WhatIsSASAndInsomniaButton";
import { JudgeType, TopResponse } from "./types";

export type ReportTopProps = {
  uuid: string;
  data: TopResponse;
};

export function ReportTop(props: ReportTopProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const recommendationExistenceAPI = useRecommendationExistenceAPI();
  const sleepImprovementNeededListAPI = useSleepImprovementNeededListAPI();

  const [openJudgeList, setOpenJudgeList] = useState(false);
  const [howToViewGraphs, setHowToViewGraphs] = useState(false);
  const [openBeforeCheckReportDialog, setOpenBeforeCheckReportDialog] =
    useState(query.get("open_before_check_report_dialog") === "true");
  const [recommendationExistenceInfo, setRecommendationExistenceInfo] =
    useState<RecommendationExistenceInfo>();
  const [totalGradeInfos, setTotalGradeInfos] = useState<TotalGradeInfo[]>();

  useEffect(() => {
    (async () => {
      const res = await recommendationExistenceAPI.get(props.uuid);
      setRecommendationExistenceInfo(res.data);
    })();
  }, [recommendationExistenceAPI, props.uuid]);

  useEffect(() => {
    (async () => {
      const res = await sleepImprovementNeededListAPI.get(props.uuid);
      setTotalGradeInfos(res.data);
    })();
  }, [sleepImprovementNeededListAPI, props.uuid]);

  /**
   * 疾患リスクレポートを表示します。
   */
  const openDiseaseRiskReport = useCallback(() => {
    const pathname = location.pathname;
    window.open(`${pathname}${pathname.endsWith("/") ? "" : "/"}disease_risk`);
  }, [location]);

  // 受診勧奨オプションのいずれかが true の場合、受診勧奨項目を表示する
  const shouldShowDiseaseRisk =
    recommendationExistenceInfo != null &&
    hasDiseaseRiskOption(recommendationExistenceInfo);
  return (
    <Stack>
      {totalGradeInfos && (
        <ResultListDialog
          open={openJudgeList}
          setOpen={setOpenJudgeList}
          totalGrandeInfos={totalGradeInfos}
        />
      )}
      <HowToViewGraphsDialog
        open={howToViewGraphs}
        setOpen={setHowToViewGraphs}
      />
      <BeforeCheckReportDialog
        open={openBeforeCheckReportDialog}
        close={() => {
          setOpenBeforeCheckReportDialog(false);
        }}
      />
      <Stack
        sx={{
          backgroundColor: "primary.dark",
          textAlign: "center",
          p: "40px",
          gap: "24px",
        }}
      >
        <Stack>
          <Typography variant="h6" sx={{ color: "primary.contrastText" }}>
            {props.data.name}さんの
          </Typography>
          <Typography variant="h4" sx={{ color: "primary.contrastText" }}>
            睡眠レポート
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b8c3d3" }}>
            測定期間:{" "}
            {format(new Date(props.data.period.at(0)!), "yyyy/MM/dd", {
              locale: ja,
            })}{" "}
            -{" "}
            {format(new Date(props.data.period.at(-1)!), "yyyy/MM/dd", {
              locale: ja,
            })}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: "#b8c3d3" }}>
            実施施設： {props.data.facility_name}
          </Typography>
        </Stack>
        <Stack>
          <ReportImg
            style={{ width: "224px", display: "block", margin: "auto" }}
            image_name="image_report_top_top.png"
          />
        </Stack>
        <Stack>
          <Typography
            variant="subtitle2"
            sx={{ color: "primary.contrastText" }}
          >
            まずは測定結果の概要を簡単にまとめた
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "primary.contrastText" }}
          >
            サマリーレポートをご覧ください
          </Typography>
        </Stack>
        <Stack sx={{ gap: "16px" }}>
          <Button
            sx={{ backgroundColor: "#FFFFFF" }}
            endIcon={<NavigateNext />}
            onClick={() => {
              navigate("summary");
            }}
          >
            サマリーレポートを見る
          </Button>
          <Button
            onClick={() => {
              setOpenBeforeCheckReportDialog(true);
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "#FFFFFF",
                fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
                borderBottom: "1px solid #FFFFFF",
              }}
            >
              レポートを確認する前に
            </Typography>
          </Button>
        </Stack>
      </Stack>
      {/** 総合評価 */}
      <Stack sx={{ p: "40px 16px 80px 16px", gap: "16px" }}>
        <Stack direction="row" justifyContent="space-between">
          <SubtitleTypography>総合評価</SubtitleTypography>
          <Button
            startIcon={<ErrorOutline sx={{ transform: "rotateX(180deg)" }} />}
            onClick={() => {
              setOpenJudgeList(true);
            }}
          >
            判定一覧
          </Button>
        </Stack>
        <Stack>
          <Typography variant="body2" mb="8px" sx={{ color: "text.secondary" }}>
            {shouldShowDiseaseRisk
              ? "睡眠改善必要度は、あなたの睡眠を「デバイスによる睡眠の測定結果」、「問診による日中の状態」、および「睡眠時無呼吸症候群、不眠症の診断ガイドラインによる疾患リスク」の組み合わせから評価した結果です。"
              : "睡眠改善必要度は、あなたの睡眠を「デバイスによる睡眠の測定結果」と「日中の状態」の2つの側面から総合評価した結果です。"}
          </Typography>
        </Stack>
        <Stack sx={{ border: "1px solid #e0e0e0", borderRadius: "8px" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              backgroundColor: "background.default",
              p: "16px",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "text.secondary",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              項目
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: "text.secondary",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              あなたの結果
            </Typography>
          </Stack>

          <Stack sx={{ gap: "16px", py: "16px" }}>
            <YourResultRow
              title="睡眠の状態"
              result={
                {
                  alert: "課題あり",
                  caution: "やや課題あり",
                  good: "良好",
                  unknown: "判定不能",
                }[props.data.your_result.sleep_result]
              }
            />

            <YourResultRow
              title="日中の状態"
              result={
                props.data.your_result.daytime_result ? "課題あり" : "課題なし"
              }
            />

            {shouldShowDiseaseRisk && (
              <YourResultRow
                title="疾患リスク"
                result={getRiskLabel(
                  recommendationExistenceInfo?.total ?? null
                )}
              />
            )}
          </Stack>
          <Divider sx={{ m: "0px 16px" }} />
          <SleepImprovementNeededLabel {...props.data.your_result} />
          {props.data.your_result.grade.endsWith("*") && (
            <Stack sx={{ p: "0px 16px 16px 16px" }}>
              <Typography variant="caption">
                *
                睡眠に関して治療中のため、かかりつけの医師のもとで治療を継続しましょう。本レポートをかかりつけ医に共有しても問題ありませんが、本計測は日常的に良質な睡眠がとれているかを測定しているため、あくまで参考情報となります。治療はかかりつけ医の指示に従ってください。
              </Typography>
            </Stack>
          )}
        </Stack>
        {/* 受診勧奨 */}
        {recommendationExistenceInfo?.total === true && (
          <RecommendForMedicalExamination
            onClick={() => {
              openDiseaseRiskReport();
            }}
          />
        )}
        {props.data.your_result.comment && (
          <Stack sx={{ border: "1px solid #e0e0e0", borderRadius: "8px" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                backgroundColor: "background.default",
                p: "16px",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Typography variant="caption">専門家からのコメント</Typography>
            </Stack>
            <Stack sx={{ p: "16px" }}>
              <Typography variant="body1">
                {props.data.your_result.comment}
              </Typography>
            </Stack>
            <Stack sx={{ p: "0px 16px 16px 16px" }}>
              <Typography variant="caption">
                ※このコメントは、あなたが今回測定を実施した実施施設の専門家が記載しています。
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      {/** 項目別評価 */}
      <Stack sx={{ p: "0px 16px 80px 16px", gap: "16px" }}>
        <Stack direction="row" justifyContent="space-between">
          <SubtitleTypography>項目別評価</SubtitleTypography>
        </Stack>
        <Stack>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {shouldShowDiseaseRisk
              ? "睡眠を「長さ」、「質」、「リズム」の3つの項目に分解して評価しています。前日の睡眠の結果、日中の活動にどのような影響が現れたのかを、2つの項目で評価しています。また、診断ガイドラインに沿って睡眠時無呼吸症候群および不眠症のリスクを評価しています。"
              : "睡眠を「長さ」、「質」、「リズム」の3つの項目に分解して評価しています。前日の睡眠の結果、日中の活動にどのような影響が現れたのかを、2つの項目で評価しています。"}
          </Typography>
        </Stack>
        <Stack sx={{ gap: "16px" }}>
          <Stack>
            <Typography
              variant="subtitle1"
              sx={{ color: "primary.main", mb: "4px" }}
            >
              睡眠の状態
            </Typography>
            <Typography variant="body2">
              測定した睡眠が健康的な状態かを評価します。
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ border: "1px solid #e0e0e0", borderRadius: "8px", p: "16px" }}
            onClick={() => {
              navigate("quantity");
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              睡眠の長さ
            </Typography>
            <Stack direction="row" alignItems="center" spacing="8px">
              <SleepJudge type={props.data.sleep_record.quantity} />
              <KeyboardArrowRight sx={{ color: "rgba(0, 0, 0, 0.5)" }} />
            </Stack>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ border: "1px solid #e0e0e0", borderRadius: "8px", p: "16px" }}
            onClick={() => {
              navigate("quality");
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              睡眠の質
            </Typography>
            <Stack direction="row" alignItems="center" spacing="8px">
              <SleepJudge type={props.data.sleep_record.quality} />
              <KeyboardArrowRight sx={{ color: "rgba(0, 0, 0, 0.5)" }} />
            </Stack>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ border: "1px solid #e0e0e0", borderRadius: "8px", p: "16px" }}
            onClick={() => {
              navigate("rhythm");
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              睡眠のリズム
            </Typography>
            <Stack direction="row" alignItems="center" spacing="8px">
              <SleepJudge type={props.data.sleep_record.rhythm} />
              <KeyboardArrowRight sx={{ color: "rgba(0, 0, 0, 0.5)" }} />
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ border: "1px dashed rgba(0, 0, 0, 0.12)" }} />
        <Stack sx={{ gap: "16px" }}>
          <Stack>
            <Typography
              variant="subtitle1"
              sx={{ color: "primary.dark", mb: "4px" }}
            >
              日中の状態
            </Typography>
            <Typography variant="body2">
              測定した睡眠があなたにとって十分かを評価します。
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ border: "1px solid #e0e0e0", borderRadius: "8px", p: "16px" }}
            onClick={() => {
              navigate("drowsiness");
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              日中の眠気
            </Typography>
            <Stack direction="row" alignItems="center" spacing="8px">
              <DaytimeJudge
                problem={props.data.daytime_record.drowsiness_problem}
              />
              <KeyboardArrowRight sx={{ color: "rgba(0, 0, 0, 0.5)" }} />
            </Stack>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ border: "1px solid #e0e0e0", borderRadius: "8px", p: "16px" }}
            onClick={() => {
              navigate("fatigue");
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              日中の疲労
            </Typography>
            <Stack direction="row" alignItems="center" spacing="8px">
              <DaytimeJudge
                problem={props.data.daytime_record.fatigue_problem}
              />
              <KeyboardArrowRight sx={{ color: "rgba(0, 0, 0, 0.5)" }} />
            </Stack>
          </Stack>
        </Stack>

        {shouldShowDiseaseRisk && (
          <>
            <Divider sx={{ border: "1px dashed rgba(0, 0, 0, 0.12)" }} />
            <Stack sx={{ gap: "16px" }}>
              <Stack>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "primary.dark", mb: "4px" }}
                >
                  疾患リスク
                </Typography>
                <Typography variant="body2">
                  睡眠時無呼吸症候群、不眠症の診断ガイドラインによる疾患リスクを評価します。詳細は別紙の疾患リスクレポートをご確認ください。
                </Typography>
              </Stack>
              {recommendationExistenceInfo?.recommendation_option.SAS && (
                <DiseaseRiskRow
                  title="睡眠時無呼吸症候群"
                  risk={
                    recommendationExistenceInfo?.recommendation_existence.SAS ??
                    null
                  }
                />
              )}
              {recommendationExistenceInfo?.recommendation_option.insomnia && (
                <DiseaseRiskRow
                  title="不眠症"
                  risk={
                    recommendationExistenceInfo?.recommendation_existence
                      .insomnia ?? null
                  }
                />
              )}
              <Button
                variant="contained"
                sx={{
                  "&:hover": {
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                  },
                  boxShadow: "none",
                  mt: "8px",
                }}
                onClick={() => {
                  openDiseaseRiskReport();
                }}
              >
                疾患リスクレポートを見る
              </Button>
              <WhatIsSASAndInsomniaButton />
            </Stack>
          </>
        )}
      </Stack>
      {/** 改善アクションリスト */}
      <Stack sx={{ p: "0px 16px 80px 16px", gap: "16px" }}>
        <Stack direction="row" justifyContent="space-between">
          <SubtitleTypography>改善アクションリスト</SubtitleTypography>
        </Stack>
        <Stack sx={{ mb: "8px" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            今回の測定の結果、あなたの睡眠をより良くするためのアクションリストを作成しました。ライフスタイルに合わせ、取り組みやすいものから行動に移してみてください。
          </Typography>
        </Stack>
        {props.data.action_list.length === 0 ? (
          <Stack sx={{ border: "1px solid #e0e0e0", borderRadius: "8px" }}>
            <Stack
              sx={{
                backgroundColor: "#f6f5f4",
                textAlign: "center",
              }}
            >
              <ReportImg
                image_name="image_report_top_no_problem.svg"
                style={{
                  width: "140px",
                  height: "140px",
                  mixBlendMode: "luminosity",
                  margin: "auto",
                  display: "block",
                }}
              />
            </Stack>
            <Stack sx={{ m: "16px", gap: "16px" }}>
              <Typography variant="h6">課題なし</Typography>
              <Typography variant="body1">
                今回の測定では改善すべき項目はありませんでした。これからも睡眠に良い生活習慣を継続しましょう。
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "8px",
              p: "16px",
              gap: "16px",
            }}
          >
            {props.data.action_list.map((v, i) => (
              <Stack key={i}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "primary.main", mb: "8px" }}
                >
                  {v.name}
                </Typography>
                {v.comment != null && (
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "13px",
                      color: "text.secondary",
                    }}
                  >
                    {v.comment}
                  </Typography>
                )}
                {v.actions.length > 0 && (
                  <table>
                    <tbody>
                      {v.actions.map((v, j) => (
                        <React.Fragment key={j}>
                          <tr>
                            <td
                              style={{
                                verticalAlign: "top",
                                paddingTop: "5px",
                              }}
                            >
                              <ReportImg
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  display: "block",
                                  margin: "auto",
                                }}
                                image_name="image_report_summary_end_of_summary_check.svg"
                              />
                            </td>
                            <td>
                              <Typography variant="body1">{v.title}</Typography>
                            </td>
                          </tr>
                          {v.note && (
                            <tr>
                              <td></td>
                              <td>
                                <Typography variant="body2">
                                  {v.note}
                                </Typography>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                )}
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
      <Stack sx={{ p: "0px 16px 40px 16px", gap: "16px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mb: "8px" }}
        >
          <SubtitleTypography>測定データの概要</SubtitleTypography>
        </Stack>
        <Stack direction="row" justifyContent="end">
          <Button
            startIcon={<ErrorOutline sx={{ transform: "rotateX(180deg)" }} />}
            onClick={() => {
              setHowToViewGraphs(true);
            }}
          >
            グラフの見方について
          </Button>
        </Stack>
        <Stack sx={{ height: "300px" }}>
          <SleepDiaryChart
            data={props.data.summary.chart_data.map((d) => ({
              date: d.date,
              bedtime: new Date(d.bedtime),
              ariseTime: new Date(d.arise_time),
              sleepData: d.sleep_data.map((d) => ({
                from: new Date(d.from),
                to: new Date(d.to),
                type: d.type,
              })),
            }))}
          />
        </Stack>
        <Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              background: "#F6F5F4",
              p: "8px",
              borderTop: "1px solid #e0e0e0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Typography variant="caption">項目</Typography>
            <Typography variant="caption">あなたの結果</Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              py: "16px",
              px: "8px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Typography variant="body2">平均的な起床時刻</Typography>
            <Typography variant="body2">
              {props.data.summary.average_wakeup_time ?? "-"}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              py: "16px",
              px: "8px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Typography variant="body2">平均睡眠時間</Typography>
            <Typography variant="body2">
              {props.data.summary.average_sleep_time != null
                ? formatDisplayTime(props.data.summary.average_sleep_time)
                : "-"}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              py: "16px",
              px: "8px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Typography variant="body2">平均の睡眠の効率</Typography>
            <Typography variant="body2">
              {props.data.summary.average_sleep_efficiency != null
                ? `${props.data.summary.average_sleep_efficiency}%`
                : "-"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ p: "0px 16px 80px 16px" }}>
        <Stack>
          <Button
            variant="outlined"
            color="inherit"
            component={Link}
            sx={{ color: "text.secondary", borderColor: "#707070" }}
            to="measurement_details"
          >
            もっと見る
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

/**
 * 睡眠の状態
 *
 * @param param0
 * @returns
 */
function SleepJudge({ type }: { type: JudgeType }) {
  const config = {
    alert: {
      text: "警戒",
      backgroundColor: "#FF3A301F",
      color: "#FF3A30",
    },
    caution: {
      text: "注意",
      backgroundColor: "#fff7ea",
      color: "#ff910b",
    },
    good: {
      text: "良好",
      backgroundColor: "#e3e9f3",
      color: "#33529d",
    },
    unknown: {
      text: "判定不能",
      backgroundColor: "#0000001F",
      color: "#707070",
    },
  }[type];
  return (
    <Tag
      sx={{
        backgroundColor: config.backgroundColor,
        color: config.color,
      }}
    >
      {config.text}
    </Tag>
  );
}

/**
 * 日中の状態
 *
 * @param param0
 * @returns
 */
function DaytimeJudge({ problem }: { problem: boolean }) {
  const config = [
    {
      text: "課題あり",
      backgroundColor: "#FF3A301F",
      color: "#FF3A30",
    },
    {
      text: "課題なし",
      backgroundColor: "#e3e9f3",
      color: "#33529d",
    },
  ][problem ? 0 : 1];
  return (
    <Tag
      sx={{
        backgroundColor: config.backgroundColor,
        color: config.color,
      }}
    >
      {config.text}
    </Tag>
  );
}

/**
 * あなたの結果行
 */
interface YourResultRowProps {
  /** タイトル */
  title: string;
  /** 結果 */
  result: string;
}
/**
 * あなたの結果行
 *
 * @param props {@link YourResultRowProps}
 * @returns あなたの結果行
 */
function YourResultRow({ title, result }: YourResultRowProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ px: "16px", py: "0px" }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          color: "text.secondary",
          fontWeight: 400,
        }}
      >
        {title}
      </Typography>
      <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
        {result}
      </Typography>
    </Stack>
  );
}

/**
 * {@link SleepImprovementNeededLabel} のプロパティ
 */
interface SleepImprovementNeededLabelProps {
  /** 評価 */
  grade: string;
  /** 評価コメント */
  grade_comment: string;
  /** 判定フラグ(判定不能だった場合は false) */
  is_determinable: boolean;
}
/**
 * 睡眠改善必要度の項目を表示します。
 *
 * @param props {@link SleepImprovementNeededLabelProps}
 * @returns
 */
function SleepImprovementNeededLabel({
  grade,
  grade_comment,
  is_determinable,
}: SleepImprovementNeededLabelProps) {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: "16px" }}>
      <Typography
        variant="subtitle1"
        sx={{
          color: "#0056A0",
          textAlign: "justify",
          fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
        }}
      >
        睡眠改善必要度
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: "#0056A0",
          textAlign: "justify",
          fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
        }}
      >
        {is_determinable
          ? `${grade} (${grade_comment})`
          : `判定不能（データ不足）${grade.endsWith("*") ? "*" : ""}`}
      </Typography>
    </Stack>
  );
}
