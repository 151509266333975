import { InitiateAuthResult } from "@/sleep_compass_lite/data_transfer_objects/target/InitiateAuthResult";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { EmailAddress } from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import { Password } from "@/sleep_compass_lite/domain_models/target/Password";
import {
  CognitoAuthenticationResultStore,
  CognitoRefreshTokenStore,
  SleepCheckupUserStore,
  callUserTypeApi,
} from "@/utils/auth";
import { getApiServerUrl, getAxios } from "@/utils/axios";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";

/**
 * ユーザーのサインイン処理を実施する
 * 詳細には、サイインインAPIとユーザータイプAPIを呼び出し、そのレスポンスを保存する
 *
 * @param email ユーザーのメールアドレス
 * @param password ユーザーのパスワード
 */
export async function signIn(
  email: EmailAddress,
  password: Password
): Promise<void> {
  try {
    const axios = getAxios(getApiServerUrl());

    // サインインAPIの呼び出し
    const response = await postInitiateAuth(axios, email, password);

    // ユーザータイプAPIの呼び出し
    const userTypeResponse = await callUserTypeApi(
      axios,
      response.data.AuthenticationResult.IdToken
    );

    // 認証情報とユーザータイプの保存
    // サインインAPIとユーザータイプAPIの両方の呼び出しに成功してから、まとめて保存する
    // (APIの呼び出しごとに保存すると、ユーザータイプAPIの呼び出しに失敗した場合に、保存されるデータが不整合を起こすため)
    CognitoAuthenticationResultStore.setItem(
      response.data.AuthenticationResult
    );
    CognitoRefreshTokenStore.setItem({
      RefreshToken: response.data.AuthenticationResult.RefreshToken,
    });
    SleepCheckupUserStore.setItem(userTypeResponse.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new ApiError(e.response);
    } else {
      throw e;
    }
  }
}

/**
 * サインインAPIを呼び出す
 * (サインインAPIは、Sleep Compassの受診者ユーザーのサインインAPIと共通)
 *
 * @param axios Axiosインスタンス
 * @param email ユーザーのメールアドレス
 * @param password ユーザーのパスワード
 * @returns APIレスポンス {@link InitiateAuthResult}
 */
function postInitiateAuth(
  axios: AxiosInstance,
  email: EmailAddress,
  password: Password
): Promise<AxiosResponse<InitiateAuthResult>> {
  return axios.post("/api/initiate_auth/", {
    username: email.value,
    password: password.value,
  });
}
