import { SCTable } from "@/components/sleep_checkup_v1/SCTable";
import { SleepCheckupInfo } from "@/components/sleep_checkup_v1/Types";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Stack,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { SyntheticEvent } from "react";

type InterviewResultProps = {
  title: string;
  sleepCheckupInfo: SleepCheckupInfo;
  tabInfoList: TabInfo[];
  disabled: boolean;
  currentTabIndex: number;
  handleTabChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
};
export function InterviewResult({
  title,
  sleepCheckupInfo,
  tabInfoList,
  disabled,
  currentTabIndex,
  handleTabChange,
}: InterviewResultProps) {
  const currentTabIsEmpty =
    tabInfoList.length === 0 ||
    currentTabIndex < 0 ||
    tabInfoList.length <= currentTabIndex ||
    tabInfoList[currentTabIndex].items.length === 0;
  const allEmpty = tabInfoList.every((tabInfo) => tabInfo.items.length === 0);

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Typography variant="h3">{title}</Typography>
        {sleepCheckupInfo && (
          <Stack
            direction="row"
            sx={{ color: "text.secondary", ml: 2 }}
            spacing={1}
          >
            <Typography>[</Typography>
            <Typography>
              {sleepCheckupInfo.medical_examinee_last_name}{" "}
              {sleepCheckupInfo.medical_examinee_first_name}
            </Typography>
            <Typography>|</Typography>
            <Typography>
              id：{sleepCheckupInfo.medical_examinee_id_in_facility}
            </Typography>
            {sleepCheckupInfo.reminds?.length > 0 && (
              <>
                <Typography>|</Typography>
                <Typography>
                  測定期間：
                  {sleepCheckupInfo.reminds[0].remind_datetime.replace(
                    /(\d+)-(\d+)-(\d+).*/,
                    "$1/$2/$3"
                  )}
                </Typography>
                <Typography>-</Typography>
                <Typography>
                  {sleepCheckupInfo.reminds[
                    sleepCheckupInfo.reminds.length - 1
                  ].remind_datetime.replace(/(\d+)-(\d+)-(\d+).*/, "$1/$2/$3")}
                </Typography>
              </>
            )}
            <Typography>]</Typography>
          </Stack>
        )}
      </Stack>
      <Tabs
        indicatorColor="primary"
        value={disabled || allEmpty ? false : currentTabIndex}
        onChange={handleTabChange}
      >
        {tabInfoList.map((tabInfo, i) => (
          <Tab
            key={tabInfo.tabName}
            label={tabInfo.tabName}
            disabled={disabled || tabInfo.items.length === 0}
          />
        ))}
      </Tabs>
      {!disabled && !currentTabIsEmpty && (
        <SCTable<Answer>
          headers={["質問", "回答"]}
          list={tabInfoList[currentTabIndex].items}
          tableRow={(item: Answer, i: number) => {
            return (
              <TableRow
                key={item.answer_id}
                sx={{
                  "&:last-child td": {
                    borderBottom: "unset",
                  },
                }}
              >
                <TableCell sx={{ width: "50%" }}>{item.question}</TableCell>
                <TableCell sx={{ width: "50%" }}>
                  {item.answers.map((x) => {
                    return (
                      <Typography key={x} variant="body2">
                        {x}
                      </Typography>
                    );
                  })}
                </TableCell>
              </TableRow>
            );
          }}
        />
      )}
      {!disabled && currentTabIsEmpty && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ color: "black.36", fontSize: "20px", pt: 20, width: "100%" }}
        >
          <ErrorOutlineOutlinedIcon />
          <Typography variant="body1">
            現在表示できる項目がありません
          </Typography>
        </Stack>
      )}
    </>
  );
}

export type Answer = {
  question: string;
  answers: string[];
  answered_at: string;
  answer_id: string;
};

export type TabInfo = {
  tabName: string;
  items: Answer[];
};
