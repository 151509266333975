import { NavigateNext } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Divider,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { format, parse } from "date-fns";
import Highcharts, { SeriesLegendItemClickEventObject } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { ReactNode, useRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import Judge from "./commons/Judge";
import LifestyleAndHealthConditionCard from "./commons/LifestyleAndHealthConditionCard";
import ReportNavigate from "./commons/ReportNavigate";
import SubtitleTypography from "./commons/SubtitleTypography";
import { QualityResponse, UserType } from "./types";

type MoreButtonProps = {
  to: RouterLinkProps["to"];
  children: ReactNode;
};
function MoreButton(props: MoreButtonProps) {
  const theme = useTheme();
  return (
    <Button
      variant="outlined"
      sx={{
        color: "text.secondary",
        borderColor: theme.palette.text.secondary,
      }}
      component={RouterLink}
      to={props.to}
    >
      {props.children}
    </Button>
  );
}

export function CharacteristicTable(props: {
  data: {
    title: string;
    result: boolean[];
  }[];
}) {
  return (
    <>
      {props.data.map((d, i) => (
        <React.Fragment key={i}>
          <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
            <Typography
              textAlign="center"
              sx={{
                width: "60px",
                color: "text.secondary",
                p: "4px 0px",
                fontSize: "10px",
              }}
            >
              {d.title}
            </Typography>
            <Stack direction="row" sx={{ width: "calc(100% - 60px)" }}>
              {d.result.map((warning, i) => (
                <Typography
                  key={i}
                  sx={{
                    flex: 1,
                    textAlign: "center",
                    p: "4px 0px",
                    color: warning ? "error.main" : "text.secondary",
                  }}
                >
                  {warning ? (
                    <img
                      src="/img/red_dot.svg"
                      loading="lazy"
                      alt="red_dot"
                      width="12px"
                      height="12px"
                    />
                  ) : (
                    <img
                      src="/img/dot.svg"
                      loading="lazy"
                      alt="dot"
                      width="12px"
                      height="12px"
                    />
                  )}
                </Typography>
              ))}
            </Stack>
          </Stack>
          <Divider />
        </React.Fragment>
      ))}
    </>
  );
}

export function Quality({
  uuid,
  userType,
  data,
}: {
  uuid: string | undefined;
  userType: UserType;
  data: QualityResponse;
}) {
  return (
    <Stack>
      <img
        loading="lazy"
        src="/img/image_report_quality.png"
        alt="image_report_quality"
        width="100%"
      />
      <Stack sx={{ p: "40px 16px 24px 16px", gap: "8px" }}>
        <Breadcrumbs
          maxItems={2}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            href={`/${userType}/report/${uuid}`}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body2">レポートTOP</Typography>
          </Link>
          <Typography variant="body2">睡眠の質</Typography>
        </Breadcrumbs>
        <Typography variant="h5" sx={{ color: "primary.main" }}>
          睡眠の質
        </Typography>
      </Stack>
      <Stack sx={{ p: "0px 16px 40px 16px" }}>
        <Typography
          variant="subtitle2"
          textAlign="justify"
          sx={{ color: "text.secondary" }}
        >
          あなたが寝ようとして寝床にいた時間のうち、実際に眠っていた時間の割合（睡眠の効率）と、3種類の睡眠の特徴の有無を評価します。
        </Typography>
      </Stack>
      <Stack
        sx={{
          p: "0 16px 40px 16px",
          gap: "24px",
        }}
      >
        <SubtitleTypography>測定結果</SubtitleTypography>
        <Stack sx={{ gap: "16x" }}>
          <Chart data={data.measurement_result.sleep_efficiencies} />
          <Stack direction="row" sx={{ width: "100%" }}>
            <Typography sx={{ width: "60px" }}></Typography>
            <Stack direction="row" sx={{ width: "calc(100% - 60px)" }}>
              {data.measurement_result.sleep_efficiencies.map((d, i) => (
                <Typography
                  key={i}
                  sx={{
                    flex: 1,
                    textAlign: "center",
                    color: `${d.is_warning ? "error.main" : "text.secondary"}`,
                    fontSize: "0.8rem",
                    fontWeight: 500,
                  }}
                >
                  {d.value == null ? "-" : `${d.value}%`}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Divider />
          <CharacteristicTable
            data={[
              {
                title: "特徴A",
                result:
                  data.measurement_result.characteristic
                    .initiating_sleep_difficulty,
              },
              {
                title: "特徴B",
                result: data.measurement_result.characteristic.awakening,
              },
              {
                title: "特徴C",
                result:
                  data.measurement_result.characteristic
                    .premature_morning_awakening,
              },
            ]}
          />
        </Stack>
      </Stack>
      <Stack sx={{ p: "0px 16px 80px 16px", gap: "24px" }}>
        <SubtitleTypography>判定と評価項目</SubtitleTypography>
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="subtitle2">判定</Typography>
          <Judge judge={data.judge_and_evaluated_items.judge} />
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ mb: "8px" }}>
            評価項目
          </Typography>
          <TableContainer
            sx={{
              borderTopWidth: "1px",
              borderTopColor: "rgba(0, 0, 0, 0.12)",
              borderTopStyle: "solid",
              borderRadius: "0px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontSize: "12px", fontWeight: 400, p: "8px" }}
                  >
                    項目
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      fontWeight: 400,
                      p: "8px",
                    }}
                  >
                    基準値
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      fontWeight: 400,
                      p: "8px",
                    }}
                  >
                    今回結果
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ px: "8px" }}>平均の睡眠の効率</TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      backgroundColor: "#f5f8fb",
                      px: "8px",
                    }}
                  >
                    85%以上
                  </TableCell>
                  <TableCell
                    sx={{
                      px: "8px",
                      whiteSpace: "nowrap",
                      color: data.judge_and_evaluated_items.evaluated_items
                        .average_sleep_efficiency.is_warning
                        ? "error.main"
                        : "text.main",
                    }}
                  >
                    {
                      data.judge_and_evaluated_items.evaluated_items
                        .average_sleep_efficiency.value == null
                        ? "-"
                        : `${data.judge_and_evaluated_items.evaluated_items.average_sleep_efficiency.value}%`
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ px: "8px" }}>
                    寝付きが悪かった日数(特徴A)
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      backgroundColor: "#f5f8fb",
                      px: "8px",
                    }}
                  >
                    2日以下/週
                  </TableCell>
                  <TableCell
                    sx={{
                      px: "8px",
                      whiteSpace: "nowrap",
                      color: data.judge_and_evaluated_items.evaluated_items
                        .characteristic_a.is_warning
                        ? "error.main"
                        : "text.main",
                    }}
                  >
                    {
                      data.judge_and_evaluated_items.evaluated_items
                        .characteristic_a.value
                    }
                    日間
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ px: "8px" }}>
                    睡眠中の覚醒が多かった日数(特徴B)
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      backgroundColor: "#f5f8fb",
                      px: "8px",
                    }}
                  >
                    2日以下/週
                  </TableCell>
                  <TableCell
                    sx={{
                      px: "8px",
                      whiteSpace: "nowrap",
                      color: data.judge_and_evaluated_items.evaluated_items
                        .characteristic_b.is_warning
                        ? "error.main"
                        : "text.main",
                    }}
                  >
                    {
                      data.judge_and_evaluated_items.evaluated_items
                        .characteristic_b.value
                    }
                    日間
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ px: "8px" }}>
                    早く目が覚めてしまった日数(特徴C)
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      backgroundColor: "#f5f8fb",
                      px: "8px",
                    }}
                  >
                    2日以下/週
                  </TableCell>
                  <TableCell
                    sx={{
                      px: "8px",
                      whiteSpace: "nowrap",
                      color: data.judge_and_evaluated_items.evaluated_items
                        .characteristic_c.is_warning
                        ? "error.main"
                        : "text.main",
                    }}
                  >
                    {
                      data.judge_and_evaluated_items.evaluated_items
                        .characteristic_c.value
                    }
                    日間
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="subtitle2" color="text.secondary">
            各特徴の詳細について
          </Typography>
          <Typography variant="body2" textAlign="justify">
            3種類の睡眠の特徴は、あなたが寝ようとして寝床にいた時間のうち、実際には眠れていなかった時間がどこに多かったのかを表しています。寝付くまで(寝付き)、寝付いてから目が覚めるまで(睡眠中の覚醒)、目が覚めてから起床まで(早朝の覚醒)として分類しています。
          </Typography>
        </Stack>
        <MoreButton
          to={`/${userType}/report/${uuid}/sleep_characteristics_detail`}
        >
          各特徴について詳しく知る
        </MoreButton>
      </Stack>
      <Stack sx={{ p: "0px 16px 80px 16px", gap: "24px" }}>
        <Stack sx={{ gap: "16px" }}>
          <SubtitleTypography>
            {data.judge_and_evaluated_items.judge === "good"
              ? "コメント"
              : "改善のポイント"}
          </SubtitleTypography>
          <Typography variant="body1" textAlign="justify">
            {data.points_of_improvement.comment}
          </Typography>
        </Stack>
        {data.points_of_improvement.items.map((d, i) => {
          return (
            <LifestyleAndHealthConditionCard
              key={i}
              type={d.type}
              comment={d.comment}
              link={`/${userType}/report/${uuid}/lifestyle_and_health_conditions/${d.type}`}
            />
          );
        })}
        <MoreButton
          to={`/${userType}/report/${uuid}/lifestyle_and_health_conditions`}
        >
          すべての項目を見る
        </MoreButton>
      </Stack>
      <Stack sx={{ p: "0px 16px 0px 16px" }}>
        <Divider sx={{ m: "32px 0px" }} />
        <Typography variant="h6" color="text.secondary">
          あわせて読みたい
        </Typography>
        <ReportNavigate
          reportUid={uuid}
          navigates={[
            {
              icon: {
                src: "/img/icon_report_rhythm.svg",
                alt: "musical_note",
              },
              text: "睡眠のリズム",
              link: `/${userType}/report/${uuid}/rhythm`,
            },
            {
              icon: {
                src: "/img/icon_report_quantity.svg",
                alt: "quantity",
              },
              text: "睡眠の長さ",
              link: `/${userType}/report/${uuid}/quantity`,
            },
            {
              icon: {
                src: "/img/icon_report_drowsiness.svg",
                alt: "drowsiness",
              },
              text: "日中の眠気",
              link: `/${userType}/report/${uuid}/drowsiness`,
            },
            {
              icon: {
                src: "/img/icon_report_fatigue.svg",
                alt: "fatigue",
              },
              text: "日中の疲労",
              link: `/${userType}/report/${uuid}/fatigue`,
            },
          ]}
        />
      </Stack>
    </Stack>
  );
}

function Chart(props: {
  data: {
    // 測定日
    date: string;
    // 睡眠の効率
    value: number | null;
  }[];
}) {
  const theme = useTheme();
  // minは、チャートの縦軸の下限値。測定データの中(測定できていない日を除外)で、最も小さい値から5を引いた値とする。
  // ただし、5を引いた値が0よりも小さいときは0とする。また、測定データが空の場合も0とする。
  const min = props.data.some((d) => d.value != null)
    ? props.data
        .flatMap((d) => (d.value != null ? [Math.max(d.value - 5, 0)] : []))
        .reduce((p, c) => Math.min(p, c))
    : 0;
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options: Highcharts.Options = {
    chart: {
      type: "column",
      height: 300,
      marginLeft: 60,
      marginRight: 0,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: props.data.map((d) => {
        const date = parse(d.date, "yyyy-MM-dd", new Date());
        return `${format(date, "M/d")}<br>${"日月火水木金土"[date.getDay()]}`;
      }),
      opposite: true,
      lineWidth: 0,
      labels: {
        rotation: 0,
        style: {
          color: theme.palette.text.secondary,
          fontSize: "10px",
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter() {
          const label = `${this.pos}%`;
          return this.pos === 85
            ? `<span style="color: #00a99d; font-weight: bold;">${label}</span>`
            : label;
        },
        style: {
          color: theme.palette.text.secondary,
          fontSize: "10px",
        },
      },
      max: 100,
      min: min,
      tickInterval: 5,
      plotBands: [
        {
          color: "#e0f5f3",
          from: 85,
          to: 100,
        },
      ],
      plotLines: [
        {
          color: "#00a99d",
          value: 85,
          zIndex: 3,
          width: 2,
        },
      ],
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      symbolHeight: 10,
      symbolRadius: 0,
      itemStyle: {
        color: theme.palette.text.secondary,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 32,
        borderRadius: 0,
      },
    },
    series: [
      {
        type: "column",
        color: "#0056A01F",
        showInLegend: false,
        data: props.data.map((d) => 100 - (d.value ?? 0)),
        stack: 0,
        events: {
          legendItemClick(e: SeriesLegendItemClickEventObject) {
            e.preventDefault();
          },
        },
      },
      {
        type: "column",
        color: theme.palette.primary.dark,
        name: "睡眠の効率",
        data: props.data.map((d) => d.value),
        dataLabels: {
          enabled: false,
        },
        stack: 0,
        events: {
          legendItemClick(e: SeriesLegendItemClickEventObject) {
            e.preventDefault();
          },
        },
      },
      // NOTE: 「特徴があった日」をlegendに表示する為に、dataがundefinedの以下SeriesOptionを追加している
      //        legend内の「特徴があった日」のsymbolを円にする為、scatter typeにしている
      {
        type: "scatter",
        marker: {
          radius: 5,
        },
        color: theme.palette.error.main,
        name: "特徴があった日",
        data: undefined,
        stack: 0,
        events: {
          legendItemClick(e: SeriesLegendItemClickEventObject) {
            e.preventDefault();
          },
        },
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
}
