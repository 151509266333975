import { Card, CardProps } from "@mui/material";

/** {@link ReportCard} のデフォルトのスタイル */
export const DEFAULT_STYLE = { borderRadius: "8px" };

/**
 * {@link ReportCard} のプロパティ
 * @see ReportCardProps
 * @see CardProps
 */
export type ReportCardProps = CardProps;

/**
 * lite のレポートのカードを表示します。
 *
 * @param props {@link ReportCardProps}
 * @returns lite のレポートのカード
 */
export function ReportCard(props: ReportCardProps) {
  return <Card variant="outlined" sx={DEFAULT_STYLE} {...props} />;
}
