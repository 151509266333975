import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogProps, Divider, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

export type WorksheetDialogProps = DialogProps;
export default function WorksheetDialog({
  title,
  onClose,
  ...props
}: WorksheetDialogProps) {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "24px",
        },
      }}
      onClose={onClose}
      {...props}
    >
      <Stack
        flexDirection="column"
        onClick={(e) => {
          // ダイアログの内側をクリックすると、ダイアログの親要素（例えばHelpButton）
          // がクリックされたことになるため、それを避けるためにstopPropagationしておく。
          e.stopPropagation();
        }}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: "16px 32px" }}
        >
          <Typography variant="h4" sx={{ color: "#0056A0" }}>
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => onClose?.({}, "backdropClick")}
            sx={{ p: 0 }}
          >
            <CloseIcon sx={{ color: "#0056A0" }} />
          </IconButton>
        </Stack>
        <Divider />
        <Stack sx={{ p: "24px 32px 40px" }}>{props.children}</Stack>
      </Stack>
    </Dialog>
  );
}
