import ErrorFallback from "@/components/ErrorFallback";
import CustomSignIn from "@/components/auth/CustomSignIn";
import { SCAppBar } from "@/components/sleep_checkup_v1/SCAppBar";
import { facilityTheme } from "@/theme/themeV1";
import { getAxios, getFacilityApiServerUrl } from "@/utils/axios";
import { Box, ThemeProvider } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";

function FacilitySignIn() {
  const MIN_WIDTH = 1098;
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ThemeProvider theme={facilityTheme}>
        <SCAppBar minWidth={MIN_WIDTH} />
        <CustomSignIn
          axios={getAxios(getFacilityApiServerUrl())}
          defaultSuccessTo="/facility/sleep_checkup"
          forgotPasswordTo="/facility/forgot_password"
          usernameLabel="ユーザー名"
          passwordLabel="パスワード"
        />
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: "auto",
          }}
        ></Box>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default FacilitySignIn;
