import { Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRetrospective } from "./apis";
import { Retrospective, TopicType } from "./commons/retrospectives";
import { RecommendMark, RetrospectiveSection } from "./retrospectives";

/**
 * 原因分析ページ
 * @returns 原因分析ページ
 */
export default function Retrospectives() {
  const { uuid } = useParams<{ uuid: string }>();
  const api = useRetrospective();
  const [retrospective, setRetrospective] = useState<Retrospective>();
  type SectionType = keyof Retrospective;
  useEffect(() => {
    if (uuid == null) return;
    (async () => {
      const res = await api.get(uuid);
      setRetrospective(res.data);
    })();
  }, [uuid, api]);

  const onChangeSection = useCallback(
    <K extends SectionType>(key: K, value: Retrospective[K]) => {
      setRetrospective((r) => {
        if (r == null) return r;
        const copy = { ...r };
        copy[key] = value;
        return copy;
      });
    },
    [setRetrospective]
  );
  return (
    <Stack sx={{ gap: 20 }}>
      <Stack sx={{ gap: 2 }}>
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
          display="flex"
          alignItems="center"
          sx={{ gap: 2 }}
        >
          <RecommendMark isWarning />
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            受診者向けレポートにて改善を推奨している項目
          </Typography>
        </Stack>
        <QuantityAndRhythmSection
          data={retrospective?.quantity_and_rhythm}
          onChange={(data) => onChangeSection("quantity_and_rhythm", data)}
        />
      </Stack>
      <ConditionSection
        data={retrospective?.condition}
        onChange={(data) => onChangeSection("condition", data)}
      />
      <ActionSection
        data={retrospective?.action}
        onChange={(data) => onChangeSection("action", data)}
      />
      <OtherSection
        data={retrospective?.action}
        onChange={(data) => onChangeSection("action", data)}
      />
    </Stack>
  );
}

function QuantityAndRhythmSection({
  data,
  onChange,
}: {
  data?: Retrospective["quantity_and_rhythm"];
  onChange: (data: Retrospective["quantity_and_rhythm"]) => void;
}) {
  type Key = keyof Retrospective["quantity_and_rhythm"] & TopicType;
  const order: Key[] = ["rhythm", "quantity"];
  return (
    <RetrospectiveSection
      title="時間とリズム"
      order={order}
      data={data}
      onChange={onChange}
    />
  );
}

function ConditionSection({
  data,
  onChange,
}: {
  data?: Retrospective["condition"];
  onChange: (data: Retrospective["condition"]) => void;
}) {
  type Key = keyof Retrospective["condition"];
  const order: Key[] = ["body", "mind", "before_sleep_mind"];
  return (
    <RetrospectiveSection
      title="状態"
      order={order}
      data={data}
      onChange={onChange}
    />
  );
}

function ActionSection({
  data,
  onChange,
}: {
  data?: Retrospective["action"];
  onChange: (data: Retrospective["action"]) => void;
}) {
  const order: (keyof Retrospective["action"])[] = [
    "exercise",
    "nightmeal",
    "alcohol",
    "bluelight",
    "room_light",
    "nap",
    "caffeine",
    "bath",
    "tobacco",
  ];
  return (
    <RetrospectiveSection
      title="行動"
      order={order}
      data={data}
      onChange={onChange}
    />
  );
}

function OtherSection({
  data,
  onChange,
}: {
  data?: Retrospective["action"];
  onChange: (data: Retrospective["action"]) => void;
}) {
  const order: (keyof Retrospective["action"])[] = [
    "bedtime_environment",
    "other",
  ];
  return (
    <RetrospectiveSection
      title="その他"
      order={order}
      data={data}
      onChange={onChange}
    />
  );
}
