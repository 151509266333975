/**
 * MedicalFacilityUserProfileに対応するDTO
 */
export class MedicalFacilityUser {
  id: number | null = null;
  phone: string = "";
  user: CustomUser = new CustomUser();
  medical_facility_group: number | null = null;
  medical_facility: number[] = [];
  scu_email_receiving: boolean = true;
}

/**
 * MedicalFacilityUserProfileから編集可能なフィールドに絞ったDTO
 */
export class MedicalFacilityUserEdit {
  phone: string;
  user: CustomUserEdit;
  medical_facility: number[];
  scu_email_receiving: boolean;

  constructor(medicalFacilityUser: MedicalFacilityUser) {
    this.phone = medicalFacilityUser.phone;
    this.user = new CustomUserEdit(medicalFacilityUser.user);
    this.medical_facility = medicalFacilityUser.medical_facility;
    this.scu_email_receiving = medicalFacilityUser.scu_email_receiving;
  }
}

/**
 * CustomUserに対応するDTO
 */
export class CustomUser {
  username: string = "";
  email: string = "";
  last_name: string = "";
  first_name: string = "";
  user_type: number | null = null;
  groups: number[] = [];
}

/**
 * CustomUserから編集可能なフィールドに絞ったDTO
 */
export class CustomUserEdit {
  email: string;
  last_name: string;
  first_name: string;
  groups: number[];

  constructor(user: CustomUser) {
    this.email = user.email;
    this.last_name = user.last_name;
    this.first_name = user.first_name;
    this.groups = user.groups;
  }
}

/**
 * SimpleMedicalFacilityUserProfileSerializerに対応するDTO
 */
export type SimpleMedicalFacilityUser = {
  id: number;
  user: {
    username: string;
    last_name: string;
    first_name: string;
  };
  phone: string;
};
