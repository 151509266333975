import { InterviewInfo as InterviewInfoObject } from "@/sleep_compass_lite/data_transfer_objects/target/InterviewInfo";

/**
 * 問診情報ドメインモデル
 */
export class InterviewInfo {
  constructor(
    /** 問診完了日 */
    readonly completedDate: Date | null
  ) {}

  /**
   * InterviewInfoオブジェクトからInterviewInfoモデルを生成する
   * @param obj {@link InterviewInfoObject}
   * @returns InterviewInfoモデルのインスタンス
   */
  static create(obj: InterviewInfoObject) {
    const completedDate =
      obj.completed_date != null ? new Date(obj.completed_date) : null;
    return new InterviewInfo(completedDate);
  }
}
