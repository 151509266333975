import { Button, Link, LinkProps, Typography } from "@mui/material";
import { HTMLAttributeAnchorTarget, ReactNode } from "react";

/**
 * リンクテキスト。SCLinkと違い、外部サイトへのリンクが可能。
 */
export type ExternalLinkProps = {
  children: ReactNode;
  href: LinkProps["href"];
  target?: HTMLAttributeAnchorTarget;
};
export function ExternalLink({ children, href, target }: ExternalLinkProps) {
  const anchorTarget = target ?? "_self";

  return (
    <Button
      variant="text"
      component={Link}
      href={href}
      target={anchorTarget}
      sx={{
        p: 0,
        color: "white",
        textDecoration: "none",
        // display: blockを指定しないと、inline-flexが自動で指定される。inline-flexだと、リンク(テキスト)の左右にスペースが設けられて、レイアウト崩れの原因になる。
        display: "block",
      }}
    >
      <Typography variant="caption" color="white">
        {children}
      </Typography>
    </Button>
  );
}
