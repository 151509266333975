export interface Remind {
  readonly remind_datetime: string;
  readonly sleep_checkup_info: string;
}

export class RemindImpl implements Remind {
  readonly remind_datetime: string;
  readonly sleep_checkup_info: string;

  constructor(remindDatetime: string, sleepCheckupInfo: string) {
    this.remind_datetime = remindDatetime;
    this.sleep_checkup_info = sleepCheckupInfo;
  }
}

export class SimpleTime {
  readonly hour: string;
  readonly minutes: string;

  get numHour(): number {
    return parseInt(this.hour);
  }

  get numMinutes(): number {
    return parseInt(this.minutes);
  }

  constructor(hour: string, minutes: string) {
    this.hour = hour;
    this.minutes = minutes;
  }
}

export class RemindItem {
  readonly date: Date;
  readonly time: SimpleTime;

  get dateTime(): Date {
    const d = new Date(this.date);
    d.setHours(this.time.numHour);
    d.setMinutes(this.time.numMinutes);
    return d;
  }

  constructor(date: Date, time: SimpleTime) {
    this.date = date;
    this.time = time;
  }
}
