import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { RegistrationCompletedImage } from "@/sleep_compass_lite/components/target/RegistrationCompletedImage";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { TargetAuthenticated as Authenticated } from "@/sleep_compass_lite/components/target/TargetAuthenticated";
import { interviewPath } from "@/sleep_compass_lite/domain_models/target/InterviewPath";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { useDailyInterview } from "@/sleep_compass_lite/use_cases/target/interview/UseDailyInterview";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

/**
 * 問診接続ページ
 */
export function InterviewConnection() {
  const { surveyInfoUid, announceId } = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState<unknown>();
  const { getEntry } = useDailyInterview();

  const handleInterviewStart = async () => {
    if (surveyInfoUid == null || announceId == null) {
      return;
    }
    const announce = parseInt(announceId);
    try {
      const entry = await getEntry(surveyInfoUid, announce);
      // 問診画面から戻るために、現在の pathname を渡して遷移
      navigate(
        interviewPath.getDailyInterviewPath(surveyInfoUid, announce, entry),
        {
          state: {
            prevPathname: window.location.pathname,
          },
        }
      );
    } catch (e: unknown) {
      setError(e);
    }
  };

  return (
    <Authenticated>
      <ScrollToTop />
      <Stack spacing={12} sx={{ pt: 8, pb: 16, px: PX4 }}>
        {error != null && (
          <NonFieldError>{getErrorMessage(error)}</NonFieldError>
        )}
        <Stack spacing={6} alignItems="center">
          <RegistrationCompletedImage sx={{ px: "41.5px" }} />
          <Typography variant="h6">事前問診完了</Typography>
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ color: "text.secondary" }}
          >
            事前問診は以上です
            <br />
            続いて「1回目問診」にご回答ください
          </Typography>
        </Stack>
        <Button
          sx={{ boxShadow: "none" }}
          variant="contained"
          size="large"
          onClick={handleInterviewStart}
        >
          1回目問診に回答する
        </Button>
      </Stack>
    </Authenticated>
  );
}
