import SleepDiaryChart, {
  DEFAULT_ARISE_TIME_LINE_ATTR,
  DEFAULT_BEDTIME_LINE_ATTR,
  DEFAULT_SLEEP_BAR_ATTR,
} from "@/components/report/commons/SleepDiaryChart";
import {
  TableContainer as MUITableContainer,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format, parse } from "date-fns";
import { ReactNode } from "react";
import {
  Annotation,
  RecommendationExistenceInfo,
  RecommendationInfo,
  SectionTitle,
  getRiskLabel,
} from "../commons";
import { MedicalFacilityReportLayout, TableContainer } from "./commons";

/**
 * yyyy-MM-dd 形式の日付を yyyy/MM/dd 形式に変換します。
 * 引数の値がnullまたはundefinedの場合、空文字を返却します。
 * @param ds yyyy-MM-dd 形式の文字列
 * @returns yyyy/MM/dd 形式
 */
function formatDateString(ds: string | null | undefined) {
  if (ds == null) return "";
  return format(parse(ds, "yyyy-MM-dd", new Date()), "yyyy/MM/dd");
}

/**
 * {@link TableHeaderCell} のプロパティ
 */
interface TableHeaderCellProps {
  /** 要素 */
  children: ReactNode;
}
/**
 * 医療機関向けレポートヘッダー用の TableCell
 * @param param0 {@link TableHeaderCellProps}
 * @returns 医療機関向けレポートヘッダー用の TableCell
 */
function TableHeaderCell({ children }: TableHeaderCellProps) {
  return (
    <TableCell
      component="th"
      sx={{
        "&.MuiTableCell-root": {
          color: "#000",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "150%",
          letterSpacing: "0.2px",
          background: "rgba(234, 234, 234, 0.50)",
        },
      }}
    >
      {children}
    </TableCell>
  );
}

/**
 * {@link ReferenceInfoProps} のプロパティ
 */
export interface ReferenceInfoProps {
  /** {@link RecommendationInfo} */
  data: RecommendationInfo;
  /** {@link RecommendationExistenceInfo} */
  existence: RecommendationExistenceInfo;
  /** 最大ページ数 */
  maxPage: number;
}
/**
 * 参考情報ページ
 *
 * @param param0 {@link ReferenceInfoProps}
 * @returns 参考情報ページ
 */
export default function ReferenceInfo({
  data,
  existence,
  maxPage,
}: ReferenceInfoProps) {
  return (
    <MedicalFacilityReportLayout
      page={1}
      maxPage={maxPage}
      examinee={data.examinee}
    >
      <Stack sx={{ gap: "22px" }}>
        <SectionTitle text="疾患リスクについて" />
        <DiseaseRiskTable data={data} existence={existence} />
      </Stack>

      {/* 参考情報 */}
      <Stack sx={{ gap: "22px" }}>
        <SectionTitle
          text="参考情報"
          subtext="（ウェアラブルデバイスで取得した受診者の睡眠状態および睡眠変数）"
        />
        {/* グラフと表 */}
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  paddingLeft: "16px",
                  color: "#000",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "Noto Sans JP",
                  fontSize: "11px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "100%",
                  letterSpacing: "0.2px",
                }}
              >
                測定期間│
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "Noto Sans JP",
                  fontSize: "11px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "100%",
                  letterSpacing: "0.5px",
                }}
              >{`${
                data.measurement_period?.start != null
                  ? formatDateString(data.measurement_period.start)
                  : ""
              } ～ ${
                data.measurement_period?.end != null
                  ? formatDateString(data.measurement_period?.end)
                  : ""
              }`}</Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <LegendItem mark="■" color="#01996D" text="睡眠" />
              <LegendItem mark="□" color="#000000" text="覚醒" />
              <LegendItem mark="●" color="#0056A0" text="消灯" />
              <LegendItem
                mark={
                  <img
                    src="/img/image_disease_risk_legend_wake_up.svg"
                    alt="wake up"
                    loading="lazy"
                  />
                }
                color="#0056A0"
                text="起床"
              />
            </Stack>
          </Stack>

          <Stack
            sx={{
              height: "286px",
              ml: "70px",
            }}
          >
            <SleepDiaryChart
              sleepBarAttributes={{
                ...DEFAULT_SLEEP_BAR_ATTR,
                active: {
                  sleep: { fill: "#01996D" },
                  awake: { fill: "#FFFFFF" },
                  no_data: { fill: "#FFFFFF" },
                  nap: { fill: "#FFFFFF" },
                },
              }}
              bedtimeLineAttributes={{
                ...DEFAULT_BEDTIME_LINE_ATTR,
                active: {
                  fill: "#0056A0",
                  "stroke-width": 1,
                  stroke: "#0056A0",
                },
              }}
              ariseTimeLineAttributes={{
                ...DEFAULT_ARISE_TIME_LINE_ATTR,
                active: {
                  fill: "#ffffff",
                  "stroke-width": 1,
                  stroke: "#0056A0",
                },
              }}
              ariseTimeCircleAttributes={{
                ...DEFAULT_ARISE_TIME_LINE_ATTR,
                active: {
                  fill: "#ffffff",
                  "stroke-width": 3,
                  stroke: "#0056A0",
                },
              }}
              data={data.detail_info_list.map((d) => ({
                date: d.date,
                bedtime: d.bedtime != null ? new Date(d.bedtime) : null,
                ariseTime: d.arise_time != null ? new Date(d.arise_time) : null,
                sleepData: d.chart_data.map((c) => ({
                  from: new Date(c.start),
                  to: new Date(c.end),
                  type: c.type,
                })),
              }))}
            />
          </Stack>
          <MUITableContainer
            sx={{
              border: "1px solid #E0E0E0",
              "tr th, tr td": {
                fontSize: "10px",
                p: "6px 6px 6px 12px",
                border: 0,
              },
              "tr th": {
                fontWeight: "700",
              },
              "tr:nth-of-type(2n) th, tr:nth-of-type(2n) td": {
                backgroundColor: "rgba(234, 234, 234, 0.50)",
              },
            }}
          >
            <Table>
              <TableBody>
                <SleepResultRow
                  title="総就床時間"
                  data={data.detail_info_list.map(
                    (d) => d.sleep_summary.total_time_in_bed
                  )}
                />
                <SleepResultRow
                  title="睡眠時間"
                  data={data.detail_info_list.map(
                    (d) => d.sleep_summary.sleep_time
                  )}
                />
                <SleepResultRow
                  title="総睡眠時間"
                  data={data.detail_info_list.map(
                    (d) => d.sleep_summary.total_sleep_time
                  )}
                />
                <SleepResultRow
                  title="睡眠効率"
                  data={data.detail_info_list.map(
                    (d) => d.sleep_summary.sleep_efficiency
                  )}
                />
                <SleepResultRow
                  title="入眠潜時"
                  data={data.detail_info_list.map(
                    (d) => d.sleep_summary.sleep_latency
                  )}
                />
                <SleepResultRow
                  title="離床潜時"
                  data={data.detail_info_list.map(
                    (d) => d.sleep_summary.wakeup_latency
                  )}
                />
                <SleepResultRow
                  title="中途覚醒時間"
                  data={data.detail_info_list.map(
                    (d) => d.sleep_summary.awakening_time
                  )}
                />
                <SleepResultRow
                  title="覚醒回数"
                  data={data.detail_info_list.map(
                    (d) => d.sleep_summary.awakening_count
                  )}
                />
                <SleepResultRow
                  title="1回あたり覚醒時間"
                  data={data.detail_info_list.map(
                    (d) => d.sleep_summary.awakening_average_time
                  )}
                />
              </TableBody>
            </Table>
          </MUITableContainer>
        </Stack>
      </Stack>
    </MedicalFacilityReportLayout>
  );
}
/**
 * {@link DiseaseRiskTable} 用のプロパティ
 */
interface DiseaseRiskTableProps {
  /** {@link RecommendationInfo} */
  data: RecommendationInfo;
  /** {@link RecommendationExistenceInfo} */
  existence: RecommendationExistenceInfo;
}
/**
 * 疾患リスクテーブル
 *
 * @param param0 {@link DiseaseRiskTableProps}
 * @returns 疾患リスクテーブル
 */
function DiseaseRiskTable({ data, existence }: DiseaseRiskTableProps) {
  const risks = [];
  if (existence.recommendation_option.SAS && data.disease_risks.SAS != null) {
    risks.push(data.disease_risks.SAS);
  }
  if (
    existence.recommendation_option.insomnia &&
    data.disease_risks.insomnia != null
  ) {
    risks.push(data.disease_risks.insomnia);
  }
  const allAnnotations = risks.flatMap((a) => a.basis_annotations);
  return (
    <Stack sx={{ gap: "11px" }}>
      <TableContainer
        sx={{
          "tbody tr:nth-of-type(1) th": {
            backgroundColor: "#F4F4F4",
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>疾患名</TableHeaderCell>
              {risks.map((r, i) => (
                <TableHeaderCell key={i}>{r.disease_name}</TableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  color: "#000",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontSize: "11px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "170%",
                  letterSpacing: "0.2px",
                }}
              >
                判定
              </TableCell>
              {risks.map((r, i) => (
                <TableCell
                  key={i}
                  sx={{
                    color: "#000",
                    fontFamily: "Noto Sans JP",
                    fontSize: "11px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "170%",
                    letterSpacing: "0.2px",
                  }}
                >
                  {r.basis_name}
                  <sup>
                    {r.basis_annotations
                      .map((a) => `*${allAnnotations.indexOf(a) + 1}`)
                      .join("")}
                  </sup>
                  <br />
                  {r.is_risk == null && "問診回答不足により"}
                  {getRiskLabel(r.is_risk)}
                  {/* 問診回答以外の場合、点数を表示 */}
                  {r.is_risk != null && `（${r.score}/${r.max_score}点）`}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        alignItems="flex-start"
        sx={{
          ml: "16px",
          gap: "11px",
        }}
      >
        <Annotation text="判定の根拠" showRightBorder />
        {allAnnotations.map((r, i) => (
          <Annotation key={i} text={`*${i + 1}：${r}`} />
        ))}
      </Stack>
    </Stack>
  );
}

/**
 * {@link LegendItem} のプロパティ
 */
interface LegendItemProps {
  /** マーク */
  mark: ReactNode;
  /** 色 */
  color?: string;
  /** テキスト */
  text: string;
}
/**
 * フラグの凡例
 */
function LegendItem({ mark, color, text }: LegendItemProps) {
  const markNode =
    typeof mark === "string" ? (
      <Typography
        sx={{
          color,
          fontSize: "10px",
          fontWeight: "700",
          ml: "20px",
          mr: "6px",
        }}
      >
        {mark}
      </Typography>
    ) : (
      <span style={{ marginLeft: "20px", marginRight: "6px" }}>{mark}</span>
    );
  return (
    <Stack direction="row" alignItems="center">
      {markNode}
      <Typography
        sx={{
          color: "#000",
          textAlign: "right",
          fontFamily: "Noto Sans JP",
          fontSize: "11px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "100%",
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
}

/**
 * {@link SleepResultRow} のプロパティ
 */
interface SleepResultRowProps {
  /** タイトル */
  title: string;
  /** データ */
  data: (string | null)[];
}
/**
 * 参考情報の行
 *
 * @param param0 {@link SleepResultRowProps}
 * @returns 参考情報の行
 */
function SleepResultRow({ title, data }: SleepResultRowProps) {
  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        sx={{
          /* NOTE: ここのwidthの値はグラフの左端（ただし18:00などのラベルを除く）と、
                   テーブルの左端（ただしタイトルのセルを除く）の位置を合わせるための値。
                   うかつに値を変えたり、あるいは SleepDiaryChart の仕様や位置を変えると
                   レイアウトが崩れるので、注意してください。

                   とはいえそもそもこのように、
                   https://accelstarsinc.slack.com/archives/C03SXTHETBN/p1709945386307419
                   グラフと表のx座標位置は現状揃っていません。
          */
          "&.MuiTableCell-root": {
            p: "10px 0px 10px 10px",
            width: "68px",
            color: "#000000",
            fontSize: "9px",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Noto Sans JP",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "100%",
            letterSpacing: "0.2px",
            whiteSpace: "nowrap",
          },
        }}
      >
        {title}
      </TableCell>
      {data.map((d, index) => (
        <TableCell
          key={index}
          sx={{
            "&.MuiTableCell-root": {
              p: "0px",
            },
            color: "#000000",
            textAlign: "center",
            width: "89px",
          }}
        >
          {d ?? "-"}
        </TableCell>
      ))}
    </TableRow>
  );
}
