import { Stack, Typography } from "@mui/material";

export function Reference({ children }: { children: string }) {
  return (
    <Stack sx={{ mt: 4, mb: 1 }}>
      <Typography variant="subtitle2">参考：</Typography>
      <Typography variant="caption">{children}</Typography>
    </Stack>
  );
}
