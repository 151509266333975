import DiseaseRiskReport from "@/components/report/disease_risk/DiseaseRiskReport";
import {
  useRecommendationAPI,
  useRecommendationExistenceAPI,
} from "@/components/report/disease_risk/apis";
import {
  RecommendationExistenceInfo,
  RecommendationInfo,
} from "@/components/report/disease_risk/commons";
import { examineeTheme } from "@/theme/themeV1";
import { ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function DiseaseRiskPage() {
  const { uuid } = useParams();
  const recommendationAPI = useRecommendationAPI();
  const recommendationExistenceAPI = useRecommendationExistenceAPI();

  const [recommendation, setRecommendation] = useState<RecommendationInfo>();
  const [recommendationExistenceInfo, setRecommendationExistenceInfo] =
    useState<RecommendationExistenceInfo>();

  useEffect(() => {
    (async () => {
      if (uuid == null) return;
      const response = await recommendationAPI.get(uuid);
      setRecommendation(response.data);
    })();
  }, [recommendationAPI, uuid]);

  useEffect(() => {
    (async () => {
      if (uuid == null) return;
      const response = await recommendationExistenceAPI.get(uuid);
      setRecommendationExistenceInfo(response.data);
    })();
  }, [recommendationExistenceAPI, uuid]);

  if (recommendation == null || recommendationExistenceInfo == null)
    return <></>;
  return (
    <ThemeProvider theme={examineeTheme}>
      <DiseaseRiskReport
        data={recommendation}
        existence={recommendationExistenceInfo}
      />
    </ThemeProvider>
  );
}
