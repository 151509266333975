import { Typography } from "@mui/material";
import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function NapHelpDialog() {
  return (
    <HelpButton dialogTitle="仮眠">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "睡眠への影響",
            content:
              "毎日夜の睡眠時間を十分確保できず、日中に眠気がある場合には、仮眠をとることで眠気の軽減効果があります。一方で、夕方以降の仮眠はその夜の睡眠に寝付きが悪くなるなどの影響を及ぼします。",
          },
          {
            title: "おすすめ",
            content:
              "日中に眠気がある場合は寝る8時間より前に30分以内の仮眠をとりましょう。",
            additionalContent: (
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                ※30分以上の長い仮眠は深い睡眠に入ってしまうため起きた後ぼーっとしてしまいます。
              </Typography>
            ),
          },
        ]}
      />
    </HelpButton>
  );
}
