import { SCSelect } from "@/components/sleep_checkup_v1/SCSelect";
import { MenuItem } from "@mui/material";

/**
 * 並び替え用ドロップダウンメニュー
 */
interface OrderSelectProps<T extends string> {
  items: Map<T, string>;
  value: T;
  onChange: (order: T) => void;
}
export function OrderSelect<T extends string>({
  items,
  value,
  onChange,
}: OrderSelectProps<T>) {
  return (
    <SCSelect
      label="並び替え"
      value={value}
      sx={{ width: "224px" }}
      variant="outlined"
      onChange={(event) => {
        onChange(event.target.value as T);
      }}
    >
      {[...items.entries()].map(([key, value], i) => (
        <MenuItem key={i} value={key}>
          {value as string}
        </MenuItem>
      ))}
    </SCSelect>
  );
}
