import { Copyright } from "@/components/examinee/Copyright";
import {
  Box,
  Button,
  Divider,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ReportDialog from "../commons/ReportDialog";
import { ReportImg } from "../commons/ReportImg";
import Stepper from "../commons/Stepper";
import { Tag } from "../commons/Tag";
import { getRiskLabel } from "../disease_risk/commons";
import { JudgeType } from "../types";
import { SummaryPageProperties, useSummaryPageContext } from "./Summary";
import { TeacherComment } from "./commons";

/**
 * 睡眠の状態
 *
 * @param param0
 * @returns
 */
function SleepJudge({ type }: { type: JudgeType }) {
  const config = {
    alert: {
      text: "警戒",
      backgroundColor: "#FF3A301F",
      color: "#FF3A30",
    },
    caution: {
      text: "注意",
      backgroundColor: "#fff7ea",
      color: "#ff910b",
    },
    good: {
      text: "良好",
      backgroundColor: "#e3e9f3",
      color: "#33529d",
    },
    unknown: {
      text: "判定不能",
      backgroundColor: "#0000001F",
      color: "#707070",
    },
  }[type];
  return (
    <Tag
      sx={{
        backgroundColor: config.backgroundColor,
        color: config.color,
      }}
    >
      {config.text}
    </Tag>
  );
}

/**
 * 睡眠の状態
 *
 * @param param0
 * @returns
 */
function DaytimeJudge({ problem }: { problem: boolean }) {
  const config = [
    {
      text: "課題あり",
      backgroundColor: "#FF3A301F",
      color: "#FF3A30",
    },
    {
      text: "課題なし",
      backgroundColor: "#e3e9f3",
      color: "#33529d",
    },
  ][problem ? 0 : 1];
  return (
    <Tag
      sx={{
        backgroundColor: config.backgroundColor,
        color: config.color,
      }}
    >
      {config.text}
    </Tag>
  );
}

/**
 * 睡眠の項目別評価
 */
function SleepItemizedEvaluation({
  type,
  name,
}: {
  type: JudgeType;
  name: "quantity" | "quality" | "rhythm";
}) {
  const title = {
    quantity: "睡眠の長さ",
    quality: "睡眠の質",
    rhythm: "睡眠のリズム",
  }[name];
  return (
    <>
      <Stack direction="row" sx={{ m: "16px 8px" }}>
        <Stack direction="row">
          <ReportImg
            style={{ marginRight: "4px" }}
            image_name={`image_report_summary_sleep_and_daytime_${name}.svg`}
          />
          <Typography variant="subtitle2">{title}</Typography>
        </Stack>
        <Stack sx={{ marginLeft: "auto" }}>
          <SleepJudge type={type} />
        </Stack>
      </Stack>
      <Divider />
    </>
  );
}

/**
 * 日中の項目別評価
 */
function DaytimeItemizedEvaluation({
  problem,
  name,
}: {
  problem: boolean;
  name: "drowsiness" | "fatigue";
}) {
  const title = {
    drowsiness: "日中の眠気",
    fatigue: "日中の疲労",
  }[name];
  return (
    <>
      <Stack direction="row" sx={{ m: "16px 8px" }}>
        <Stack direction="row">
          <ReportImg
            style={{ marginRight: "4px" }}
            image_name={`image_report_summary_sleep_and_daytime_${name}.svg`}
          />
          <Typography variant="subtitle2">{title}</Typography>
        </Stack>
        <Stack sx={{ marginLeft: "auto" }}>
          <DaytimeJudge problem={problem} />
        </Stack>
      </Stack>
      <Divider />
    </>
  );
}

/**
 * 疾患リスクの評価
 */
function DiseaseRiskEvaluation({
  existence,
  name,
}: {
  existence: boolean | null;
  name: "SAS" | "insomnia";
}) {
  const title = {
    SAS: "睡眠時無呼吸症候群",
    insomnia: "不眠症",
  }[name];
  function getProperty(): SxProps {
    if (existence == null) {
      return {
        // 未判定
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        color: "#A3A3A3",
      };
    }
    if (existence) {
      return {
        // リスクあり
        backgroundColor: "rgba(255, 58, 48, 0.12)",
        color: "#FF3A30",
      };
    }
    return {
      // リスク低い
      backgroundColor: "rgba(0, 86, 160, 0.12)",
      color: "#0056A0",
    };
  }
  return (
    <>
      <Stack direction="row" sx={{ m: "16px 8px" }}>
        <Stack direction="row">
          <ReportImg
            style={{ marginRight: "4px" }}
            image_name={`image_report_summary_sleep_and_daytime_${name}.svg`}
          />
          <Typography variant="subtitle2">{title}</Typography>
        </Stack>
        <Stack sx={{ marginLeft: "auto" }}>
          <Tag sx={getProperty()}>{getRiskLabel(existence)}</Tag>
        </Stack>
      </Stack>
      <Divider />
    </>
  );
}
/**
 * 睡眠と日中の記録
 *
 * @param param0
 * @returns
 */
export default function SleepAndDaytimeRecords() {
  const context = useSummaryPageContext();
  const [openInitDialog, setOpenInitDialog] = useState(context.isFirstVisit);
  return (
    <Stack
      sx={{
        p: "24px 16px 80px 16px",
        gap: "24px",
        height: "100%",
      }}
    >
      <SleepAndDaytimeDialog
        open={openInitDialog}
        setOpen={setOpenInitDialog}
        context={context}
      />
      <Stack
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          p: "16px",
          gap: "16px",
        }}
      >
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="h6">睡眠の状態</Typography>
          <Typography variant="body2">
            デバイスで測定した睡眠が基準内に収まっているかどうかを評価します。
          </Typography>
        </Stack>
        <Stack sx={{ mb: "8px" }}>
          <Typography
            variant="subtitle2"
            sx={{ color: "text.secondary", mb: "8px" }}
          >
            項目別評価
          </Typography>
          <Divider />
          <SleepItemizedEvaluation
            type={
              context.props.data.sleep_and_daytime_record.sleep_condition
                .quantity
            }
            name="quantity"
          />
          <SleepItemizedEvaluation
            type={
              context.props.data.sleep_and_daytime_record.sleep_condition
                .quality
            }
            name="quality"
          />
          <SleepItemizedEvaluation
            type={
              context.props.data.sleep_and_daytime_record.sleep_condition.rhythm
            }
            name="rhythm"
          />
        </Stack>
      </Stack>

      <Stack
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          p: "16px",
          gap: "16px",
        }}
      >
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="h6">日中の状態</Typography>
          <Typography variant="body2">
            測定期間の睡眠があなたにとって適切かを日中の状態から評価します。
          </Typography>
        </Stack>
        <Stack sx={{ mb: "8px" }}>
          <Typography
            variant="subtitle2"
            sx={{ color: "text.secondary", mb: "8px" }}
          >
            項目別評価
          </Typography>
          <Divider />
          <DaytimeItemizedEvaluation
            problem={
              context.props.data.sleep_and_daytime_record.daytime_condition
                .drowsiness_problem
            }
            name="drowsiness"
          />
          <DaytimeItemizedEvaluation
            problem={
              context.props.data.sleep_and_daytime_record.daytime_condition
                .fatigue_problem
            }
            name="fatigue"
          />
        </Stack>
      </Stack>

      {context.hasDiseaseRiskOption && (
        <Stack
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            p: "16px",
            gap: "16px",
          }}
        >
          <Stack sx={{ gap: "8px" }}>
            <Typography variant="h6">疾患リスク</Typography>
            <Typography variant="body2">
              診断ガイドラインに沿って睡眠時無呼吸症候群と不眠症のリスクを評価します。
            </Typography>
          </Stack>
          <Stack sx={{ mb: "8px" }}>
            <Typography
              variant="subtitle2"
              sx={{ color: "text.secondary", mb: "8px" }}
            >
              項目別評価
            </Typography>
            <Divider />
            <DiseaseRiskEvaluation
              existence={context.props.existence.recommendation_existence.SAS}
              name="SAS"
            />
            <DiseaseRiskEvaluation
              existence={
                context.props.existence.recommendation_existence.insomnia
              }
              name="insomnia"
            />
          </Stack>
        </Stack>
      )}

      <Stack sx={{ mb: "48px" }}>
        <TeacherComment>
          {context.props.data.sleep_and_daytime_record.comment}
        </TeacherComment>
      </Stack>
      <Stack
        sx={{
          pb: "10px",
          mb: "80px",
          position: "sticky",
          bottom: 0,
          zIndex: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{ boxShadow: "none" }}
          onClick={() => context.setScreenType("sleep_quantity")}
        >
          次へ
        </Button>
      </Stack>
      <Stack
        sx={{
          position: "sticky",
          alignItems: "center",
          p: "32px 16px",
        }}
      >
        <Copyright />
      </Stack>
    </Stack>
  );
}

/**
 * 睡眠の状態について
 * @param param0
 * @returns
 */
function SleepStateDialog({
  next,
  context,
  step,
  activeStep,
}: {
  next: () => void;
  context: SummaryPageProperties;
  step: () => number;
  activeStep: number;
}) {
  return (
    <Stack>
      <Stack sx={{ p: "16px" }}>
        <Stack direction="row" sx={{ gap: "8px" }}>
          <Typography variant="subtitle1" sx={{ color: "primary.dark" }}>
            睡眠の状態について
          </Typography>
          <Stack
            direction="row"
            sx={{ marginLeft: "auto", gap: "8px", alignItems: "center" }}
          >
            <Stepper step={step()} activeStep={activeStep} />
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ background: "#EBF1F7", p: "16px" }}>
        <ReportImg image_name="image_report_sleep_state.png" />
      </Stack>
      <Stack sx={{ p: "16px" }}>
        <Typography variant="body2">
          睡眠を「長さ」、「質」、「リズム」の3つの項目に分解して評価しています。
        </Typography>
        <Box textAlign="center" sx={{ pt: "16px" }}>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "18px",
              p: "6px 32px 6px 32px",
              height: "32px",
            }}
            onClick={() => next()}
          >
            つぎへ
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}

/**
 * 日中の状態について
 *
 * @param param0
 * @returns
 */
function DaytimeStateDialog({
  next,
  context,
  step,
  activeStep,
}: {
  next: () => void;
  context: SummaryPageProperties;
  step: () => number;
  activeStep: number;
}) {
  return (
    <Stack>
      <Stack sx={{ p: "16px" }}>
        <Stack direction="row" sx={{ gap: "8px" }}>
          <Typography variant="subtitle1" sx={{ color: "primary.dark" }}>
            日中の状態について
          </Typography>
          <Stack
            direction="row"
            sx={{ marginLeft: "auto", gap: "8px", alignItems: "center" }}
          >
            <Stepper step={step()} activeStep={activeStep} />
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ background: "#EBF1F7", p: "16px" }}>
        <ReportImg image_name="image_report_daytime_state.png" />
      </Stack>
      <Stack sx={{ p: "16px", gap: "8px" }}>
        <Typography variant="body2" textAlign="justify">
          前日の睡眠の結果、日中の活動に支障がなかったのかを、2つの項目で評価しています。
        </Typography>
        <Typography variant="caption" textAlign="justify">
          必要な睡眠には個人差があるため、睡眠の状態が良くても、日中に強い眠気や疲労が続く場合はあなたにとっては睡眠が不十分な可能性があります。
        </Typography>
        <Box textAlign="center" sx={{ pt: "16px" }}>
          <Button
            variant={context.hasDiseaseRiskOption ? "outlined" : "contained"}
            sx={{
              borderRadius: "18px",
              p: "6px 32px 6px 32px",
              boxShadow: "none",
            }}
            onClick={() => next()}
          >
            {context.hasDiseaseRiskOption ? "つぎへ" : "結果を見る"}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}

/**
 * 疾患のリスクについて
 *
 * @param param0
 * @returns
 */
function DiseaseRiskDialog({
  next,
  step,
  activeStep,
}: {
  next: () => void;
  context: SummaryPageProperties;
  step: () => number;
  activeStep: number;
}) {
  return (
    <Stack>
      <Stack sx={{ p: "16px" }}>
        <Stack direction="row" sx={{ gap: "8px" }}>
          <Typography variant="subtitle1" sx={{ color: "primary.dark" }}>
            疾患リスクについて
          </Typography>
          <Stack
            direction="row"
            sx={{ marginLeft: "auto", gap: "8px", alignItems: "center" }}
          >
            <Stepper step={step()} activeStep={activeStep} />
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ background: "#EBF1F7", p: "16px" }}>
        <ReportImg image_name="image_report_disease_risk.png" />
      </Stack>
      <Stack sx={{ p: "16px", gap: "8px" }}>
        <Typography variant="body2" textAlign="justify">
          代表的な睡眠障害である「睡眠時無呼吸症候群」「不眠症」について、あなたの問診回答を各診断ガイドラインに基づいて評価し、それぞれの疾患リスクを表示しています。
        </Typography>
        <Typography variant="caption" textAlign="justify">
          ※疾患リスクや本レポートで提供される情報は、病気の診断を意図するものではありません。
        </Typography>
        <Box textAlign="center" sx={{ pt: "16px" }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "18px",
              p: "6px 32px 6px 32px",
              boxShadow: "none",
            }}
            onClick={() => next()}
          >
            結果を見る
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}

type SleepAndDaytimeDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  context: SummaryPageProperties;
};

/**
 * 睡眠と日中の記録の初期表示ダイアログ
 *
 * @param param0
 * @returns
 */
function SleepAndDaytimeDialog({
  open,
  setOpen,
  context,
}: SleepAndDaytimeDialogProps) {
  const [display, setDisplay] = useState(0);
  const dialogs = [];
  function next() {
    if (dialogs.length === display + 1) {
      return setOpen(false);
    }
    return setDisplay((display) => display + 1);
  }
  dialogs.push(
    <SleepStateDialog
      next={next}
      context={context}
      step={() => dialogs.length}
      activeStep={dialogs.length + 1}
    />
  );
  dialogs.push(
    <DaytimeStateDialog
      next={next}
      context={context}
      step={() => dialogs.length}
      activeStep={dialogs.length + 1}
    />
  );
  if (context.hasDiseaseRiskOption) {
    dialogs.push(
      <DiseaseRiskDialog
        next={next}
        context={context}
        step={() => dialogs.length}
        activeStep={dialogs.length + 1}
      />
    );
  }
  return (
    <ReportDialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      {dialogs[display]}
    </ReportDialog>
  );
}
