import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function TobaccoHelpDialog() {
  return (
    <HelpButton dialogTitle="喫煙">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "睡眠への影響",
            content:
              "ニコチンには強い覚醒作用があり、寝付きが悪くなる・中途覚醒の増加・睡眠効率の低下・深睡眠の減少などさまざまな影響をもたらします。喫煙歴の長い人は睡眠時無呼吸症候群のリスクが高くなります。",
          },
          {
            title: "おすすめ",
            content:
              "喫煙により摂取したニコチンは約1時間作用します。少なくとも就寝1時間前や途中で起きた時の喫煙は避けましょう。",
          },
        ]}
      />
    </HelpButton>
  );
}
