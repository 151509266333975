import { SxProps, Theme, Typography } from "@mui/material";

type CopyrightProps = {
  sx?: SxProps<Theme>;
};
export function Copyright({ sx }: CopyrightProps) {
  return (
    <Typography variant="caption" color="text.disabled" sx={sx}>
      &copy; ACCELStars,inc.
    </Typography>
  );
}
