import { getEncodedCurrentPath } from "@/components/common/LocationService";
import { InfoAlert, NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { Copyright } from "@/components/examinee/Copyright";
import {
  getDays,
  toColor,
} from "@/components/examinee/daily_interview_remind/DateService";
import {
  getReminds,
  postReminds,
} from "@/components/examinee/daily_interview_remind/RemindAPI";
import {
  NOT_SELECTED,
  createRemindItem,
  updateHour,
  updateMinutes,
  validate,
} from "@/components/examinee/daily_interview_remind/RemindService";
import {
  Remind,
  RemindImpl,
  RemindItem,
  SimpleTime,
} from "@/components/examinee/daily_interview_remind/Types";
import { canBack } from "@/components/examinee/interview/Interview";
import { InterviewAppBar } from "@/components/examinee/interview/InterviewAppBar";
import { paths } from "@/components/examinee/interview/Path";
import { ProgressBar } from "@/components/examinee/interview/ProgressBar";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import Constants from "@/utils/constants";
import { toIsoString, toStringDayOfWeek } from "@/utils/date";
import {
  AlertTitle,
  Box,
  Button,
  ButtonProps,
  Container,
  Divider,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

type RemindChangeHandler = (index: number, value: string) => void;

export function DailyInterviewRemind({ headerHeight }: ExamineePageProps) {
  const [displayedDate, setDisplayedDate] = useState<Date>();
  const [remindItems, setRemindItems] = useState<RemindItem[]>([]);
  const [mode, setMode] = useState<Mode>("Read");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const FOOTER_HEIGHT = "85px";
  const PX = 4;
  const BACKGROUND_COLOR = "background.default";

  useEffect(() => {
    setDisplayedDate(new Date());
  }, []);

  useEffect(() => {
    if (params.sleepCheckupId == null) {
      throw new Error("sleepCheckupId is null");
    }

    getReminds(params.sleepCheckupId, getEncodedCurrentPath(location))
      .then((res) => {
        const reminds = res.data as Remind[];
        const mode = getMode(reminds);
        setMode(mode);
        setRemindItems(getRemindItems(mode, reminds));
      })
      .catch((err) => {
        setErrorMessage("エラーが発生しました。しばらく経ってから、再試行してください。")
      });
  }, [params.sleepCheckupId, location]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleChangeHour: RemindChangeHandler = (index, value) => {
    setRemindItems(updateHour(remindItems, index, value));
  };

  const handleChangeMinutes: RemindChangeHandler = (index, value) => {
    setRemindItems(updateMinutes(remindItems, index, value));
  };

  const handleClick = () => {
    if (mode === "Read") {
      // 閲覧モードのとき、リマインド設定を実施する必要がないので、何もせずに次の画面へ遷移
      navigate(paths.getFullPath("PrimaryInterviewCompletion"), {
        state: { canBack: true },
      });
      return;
    }

    const reminds: Remind[] = remindItems.map((item) => {
      if (
        params.sleepCheckupId == null ||
        params.sleepCheckupId === undefined
      ) {
        throw new Error("params.sleepCheckupId is null");
      }

      return new RemindImpl(toIsoString(item.dateTime), params.sleepCheckupId);
    });

    postReminds(reminds, getEncodedCurrentPath(location))
      .then((res) => {
        const displayedHour = displayedDate!.getHours();
        // 現在日時が0 ~ 4時の間の場合、次の画面でダイアログを表示
        const caution = 0 <= displayedHour && displayedHour < 4;
        navigate(paths.getFullPath("PrimaryInterviewCompletion"), {
          state: { canBack: true, caution, remindItems },
        });
      })
      .catch((err) => {
        setErrorMessage("エラーが発生しました。しばらく経ってから、再試行してください。");
        // エラーメッセージが表示されるように画面スクロールを指定
        window.scrollTo(0, 0);
      });
  };

  return (
    <>
      <ScrollToTop />
      <Box
        sx={{
          minHeight: "100vh",
          position: "relative",
          backgroundColor: BACKGROUND_COLOR,
        }}
      >
        <InterviewAppBar
          height={headerHeight}
          title="通知メール設定"
          onClickBack={canBack(location) ? handleBack : undefined}
        />
        <NonFieldError>{errorMessage}</NonFieldError>
        <Container
          maxWidth="sm"
          sx={{
            p: 0,
            pt: 0,
            pl: 0,
            pr: 0,
            pb: FOOTER_HEIGHT,
          }}
        >
          {remindItems.length > 0 && (
            <>
              <Box sx={{ backgroundColor: "white" }}>
                <Stack sx={{ py: 6, px: PX }} spacing={6}>
                  <ProgressBar value={95} />
                  {mode === "Read" && <ReadOnlyAlert />}
                  <RemindGuideMessage />
                </Stack>
              </Box>
              <RemindForm
                sx={{
                  pt: 6,
                  px: PX,
                  pb: 16,
                  backgroundColor: BACKGROUND_COLOR,
                }}
                remindItems={remindItems}
                disabledSelect={mode === "Read"}
                onChangeHour={handleChangeHour}
                onChangeMinutes={handleChangeMinutes}
                onClick={handleClick}
              />
            </>
          )}
        </Container>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: FOOTER_HEIGHT,
            backgroundColor: BACKGROUND_COLOR,
          }}
        >
          <Copyright />
        </Stack>
      </Box>
    </>
  );
}

function RemindGuideMessage() {
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1" textAlign="justify">
        測定は本日の就寝時から開始され、明日の起床後から毎日の問診に回答いただきます。問診の通知メールをお送りするため、測定期間中のおおよその起床時刻を入力してください。
      </Typography>
      <Typography variant="caption">
        ※交替勤務等で1日2回以上就寝する場合は、一番長い睡眠に関してお答えください。
      </Typography>
    </Stack>
  );
}

type RemindFormProps = {
  sx: SxProps<Theme>;
  remindItems: RemindItem[];
  disabledSelect: boolean;
  onChangeHour: RemindChangeHandler;
  onChangeMinutes: RemindChangeHandler;
  onClick: ButtonProps["onClick"];
};
function RemindForm({
  sx,
  remindItems,
  disabledSelect,
  onChangeHour,
  onChangeMinutes,
  onClick,
}: RemindFormProps) {
  const HOURS = [
    NOT_SELECTED,
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];
  const MINUTES = [
    NOT_SELECTED,
    "00",
    "05",
    "10",
    "15",
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "55",
  ];

  return (
    <Stack sx={sx} spacing={12}>
      <Stack>
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">日付</Typography>
            <Typography variant="caption">おおよその起床時刻</Typography>
          </Stack>
          <Divider sx={{ mt: "8px", mb: "16px" }} />
        </Stack>
        <Stack spacing={6}>
          {remindItems.map((item, i) => {
            return (
              <Stack
                key={i}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <DateTypography date={item.date} first={i === 0} />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <TimeSelect
                    value={item.time.hour}
                    disabled={disabledSelect}
                    onChange={(value) => onChangeHour(i, value)}
                  >
                    {HOURS.map((v) => (
                      <option value={v} key={v}>
                        {v}
                      </option>
                    ))}
                  </TimeSelect>
                  <Typography variant="body2">時</Typography>
                  <TimeSelect
                    value={item.time.minutes}
                    disabled={disabledSelect}
                    onChange={(value) => onChangeMinutes(i, value)}
                  >
                    {MINUTES.map((v) => (
                      <option value={v} key={v}>
                        {v}
                      </option>
                    ))}
                  </TimeSelect>
                  <Typography variant="body2">分頃</Typography>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <Button
        variant="contained"
        size="large"
        disabled={!validate(remindItems)}
        onClick={onClick}
      >
        次へ
      </Button>
    </Stack>
  );
}

type DateTypographyProps = {
  date: Date;
  first?: boolean;
};
function DateTypography({ date, first }: DateTypographyProps) {
  const VARIANT = "subtitle1";
  return (
    <Stack direction="row" sx={{ alignItems: "center" }}>
      <Typography variant={VARIANT}>{format(date, "M月d日")}</Typography>
      <Typography variant={VARIANT}>(</Typography>
      <Typography variant={VARIANT} color={toColor(date)}>
        {toStringDayOfWeek(date)}
      </Typography>
      <Typography variant={VARIANT}>)</Typography>
      {first && (
        <Typography
          variant="caption"
          sx={{ verticalAlign: "center", m: "8px", color: "error.main" }}
        >
          ※明日
        </Typography>
      )}
    </Stack>
  );
}

type TimeSelectProps = {
  children: ReactNode;
  value: string;
  disabled: SelectProps["disabled"];
  onChange: (value: string) => void;
};
function TimeSelect({ children, value, disabled, onChange }: TimeSelectProps) {
  return (
    <Select
      native
      value={value}
      size="small"
      disabled={disabled}
      sx={{
        select: {
          paddingLeft: "16px !important",
          paddingRight: "16px !important",
        },
        backgroundColor: "white",
      }}
      IconComponent={() => null}
      onChange={(event: SelectChangeEvent) => {
        onChange(event.target.value);
      }}
    >
      {children}
    </Select>
  );
}

type Mode = "Read" | "Write";

function getMode(reminds: Remind[]) {
  return reminds.length === 0 ? "Write" : "Read";
}

function getRemindItems(mode: Mode, reminds: Remind[]) {
  if (mode === "Write") {
    const dates = getDays(Constants.SLEEP_CHECKUP_LENGTH);
    // 規定のRemindItem配列を生成
    return dates.map(
      (d) => new RemindItem(d, new SimpleTime(NOT_SELECTED, NOT_SELECTED))
    );
  } else {
    return reminds.map((r) => createRemindItem(r));
  }
}

function ReadOnlyAlert() {
  return (
    <InfoAlert>
      <AlertTitle>閲覧専用</AlertTitle>
      <Typography variant="body2">
        このページでは回答内容の確認のみ可能です。内容の編集はできません。
      </Typography>
    </InfoAlert>
  );
}
