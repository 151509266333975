import { useJudgementTable } from "@/components/report/apis";
import { JudgementTable } from "@/components/report/types/judgement_tables";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import HelpButton from "../HelpButton";
import AnnotationTypography from "./criterions/AnnotationTypography";
import CriterionTableContainer, {
  Cells,
} from "./criterions/CriterionTableContainer";

export default function SleepImprovementNeededCriterionDialog({
  uuid,
}: {
  uuid?: string;
}) {
  const api = useJudgementTable();
  const [judgementTable, setJudgementTable] = useState<JudgementTable>();

  useEffect(() => {
    if (uuid == null) return;
    (async () => {
      const res = await api.get(uuid);
      setJudgementTable(res.data);
    })();
  }, [uuid, api]);

  const gradeColors = judgementTable?.grade_alphabet.map(
    (g) =>
      ({
        A: Cells.color.very_good,
        B: Cells.color.good,
        C: Cells.color.caution,
        D: Cells.color.bad,
        E: Cells.color.very_bad,
      }[g])
  );
  return (
    <HelpButton
      dialogTitle="睡眠改善必要度の判定基準"
      dialogProps={{
        maxWidth: "lg",
      }}
    >
      <Stack sx={{ mb: 6 }}>
        <Typography variant="subtitle1">睡眠改善必要度：</Typography>
        <Typography variant="body1">
          睡眠を「睡眠の状態」（デバイスによる睡眠の測定結果）、「日中の状態」（問診による日中の測定結果）、および「疾患リスク」（睡眠時無呼吸症候群、不眠症の診断ガイドラインに基づく問診の判定結果）の組み合わせで評価します。
        </Typography>
      </Stack>
      <CriterionTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={(judgementTable?.grade_alphabet.length ?? 0) + 1}
              >
                <Typography variant="subtitle2">判定の仕組み</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell sx={{ boxSizing: "border-box", width: "200px" }}>
                <Typography variant="subtitle2">睡眠の状態</Typography>
                <Typography variant="caption">
                  良好/やや課題あり/課題あり
                </Typography>
              </TableCell>
              {judgementTable?.sleep_condition.map((v, i) => (
                <TableCell
                  key={i}
                  sx={{
                    backgroundColor: gradeColors?.[i],
                    boxSizing: "border-box",
                    width: "100px",
                  }}
                >
                  <Typography variant="body2">{v}</Typography>
                </TableCell>
              ))}
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">日中の状態</Typography>
                <Typography variant="caption">課題なし/課題あり</Typography>
              </TableCell>
              {judgementTable?.daytime_condition.map((v, i) => (
                <TableCell key={i} sx={{ backgroundColor: gradeColors?.[i] }}>
                  <Typography variant="body2">{v}</Typography>
                </TableCell>
              ))}
            </TableRow>

            {judgementTable?.disease_risk && (
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">疾患リスク</Typography>
                  <Typography variant="caption">
                    リスク低/未判定/リスクあり
                  </Typography>
                </TableCell>
                {judgementTable.disease_risk.map((v, i) => (
                  <TableCell key={i} sx={{ backgroundColor: gradeColors?.[i] }}>
                    {v === "low_risk" ? (
                      <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                        リスク低
                        <br />
                        <span style={{ fontSize: "12px" }}>または</span>
                        <br />
                        未判定
                      </Typography>
                    ) : (
                      <Typography variant="body2">{v}</Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">判定</Typography>
              </TableCell>
              {judgementTable?.grade_alphabet.map((v, i) => (
                <TableCell key={i} sx={{ backgroundColor: gradeColors?.[i] }}>
                  <Typography variant="subtitle1">{v}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </CriterionTableContainer>
      <AnnotationTypography>
        ※睡眠に関して治療中の場合は判定の横に*が付きます。
      </AnnotationTypography>
      <AnnotationTypography>
        ※測定データが4日に満たない場合は判定不能。
      </AnnotationTypography>
    </HelpButton>
  );
}
