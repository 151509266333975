import { Typography } from "@mui/material";

type NumberBoxProps = {
  number: number;
};
export function NumberBox({ number }: NumberBoxProps) {
  return (
    <Typography variant="h6" sx={{ color: "primary.main" }}>
      Q{number}
    </Typography>
  );
}
