import { PAGE_TITLE, paths } from "@/components/sleep_checkup_v1/Path";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import { Breadcrumbs, Button, ButtonProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

type BreadcrumbLinkProps = {
  children: ReactNode;
  startIcon?: ButtonProps["startIcon"];
  to: string;
  disabled?: ButtonProps["disabled"];
};
function BreadcrumbLink({
  children,
  startIcon,
  to,
  disabled,
}: BreadcrumbLinkProps) {
  return (
    <Button
      component={Link}
      startIcon={startIcon}
      to={to}
      sx={{
        p: 0,
        "&.Mui-disabled": {
          color: "text.secondary",
        },
      }}
      disabled={disabled}
    >
      <Typography variant="body2">{children}</Typography>
    </Button>
  );
}

/**
 * パンくずリスト用のリンク 睡眠健康測定一覧
 */
export function SleepCheckupListLink() {
  return (
    <BreadcrumbLink
      startIcon={<HotelOutlinedIcon />}
      to={paths.getFullPath("SleepCheckupList")}
    >
      {PAGE_TITLE["SleepCheckupList"]}
    </BreadcrumbLink>
  );
}

/**
 * パンくずリスト用のリンク 測定詳細
 */
type SleepCheckupDetailLinkProps = {
  sleepCheckupId: string;
  disabled?: ButtonProps["disabled"];
};
export function SleepCheckupDetailLink({
  sleepCheckupId,
  disabled,
}: SleepCheckupDetailLinkProps) {
  return (
    <BreadcrumbLink
      to={paths.getFullPath("SleepCheckupDetail", String(sleepCheckupId))}
      disabled={disabled}
    >
      {PAGE_TITLE["SleepCheckupDetail"]}
    </BreadcrumbLink>
  );
}

/**
 * パンくずリスト用のリンク 基礎情報
 */
type SleepCheckupGuidanceLinkProps = {
  sleepCheckupId: string;
  disabled?: ButtonProps["disabled"];
};
export function SleepCheckupGuidanceLink({
  sleepCheckupId,
  disabled,
}: SleepCheckupGuidanceLinkProps) {
  return (
    <BreadcrumbLink
      to={paths.getFullPath("SleepCheckupGuidance", String(sleepCheckupId))}
      disabled={disabled}
    >
      {PAGE_TITLE["SleepCheckupGuidance"]}
    </BreadcrumbLink>
  );
}

type PrimaryInterviewResultLinkProps = {
  sleepCheckupId: string;
  disabled?: ButtonProps["disabled"];
};
export function PrimaryInterviewResultLink({
  sleepCheckupId,
  disabled,
}: PrimaryInterviewResultLinkProps) {
  return (
    <BreadcrumbLink
      to={paths.getFullPath("PrimaryInterviewResult", String(sleepCheckupId))}
      disabled={disabled}
    >
      {PAGE_TITLE["PrimaryInterviewResult"]}
    </BreadcrumbLink>
  );
}

type DailyInterviewResultLinkProps = {
  sleepCheckupId: string;
  disabled?: ButtonProps["disabled"];
};
export function DailyInterviewResultLink({
  sleepCheckupId,
  disabled,
}: DailyInterviewResultLinkProps) {
  return (
    <BreadcrumbLink
      to={paths.getFullPath("DailyInterviewResult", String(sleepCheckupId))}
      disabled={disabled}
    >
      {PAGE_TITLE["DailyInterviewResult"]}
    </BreadcrumbLink>
  );
}

/** 問診回答面用パンくずタイプ */
export type InterviewResultBreadcrumbType = "detail" | "guidance";
/**
 * {@link InterviewResultBreadcrumbs} 用プロパティ
 */
interface InterviewResultBreadcrumbsProps {
  /** sleep checkup id */
  sleepCheckupId: string;
  /** {@link InterviewResultBreadcrumbType} */
  breadcrumbType: InterviewResultBreadcrumbType;
  /** 表示内容 */
  children: ReactNode;
}
/**
 * 問診回答用パンくずリスト
 *
 * @param props {@link InterviewResultBreadcrumbsProps}
 * @returns 問診回答用パンくずリスト
 */
export function InterviewResultBreadcrumbs({
  sleepCheckupId,
  breadcrumbType,
  children,
}: InterviewResultBreadcrumbsProps) {
  const parent: ReactNode = {
    detail: <SleepCheckupDetailLink sleepCheckupId={sleepCheckupId} />,
    guidance: <SleepCheckupGuidanceLink sleepCheckupId={sleepCheckupId} />,
  }[breadcrumbType];
  return (
    <Breadcrumbs>
      <SleepCheckupListLink />
      {parent}
      {children}
    </Breadcrumbs>
  );
}
