import { ScrollToTop } from "@/components/common/ScrollToTop";
import { SubtitleTypography } from "@/components/common/Typographies";
import { RemindItem } from "@/components/examinee/daily_interview_remind/Types";
import { ExamineeFooter as Footer } from "@/components/examinee/ExamineeFooter";
import { InterviewAppBar } from "@/components/examinee/interview/InterviewAppBar";
import { ProgressBar } from "@/components/examinee/interview/ProgressBar";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import {
  Box,
  BoxProps,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { addDays, format } from "date-fns";
import { ReactNode, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";

function MeasurementPeriod({ remindItems }: { remindItems?: RemindItem[] }) {
  if (remindItems == null || remindItems.length === 0) return <></>;

  const fmt = (date?: Date) =>
    date == null ? null : format(addDays(date, -1), "yyyy年M月d日");
  return (
    <Stack
      sx={{
        mt: "32px",
        p: "12px 16px",
        backgroundColor: "#f5f8fb",
        borderRadius: "8px",
        gap: "4px",
      }}
    >
      <Typography variant="subtitle2" sx={{ color: "primary.main" }}>
        測定期間：
      </Typography>
      <Stack
        direction="row"
        sx={{
          gap: "4px",
          "& .measurement_date": {
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "31.5px",
            color: "text.primary",
          },
        }}
      >
        <Typography className="measurement_date">
          {fmt(remindItems.at(0)?.date)}
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "31.5px",
            color: "text.secondary",
          }}
        >
          ～
        </Typography>
        <Typography className="measurement_date">
          {fmt(remindItems?.at(-1)?.date)}
        </Typography>
      </Stack>
    </Stack>
  );
}

export function PrimaryInterviewCompletion({
  headerHeight,
}: ExamineePageProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const FOOTER_HEIGHT = "151px";
  const PX = 4;
  const BACKGROUND_COLOR = "background.default";

  const state = location.state as PrimaryInterviewCompletionState | undefined;

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <ScrollToTop />
      <CautionDialog caution={state?.caution} />
      <Box
        sx={{
          position: "relative",
          backgroundColor: BACKGROUND_COLOR,
        }}
      >
        <InterviewAppBar
          height={headerHeight}
          title="回答完了"
          onClickBack={canBack(location) ? handleBack : undefined}
        />
        <Container
          maxWidth="sm"
          sx={{
            p: 0,
            pt: 0,
            pl: 0,
            pr: 0,
            pb: FOOTER_HEIGHT,
          }}
        >
          <Box sx={{ backgroundColor: "white", p: "0px 16px 80px 16px" }}>
            <Stack sx={{ py: 6, px: PX }} spacing={6} alignItems="center">
              <ProgressBar sx={{ width: "100%" }} value={100} />
              <CompletionImage sx={{ py: 6 }} />
              <CompletionMessage />
            </Stack>
            <Divider sx={{ mt: "80px", mb: "32px" }} />
            <Stack sx={{ gap: "16px" }}>
              <Stack sx={{ mb: "40px", gap: "8px" }}>
                <SubtitleTypography>これからの流れ</SubtitleTypography>
                <Typography variant="body2" sx={{ mt: "8px" }}>
                  測定は本日から開始されます。寝床に入る前にデバイスをつけ、消灯時と起床時にボタンを押してください。明日から毎日問診のメールが届きますので、ご回答ください。
                </Typography>
                <MeasurementPeriod remindItems={state?.remindItems} />
              </Stack>

              <Stack sx={{ gap: "80px" }}>
                <StepContainer
                  step="STEP 01"
                  title="就寝時にデバイスを装着"
                  content="必ず事前にデバイスを充電し、寝床に入る際に装着してください。"
                  src={[
                    "image_primary_interview_completion_step_01_1",
                    "image_primary_interview_completion_step_01_2",
                  ]}
                  imageNote="※デバイスの色についてはイラストと異なる場合があります。"
                />
                <StepContainer
                  step="STEP 02"
                  title="消灯時と起床時にボタンを押す"
                  content={
                    <>
                      消灯（就寝）時と起床時<sup>※</sup>
                      に、デバイスのボタンを押してください。起床後はデバイスを外し、安全な場所で保管してください。
                    </>
                  }
                  note="※睡眠中に一時的に起きた際は、ボタンを押す必要はありません。"
                  src="image_primary_interview_completion_step_02"
                />
                <StepContainer
                  step="STEP 03"
                  title="毎日問診に回答"
                  content="毎日問診の案内がメールで届きます。忘れないうちに回答しましょう。"
                  src="image_primary_interview_completion_step_03"
                />
              </Stack>
            </Stack>
          </Box>
        </Container>
        <Footer
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: FOOTER_HEIGHT,
          }}
        />
      </Box>
    </>
  );
}

type CompletionImageProps = {
  sx: BoxProps["sx"];
};
function CompletionImage({ sx }: CompletionImageProps) {
  return (
    <Box sx={sx}>
      <img
        src="/img/image_interview_completion_01.png"
        alt="おつかれさまでした"
        loading="lazy"
        style={{ width: 260, height: "auto" }}
      />
    </Box>
  );
}

function CompletionMessage() {
  return (
    <Stack spacing={4} alignItems="center">
      <Typography variant="h6">おつかれさまでした！</Typography>
      <Typography variant="subtitle2" align="center" color="text.secondary">
        ご回答ありがとうございました
        <br />
        事前問診は以上です
      </Typography>
    </Stack>
  );
}

type PrimaryInterviewCompletionState = {
  canBack: boolean;
  /**
   * 通知メール設定画面(※回答完了画面ではなく一つ前の画面)にアクセスしたのが0時~4時の場合に、
   * 注意喚起ダイアログを表示するためのフラグ
   */
  caution?: boolean;

  /**
   * 通知メール設定画面で表示していた日付および時間
   */
  remindItems: RemindItem[];
};

function canBack(location: Location): boolean {
  if (location.state == null) {
    return false;
  }

  const state = location.state as PrimaryInterviewCompletionState;
  return state.canBack;
}

function StepContainer({
  step,
  title,
  content,
  note,
  src,
  imageNote,
}: {
  step: string;
  title: string;
  content: ReactNode;
  note?: string;
  src: string | string[];
  imageNote?: string;
}) {
  const sources = typeof src === "string" ? [src] : src;
  return (
    <Stack sx={{ gap: "16px" }}>
      <Stack sx={{ gap: "8px" }}>
        <StepTypography>{step}</StepTypography>
        <StepTitleTypography>{title}</StepTitleTypography>
        <StepContentTypography sx={{ textAlign: "justify" }}>
          {content}
        </StepContentTypography>
        {note && (
          <Typography variant="caption" sx={{ mb: "16px" }}>
            {note}
          </Typography>
        )}
      </Stack>
      {sources.map((s, i) => (
        <img key={i} src={`/img/${s}.svg`} alt={s} loading="lazy" />
      ))}
      {imageNote != null && (
        <Typography variant="caption" sx={{ textAlign: "center" }}>
          {imageNote}
        </Typography>
      )}
    </Stack>
  );
}

function StepTypography(props: TypographyProps) {
  return <Typography variant="subtitle1" color="primary.main" {...props} />;
}

function StepTitleTypography(props: TypographyProps) {
  return <Typography variant="h6" color="text.primary" {...props} />;
}

function StepContentTypography(props: TypographyProps) {
  return <Typography variant="body1" color="text.secondary" {...props} />;
}

function CautionDialog(props: { caution?: boolean }) {
  const [open, setOpen] = useState(props.caution ?? false);
  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle
        textAlign="center"
        variant="h6"
        sx={{ color: "primary.main" }}
      >
        ご注意ください
      </DialogTitle>
      <DialogContent>
        <Stack sx={{ gap: 4 }}>
          <Typography variant="body1">
            下図のように日付が変わってから事前問診に回答完了した場合、測定の開始は[睡眠①]ではなく、[睡眠②]の就寝タイミングからとなります。初回の毎日問診のメールは翌日に届きます。
          </Typography>
          <img
            src="/img/image_daily_interview_remind_caution.png"
            alt="caution"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ width: "100%" }}
          onClick={() => {
            setOpen(false);
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
