import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function LightHelpDialog() {
  return (
    <HelpButton dialogTitle="部屋の明かり">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "睡眠への影響",
            content:
              "寝る時間が近づいても明るい光を浴びていると、脳や交感神経の活動が高まり、スムーズな入眠を妨げます。また、体は夜を感じると「メラトニン」が分泌されます。起床から14〜15時間後に分泌されますが、その時間に明るい環境にいることでメラトニンが出にくくなり、眠りを安定させづらくなります。",
          },
          {
            title: "おすすめ",
            content:
              "寝る前から部屋の明るさを抑えることが重要です。就寝1時間前には白色照明を控え、暖色照明・間接照明等で部屋の明かりを半分ほどに抑えましょう。最近の照明は調光機能がついているものも多いため、明るさを抑えたモードに切り替えるのもおすすめです。",
          },
        ]}
      />
    </HelpButton>
  );
}
