import { Alert, AlertProps } from "@mui/material";

type ErrorMessageProps = {
  errorMessage: string;
  sx?: AlertProps["sx"];
};
function ErrorMessage(props: ErrorMessageProps) {
  return (
    <>
      {props.errorMessage === "" ? (
        ""
      ) : (
        <Alert severity="error" sx={props.sx}>
          {props.errorMessage}
        </Alert>
      )}
    </>
  );
}

export default ErrorMessage;
