import { Stack, Table, TableHead } from "@mui/material";
import { SectionTitle } from "../commons";
import {
  BasisOfDetermination,
  InterviewSummaryTableRow,
  InterviewTableBody,
  MedicalFacilityDiseaseRiskReportCommonProps,
  MedicalFacilityReportLayout,
  Question,
  QuestionRows,
  TableContainer,
  TableHeaderCell,
  TableQuestionTypography,
  TableRow,
} from "./commons";

/**
 * {@link AthensInsomniaScale} のプロパティ
 */
export interface AthensInsomniaScaleProps
  extends MedicalFacilityDiseaseRiskReportCommonProps {}
/**
 * 不眠リスクページ
 * @param param0 {@link AthensInsomniaScaleProps}
 * @returns 不眠リスクページ
 */
export default function AthensInsomniaScale({
  data,
  page,
  maxPage,
}: AthensInsomniaScaleProps) {
  if (data.disease_risks.insomnia == null) return <></>;
  const risk = data.disease_risks.insomnia;
  const questions: Question[] = [
    {
      question: (
        <TableQuestionTypography>
          寝付きの問題について（布団に入って電気を消してから眠るまでに要した時間）
        </TableQuestionTypography>
      ),
      choices: [
        { text: "問題なかった", point: 0 },
        { text: "少し時間がかかった", point: 1 },
        { text: "かなり時間がかかった", point: 2 },
        { text: "非常に時間がかかったか、全く眠れなかった", point: 3 },
      ],
    },
    {
      question: (
        <TableQuestionTypography>
          夜間、睡眠途中に目が覚める問題について
        </TableQuestionTypography>
      ),
      choices: [
        { text: "問題になるほどではなかった", point: 0 },
        { text: "少し困ることがあった", point: 1 },
        { text: "かなり困っている", point: 2 },
        { text: "深刻な状態か、全く眠れなかった", point: 3 },
      ],
    },

    {
      question: (
        <TableQuestionTypography>
          希望する起床時間より早く目覚め、それ以上眠れない問題について
        </TableQuestionTypography>
      ),
      choices: [
        { text: "そのようなことはなかった", point: 0 },
        { text: "少し早かった", point: 1 },
        { text: "かなり早かった", point: 2 },
        { text: "非常に早かった、あるいは全く眠れなかった", point: 3 },
      ],
    },

    {
      question: (
        <TableQuestionTypography>総睡眠時間について</TableQuestionTypography>
      ),
      choices: [
        { text: "十分だった", point: 0 },
        { text: "少し足りなかった", point: 1 },
        { text: "かなり足りなかった", point: 2 },
        { text: "全く足りないか、全く眠れなかった", point: 3 },
      ],
    },

    {
      question: (
        <TableQuestionTypography>
          全体的な睡眠の質について
        </TableQuestionTypography>
      ),
      choices: [
        { text: "満足している", point: 0 },
        { text: "少し不満", point: 1 },
        { text: "かなり不満", point: 2 },
        { text: "非常に不満か、全く眠れなかった", point: 3 },
      ],
    },

    {
      question: (
        <TableQuestionTypography>日中の満足感について</TableQuestionTypography>
      ),
      choices: [
        { text: "いつも通り", point: 0 },
        { text: "少し低下", point: 1 },
        { text: "かなり低下", point: 2 },
        { text: "非常に低下", point: 3 },
      ],
    },
    {
      question: (
        <TableQuestionTypography>
          日中の活動について（身体的および精神的）
        </TableQuestionTypography>
      ),
      choices: [
        { text: "いつも通り", point: 0 },
        { text: "少し低下", point: 1 },
        { text: "かなり低下", point: 2 },
        { text: "非常に低下", point: 3 },
      ],
    },
    {
      question: (
        <TableQuestionTypography>日中の眠気について</TableQuestionTypography>
      ),
      choices: [
        { text: "全くない", point: 0 },
        { text: "少しある", point: 1 },
        { text: "かなりある", point: 2 },
        { text: "激しい", point: 3 },
      ],
    },
  ];
  return (
    <MedicalFacilityReportLayout
      page={page}
      maxPage={maxPage}
      examinee={data.examinee}
    >
      <Stack sx={{ gap: "22px" }}>
        <SectionTitle text="不眠症リスク：アテネ不眠尺度" />
        <Stack>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell width="312px" padding="11px auto 11px 16px">
                    質問
                  </TableHeaderCell>
                  <TableHeaderCell
                    textAlign="center"
                    padding="11px 0px"
                    width="53px"
                  >
                    回答
                  </TableHeaderCell>
                  <TableHeaderCell textAlign="left">選択肢</TableHeaderCell>
                  <TableHeaderCell
                    textAlign="center"
                    padding="11px 0px"
                    width="53px"
                  >
                    点数
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <InterviewTableBody>
                {questions.map((q, i) => (
                  <QuestionRows
                    key={i}
                    rowIndex={i}
                    question={q}
                    choiceIndex={risk.choice_indices[i]}
                    choiceStyle={{
                      color: "#000",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Noto Sans JP",
                      fontSize: "8px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "150%",
                      letterSpacing: "0.2px",
                    }}
                    pointStyle={{
                      color: "#000",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Noto Sans JP",
                      fontSize: "8px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "150%",
                      letterSpacing: "0.2px",
                    }}
                  />
                ))}
                <InterviewSummaryTableRow
                  {...risk}
                  note="※合計6点以上で不眠症の疑いがあります"
                />
              </InterviewTableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" sx={{ m: "8px 16px" }}>
            <BasisOfDetermination basisAnnotations={risk.basis_annotations} />
          </Stack>
        </Stack>
      </Stack>
    </MedicalFacilityReportLayout>
  );
}
