import { Avatar, Stack } from "@mui/material";

import { ReportImg, ReportImgProps } from "../commons/ReportImg";
import { ReportMarkdown } from "../commons/ReportMarkdown";
import {
  RecommendationExistenceInfo,
  hasDiseaseRiskOption,
} from "../disease_risk/commons";
import { PointsOfImprovements } from "../types";

/**
 * サマリーページの測定結果の詳細のページ数を返却します。
 *
 * @param param0
 */
export function getDetailsOfMeasurementResultsCount({
  existence,
}: {
  existence: RecommendationExistenceInfo;
}) {
  let count = 4;
  if (hasDiseaseRiskOption(existence)) {
    count += 1;
  }
  return count;
}

/**
 * レポートの女性のコメント。
 *
 * @param param0
 * @returns
 */
export function TeacherComment({ children }: { children?: string | null }) {
  return (
    <Stack direction="row">
      <Stack>
        <Avatar alt="teacher" src="/img/image_report_summary_teacher.svg" />
      </Stack>
      <Stack sx={{ width: "100%", textAlign: "justify" }}>
        <ReportMarkdown>{children}</ReportMarkdown>
      </Stack>
    </Stack>
  );
}

/**
 * {@link ResultDiseaseRiskIcon} のプロパティ
 */
export interface ResultDiseaseRiskIconProps
  extends Omit<ReportImgProps, "image_name"> {}
/**
 * 疾患リスクのアイコンを表示します。
 *
 * @param props {@link ResultDiseaseRiskIconProps}
 * @returns 疾患リスクのアイコン
 */
export function ResultDiseaseRiskIcon(props: ResultDiseaseRiskIconProps) {
  return (
    <ReportImg
      image_name="image_report_summary_result_disease_risk.svg"
      {...props}
    />
  );
}

/**
 * {@link ResultBedtimeIconIcon} のプロパティ
 */
export interface ResultBedtimeIconProps
  extends Omit<ReportImgProps, "image_name"> {}
/**
 * 疾患リスクのアイコンを表示します。
 *
 * @param props {@link ResultBedtimeIconProps}
 * @returns 疾患リスクのアイコン
 */
export function ResultBedtimeIcon(props: ResultBedtimeIconProps) {
  return (
    <ReportImg
      image_name="image_report_summary_result_bedtime.svg"
      {...props}
    />
  );
}

/**
 * {@link ResultDaytimeIcon} のプロパティ
 */
export interface ResultDaytimeIconProps
  extends Omit<ReportImgProps, "image_name"> {}
/**
 * 疾患リスクのアイコンを表示します。
 *
 * @param props {@link ResultDaytimeIconProps}
 * @returns 疾患リスクのアイコン
 */
export function ResultDaytimeIcon(props: ResultDaytimeIconProps) {
  return (
    <ReportImg
      image_name="image_report_summary_result_daytime.svg"
      {...props}
    />
  );
}

/**
 * 睡眠の質の改善ポイントが存在するかどうか判定します。
 *
 * @param poi
 * @returns
 */
export function hasPointsOfImprovement(poi: PointsOfImprovements) {
  return poi != null && poi.length > 0;
}
