import { LineBreakText } from "@/components/common/LineBreakText";
import { getEncodedCurrentPath } from "@/components/common/LocationService";
import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import {
  CompletionInfo,
  FirstViewInfo,
  getCompletionInfo,
  MessageInfo,
} from "@/components/examinee/daily_interview_completion/CompletionInfo";
import {
  IconVariant,
  InterviewRecordInfo,
} from "@/components/examinee/daily_interview_completion/InterviewRecordInfo";
import { ExamineeFooter as Footer } from "@/components/examinee/ExamineeFooter";
import { canBack } from "@/components/examinee/interview/Interview";
import { InterviewAppBar } from "@/components/examinee/interview/InterviewAppBar";
import { ProgressBar } from "@/components/examinee/interview/ProgressBar";
import { getSleepCheckupInfo } from "@/components/examinee/sleep_checkup/SleepCheckupAPI";
import { SleepCheckupInfo } from "@/components/examinee/sleep_checkup/Types";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardProps,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export function DailyInterviewCompletion({ headerHeight }: ExamineePageProps) {
  const [info, setInfo] = useState<CompletionInfo | null>(null);
  const [records, setRecords] = useState<InterviewRecordInfo[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const FOOTER_HEIGHT = "151px";
  const PX = 4;
  const BACKGROUND_COLOR = "background.default";

  useEffect(() => {
    if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
      throw new Error("sleepCheckupId is not assigned");
    }
    if (params.remindId == null || params.remindId === undefined) {
      throw new Error("remindId is not assigned");
    }

    getSleepCheckupInfo(params.sleepCheckupId, getEncodedCurrentPath(location))
      .then((res: any) => {
        const info = res.data as SleepCheckupInfo;
        const today = new Date();

        setInfo(getCompletionInfo(parseInt(params.remindId!), info, today));
        setRecords(
          info.daily_interview_reminds.map(
            (r, i) => new InterviewRecordInfo(r, i, today)
          )
        );
      })
      .catch((err) => {
        setErrorMessage("エラーが発生しました。しばらく経ってから、再試行してください。");
      });
  }, [params.sleepCheckupId, params.remindId, location]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <ScrollToTop />
      <Box
        sx={{
          minHeight: "100vh",
          position: "relative",
          backgroundColor: BACKGROUND_COLOR,
        }}
      >
        <InterviewAppBar
          height={headerHeight}
          title="回答完了"
          onClickBack={canBack(location) ? handleBack : undefined}
        />
        <NonFieldError>{errorMessage}</NonFieldError>
        {!errorMessage && (
          <Container
            maxWidth="sm"
            sx={{
              p: 0,
              pt: 0,
              pl: 0,
              pr: 0,
              pb: FOOTER_HEIGHT,
            }}
          >
            <Box sx={{ backgroundColor: "white" }}>
              <Stack sx={{ py: 6, px: PX }} spacing={12}>
                <ProgressBar value={100} />
                {info != null && (
                  <Stack spacing={6} alignItems="center">
                    {info.firstViewInfo != null && (
                      <FirstViewImage
                        image={info.firstViewInfo.image}
                        alt={info.firstViewInfo.alt}
                      />
                    )}
                    <CompletionMessage
                      main={info.messageInfo.main}
                      sub={info.messageInfo.sub}
                    />
                  </Stack>
                )}
                {info?.buttonInfo != null && (
                  <Button
                    variant="contained"
                    size="large"
                    component={Link}
                    to={info.buttonInfo.to}
                  >
                    {info.buttonInfo.title}
                  </Button>
                )}
              </Stack>
            </Box>
            {info != null && (
              <InterviewRecord sx={{ mx: PX, mt: 8, mb: 16 }} records={records} />
            )}
          </Container>
        )}
        <Footer
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: FOOTER_HEIGHT,
          }}
        />
      </Box>
    </>
  );
}

function FirstViewImage({ image, alt }: FirstViewInfo) {
  return (
    <img
      src={`/img/${image}`}
      alt={alt}
      loading="lazy"
      style={{ width: 260, height: "auto" }}
    />
  );
}

function CompletionMessage({ main, sub }: MessageInfo) {
  return (
    <Stack spacing={4} alignItems="center">
      <Typography variant="h6">{main}</Typography>
      <Typography variant="subtitle2" align="center" color="text.secondary">
        <LineBreakText text={sub} />
      </Typography>
    </Stack>
  );
}

type InterviewRecordProps = {
  sx: CardProps["sx"];
  records: InterviewRecordInfo[];
};
function InterviewRecord({ sx, records }: InterviewRecordProps) {
  const half = records.length / 2;
  const first = records.slice(0, half);
  const second = records.slice(half, records.length);

  return (
    <Card sx={sx}>
      <CardContent sx={{ px: 8, py: 4 }}>
        <Stack spacing={4} alignItems="center">
          <Typography variant="subtitle1">毎日問診の記録</Typography>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Stack direction="row" justifyContent="space-between">
              {first.map((recordInfo, i) => (
                <Record
                  key={i}
                  date={recordInfo.getDate()}
                  variant={recordInfo.getIconVariant()}
                />
              ))}
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              {second.map((recordInfo, j) => (
                <Record
                  key={j}
                  date={recordInfo.getDate()}
                  variant={recordInfo.getIconVariant()}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

type RecordProps = {
  date: string;
  variant: IconVariant;
};
function Record({ date, variant }: RecordProps) {
  return (
    <Stack spacing={2} alignItems="center">
      <Typography
        fontFamily="Montserrat"
        fontSize="10px"
        fontWeight="700"
        color="text.disabled"
      >
        {date}
      </Typography>
      <RecordIcon variant={variant} />
    </Stack>
  );
}

const ICONS: Record<IconVariant, string> = {
  Completed: "icon_interview_complete.png",
  Incompleted: "icon_interview_incomplete.png",
  Day1: "icon_interview_day1.png",
  Day2: "icon_interview_day2.png",
  Day3: "icon_interview_day3.png",
  Day4: "icon_interview_day4.png",
  Day5: "icon_interview_day5.png",
  Day6: "icon_interview_day6.png",
  Day7: "icon_interview_day7.png",
  Day8: "icon_interview_day8.png",
};

type RecordIconProps = {
  variant: IconVariant;
};
function RecordIcon({ variant }: RecordIconProps) {
  return (
    <img
      src={`/img/${ICONS[variant]}`}
      alt={variant}
      loading="lazy"
      style={{ width: 50, height: "auto" }}
    />
  );
}
