import { Copyright } from "@/components/examinee/Copyright";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import RangeChart from "../commons/RangeChart";
import ReportDialog from "../commons/ReportDialog";
import { ReportImg } from "../commons/ReportImg";
import { useSummaryPageContext } from "./Summary";
import { TeacherComment, hasPointsOfImprovement } from "./commons";

/**
 * 睡眠の質
 *
 * @param param0
 * @returns
 */
export default function SleepQuality() {
  const context = useSummaryPageContext();
  const [openInitDialog, setOpenInitDialog] = useState(context.isFirstVisit);
  const theme = useTheme();

  function next() {
    const { points_of_improvement } = context.props.data;
    if (hasPointsOfImprovement(points_of_improvement)) {
      context.setScreenType("points_of_improvement");
    } else {
      // 改善のポイントが存在しない場合、「睡眠の質の改善ポイント」をスキップして、リズムを表示
      context.setScreenType("sleep_rhythm");
    }
  }
  return (
    <Stack
      sx={{
        p: "24px 16px 80px 16px",
        gap: "24px",
        height: "100%",
      }}
    >
      <SleepQualityDialog open={openInitDialog} setOpen={setOpenInitDialog} />
      <Stack
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          p: "16px",
          gap: "16px",
        }}
      >
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="h6">睡眠の質</Typography>
          <Stack direction="row" sx={{ marginLeft: "auto" }}>
            <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
              <ReportImg
                style={{ width: "8px", height: "8px" }}
                image_name="image_report_summary_chart_position_mark.svg"
              />
            </Box>
            <Typography variant="caption">あなたの結果</Typography>
            <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
              <ReportImg
                style={{ width: "8px", height: "8px", marginLeft: "16px" }}
                image_name="image_report_summary_chart_reference_area.svg"
              />
            </Box>
            <Typography variant="caption">推奨範囲</Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              評価項目1
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="justify"
              sx={{ color: "text.primary" }}
            >
              あなたの睡眠の効率(平均)
            </Typography>
            <Typography variant="caption" textAlign="justify">
              ※あなたが寝床にいた時間のうち実際に眠っていた時間の割合です。
            </Typography>
            <Stack sx={{ mt: "8px", mb: "24px" }}>
              <RangeChart
                max={{
                  value: 100,
                  label: "100%",
                  attr: { fill: "#00A99D" },
                }}
                min={{ value: 0, label: "0%" }}
                recommendFrom={{
                  value: 85,
                  label: "85%",
                }}
                recommendTo={{
                  value: 100,
                }}
                plot={{
                  value: context.props.data.sleep_quality.evaluation_1 ?? 0,
                  label: (() =>
                    context.props.data.sleep_quality.evaluation_1 !== null
                      ? `${context.props.data.sleep_quality.evaluation_1}%`
                      : "-")(),
                  attr: (() =>
                    context.props.data.sleep_quality.evaluation_1 === null ||
                    (85 <= context.props.data.sleep_quality.evaluation_1 &&
                      context.props.data.sleep_quality.evaluation_1 <= 100)
                      ? {}
                      : { fill: theme.palette.error.main })(),
                }}
              />
            </Stack>
          </Stack>

          <Stack>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              評価項目2
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="justify"
              sx={{ color: "text.primary" }}
            >
              寝付きが悪かった日数
            </Typography>
            <Stack sx={{ mt: "8px", mb: "24px" }}>
              <RangeChart
                min={{ value: 0, label: "0日間", attr: { fill: "#00A99D" } }}
                max={{ value: 7, label: "7日間" }}
                recommendFrom={{
                  value: 0,
                }}
                recommendTo={{
                  value: 2,
                  label: "2日間",
                }}
                plot={{
                  value: context.props.data.sleep_quality.evaluation_2,
                  label: `${context.props.data.sleep_quality.evaluation_2}日間`,
                  attr: (() =>
                    context.props.data.sleep_quality.evaluation_2 > 2
                      ? { fill: theme.palette.error.main }
                      : {})(),
                }}
              />
            </Stack>
          </Stack>

          <Stack>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              評価項目3
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="justify"
              sx={{ color: "text.primary" }}
            >
              睡眠中の覚醒が多かった日数
            </Typography>
            <Typography variant="caption" textAlign="justify">
              ※睡眠中の覚醒は、本人の自覚がない場合があります。
            </Typography>
            <Stack sx={{ mt: "8px", mb: "24px" }}>
              <RangeChart
                min={{ value: 0, label: "0日間", attr: { fill: "#00A99D" } }}
                max={{ value: 7, label: "7日間" }}
                recommendFrom={{
                  value: 0,
                }}
                recommendTo={{
                  value: 2,
                  label: "2日間",
                }}
                plot={{
                  value: context.props.data.sleep_quality.evaluation_3,
                  label: `${context.props.data.sleep_quality.evaluation_3}日間`,
                  attr: (() =>
                    context.props.data.sleep_quality.evaluation_3 > 2
                      ? { fill: theme.palette.error.main }
                      : {})(),
                }}
              />
            </Stack>
          </Stack>

          <Stack>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              評価項目4
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="justify"
              sx={{ color: "text.primary" }}
            >
              早く目が覚めてしまった日数
            </Typography>
            <Stack sx={{ mt: "8px" }}>
              <RangeChart
                min={{ value: 0, label: "0日間", attr: { fill: "#00A99D" } }}
                max={{ value: 7, label: "7日間" }}
                recommendFrom={{
                  value: 0,
                }}
                recommendTo={{
                  value: 2,
                  label: "2日間",
                }}
                plot={{
                  value: context.props.data.sleep_quality.evaluation_4,
                  label: `${context.props.data.sleep_quality.evaluation_4}日間`,
                  attr: (() =>
                    context.props.data.sleep_quality.evaluation_4 > 2
                      ? { fill: theme.palette.error.main }
                      : {})(),
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <TeacherComment>
          {context.props.data.sleep_quality.comment}
        </TeacherComment>
      </Stack>
      <Stack
        sx={{
          pt: "24px",
          pb: "10px",
          mb: "80px",
          position: "sticky",
          bottom: 0,
          zIndex: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{ boxShadow: "none" }}
          onClick={() => {
            next();
          }}
        >
          次へ
        </Button>
      </Stack>
      <Stack
        sx={{
          p: "32px 0px",
          position: "sticky",
          alignItems: "center",
        }}
      >
        <Copyright />
      </Stack>
    </Stack>
  );
}

type SleepQuantityDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

/**
 * 睡眠の質の初期表示ダイアログ
 *
 * @param param0
 * @returns
 */
function SleepQualityDialog({ open, setOpen }: SleepQuantityDialogProps) {
  return (
    <ReportDialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <Stack>
        <Stack sx={{ p: "16px" }}>
          <Stack direction="row" sx={{ gap: "8px" }}>
            <Typography variant="subtitle1" sx={{ color: "primary.dark" }}>
              睡眠の質とは
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ p: "0px 16px 16px 16px", gap: "8px" }}>
          <Typography variant="body2" textAlign="justify">
            あなたが寝床にいた時間の中で実際にどれだけ眠れていたのかを評価します。
          </Typography>
          <Typography variant="body2" textAlign="justify">
            睡眠中の覚醒は無自覚なことも多いため、デバイスなどで客観的に測ることが重要です。
          </Typography>
          <Typography variant="body2" textAlign="justify">
            布団に入っても寝付きが悪かったり、途中で目が覚めてしまうことが多い場合、「睡眠の質が悪い」と言えます。
          </Typography>
        </Stack>
        <Stack sx={{ background: "#F6F5F4", p: "16px" }}>
          <ReportImg image_name="image_report_summary_what_is_quality.svg" />
        </Stack>
        <Stack sx={{ textAlign: "center", mt: "8px" }}>
          <Typography variant="caption">
            実際の睡眠時間は同じだが、質が異なる睡眠の例
          </Typography>
        </Stack>
        <Stack sx={{ p: " 16px" }}>
          <Box textAlign="center" sx={{ pt: "16px" }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "18px",
                p: "6px 32px 6px 32px",
                boxShadow: "none",
              }}
              onClick={() => setOpen(false)}
            >
              結果を見る
            </Button>
          </Box>
        </Stack>
      </Stack>
    </ReportDialog>
  );
}
