import { Typography, TypographyProps } from "@mui/material";

interface AnnotationTypographyProps extends TypographyProps {}
export default function AnnotationTypography({
  sx,
  ...props
}: AnnotationTypographyProps) {
  return (
    <Typography
      sx={{
        fontWeight: "400",
        fontSize: "14px",
        textAlign: "justify",
        color: "#707070",
        ...sx,
      }}
      {...props}
    />
  );
}
