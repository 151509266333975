import { InterviewRecordInfo } from "@/sleep_compass_lite/components/target/InterviewRecordCard";
import { DailyInterviewInfo } from "@/sleep_compass_lite/use_cases/target/interview/UseDailyInterviewInfo";
import { InterviewInfo as PrimaryInterviewInfo } from "@/sleep_compass_lite/use_cases/target/interview/UsePrimaryInterviewInfo";
import { toStringMd } from "@/utils/date";

/**
 * InterviewRecordCardコンポーネントで表示する問診の記録配列を作成
 * @param primaryInterviewInfo 事前問診情報 {@link PrimaryInterviewInfo}
 * @param dailyInterviewInfo 毎日問診情報 {@link DailyInterviewInfo}
 * @param dailyInterviewLen 毎日問診の回答回数
 * @returns 問診の記録配列
 */
export function createInterviewRecordInfoArray(
  primaryInterviewInfo: PrimaryInterviewInfo,
  dailyInterviewInfo: DailyInterviewInfo,
  dailyInterviewLen: number
): InterviewRecordInfo[] {
  // 問診の記録配列
  const ret: InterviewRecordInfo[] = [];

  // 事前問診の記録を作成して、問診の記録配列にpushする
  const primaryInterviewRecord: InterviewRecordInfo = {
    title: "事前問診",
    completedDate:
      primaryInterviewInfo?.completedDate != null
        ? toStringMd(primaryInterviewInfo.completedDate, "/")
        : null,
  };

  ret.push(primaryInterviewRecord);

  // 以降、毎日問診の記録を作成して、問診の記録配列にpushしていく
  // 毎日問診を未完了のデータをフィルターして、毎日問診の完了日のリストを取得
  const completedDates: Date[] =
    dailyInterviewInfo?.dailyInterviews.flatMap((i) =>
      i.isCompleted && i.date != null ? i.date : []
    ) ?? [];

  // dailyInterviewLenの長さ分だけ、毎日問診の記録を作成して、問診の記録配列にpushする
  for (let i = 0; i < dailyInterviewLen; i++) {
    const dailyInterviewRecord: InterviewRecordInfo = {
      title: `${i + 1}回目問診`,
      // i+1回目の問診を完了している場合は完了日を、未完了の場合はnullをセット
      completedDate:
        i < completedDates.length ? toStringMd(completedDates[i], "/") : null,
    };

    ret.push(dailyInterviewRecord);
  }

  return ret;
}
