import { SubtitleTypography } from "@/components/common/Typographies";
import { ArrowRightAlt, ErrorOutline, Moving } from "@mui/icons-material";
import {
  Stack,
  SvgIconProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { BorderlessTableContainer } from "..";
import {
  EvaluationResult,
  ImprovementComparisonType,
} from "../commons/sleep_summaries";

interface SectionLayoutProps {
  title: string;
  caption: string;
  children?: ReactNode;
}
/**
 * ワークシートのセクション共通のレイアウト
 * @param props {@link SectionLayoutProps}
 * @returns ワークシートのセクション共通のレイアウト
 */
export function SectionLayout({
  title,
  caption,
  children,
}: SectionLayoutProps) {
  return (
    <Stack sx={{ gap: 6 }}>
      <Stack sx={{ gap: 2 }}>
        <SubtitleTypography>{title}</SubtitleTypography>
        <Typography variant="body2">{caption}</Typography>
      </Stack>
      <Stack>{children}</Stack>
    </Stack>
  );
}

interface HeadTypographyProps {
  children: ReactNode;
}
/**
 * ワークシート内のヘッダ用 {@link Typography}
 * @param props {@link HeadTypographyProps}
 * @returns ワークシート用ヘッダ
 */
export function HeadTypography({ children }: HeadTypographyProps) {
  return (
    <Typography variant="subtitle1" sx={{ color: "#3D3D3D" }}>
      {children}
    </Typography>
  );
}

interface SectionTableProps {
  standardLabel: string;
  result?: Omit<EvaluationResult, "comment"> & {
    comment?: EvaluationResult["comment"];
  };
  data?: {
    title: ReactNode;
    standard_value?: ReactNode;
    result?: string | null;
    is_warning?: boolean | null;
    last_time_result?: string | null;
  }[];
}
/**
 * 睡眠評価用テーブル
 *
 * @param props {@link SectionTableProps}
 * @returns 睡眠評価用テーブル
 */
export function SectionTable({
  standardLabel,
  result,
  data,
}: SectionTableProps) {
  return (
    <BorderlessTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="127px">
              <Typography variant="subtitle2">評価項目</Typography>
            </TableCell>
            <TableCell width="127px">
              <Typography variant="subtitle2">{standardLabel}</Typography>
            </TableCell>
            <TableCell width="127px">
              <Typography variant="subtitle2">今回測定</Typography>
            </TableCell>
            <TableCell width="127px">
              <Typography variant="subtitle2">前回測定</Typography>
            </TableCell>
            <TableCell width="127px">
              <Typography variant="subtitle2">今回判定</Typography>
            </TableCell>
            <TableCell width="127px">
              <Typography variant="subtitle2">前回判定</Typography>
            </TableCell>
            {result?.comment !== undefined && (
              <TableCell>
                <Typography variant="subtitle2">コメント</Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((d, i) => (
            <TableRow key={i}>
              <TableData>{d.title}</TableData>
              <TableData>{d.standard_value}</TableData>
              <TableData
                textColor={d.is_warning ? "error.main" : "text.primary"}
                sx={{ backgroundColor: "rgb(0 86 160 / .04)" }}
              >
                {d.result}
              </TableData>
              <TableData textColor="text.disabled">
                {d.last_time_result}
              </TableData>
              {i === 0 && (
                <>
                  <TableData
                    rowSpan={data.length}
                    sx={{ backgroundColor: "rgb(0 86 160 / .04)" }}
                  >
                    {result?.result}
                  </TableData>
                  <TableData rowSpan={data.length} textColor="text.disabled">
                    {result?.last_time_result}
                  </TableData>
                  {result?.comment !== undefined && (
                    <TableData
                      rowSpan={data.length}
                      sx={{ borderLeft: "1px dashed #E0E0E0" }}
                    >
                      {result?.comment}
                    </TableData>
                  )}
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </BorderlessTableContainer>
  );
}
interface TableDataProps extends TableCellProps {
  textColor?: string;
}
function TableData({ textColor, children, ...props }: TableDataProps) {
  return (
    <TableCell {...props}>
      <Typography color={textColor} variant="body2">
        {children ?? "-"}
      </Typography>
    </TableCell>
  );
}

interface SectionProps {
  title: string;
  help?: ReactNode;
  result?: Omit<EvaluationResult, "comment"> & {
    comment?: EvaluationResult["comment"];
  };
  data?: {
    title: ReactNode;
    standard_value?: ReactNode;
    result?: string | null;
    is_warning?: boolean | null;
    last_time_result?: string | null;
  }[];
  standardLabel: string;
  captions?: string[];
}
/**
 * 睡眠評価共通レイアウト
 *
 * @param props {@link SectionProps}
 * @returns 睡眠評価共通レイアウト
 */
export function Section({
  title,
  help,
  result,
  data,
  standardLabel,
  captions,
}: SectionProps) {
  return (
    <Stack sx={{ gap: 2 }}>
      <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}>
        <HeadTypography>{title}</HeadTypography>
        {help}
      </Stack>
      <SectionTable standardLabel={standardLabel} result={result} data={data} />
      {captions != null && (
        <Stack>
          {captions.map((c, i) => (
            <Typography key={i} variant="caption" color="#707070">
              {c}
            </Typography>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

/**
 * インフォメーションアイコン
 * @param props {@link SvgIconProps}
 * @returns インフォメーションアイコン
 */
export function InformationOutline({ sx, ...props }: SvgIconProps) {
  return (
    <ErrorOutline sx={{ transform: "rotateX(180deg)", ...sx }} {...props} />
  );
}

/**
 * 右上がりアイコン
 * @param props {@link SvgIconProps}
 * @returns 右上がりアイコン
 */
export function MovingUp({ sx, ...props }: SvgIconProps) {
  return <Moving sx={{ color: "#0056A0", ...sx }} {...props} />;
}
/**
 * 右下がりアイコン
 *
 * @param props {@link SvgIconProps}
 * @returns 右下がりアイコン
 */
export function MovingDown({ sx, ...props }: SvgIconProps) {
  return (
    <Moving
      sx={{ transform: "rotateX(180deg)", color: "#FF3A30", ...sx }}
      {...props}
    />
  );
}

/**
 * 横這いアイコン
 * @param props {@Link SvgIconProps}
 * @returns 横這いアイコン
 */
export function MovingSideway({ sx, ...props }: SvgIconProps) {
  return <ArrowRightAlt sx={{ color: "#A3A3A3" }} {...props} />;
}

type MoveImprovementProps = SvgIconProps & {
  improvement: ImprovementComparisonType | null | undefined;
};

/**
 * {@link MoveImprovementProps#improvement} からアイコンを判定します。
 *
 * @param props {@link MoveImprovementProps}
 * @returns 判定後アイコン
 */
export function MoveImprovement({
  improvement,
  ...props
}: MoveImprovementProps) {
  if (improvement == null) return <></>;
  const icon: ReactNode = {
    greater_than: <MovingUp {...props} />,
    less_than: <MovingDown {...props} />,
    equal: <MovingSideway {...props} />,
  }[improvement];
  return icon;
}
