import { TableRow } from "@/components/report/disease_risk/medical_facility_report/commons";
import { Stack, Table, TableBody, TableCell, Typography } from "@mui/material";
import { BorderlessTableContainer } from "../..";
import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function CaffeineHelpDialog() {
  return (
    <HelpButton dialogTitle="カフェイン">
      <Stack sx={{ gap: 6 }}>
        <SimpleParagraphs
          paragraphs={[
            {
              title: "睡眠への影響",
              content:
                "カフェインには覚醒作用があり、スムーズな入眠を妨げます。利尿作用もあるため、途中でトイレに起きたりすることにつながります。",
            },
            {
              title: "おすすめ",
              content:
                "個人差がありますがカフェインの半減期は3～5時間程度のため、夜はカフェインが含まれていない飲料をとるようにしましょう。",
            },
          ]}
        />
        <BorderlessTableContainer>
          <Table>
            <TableBody>
              <TableRow // FIXME: 本当はTableHeadに入れたいが、背景色が変わってしまう
                sx={{
                  backgroundColor: "#4F88BA", // FIXME: 本当は #4D89BD にしたいが、背景色が変わってしまう
                }}
              >
                <TableCell
                  align="center"
                  sx={{ borderRight: "1px solid #0000001F", pt: 3, pb: 3 }}
                >
                  <Typography
                    variant="caption"
                    sx={{ color: "#FFFFFF", fontSize: "12px" }}
                  >
                    カフェインあり
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ pt: 3, pb: 3 }}>
                  <Typography
                    variant="caption"
                    sx={{ color: "#FFFFFF", fontSize: "12px" }}
                  >
                    カフェインが少ない/含まれない
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#0056A00A" }}>
                <TableCell sx={{ borderRight: "1px solid #D7DABC" }}>
                  <Typography variant="body2">
                    コーヒー、ココア、緑茶、紅茶、煎茶、烏龍茶、ほうじ茶、玄米茶、ジャスミン茶、エナジードリンク、コーラ、眠気覚まし飲料、チョコレート
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    麦茶、昆布茶、ごぼう茶、そば茶、はと麦茶、コーン茶、黒豆茶、たんぽぽ茶、デカフェ飲料、ルイボスティー、水、炭酸水、牛乳
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </BorderlessTableContainer>
      </Stack>
    </HelpButton>
  );
}
