import { ScreenLockProvider } from "@/components/ScreenLock";
import { ExamineeFooter as Footer } from "@/components/examinee/ExamineeFooter";
import { SCAppBar as ExamineeAppBar } from "@/components/examinee/SCAppBar";
import { paths } from "@/components/examinee/interview/Path";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import { Confirmation } from "@/pages/examinee/activation/Confirmation";
import { DeviceIdInput } from "@/pages/examinee/activation/DeviceIdInput";
import { DeviceIdScanIntroduction } from "@/pages/examinee/activation/DeviceIdScanIntroduction";
import { Greetings } from "@/pages/examinee/activation/Greetings";
import { SignUp } from "@/pages/examinee/activation/SignUp";
import { DailyInterview } from "@/pages/examinee/daily_interview/DailyInterview";
import { DailyInterviewCompletion } from "@/pages/examinee/daily_interview_completion/DailyInterviewCompletion";
import { DailyInterviewEntrance } from "@/pages/examinee/daily_interview_entrance/DailyInterviewEntrance";
import { DailyInterviewRemind } from "@/pages/examinee/daily_interview_remind/DailyInterviewRemind";
import { PrimaryInterview } from "@/pages/examinee/primary_interview/PrimaryInterview";
import { PrimaryInterviewEntryLoader } from "@/pages/examinee/primary_interview/PrimaryInterviewEntryLoader";
import { PrimaryInterviewCompletion } from "@/pages/examinee/primary_interview_completion/PrimaryInterviewCompletion";
import { PrimaryInterviewEntrance } from "@/pages/examinee/primary_interview_entrance/PrimaryInterviewEntrance";
import { PrimaryInterviewIntroduction } from "@/pages/examinee/primary_interview_introduction/PrimaryInterviewIntroduction";
import { ServicePositioning } from "@/pages/examinee/service_positioning/ServicePositioning";
import { TermsOfServiceAgreement } from "@/pages/examinee/terms_of_service_agreement_v1/TermsOfServiceAgreement";
import { examineeTheme } from "@/theme/themeV1";
import { Box, Container, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ja from "date-fns/locale/ja";
import { Route, Routes } from "react-router-dom";
import ReportRouterConfig from "../common/report/ReportRouterConfig";
import { SummaryPage } from "../common/report/SummaryPage";
import { ForgotPassword } from "./activation/ForgotPassword";
import { RegistrationCompleted } from "./activation/RegistrationCompleted";
import { SetNewPassword } from "./activation/SetNewPassword";
import { SignIn } from "./activation/SignIn";
import { SignInForSecond } from "./activation/SignInForSecond";
import { DailyInterviewEntryLoader } from "./daily_interview/DailyInterviewEntryLoader";

export default function ExamineeLayoutV1() {
  const HEADER_HEIGHT = 56;
  return (
    <ThemeProvider theme={examineeTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <Routes>
          <Route
            path={paths.getPath("PrimaryInterviewEntryLoader")}
            element={<PrimaryInterviewEntryLoader />}
          />
          <Route
            path={paths.getPath("PrimaryInterview")}
            element={<PrimaryInterview headerHeight={HEADER_HEIGHT} />}
          />
          <Route
            path={paths.getPath("PrimaryInterviewIntroduction")}
            element={
              <PrimaryInterviewIntroduction headerHeight={HEADER_HEIGHT} />
            }
          />
          <Route
            path={paths.getPath("PrimaryInterviewCompletion")}
            element={
              <PrimaryInterviewCompletion headerHeight={HEADER_HEIGHT} />
            }
          />
          <Route
            path={paths.getPath("DailyInterviewRemind")}
            element={<DailyInterviewRemind headerHeight={HEADER_HEIGHT} />}
          />
          <Route
            path={paths.getPath("DailyInterviewLoader")}
            element={<DailyInterviewEntryLoader />}
          />
          <Route
            path={paths.getPath("DailyInterview")}
            element={<DailyInterview headerHeight={HEADER_HEIGHT} />}
          />
          <Route
            path={paths.getPath("DailyInterviewCompletion")}
            element={<DailyInterviewCompletion headerHeight={HEADER_HEIGHT} />}
          />
          <Route
            path="/*"
            element={<StandardExamineeLayout headerHeight={HEADER_HEIGHT} />}
          />
          <Route
            path="/report/:uuid/summary/*"
            element={<SummaryPageLayout />}
          />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function SummaryPageLayout() {
  return (
    <Container maxWidth="sm" sx={{ margin: "auto", p: "0px" }}>
      <Routes>
        <Route path="/" element={<SummaryPage />} />
      </Routes>
    </Container>
  );
}

function StandardExamineeLayout({ headerHeight }: ExamineePageProps) {
  const FOOTER_HEIGHT = "218px";

  return (
    <Box sx={{ minHeight: "100vh", position: "relative" }}>
      <ExamineeAppBar height={headerHeight} />
      {/* Note: Footerを画面最下部に設置するために { minHeight: "100vh", position: "relative" } が必要 */}
      <Container maxWidth="sm" sx={{ pt: 0, pl: 0, pr: 0, pb: FOOTER_HEIGHT }}>
        <Routes>
          <Route path="/greetings" element={<Greetings />} />

          <Route path="/report/*" element={<ReportRouterConfig />} />
          {/* 初めて睡眠健診を受診する人用のサインアップページ */}
          <Route
            path="/signup"
            element={
              // TODO 落ち着いたら上のコンポーネントで囲むように修正する。
              <ScreenLockProvider>
                <SignUp />
              </ScreenLockProvider>
            }
          />
          {/* ログアウトした人用のサインインページ */}
          <Route path="/signin" element={<SignIn />} />
          {/* 2回目以降の受診の人用のサインインページ */}
          <Route path="/signin2" element={<SignInForSecond />} />

          {/* パスワード忘れ */}
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/set_new_password" element={<SetNewPassword />} />

          <Route
            path="/terms_of_service_agreement"
            element={<TermsOfServiceAgreement />}
          />
          <Route
            path="/terms_of_service"
            element={<TermsOfServiceAgreement hideAgreeButton />}
          />
          <Route
            path="/device_id_scan_introduction/:sleepCheckupId"
            element={<DeviceIdScanIntroduction />}
          />
          <Route path="/device_id_input" element={<DeviceIdInput />} />
          <Route
            path="/confirmation"
            element={
              // QRコード読み取りの検証のため、API呼び出しが増えて処理時間が長くなったので、画面ロックを使う
              <ScreenLockProvider>
                <Confirmation />
              </ScreenLockProvider>
            }
          />
          <Route
            path="/registration_completed"
            element={<RegistrationCompleted />}
          />
          <Route path="/service_positioning" element={<ServicePositioning />} />
          <Route
            path={paths.getPath("PrimaryInterviewEntrance")}
            element={<PrimaryInterviewEntrance />}
          />
          <Route
            path={paths.getPath("DailyInterviewEntrance")}
            element={<DailyInterviewEntrance />}
          />
        </Routes>
      </Container>
      {/* Note: Footerを画面最下部に設置するために { position: "absolute", bottom: 0 } が必要 */}
      <Footer
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: FOOTER_HEIGHT,
        }}
      />
    </Box>
  );
}
