import { Typography, TypographyProps } from "@mui/material";

export type TagProps = TypographyProps;

/**
 * タグを表現するコンポーネントです。
 * {@link Typography} をベースとしており、デフォルトのプロパティを持っています。
 * variant: "subtitle2"
 * sx: { p: "0 16px", borderRadius: "24px" }
 * @param param0
 * @returns
 */
export function Tag({ sx, ...props }: TagProps) {
  const _sx = {
    ...{ p: "0 16px", borderRadius: "24px" },
    ...sx,
  };
  return <Typography variant="subtitle2" sx={_sx} {...props}></Typography>;
}
