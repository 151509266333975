import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import HelpButton from "../HelpButton";
import CriterionTableContainer, {
  Cells,
} from "./criterions/CriterionTableContainer";

export default function DiseaseRiskCriterionDialog() {
  return (
    <HelpButton
      dialogTitle="疾患リスクの判定基準"
      dialogProps={{
        maxWidth: "lg",
      }}
    >
      <CriterionTableContainer
        sx={{
          "& td.MuiTableCell-root:nth-child(2)": {
            backgroundColor: Cells.color.good,
          },
          "& td.MuiTableCell-root:nth-child(3)": {
            backgroundColor: Cells.color.bad,
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="subtitle2">判定の仕組み</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ boxSizing: "border-box", width: "200px" }}>
                <Typography variant="subtitle2">睡眠時無呼吸症候群</Typography>
                <Typography variant="caption">
                  リスクあり/リスク低/未判定
                </Typography>
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{ boxSizing: "border-box", width: "232px" }}
              >
                <Typography variant="body2">
                  2項目とも「リスク低」の場合
                </Typography>
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{ boxSizing: "border-box", width: "232px" }}
              >
                <Typography variant="body2">
                  1項目以上が「リスクあり」の場合
                </Typography>
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{ boxSizing: "border-box", width: "232px" }}
              >
                <Typography variant="body2">
                  リスクありの項目が存在せず、
                  <br />
                  1項目以上が「未判定」の場合
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">不眠症</Typography>
                <Typography variant="caption">
                  リスクあり/リスク低/未判定
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">判定</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">リスク低</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">リスクあり</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">未判定</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CriterionTableContainer>
    </HelpButton>
  );
}
