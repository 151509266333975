import { AxiosResponse } from "axios";

/**
 * APIのエラーレスポンスのためのドメインモデル
 * エラーレスポンス(AxiosResponse)を操作する処理をまとめる
 */
export class ApiError extends Error {
  readonly response?: AxiosResponse;

  constructor(response?: AxiosResponse) {
    super();
    this.response = response;
  }

  /**
   * APIレスポンスのエラーメッセージ
   */
  get message(): string {
    if (
      this.response?.data?.error_messages == null ||
      this.response?.data?.error_messages.length === 0
    ) {
      return this.response?.data?.detail ?? "";
    }
    return this.response.data.error_messages[0];
  }

  /**
   * エラーがクライアントエラーかどうかを表す真偽値
   * クライアントエラーは、HTTPステータスコードの400系のエラーのこと
   */
  get isClientError(): boolean {
    if (this.response == null) {
      return false;
    }
    return 400 <= this.response.status && this.response.status < 500;
  }
}
