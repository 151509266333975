import {
  Question,
  QuestionAnswer,
  Screen as QuizScreen,
} from "@/components/examinee/interview/Types";

export class QuizAnswer {
  readonly entryId: number;
  readonly screen: QuizScreen;
  readonly questionAnswer: QuestionAnswer;

  constructor(
    entryId: number,
    screen: QuizScreen,
    questionAnswer?: QuestionAnswer
  ) {
    this.entryId = entryId;
    this.screen = screen;
    this.questionAnswer =
      questionAnswer ??
      this.createQuestionAnswer(screen.question_group.questions[0]);
  }

  getInterviewAnswer = () => {
    return {
      id: this.entryId,
      screen: {
        id: this.screen.id,
        question_group: {
          id: this.screen.question_group.id,
          questions: [this.questionAnswer],
        },
      },
    };
  };

  private createQuestionAnswer = (question: Question) => {
    return {
      id: question.id,
      question_code: question.question_code,
      answers: [],
    };
  };
}
