import { CheckCircle, Circle } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

type PasswordCheckListProps = {
  /** 表示テキスト */
  text: string;
  /** コンポーネントの見た目を切り替えるためのスイッチ */
  checked: boolean;
};
/**
 * パスワードのチェック項目を表示するためのコンポーネント。チェック項目の状態によって、コンポーネントの見た目(color, icon)を切り替えることができる。
 */
export function PasswordCheckListItem({
  text,
  checked,
}: PasswordCheckListProps) {
  const color = checked ? "text.disabled" : "primary.main";
  const icon = checked ? (
    <CheckCircle fontSize="inherit" />
  ) : (
    <Circle fontSize="inherit" />
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={"9px"}
      sx={{ color: color }}
    >
      {icon}
      <Typography variant="caption" sx={{ color: color }}>
        {text}
      </Typography>
    </Stack>
  );
}
