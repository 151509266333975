import { Button, Divider, Link, Stack, Typography } from "@mui/material";
import { useState } from "react";
import ReportDialog from "../commons/ReportDialog";

/**
 * 睡眠時無呼吸症候群/不眠症とはのポップアップを表示するボタン
 */
export default function WhatIsSASAndInsomniaButton() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "#3D3D3D",
            fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
            borderBottom: "1px solid #3D3D3D",
          }}
        >
          睡眠時無呼吸症候群/不眠症とは
        </Typography>
      </Button>
      <ReportDialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <Stack>
          <Stack sx={{ alignItems: "center" }}>
            <Typography
              variant="h6"
              sx={{
                color: "primary.dark",
                textAlign: "center",
                fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
                p: "16px",
              }}
            >
              睡眠時無呼吸症候群/不眠症とは
            </Typography>
            <Stack sx={{ gap: "24px", p: "0px 16px 24px 16px" }}>
              <Describe
                title="睡眠時無呼吸症候群"
                detail="睡眠時無呼吸症候群は、眠っている間に呼吸が止まってしまう病気です。呼吸をするために夜間何度も覚醒してしまい、十分な睡眠がとれなくなります。"
                referenceLink="https://www.e-healthnet.mhlw.go.jp/information/dictionary/heart/yk-026.html"
                referenceTitle="厚生労働省e-ヘルスネット（睡眠時無呼吸症候群 / SAS）"
              />
              <Describe
                title="不眠症"
                detail="不眠症は、眠りたいのに寝付きが悪い・何度も目が覚める・眠りが浅いなどの睡眠問題があり、結果として日中に不調が発生してしまう病気です。"
                referenceLink="https://www.e-healthnet.mhlw.go.jp/information/dictionary/heart/yk-083.html"
                referenceTitle="厚生労働省e-ヘルスネット（不眠症）"
              />
            </Stack>
          </Stack>
          <Divider />
          <Stack sx={{ p: "16px" }}>
            <Button
              variant="text"
              sx={{ color: "primary.dark" }}
              onClick={() => {
                setOpen(false);
              }}
            >
              閉じる
            </Button>
          </Stack>
        </Stack>
      </ReportDialog>
    </>
  );
}

/** {@link Describe} のプロパティ */
interface DescribeProps {
  /** タイトル */
  title: string;
  /** 詳細 */
  detail: string;
  /** 参照リンク */
  referenceLink: string;
  /** 参照名称 */
  referenceTitle: string;
}
/**
 * 説明
 * @param props {@link DescribeProps}
 * @returns 説明
 */
function Describe({
  title,
  detail,
  referenceLink,
  referenceTitle,
}: DescribeProps) {
  return (
    <Stack sx={{ gap: "4px" }}>
      <Typography
        variant="subtitle2"
        sx={{
          color: "text.primary",
          textAlign: "justify",
          fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: "text.primary",
          textAlign: "justify",
          fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
        }}
      >
        {detail}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          color: "text.secondary",
          textAlign: "justify",
          fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
        }}
      >
        参考：
        <Link
          href={referenceLink}
          color="inherit"
          target="_blank"
          sx={{
            color: "text.secondary",
          }}
        >
          {referenceTitle}
        </Link>
      </Typography>
    </Stack>
  );
}
