import {
  EMAIL_ADDRESS_REGEX,
  PASSWORD_SYMBOL_REGEX,
} from "@/components/activation/Constants";
import { NonFieldError, SuccessAlert } from "@/components/common/SCAlert";
import { SCLink } from "@/components/common/SCLink";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { paths } from "@/components/examinee/interview/Path";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { isTestTargetFacility } from "@/utils/abtest";
import {
  CognitoAuthenticationResultStore,
  CognitoRefreshTokenStore,
  SleepCheckupUserStore,
  callUserTypeApi,
} from "@/utils/auth";
import { getApiServerUrl, getAxios } from "@/utils/axios";
import { validateBirthday } from "@/utils/date";
import { Button, Stack, Typography } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  successMessage: string;
};

export const SignInForSecond = () => {
  const API_URL = getApiServerUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { successMessage } = (location.state as StateType) || {};

  const validators: Record<string, () => string[] | null> = {
    // validatorは引数を受け取らず実行され、エラーが無ければ何もreturnしない。
    // エラーがあった場合はエラーメッセージの配列を返す。
    confirmationCode: () => {
      if (!confirmationCode.match(/^\d{6}$/)) {
        return ["※半角数字6桁で入力してください"];
      }
      return null;
    },
    birthday: () => validateBirthday(birthday),
    email: () => {
      if (!email.match(EMAIL_ADDRESS_REGEX)) {
        return ["※メールアドレスの形式が正しくありません"];
      }
      return null;
    },
    passwordNotEmpty: () => {
      if (!password) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    password: () => {
      if (
        password.replace(PASSWORD_SYMBOL_REGEX, "").replace(/[a-zA-Z\d]/g, "")
      ) {
        return ["※使用できない文字列が含まれています"];
      }
      return null;
    },
  };

  const handleClickLogin = () => {
    if (Object.values(validators).some((v) => v())) {
      // 何かしらのエラーがある場合はログイン処理を行わない
      return;
    }
    const birthdayWithHyphen = birthday.replace(
      /(\d{4})(\d{2})(\d{2})/,
      "$1-$2-$3"
    );
    axios
      .post(`${API_URL}/api/examination_entry/`, {
        email: email,
        password: password,
        birthday: birthdayWithHyphen,
        confirmation_code: confirmationCode,
      })
      .then((res: AxiosResponse) => {
        const sleepCheckupUuid: string = res.data.sleep_checkup;
        const medicalFacilityId: string = res.data.medical_facility;
        CognitoAuthenticationResultStore.setItem(res.data.AuthenticationResult);
        CognitoRefreshTokenStore.setItem({
          RefreshToken: res.data.AuthenticationResult.RefreshToken,
        });
        callUserTypeApi(
          getAxios(API_URL),
          res.data.AuthenticationResult.IdToken
        ).then((res: AxiosResponse) => {
          SleepCheckupUserStore.setItem(res.data);
          if (isTestTargetFacility(Number(medicalFacilityId))) {
            navigate(
              `/examinee/device_id_scan_introduction/${sleepCheckupUuid}`
            );
          } else {
            const nextPath = paths.getFullPath("PrimaryInterviewIntroduction", {
              ":sleepCheckupId": sleepCheckupUuid,
            });
            navigate(nextPath);
          }
        });
      })
      .catch((err) => {
        const statusCode = err.response?.status;
        const messages = err.response?.data?.error_messages;
        if (
          statusCode &&
          400 <= statusCode &&
          statusCode < 500 &&
          messages &&
          messages.length > 0
        ) {
          // 400系エラーでエラーメッセージが存在する場合はそれを表示する
          setErrorMessage(messages[0]);
        } else {
          setErrorMessage(
            "エラーが発生しました。申し訳ありませんが、少し時間をおいてから再度お試しください。"
          );
        }

        // エラーアラートが画面内に表示されるようにスクロールをリセットする
        window.scrollTo(0, 0);
      });
  };

  return (
    <>
      <ScrollToTop />
      <Stack spacing={8} sx={{ mt: 8, mb: 12, mx: 4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            ログイン
          </Typography>

          {/* パスワード再設定直後に表示されるメッセージ */}
          <SuccessAlert>{successMessage}</SuccessAlert>

          {/* エラーメッセージ */}
          <NonFieldError>{errorMessage}</NonFieldError>

          <Typography
            variant="subtitle2"
            textAlign="justify"
            sx={{ color: "text.secondary" }}
          >
            以下のフォームに本人確認用の受付番号、生年月日、前回（もしくはSLEEP
            COMPASS
            Lightにて）設定したメールアドレス、パスワードを入力し、[ログイン]ボタンを押してください。
          </Typography>

          <TextForm
            title="受付番号"
            placeholder="数字6桁"
            value={confirmationCode}
            onChange={(e) => {
              setConfirmationCode(e.target.value);
            }}
            validator={validators.confirmationCode}
            helperText="※実施機関から届いた「アカウント登録のご案内」に記載のある数字6桁をご記入ください"
          />
          <TextForm
            title="生年月日"
            placeholder="例：20230301"
            value={birthday}
            onChange={(e) => {
              setBirthday(e.target.value);
            }}
            validator={validators.birthday}
          />
          <TextForm
            title="メールアドレス"
            placeholder="例：sleep-compass@example.com"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            validator={validators.email}
          />
          <TextForm
            title="パスワード"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type="password"
            givenErrors={validators.password()}
          />
        </Stack>
      </Stack>
      <Stack width="100%" sx={{ mb: 16 }}>
        <Button
          variant="contained"
          size="large"
          sx={{ mx: 4, mb: 4 }}
          onClick={handleClickLogin}
          disabled={Object.values(validators).some((v) => v())}
        >
          ログイン
        </Button>
        <SCLink
          variant="body2"
          to="/examinee/forgot_password"
          sx={{ color: "text.secondary" }}
          state={{ isSecondUser: true }}
        >
          パスワードを忘れた場合
        </SCLink>
      </Stack>
    </>
  );
};
