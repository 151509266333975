import { CHOICE_TYPE_LABEL_WITH_FREE_TEXT } from "@/components/examinee/interview/Constants";
import { Answer, Choice } from "@/components/examinee/interview/Types";

export function getFreeTextAnswer(
  choices: Choice[],
  answers: Answer[]
): Answer | undefined {
  const freeTextChoice = choices.find(
    (c) => c.choice_type === CHOICE_TYPE_LABEL_WITH_FREE_TEXT
  );
  if (freeTextChoice === undefined) {
    return undefined;
  }

  return answers.find((a) => a.id === freeTextChoice.id);
}

export function isChecked(choice: Choice, answers: Answer[]): boolean {
  return answers.find((a: Answer) => a.id === choice.id) !== undefined;
}
