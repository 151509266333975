import {
  Breadcrumbs,
  Link,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import SubtitleTypography from "./commons/SubtitleTypography";
import {
  LifestyleAndHealthCondition,
  LifestyleAndHealthConditionsResponse,
  UserType,
} from "./types";

import { Fragment } from "react";
import LifestyleAndHealthConditionCard from "./commons/LifestyleAndHealthConditionCard";

type SectionStackProps = StackProps & {
  title: string;
  description: string;
  uuid: string | undefined;
  reportType: UserType;
  data: LifestyleAndHealthCondition[];
};
function SectionStack({
  key,
  title,
  description,
  uuid,
  reportType,
  data,
  sx,
  ...props
}: SectionStackProps) {
  return (
    <Stack sx={{ p: "40px 16px 80px 16px", ...sx }} {...props}>
      <Stack sx={{ display: "flex", gap: "16px", mb: "16px" }}>
        <SubtitleTypography>{title}</SubtitleTypography>
        <Typography variant="body1">{description}</Typography>
      </Stack>
      <Stack sx={{ gap: "24px" }}>
        {data.map((d, i) => {
          return (
            <LifestyleAndHealthConditionCard
              key={i}
              type={d.type}
              comment={d.comment}
              link={`/${reportType}/report/${uuid}/lifestyle_and_health_conditions/${d.type}`}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}

export function LifestyleAndHealthConditions({
  uuid,
  reportType,
  data,
}: {
  uuid: string | undefined;
  reportType: UserType;
  data: LifestyleAndHealthConditionsResponse;
}) {
  const results: JSX.Element[] = [];
  function addResult({
    title,
    description,
    data,
  }: {
    title: string;
    description: string;
    data: LifestyleAndHealthCondition[];
  }) {
    if (data.length > 0) {
      results.push(
        <SectionStack
          title={title}
          description={description}
          data={data}
          uuid={uuid}
          reportType={reportType}
          sx={{
            backgroundColor:
              results.length % 2 === 0 ? "background.default" : "#FFFFFF",
          }}
        />
      );
    }
  }
  addResult({
    title: "改善をおすすめ",
    description: data.bad_result.comment,
    data: data.bad_result.items,
  });
  addResult({
    title: "その調子",
    description: data.good_result.comment,
    data: data.good_result.items,
  });
  addResult({
    title: "その他",
    description: data.other_result.comment,
    data: data.other_result.items,
  });
  return (
    <>
      <Stack sx={{ p: "40px 16px 24px 16px", display: "flex", gap: "8px" }}>
        <Breadcrumbs maxItems={2} separator="›" aria-label="breadcrumb">
          <Link
            underline="hover"
            variant="body1"
            sx={{ color: "text.secondary" }}
            href={`/${reportType}/report/${uuid}`}
          >
            レポートTOP
          </Link>
          <Link
            underline="hover"
            variant="body1"
            sx={{ color: "text.secondary" }}
            href={`/${reportType}/report/${uuid}/quality`}
          >
            睡眠の質
          </Link>
          <Typography variant="body1">生活習慣と健康状態</Typography>
        </Breadcrumbs>
        <Typography variant="h5" color="primary.main">
          生活習慣と健康状態
        </Typography>
      </Stack>
      <Stack sx={{ p: "0px 16px 40px 16px" }}>
        <Typography variant="subtitle2">
          睡眠の質を決めるのは日々の生活習慣や健康状態です。あなたの睡眠に影響を与えている可能性が高い生活習慣を確認しましょう。
        </Typography>
      </Stack>
      {results.map((r, i) => (
        <Fragment key={i}>{r}</Fragment>
      ))}
    </>
  );
}
