import {
  BorderlessTableContainer,
  BorderlessTableContainerProps,
} from "@/components/worksheet";

export const Cells = {
  color: {
    very_good: "#e0eaf3",
    good: "#ebf2f8",
    caution: "#fff6e9",
    bad: "#fff0ef",
    very_bad: "#ffe7e6",
  },
} as const;

export interface CriterionTableContainerProps
  extends BorderlessTableContainerProps {}
export default function CriterionTableContainer({
  sx,
  ...props
}: CriterionTableContainerProps) {
  return (
    <BorderlessTableContainer
      sx={{
        borderRadius: "0",
        mb: 2,
        "& table tbody tr:last-child>td": { borderTop: " 1px solid #808080" },
        ...sx,
      }}
      {...props}
    ></BorderlessTableContainer>
  );
}
