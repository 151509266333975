export type Pages = RootPages | SleepCheckupSubPages;
export type RootPages = "SleepCheckupList" | "UserList";
export type SleepCheckupSubPages =
  | "SleepCheckupDetail"
  | "PrimaryInterviewResult"
  | "DailyInterviewResult"
  | "SleepCheckupGuidance"
  | "GuidancePrimaryInterviewResult"
  | "GuidanceDailyInterviewResult";

class Path {
  readonly parent: string;
  readonly table: Record<Pages, string>;

  constructor(parent: string, table: Record<Pages, string>) {
    this.parent = parent;
    this.table = table;
  }

  getPath(key: Pages, param?: string): string {
    const path = this.table[key];
    switch (key) {
      case "SleepCheckupDetail":
      case "PrimaryInterviewResult":
      case "DailyInterviewResult":
      case "SleepCheckupGuidance":
      case "GuidancePrimaryInterviewResult":
      case "GuidanceDailyInterviewResult":
        if (param != null) {
          return path.replace(":sleepCheckupId", param);
        } else {
          return path;
        }
      default:
        return path;
    }
  }

  getFullPath(key: Pages, param?: string): string {
    return `${this.parent}${this.getPath(key, param)}`;
  }

  getRootPageFull(path: string): RootPages {
    const page = this.getPageFull(path);
    switch (page) {
      case "SleepCheckupList":
      case "SleepCheckupDetail":
      case "PrimaryInterviewResult":
      case "DailyInterviewResult":
      case "SleepCheckupGuidance":
      case "GuidancePrimaryInterviewResult":
      case "GuidanceDailyInterviewResult":
        return "SleepCheckupList";
      case "UserList":
        return "UserList";
    }
  }

  private getPageFull(path: string): Pages {
    return this.getPage(path.replace(this.parent, ""));
  }

  private getPage(path: string): Pages {
    for (const [page, url] of Object.entries(this.table)) {
      switch (page) {
        case "SleepCheckupDetail":
        case "PrimaryInterviewResult":
        case "DailyInterviewResult":
        case "SleepCheckupGuidance":
        case "GuidancePrimaryInterviewResult":
        case "GuidanceDailyInterviewResult":
          const regex = new RegExp(url.replace(":sleepCheckupId", "[^/]+"));
          if (regex.test(path)) {
            return page;
          }
          break;
        default:
          if (path === url) {
            return page as Pages;
          }
          break;
      }
    }

    throw new Error(`path (${path}) is not found`);
  }
}

/**
 * 施設ユーザー用のページ(/facility配下のページ)のパスを保持するオブジェクト
 */
export const paths = new Path("/facility", {
  SleepCheckupList: "/sleep_checkup",
  UserList: "/user",
  SleepCheckupDetail: "/sleep_checkup/detail/:sleepCheckupId",
  PrimaryInterviewResult:
    "/sleep_checkup/detail/:sleepCheckupId/primary_interview_result",
  DailyInterviewResult:
    "/sleep_checkup/detail/:sleepCheckupId/daily_interview_result",

  SleepCheckupGuidance: "/sleep_checkup/guidance/:sleepCheckupId",
  GuidancePrimaryInterviewResult:
    "/sleep_checkup/guidance/:sleepCheckupId/primary_interview_result",
  GuidanceDailyInterviewResult:
    "/sleep_checkup/guidance/:sleepCheckupId/daily_interview_result",
});

/**
 * 施設ユーザー用のページ(/facility配下のページ)のページタイトル
 */
export const PAGE_TITLE: Record<Pages, string> = {
  SleepCheckupList: "睡眠健康測定一覧",
  UserList: "施設ユーザー一覧",
  SleepCheckupDetail: "測定詳細",
  PrimaryInterviewResult: "事前問診",
  DailyInterviewResult: "毎日問診",
  SleepCheckupGuidance: "睡眠指導",
  GuidancePrimaryInterviewResult: "事前問診",
  GuidanceDailyInterviewResult: "毎日問診",
};
