import {
  CUSTOM_USER_FIELDS,
  CustomUserFields,
  PRIVILEGE_LABELS as PRIVILEGE_LABELS_CUSTOM_USER,
  clone as cloneCustomUser,
  create as createCustomUser,
  fullName as fullNameCustomUser,
  getPrivilege as getPrivilegeCustomUser,
  privilege as privilegeCustomUser,
  set as setCustomUser,
} from "@/components/sleep_checkup_v1/CustomUserService";
import {
  CustomUser,
  MedicalFacilityUser,
} from "@/components/sleep_checkup_v1/MedicalFacilityUser";
import { MedicalFacility } from "@/components/sleep_checkup_v1/Types";

/**
 * 初期化された新しいMedicalFacilityUserオブジェクトを返す
 * @param medicalFacility 作成対象のMedicalFacilityUserが所属するMedicalFacility
 * @returns MedicalFacilityUserオブジェクト
 */
export function create(
  medicalFacility: MedicalFacility[]
): MedicalFacilityUser {
  if (medicalFacility.length === 0) {
    throw new Error("medicalFacility must have at least one element");
  }

  const facilityUser = new MedicalFacilityUser();
  facilityUser.medical_facility = medicalFacility.map((m) => parseInt(m.id));
  facilityUser.user = createCustomUser();
  return facilityUser;
}

function clone(
  facilityUser: MedicalFacilityUser,
  customUser?: CustomUser
): MedicalFacilityUser {
  const orgMedicalFacilityUser = facilityUser as any;
  const newMedicalFacilityUser = new MedicalFacilityUser() as any;

  for (const key of Object.keys(newMedicalFacilityUser)) {
    if (key === "user") {
      if (customUser == null) {
        newMedicalFacilityUser["user"] = cloneCustomUser(facilityUser.user);
      } else {
        newMedicalFacilityUser["user"] = customUser;
      }
    } else {
      newMedicalFacilityUser[key] = orgMedicalFacilityUser[key];
    }
  }

  return newMedicalFacilityUser as MedicalFacilityUser;
}

/**
 * MedicalFacilityUserに値をセットし、新しいMedicalFacilityUserオブジェクトを返す
 * @param key 対象パラメータ
 * @param value 対象パラメータにセットする値
 * @param facilityUser 対象のMedicalFacilityUser
 * @returns 引数facilityUserがコピー（ディープコピー）された新しいMedicalFacilityオブジェクト
 */
export function setValue(
  key: MedicalFacilityUserFields,
  value: any,
  facilityUser: MedicalFacilityUser
): MedicalFacilityUser {
  switch (key) {
    case "phone":
    case "username":
    case "email":
    case "last_name":
    case "first_name":
    case "groups":
      return clone(facilityUser, setCustomUser(key, value, facilityUser.user));
    case "scu_email_receiving":
      return setEmailReceiving(value, facilityUser);
    case "medical_facility":
    case "id":
    case "total_error":
      throw new Error(`${key} is not supported by setValue`);
  }
}

/**
 * 施設ユーザーのメール受信設定とUI用の文言を変換するテーブル
 */
export const EMAIL_RECEIVING_LABELS = new Map<boolean, string>([
  [true, "受け取る"],
  [false, "受け取らない"],
]);

/**
 * 施設ユーザーのメール受信設定をUI用の文言に変換する
 * @param facilityUser 対象の施設ユーザー
 * @returns メール受信設定のUI用の文言
 */
export function emailReceiving(facilityUser: MedicalFacilityUser): string {
  return (
    EMAIL_RECEIVING_LABELS.get(facilityUser.scu_email_receiving) ??
    "受け取らない"
  );
}

function setEmailReceiving(
  value: boolean,
  facilityUser: MedicalFacilityUser
): MedicalFacilityUser {
  const newUser = clone(facilityUser);
  newUser.scu_email_receiving = value;
  return newUser;
}

/**
 * MedicalFacilityUserのプロパティ
 */
export type MedicalFacilityUserFields =
  | MedicalFacilityUserProfileFields
  | CustomUserFields
  | "total_error";

type MedicalFacilityUserProfileFields =
  | "id"
  | "medical_facility"
  | "scu_email_receiving";

const MEDICAL_FACILITY_FIELDS: MedicalFacilityUserProfileFields[] = [
  "id",
  "medical_facility",
  "scu_email_receiving",
];

/**
 * MedicalFacilityUser編集APIからのエラーを保持するMapを生成し、返す
 * @param errorData エラー(APIのエラーレスポンス)
 * @returns MedicalFacilityUser編集APIからのエラーを保持するMap
 */
export function createErrorMessages(
  errorData: any
): Map<MedicalFacilityUserFields, string> {
  const messages = new Map<MedicalFacilityUserFields, string>();
  for (const key of MEDICAL_FACILITY_FIELDS) {
    if (
      errorData == null ||
      errorData[key] == null ||
      errorData[key].length === 0
    ) {
      messages.set(key, "");
      continue;
    }

    messages.set(key, errorData[key][0]);
  }

  for (const key of CUSTOM_USER_FIELDS) {
    if (
      errorData == null ||
      errorData["user"] == null ||
      errorData["user"][key] == null ||
      errorData["user"][key].length === 0
    ) {
      messages.set(key, "");
      continue;
    }

    messages.set(key, errorData["user"][key][0]);
  }

  return messages;
}

/**
 * MedicalFacilityUser編集処理で不明なエラーが起きたときに表示するエラーメッセージを返す
 * @returns 不明なエラー用のエラーメッセージ(Map)
 */
export function createUnknownErrorMessages(): Map<MedicalFacilityUserFields, string> {
  return new Map<MedicalFacilityUserFields, string>([
    ["total_error", "エラーが発生しました。しばらく経ってから、再試行してください。"]
  ]);
}

/**
 * 施設ユーザーが新規作成中か否かを返す
 * @param facilityUser 対象の施設ユーザー
 * @returns 新規作成中ならtrue,そうでなければfalse
 */
export function isNew(facilityUser: MedicalFacilityUser): boolean {
  return facilityUser.id == null;
}

// CustomUser wrapper

/**
 * 施設ユーザーのuser name(ログインユーザー名)を返す
 * @param facilityUser 対象の施設ユーザー
 * @returns user name(ログインユーザー名)
 */
export function username(facilityUser: MedicalFacilityUser): string {
  return facilityUser.user.username;
}

/**
 * 施設ユーザーの苗字を返す
 * @param facilityUser 対象の施設ユーザー
 * @returns 苗字
 */
export function firstName(facilityUser: MedicalFacilityUser): string {
  return facilityUser.user.first_name;
}

/**
 * 施設ユーザーの名前を返す
 * @param facilityUser 対象の施設ユーザー
 * @returns 名前
 */
export function lastName(facilityUser: MedicalFacilityUser): string {
  return facilityUser.user.last_name;
}

/**
 * 施設ユーザーのメールアドレスを返す
 * @param facilityUser 対象の施設ユーザー
 * @returns メールアドレス
 */
export function email(facilityUser: MedicalFacilityUser): string {
  return facilityUser.user.email;
}

/**
 * 施設ユーザーのフルネーム(last_nameとfirst_nameを連結した文字列)を返す
 * @param facilityUser 対象の施設ユーザー
 * @returns フルネーム
 */
export function fullName(facilityUser: MedicalFacilityUser): string {
  return fullNameCustomUser(facilityUser.user);
}

/**
 * 施設ユーザー用の権限ラベル
 */
export const PRIVILEGE_LABELS: Record<number, string> =
  PRIVILEGE_LABELS_CUSTOM_USER;

/**
 * 施設ユーザーの権限を表す文字列返す
 * @param facilityUser 対象の施設ユーザー
 * @returns 施設ユーザーの権限を表す文字列
 */
export function privilege(facilityUser: MedicalFacilityUser): string {
  return privilegeCustomUser(facilityUser.user);
}

/**
 * 施設ユーザーの権限を数値(値)で返す
 * @param facilityUser 対象の施設ユーザー
 * @returns 施設ユーザーの権限
 */
export function getPrivilege(facilityUser: MedicalFacilityUser): number {
  return getPrivilegeCustomUser(facilityUser.user);
}
