import {
  Autocomplete,
  AutocompleteProps,
  ChipTypeMap,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useEffect, useState } from "react";

type CustomAutocompleteProps<
  DisableClearable extends boolean | undefined = true,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"]
> = AutocompleteProps<string, DisableClearable, true, true, ChipComponent>;
export type SuggestTextFieldProps = Omit<
  TextFieldProps,
  "onChange" | "value"
> & {
  /**
   * 入力値を使用してオプションを取得する関数を指定します。
   *
   * @param inputValue 入力値
   * @returns
   */
  getOptions: (inputValue?: string) => string[] | Promise<string[]>;

  /**
   * @see {@link AutocompleteProps#inputValue}
   */
  inputValue: CustomAutocompleteProps["inputValue"];

  /**
   * @see {@link CustomAutocompleteProps#onInputChange}
   */
  onInputChange: CustomAutocompleteProps["onInputChange"];

  /**
   * "freeSolo" | "openOnFocus" | "disableClearable" | "options" | "inputValue" | "onInputChange" | "renderInput" 以外の {@link AutocompleteProps} のプロパティ
   * @see {@link AutocompleteProps}
   */
  autocompleteProps?: Omit<
    CustomAutocompleteProps,
    | "freeSolo"
    | "openOnFocus"
    | "disableClearable"
    | "options"
    | "inputValue"
    | "onInputChange"
    | "renderInput"
  >;
};

/**
 * 入力候補を表示するTextFieldです。
 *
 * @param {SuggestTextFieldProps} param0
 * @returns {JSX.Element}
 */
function SuggestTextField({
  getOptions,
  inputValue,
  onInputChange,
  autocompleteProps,
  ...textFieldProps
}: SuggestTextFieldProps): JSX.Element {
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    (async () => setOptions(await getOptions(inputValue)))();
  }, [inputValue, getOptions]);

  return (
    <Autocomplete
      {...autocompleteProps}
      freeSolo
      openOnFocus
      disableClearable
      options={options}
      inputValue={inputValue}
      onInputChange={onInputChange}
      renderInput={(params) => <TextField {...textFieldProps} {...params} />}
    />
  );
}
export default SuggestTextField;
