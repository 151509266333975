import Authenticated from "@/components/auth/Authenticated";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { Copyright } from "@/components/examinee/Copyright";
import {
  GuideCard,
  GuideCardProps,
} from "@/components/examinee/interview/GuideCard";
import { InterviewAppBar } from "@/components/examinee/interview/InterviewAppBar";
import { paths } from "@/components/examinee/interview/Path";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import constants from "@/utils/constants";
import {
  Box,
  Button,
  Container,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";

export function PrimaryInterviewIntroduction({
  headerHeight,
}: ExamineePageProps) {
  const params = useParams();

  const FOOTER_HEIGHT = "85px";
  const PX = 4;
  const BACKGROUND_COLOR = "background.default";

  if (params.sleepCheckupId == null) {
    throw new Error("params.sleepCheckupId is null");
  }

  const next = paths.getFullPath("PrimaryInterviewEntryLoader", {
    ":sleepCheckupId": params.sleepCheckupId,
  });

  return (
    <Authenticated redirectTo={constants.SIGNIN_URL_EXAMINEE}>
      <ScrollToTop />
      <Box sx={{ minHeight: "100vh", position: "relative" }}>
        <InterviewAppBar height={headerHeight} title="はじめに" />
        <Container
          maxWidth="sm"
          sx={{
            pt: 0,
            pl: 0,
            pr: 0,
            pb: `calc(${FOOTER_HEIGHT} + 40px)`,
            backgroundColor: BACKGROUND_COLOR,
          }}
        >
          <IntroductionMessage
            sx={{ pt: 8, pb: 6, px: PX, backgroundColor: "white" }}
          />
          <IntroductionGuideCards
            sx={{ pt: 8, pb: 6, px: PX, backgroundColor: BACKGROUND_COLOR }}
          />
          <Stack
            position="sticky"
            bottom={0}
            sx={{
              py: 6,
              px: PX,
              background:
                "linear-gradient(180deg, rgba(246, 245, 244, 0.00) 0%, #F6F5F4 100%);",
            }}
          >
            <Button component={Link} to={next} variant="contained" size="large">
              回答をはじめる
            </Button>
          </Stack>
        </Container>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: FOOTER_HEIGHT,
          }}
        >
          <Copyright />
        </Stack>
      </Box>
    </Authenticated>
  );
}

type IntroductionMessageProps = {
  sx: SxProps<Theme>;
};
function IntroductionMessage({ sx }: IntroductionMessageProps) {
  return (
    <Stack spacing={2} sx={sx}>
      <Typography variant="subtitle1" textAlign="justify">
        これから事前問診をはじめます。以下の説明を読んで、問診に回答してください。回答完了までには10分ほどかかります。一度回答を始めた場合、中断せず最後まで回答をお願いします。
      </Typography>
      <Typography variant="caption">
        ※ 途中で画面を閉じてしまうと、最初からやり直しとなります。
      </Typography>
    </Stack>
  );
}

type IntroductionGuideCardsProps = {
  sx: SxProps<Theme>;
};
function IntroductionGuideCards({ sx }: IntroductionGuideCardsProps) {
  const CARDS: GuideCardProps[] = [
    {
      image: {
        src: "/img/image_interview_introduction_01.png",
        alt: "事前問診の操作",
      },
      text: "画面上部に質問が表示されます。回答を入力したら、画面下部の[次へ]ボタンを押して次の質問に進んでください。",
    },
    {
      image: {
        src: "/img/image_interview_introduction_02.png",
        alt: "事前問診の操作",
      },
      text: "[次へ]ボタンの表示がないページでは、表示された選択肢のうち最も当てはまるものにタッチしてください。選択すると自動的に次の質問に進みます。",
    },
    {
      image: {
        src: "/img/image_interview_introduction_03.png",
        alt: "事前問診の操作",
      },
      text: "間違えて回答してしまったときや、回答を変更したいときは画面左上の“戻る”をタッチ。ひとつ前の質問に戻れます。",
    },
  ];

  return (
    <Stack spacing={6} sx={sx}>
      {CARDS.map((c, i) => (
        <GuideCard key={i} image={c.image} text={c.text} />
      ))}
    </Stack>
  );
}
