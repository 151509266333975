import { Divider, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

export default function SubtitleTypography({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Stack direction="row" spacing={2}>
      <Divider
        orientation="vertical"
        sx={{ my: "7px", borderRightWidth: 4, borderColor: "primary.main" }}
        flexItem
      />
      <Typography variant="h6">{children}</Typography>
    </Stack>
  );
}
