import Authenticated from "@/components/auth/Authenticated";
import { getEncodedCurrentPath } from "@/components/common/LocationService";
import { NonFieldError } from "@/components/common/SCAlert";
import { ConfirmationDialog } from "@/components/examinee/ConfirmationDialog";
import {
  DailyInterviewInfo,
  RemindInfo,
} from "@/components/examinee/daily_interview_entrance/DailyInterviewInfo";
import { getDailyInterview } from "@/components/examinee/interview/InterviewAPI";
import {
  ErrorContext,
  createErrorContext,
} from "@/components/examinee/interview/InterviewEntryLoader";
import { paths } from "@/components/examinee/interview/Path";
import { InterviewEntry } from "@/components/examinee/interview/Types";
import { getSleepCheckupInfo } from "@/components/examinee/sleep_checkup/SleepCheckupAPI";
import constants from "@/utils/constants";
import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function DailyInterviewEntrance() {
  const [info, setInfo] = useState<DailyInterviewInfo | null>(null);
  const [unanswered, setUnanswered] = useState<RemindInfo | null>(null);
  const [error, setError] = useState<ErrorContext | null>(null);

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const remindId = params.remindId != null ? parseInt(params.remindId) : null;

  useEffect(() => {
    if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
      throw new Error("sleepCheckupId is not assigned");
    }
    if (params.remindId == null || params.remindId === undefined) {
      throw new Error("remindId is not assigned");
    }

    getSleepCheckupInfo(params.sleepCheckupId, getEncodedCurrentPath(location))
      .then((res: any) => {
        const info = new DailyInterviewInfo(res.data);
        setInfo(info);
        setUnanswered(info.getUnansweredRemind(parseInt(params.remindId!)));
      })
      .catch((err) => {
        setError(createErrorContext(err));
      });
  }, [params.sleepCheckupId, params.remindId, location]);

  const getInterview = (sleepCheckupId: string, remindId: string) => {
    getDailyInterview(sleepCheckupId, remindId, getEncodedCurrentPath(location))
      .then((res) => {
        const entry = res.data as InterviewEntry;
        navigate(
          paths.getFullPath("DailyInterview", {
            ":sleepCheckupId": sleepCheckupId,
            ":remindId": remindId,
            ":interviewId": String(entry.id),
            ":screen": String(entry.screen.id),
          })
        );
      })
      .catch((err) => {
        setError(createErrorContext(err));
      });
  };

  const handleClick = () => {
    if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
      throw new Error("sleepCheckupId is not assigned");
    }
    if (params.remindId == null || params.remindId === undefined) {
      throw new Error("remindId is not assigned");
    }

    getInterview(params.sleepCheckupId, params.remindId);
  };

  const handleToPrevInterview = (remind: RemindInfo | null) => {
    if (remind == null) {
      throw new Error("unanswered is null");
    }

    if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
      throw new Error("sleepCheckupId is not assigned");
    }

    getInterview(params.sleepCheckupId, String(remind.remind.id));
  };

  return (
    <Authenticated redirectTo={constants.SIGNIN_URL_EXAMINEE}>
      <Stack spacing={8} sx={{ mx: 4, mt: 8, mb: 14 }} alignItems="center">
        {error != null && <NonFieldError>{error.message}</NonFieldError>}
        <FirstViewImage />
        {info != null && remindId != null && (
          <Stack spacing={6} sx={{ width: "100%" }}>
            <Stack spacing={4}>
              <MorningMessage examineeName={info.examineeName} />
              <TodaysMessage remindInfo={info.getRemindInfo(remindId)} />
            </Stack>
            <Button
              variant="contained"
              size="large"
              onClick={handleClick}
              disabled={error != null}
            >
              回答をはじめる
            </Button>
          </Stack>
        )}
      </Stack>
      <ConfirmationDialog
        open={unanswered != null}
        message={`${unanswered?.date}分の問診が未回答です。先に未回答の問診に回答しますか？`}
        action={{
          title: "回答する",
          onClick: () => {
            handleToPrevInterview(unanswered);
          },
        }}
        onClickClose={() => {
          setUnanswered(null);
        }}
      />
    </Authenticated>
  );
}

function FirstViewImage() {
  return (
    <img
      src="/img/image_interview_entrance_01.png"
      alt="おはようございます"
      loading="lazy"
      style={{ width: 343, height: "auto" }}
    />
  );
}

type MorningMessageProps = {
  examineeName: string;
};
function MorningMessage({ examineeName }: MorningMessageProps) {
  return (
    <Stack alignItems="center">
      <Typography variant="h6">おはようございます</Typography>
      <Stack direction="row" spacing={1}>
        <Typography variant="h6" color="primary">
          {examineeName}
        </Typography>
        <Typography variant="h6">さん</Typography>
      </Stack>
    </Stack>
  );
}

type TodaysMessageProps = {
  remindInfo: RemindInfo;
};
function TodaysMessage({ remindInfo }: TodaysMessageProps) {
  const today = `${remindInfo.date}(${remindInfo.times})`;

  return (
    <Stack alignItems="center">
      <Stack direction="row">
        <Typography variant="subtitle2" color="primary">
          {today}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          の
        </Typography>
      </Stack>
      <Typography variant="subtitle2" color="text.secondary">
        毎日問診をはじめましょう
      </Typography>
    </Stack>
  );
}
