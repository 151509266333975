import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { useScreenLock } from "@/components/ScreenLock";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import { SCSnackbar } from "@/components/sleep_checkup_v1/SCSnackbar";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { isTestTargetFacility } from "@/utils/abtest";
import {
  callUserTypeApi,
  CognitoAuthenticationResultStore,
  CognitoRefreshTokenStore,
  SleepCheckupUserStore,
} from "@/utils/auth";
import { getApiServerUrl, getAxios } from "@/utils/axios";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  email: string;
  password: string;
  birthdayWithHyphen: string;
  confirmationCode: string;
  registrationEntryId: string;
  sleepCheckupUuid: string;
  medicalFacilityId: string;
};

export const Confirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const API_URL = getApiServerUrl();

  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarText, setSnackbarText] = useState("");
  const [cognitoCode, setCognitoCode] = useState("");
  const [toShowResendDialog, setToShowResendDialog] = useState(false);
  const [isLocked, setLock] = useScreenLock();

  const {
    email,
    password,
    birthdayWithHyphen,
    confirmationCode,
    registrationEntryId,
    sleepCheckupUuid,
    medicalFacilityId,
  } = (location.state as StateType) || {};

  if (
    !email ||
    !password ||
    !birthdayWithHyphen ||
    !confirmationCode ||
    !registrationEntryId ||
    !sleepCheckupUuid ||
    !medicalFacilityId
  ) {
    return (
      <GeneralError
        to="/examinee/signup"
        buttonText="アカウント登録をやり直す"
      />
    );
  }

  const validators: Record<string, () => string[] | null> = {
    cognitoCode: () => {
      if (!cognitoCode.match(/^\d{6}$/)) {
        return ["※半角数字6桁で入力してください"];
      }
      return null;
    },
  };

  const handleClickConfirm = () => {
    if (Object.values(validators).some((v) => v())) {
      // 何かしらのエラーがある場合は処理を行わない
      return;
    }

    if (isLocked) {
      return;
    }
    setLock(true);

    const isTargetFacility = isTestTargetFacility(Number(medicalFacilityId));

    // 受診者登録API
    axios
      // NOTE: PATCHメソッドの場合はURL末尾にスラッシュが無いと、サーバー側で
      //       RuntimeError: You called this URL via PATCH, but the URL doesn't end in a slash
      //       and you have APPEND_SLASH set. Django can't redirect to the slash URL while
      //       maintaining PATCH data. Change your form to point to
      //       /api/registration_entry/.../ (note the trailing slash),
      //       or set APPEND_SLASH = False in your Django settings.
      //       というエラーが出る。
      .patch(`${API_URL}/api/registration_entry/${registrationEntryId}/`, {
        sleep_checkup: sleepCheckupUuid,
        email: email,
        password: password,
        cognito_verification_code: cognitoCode,
      })
      .then((res) => {
        // ChallengeNameが無い場合、認証が成功している
        CognitoAuthenticationResultStore.setItem(res.data.AuthenticationResult);
        CognitoRefreshTokenStore.setItem({
          RefreshToken: res.data.AuthenticationResult.RefreshToken,
        });
        callUserTypeApi(
          getAxios(API_URL),
          res.data.AuthenticationResult.IdToken
        )
          .then((res: AxiosResponse) => {
            SleepCheckupUserStore.setItem(res.data);

            if (isTargetFacility) {
              // QRコードの読み取り可否の検証のため、ACCELStars施設の受診者にだけデバイスID登録を実施してもらう
              navigate(
                `/examinee/device_id_scan_introduction/${sleepCheckupUuid}`
              );
            } else {
              navigate("/examinee/registration_completed", {
                state: {
                  sleepCheckupUuid: sleepCheckupUuid,
                },
              });
            }
          })
          .finally(() => setLock(false));
      })
      .catch((err) => {
        const statusCode = err.response?.status;
        const messages = err.response?.data?.error_messages;
        if (
          statusCode &&
          400 <= statusCode &&
          statusCode < 500 &&
          messages &&
          messages.length > 0
        ) {
          // 400系エラーでエラーメッセージが存在する場合はそれを表示する
          setErrorMessage(messages[0]);
        } else {
          setErrorMessage(
            "エラーが発生しました。申し訳ありませんが、少し時間をおいてから再度お試しください。"
          );
        }

        // エラーアラートが画面内に表示されるようにスクロールをリセットする
        window.scrollTo(0, 0);

        setLock(false);
      });
  };

  const handleClickResend = () => {
    setToShowResendDialog(false);
    axios
      .put(`${API_URL}/api/confirmation_code/${registrationEntryId}/`, {
        sleep_checkup: sleepCheckupUuid,
      })
      .then((res) => {
        setSnackbarText("確認コードを再送しました。");
      })
      .catch((err) => {
        const statusCode = err.response?.status;
        const messages = err.response?.data?.error_messages;
        if (
          statusCode &&
          400 <= statusCode &&
          statusCode < 500 &&
          messages &&
          messages.length > 0
        ) {
          // 400系エラーでエラーメッセージが存在する場合はそれを表示する
          setErrorMessage(messages[0]);
        } else {
          setErrorMessage(
            "エラーが発生しました。申し訳ありませんが、少し時間をおいてから再度お試しください。"
          );
        }
      });
  };

  return (
    <>
      <ScrollToTop />
      <Stack spacing={8} sx={{ mt: 8, mb: 12, mx: 4 }}>
        <img
          src="/img/mail_icon.svg"
          alt="睡眠健診"
          loading="lazy"
          style={{ height: 72 }}
        />
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            確認コードの入力
          </Typography>

          {/* エラーメッセージ */}
          <NonFieldError>{errorMessage}</NonFieldError>

          {email && (
            <>
              <Typography
                variant="subtitle2"
                textAlign="justify"
                sx={{ color: "text.secondary", wordBreak: "break-all" }}
              >
                入力したメールアドレス({email}
                )に利用者登録用の確認コードをお送りしました。記載されている確認コードを入力してください。
              </Typography>

              <TextForm
                title="確認コード"
                placeholder="数字6桁"
                value={cognitoCode}
                onChange={(e) => {
                  setCognitoCode(e.target.value);
                }}
                validator={validators.cognitoCode}
              />
            </>
          )}
        </Stack>
      </Stack>
      <Stack sx={{ mb: 6 }} alignItems="center">
        <Stack width="100%">
          <Button
            variant="contained"
            size="large"
            sx={{ mx: 4, mb: 4 }}
            onClick={handleClickConfirm}
            disabled={Object.values(validators).some((v) => v())}
          >
            確認する
          </Button>
        </Stack>
        <Button
          variant="text"
          sx={{ p: 0 }}
          onClick={() => {
            setToShowResendDialog(true);
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textDecoration: "underline" }}
          >
            コードを再送する
          </Typography>
        </Button>
      </Stack>

      {/* コードを再送するダイアログ */}
      <Dialog
        maxWidth="md"
        open={toShowResendDialog}
        onClose={() => {
          setToShowResendDialog(false);
        }}
        PaperProps={{
          style: { borderRadius: 16 },
        }}
      >
        <DialogContent sx={{ m: 0, p: 6 }}>
          <Stack spacing={2} sx={{ mb: 6 }}>
            <Typography variant="body1" sx={{ color: "text.primary" }}>
              以下のメールアドレス宛に確認コードを再送します。
            </Typography>
            <Typography variant="body2" sx={{ color: "primary.main" }}>
              {email}
            </Typography>
          </Stack>
          <Stack spacing={4} direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                setToShowResendDialog(false);
              }}
              sx={{ width: "100%" }}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ width: "100%" }}
              onClick={() => {
                setToShowResendDialog(false);
                handleClickResend();
              }}
            >
              再送する
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      <SCSnackbar
        open={!!snackbarText}
        severity="success"
        onClose={() => setSnackbarText("")}
      >
        {snackbarText}
      </SCSnackbar>
    </>
  );
};
