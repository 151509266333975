export type Pages =
  | "PrimaryInterviewEntryLoader"
  | "PrimaryInterviewIntroduction"
  | "PrimaryInterviewCompletion"
  | "DailyInterviewRemind"
  | "DailyInterviewLoader"
  | "DailyInterviewCompletion"
  | "DailyInterviewEntrance"
  | "PrimaryInterviewEntrance"
  | InterviewPages;

export type InterviewPages = "PrimaryInterview" | "DailyInterview";

class Path {
  readonly parent: string;
  readonly table: Record<Pages, string>;

  constructor(parent: string, table: Record<Pages, string>) {
    this.parent = parent;
    this.table = table;
  }

  getPath(key: Pages, param?: Record<string, string>): string {
    let path = this.table[key];
    if (param != null) {
      for (const [k, v] of Object.entries(param)) {
        path = path.replace(k, v);
      }
      return path;
    } else {
      return path;
    }
  }

  getFullPath(key: Pages, param?: Record<string, string>): string {
    return `${this.parent}${this.getPath(key, param)}`;
  }
}

/**
 * 受診者ユーザー用の事前問診ページ系のパスを保持するオブジェクト
 */
export const paths = new Path("/examinee", {
  PrimaryInterview: "/primary_interview/:sleepCheckupId/:interviewId/:screen",
  PrimaryInterviewEntryLoader: "/primary_interview/:sleepCheckupId",
  PrimaryInterviewEntrance: "/primary_interview_entrance/:sleepCheckupId",
  PrimaryInterviewIntroduction:
    "/primary_interview_introduction/:sleepCheckupId",
  PrimaryInterviewCompletion: "/primary_interview_completion",
  DailyInterviewRemind: "/daily_interview_remind/:sleepCheckupId",
  DailyInterview:
    "/daily_interview/:sleepCheckupId/:remindId/:interviewId/:screen",
  DailyInterviewLoader: "/daily_interview/:sleepCheckupId/:remindId",
  DailyInterviewCompletion:
    "/daily_interview_completion/:sleepCheckupId/:remindId",
  DailyInterviewEntrance: "/daily_interview_entrance/:sleepCheckupId/:remindId",
});
