import { useScreenLock } from "@/components/ScreenLock";
import { Copyright } from "@/components/examinee/Copyright";
import { useAxios } from "@/utils/axios";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RangeChart from "../commons/RangeChart";
import ReportDialog from "../commons/ReportDialog";
import { ReportImg } from "../commons/ReportImg";
import { useSummaryPageContext } from "./Summary";
import { TeacherComment } from "./commons";

/**
 * リスク情報
 */
interface SummaryDiseaseRiskInfo {
  /** リスク名 */
  title: string;
  /** 尺度名 */
  basis_name: string;
  /** 尺度注釈 */
  basis_annotations: string[];
  /** 今回値 */
  score: number;
  /** 尺度最大値 */
  max_score: number;
  /** リスクあり・なし */
  is_risk: boolean | null; // null の場合、回答不足のため未判定
  /** リスク値 */
  cutoff: number; // STOP-BANGでは2点
}

/**
 * 測定結果の詳細情報
 */
interface SummaryDiseaseRiskResultInfo {
  /**
   * リスク一覧
   */
  risk_list: SummaryDiseaseRiskInfo[];
  /**
   * コメント
   */
  comment: string;
}

/**
 * 測定結果の詳細（疾患リスク）画面
 *
 */
export default function DiseaseRiskResult() {
  const [, setLock] = useScreenLock();
  const context = useSummaryPageContext();
  const [openInitDialog, setOpenInitDialog] = useState(context.isFirstVisit);
  const [summaryDiseaseRiskResultInfo, setSummaryDiseaseRiskResultInfo] =
    useState<SummaryDiseaseRiskResultInfo>();
  const axios = useAxios();

  useEffect(() => {
    setLock(true);
  }, [setLock]);

  useEffect(() => {
    (async () => {
      if (context.props.uuid == null) return;
      const response = await axios.get<SummaryDiseaseRiskResultInfo>(
        `/api/summary/disease_risk/${context.props.uuid}`
      );
      setSummaryDiseaseRiskResultInfo(response.data);
      setLock(false);
    })();
  }, [axios, context.props.uuid, setLock]);

  function next() {
    return context.setScreenType("end_of_summary");
  }

  if (summaryDiseaseRiskResultInfo == null) return <></>;
  return (
    <Stack
      sx={{
        p: "24px 16px 80px 16px",
        gap: "24px",
        height: "100%",
      }}
    >
      <DiseaseRiskDialog open={openInitDialog} setOpen={setOpenInitDialog} />
      {summaryDiseaseRiskResultInfo.risk_list.map((risk, i) => (
        <DiseaseRiskSection key={i} index={i} info={risk} />
      ))}
      <Stack>
        <TeacherComment>{summaryDiseaseRiskResultInfo.comment}</TeacherComment>
      </Stack>
      <Stack
        sx={{
          pt: "24px",
          pb: "10px",
          mb: "80px",
          position: "sticky",
          bottom: 0,
          zIndex: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{ boxShadow: "none" }}
          onClick={() => {
            next();
          }}
        >
          次へ
        </Button>
      </Stack>
      <Stack
        sx={{
          p: "32px 0px",
          position: "sticky",
          alignItems: "center",
        }}
      >
        <Copyright />
      </Stack>
    </Stack>
  );
}

/**
 * {@link DiseaseRiskDialogProps} のプロパティ
 */
type DiseaseRiskDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

/**
 * 疾患リスクの初期表示ダイアログ
 *
 * @param param0
 * @returns
 */
function DiseaseRiskDialog({ open, setOpen }: DiseaseRiskDialogProps) {
  return (
    <ReportDialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <Stack>
        <Stack sx={{ p: "16px" }}>
          <Stack direction="row" sx={{ gap: "8px" }}>
            <Typography variant="subtitle1" sx={{ color: "primary.dark" }}>
              睡眠時無呼吸症候群/不眠症リスクとは
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ background: "#EBF1F7", p: "16px" }}>
          <ReportImg image_name="image_report_summary_what_is_disease_risk.png" />
        </Stack>
        <Stack sx={{ p: "16px 16px 16px 16px", gap: "8px" }}>
          <Typography variant="body2" textAlign="justify">
            睡眠時無呼吸症候群は眠っている間に呼吸が止まってしまいます。呼吸をするために夜間何度も覚醒してしまい、十分な睡眠がとれなくなります。
          </Typography>
          <Typography variant="body2" textAlign="justify">
            不眠症は、眠りたいのに寝付きが悪い・何度も目が覚める・眠りが浅いなどの症状が慢性化している状態です。
          </Typography>
          <Typography variant="body2" textAlign="justify">
            どちらも放っておくと必要な睡眠がとれなくなるため、リスクがある場合は医療機関の受診をご検討ください。
          </Typography>
        </Stack>
        <Stack sx={{ p: " 16px" }}>
          <Box textAlign="center" sx={{ pt: "16px" }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "18px",
                p: "6px 32px 6px 32px",
                boxShadow: "none",
              }}
              onClick={() => setOpen(false)}
            >
              結果を見る
            </Button>
          </Box>
        </Stack>
      </Stack>
    </ReportDialog>
  );
}

/**
 * {@link DiseaseRiskSection} のプロパティ
 */
interface DiseaseRiskSectionProps {
  /** {@link SummaryDiseaseRiskInfo} */
  info: SummaryDiseaseRiskInfo;
  /** index */
  index: number;
}

/**
 * 疾患リスク情報
 * @param param0 {@link DiseaseRiskSectionProps}
 * @returns 疾患リスク情報
 */
function DiseaseRiskSection({ info, index }: DiseaseRiskSectionProps) {
  return (
    <Stack
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        p: "16px",
        gap: "16px",
      }}
    >
      <Stack sx={{ gap: "8px" }}>
        <Typography variant="h6">{info.title}</Typography>
        <Stack direction="row" sx={{ marginLeft: "auto" }}>
          <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
            {info.is_risk != null && (
              <ReportImg
                style={{ width: "8px", height: "8px" }}
                image_name="image_report_summary_chart_position_mark.svg"
              />
            )}
          </Box>
          {info.is_risk != null && (
            <Typography variant="caption">あなたの結果</Typography>
          )}
          <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
            <ReportImg
              style={{ width: "8px", height: "8px", marginLeft: "16px" }}
              image_name="image_report_summary_chart_reference_area.svg"
            />
          </Box>
          <Typography variant="caption">低リスクの範囲</Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
            評価項目
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "text.primary" }}
            textAlign="justify"
          >
            {info.basis_name}
            <sup>
              {info.basis_annotations.map((_, i) => `*${index + 1 + i}`)}
            </sup>
          </Typography>
          {info.basis_annotations.map((a, i) => (
            <Typography
              key={i}
              variant="caption"
              sx={{
                color: "#707070",
                textAlign: "justify",
                fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
              }}
            >
              *{index + 1 + i}:{a}
            </Typography>
          ))}

          <Stack>
            <RangeChart
              min={{
                value: 0,
                label: "0点",
                attr: { fill: "#00A99D" },
              }}
              max={{
                value: info.max_score,
                label: `${info.max_score}点`,
              }}
              recommendFrom={{
                value: 0,
              }}
              recommendTo={{
                value: info.cutoff,
                label: `${info.cutoff}点`,
              }}
              plot={
                info.is_risk != null
                  ? {
                      value: info.score ?? 0,
                      label: `${info.score ?? 0}点`,
                      attr: (() => (info.is_risk ? { fill: "#FF3A30" } : {}))(),
                    }
                  : null
              }
              message={
                info.is_risk == null
                  ? {
                      text: "回答不足のため未判定",
                      attr: {
                        fill: "#A3A3A3",
                        "font-feature-settings":
                          "'palt' on, 'clig' off, 'liga' off",
                        "font-family": "Noto Sans JP",
                        "font-size": "12px",
                        "font-style": "normal",
                        "font-weight": 500,
                      },
                    }
                  : null
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
