import { NonFieldError } from "@/components/common/SCAlert";
import { MessageDialog } from "@/components/examinee/MessageDialog";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { TargetAuthenticated as Authenticated } from "@/sleep_compass_lite/components/target/TargetAuthenticated";
import { interviewPath } from "@/sleep_compass_lite/domain_models/target/InterviewPath";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { useSurveyInfo } from "@/sleep_compass_lite/use_cases/target/interview/UseSurveyInfo";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

/**
 * 事前問診開始ページ
 */
export function PrimaryInterviewEntrance() {
  const [surveyInfo, surveyInfoError] = useSurveyInfo();
  const [openConfirm, setOpenConfirm] = useState(false);

  const error = [surveyInfoError].find((e) => e != null);
  const { surveyInfoUid } = useParams();
  const navigate = useNavigate();
  const introductionPath = surveyInfoUid
    ? interviewPath.getPrimaryInterviewIntroductionPath(surveyInfoUid)
    : null;

  useEffect(() => {
    if (!surveyInfo) {
      return;
    }

    // 事前問診回答済みなので再回答する場合は上書きになる旨のダイアログを表示するステータス
    const shouldShowDialogStatus = ["PrimaryInterviewCompleted"];
    // 事前問診再回答不可能なので introduction ページに遷移する
    const skipLpPageStatus = [
      "DailyInterviewCompletedOne",
      "DailyInterviewCompletedTwo",
      "DailyInterviewCompletedAll",
      "ReportSent",
      "AttitudeEnqueteCompleted",
    ];
    if (shouldShowDialogStatus.includes(surveyInfo.status)) {
      setOpenConfirm(true);
    } else if (
      skipLpPageStatus.includes(surveyInfo.status) &&
      introductionPath
    ) {
      navigate(introductionPath);
    }
  }, [surveyInfo, navigate, introductionPath]);

  if (error != null) {
    return <NonFieldError>{getErrorMessage(error)}</NonFieldError>;
  }

  return (
    <Authenticated>
      <Stack spacing={6} sx={{ px: PX4, pt: 10, pb: 14 }} alignItems="center">
        <MessageDialog
          open={openConfirm}
          message={
            "この問診は既に回答済みです。再度回答すると以前の回答は上書きされます。回答の上書きを希望しない場合は、このページを閉じてください。"
          }
          action={{
            title: "OK",
            onClick: () => {
              setOpenConfirm(false);
            },
          }}
        />
        <PrimaryInterviewEntranceImage />
        {surveyInfo != null && (
          <Stack spacing={6} sx={{ width: "100%" }}>
            <GreetingMessage
              message="こんにちは"
              targetName={surveyInfo.target.userName.fullName()}
              caption="「事前問診」にご回答ください"
            />
            {introductionPath != null && (
              <Button
                component={Link}
                to={introductionPath}
                variant="contained"
                size="large"
                sx={{ boxShadow: 0 }}
              >
                事前問診に回答する
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Authenticated>
  );
}

/**
 * 事前問診開始ページのファーストビューコンポーネント
 */
function PrimaryInterviewEntranceImage() {
  return (
    <Box sx={{ px: "41.5px" }}>
      <img
        src="/img/image_interview_entrance_02.png"
        alt="primary interview entrance"
        loading="lazy"
        style={{ width: "100%", height: "auto", maxWidth: "390px" }}
      />
    </Box>
  );
}

type GreetingMessageProps = {
  /** メッセージ */
  message: string;
  /** 対象者の名前 */
  targetName: string;
  /** キャプション */
  caption: string;
};
/**
 * 事前問診開始ページのメインメッセージコンポーネント
 */
function GreetingMessage({
  message,
  targetName,
  caption,
}: GreetingMessageProps) {
  return (
    <Stack alignItems="center">
      <Typography variant="h6">{message}</Typography>
      <Stack direction="row" spacing={1}>
        <Typography variant="h6" color="primary">
          {targetName}
        </Typography>
        <Typography variant="h6">さん</Typography>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ mt: 4 }}>
        <Typography
          variant="caption"
          sx={{ fontSize: "14px", fontWeight: 500 }}
        >
          {caption}
        </Typography>
      </Stack>
    </Stack>
  );
}
