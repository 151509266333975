import { AddButton } from "@/components/common/AddButton";
import { clone, equals } from "@/components/common/ObjectService";
import { DeleteButton } from "@/components/sleep_checkup_v1/DeleteButton";
import { EditButton } from "@/components/sleep_checkup_v1/EditButton";
import { getMedicalFacility } from "@/components/sleep_checkup_v1/MedicalFacilityAPI";
import { MedicalFacilityUser } from "@/components/sleep_checkup_v1/MedicalFacilityUser";
import {
  createMedicalFacilityUser,
  editMedicalFacilityUser,
  getMedicalFacilityUser,
} from "@/components/sleep_checkup_v1/MedicalFacilityUserAPI";
import {
  setValue as setValueSearchParams,
  text,
  value as valueSearchParams,
} from "@/components/sleep_checkup_v1/MedicalFacilityUserSearchParamService";
import { MedicalFacilityUserSearchParams as SearchParams } from "@/components/sleep_checkup_v1/MedicalFacilityUserSearchParams";
import {
  EMAIL_RECEIVING_LABELS,
  MedicalFacilityUserFields,
  PRIVILEGE_LABELS,
  create,
  createErrorMessages,
  createUnknownErrorMessages,
  email,
  emailReceiving,
  firstName,
  fullName,
  getPrivilege,
  isNew,
  lastName,
  privilege,
  setValue,
  username,
} from "@/components/sleep_checkup_v1/MedicalFacilityUserService";
import { OrderSelect } from "@/components/sleep_checkup_v1/OrderSelect";
import { PAGE_TITLE } from "@/components/sleep_checkup_v1/Path";
import { SCDialogTitle } from "@/components/sleep_checkup_v1/SCDialogTitle";
import { SCSelect } from "@/components/sleep_checkup_v1/SCSelect";
import { SCSnackbar } from "@/components/sleep_checkup_v1/SCSnackbar";
import {
  Pagination,
  SCEmptyTable,
  SCTable,
} from "@/components/sleep_checkup_v1/SCTable";
import { SearchBar } from "@/components/sleep_checkup_v1/SearchBar";
import {
  MessageClosure,
  MessageClosureHandler,
  SnackbarContext,
} from "@/components/sleep_checkup_v1/SnackbarContext";
import { MedicalFacility } from "@/components/sleep_checkup_v1/Types";
import { SleepCheckupUserStore } from "@/utils/auth";
import { facilityAxios as axios } from "@/utils/axios";
import { Add, Check } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

/**
 * 施設ユーザー一覧を取得するためのデータをまとめたクラス
 */
class UserListInput {
  readonly rowsPerPage: number;
  readonly page: number;
  readonly order: MedicalFacilityUserOrder;
  readonly params: SearchParams;

  constructor(
    rowsPerPage: number,
    page: number,
    order: MedicalFacilityUserOrder,
    params: SearchParams
  ) {
    this.rowsPerPage = rowsPerPage;
    this.page = page;
    this.order = order;
    this.params = params;
  }
}

/**
 * 取得した施設ユーザー一覧に関するデータをまとめたクラス
 */
class UserListOutput {
  readonly list: MedicalFacilityUser[];
  readonly count: number;

  constructor(list: MedicalFacilityUser[], count: number) {
    this.list = list;
    this.count = count;
  }
}

/**
 * 新規作成や編集の対象になる施設ユーザーと、それに関するデータ、処理をまとめるためのクラス
 */
class UpdateContext {
  readonly facilityUser: MedicalFacilityUser;
  readonly dialogTitle: string;
  readonly canDelete: boolean;
  readonly buttonProps: UpdateUserDialogButtonProps;
  readonly promise: UpdateUserHandler;
  readonly messageClosure: MessageClosure;

  constructor(
    facilityUser: MedicalFacilityUser,
    dialogTitle: string,
    canDelete: boolean,
    buttonProps: UpdateUserDialogButtonProps,
    promise: UpdateUserHandler,
    messageClosure: MessageClosure
  ) {
    this.facilityUser = facilityUser;
    this.dialogTitle = dialogTitle;
    this.canDelete = canDelete;
    this.buttonProps = buttonProps;
    this.promise = promise;
    this.messageClosure = messageClosure;
  }
}

type MedicalFacilityUserOrder =
  | "user__date_joined"
  | "-user__date_joined"
  | "user__username"
  | "user__last_name,user__first_name"
  | "user__email";

type MedicalFacilityUserHandler = (facilityUser: MedicalFacilityUser) => void;

type ChangePageHandler = (newPage: number) => void;

type RowsPerPageChangeHandler = (newRowsPerPage: number) => void;

type UpdateUserHandler = (facilityUser: MedicalFacilityUser) => Promise<any>;

type SearchMedicalFacilityUserHandler = (params: SearchParams) => void;

const createInput = () =>
  new UserListInput(10, 1, "-user__date_joined", new SearchParams());

const createPagination = (input: UserListInput, output: UserListOutput) =>
  new Pagination(input.rowsPerPage, input.page, output.count);

type UserListProps = {
  minWidth: number;
};
export function UserList({ minWidth }: UserListProps) {
  const [update, setUpdate] = useState<UpdateContext | null>(null);
  const [searchDialogContext, setSearchDialogContext] =
    useState<SearchParams | null>(null);
  const [input, setInput] = useState<UserListInput>(createInput());
  const [output, setOutput] = useState<UserListOutput | null>(null);
  const [medicalFacility, setMedicalFacility] = useState<MedicalFacility[]>([]);
  const [snackbarContext, setSnackbarContext] =
    useState<SnackbarContext | null>(null);

  const ROWS_PER_PAGE_OPTION = [10, 25, 50, 100];
  const ORDER_ITEM: Map<MedicalFacilityUserOrder, string> = new Map([
    ["-user__date_joined", "登録日が新しい順"],
    ["user__date_joined", "登録日が古い順"],
    ["user__username", "ユーザー名順"],
  ]);
  const TABLE_HEADERS = [
    "ユーザー名",
    "氏名",
    "権限",
    "メールアドレス",
    "通知メール",
    "操作",
  ];

  const getUsers = (input: UserListInput) => {
    return getMedicalFacilityUser(
      input.params,
      input.page,
      input.order,
      input.rowsPerPage
    ).then((res) => {
      const { count, results } = res.data;
      return new UserListOutput(results, count);
    });
  };

  const succeeded = (output: UserListOutput) => {
    setOutput(output);
  };

  const failed = (err: any) => {
    setSnackbarContext(
      new SnackbarContext(`施設ユーザー一覧の取得に失敗しました (${err})`, "error")
    );
  };

  useEffect(() => {
    Promise.all([getUsers(input), getMedicalFacility()])
      .then((res) => {
        const [getUsersResult, getMedicalFacilityResult] = res;
        succeeded(getUsersResult);
        setMedicalFacility(getMedicalFacilityResult.data);
      })
      .catch(failed);
  }, [input]);

  const showSearchBarCancelButton = !equals(new SearchParams(), input.params);

  const handleCreateUserDialogOpen = () => {
    const context = new UpdateContext(
      create(medicalFacility),
      "新しいユーザーを登録",
      false, // 削除ボタンは非表示
      createButtonProps,
      (facilityUser) => {
        return createMedicalFacilityUser(facilityUser);
      },
      () => {
        return new SnackbarContext("新しいユーザーが追加されました", "success");
      }
    );
    setUpdate(context);
  };

  const handleEditUserDialogOpen: MedicalFacilityUserHandler = (
    facilityUser
  ) => {
    const context = new UpdateContext(
      facilityUser,
      "ユーザー情報を編集",
      SleepCheckupUserStore.getItem()?.username !== username(facilityUser), // ログインユーザーは自分自身を削除出来ない
      editButtonProps,
      (facilityUser) => {
        return editMedicalFacilityUser(facilityUser);
      },
      () => {
        return new SnackbarContext("ユーザー情報が編集されました", "success");
      }
    );
    setUpdate(context);
  };

  const handleUpdateUser: MessageClosureHandler = (closure) => {
    getUsers(input)
      .then((result) => {
        setUpdate(null);
        succeeded(result);
        setSnackbarContext(closure());
      })
      .catch(failed);
  };

  const handleDeleteUser = () => {
    getUsers(input)
      .then((result) => {
        setSnackbarContext(
          new SnackbarContext(
            `ユーザー ${update?.facilityUser.user.username} を削除しました`,
            "success"
          )
        );
        setUpdate(null);
        succeeded(result);
      })
      .catch(failed);
  };

  const handleUpdateDialogClose = () => {
    setUpdate(null);
  };

  const handleSearchDialogOpen = () => {
    setSearchDialogContext(clone(input.params));
  };

  const handleSearchDialogClose = () => {
    setSearchDialogContext(null);
  };

  const handleClearSearchParam = () => {
    // inputを更新することで、useEffectを介して、ユーザー一覧の更新をする
    setInput(
      new UserListInput(input.rowsPerPage, 1, input.order, new SearchParams())
    );
  };

  const handleSearchMedicalFacilityUser: SearchMedicalFacilityUserHandler = (
    params
  ) => {
    // inputを更新することで、useEffectを介して、ユーザー一覧の更新をする
    setInput(new UserListInput(input.rowsPerPage, 1, input.order, params));
    setSearchDialogContext(null);
  };

  const handleChangeOrder = (order: MedicalFacilityUserOrder) => {
    // inputを更新することで、useEffectを介して、ユーザー一覧の更新をする
    setInput(new UserListInput(input.rowsPerPage, 1, order, input.params));
  };

  const handlePageChange: ChangePageHandler = (newPage) => {
    // inputを更新することで、useEffectを介して、ユーザー一覧の更新をする
    setInput(
      new UserListInput(input.rowsPerPage, newPage, input.order, input.params)
    );
  };

  const handleRowsPerPageChange: RowsPerPageChangeHandler = (
    newRowsPerPage
  ) => {
    // inputを更新することで、useEffectを介して、ユーザー一覧の更新をする
    setInput(new UserListInput(newRowsPerPage, 1, input.order, input.params));
  };

  const handleSnackbarClose = () => {
    setSnackbarContext(null);
  };

  const searchBarText = text(input.params);
  const searchBarColor =
    searchBarText != null ? "black.default" : "text.disabled";

  return (
    <>
      <Stack spacing={6} sx={{ mx: 10, my: 6, minWidth: minWidth }}>
        <Typography variant="h3">{PAGE_TITLE["UserList"]}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={4}>
            <AddButton onClick={handleCreateUserDialogOpen}>
              新しいユーザーを登録
            </AddButton>
          </Stack>
          <Stack direction="row" spacing={3}>
            <SearchBar
              value={searchBarText ?? "氏名、ID等で検索"}
              color={searchBarColor}
              sx={{ width: "300px" }}
              onClick={handleSearchDialogOpen}
              onClickCancel={
                showSearchBarCancelButton ? handleClearSearchParam : undefined
              }
            />
            <OrderSelect<MedicalFacilityUserOrder>
              items={ORDER_ITEM}
              value={input.order}
              onChange={handleChangeOrder}
            />
          </Stack>
        </Box>
        {output !== null && output.list.length > 0 && (
          <SCTable<MedicalFacilityUser>
            headers={TABLE_HEADERS}
            list={output.list}
            pagination={createPagination(input, output)}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTION}
            tableRow={(facilityUser, i) => {
              return (
                <MedicalFacilityUserTableRow
                  key={i}
                  facilityUser={facilityUser}
                  onClickEdit={handleEditUserDialogOpen}
                ></MedicalFacilityUserTableRow>
              );
            }}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            maxHeight="60vh"
          />
        )}
        {output !== null && output.list.length === 0 && (
          <SCEmptyTable
            headers={TABLE_HEADERS}
            message="検索条件に一致する結果が見つかりませんでした。"
          />
        )}
      </Stack>
      {update !== null && (
        <UpdateUserDialog
          open={update !== null}
          context={update}
          onUpdateUser={handleUpdateUser}
          onDeleteUser={handleDeleteUser}
          onClickClose={handleUpdateDialogClose}
        />
      )}
      {searchDialogContext != null && (
        <SearchMedicalFacilityUserDialog
          open={true}
          params={searchDialogContext}
          onClickSearch={handleSearchMedicalFacilityUser}
          onClickClose={handleSearchDialogClose}
        />
      )}
      {snackbarContext != null && (
        <SCSnackbar
          open={true}
          severity={snackbarContext.severity}
          onClose={() => handleSnackbarClose()}
        >
          {snackbarContext.message}
        </SCSnackbar>
      )}
    </>
  );
}

type MedicalFacilityUserTableRowProps = {
  facilityUser: MedicalFacilityUser;
  onClickEdit: MedicalFacilityUserHandler;
};
function MedicalFacilityUserTableRow({
  facilityUser,
  onClickEdit,
}: MedicalFacilityUserTableRowProps) {
  return (
    <TableRow>
      <TableCell>{username(facilityUser)}</TableCell>
      <TableCell>{fullName(facilityUser)}</TableCell>
      <TableCell>{privilege(facilityUser)}</TableCell>
      <TableCell
        sx={{ maxWidth: "188px", textOverflow: "ellipsis", overflow: "hidden" }}
      >
        {email(facilityUser)}
      </TableCell>
      <TableCell>{emailReceiving(facilityUser)}</TableCell>
      <TableCell>
        <EditButton
          variant="text"
          size="small"
          onClick={() => onClickEdit(facilityUser)}
        />
      </TableCell>
    </TableRow>
  );
}

type UpdateUserDialogProps = {
  open: DialogProps["open"];
  context: UpdateContext;
  onUpdateUser: MessageClosureHandler;
  onDeleteUser: () => void;
  onClickClose: () => void;
};
type UpdateUserDialogButtonProps = {
  title: string;
  icon: ButtonProps["startIcon"];
};
function UpdateUserDialog({
  open,
  context,
  onUpdateUser,
  onDeleteUser,
  onClickClose,
}: UpdateUserDialogProps) {
  const SUBTITLE_SPACING = 1;
  const ROW_SPACING = 4;
  const COLUMN_SPACING = 8;
  const FIELD_WIDTH = "200px";
  const FIELD_HEIGHT = "52px";
  const SX = {
    "&.MuiTextField-root": { height: "auto" },
    width: FIELD_WIDTH,
    height: FIELD_HEIGHT,
  };
  const TOTAL_ERROR_SX = {
    color: "error.main",
    fontSize: "14px",
    maxWidth: "366px",
  };

  const [facilityUser, setUser] = useState<MedicalFacilityUser>(
    context.facilityUser
  );
  const [errorMessages, setErrorMessages] = useState<
    Map<MedicalFacilityUserFields, string>
  >(createErrorMessages(null));
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const handleDialogClose = () => {
    onClickClose();
    setIsOpenConfirmation(false);
  };

  const handleUpdateButtonClick = () => {
    context
      .promise(facilityUser)
      .then(() => onUpdateUser(context.messageClosure))
      .catch((err) => {
        if (err.response?.status === 400) {
          const messages = createErrorMessages(err.response.data);
          setErrorMessages(messages);
        } else {
          setErrorMessages(createUnknownErrorMessages());
        }
      });
  };

  // 削除ボタン
  const handleDeleteButtonClick = () => {
    setIsOpenConfirmation(true);
  };

  // 削除の確認後、実行
  const handleDeleteAfterConfirmation = () => {
    axios
      .delete(`/api/facility/medical_facility_user/${context.facilityUser.id}/`)
      .then(() => {
        handleDialogClose();
        onDeleteUser();
      });
  };

  // 削除の確認後、キャンセル
  const handleCancelAfterConfirmation = () => {
    setIsOpenConfirmation(false);
  };

  const dialogActionsSx = context.canDelete
    ? { justifyContent: "space-between" }
    : {};

  const totalErrorMessage = errorMessages.get("total_error");

  return (
    <>
      <Dialog open={open} onClose={handleDialogClose}>
        <SCDialogTitle onClickClose={handleDialogClose}>
          {context.dialogTitle}
        </SCDialogTitle>
        <DialogContent>
          <Stack spacing={8}>
            <Stack spacing={SUBTITLE_SPACING}>
              {totalErrorMessage && (
                <Alert severity="error">
                  <Typography sx={TOTAL_ERROR_SX}>{totalErrorMessage}</Typography>
                </Alert>
              )}
            </Stack>
            <Stack spacing={SUBTITLE_SPACING}>
              <Stack spacing={ROW_SPACING}>
                <TextField
                  variant="standard"
                  label="ユーザー名"
                  sx={{ height: FIELD_HEIGHT }}
                  required={isNew(facilityUser)}
                  disabled={!isNew(facilityUser)}
                  value={username(facilityUser)}
                  error={errorMessages.get("username") ? true : false}
                  helperText={errorMessages.get("username")}
                  id="username"
                  onChange={(event) => {
                    setUser(
                      setValue("username", event.target.value, facilityUser)
                    );
                  }}
                />
                <Stack direction="row" spacing={COLUMN_SPACING}>
                  <TextField
                    variant="standard"
                    label="姓"
                    required
                    sx={SX}
                    value={lastName(facilityUser)}
                    error={errorMessages.get("last_name") ? true : false}
                    helperText={errorMessages.get("last_name")}
                    id="last_name"
                    onChange={(event) => {
                      setUser(
                        setValue("last_name", event.target.value, facilityUser)
                      );
                    }}
                  />
                  <TextField
                    variant="standard"
                    label="名"
                    required
                    sx={SX}
                    value={firstName(facilityUser)}
                    error={errorMessages.get("first_name") ? true : false}
                    helperText={errorMessages.get("first_name")}
                    id="first_name"
                    onChange={(event) => {
                      setUser(
                        setValue("first_name", event.target.value, facilityUser)
                      );
                    }}
                  />
                </Stack>
                <TextField
                  variant="standard"
                  label="Eメールアドレス"
                  required
                  sx={{ height: FIELD_HEIGHT }}
                  value={email(facilityUser)}
                  error={errorMessages.get("email") ? true : false}
                  helperText={errorMessages.get("email")}
                  id="email"
                  onChange={(event) => {
                    setUser(
                      setValue("email", event.target.value, facilityUser)
                    );
                  }}
                />
                <Stack direction="row" spacing={COLUMN_SPACING}>
                  <SCSelect
                    label="権限"
                    required
                    value={getPrivilege(facilityUser)}
                    sx={SX}
                    variant="standard"
                    id="groups"
                    onChange={(event) => {
                      setUser(
                        setValue("groups", [event.target.value], facilityUser)
                      );
                    }}
                    disabled={
                      SleepCheckupUserStore.getItem()?.username ===
                      username(facilityUser)
                    } // ログインユーザーは自分自身の権限を変更できない
                  >
                    {Object.entries(PRIVILEGE_LABELS).map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </SCSelect>
                  <SCSelect
                    label="通知メール"
                    required
                    value={facilityUser.scu_email_receiving}
                    sx={SX}
                    variant="standard"
                    id="scu_email_receiving"
                    onChange={(event) => {
                      setUser(
                        setValue(
                          "scu_email_receiving",
                          event.target.value,
                          facilityUser
                        )
                      );
                    }}
                  >
                    {[...EMAIL_RECEIVING_LABELS].map(([value, label]) => {
                      return (
                        <MenuItem key={label} value={String(value)}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </SCSelect>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={dialogActionsSx}>
          {context.canDelete && (
            <DeleteButton variant="text" onClick={handleDeleteButtonClick}>
              削除する
            </DeleteButton>
          )}
          <Button
            variant="contained"
            size="small"
            startIcon={context.buttonProps.icon}
            onClick={handleUpdateButtonClick}
          >
            {context.buttonProps.title}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 削除の確認ダイアログ */}
      <Dialog open={isOpenConfirmation} onClose={handleCancelAfterConfirmation}>
        <SCDialogTitle
          onClickClose={handleCancelAfterConfirmation}
          noCloseButton
        >
          施設ユーザーを削除
        </SCDialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ minWidth: 380 }}>
            本当にこのユーザーを削除しますか？
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelAfterConfirmation}>キャンセル</Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleDeleteAfterConfirmation}
          >
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const createButtonProps: UpdateUserDialogButtonProps = {
  title: "登録する",
  icon: <Add />,
};

const editButtonProps: UpdateUserDialogButtonProps = {
  title: "変更を保存",
  icon: <Check />,
};

type SearchMedicalFacilityUserDialogProps = {
  open: DialogProps["open"];
  params: SearchParams;
  onClickSearch: SearchMedicalFacilityUserHandler;
  onClickClose: () => void;
};
function SearchMedicalFacilityUserDialog({
  open,
  params,
  onClickSearch,
  onClickClose,
}: SearchMedicalFacilityUserDialogProps) {
  const ROW_SPACING = 4;
  const COLUMN_SPACING = 8;
  const FIELD_WIDTH = "184px";
  const FIELD_HEIGHT = "48px";
  const SX = {
    width: FIELD_WIDTH,
    height: FIELD_HEIGHT,
  };
  const BUTTON_WIDTH = "120px";

  const [searchParams, setSearchParams] = useState<SearchParams>(
    new SearchParams(params)
  );

  // Note: searchParams,calendarTypeはpropsで与えられるparams,calendarと同期させる
  useEffect(() => {
    setSearchParams(new SearchParams(params));
  }, [params]);

  const handleClearSearchParams = () => {
    setSearchParams(new SearchParams());
  };

  const handleClose = () => {
    // Note: 閉じるボタンが押されたときは、各Stateを検索ダイアログ表示前の状態に戻す
    setSearchParams(params);

    onClickClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <SCDialogTitle onClickClose={handleClose}>ユーザーを検索</SCDialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={COLUMN_SPACING}>
          <Stack spacing={ROW_SPACING}>
            <TextField
              id="search_user_username"
              variant="standard"
              sx={{ height: FIELD_HEIGHT }}
              label="ユーザー名"
              value={valueSearchParams("username", searchParams)}
              onChange={(event) => {
                setSearchParams(
                  setValueSearchParams(
                    "username",
                    event.target.value,
                    searchParams
                  )
                );
              }}
            />
            <Stack direction="row" spacing={COLUMN_SPACING}>
              <TextField
                id="search_user_last_name"
                variant="standard"
                sx={SX}
                label="姓"
                value={valueSearchParams("last_name", searchParams)}
                onChange={(event) => {
                  setSearchParams(
                    setValueSearchParams(
                      "last_name",
                      event.target.value,
                      searchParams
                    )
                  );
                }}
              />
              <TextField
                id="search_user_first_name"
                variant="standard"
                sx={SX}
                label="名"
                value={valueSearchParams("first_name", searchParams)}
                onChange={(event) => {
                  setSearchParams(
                    setValueSearchParams(
                      "first_name",
                      event.target.value,
                      searchParams
                    )
                  );
                }}
              />
            </Stack>
            <TextField
              id="search_user_email"
              variant="standard"
              sx={{ height: FIELD_HEIGHT }}
              label={"Eメールアドレス"}
              value={valueSearchParams("email", searchParams)}
              onChange={(event) => {
                setSearchParams(
                  setValueSearchParams(
                    "email",
                    event.target.value,
                    searchParams
                  )
                );
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={4}>
          <Button
            variant="text"
            size="small"
            sx={{ width: BUTTON_WIDTH }}
            onClick={handleClearSearchParams}
          >
            条件をクリア
          </Button>
          <Button
            variant="contained"
            size="small"
            startIcon={<SearchIcon />}
            sx={{ width: BUTTON_WIDTH }}
            onClick={() => {
              onClickSearch(searchParams);
            }}
          >
            検索する
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
