import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function RhythmHelpDialog() {
  return (
    <HelpButton dialogTitle="リズム">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "リズムを整える目的",
            content:
              "休日に睡眠不足を解消しようとして朝寝坊をすると、体内時計がずれてしまいます。これにより睡眠・覚醒リズムが乱れてしまい、日中の眠気が強くなったり、休日に寝付くことができる時間が遅くなってしまい翌週の平日に影響を与えてしまいます。",
          },
          {
            title: "おすすめ",
            content:
              "平日・休日ともにほぼ決まった時間に起きるようにしましょう。朝寝坊する場合でも2時間以上ずれないようにする、睡眠不足は適切な時間帯の仮眠で補う、朝起きたら太陽の光をしっかり浴びる、などで睡眠・覚醒リズムを整えやすくなります。",
          },
        ]}
      />
    </HelpButton>
  );
}
