import { ComprehensiveEvaluation as ComprehensiveEvaluationObject } from "@/sleep_compass_lite/data_transfer_objects/target/report/ComprehensiveEvaluation";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { ComprehensiveEvaluation } from "@/sleep_compass_lite/domain_models/target/ComprehensiveEvaluation";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export { ComprehensiveEvaluation } from "@/sleep_compass_lite/domain_models/target/ComprehensiveEvaluation";

/** useComprehensiveEvaluation getEvaluation 型宣言 */
type GetComprehensiveEvaluation = (
  surveyInfoUid: string
) => Promise<ComprehensiveEvaluation>;

/***
 * 評価(レポートに使用)を取得するクロージャーを返すカスタムフック。カスタムフックで実装している理由は、useAxios(カスタムフック)を利用するため。
 */
function useLiteComprehensiveEvaluation() {
  const axios = useAxios();

  /**
   * 評価を取得する
   * @param surveyInfoUid サーベイ情報のUUID
   * @returns 評価
   */
  const getEvaluation: GetComprehensiveEvaluation = useCallback(
    async (surveyInfoUid) => {
      try {
        const response = await axios.get<ComprehensiveEvaluationObject>(
          `/api/lite/report/${surveyInfoUid}/comprehensive_evaluation/`
        );
        return ComprehensiveEvaluation.create(response.data);
      } catch (e) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  return { getEvaluation };
}

/**
 * URLパラメータから取得した surveyInfoUid を使用して {@link ComprehensiveEvaluation 評価} を取得するカスタムフック
 *
 * @returns 評価
 */
export function useComprehensiveEvaluation(): [
  ComprehensiveEvaluation | undefined,
  unknown
] {
  const { surveyInfoUid } = useParams();
  const { getEvaluation } = useLiteComprehensiveEvaluation();
  const [evaluation, setEvaluation] = useState<ComprehensiveEvaluation>();
  const [evaluationError, setEvaluationError] = useState<unknown>();

  useEffect(() => {
    (async () => {
      if (surveyInfoUid == null) return;
      try {
        const evaluation = await getEvaluation(surveyInfoUid);
        setEvaluation(evaluation);
      } catch (e: unknown) {
        setEvaluationError(e);
      }
    })();
  }, [getEvaluation, surveyInfoUid]);

  return [evaluation, evaluationError];
}
