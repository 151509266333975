import { styled } from "@mui/material";

export const SpeechBalloon = styled("div")({
  position: "relative",
  display: "inline-block",
  margin: "0 0 0 16px",
  padding: "16px 16px",
  background: "#FFFFFF",
  boxSizing: "border-box",
  borderRadius: "0px 8px 8px 8px",
  "&:before": {
    content: '""',
    position: "absolute",
    top: "4px",
    left: "-8px",
    marginTop: "-4px",
    borderTop: "4px solid #FFFFFF",
    borderRight: "4px solid #FFFFFF",
    borderBottom: "4px solid transparent",
    borderLeft: "4px solid transparent",
    zIndex: 2,
  },
});
