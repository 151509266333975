import { NonFieldError, SuccessAlert } from "@/components/common/SCAlert";
import { SCLink } from "@/components/common/SCLink";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import {
  EmailAddress,
  EmailAddressValidationError,
} from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import { interviewPath } from "@/sleep_compass_lite/domain_models/target/InterviewPath";
import {
  Password,
  PasswordLenientValidationError,
} from "@/sleep_compass_lite/domain_models/target/Password";
import { StateType as ForgotPasswordState } from "@/sleep_compass_lite/pages/target/authentication/ForgotPassword";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { signInForSecond } from "@/sleep_compass_lite/use_cases/target/authentication/SignInForSecond";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  surveyInfo: string;
  successMessage: string;
};

/**
 * 2回目以降の登録用ページ
 */
export function SignInForSecond() {
  const location = useLocation();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState<EmailAddress>(new EmailAddress(""));
  const [password, setPassword] = useState<Password>(new Password(""));

  const EMAIL_VALIDATION_ERROR_MESSAGES: Map<
    EmailAddressValidationError,
    string
  > = new Map([["FormatError", "メールアドレスの形式が正しくありません"]]);
  const PASSWORD_VALIDATION_ERROR_MESSAGES: Map<
    PasswordLenientValidationError,
    string
  > = new Map([["InvalidCharsError", "使用できない文字が含まれています"]]);

  const { surveyInfo, successMessage } = (location.state as StateType) || {};
  const passwordValidationErrors = password.lenientValidate();
  const emailValidationErrors = email.validate();
  const hasErrors =
    passwordValidationErrors.length !== 0 || emailValidationErrors.length !== 0;

  const handleClickLogin = async () => {
    if (hasErrors) {
      // 何かしらのエラーがある場合はログイン処理を行わない
      return;
    }

    try {
      await signInForSecond(surveyInfo, email, password);

      navigate(
        interviewPath.getFullPath("PrimaryInterviewIntroduction", {
          ":surveyInfoUid": surveyInfo,
        })
      );
    } catch (e: unknown) {
      setErrorMessage(getErrorMessage(e));
      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    }
  };

  if (!surveyInfo) {
    // 遷移先がないエラーメッセージ画面を表示する。本来なら、やり直すために、Greetings画面に遷移させたいが、surveyInfoがなく、Greetings画面のURLが分からないので、遷移先をなしにする。
    return <GeneralError to="" />;
  }

  return (
    <>
      <ScrollToTop />
      <Stack spacing={12} sx={{ pt: 8, pb: 16, px: PX4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            ログイン
          </Typography>
          {/* パスワード再設定直後に表示されるメッセージ */}
          <SuccessAlert>{successMessage}</SuccessAlert>
          {/* エラーメッセージ */}
          <NonFieldError>{errorMessage}</NonFieldError>
          <Typography
            variant="subtitle2"
            textAlign="justify"
            sx={{ color: "text.secondary" }}
          >
            以下のフォームに前回設定したメールアドレス、パスワードを入力し、[ログイン]ボタンを押してください。
          </Typography>
          <TextForm
            title="メールアドレス"
            placeholder="例：sleep-compass@example.com"
            value={email.value}
            onChange={(e) => {
              setEmail(new EmailAddress(e.target.value));
            }}
            validator={() =>
              emailValidationErrors.map(
                (e) => `※${EMAIL_VALIDATION_ERROR_MESSAGES.get(e)}`
              )
            }
          />
          <TextForm
            title="パスワード"
            value={password.value}
            onChange={(e) => {
              setPassword(new Password(e.target.value));
            }}
            type="password"
            givenErrors={passwordValidationErrors
              .filter((e) => e !== "EmptyError")
              .map((e) => `※${PASSWORD_VALIDATION_ERROR_MESSAGES.get(e)}`)}
          />
        </Stack>
        <Stack spacing={4}>
          <Button
            variant="contained"
            size="large"
            sx={{ boxShadow: "none" }}
            onClick={handleClickLogin}
            disabled={hasErrors}
          >
            ログイン
          </Button>
          <SCLink
            variant="body2"
            to={authenticationPath.getFullPath("ForgotPassword")}
            sx={{ color: "text.secondary" }}
            state={
              {
                isSecondUser: true,
                surveyInfo: surveyInfo,
              } as ForgotPasswordState
            }
          >
            パスワードを忘れた場合
          </SCLink>
        </Stack>
      </Stack>
    </>
  );
}
