import { Copyright } from "@/components/examinee/Copyright";
import TimeUnit from "@/utils/TimeUnit";
import { formatDisplayTime } from "@/utils/date";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import RangeChart from "../commons/RangeChart";
import ReportDialog from "../commons/ReportDialog";
import { ReportImg } from "../commons/ReportImg";
import { useSummaryPageContext } from "./Summary";
import { TeacherComment } from "./commons";

/**
 * 睡眠の長さ
 *
 * @param param0
 * @returns
 */
export default function SleepQuantity() {
  const context = useSummaryPageContext();
  const [openInitDialog, setOpenInitDialog] = useState(context.isFirstVisit);
  const theme = useTheme();
  return (
    <Stack
      sx={{
        p: "24px 16px 80px 16px",
        gap: "24px",
        height: "100%",
      }}
    >
      <SleepQuantityDialog open={openInitDialog} setOpen={setOpenInitDialog} />
      <Stack
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          p: "16px",
          gap: "16px",
        }}
      >
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="h6">睡眠の長さ</Typography>
          <Stack direction="row" sx={{ marginLeft: "auto" }}>
            <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
              <ReportImg
                style={{ width: "8px", height: "8px" }}
                image_name="image_report_summary_chart_position_mark.svg"
              />
            </Box>
            <Typography variant="caption">あなたの結果</Typography>
            <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
              <ReportImg
                style={{ width: "8px", height: "8px", marginLeft: "16px" }}
                image_name="image_report_summary_chart_reference_area.svg"
              />
            </Box>
            <Typography variant="caption">推奨範囲</Typography>
          </Stack>
          <Stack sx={{ mt: "8px", mb: "24px" }}>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              評価項目1
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="justify"
              sx={{ color: "text.primary" }}
            >
              あなたの睡眠時間(平均)
            </Typography>
            <Stack>
              <RangeChart
                max={{
                  value: TimeUnit.HOURS.toMinutes(24),
                  label: "24時間",
                }}
                min={{ value: 0, label: "0時間" }}
                recommendFrom={{
                  value:
                    context.props.data.sleep_quantity.evaluation_1
                      .standard_value.from,
                  label: formatDisplayTime(
                    context.props.data.sleep_quantity.evaluation_1
                      .standard_value.from
                  ),
                }}
                recommendTo={{
                  value:
                    context.props.data.sleep_quantity.evaluation_1
                      .standard_value.to,
                  label: formatDisplayTime(
                    context.props.data.sleep_quantity.evaluation_1
                      .standard_value.to
                  ),
                }}
                plot={{
                  value: context.props.data.sleep_quantity.evaluation_1.value,
                  label: formatDisplayTime(
                    context.props.data.sleep_quantity.evaluation_1.value
                  ),
                  attr: (() =>
                    context.props.data.sleep_quantity.evaluation_1
                      .standard_value.from <=
                      context.props.data.sleep_quantity.evaluation_1.value &&
                    context.props.data.sleep_quantity.evaluation_1.value <=
                      context.props.data.sleep_quantity.evaluation_1
                        .standard_value.to
                      ? {}
                      : { fill: theme.palette.error.main })(),
                }}
              />
            </Stack>
          </Stack>

          <Stack>
            <Typography
              variant="subtitle2"
              textAlign="justify"
              sx={{ color: "text.secondary" }}
            >
              評価項目2
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="justify"
              sx={{ color: "text.primary" }}
            >
              {context.props.data.sleep_quantity.evaluation_2.evaluation_axis}
              時間未満の睡眠時間があった日数
            </Typography>
            <Stack sx={{ mt: "8px" }}>
              <RangeChart
                min={{ value: 0, label: "0日間", attr: { fill: "#00A99D" } }}
                max={{ value: 7, label: "7日間" }}
                plot={{
                  value: context.props.data.sleep_quantity.evaluation_2.days,
                  label: `${context.props.data.sleep_quantity.evaluation_2.days}日間`,
                  attr: (() =>
                    context.props.data.sleep_quantity.evaluation_2.days > 0
                      ? { fill: theme.palette.error.main }
                      : {})(),
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ mb: "48px" }}>
        <TeacherComment>
          {context.props.data.sleep_quantity.comment}
        </TeacherComment>
      </Stack>
      <Stack
        sx={{
          pb: "10px",
          mb: "80px",
          position: "sticky",
          bottom: 0,
          zIndex: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={() => context.setScreenType("sleep_quality")}
          sx={{ boxShadow: "none" }}
        >
          次へ
        </Button>
      </Stack>
      <Stack
        sx={{
          position: "sticky",
          alignItems: "center",
        }}
      >
        <Copyright />
      </Stack>
    </Stack>
  );
}

type SleepQuantityDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

/**
 * 睡眠と日中の記録の初期表示ダイアログ
 *
 * @param param0
 * @returns
 */
function SleepQuantityDialog({ open, setOpen }: SleepQuantityDialogProps) {
  return (
    <ReportDialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <Stack>
        <Stack sx={{ p: "16px" }}>
          <Stack direction="row" sx={{ gap: "8px" }}>
            <Typography variant="subtitle1" sx={{ color: "primary.dark" }}>
              睡眠の長さとは
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ background: "#EBF1F7", p: "16px" }}>
          <ReportImg image_name="image_report_summary_what_is_quantity.svg" />
        </Stack>
        <Stack sx={{ p: "16px", gap: "8px" }}>
          <Typography variant="body2" textAlign="justify">
            あなたが眠りについてから目が覚めるまでの時間を評価します。
          </Typography>
          <Box textAlign="center" sx={{ pt: "16px" }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "18px",
                p: "6px 32px 6px 32px",
                boxShadow: "none",
              }}
              onClick={() => setOpen(false)}
            >
              結果を見る
            </Button>
          </Box>
        </Stack>
      </Stack>
    </ReportDialog>
  );
}
