import { Box, Stack, Typography } from "@mui/material";

export function TermsOfService() {
  return (
    <Stack spacing={16}>
      <Typography variant="caption" color="text.primary">
        本規約は、株式会社ACCELStars（以下「当社」といいます。）が提供する睡眠チェックサービス（以下「スリープコンパスライト」といいます。）およびその関連サービス（以下総称して「本サービス」といいます。）の利用等の諸条件を定めるものであり、本サービスの一部又は全部をご利用いただく際の利用者（以下「会員」といいます。）と、当社との関係に適応されます。
      </Typography>
      <Stack spacing={2}>
        <Typography variant="subtitle2" color="text.primary">
          1 適用
        </Typography>
        <Typography variant="caption" color="text.primary">
          本規約は、本サービスの利用に関する当社と会員との間の一切の関係に適用されます。本利用規約の内容に同意しない場合、本サービスを利用することはできません。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">2 本規約の変更</Typography>
        <Typography variant="caption" color="text.primary">
          当社が会員規約の変更が必要と判断した場合は、本規約を変更することができるものとします。本規約を変更した場合、料金その他の本サービスに関する事項については変更後の本規約が適用されます。本規約の変更に同意いただけない場合には本サービスの一部又は全部について利用できなくなる場合があることにつきご了承ください。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">3 定義</Typography>
        <Typography variant="caption" color="text.primary">
          本規約において使用する用語の定義は以下のとおりとします。
        </Typography>
        <Typography variant="caption" color="text.primary">
          3.1
          「会員」とは、本規約を承認の上、当社が運営する本サービス利用のために会員として入会を申し込み、当社が入会を認めた者のことをいいます。
        </Typography>
        <Typography variant="caption" color="text.primary">
          3.2
          「個人情報」とは、それぞれ個人情報の保護に関する法律（平成15年法律第57号。以下「個人情報保護法」といいます。）に定める個人情報及び個人データをいいます。
        </Typography>
        <Typography variant="caption" color="text.primary">
          3.3
          「会員情報」とは、本サービスにおいて、当社が取得し、利用する以下の情報です。
        </Typography>
        <Box>
          <Stack sx={{ ml: 4 }}>
            <Typography variant="caption" color="text.primary">
              3.3.1
              会員、会員が加入する健康保険組合、自治体又は勤務先等が本サービスへ登録する氏名、勤務先、部署、生年月日、メールアドレス（以下「会員基本情報」といいます。）
            </Typography>
            <Typography variant="caption" color="text.primary">
              3.3.2
              会員が本サービスへ登録する問診の回答（生活習慣情報、健康記録に関する情報含む）、その他当社が定める情報（以下「会員問診情報」といいます。）
            </Typography>
            <Typography variant="caption" color="text.primary">
              3.3.3
              会員の同意のもと、健康保険組合、自治体又は勤務先等から受領した健康診断、ストレスチェック、勤怠情報等の結果、診療報酬明細書（以下「会員関連情報」といいます。）
            </Typography>
            <Typography variant="caption" color="text.primary">
              3.3.4
              健康保険組合、自治体又は勤務先等が実施する保健指導の提供にかかる情報（以下「保健指導情報」といいます。）
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">4 サービスの利用</Typography>
        <Typography variant="caption" color="text.primary">
          4.1
          当社は、別途ご提供頂く会員の睡眠時に関する情報に基づき本サービスを実施いたします。また、これらの情報につきましては、本サービス実施後も本サービスに使用するアルゴリズム等の改善、精度向上、不具合対応及び当社における研究開発等に活用します。
        </Typography>
        <Typography variant="caption" color="text.primary">
          4.2 本サービスでは、睡眠との関係をみるためにThe
          K6を使用しています。The K6の著作権はWorld Health Organization
          2007に帰属します。
        </Typography>
        <Typography variant="caption" color="text.primary">
          4.3
          会員は当社が当社の定める情報に含まれる病歴その他の要配慮個人情報を取得することに同意するものとします。
        </Typography>
        <Typography variant="caption" color="text.primary">
          4.4
          本サービスにかかる技術及び研究の進展によって、将来、分析項目の追加・変更または提供する情報の追加・変更が生じたり、結果やその評価に変更が生じる可能性があります。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">5 入会</Typography>
        <Typography variant="caption" color="text.primary">
          5.1
          会員になろうとする方は、本規約の内容を確認し、同意の上、当社の定める手続きにより利用申込を行います。
        </Typography>
        <Typography variant="caption" color="text.primary">
          5.2
          会員になろうとする方に、当社は、専用申込URLもしくは初期ログインIDと初期パスワードを当社所定の方法にて付与させていただきます。専用申込URL、初期ログインID、初期パスワードを第三者に使用または利用させたり、貸与、譲渡、売買、質入等してはなりません。
        </Typography>
        <Typography variant="caption" color="text.primary">
          5.3
          会員が未成年者である場合、親権者その他の法定代理人の同意を得たうえで、本サービスを利用することができます。
        </Typography>
        <Typography variant="caption" color="text.primary">
          5.4
          前項までの規定にかかわらず、当社は、当社の判断に基づき会員になろうとする方の入会をお断りすることがあります。この場合、当社は入会をお断りした理由について開示しませんのでご了承ください。
        </Typography>
        <Typography variant="caption" color="text.primary">
          5.5
          当社は、会員が本規約に違反したと判断した場合、本サービスが不正利用される疑いを認めた場合、その他当社が不適当だと判断した場合、本サービスの提供を解除することができます。
        </Typography>
        <Typography variant="caption" color="text.primary">
          5.6
          会員は会員資格を第三者に利用させたり、貸与、譲渡、売買、質入等してはなりません。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">6 IDおよびパスワードの管理</Typography>
        <Typography variant="caption" color="text.primary">
          6.1 会員は、ID及びパスワードの管理責任を負います。
        </Typography>
        <Typography variant="caption" color="text.primary">
          6.2
          会員は、ID及びパスワードを第三者に使用または利用させたり、貸与、譲渡、売買、質入等してはなりません。なお、当社はID及びパスワードを入力した方を会員本人とみなして本規約を適用します。
        </Typography>
        <Typography variant="caption" color="text.primary">
          6.3
          会員がID及びパスワードを入力した場合、ID及びパスワードの入力後当社の定める時間を経過するまでの間（以下、「利用可能時間」といいます。）、会員は、ID及びパスワードを入力する事なく、本サービスにおいて、当社の定める利用方法による利用ができます。会員は、利用可能時間中、ID及びパスワードを入力した装置を第三者に利用させたり、貸与、譲渡、売買、質入等してはなりません。
        </Typography>
        <Typography variant="caption" color="text.primary">
          6.4
          ID及びパスワード並びにID及びパスワードを入力した装置の管理不十分、使用上の過誤、第三者の使用等による損害の責任は、当社の責に帰すべき事由による場合を除き、会員が負うものとします。
        </Typography>
        <Typography variant="caption" color="text.primary">
          6.5
          会員は、パスワードを第三者に知られた場合、利用可能時間中のID及びパスワードを入力した装置を第三者に使用されるおそれのある場合、またはIDまたはパスワードが第三者に使用されている疑いのある場合には、直ちに当社にその旨連絡するとともに、当社の指示がある場合にはこれに従うものとします。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">7 結果レポートの提供</Typography>
        <Typography variant="caption" color="text.primary">
          7.1
          当社は、本サービスを通じて得られた結果を、退会・サービスの廃止その他当社の定める場合を除き、本サービスのウェブサイト及びアプリケーション、紙面上で当社の定める方法により会員に対して提供します。
        </Typography>
        <Typography variant="caption" color="text.primary">
          7.2 以下の場合は、本サービスの結果を提供することができません。
        </Typography>
        <Box>
          <Stack sx={{ ml: 4 }}>
            <Typography variant="caption" color="text.primary">
              当社の定める方法により、問診回答を全て行われなかった。
            </Typography>
          </Stack>
        </Box>
        <Typography variant="caption" color="text.primary">
          7.3
          当社は、会員が本規約に違反した場合、その他本サービスが不正利用されている場合その他不適当な場合、本サービスを通じて得られた結果を提供しないことができます。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">8 会員記述情報</Typography>
        <Typography variant="caption" color="text.primary">
          8.1
          会員記述情報とは、本サービス内に会員が記述した情報及び本サービスに関して当社と会員との間でメール等によりやりとりされる情報をいいます。会員記述情報に対しては、これを記述した会員が責任を負うものとします。会員は以下の情報を記述することはできません。
        </Typography>
        <Box>
          <Stack sx={{ ml: 4 }}>
            <Typography variant="caption" color="text.primary">
              8.1.1 真実でないもの
            </Typography>
            <Typography variant="caption" color="text.primary">
              8.1.2 他人の名誉または信用を傷つけるもの
            </Typography>
            <Typography variant="caption" color="text.primary">
              8.1.3
              特許権、実用新案権、意匠権、商標権、著作権、肖像権その他の他人の権利を侵害するもの
            </Typography>
            <Typography variant="caption" color="text.primary">
              8.1.4 コンピューターウイルスを含むもの
            </Typography>
            <Typography variant="caption" color="text.primary">
              8.1.5 公序良俗に反するもの
            </Typography>
            <Typography variant="caption" color="text.primary">
              8.1.6 法令に違反するもの
            </Typography>
            <Typography variant="caption" color="text.primary">
              8.1.7 その他不適当なもの
            </Typography>
          </Stack>
        </Box>
        <Typography variant="caption" color="text.primary">
          8.2
          当社は、会員記述情報が本規約に違反する場合、または、不適当と当社が判断した場合には、会員記述情報を削除することができます。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">
          9 本サービスにおける情報の利用
        </Typography>
        <Typography variant="caption" color="text.primary">
          9.1 当社は、会員情報を当社が定める個人情報取扱規定（
          <a href="https://www.accelstars.com/security-policy/">
            https://www.accelstars.com/security-policy/
          </a>
          ）に従って適切に取り扱います。
        </Typography>
        <Typography variant="caption" color="text.primary">
          9.2
          前項に定めるほか、会員基本情報を以下の目的で利用することができるものとします。
        </Typography>
        <Box>
          <Stack sx={{ ml: 4 }}>
            <Typography variant="caption" color="text.primary">
              9.2.1 本サービスの提供のため
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.2.2
              当社及び第三者の商品等の販売、販売の勧誘、発送、サービス提供のため
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.2.3
              当社及び第三者の商品等の広告または宣伝（電子メールの送信を含む。）のため
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.2.4 本人確認、認証サービスのため
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.2.5
              会員、健康保険組合、自治体又は勤務先からの問い合わせに対応するため
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.2.6 サービス提供先への対応のため
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.2.7 アンケートの実施のため
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.2.8 マーケティングデータの調査、統計、分析のため
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.2.9 研究協力のお願いのため
            </Typography>
          </Stack>
        </Box>
        <Typography variant="caption" color="text.primary">
          9.3 本サービスを利用する場合には、プライバシーポリシー（
          <a href="https://www.accelstars.com/privacy-policy/">
            https://www.accelstars.com/privacy-policy/
          </a>
          ）の各規定に従うことを承諾したものとみなします。
        </Typography>
        <Typography
          variant="caption"
          color="text.primary"
          sx={{ fontWeight: "bold" }}
        >
          9.4
          会員情報および結果レポートは、会員が本サービスに対して個人申込された場合を除き、会員が加入する健康保険組合、自治体又は勤務先等から要請があった場合、共有できるものとします。
        </Typography>
        <Typography variant="caption" color="text.primary">
          9.5
          当社は、以下に定める場合には、会員情報を第三者に提供することができるものとします。
        </Typography>
        <Box>
          <Stack sx={{ ml: 4 }}>
            <Typography variant="caption" color="text.primary">
              9.5.1 会員の同意がある場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.5.2
              裁判所、検察庁、警察、税務署、弁護士会またはこれらに準じた権限を有する機関から開示を求められた場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.5.3
              人（法人を含む）の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.5.4
              公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.5.5
              国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.5.6
              学術研究機関等に対して、学術研究目的で提供または取り扱いが必要な場合（当社と第三者が共同して学術研究を行う場合に限る。）
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.5.7 当社が行う業務の全部または一部を第三者に委託する場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.5.8
              合併、営業譲渡その他の事由による事業の承継の際に、事業を承継する者に対して開示する場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.5.9 個人情報保護法その他の法令により認められた場合
            </Typography>
          </Stack>
        </Box>
        <Typography variant="caption" color="text.primary">
          9.6
          当社は、会員情報に基づく匿名加工情報（特定の個人を識別することや個人情報を復元することができないように加工した個人に関する情報）を以下の利用及び提供することができるものとします。なお当社は匿名加工情報の作成にあたって、法令等に基づいた適切な加工を講じるものとします。
        </Typography>
        <Box>
          <Stack sx={{ ml: 4 }}>
            <Typography variant="caption" color="text.primary">
              9.6.1
              マーケティング活動、サービスの精度向上、研究開発、不具合対応等に利用
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.6.2 当社以外の医療・健康に関するサービス開発者・提供者への提供
            </Typography>
            <Typography variant="caption" color="text.primary">
              9.6.3 学術研究機関（学会に所属する個人を含む）への提供
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">10 会員規約の違反等</Typography>
        <Typography variant="caption" color="text.primary">
          10.1
          会員が以下の各号のいずれかに該当した場合、当社は、当社の定める期間、本サービス及び当社が提供するその他のサービスの全部もしくは一部の利用を認めないこと、または、会員の会員資格を取り消すことができます。ただし、この場合も当社が既に受領した料金等その他の金員は返還しません｡
        </Typography>
        <Box>
          <Stack sx={{ ml: 4 }}>
            <Typography variant="caption" color="text.primary">
              10.1.1
              会員登録申込みの際の個人情報登録、及び会員となった後の個人情報変更において、その内容に虚偽や不正があった場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              10.1.2 本規約に違反した場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              10.1.3 会員が真意に基づかずに入会の申込みを行った場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              10.1.4 他の会員に不当に迷惑をかけた場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              10.1.5 会員と連絡が取れない場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              10.1.6
              会員が、暴力団その他これらに類する団体、組織に現在関与し、あるいは過去に関与していた場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              10.1.7
              本サービスが不正利用されている疑いがある場合その他不適当な場合
            </Typography>
            <Typography variant="caption" color="text.primary">
              10.1.8
              当社が会員資格を取り消した会員は再入会することはできません。
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">11 サービスの提供条件</Typography>
        <Typography variant="caption" color="text.primary">
          11.1
          当社は、メンテナンス等のために、会員に通知することなく、本サービスを停止し、または変更することがあります。
        </Typography>
        <Typography variant="caption" color="text.primary">
          11.2
          本サービスの提供を受けるために必要な機器、通信手段及び電力等は、会員の費用と責任で備えるものとします。
        </Typography>
        <Typography variant="caption" color="text.primary">
          11.3
          当社は、本サービスに中断、中止その他の障害が生じないことを保証しません。
        </Typography>
        <Typography variant="caption" color="text.primary">
          11.4
          当社は、当社が提供するウェブサイト及びアプリケーションを現状有姿で提供するものであり、当該ウェブサイト及びアプリケーションが正常に動作すること及び当該ウェブサイト及びアプリケーションに瑕疵のないことを保証しません。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">12 禁止事項</Typography>
        <Typography variant="caption" color="text.primary">
          12.1 会員は、以下の行為を行ってはなりません。
        </Typography>
        <Box>
          <Stack sx={{ ml: 4 }}>
            <Typography variant="caption" color="text.primary">
              12.1.1
              会員の権利および義務を第三者に譲渡、承継、貸与、質入等の行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.2
              スリープコンパスライトを含む本サービスを商用またはその準備の目的で利用する行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.3 他人のデータを当社に送付する行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.4
              当社が提供するサービス、当社が保有するサーバー及びこれらが生成する情報、通信内容等の解読、解析、逆コンパイル、逆アセンブルまたはリバースエンジニアリング、またはその他の方法でソースコードを抽出する行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.5
              本サービスのネットワーク又はシステム等に過度な負担をかける行為及び改変する行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.6
              他人の個人情報、本サービスを通じて得られた結果または会員記述情報を違法、不適切に収集、開示その他不正な目的で利用する行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.7
              他の個人または団体になりすまし、または他の個人または団体と関係があるように不当に見せかける行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.8 他の会員のＩＤ、パスワードを入手しようとする行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.9
              迷惑メール、チェーンメール、コンピューターウイルス等の不適切なデータを送信する行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.10 ボット等の自動化された手段を用いて本サービスを利用する行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.11 本サービスを変更または妨げることを目的に利用する行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.12
              第三者または当社の知的財産権を侵害または侵害するおそれのある行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.13 詐欺的行為をする行為
            </Typography>
            <Typography variant="caption" color="text.primary">
              12.1.14 その他当社が不適当と判断した行為
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">13 当社の責任</Typography>
        <Typography variant="caption" color="text.primary">
          13.1
          当社は、本サービスの内容、並びに会員が本サービスを通じて入手した情報等について、その完全性、正確性、確実性等につき、責任を負いません｡
        </Typography>
        <Typography variant="caption" color="text.primary">
          13.2
          会員は法律の範囲内で本サービスを利用するものとします。本サービスの利用に関連して会員が日本及び外国の法律に触れた場合でも、当社は責任を負いません。
        </Typography>
        <Typography variant="caption" color="text.primary">
          13.3
          会員は、本サービスを利用する際に発生するあらゆるリスクについて、自己の責任において利用するものとします。当社に故意または重過失が存する場合を除き、本サービスに関連するトラブル、損害、負傷、疾病その他の健康上の問題について、一切の責任を負いません。
        </Typography>
        <Typography variant="caption" color="text.primary">
          13.4
          当社は、何らかの理由によって責任を負う場合にも、通常生じうる損害の範囲内かつ個人情報の漏洩については会員一人当たり1万円以下、その他の損害については20万円以下の範囲内においてのみ賠償の責任を負うものとします。
        </Typography>
        <Typography variant="caption" color="text.primary">
          13.5
          通信回線およびシステムの機器等の障害（不可抗力によるものを含みます。）、通信速度の低下、通信回線の混雑、コンピューターウイルスや第三者による妨害、侵入、情報改変等によって生じた障害について、その損害が当社の故意または重過失による場合を除いて、当社および会員は一切責任を負いません。
        </Typography>
        <Typography variant="caption" color="text.primary">
          13.6
          当社の製造にかからないハードウェア、ソフトウェアおよびデータベース並びに当社以外の第三者が提供するサービスに起因して発生した損害について、その損害が当社の故意または重過失による場合を除いて、一切の責任を負いません。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">14 保証</Typography>
        <Typography variant="caption" color="text.primary">
          14.1
          当社は提供するスリープコンパスライトを含む本サービスの内容、品質および本サービスの安定的な提供については一切保証しません。
        </Typography>
        <Typography variant="caption" color="text.primary">
          14.2
          当社は会員に対して結果レポートをもとにアドバイスを行うことがありますが、それらに対して責任を負わないものとします。また、そのアドバイスや情報提供の正確性や有用性を保証しません。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">15 サービス廃止</Typography>
        <Typography variant="caption" color="text.primary">
          当社は当社の判断により本サービスを廃止できるものとします。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">16 退会</Typography>
        <Typography variant="caption" color="text.primary">
          16.1 会員は、当社の定める手続きにより退会することができます。
        </Typography>
        <Typography variant="caption" color="text.primary">
          16.2 退会した場合、会員は、本サービスを利用することができません。
        </Typography>
        <Typography variant="caption" color="text.primary">
          16.3
          当社は会員が退会した場合、当社が保管している当該会員に関する情報の一部または全部を抹消することができます。
        </Typography>
        <Typography variant="caption" color="text.primary">
          16.4
          当社は会員が退会した場合、当社が保管している当該会員に関する情報をマーケティング活動、サービスの精度向上、研究開発、不具合対応等に利用する場合があります。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle1">17 データの保存</Typography>
        <Typography variant="caption" color="text.primary">
          当社は、個別プライバシーポリシーで別段の定めがある場合を除き、外国に所在するサーバ等において情報を保管することはありません。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">18 準拠法</Typography>
        <Typography variant="caption" color="text.primary">
          本規約に関する準拠法は日本法とします。
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle2">19 管轄裁判所</Typography>
        <Typography variant="caption" color="text.primary">
          本サービスに関し、会員と当社との間で訴訟が生じた場合、東京地方裁判所を第一審の専属的管轄裁判所とします。
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="caption" color="text.primary">
          以上
        </Typography>
        <Typography variant="caption" color="text.primary">
          2024年2月26日制定
        </Typography>
        <Typography variant="caption" color="text.primary">
          2024年7月31日改定
        </Typography>
      </Stack>
    </Stack>
  );
}
