import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import HelpButton from "../HelpButton";
import AnnotationTypography from "./criterions/AnnotationTypography";
import CriterionTableContainer, {
  Cells,
} from "./criterions/CriterionTableContainer";

export default function SleepConditionCriterionDialog() {
  return (
    <HelpButton
      dialogTitle="睡眠の状態の判定基準"
      dialogProps={{
        maxWidth: "lg",
      }}
    >
      <Stack sx={{ mb: 6 }}>
        <Typography variant="subtitle1">睡眠の状態</Typography>
        <Typography variant="body1">
          睡眠を「長さ」、「質」、「リズム」の3つの項目を組み合わせて評価します。
        </Typography>
      </Stack>
      <CriterionTableContainer
        sx={{
          "& td.MuiTableCell-root:nth-child(2)": {
            backgroundColor: Cells.color.good,
          },
          "& td.MuiTableCell-root:nth-child(3)": {
            backgroundColor: Cells.color.caution,
          },
          "& td.MuiTableCell-root:nth-child(4)": {
            backgroundColor: Cells.color.bad,
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="subtitle2">判定の仕組み</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ boxSizing: "border-box", width: "200px" }}>
                <Typography variant="subtitle2">睡眠の長さ</Typography>
                <Typography variant="caption">良好/注意/警戒</Typography>
              </TableCell>
              <TableCell
                rowSpan={3}
                sx={{ boxSizing: "border-box", width: "232px" }}
              >
                <Typography variant="body2">3項目とも「良好」の場合</Typography>
              </TableCell>
              <TableCell
                rowSpan={3}
                sx={{ boxSizing: "border-box", width: "232px" }}
              >
                <Typography variant="body2">
                  いずれか1項目のみが
                  <br />
                  「注意」の場合
                </Typography>
              </TableCell>
              <TableCell
                rowSpan={3}
                sx={{ boxSizing: "border-box", width: "232px" }}
              >
                <Typography variant="body2">
                  左記のパターンに
                  <br />
                  当てはまらない場合
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">睡眠の質</Typography>
                <Typography variant="caption">良好/注意/警戒</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">睡眠のリズム</Typography>
                <Typography variant="caption">良好/注意</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">判定</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">良好</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">やや課題あり</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">課題あり</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CriterionTableContainer>
      <AnnotationTypography>
        ※測定データが4日に満たない場合は判定不能。
      </AnnotationTypography>
    </HelpButton>
  );
}
