import { ScrollToTop } from "@/components/common/ScrollToTop";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { TermsOfServiceBase as Terms } from "@/sleep_compass_lite/components/target/TermsOfServiceBase";
import { Stack } from "@mui/material";

/**
 * 利用規約ページ
 * 利用規約同意ページとほぼ同じだが、こちらのページは同意ボタンが表示されない
 */
export function TermsOfService() {
  return (
    <>
      <ScrollToTop />
      <Stack spacing={8} sx={{ pt: 8, pb: 18, px: PX4 }}>
        <Terms />
      </Stack>
    </>
  );
}
