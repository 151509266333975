import { Element, Rect, SVG, Text } from "@svgdotjs/svg.js";
import { useCallback, useLayoutEffect, useState } from "react";

/**
 * 睡眠のグラフを描画します。
 *
 */
export class Chart {
  private readonly svg = SVG();

  private minLabel?: Text;
  private maxLabel?: Text;
  private fromLabel?: Text;
  private toLabel?: Text;
  private chartArea?: Rect;
  private recommendArea?: Rect;
  private star?: Element;
  private plotText?: Text;
  private messageText?: Text;

  constructor(
    readonly parent: HTMLElement | null,
    readonly props: RangeChartProps
  ) {
    this.draw();
  }
  /**
   * clearします。
   *
   */
  protected clear() {
    while (this.parent?.firstChild)
      this.parent.removeChild(this.parent.firstChild);
    this.svg.clear();
  }
  /**
   * {@link HTMLElement} にSVGを追加します。
   * @param parent
   */
  protected setUpSVG() {
    if (this.parent == null) return;
    this.svg.width("100%").height("60px").addTo(this.parent);
  }
  /**
   * {@link Text} を作成します。
   *
   * @param text
   * @returns
   */
  protected textOf(text: string) {
    return this.svg.text(text).font({
      family: "Noto Sans JP",
      size: "12px",
      style: "normal",
      weight: 500,
    });
  }
  /**
   * 最小値のラベルを作成します。
   */
  protected createMinLabel() {
    if (this.props.min.label == null) return;
    this.minLabel = this.textOf(this.props.min.label).attr("fill", "#707070");
    if (this.props.min.attr != null) this.minLabel.attr(this.props.min.attr);
  }
  /**
   * 最小値ラベルを移動します。
   * @returns
   */
  protected moveMinLabel() {
    if (this.minLabel == null) return;
    this.minLabel.move(0, 0);
  }
  /**
   * 最大値のラベルを作成します。
   */
  protected createMaxLabel() {
    if (this.props.max.label == null) return;
    this.maxLabel = this.textOf(this.props.max.label).attr("fill", "#707070");
    if (this.props.max.attr != null) this.maxLabel.attr(this.props.max.attr);
  }
  /**
   * 最大値ラベルを移動します。
   *
   * @returns
   */
  protected moveMaxLabel() {
    if (this.maxLabel == null) return;
    const { width } = this.svg.node.getBoundingClientRect();
    const { width: maxLabelWidth } = this.maxLabel.node.getBoundingClientRect();
    this.maxLabel.move(width - maxLabelWidth, 0);
  }
  /**
   * 推奨値の開始ラベルを作成します。
   * @returns
   */
  protected createFromLabel() {
    if (this.props.recommendFrom?.label == null) return;
    this.fromLabel = this.textOf(this.props.recommendFrom.label).attr({
      fill: "#00A99D",
    });
    if (this.props.recommendFrom.attr != null)
      this.fromLabel.attr({
        ...this.fromLabel.attr(),
        ...this.props.recommendFrom.attr,
      });
  }
  /**
   * 推奨値の開始ラベルを移動します。
   *
   * @returns
   */
  protected moveFromLabel() {
    if (this.fromLabel == null || this.props.recommendFrom == null) return;
    const { width } = this.svg.node.getBoundingClientRect();
    const { width: fromWidth } = this.fromLabel.node.getBoundingClientRect();

    const left =
      (width / (this.props.max.value - this.props.min.value)) *
        this.props.recommendFrom.value -
      fromWidth / 2;
    this.fromLabel.move(left, 0);
  }
  /**
   * 推奨値の終了ラベルを作成します。
   *
   * @returns
   */
  protected createToLabel() {
    if (this.props.recommendTo?.label == null) return;

    this.toLabel = this.textOf(this.props.recommendTo.label).attr({
      fill: "#00A99D",
    });
    if (this.props.recommendTo.attr != null)
      this.toLabel.attr({
        ...this.toLabel.attr(),
        ...this.props.recommendTo.attr,
      });
  }
  /**
   * 推奨値の終了ラベルを移動します。
   * @returns
   */
  protected moveToLabel() {
    if (this.toLabel == null || this.props.recommendTo == null) return;
    this.toLabel.rotate(0);

    const { width } = this.svg.node.getBoundingClientRect();
    const { width: toWidth } = this.toLabel.node.getBoundingClientRect();

    const left =
      (width / (this.props.max.value - this.props.min.value)) *
        this.props.recommendTo.value -
      toWidth / 2;
    this.toLabel.move(left, 0);
  }
  /**
   * グラフの長方形を作成します。
   */
  protected createChartArea() {
    const { width } = this.svg.node.getBoundingClientRect();
    this.chartArea = this.svg.rect(width, 40).attr({ fill: "#F6F5F4" });
  }
  /**
   * グラフの長方形を移動します。
   * @returns
   */
  protected moveChartArea() {
    if (this.chartArea == null) return;

    const { width } = this.svg.node.getBoundingClientRect();
    this.chartArea
      .width(width)
      .move(
        0,
        Math.max(
          this.minLabel?.node.getBoundingClientRect().height ?? 0,
          this.maxLabel?.node.getBoundingClientRect().height ?? 0,
          this.fromLabel?.node.getBoundingClientRect().height ?? 0,
          this.toLabel?.node.getBoundingClientRect().height ?? 0
        )
      );
  }
  /**
   * 推奨エリアを作成します。
   *
   * @returns
   */
  protected createRecommendArea() {
    const { width } = this.svg.node.getBoundingClientRect();

    if (this.props.recommendTo == null || this.props.recommendFrom == null) {
      this.recommendArea = this.svg.rect(5, 40).attr({ fill: "#D6F1EF" });
      return;
    }

    this.recommendArea = this.svg
      .rect(
        (width / (this.props.max.value - this.props.min.value)) *
          (this.props.recommendTo.value - this.props.recommendFrom.value),
        40
      )
      .attr({ fill: "#D6F1EF" });
  }
  /**
   * 推奨エリアを移動します。
   *
   * @returns
   */
  protected moveRecommendArea() {
    if (this.recommendArea == null) return;

    const { width } = this.svg.node.getBoundingClientRect();

    if (this.props.recommendFrom == null || this.props.recommendTo == null) {
      // 推奨エリアの指定がない場合、０が推奨とする
      this.recommendArea.move(
        0,
        (this.chartArea?.node.getBoundingClientRect().top ?? 0) -
          this.svg.node.getBoundingClientRect().top
      );
      return;
    }

    this.recommendArea
      .width(
        (width / (this.props.max.value - this.props.min.value)) *
          (this.props.recommendTo.value - this.props.recommendFrom.value)
      )
      .move(
        (width / (this.props.max.value - this.props.min.value)) *
          this.props.recommendFrom.value,
        (this.chartArea?.node.getBoundingClientRect().top ?? 0) -
          this.svg.node.getBoundingClientRect().top
      );
  }
  /**
   * 星を作成します。
   *
   */
  protected createStar() {
    if (this.props.plot == null) return;
    this.star = SVG(
      `<path d="M3.43173 7.59008L3.24455 7.02854C2.88666 5.95489 2.04511 5.11334 0.971455 4.75545L0.40992 4.56827C-0.13664 4.38559 -0.13664 3.61441 0.40992 3.43173L0.971455 3.24455C2.04511 2.88666 2.88666 2.04511 3.24455 0.971455L3.43173 0.40992C3.61441 -0.13664 4.38559 -0.13664 4.56827 0.40992L4.75545 0.971455C5.11334 2.04511 5.95489 2.88666 7.02854 3.24455L7.59008 3.43173C8.13664 3.61441 8.13664 4.38559 7.59008 4.56827L7.02854 4.75545C5.95489 5.11334 5.11334 5.95489 4.75545 7.02854L4.56827 7.59008C4.38559 8.13664 3.61441 8.13664 3.43173 7.59008Z" fill="#0056A0"/>`
    );
    this.svg.add(this.star);
  }
  /**
   * 星を移動します。
   *
   * @returns
   */
  protected moveStar() {
    if (this.star == null || this.props.plot == null) return;
    const { width } = this.svg.node.getBoundingClientRect();
    const { width: starWidth } = this.star.node.getBoundingClientRect();
    const left =
      (width / (this.props.max.value - this.props.min.value)) *
        this.props.plot.value -
      starWidth / 2;
    this.star.move(
      left < 0 ? 0 : left + starWidth > width ? width - starWidth : left,
      (this.chartArea?.node.getBoundingClientRect().top ?? 0) -
        this.svg.node.getBoundingClientRect().top +
        10
    );
  }
  /**
   * 値のラベルを作成します。
   */
  protected createPlotText() {
    if (this.props.plot?.label == null) return;
    this.plotText = this.textOf(this.props.plot.label);
    if (this.props.plot.attr != null)
      this.plotText.attr({
        ...this.plotText.attr(),
        ...this.props.plot.attr,
      });
  }
  /**
   * 値のラベルを移動します。
   * @returns
   */
  protected movePlotText() {
    if (this.plotText == null || this.props.plot == null) return;
    const { width } = this.svg.node.getBoundingClientRect();
    const { width: plotWidth } = this.plotText.node.getBoundingClientRect();

    const left =
      (width / (this.props.max.value - this.props.min.value)) *
        this.props.plot.value -
      plotWidth / 2;
    this.plotText.move(
      left < 0 ? 0 : left + plotWidth > width ? width - plotWidth : left,
      (this.chartArea?.node.getBoundingClientRect().top ?? 0) -
        this.svg.node.getBoundingClientRect().top +
        20
    );
  }
  /**
   * メッセージラベルを作成します。
   */
  protected createMessageText() {
    if (this.props.message == null) return;
    const { text, attr } = this.props.message;
    this.messageText = this.svg.text(text).attr(attr);
  }
  /**
   * メッセージラベルをグラフに対して中央へ移動します。
   */
  protected moveMessageText() {
    if (this.messageText == null || this.chartArea == null) return;
    const { top, width } = this.svg.node.getBoundingClientRect();
    const messageText = this.messageText.node.getBoundingClientRect();
    const chartArea = this.chartArea?.node.getBoundingClientRect();
    this.messageText.move(
      (width - messageText.width) / 2,
      chartArea.top - top + (chartArea.height - messageText.height) / 2
    );
  }
  /**
   * ラベルの位置を調整します。
   */
  protected arrangeLabel() {
    if (this.fromLabel != null && this.toLabel != null) {
      const flabel = this.fromLabel.node.getBoundingClientRect();
      const tlabel = this.toLabel.node.getBoundingClientRect();
      const diff = flabel.left + flabel.width - tlabel.left;
      if (diff > 0) {
        const { left } = this.svg.node.getBoundingClientRect();
        this.fromLabel.move(flabel.left - left - diff / 2, 0);
        this.toLabel.move(tlabel.left - left + diff / 2, 0);
      }
    }
  }
  /**
   * グラフを描画します。
   */
  draw() {
    this.clear();
    this.setUpSVG();

    this.createMinLabel();
    this.createMaxLabel();
    this.createFromLabel();
    this.createToLabel();

    this.createChartArea();
    this.createRecommendArea();

    this.createStar();
    this.createPlotText();
    this.createMessageText();

    this.resize();
  }
  /**
   * グラフをリサイズします。
   */
  resize() {
    this.moveMinLabel();
    this.moveMaxLabel();
    this.moveFromLabel();
    this.moveToLabel();

    this.moveChartArea();
    this.moveRecommendArea();

    this.moveStar();
    this.movePlotText();
    this.moveMessageText();

    this.arrangeLabel();
  }
}

/**
 * 睡眠グラフの値
 */
export type RangeChartValue = {
  value: number;
  label?: string;
  attr?: object;
};
/**
 * 睡眠グラフのプロパティ
 */
export type RangeChartProps = {
  max: RangeChartValue;
  min: RangeChartValue;
  recommendFrom?: RangeChartValue;
  recommendTo?: RangeChartValue;
  plot: RangeChartValue | null;
  message?: {
    text: string;
    attr: object;
  } | null;
};
/**
 * 睡眠グラフを描画します。
 *
 * @param param0 {@link SleepDiaryChartProps}
 * @returns
 */
export default function RangeChart(props: RangeChartProps) {
  const [chart, setChart] = useState<Chart | null>(null);

  const loadChart = useCallback(
    (element: HTMLDivElement | null) => {
      if (element == null) return null;
      setChart(new Chart(element, props));
    },
    [props]
  );

  const resizeChart = useCallback(() => {
    chart?.resize();
  }, [chart]);

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeChart);
    return () => {
      window.removeEventListener("resize", resizeChart);
    };
  }, [loadChart, resizeChart]);
  return <div ref={loadChart} style={{ width: "100%" }}></div>;
}
