import Shadow from "@/utils/shadow";
import {
  Alert,
  AlertProps,
  Snackbar,
  SnackbarCloseReason,
  SnackbarProps,
} from "@mui/material";
import { ReactNode, SyntheticEvent } from "react";

type CloseReason = SnackbarCloseReason | "clickClose";

type CloseHandler = (
  event: SyntheticEvent<Element, Event> | Event | SyntheticEvent<any, Event>,
  reason: CloseReason
) => void;

/**
 * スナックバー
 */
type SCSnackbarProps = {
  children: ReactNode;
  open: SnackbarProps["open"];
  severity: AlertProps["severity"];
  onClose: CloseHandler;
};
export function SCSnackbar({
  children,
  open,
  severity,
  onClose,
}: SCSnackbarProps) {
  const HIDDEN_DURATION = 6000;

  return (
    <Snackbar
      open={open}
      autoHideDuration={HIDDEN_DURATION}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={onClose}
    >
      <Alert
        variant="filled"
        onClose={(event) => onClose(event, "clickClose")}
        severity={severity}
        sx={{ width: "100%", boxShadow: Shadow.snackbarAlert }}
      >
        {children}
      </Alert>
    </Snackbar>
  );
}
