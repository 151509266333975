import { MedicalFacilityUserSearchParams as SearchParams } from "@/components/sleep_checkup_v1/MedicalFacilityUserSearchParams";
import {
  omitTextIfNeeded,
  searchParamsText,
} from "@/components/sleep_checkup_v1/TextService";

/**
 * 施設ユーザーの検索パラメータの設定値をテキストにする
 * @param params 検索パラメータ
 * @returns 検索パラメータの設定値をテキストにした文字列
 */
export function text(params?: SearchParams): string | null {
  if (params == null) {
    return null;
  }

  const SEPARATOR = ",";

  const text = searchParamsText(params, PARAMS_LABEL, SEPARATOR);
  if (!text) {
    return null;
  }

  // Note: テキストが長すぎる場合は三点リーダーをつけて、省略する
  return omitTextIfNeeded(text, 18, "…", SEPARATOR);
}

type SearchParamsFields = "username" | "last_name" | "first_name" | "email";

/**
 * 検索パラメータキーをUI用の文言に変換するテーブル
 */
export const PARAMS_LABEL: Record<string, string> = {
  username: "ユーザー名",
  last_name: "姓",
  first_name: "名",
  email: "メールアドレス",
};

/**
 * SearchParamsの値を取得する
 * @param key 対象パラメータ
 * @param params 対象のSearchParams
 * @returns 引数keyで指定されたパラメータにセットされている値
 */
export function value(key: SearchParamsFields, params: SearchParams): string {
  const p = params as any;
  return p[key] ?? "";
}

/**
 * SearchParamsに値をセットし、新しいSearchParamsオブジェクトを返す
 * @param key 対象パラメータ
 * @param value 対象パラメータにセットする値
 * @param params 対象のSearchParams
 * @returns 引数paramsがコピー（ディープコピー）された新しいSearchParamsオブジェクト
 */
export function setValue(
  key: SearchParamsFields,
  value: string,
  params: SearchParams
): SearchParams {
  const newParams = new SearchParams(params) as any;
  newParams[key] = value;
  return newParams as SearchParams;
}
