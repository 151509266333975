import { DailyInterviewRemind } from "@/components/examinee/sleep_checkup/Types";

// リマインド期間(msec): 2日間
const REMIND_PERIOD = 2 * 24 * 60 * 60 * 1000;

export function isHigherPriority(
  remind: DailyInterviewRemind,
  referenceDate: Date
) {
  if (remind.status === "done") {
    return false;
  }

  return inRemindPeriod(remind, referenceDate);
}

function inRemindPeriod(remind: DailyInterviewRemind, referenceDate: Date) {
  const remindDate = new Date(remind.remind_datetime);
  if (referenceDate < remindDate) {
    return false;
  }

  const delta = referenceDate.getTime() - remindDate.getTime();
  return delta <= REMIND_PERIOD;
}
