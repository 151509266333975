import { ScreenLockProvider } from "@/components/ScreenLock";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import { ChangeMetaData } from "@/sleep_compass_lite/components/target/ChangeMetaData";
import LiteErrorBoundary from "@/sleep_compass_lite/components/target/LiteErrorBoundary";
import { TargetAppBar } from "@/sleep_compass_lite/components/target/TargetAppBar";
import { TargetAuthenticated as Authenticated } from "@/sleep_compass_lite/components/target/TargetAuthenticated";
import { TargetFooter as Footer } from "@/sleep_compass_lite/components/target/TargetFooter";
import LiteReportHeaderContext from "@/sleep_compass_lite/components/target/report/LiteReportMenuContext";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import { interviewPath } from "@/sleep_compass_lite/domain_models/target/InterviewPath";
import {
  REPORT_PAGES_PARENT,
  reportPath,
} from "@/sleep_compass_lite/domain_models/target/ReportPath";
import { Confirmation } from "@/sleep_compass_lite/pages/target/authentication/Confirmation";
import { ForgotPassword } from "@/sleep_compass_lite/pages/target/authentication/ForgotPassword";
import { Greetings } from "@/sleep_compass_lite/pages/target/authentication/Greetings";
import { RegistrationCompleted } from "@/sleep_compass_lite/pages/target/authentication/RegistrationCompleted";
import { SetNewPassword } from "@/sleep_compass_lite/pages/target/authentication/SetNewPassword";
import { SignIn } from "@/sleep_compass_lite/pages/target/authentication/SignIn";
import { SignInForSecond } from "@/sleep_compass_lite/pages/target/authentication/SignInForSecond";
import { SignInSucceeded } from "@/sleep_compass_lite/pages/target/authentication/SignInSucceeded";
import { SignUp } from "@/sleep_compass_lite/pages/target/authentication/SignUp";
import { TermsOfServiceAgreement } from "@/sleep_compass_lite/pages/target/authentication/TermsOfServiceAgreement";
import { DailyInterview } from "@/sleep_compass_lite/pages/target/interview/DailyInterview";
import { DailyInterviewCompleted } from "@/sleep_compass_lite/pages/target/interview/DailyInterviewCompleted";
import { DailyInterviewEntrance } from "@/sleep_compass_lite/pages/target/interview/DailyInterviewEntrance";
import { InterviewConnection } from "@/sleep_compass_lite/pages/target/interview/InterviewConnection";
import { PrimaryInterview } from "@/sleep_compass_lite/pages/target/interview/PrimaryInterview";
import { PrimaryInterviewCompleted } from "@/sleep_compass_lite/pages/target/interview/PrimaryInterviewCompleted";
import { PrimaryInterviewEntrance } from "@/sleep_compass_lite/pages/target/interview/PrimaryInterviewEntrance";
import { PrimaryInterviewIntroduction } from "@/sleep_compass_lite/pages/target/interview/PrimaryInterviewIntroduction";
import { AttitudeEnquete } from "@/sleep_compass_lite/pages/target/report/AttitudeEnquete";
import { DaytimeCondition } from "@/sleep_compass_lite/pages/target/report/DaytimeCondition";
import { End } from "@/sleep_compass_lite/pages/target/report/End";
import { PointsOfImprovement } from "@/sleep_compass_lite/pages/target/report/PointsOfImprovement";
import { SleepCondition } from "@/sleep_compass_lite/pages/target/report/SleepCondition";
import { Top } from "@/sleep_compass_lite/pages/target/report/Top";
import { TermsOfService } from "@/sleep_compass_lite/pages/target/terms/TermsOfService";
import { examineeTheme } from "@/theme/themeV1";
import { Box, Container, ThemeProvider, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ja from "date-fns/locale/ja";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";

export default function TargetLayout() {
  const HEADER_HEIGHT = 56;

  return (
    <ThemeProvider theme={examineeTheme}>
      <LiteMetaData />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <LiteErrorBoundary>
          <Routes>
            <Route
              path={interviewPath.getPath("PrimaryInterview")}
              element={<PrimaryInterview headerHeight={HEADER_HEIGHT} />}
            />
            <Route
              path={interviewPath.getPath("PrimaryInterviewIntroduction")}
              element={
                <PrimaryInterviewIntroduction headerHeight={HEADER_HEIGHT} />
              }
            />
            <Route
              path={interviewPath.getPath("PrimaryInterviewCompleted")}
              element={
                <PrimaryInterviewCompleted headerHeight={HEADER_HEIGHT} />
              }
            />
            <Route
              path={interviewPath.getPath("DailyInterview")}
              element={<DailyInterview headerHeight={HEADER_HEIGHT} />}
            />
            <Route
              path={interviewPath.getPath("DailyInterviewCompleted")}
              element={<DailyInterviewCompleted headerHeight={HEADER_HEIGHT} />}
            />
            <Route
              path={`${REPORT_PAGES_PARENT}/*`}
              element={<ReportLayout headerHeight={HEADER_HEIGHT} />}
            />
            <Route
              path="/*"
              element={<StandardTargetLayout headerHeight={HEADER_HEIGHT} />}
            />
          </Routes>
        </LiteErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function StandardTargetLayout({ headerHeight }: ExamineePageProps) {
  const FOOTER_HEIGHT = "151px";

  return (
    <Box
      sx={{
        minHeight: "100vh",
        position: "relative",
        backgroundColor: "background.blankSpace",
      }}
    >
      <TargetAppBar height={headerHeight} />
      {/* Note: Footerを画面最下部に設置するために { minHeight: "100vh", position: "relative" } が必要 */}
      <Container
        maxWidth="sm"
        sx={{
          pt: 0,
          pl: { xs: 0, sm: 0 },
          pr: { xs: 0, sm: 0 },
          pb: FOOTER_HEIGHT,
          backgroundColor: "white",
          minHeight: `calc(100vh - ${headerHeight}px - ${FOOTER_HEIGHT})`,
        }}
      >
        <Routes>
          {/* 対象者が登録時に最初に訪問するページ */}
          <Route
            path={authenticationPath.getPath("Greetings")}
            element={<Greetings />}
          />
          {/* 対象者のサインアップページ(登録ページ) */}
          <Route
            path={authenticationPath.getPath("SignUp")}
            element={
              <ScreenLockProvider>
                <SignUp />
              </ScreenLockProvider>
            }
          />
          {/* サインインページ */}
          <Route
            path={authenticationPath.getPath("SignIn")}
            element={<SignIn />}
          />
          {/* 2回目以降の対象者のサインインページ(登録ページ) */}
          <Route
            path={authenticationPath.getPath("SignIn2")}
            element={<SignInForSecond />}
          />
          {/* サインイン成功ページ */}
          <Route
            path={authenticationPath.getPath("SignInSucceeded")}
            element={<SignInSucceeded />}
          />
          {/* パスワード忘れページ */}
          <Route
            path={authenticationPath.getPath("ForgotPassword")}
            element={<ForgotPassword />}
          />
          {/* パスワード再設定ページ */}
          <Route
            path={authenticationPath.getPath("SetNewPassword")}
            element={<SetNewPassword />}
          />
          {/* 利用規約への同意ページ */}
          <Route
            path={authenticationPath.getPath("TermsOfServiceAgreement")}
            element={<TermsOfServiceAgreement />}
          />
          {/* 利用規約ページ */}
          <Route
            path={authenticationPath.getPath("TermsOfService")}
            element={<TermsOfService />}
          />
          {/* 確認コード検証ページ */}
          <Route
            path={authenticationPath.getPath("Confirmation")}
            element={
              // QRコード読み取りの検証のため、API呼び出しが増えて処理時間が長くなったので、画面ロックを使う
              <ScreenLockProvider>
                <Confirmation />
              </ScreenLockProvider>
            }
          />
          {/* アカウント登録完了ページ */}
          <Route
            path={authenticationPath.getPath("RegistrationCompleted")}
            element={<RegistrationCompleted />}
          />
          {/* 事前問診開始ページ */}
          <Route
            path={interviewPath.getPath("PrimaryInterviewEntrance")}
            element={<PrimaryInterviewEntrance />}
          />
          {/* 毎日問診開始ページ */}
          <Route
            path={interviewPath.getPath("DailyInterviewEntrance")}
            element={<DailyInterviewEntrance />}
          />
          {/* 事前問診完了直後に毎日問診に遷移するときの毎日問診開始ページ(問診接続ページ) */}
          <Route
            path={interviewPath.getPath("InterviewConnection")}
            element={<InterviewConnection />}
          />
        </Routes>
      </Container>
      {/* Note: Footerを画面最下部に設置するために { position: "absolute", bottom: 0 } が必要 */}
      <Footer
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: FOOTER_HEIGHT,
        }}
      />
    </Box>
  );
}

function ReportLayout({ headerHeight }: ExamineePageProps) {
  const FOOTER_HEIGHT = "151px";
  const [liteReportHeaderContext, setLiteReportHeaderContext] =
    useState<LiteReportHeaderContext | null>(null);

  return (
    <Authenticated>
      <Box
        sx={{
          minHeight: "100vh",
          position: "relative",
          backgroundColor: "background.blankSpace",
        }}
      >
        {/* レポートTOPのみロゴで戻るボタンなしのため、liteReportHeaderContext を使用して切り替えを行う */}
        <TargetAppBar
          height={headerHeight}
          logo={
            liteReportHeaderContext?.title != null ? (
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
                }}
              >
                {liteReportHeaderContext?.title}
              </Typography>
            ) : null
          }
          onClickBack={liteReportHeaderContext?.onClickBack}
        />
        {/* Note: Footerを画面最下部に設置するために { minHeight: "100vh", position: "relative" } が必要 */}
        <Container
          maxWidth="sm"
          sx={{
            pt: 0,
            pl: { xs: 0, sm: 0 },
            pr: { xs: 0, sm: 0 },
            pb: FOOTER_HEIGHT,
            backgroundColor: "white",
            minHeight: `calc(100vh - ${headerHeight}px - ${FOOTER_HEIGHT})`,
          }}
        >
          <Routes>
            <Route
              path={reportPath.getPath("ReportTop")}
              element={
                <Top setLiteReportHeaderContext={setLiteReportHeaderContext} />
              }
            />
            <Route
              path={reportPath.getPath("PointsOfImprovement")}
              element={
                <PointsOfImprovement
                  setLiteReportHeaderContext={setLiteReportHeaderContext}
                />
              }
            />
            <Route
              path={reportPath.getPath("SleepCondition")}
              element={
                <SleepCondition
                  setLiteReportHeaderContext={setLiteReportHeaderContext}
                />
              }
            />
            <Route
              path={reportPath.getPath("DaytimeCondition")}
              element={
                <DaytimeCondition
                  setLiteReportHeaderContext={setLiteReportHeaderContext}
                />
              }
            />
            <Route
              path={reportPath.getPath("AttitudeEnquete")}
              element={
                <AttitudeEnquete
                  setLiteReportHeaderContext={setLiteReportHeaderContext}
                />
              }
            />
            <Route
              path={reportPath.getPath("ReportEnd")}
              element={
                <End setLiteReportHeaderContext={setLiteReportHeaderContext} />
              }
            />
          </Routes>
        </Container>
        {/* Note: Footerを画面最下部に設置するために { position: "absolute", bottom: 0 } が必要 */}
        <Footer
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: FOOTER_HEIGHT,
          }}
        />
      </Box>
    </Authenticated>
  );
}

/**
 * Lite用のメタデータを設定するコンポーネント
 */
function LiteMetaData() {
  return (
    <ChangeMetaData
      pageTitle="睡眠チェック"
      pageDescription="スリープコンパスライト｜3日間、仕事終わりに問診に答えるだけで、睡眠・パフォーマンスを気軽にチェック！"
    />
  );
}
