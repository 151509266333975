import { RegistrationEntry } from "@/sleep_compass_lite/data_transfer_objects/target/RegistrationEntry";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { EmailAddress } from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import { Password } from "@/sleep_compass_lite/domain_models/target/Password";
import { getApiServerUrl } from "@/utils/axios";
import axios, { AxiosError, AxiosResponse } from "axios";

/**
 * 初回登録におけるサインアップ
 * 対象者登録APIを呼び出すことで、アカウントの仮作成、メールアドレスの検証コードの送信を行う。
 * @param surveyInfo サーベイ情報のUUID
 * @param email 対象者のメールアドレス
 * @param password 対象者のパスワード
 * @returns 対象者登録APIのレスポンス(データ)
 */
export async function signUp(
  surveyInfo: string,
  email: EmailAddress,
  password: Password
): Promise<RegistrationEntry> {
  try {
    const response = await postRegistrationEntry(surveyInfo, email, password);
    return response.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiError(e.response);
    } else {
      throw e;
    }
  }
}

/**
 * 対象者登録APIの呼び出し
 * @param surveyInfo サーベイ情報のUUID
 * @param email 対象者のメールアドレス
 * @param password 対象者のパスワード
 * @returns 対象者登録APIのレスポンス
 */
function postRegistrationEntry(
  surveyInfo: string,
  email: EmailAddress,
  password: Password
): Promise<AxiosResponse<RegistrationEntry>> {
  return axios.post<RegistrationEntry>(
    `${getApiServerUrl()}/api/lite/registration_entry/`,
    {
      survey_info: surveyInfo,
      email: email.value,
      password: password.value,
    }
  );
}
