type TimeUnitName = "milliseconds" | "seconds" | "minutes" | "hours" | "days";
const CONFIG: ReadonlyMap<TimeUnitName, number> = new Map([
  ["milliseconds", 1],
  ["seconds", 1000],
  ["minutes", 60 * 1000],
  ["hours", 60 * 60 * 1000],
  ["days", 24 * 60 * 60 * 1000],
]);

/**
 * 時間の単位を表し、各単位への変換を実行するユーティリティメソッドを提供します。
 * このクラスは情報を保持せず、stateless なメソッドのみで構成されます。
 * <code>
 * TimeUnit.MILLISECONDS.convert(10, "seconds");  // 10 秒 をミリ秒に変換します。
 * TimeUnit.MILLISECONDS.to("seconds", 10);  // 10 ミリ秒を秒に変換します。
 * </code>
 */
export default class TimeUnit {
  private constructor(readonly name: TimeUnitName) {}

  /**
   * 指定単位から変換を行います。
   *
   * @param value
   * @param from
   * @returns
   */
  convert(value: number, from: TimeUnitName) {
    const config = Object.fromEntries(CONFIG);
    return value * (config[from] / config[this.name]);
  }
  /**
   * 指定単位へ変換を行う関数を返却します。
   *
   * @param to
   * @param value
   * @returns
   */
  to(to: TimeUnitName, value: number) {
    return TimeUnit.valueOf(to).convert(value, this.name);
  }

  /**
   * ミリ秒へ変換します。
   *
   * @param value
   * @returns
   */
  toMilliseconds(value: number) {
    return this.to("milliseconds", value);
  }
  /**
   * 秒へ変換します。
   *
   * @param value
   * @returns
   */
  toSeconds(value: number) {
    return this.to("seconds", value);
  }
  /**
   * 分へ変換します。
   *
   * @param value
   * @returns
   */
  toMinutes(value: number) {
    return this.to("minutes", value);
  }
  /**
   * 時へ変換します。
   *
   * @param value
   * @returns
   */
  toHours(value: number) {
    return this.to("hours", value);
  }
  /**
   * 日へ変換します。
   * @param value
   * @returns
   */
  toDays(value: number) {
    return this.to("days", value);
  }

  /**
   * 指定した単位でインスタンスを構築します。
   *
   * @param name
   * @returns
   */
  static valueOf(name: TimeUnitName) {
    return new TimeUnit(name);
  }

  /**
   * 全単位を取得します。
   *
   * @returns
   */
  static units() {
    return TimeUnit.names().map((name) => TimeUnit.valueOf(name));
  }
  /***
   * 全単位名を取得します。
   */
  static names() {
    return [...CONFIG.keys()];
  }
  /**
   * ミリ秒
   * @see https://ja.wikipedia.org/wiki/%E3%83%9F%E3%83%AA%E7%A7%92
   */
  static MILLISECONDS = new TimeUnit("milliseconds");
  /**
   * 秒
   * @see https://ja.wikipedia.org/wiki/%E7%A7%92
   */
  static SECONDS = new TimeUnit("seconds");
  /**
   * 分
   * @see https://ja.wikipedia.org/wiki/%E5%88%86
   */
  static MINUTES = new TimeUnit("minutes");
  /**
   * 時
   * @see https://ja.wikipedia.org/wiki/%E6%99%82%E9%96%93_(%E5%8D%98%E4%BD%8D)
   */
  static HOURS = new TimeUnit("hours");
  /**
   * 日
   * @see https://ja.wikipedia.org/wiki/%E6%97%A5#%E6%99%82%E9%96%93%E3%81%AE%E5%8D%98%E4%BD%8D%E3%81%A8%E3%81%97%E3%81%A6%E3%81%AE%E6%97%A5
   */
  static DAYS = new TimeUnit("days");
}
