import { palette } from "@/theme/palette";

const FONT_FAMILY =
  '"Noto Sans JP", "Roboto", "Helvetica", "Arial", sans-serif';

export const examineeTypography = {
  typography: {
    fontFamily: FONT_FAMILY,
    h1: {
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
      color: palette.palette.text.primary,
    },
    h2: {
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
      color: palette.palette.text.primary,
    },
    h3: {
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
      color: palette.palette.text.primary,
    },
    h4: {
      fontWeight: 500,
      fontSize: "2.125rem",
      lineHeight: 1.25,
      letterSpacing: "0.00735em",
      color: palette.palette.text.primary,
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.5rem",
      lineHeight: 1.5,
      letterSpacing: "0em",
      color: palette.palette.text.primary,
    },
    h6: {
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.7,
      letterSpacing: "0.0075em",
      color: palette.palette.text.primary,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.8,
      letterSpacing: "0.00937em",
      color: palette.palette.text.primary,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.9,
      letterSpacing: "0.00714em",
      color: palette.palette.text.primary,
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: "0.00937em",
      color: palette.palette.text.primary,
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.85,
      letterSpacing: "0.01214em",
      color: palette.palette.text.primary,
    },
    body3: {
      fontWeight: 400,
      fontSize: "0.8125rem",
      lineHeight: 1.95,
      letterSpacing: "0.01307em",
      color: palette.palette.text.primary,
    },
    button: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.714,
      letterSpacing: "0.02857em",
      color: palette.palette.text.primary,
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.85,
      letterSpacing: "0.01416em",
      color: palette.palette.text.secondary,
    },
    overline: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.85,
      letterSpacing: "0.01214em",
      color: palette.palette.text.primary,
    },
  },
};

export const facilityTypography = {
  typography: {
    fontFamily: FONT_FAMILY,
    h1: {
      fontSize: 48,
      fontWeight: 400,
      lineHeight: "140%",
    },
    h2: {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: "160%",
    },
    h3: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: "160%",
    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "160%",
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "28px",
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "24px",
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "28px",
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "24px",
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "20px",
    },
    button: {
      fontWeight: 500,
      textTransform: undefined,
      lineHeight: "160%",
    },
  },
};
