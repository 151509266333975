class Constants {
  readonly USERTYPE_EXAMINEE: number = 100;
  readonly USERTYPE_MEDICAL_FACILITY_USER: number = 200;
  readonly USERTYPE_ACCELSTARS_USER: number = 300;

  readonly SIGNIN_URL_EXAMINEE = "/examinee/signin";
  readonly SIGNIN_URL_MEDICAL_FACILITY_USER = "/facility/signin";
  readonly SIGNIN_URL_ACCELSTARS_USER = "/accelstars/signin";

  // 睡眠健診の日数 試用日(1日)+測定日(7日)=8日
  readonly SLEEP_CHECKUP_LENGTH = 8;
}

export default new Constants();
