import { ScrollToTop } from "@/components/common/ScrollToTop";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { LiteReportHeaderProps } from "@/sleep_compass_lite/components/target/report/LiteReportMenuContext";
import { reportPath } from "@/sleep_compass_lite/domain_models/target/ReportPath";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

/**
 * おわりにページを表示します。
 *
 * @returns おわりに
 */
export function End({ setLiteReportHeaderContext }: LiteReportHeaderProps) {
  const { surveyInfoUid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // メニューバーの制御
    setLiteReportHeaderContext({
      title: "おわりに",
      onClickBack() {
        if (surveyInfoUid == null) return;
        navigate(
          reportPath.getFullPath("PointsOfImprovement", {
            ":surveyInfoUid": surveyInfoUid,
          })
        );
      },
    });
  }, [navigate, setLiteReportHeaderContext, surveyInfoUid]);

  if (surveyInfoUid == null) return <></>;
  return (
    <Stack sx={{ px: PX4 }}>
      <ScrollToTop />
      <Box sx={{ pt: 10, pb: 2, px: "61.5px" }}>
        <img
          src="/img/image_interview_completion_01.png"
          alt="report end"
          loading="lazy"
          style={{
            display: "block",
            margin: "auto",
            width: "100%",
            height: "auto",
            maxWidth: "330px",
          }}
        />
      </Box>
      <Stack sx={{ gap: 4, mb: 6 }}>
        <Typography variant="h6" align="center">
          HAVE A GOOD SLEEP
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "text.secondary",
            textAlign: "center",
          }}
        >
          レポートは以上です。
          <br />
          これからの睡眠の改善にお役立てください。
        </Typography>
      </Stack>
      <Stack sx={{ mt: 6, mb: 20 }}>
        <Button
          variant="contained"
          sx={{ boxShadow: "none" }}
          component={Link}
          to={reportPath.getFullPath("ReportTop", {
            ":surveyInfoUid": surveyInfoUid,
          })}
        >
          レポートTOPへ戻る
        </Button>
      </Stack>
    </Stack>
  );
}
