import Authenticated from "@/components/auth/Authenticated";
import { SCLink } from "@/components/common/SCLink";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import constants from "@/utils/constants";
import { Button, Stack, Typography } from "@mui/material";
import { Link, LinkProps, useParams } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  sleepCheckupUuid: string;
};

export function DeviceIdScanIntroduction() {
  const params = useParams();

  if (params.sleepCheckupId == null) {
    return (
      <GeneralError
        to="/examinee/signup"
        buttonText="アカウント登録をやり直す"
      />
    );
  }

  const state = { sleepCheckupUuid: params.sleepCheckupId };

  return (
    <Authenticated redirectTo={constants.SIGNIN_URL_EXAMINEE}>
      <ScrollToTop />
      <Stack spacing={12} sx={{ mt: 8, mb: 16, mx: 4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            デバイスIDの登録
          </Typography>
          <Stack spacing={4} alignItems="center">
            <Typography variant="subtitle2">
              測定に利用するデバイスの登録を行います。本ページ下部の[QRコードを読み取る]ボタンを押して、お使いのスマートフォンでデバイスの腕側に表示されているQRコードを読み取ってください。
            </Typography>
            <DeviceIntroductionImage src="/img/image_device_id_scan_introduction_01.png" />
            <DeviceIntroductionImage src="/img/image_device_id_scan_introduction_02.png" />
            <Stack alignItems="left" width="100%">
              <Typography
                variant="caption"
                sx={{
                  color: "text.secondary",
                  fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
                }}
              >
                ※デバイスの色についてはイラストと異なる場合があります。
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={4}>
          <QRCodeScanButton
            title="QRコードを読み取る"
            subTitle="※カメラが起動します"
            to="/examinee/device_id_scan"
            state={state}
          />
          <SCLink
            variant="body2"
            to="/examinee/device_id_input"
            state={state}
            sx={{ color: "text.secondary" }}
          >
            うまく読み取れない場合
          </SCLink>
        </Stack>
      </Stack>
    </Authenticated>
  );
}

type DeviceIntroductionImageProps = {
  src: string;
};
function DeviceIntroductionImage({ src }: DeviceIntroductionImageProps) {
  return (
    <img
      src={src}
      alt="デバイスIDの登録"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  );
}

type QRCodeScanButtonProps = {
  title: string;
  subTitle: string;
  to: LinkProps["to"];
  state: LinkProps["state"];
};
function QRCodeScanButton({
  title,
  subTitle,
  to,
  state,
}: QRCodeScanButtonProps) {
  return (
    <Button
      component={Link}
      to={to}
      state={state}
      variant="contained"
      size="large"
    >
      <Stack alignItems="center">
        <Typography variant="button" color="white" fontSize="15px">
          {title}
        </Typography>
        <Typography variant="caption" color="white">
          {subTitle}
        </Typography>
      </Stack>
    </Button>
  );
}
