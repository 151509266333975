export type ReportMarkProps = Omit<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  "src"
>;
export default function ReportMark(props: ReportMarkProps) {
  return (
    <img
      src="/img/image_report_mark.svg"
      alt="report_mark"
      width="10"
      height="10"
      loading="lazy"
      {...props}
    ></img>
  );
}
