import { Copyright } from "@/components/examinee/Copyright";
import {
  ExternalLink,
  ExternalLinkProps,
} from "@/components/examinee/ExternalLink";
import { Stack, SxProps, Theme } from "@mui/material";

type FooterProps = {
  sx?: SxProps<Theme>;
  links: ExternalLinkProps[];
};
export function Footer({ sx, links }: FooterProps) {
  return (
    <Stack
      sx={{
        backgroundColor: "primary.dark",
        ...sx,
      }}
    >
      <Stack spacing={4} sx={{ pt: 8, px: 4 }}>
        <Stack alignItems="flex-start">
          {links.map((props, i) => (
            <ExternalLink key={i} href={props.href} target={props.target}>
              {props.children}
            </ExternalLink>
          ))}
        </Stack>
        <Stack alignItems="center">
          <Copyright sx={{ color: "white" }} />
        </Stack>
      </Stack>
    </Stack>
  );
}
