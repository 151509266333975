import { CheckupProject } from "@/components/sleep_checkup_v1/Types";

export type CheckupProjectFields = "name";

export const CHECKUP_PROJECT_FIELDS: CheckupProjectFields[] = ["name"];

/**
 * CheckupProjectに、引数で指定されたnameをセットし、新しいCheckupProjectオブジェクトを返す
 * @param value CheckupProjectのname
 * @param project 対象のCheckupProject
 * @returns 引数projectがコピー（ディープコピー）された新しいCheckupProjectオブジェクト
 */
export function setProjectName(
  value: string,
  project: CheckupProject | null
): CheckupProject {
  return {
    id: project?.id ?? null,
    name: value,
  };
}

const CHECKUP_PROJECT_LABEL: Record<CheckupProjectFields, string> = {
  name: "project_name",
};

/**
 * 施設ユーザーCSVインポートAPIのエラーレスポンスから、CheckupProjectフィールドに関するエラーメッセージを抽出する
 * @param project 施設ユーザーCSVインポートAPIのエラーレスポンス、projectフィールドに対するバリュー
 * @returns CheckupProjectフィールドに関するエラーメッセージ配列
 */
export function getProjectErrorMessages(project: any): string[] {
  return Object.entries(project).map(
    ([field, message]) =>
      `${CHECKUP_PROJECT_LABEL[field as CheckupProjectFields]}: ${message}`
  );
}
