import { LITE_GOOGLE_FORM_LINK } from "@/sleep_compass_lite/constants";
import { Box, Button, Container, Link, Stack, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { TargetAppBar } from "./TargetAppBar";
import { TargetFooter } from "./TargetFooter";

const FOOTER_HEIGHT = "151px";

/**
 * {@link LiteErrorBoundary} でキャッチするための例外を throw します。
 * ```
 * const throwError = useThrowError();
 *
 * try {
 *   .
 *   .
 *   .
 * } catch (e: unknown) {
 *   throwError(e)
 * }
 *
 * ```
 * のように使用することを想定しています。
 *
 * @returns throwError 関数
 */
export function useThrowError() {
  const [, setState] = useState();
  return function (error: any) {
    setState(() => {
      throw error;
    });
  };
}

/**
 * Lite 用汎用エラー画面
 *
 * @param param {@link FallbackProps フォールバックプロパティ}
 * @returns Lite 用汎用エラー画面コンポーネント
 */
export function LiteErrorFallback({ error }: FallbackProps) {
  console.error(error);
  return (
    <Box sx={{ minHeight: "100vh", position: "relative" }}>
      <TargetAppBar height={56} />
      <Container
        maxWidth="sm"
        sx={{ pt: 0, px: 4, pb: FOOTER_HEIGHT, textAlign: "center" }}
      >
        <Stack>
          <Box sx={{ mt: 10, mx: 4, mb: 6 }}>
            <img src="/img/image_lite_error_boundary.svg" alt="error" />
          </Box>
        </Stack>
        <Stack sx={{ gap: 6 }}>
          <Typography
            sx={{
              color: "text.primary",
              fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
              fontFamily: "Noto Sans JP",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "170%",
              letterSpacing: "0.15px",
            }}
          >
            エラーが発生しました
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "text.secondary",
              textAlign: "center",
              fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
            }}
          >
            何らかのエラーが発生しました。<br></br>
            ページを再度開いても正しく表示されない場合、<br></br>
            以下のお問い合わせフォームからご連絡ください。
          </Typography>
          <Button
            component={Link}
            variant="contained"
            size="large"
            fullWidth
            sx={{ boxShadow: "none" }}
            href={LITE_GOOGLE_FORM_LINK}
            target="inquiry"
          >
            お問い合わせフォームを開く
          </Button>
        </Stack>
      </Container>
      <TargetFooter
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: FOOTER_HEIGHT,
        }}
      />
    </Box>
  );
}

/**
 * Lite用のエラーバウンダリー
 */
interface LiteErrorBoundaryProps {
  /** 対象のコンポーネント */
  children: ReactNode;
}
/**
 * Lite用のエラーバウンダリー
 * @param param {@link LiteErrorBoundaryProps Lite用エラーバウンダリーのプロパティ}
 * @returns Lite用のエラーバウンダリー
 */
export default function LiteErrorBoundary({
  children,
}: LiteErrorBoundaryProps) {
  return (
    <ErrorBoundary FallbackComponent={LiteErrorFallback}>
      {children}
    </ErrorBoundary>
  );
}
