import { useEffect } from "react";

interface ChangeMetaDataProps {
  pageTitle: string;
  pageDescription: string;
}
/**
 * ページ情報(e.g. ページタイトル, ページディスクリプション)を一時的に変更するコンポーネント
 * コンポーネントのアンマウント時にページ情報を元に戻す
 */
export function ChangeMetaData({
  pageTitle,
  pageDescription,
}: ChangeMetaDataProps) {
  useEffect(() => {
    // ページタイトルの設定
    const prevTitle = document.title;
    document.title = pageTitle;
    // ページディスクリプションの設定
    const descriptionElement = document.querySelector(
      "meta[name='description']"
    );
    const prevDescription = descriptionElement?.getAttribute("content");
    descriptionElement?.setAttribute("content", pageDescription);
    return () => {
      // ページタイトルの復元
      document.title = prevTitle;
      // ページディスクリプションの復元
      if (descriptionElement != null && prevDescription != null) {
        descriptionElement.setAttribute("content", prevDescription);
      }
    };
  });

  return <></>;
}
