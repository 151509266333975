export const QUESTION_GROUP_TYPE_NORMAL = "NORMAL";
export const QUESTION_GROUP_TYPE_LIKERT_SCALE = "LIKERT_SCALE";

export const QUESTION_INFO_KEY_SCALES = "scales";

export const SELECTION_TYPE_1_1 = "1/1";
export const SELECTION_TYPE_1_N = "1/N";
export const SELECTION_TYPE_N_N = "N/N";

export const CHOICE_TYPE_LABEL = "LABEL";
export const CHOICE_TYPE_LABEL_WITH_FREE_TEXT = "LABEL_WITH_FREE_TEXT";
export const CHOICE_TYPE_LABEL_WITH_NUMBER_CHOICES = "LABEL_WITH_NUMBER_CHOICE";
export const CHOICE_TYPE_NO_LABEL = "NO_LABEL";

export const SCREEN_TYPE_QUESTION = "QUESTION";
export const SCREEN_TYPE_COMPLETION_MESSAGE_PRIMARY =
  "COMPLETION_MESSAGE_PRIMARY";
export const SCREEN_TYPE_COMPLETION_MESSAGE_DAILY = "COMPLETION_MESSAGE_DAILY";
export const SCREEN_TYPE_ELEARNING_LINK_DAILY = "ELEARNING_LINK_DAILY";

export const LABEL_CONTAINED_VALUE_REGEX = /\[.*?\]/g;

export const SELECT_CHOICE_DELIMITER = ":";
export const SELECT_CHOICE_DEFAULT_VALUE = "--";
export const FREE_TEXT_CHOICE_DEFAULT_VALUE = "";

export const RETRIEVE_VALUE_REGEX = /\[(?<value>.*?)\]/g;
