import { usePlan } from "@/components/worksheet/apis";
import { Add, Edit } from "@mui/icons-material";
import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import Axios from "axios";
import { format } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SubtitleTypography } from "../common/Typographies";
import { Plan, PlanUpdateData, TopicType } from "./commons/plans";

/**
 * 改善目標ページ
 * @returns 改善目標ページ
 */
export default function Plans() {
  const { uuid } = useParams<{ uuid: string }>();
  const api = usePlan();
  const [plan, setPlan] = useState<Plan>();
  const [highlightImprovement, setHighlightImprovement] = useState(false);
  const [highlightAction, setHighlightAction] = useState(false);
  const [highlightRetrospective, setHighlightRetrospective] = useState(false);

  useEffect(() => {
    if (uuid == null) return;
    (async () => {
      const res = await api.get(uuid);
      setPlan(res.data);
    })();
  }, [uuid, api]);

  return (
    <>
      <Stack sx={{ gap: 10 }}>
        <Section
          title="改善目標"
          data={plan}
          topic="improvement"
          isHighlight={highlightImprovement}
          onChangeHighlight={(b) => {
            setHighlightImprovement(b);
          }}
        />
        <Section
          title="行動目標"
          data={plan}
          topic="action"
          isHighlight={highlightAction}
          onChangeHighlight={(b) => {
            setHighlightAction(b);
          }}
        />
        <Section
          title="振り返り"
          data={plan}
          topic="retrospective"
          isHighlight={highlightRetrospective}
          onChangeHighlight={(b) => {
            setHighlightRetrospective(b);
          }}
        />
      </Stack>
    </>
  );
}

/**
 * 目標や振り返りのセクション
 */
function Section({
  title,
  data,
  topic,
  isHighlight,
  onChangeHighlight,
}: {
  title: string;
  data?: Plan;
  topic: TopicType;
  isHighlight: boolean;
  onChangeHighlight: (b: boolean) => void;
}) {
  const { uuid } = useParams<{ uuid: string }>();
  const api = usePlan();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { t } = useTranslation();

  const text = data?.[topic]?.comment ?? "";
  const createdAt: string | null = data?.[topic]?.created_at ?? null;
  const updatedAt: string | null = data?.[topic]?.updated_at ?? null;

  const [currentText, setCurrentText] = useState<string>(
    data?.[topic]?.comment ?? ""
  );
  const [isEditable, setIsEditable] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCurrentText(text);
    setIsEditable(text === "");
  }, [text]);

  const update = useCallback(
    async (updatedData: PlanUpdateData) => {
      if (uuid == null || data == null) return;
      try {
        const res = await api.post(uuid, updatedData);
        setErrorMessage("");

        const comment = data?.[topic];
        if (comment != null) {
          comment.comment = updatedData.comment;
          comment.updated_at = res.data.updated_at;
          comment.created_at = createdAt ?? res.data.updated_at; // FIXME: APIがcreated_atを返すべき
        } else {
          data[topic] = {
            comment: updatedData.comment,
            updated_at: res.data.updated_at,
            created_at: res.data.updated_at, // FIXME: APIがcreated_atを返すべき
          };
        }
        onChangeHighlight(false);
        setIsEditable(updatedData.comment === "");
      } catch (e: unknown) {
        if (Axios.isAxiosError(e)) {
          if (e.response == null) return;
          const data = e.response.data as Record<string, string[]>;
          for (const [, messages] of Object.entries(data)) {
            setErrorMessage(t(messages[0]));
            break;
          }
        } else {
          throw e;
        }
      }
    },
    [uuid, api, data, topic, t, onChangeHighlight, createdAt]
  );

  /**
   * 作成日の表示
   */
  function LatestUpdated({
    createdAt,
    updatedAt,
  }: {
    createdAt: string;
    updatedAt: string | null;
  }) {
    const [title, date] = (() => {
      if (isSameTime(createdAt, updatedAt) || updatedAt == null) {
        // 作成日時と更新日時が等しいということは、データが作成後更新されていないということ
        return ["作成日", new Date(createdAt)];
      }
      return ["最終更新日", new Date(updatedAt)];
    })();

    try {
      const dateString = format(date, "yyyy年M月d日 H時m分");
      return (
        <Typography
          variant="body2"
          sx={{ color: "#707070" }}
        >{`${title}: ${dateString}`}</Typography>
      );
    } catch (e) {
      return <Typography variant="body2">{`${title}: -`}</Typography>;
    }
  }

  /**
   * 「登録する」「編集」ボタン
   */
  function OperationButtons() {
    if (isHighlight) {
      // 編集中はキャンセルボタンと登録するボタンを表示
      return (
        <Stack flexDirection="row" sx={{ gap: 4 }}>
          <Button
            variant="outlined"
            size="small"
            onClick={(e) => {
              setCurrentText(text);
              onChangeHighlight(false);
              if (text !== "") {
                setIsEditable(false);
              }
              setErrorMessage("");
            }}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            size="small"
            startIcon={<Add />}
            sx={{ padding: "8px 16px" }}
            onClick={(e) => {
              update({
                topic,
                comment: currentText,
                updated_at: updatedAt,
              });
            }}
          >
            登録する
          </Button>
        </Stack>
      );
    }
    if (text === "") {
      // テキストが空の場合はdisabledな登録ボタンを表示
      return (
        <Button
          variant="text"
          size="small"
          startIcon={<Add />}
          sx={{ padding: "8px 16px" }}
          disabled
        >
          登録する
        </Button>
      );
    }
    // 編集を開始するためのボタンを表示
    return (
      <Button
        variant="text"
        size="small"
        startIcon={<Edit />}
        sx={{ padding: "8px 16px" }}
        onClick={(e) => {
          onChangeHighlight(true);
          setIsEditable(true);
          if (textFieldRef?.current != null) {
            textFieldRef.current.focus();
            const n = textFieldRef.current.value.length;
            textFieldRef.current.setSelectionRange(n, n);
          }
        }}
      >
        編集
      </Button>
    );
  }

  return (
    <Stack sx={{ gap: 6, width: "800px" }}>
      <SubtitleTypography>{title}</SubtitleTypography>
      <Stack
        sx={{
          border: isHighlight ? "2px solid #0056A0" : "1px solid #E0E0E0",
          borderRadius: 4,
          boxSizing: "border-box",
          gap: 4,
          // フォーカス時はborderが太くなるため位置がずれるが、
          // それを防ぐためにmarginを調整している
          margin: isHighlight ? 0 : "2px",
          padding: 4,
        }}
      >
        <TextField
          fullWidth
          multiline
          error={Boolean(errorMessage?.length)}
          placeholder={`${title}を記入しましょう`}
          helperText={errorMessage}
          variant="standard"
          value={currentText}
          onChange={(e) => setCurrentText(e.target.value)}
          InputProps={{
            disableUnderline: true,
            readOnly: !isEditable,
          }}
          inputRef={textFieldRef}
          onFocus={(e) => {
            if (isEditable) {
              onChangeHighlight(true);
            }
          }}
        />
        <Divider />
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          sx={{ gap: 1 }}
        >
          {!isEditable && createdAt != null && (
            <LatestUpdated createdAt={createdAt} updatedAt={updatedAt} />
          )}
          <OperationButtons />
        </Stack>
      </Stack>
    </Stack>
  );
}

/**
 * データを新たに作成した場合においても、created_at == updated_at とはならない。
 * そのため、「データが作成後編集されたか」を判定するために本関数を用意した。
 * APIが「データが作成後編集されたか」を返すようになれば本関数は不要となる。
 */
const isSameTime = (createdAt: string | null, updatedAt: string | null) => {
  if (createdAt == null || updatedAt == null) {
    // いずれかがnullの場合はfalseとする（null同士で等しくてもfalseとする）
    return false;
  }
  const createdAtUnixTime = new Date(createdAt).getTime();
  const updatedAtUnixTime = new Date(updatedAt).getTime();
  const diff = Math.abs(updatedAtUnixTime - createdAtUnixTime);
  return diff < 100; // 100ms未満の差は同時刻とみなす
};
