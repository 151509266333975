import { CardContent, CardContentProps } from "@mui/material";

/**
 * {@link ReportCardContent} のデフォルトスタイル
 */
export const DEFAULT_STYLE = {
  p: 4,
  "&:last-child": {
    pb: 4,
  },
};

/**
 * {@link ReportCardContent} のプロパティ
 */
export type ReportCardContentProps = CardContentProps;

/**
 * lite のレポート用の {@link CardContent}
 * @see CardContent
 */
export function ReportCardContent(props: ReportCardContentProps) {
  return <CardContent sx={DEFAULT_STYLE} {...props} />;
}
