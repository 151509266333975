import {
  REGISTRATION_METHOD_INPUT,
  postDeviceIdRegistration,
} from "@/components/activation/DeviceIdRegistrationAPI";
import { deviceIdValidator } from "@/components/activation/DeviceIdService";
import { getEncodedCurrentPath } from "@/components/common/LocationService";
import { NonFieldError } from "@/components/common/SCAlert";
import { SCLink } from "@/components/common/SCLink";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  sleepCheckupUuid: string;
};

export function DeviceIdInput() {
  const [deviceId, setDeviceId] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const { sleepCheckupUuid } = (location.state as StateType) || {};

  const onRegisterDeviceIdHandler = () => {
    postDeviceIdRegistration(
      deviceId,
      REGISTRATION_METHOD_INPUT,
      sleepCheckupUuid,
      getEncodedCurrentPath(location)
    )
      .then(() => {
        navigate("/examinee/registration_completed", {
          state: {
            sleepCheckupUuid: sleepCheckupUuid,
          },
        });
      })
      .catch((e) => {
        setErrorMessage("エラーが発生しました。しばらく経ってから、再試行してください。");
      });
  };

  const validator = () => deviceIdValidator(deviceId);
  const isInvalidDeviceId = deviceIdValidator(deviceId) != null;

  if (!sleepCheckupUuid) {
    return (
      <GeneralError
        to="/examinee/signup"
        buttonText="アカウント登録をやり直す"
      />
    );
  }

  return (
    <>
      <ScrollToTop />
      <Stack spacing={12} sx={{ mt: 8, mb: 16, mx: 4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            デバイスIDの登録
          </Typography>
          <NonFieldError>{errorMessage}</NonFieldError>
          <Stack spacing={4} alignItems="center">
            <Typography variant="subtitle2">
              手入力によりデバイスIDの登録を行います。デバイスの下図の位置に表示されているデバイスID(英数字8桁)を入力してください。
            </Typography>
            <img
              src="/img/image_device_id_input_01.png"
              alt="デバイスIDの記載場所"
              loading="lazy"
              style={{ width: "100%", height: "auto" }}
            />
          </Stack>
          <TextForm
            title="デバイスID"
            placeholder="例：AN1Z0000"
            validator={validator}
            onChange={(e) => setDeviceId(e.target.value.toUpperCase())}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </Stack>
        <Stack spacing={4}>
          <Button
            variant="contained"
            size="large"
            onClick={onRegisterDeviceIdHandler}
            disabled={isInvalidDeviceId}
          >
            登録する
          </Button>
          <SCLink
            variant="body2"
            to={`/examinee/device_id_scan_introduction/${sleepCheckupUuid}`}
            sx={{ color: "text.secondary" }}
          >
            QRコードの読み取りに戻る
          </SCLink>
        </Stack>
      </Stack>
    </>
  );
}
