import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function MindHelpDialog() {
  return (
    <HelpButton dialogTitle="こころの調子">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "睡眠への影響",
            content:
              "こころの調子が良くないと、自分の睡眠状態の誤認をすることがあります。客観的な睡眠時間と主観的な睡眠時間が一致しないこともあるため、ズレがある場合は客観的な睡眠時間を認識することが大切です。",
          },
          {
            title: "おすすめ",
            content:
              "こころの調子と睡眠は相互に影響するため、まずは良い睡眠をとれるよう心がけましょう。",
          },
        ]}
      />
    </HelpButton>
  );
}
