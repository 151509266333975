/**
 * APIレスポンスドメインモデル
 */
export class ApiResponse<T> {
  constructor(
    /** レスポンス本体 */
    readonly data: T,
    /** HTTPステータスコード */
    readonly status: number
  ) {}
}
