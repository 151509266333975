import { SubtitleTypography } from "@/components/common/Typographies";
import { ReportCard } from "@/sleep_compass_lite/components/target/ReportCard";
import { ReportCardComment } from "@/sleep_compass_lite/components/target/ReportCardComment";
import { ReportCardContent } from "@/sleep_compass_lite/components/target/ReportCardContent";
import { ReportQuestionTitle } from "@/sleep_compass_lite/components/target/ReportQuestionTitle";
import { ReportShowMore } from "@/sleep_compass_lite/components/target/ReportShowMore";
import { ReportTableContainer } from "@/sleep_compass_lite/components/target/ReportTableContainer";
import { SleepDaytimeReportTitle } from "@/sleep_compass_lite/components/target/SleepDaytimeReportTitle";
import { LiteReportHeaderProps } from "@/sleep_compass_lite/components/target/report/LiteReportMenuContext";
import {
  SleepDaytimeConditionBase,
  SleepDaytimeConditionItemBase,
} from "@/sleep_compass_lite/data_transfer_objects/target/report";
import { reportPath } from "@/sleep_compass_lite/domain_models/target/ReportPath";

import {
  PX2,
  PX4,
  PX6,
} from "@/sleep_compass_lite/components/target/StackStyles";
import { StickyStack } from "@/sleep_compass_lite/components/target/StickyStack";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { formatDisplayTime } from "@/utils/date";
import {
  Button,
  ButtonProps,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

/**
 * 睡眠の状態情報
 */
interface SleepConditionInfo {
  /**
   * 睡眠の長さ
   */
  quantity: {
    /** 基準値 */
    standard_value: string;
  } & SleepDaytimeConditionBase<
    {
      /** 睡眠時間 */
      sleep_time_min: number;
    } & SleepDaytimeConditionItemBase
  >;
  /**
   * 睡眠の質
   */
  quality: SleepDaytimeConditionBase<
    {
      /** 結果 */
      result: string;
    } & SleepDaytimeConditionItemBase
  >;
  /**
   * 睡眠のリズム情報
   */
  rhythm: SleepDaytimeConditionBase<
    {
      /** 結果 */
      result: string;
      /** 基準となる情報(基準データの場合、true) */
      is_baseline: boolean;
    } & SleepDaytimeConditionItemBase
  >;
}

/**
 * 睡眠の長さを表示します。
 *
 * @param props {@link SleepConditionInfo#quantity}
 * @returns 睡眠の長さコンポーネント
 */
function SleepQuantitySection(props: SleepConditionInfo["quantity"]) {
  return (
    <ReportCard>
      <ReportCardContent>
        <Stack sx={{ gap: 2 }}>
          <SleepDaytimeReportTitle type="quantity" score={props.stars} />
          <ReportCardComment>{props.comment}</ReportCardComment>
          <ReportShowMore>
            <Stack sx={{ gap: 2 }}>
              <ReportQuestionTitle title="睡眠時間" />
              <Typography variant="body2">{props.standard_value}</Typography>
              <ReportTableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 2,
                        }}
                      >
                        <Typography variant="caption">回答日</Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          p: 2,
                        }}
                      >
                        <Typography variant="caption">あなたの結果</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.items.map((v, i) => (
                      <TableRow key={i}>
                        <TableCell component="th" sx={{ py: 3, px: 2 }}>
                          <Typography variant="body2" noWrap>
                            {v.date}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ py: 3, px: 2 }}>
                          <Typography
                            variant="body2"
                            sx={v.is_highlight ? { color: "error.main" } : {}}
                          >
                            {formatDisplayTime(v.sleep_time_min)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ReportTableContainer>
            </Stack>
          </ReportShowMore>
        </Stack>
      </ReportCardContent>
    </ReportCard>
  );
}

/**
 * 睡眠の質を表示します。
 * @param props {@link SleepConditionInfo#quality}
 * @returns 睡眠の質コンポーネント
 */
function SleepQualitySection(props: SleepConditionInfo["quality"]) {
  return (
    <ReportCard>
      <ReportCardContent>
        <Stack sx={{ gap: 2 }}>
          <SleepDaytimeReportTitle type="quality" score={props.stars} />
          <ReportCardComment>{props.comment}</ReportCardComment>
          <ReportShowMore>
            <Stack sx={{ mt: 4, gap: 4 }}>
              <ReportQuestionTitle title="睡眠に関して困ったことは？" />
              <ReportTableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 2,
                        }}
                      >
                        <Typography variant="caption">回答日</Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          p: 2,
                        }}
                      >
                        <Typography variant="caption">あなたの結果</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.items.map((v, i) => (
                      <TableRow key={i}>
                        <TableCell component="th" sx={{ py: 3, px: 2 }}>
                          <Typography variant="body2" noWrap>
                            {v.date}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ py: 3, px: 2 }}>
                          <Typography
                            variant="body2"
                            sx={v.is_highlight ? { color: "error.main" } : {}}
                          >
                            {v.result}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ReportTableContainer>
            </Stack>
          </ReportShowMore>
        </Stack>
      </ReportCardContent>
    </ReportCard>
  );
}

/**
 * 睡眠のリズムを表示します。
 *
 * @param props {@link SleepConditionInfo#rhythm}
 * @returns 睡眠のリズムのコンポーネント
 */
function SleepRhythmSection(props: SleepConditionInfo["rhythm"]) {
  return (
    <ReportCard>
      <ReportCardContent>
        <Stack sx={{ gap: 2 }}>
          <SleepDaytimeReportTitle type="rhythm" score={props.stars} />
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {props.comment}
          </Typography>
          <ReportShowMore>
            <Stack sx={{ gap: 2 }}>
              <ReportQuestionTitle title="起床時刻" />
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                ※基準とした起床時刻
              </Typography>
              <ReportTableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 2,
                        }}
                      >
                        <Typography variant="caption">回答日</Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          p: 2,
                        }}
                      >
                        <Typography variant="caption">あなたの結果</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.items.map((v, i) => (
                      <TableRow key={i}>
                        <TableCell component="th" sx={{ py: 3, px: 2 }}>
                          <Typography variant="body2" noWrap>
                            {v.date}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 2 }}>
                          <Grid container justifyContent="flex-end">
                            <Grid item xs={3}>
                              <Typography
                                variant="body2"
                                sx={{
                                  display: "inline",
                                  ...(v.is_highlight
                                    ? { color: "error.main" }
                                    : {}),
                                }}
                              >
                                {v.result}
                              </Typography>
                              {v.is_baseline && (
                                <Typography
                                  sx={{
                                    display: "inline",
                                    fontSize: "0.8em",
                                    verticalAlign: "top",
                                  }}
                                >
                                  ※
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ReportTableContainer>
            </Stack>
          </ReportShowMore>
        </Stack>
      </ReportCardContent>
    </ReportCard>
  );
}

/**
 * 睡眠の状態の情報をサーバーから取得します。
 */
function useSleepConditionInfo({ surveyInfoUid }: { surveyInfoUid?: string }) {
  const axios = useAxios();
  const [sleepConditionInfo, setSleepConditionInfo] =
    useState<SleepConditionInfo>();
  useEffect(() => {
    (async () => {
      if (surveyInfoUid == null) return;
      const response: AxiosResponse<SleepConditionInfo> = await axios.get(
        `/api/lite/report/${surveyInfoUid}/sleep_condition/`
      );
      setSleepConditionInfo(response.data);
    })();
  }, [axios, surveyInfoUid]);
  return sleepConditionInfo;
}

/**
 * 睡眠の状態を表示します。
 *
 * @returns 睡眠の状態
 */
export function SleepCondition({
  setLiteReportHeaderContext,
}: LiteReportHeaderProps) {
  const { surveyInfoUid } = useParams();
  const sleepConditionInfo = useSleepConditionInfo({ surveyInfoUid });
  const navigate = useNavigate();

  const [isNextButtonFloating, setIsNextButtonFloating] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    // メニューバーの制御
    setLiteReportHeaderContext({
      title: "睡眠の状態",
      onClickBack() {
        if (surveyInfoUid == null) return;
        navigate(
          reportPath.getFullPath("ReportTop", {
            ":surveyInfoUid": surveyInfoUid,
          })
        );
      },
    });
  }, [navigate, setLiteReportHeaderContext, surveyInfoUid]);

  const nextButtonSX: ButtonProps["sx"] = { my: 6 };
  if (isNextButtonFloating === false) {
    // ボタンの影は、フローティングしていないときは非表示(フローティングしているときは表示)
    nextButtonSX["boxShadow"] = "none";
  }

  if (surveyInfoUid == null || sleepConditionInfo == null) return <></>;
  return (
    <Stack sx={{ backgroundColor: "background.default", pb: 14 }}>
      <Stack
        spacing={10}
        sx={{ px: PX6, pt: 6, pb: 10, backgroundColor: "white" }}
      >
        <img
          src="/img/image_report_stepper_02.svg"
          alt="Sleep condition step"
          loading="lazy"
          style={{ width: "100%", height: "auto" }}
        />
        <Stack sx={{ gap: "24px" }}>
          <Typography variant="h5">睡眠の状態とは</Typography>
          <Typography variant="body2">
            睡眠を「長さ」、「質」、「リズム」の3つの項目に分解して評価しています。
          </Typography>
          <Stack sx={{ backgroundColor: "#EBF1F7", p: 6 }}>
            <img
              src="/img/image_lite_report_sleep_condition.png"
              alt="What's sleep condition"
              loading="lazy"
              style={{ width: "100%", height: "auto", maxWidth: "420px" }}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ backgroundColor: "background.default", px: PX4 }}>
        <Stack sx={{ pt: 10, pb: 6, px: PX2 }}>
          <SubtitleTypography>あなたの結果</SubtitleTypography>
        </Stack>
        <Stack sx={{ gap: 4, pb: 6 }}>
          <SleepQuantitySection {...sleepConditionInfo.quantity} />
          <SleepQualitySection {...sleepConditionInfo.quality} />
          <SleepRhythmSection {...sleepConditionInfo.rhythm} />
        </Stack>
      </Stack>
      <StickyStack
        sx={{
          px: PX4,
          background:
            "linear-gradient(180deg, rgba(246, 245, 244, 0.00) 0%, #F6F5F4 100%);",
        }}
        onChangeFloatingState={(isFloating) =>
          setIsNextButtonFloating(isFloating)
        }
      >
        <Button
          variant="contained"
          size="large"
          sx={nextButtonSX}
          component={Link}
          to={reportPath.getFullPath("DaytimeCondition", {
            ":surveyInfoUid": surveyInfoUid,
          })}
        >
          次へ
        </Button>
      </StickyStack>
    </Stack>
  );
}
