import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function ImprovementActionListHelpDialog() {
  return (
    <HelpButton dialogTitle="改善アクションリストの説明">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "改善アクションリストとは",
            content:
              "測定の結果、課題のあった睡眠の項目と睡眠に悪影響を与えている可能性がある生活習慣をリスト形式で表示しています。ライフスタイルに合わせ、取り組みやすいものから行動に移すようにしてください。（改善に取り組む優先順位を付ける際は、原因分析タブの活用をおすすめします）",
          },
        ]}
      />
    </HelpButton>
  );
}
