import { ScrollToTop } from "@/components/common/ScrollToTop";
import { PrimaryInterviewPageSummary } from "@/components/examinee/interview/PageSummary";
import {
  InterviewAnswer,
  InterviewEntry,
} from "@/components/examinee/interview/Types";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import {
  GetNextPageHandler,
  GetNextScreenHandler,
  Interview,
  PostAnswerHandler,
} from "@/sleep_compass_lite/components/target/Interview";
import { TargetAuthenticated as Authenticated } from "@/sleep_compass_lite/components/target/TargetAuthenticated";
import { interviewPath } from "@/sleep_compass_lite/domain_models/target/InterviewPath";
import { useLiteDailyInterviewAnnounce } from "@/sleep_compass_lite/use_cases/target/interview/UseDailyInterviewAnnounce";
import { usePrimaryInterview } from "@/sleep_compass_lite/use_cases/target/interview/UsePrimaryInterview";
import { useParams } from "react-router-dom";

/**
 * 事前問診ページ
 */
export function PrimaryInterview({ headerHeight }: ExamineePageProps) {
  const { surveyInfoUid, interviewId, screen } = useParams();
  const { postAnswer: postInterviewAnswer } = usePrimaryInterview();
  const { postAnnounce } = useLiteDailyInterviewAnnounce();

  const postAnswer: PostAnswerHandler = async (
    interviewAnswer: InterviewAnswer
  ) => {
    if (surveyInfoUid == null) {
      throw new Error(`invalid param, surveyInfoUid: ${surveyInfoUid}`);
    }

    return postInterviewAnswer(surveyInfoUid, interviewAnswer);
  };

  const getNextScreen: GetNextScreenHandler = (entry: InterviewEntry) => {
    if (surveyInfoUid == null) {
      throw new Error(`invalid param, surveyInfoUid: ${surveyInfoUid}`);
    }

    return Promise.resolve(
      interviewPath.getPrimaryInterviewPath(surveyInfoUid, entry)
    );
  };

  const getNextPage: GetNextPageHandler = async () => {
    if (surveyInfoUid == null) {
      throw new Error(`invalid param, surveyInfoUid: ${surveyInfoUid}`);
    }

    const response = await postAnnounce(surveyInfoUid);
    if (response.status === 201 && response.data?.id != null) {
      // 現在、回答可能な毎日問診がある場合は、毎日問診に接続する画面へ
      return interviewPath.getInterviewConnectionPath(
        surveyInfoUid,
        response.data.id
      );
    } else {
      // 現在、回答可能な毎日問診がない場合は、事前問診完了画面へ
      return interviewPath.getFullPath("PrimaryInterviewCompleted", {
        ":surveyInfoUid": surveyInfoUid,
      });
    }
  };

  if (surveyInfoUid == null || interviewId == null || screen == null) {
    throw new Error(
      `invalid params, ${surveyInfoUid}, ${interviewId}, ${screen}`
    );
  }

  return (
    <Authenticated>
      <ScrollToTop />
      <Interview
        pageProps={{ headerHeight: headerHeight }}
        context={{
          surveyInfoUid: surveyInfoUid,
          interviewId: interviewId,
          screenId: screen,
          pageSummaryCreator: (questionScreen) =>
            new PrimaryInterviewPageSummary(questionScreen),
          postAnswer: postAnswer,
          getNextScreen: getNextScreen,
          getNextPage: getNextPage,
        }}
      />
    </Authenticated>
  );
}
