import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import HelpButton from "../HelpButton";
import { Range } from "../sleep_summaries";
import AnnotationTypography from "./criterions/AnnotationTypography";
import CriterionTableContainer, {
  Cells,
} from "./criterions/CriterionTableContainer";

export default function QuantityCriterionDialog({
  age_range,
  recommended,
  appropriate,
}: {
  /** 年齢の属するグループ */
  age_range: string | undefined;
  /** 推奨睡眠時間 */
  recommended: Range | undefined;
  /** 適切睡眠時間 */
  appropriate: Range | undefined;
}) {
  return (
    <HelpButton
      dialogTitle="睡眠の長さの判定基準"
      dialogProps={{
        maxWidth: "lg",
      }}
    >
      <CriterionTableContainer
        sx={{
          "& td.MuiTableCell-root:nth-child(2)": {
            backgroundColor: Cells.color.good,
          },
          "& td.MuiTableCell-root:nth-child(n+3):nth-child(-n+5)": {
            backgroundColor: Cells.color.caution,
          },
          "& td.MuiTableCell-root:last-child": {
            backgroundColor: Cells.color.bad,
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <Typography variant="subtitle2">判定の仕組み</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ boxSizing: "border-box", width: "200px" }}>
                <Typography variant="subtitle2">平均睡眠時間</Typography>
                <Stack>
                  <Typography variant="caption">
                    推奨範囲：{recommended?.low}-{recommended?.high}時間（
                    {age_range} ）
                  </Typography>
                  <Typography variant="caption">
                    適切睡眠時間：{appropriate?.low}-{appropriate?.high}時間（
                    {age_range}）
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell sx={{ boxSizing: "border-box", width: "140px" }}>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
              <TableCell sx={{ boxSizing: "border-box", width: "140px" }}>
                <Typography variant="body2">
                  推奨範囲外だが、
                  <br />
                  適切睡眠時間内
                </Typography>
              </TableCell>
              <TableCell sx={{ boxSizing: "border-box", width: "140px" }}>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
              <TableCell sx={{ boxSizing: "border-box", width: "140px" }}>
                <Typography variant="body2">
                  推奨範囲外だが、
                  <br />
                  適切睡眠時間内
                </Typography>
              </TableCell>
              <TableCell sx={{ boxSizing: "border-box", width: "140px" }}>
                <Typography variant="body2">適切睡眠時間外</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  睡眠時間が短すぎる<sup>※</sup>日
                </Typography>
                <Stack>
                  <Typography variant="caption">
                    ※{appropriate?.low}時間未満（{age_range}）
                  </Typography>
                  <Typography variant="caption">推奨範囲：0日/週</Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">推奨範囲外</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">推奨範囲外</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">（問わない）</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">判定</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">良好</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">注意</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">注意</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">注意</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">警戒</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CriterionTableContainer>
      <AnnotationTypography>
        ※測定データが4日に満たない場合は判定不能。
      </AnnotationTypography>
    </HelpButton>
  );
}
