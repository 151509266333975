import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { EmailAddress } from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import { EmailVerificationCode } from "@/sleep_compass_lite/domain_models/target/EmailVerificationCode";
import { Password } from "@/sleep_compass_lite/domain_models/target/Password";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError } from "axios";
import { useCallback } from "react";

/** useConfirmForgetPassword PostConfirmForgetPassword 型宣言 */
type PostConfirmForgetPassword = (
  email: EmailAddress,
  password: Password,
  code: EmailVerificationCode
) => Promise<void>;

/**
 * パスワード再設定APIを呼び出すクロージャーを返すカスタムフック。カスタムフックで実装している理由は、useAxios(カスタムフック)を利用するため。
 */
export function useConfirmForgetPassword() {
  const axios = useAxios();

  /**
   * メールアドレス検証コードを確認し、新規パスワードを設定する
   *
   * @param email 対象者ユーザーのメールアドレス
   * @param password 新規パスワード
   * @param code メールアドレス検証コード
   */
  const postNewPassword: PostConfirmForgetPassword = useCallback(
    async (email, password, code) => {
      try {
        await axios.post("/api/confirm_forget_password/", {
          username: email.value,
          password: password.value,
          confirmation_code: code.value,
        });
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  return { postNewPassword };
}
