import { facilityAxios as axios } from "@/utils/axios";

/**
 * レポート取得APIを呼び出し、レスポンスを返す
 * @param url 対象レポートのURL
 * @returns APIのレスポンス(レポートのバイナリーデータ)
 */
export function getReportPDF(url: string) {
  return axios.get(url, {
    responseType: "arraybuffer",
  });
}
