import Authenticated from "@/components/auth/Authenticated";
import { AppBarProps } from "@/components/elearning/common/AppBar";
import elearningConstants from "@/components/elearning/common/constants";
import { ExamineeFooter as Footer } from "@/components/examinee/ExamineeFooter";
import { SCAppBar as ExamineeAppBar } from "@/components/examinee/SCAppBar";
import ElearningContentsLayout from "@/pages/elearning/ElearningContentsLayout";
import Quiz from "@/pages/elearning/quiz/Quiz";
import { examineeTheme } from "@/theme/themeV1";
import constants from "@/utils/constants";
import { Close, Menu } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  MenuItem,
  MenuList,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { createContext, useContext, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import QuizStart from "./quiz/QuizStart";
import QuizResult from "./quiz_result/QuizResult";

class ElearningParam {
  readonly id: string | null;

  constructor(param: ElearningParam) {
    this.id = param.id;
  }
}

const ElearningParamsContext = createContext<ElearningParam>({
  id: null,
});

/**
 * Elearning を使用するうえで必須のクエリパラメータを持ちまわすためのカスタムフックです。
 * 内部で createContext を使用しているため、このLayoutを使用していない場合、正常に動きません。
 * ※ 本来であれば、export せずにすむように各 component の構成を変えるべきですが、規模が大きくなるため暫定対応としています。
 *
 * @param init Elearning の必須パラメータとは別に追加する {@link URLSearchParams} の引数。
 * @returns Elearning の必須パラメータと引数で受けたパラメータで構築した {@link URLSearchParams}.
 *
 */
export function useElearningSearchParams(
  init?:
    | string
    | URLSearchParams
    | string[][]
    | Record<string, string>
    | undefined
) {
  const context = useContext(ElearningParamsContext);
  const params = new URLSearchParams(init);
  for (const [key, value] of Object.entries(context)) {
    if (value != null) {
      params.append(key, value);
    }
  }
  return params;
}

function parseQuery(query: string) {
  const params = new URLSearchParams(query);
  return new ElearningParam({
    id: params.get("id"),
  });
}

function ElearningMenu({
  open,
  hideMenu,
}: {
  open: boolean;
  hideMenu: () => void;
}) {
  const elearningSearchParams = useElearningSearchParams();
  return (
    <Dialog
      sx={{ borderRadius: "0px" }}
      fullScreen
      open={open}
      onClose={hideMenu}
      PaperProps={{
        style: {
          borderRadius: "0px",
        },
      }}
    >
      <Box sx={{ height: "100%", backgroundColor: "#003C70" }}>
        <ExamineeAppBar
          height={elearningConstants.APP_BAR_HEIGHT}
          color="reverse"
          menuButton={
            <IconButton
              aria-label="ElearningMenu close button"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={hideMenu}
              color="inherit"
            >
              <Close />
            </IconButton>
          }
        />
        <Box
          sx={{
            height: `calc(100% - ${elearningConstants.APP_BAR_HEIGHT}px)`,
            color: "#FFFFFF",
            display: "grid",
            alignItems: "center",
          }}
        >
          <MenuList>
            <MenuItem>
              <Button
                component={Link}
                to={`/elearning/quiz_start?${elearningSearchParams}`}
                sx={{ margin: "auto" }}
                onClick={hideMenu}
              >
                <Typography variant="h5" color="#FFFFFF">
                  睡眠クイズ
                </Typography>
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                component={Link}
                to={`/elearning/knowledge_and_tips?${elearningSearchParams}`}
                sx={{ margin: "auto" }}
                onClick={hideMenu}
              >
                <Typography variant="h5" color="#FFFFFF">
                  睡眠について学ぶ
                </Typography>
              </Button>
            </MenuItem>
          </MenuList>
        </Box>
      </Box>
    </Dialog>
  );
}

function ElearningContent({
  path,
  onClickMenu,
}: {
  path: string;
  onClickMenu: AppBarProps["onClickMenu"];
}) {
  const background = path === "quiz" ? "#F6F5F4" : null;

  return (
    <Box
      sx={{ minHeight: "100vh", position: "relative", background: background }}
    >
      <ExamineeAppBar
        height={elearningConstants.APP_BAR_HEIGHT}
        menuButton={
          <IconButton
            aria-label="hamburger menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="primary"
            onClick={onClickMenu}
          >
            <Menu />
          </IconButton>
        }
      />
      <Container
        maxWidth="sm"
        sx={{
          pt: 0,
          pl: 0,
          pr: 0,
          pb: `${elearningConstants.FOOTER_HEIGHT}px`,
        }}
      >
        <Routes>
          <Route path="/quiz_start" element={<QuizStart />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/quiz_result" element={<QuizResult />} />
          <Route path="/*" element={<ElearningContentsLayout />} />
        </Routes>
      </Container>
      <Footer
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: elearningConstants.FOOTER_HEIGHT,
        }}
      />
    </Box>
  );
}

export default function ElearningLayout() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const params = parseQuery(location.search);
  const pathnames = location.pathname.split("/");
  const path = pathnames[pathnames.length - 1];

  return (
    <ElearningParamsContext.Provider value={params}>
      <ThemeProvider theme={examineeTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <Authenticated redirectTo={constants.SIGNIN_URL_EXAMINEE}>
            <ElearningContent
              path={path}
              onClickMenu={() => setShowMenu(true)}
            />
            <ElearningMenu
              open={showMenu}
              hideMenu={() => setShowMenu(false)}
            />
          </Authenticated>
        </LocalizationProvider>
      </ThemeProvider>
    </ElearningParamsContext.Provider>
  );
}
