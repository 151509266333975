import { DailyInterviewItem as DailyInterviewItemObject } from "@/sleep_compass_lite/data_transfer_objects/target/DailyInterviewInfo";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { ApiResponse } from "@/sleep_compass_lite/domain_models/target/ApiResponse";
import { DailyInterviewItem } from "@/sleep_compass_lite/domain_models/target/DailyInterviewInfo";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback } from "react";

/** useLiteDailyInterviewAnnounce postAnnounce 型宣言 */
type PostDailyInterviewAnnounce = (
  surveyInfoUid: string
) => Promise<ApiResponse<DailyInterviewItem | null>>;

/**
 * 毎日問診メールを送信するクロージャーを返すカスタムフック。カスタムフックで実装している理由は、useAxios(カスタムフック)を利用するため。
 */
export function useLiteDailyInterviewAnnounce() {
  const axios = useAxios();

  /**
   * 毎日問診メールの送信
   *
   * 毎日問診メールAPIのレスポンス(HTTPステータスコード)について
   * 201: メール送信完了、レスポンス本体にメールに対応する毎日問診の情報が格納されている
   * 204: メール未送信、レスポンス本体は空、回答可能な毎日問診がない場合などにこのレスポンスが返される
   *
   * @param surveyInfoUid サーベイ情報のUUID
   * @returns 毎日問診情報 {@link DailyInterviewItem}
   */
  const postAnnounce: PostDailyInterviewAnnounce = useCallback(
    async (surveyInfoUid) => {
      try {
        const response: AxiosResponse<DailyInterviewItemObject | null> =
          await axios.post(`/api/lite/daily_interview_announce/`, {
            survey_info: surveyInfoUid,
          });

        const data: DailyInterviewItem | null =
          response.data != null
            ? DailyInterviewItem.create(response.data)
            : null;

        return new ApiResponse<DailyInterviewItem | null>(
          data,
          response.status
        );
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  return { postAnnounce };
}
