import { SubtitleTypography } from "@/components/common/Typographies";
import { TableRow } from "@/components/report/disease_risk/medical_facility_report/commons";
import { HelpOutline } from "@mui/icons-material";
import {
  Box,
  Chip,
  ChipProps,
  FormControl,
  IconButton,
  IconButtonProps,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import Axios from "axios";
import { ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BorderlessTableContainer } from "..";
import { useRetrospective } from "../apis";
import AlcoholHelpDialog from "../commons/dialogs/AlcoholHelpDialog";
import BathHelpDialog from "../commons/dialogs/BathHelpDialog";
import BedtimeEnvironmentHelpDialog from "../commons/dialogs/BedtimeEnvironmentHelpDialog";
import BeforeSleepMindHelpDialog from "../commons/dialogs/BeforeSleepMind";
import BluelightHelpDialog from "../commons/dialogs/BluelightHelpDialog";
import BodyConditionHelpDialog from "../commons/dialogs/BodyConditionHelpDialog";
import CaffeineHelpDialog from "../commons/dialogs/CaffeineHelpDialog";
import ExerciseHelpDialog from "../commons/dialogs/ExerciseHelpDialog";
import LightHelpDialog from "../commons/dialogs/LightHelpDialog";
import MindHelpDialog from "../commons/dialogs/MindHelpDialog";
import NapHelpDialog from "../commons/dialogs/NapHelpDialog";
import NightmealHelpDialog from "../commons/dialogs/NightmealHelpDialog";
import OtherHelpDialog from "../commons/dialogs/OtherHelpDialog";
import RhythmHelpDialog from "../commons/dialogs/RhythmHelpDialog";
import SleepTimeHelpDialog from "../commons/dialogs/SleepTimeHelpDialog";
import TobaccoHelpDialog from "../commons/dialogs/TobaccoHelpDialog";
import {
  EaseOfImprovementType,
  RetrospectiveItem,
  RetrospectiveQuantityAndRhythmItem,
  RetrospectiveUpdateData,
  TopicType,
} from "../commons/retrospectives";

const bindLabelTemplate = (text: string, count: number | null) => {
  if (count == null) {
    if (text === "注意してください") {
      return "気持ちの落ち込み傾向：傾向あり";
    } else if (text === "特に問題ありません") {
      return "気持ちの落ち込み傾向：傾向小";
    } else {
      return `${text}`;
    }
  } else {
    return `${text}: ${count}回`;
  }
};

const toLabel = (item?: RetrospectiveItem) => {
  if (item == null || item.answers.length === 0) {
    return "未回答";
  }
  return item.answers.map((a, i) => (
    <Typography
      key={i}
      variant="body2"
      color={a.is_ng_answer ? "error.main" : ""}
    >
      {bindLabelTemplate(a.text, a.count)}
    </Typography>
  ));
};

type RecommendMarkProps = Omit<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  "src"
> & {
  isWarning?: boolean;
};
/**
 * 改善を推奨しているマーク
 *
 * @param props {@link RecommendMarkProps}
 * @returns 改善を推奨しているマーク
 */
export function RecommendMark({
  isWarning = false,
  ...props
}: RecommendMarkProps) {
  return (
    <img
      src={`/img/image_report_${isWarning ? "" : "un"}mark.svg`}
      alt="mark"
      width="8"
      height="8"
      loading="lazy"
      {...props}
    ></img>
  );
}

interface RetrospectiveSectionProps<
  T extends TopicType,
  D extends RetrospectiveQuantityAndRhythmItem | RetrospectiveItem
> {
  title: string;
  order: T[];
  data?: Record<T, D>;
  onChange: (newData: Record<T, D>) => void;
}
/**
 *  原因分析共通のセクション
 * @param props {@link RetrospectiveSectionProps}
 * @returns 原因分析共通のセクション
 */
export function RetrospectiveSection<
  T extends TopicType,
  D extends RetrospectiveQuantityAndRhythmItem | RetrospectiveItem
>({ title, order, data, onChange }: RetrospectiveSectionProps<T, D>) {
  const configs: Record<TopicType, { title: string; help: ReactNode }> = {
    rhythm: { title: "リズム", help: <RhythmHelpDialog /> },
    quantity: { title: "睡眠時間", help: <SleepTimeHelpDialog /> },
    body: { title: "体の調子", help: <BodyConditionHelpDialog /> },
    mind: {
      title: "こころの調子",
      help: <MindHelpDialog />,
    },
    before_sleep_mind: {
      title: "寝る前の気分",
      help: <BeforeSleepMindHelpDialog />,
    },
    exercise: { title: "運動", help: <ExerciseHelpDialog /> },
    nightmeal: { title: "夜食", help: <NightmealHelpDialog /> },
    alcohol: { title: "アルコール", help: <AlcoholHelpDialog /> },
    bluelight: { title: "ブルーライト", help: <BluelightHelpDialog /> },
    room_light: { title: "部屋の明かり", help: <LightHelpDialog /> },
    nap: { title: "仮眠", help: <NapHelpDialog /> },
    caffeine: { title: "カフェイン", help: <CaffeineHelpDialog /> },
    bath: { title: "入浴", help: <BathHelpDialog /> },
    tobacco: { title: "喫煙", help: <TobaccoHelpDialog /> },
    bedtime_environment: {
      title: "就寝環境",
      help: <BedtimeEnvironmentHelpDialog />,
    },
    other: { title: "その他", help: <OtherHelpDialog /> },
  } as const;
  const onChangeSection = useCallback(
    (topic: T, record: D) => {
      if (data == null) return;
      const copy = { ...data };
      copy[topic] = record;
      onChange(copy);
    },
    [data, onChange]
  );
  return (
    <Stack sx={{ gap: 6 }}>
      <SubtitleTypography>{title}</SubtitleTypography>
      <BorderlessTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: "160px" }}>
                <Typography variant="subtitle2">項目</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: "240px" }}>
                <Typography variant="subtitle2">結果</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: "160px" }}>
                <Typography variant="subtitle2">影響因子</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: "160px" }}>
                <Typography variant="subtitle2">改善のしやすさ</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">メモ</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.map((o, i) => (
              <RetrospectiveTableRow
                key={i}
                topic={o}
                config={configs[o]}
                data={data?.[o]}
                onChange={onChangeSection}
              />
            ))}
          </TableBody>
        </Table>
      </BorderlessTableContainer>
    </Stack>
  );
}

interface EaseOfImprovementProps {
  value: EaseOfImprovementType | null | undefined;
  onChange: (selected: EaseOfImprovementType | null) => void;
}
/**
 * 改善のしやすさ
 * @param props {@link EaseOfImprovementProps}
 * @returns 改善のしやすさ
 */
function EaseOfImprovement({ value, onChange }: EaseOfImprovementProps) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as EaseOfImprovementType);
  };
  const handleDelete = () => {
    onChange(null);
  };

  const order: EaseOfImprovementType[] = ["easy", "normal", "hard"];
  const config: Record<
    EaseOfImprovementType,
    {
      label: string;
      color: ChipProps["color"];
    }
  > = {
    easy: {
      label: "かんたん",
      color: "success",
    },
    normal: {
      label: "ふつう",
      color: "default",
    },
    hard: {
      label: "むずかしい",
      color: "error",
    },
  };
  return (
    <>
      {value != null ? (
        <Chip {...config[value]} onDelete={handleDelete} />
      ) : (
        <FormControl fullWidth>
          <InputLabel>選択する</InputLabel>
          <Select
            variant="standard"
            value={value ?? ""}
            label="選択する"
            onChange={handleChange}
            disableUnderline
          >
            {order.map((o, i) => (
              <MenuItem value={o} key={i}>
                {config[o].label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}

interface HelpIconButtonProps extends IconButtonProps {}
/**
 * ヘルプアイコンボタン
 * @param props {@link HelpIconButtonProps}
 * @returns ヘルプアイコンボタン
 */
export function HelpIconButton({ ...props }: HelpIconButtonProps) {
  return (
    <IconButton {...props}>
      <HelpOutline
        sx={{
          color: "text.disabled",
          "&:hover": {
            color: "text.secondary",
          },
          fontSize: "14px",
        }}
      />
    </IconButton>
  );
}

function RetrospectiveTableRow<
  T extends TopicType,
  D extends RetrospectiveQuantityAndRhythmItem | RetrospectiveItem
>({
  topic,
  config,
  data,
  onChange,
}: {
  topic: T;
  config: { title: string; help: ReactNode };
  data?: D;
  onChange: (topic: T, data: D) => void;
}) {
  const { uuid } = useParams<{ uuid: string }>();
  const api = useRetrospective();
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const label = useCallback(() => {
    if (["bedtime_environment", "other"].includes(topic)) {
      return "-";
    }
    return data?.hasOwnProperty("result") ? (
      <Typography variant="body2" color={data.is_warning ? "error.main" : ""}>
        {(data as RetrospectiveQuantityAndRhythmItem).result ?? "-"}
      </Typography>
    ) : (
      toLabel(data as RetrospectiveItem)
    );
  }, [data, topic]);

  const update = useCallback(
    async (updated: RetrospectiveUpdateData) => {
      if (uuid == null || data == null) return;
      try {
        const res = await api.post(uuid, updated);
        onChange(topic, {
          ...data,
          ...updated,
          updated_at: res.data.updated_at,
        });
        setErrorMessage("");
      } catch (e: unknown) {
        if (Axios.isAxiosError(e)) {
          if (e.response == null) return;
          const data = e.response.data as Record<string, string[]>;
          for (const [, messages] of Object.entries(data)) {
            setErrorMessage(t(messages[0]));
            break;
          }
        } else {
          throw e;
        }
      }
    },
    [uuid, api, t, onChange, data, topic]
  );

  return (
    <TableRow>
      <TableCell>
        {/** 項目 */}
        <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
          <RecommendMark isWarning={data?.is_warning} />
          <Typography variant="body2">{config.title}</Typography>
          {config.help}
        </Stack>
      </TableCell>
      <TableCell>
        {/** 結果 */}
        {label()}
      </TableCell>
      <TableCell>
        <Stack sx={{ gap: 2 }}>
          {/** 影響因子 */}
          {data?.factor?.length
            ? data?.factor?.map((f, i) => (
                <Box key={i}>
                  <Chip label={f} />
                </Box>
              ))
            : "-"}
        </Stack>
      </TableCell>
      <TableCell>
        {/** 改善のしやすさ */}
        <EaseOfImprovement
          value={data?.ease_of_improvement}
          onChange={(v) => {
            if (data == null) return;
            update({
              topic,
              ease_of_improvement: v,
              comment: data.comment,
              updated_at: data.updated_at,
            });
          }}
        />
      </TableCell>
      <TableCell
        sx={{
          "&>textarea::placeholder": {
            padding: "1em",
            font: "inherit",
            fontSize: "14px",
            position: "relative",
          },
        }}
      >
        <TextField
          fullWidth
          multiline
          rows={4}
          error={Boolean(errorMessage.length)}
          helperText={errorMessage}
          label="メモを追加"
          variant="standard"
          value={data?.comment ?? ""}
          onChange={(e) => {
            if (data == null) return;
            onChange(topic, {
              ...data,
              comment: e.target.value,
            });
          }}
          onBlur={(e) => {
            if (data == null) return;
            update({
              topic,
              ease_of_improvement: data.ease_of_improvement,
              comment: e.target.value,
              updated_at: data.updated_at,
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
}
