import { Box, CssBaseline } from "@mui/material";
import { ReactNode } from "react";

type LayoutProps = {
  children: ReactNode;
};
function Layout(props: LayoutProps) {
  return (
    <Box>
      <CssBaseline />

      {props.children}
    </Box>
  );
}

export default Layout;
