import {
  InterviewAnswer,
  InterviewEntry,
} from "@/components/examinee/interview/Types";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError } from "axios";
import { useCallback } from "react";

/** usePrimaryInterview getEntry 型宣言 */
type GetPrimaryInterviewEntry = (
  surveyInfoUid: string
) => Promise<InterviewEntry>;

/** usePrimaryInterviewAnswer postAnswer 型宣言 */
type PostPrimaryInterviewAnswer = (
  surveyInfoUid: string,
  answer: InterviewAnswer
) => Promise<InterviewEntry>;

/***
 * PrimaryInterviewの最初の質問を取得するクロージャーを返すカスタムフック。カスタムフックで実装している理由は、useAxios(カスタムフック)を利用するため。
 */
export function usePrimaryInterview() {
  const axios = useAxios();

  /**
   * 事前問診のメタ情報(InterviewEntry)の取得
   * InterviewEntryは、事前問診の質問を得るために必要。質問を得るにあたって、InterviewEntryに含まれるInterviewのIDやScreenのIDを使う。
   * @param surveyInfoUid サーベイ情報のUUID
   * @returns 事前問診のメタ情報
   */
  const getEntry: GetPrimaryInterviewEntry = useCallback(
    async (surveyInfoUid) => {
      try {
        const response = await axios.get(getApiPath(surveyInfoUid));
        return response.data;
      } catch (e) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  /**
   * 回答を送信
   * @param surveyInfoUid サーベイ情報のUUID
   * @param answer 回答
   * @returns 事前問診のメタ情報(次の質問画面のID)
   */
  const postAnswer: PostPrimaryInterviewAnswer = useCallback(
    async (surveyInfoUid: string, answer: InterviewAnswer) => {
      try {
        const response = await axios.post(getApiPath(surveyInfoUid), answer);
        return response.data;
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  return { getEntry, postAnswer };
}

/**
 * 事前問診のメタ情報取得API/回答APIのパスを返す
 * @param surveyInfoUid サーベイ情報のUID
 * @returns メタ情報取得API/回答APIのパス
 */
function getApiPath(surveyInfoUid: string): string {
  return `/api/lite/${surveyInfoUid}/primary-interview/`;
}
