import { PASSWORD_SYMBOL_REGEX } from "@/components/activation/Constants";
import { NonFieldError } from "@/components/common/SCAlert";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { getApiServerUrl } from "@/utils/axios";
import { Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PasswordCheckListItem } from "./SignUp";

// このページが受け取るstateの型
export type StateType = {
  email: string;
  isSecondUser: boolean; // 2回目以降の受診者かどうか
};

export const SetNewPassword = () => {
  const API_URL = getApiServerUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const { email, isSecondUser } = (location.state as StateType) || {};

  if (!email) {
    return <GeneralError to="/examinee/forgot_password" buttonText="戻る" />;
  }

  const validators: Record<string, () => string[] | null> = {
    // validatorは引数を受け取らず実行され、エラーが無ければ何もreturnしない。
    // エラーがあった場合はエラーメッセージの配列を返す。
    confirmationCode: () => {
      if (!confirmationCode.match(/^\d{6}$/)) {
        return ["※半角数字6桁で入力してください"];
      }
      return null;
    },
    passwordLength: () => {
      if (password.length < 8 || 24 < password.length) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordAlphabetLowerAndUpper: () => {
      if (!(password.match(/[a-z]/) && password.match(/[A-Z]/))) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordDigit: () => {
      if (!password.match(/\d/)) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordSymbol: () => {
      if (!password.match(PASSWORD_SYMBOL_REGEX)) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordInvalidChars: () => {
      if (
        password.replace(PASSWORD_SYMBOL_REGEX, "").replace(/[a-zA-Z\d]/g, "")
      ) {
        return ["※使用できない文字が含まれています"];
      }
      return null;
    },
    password2SameAsPassword: () => {
      if (password === "" || password2 === "") {
        // password や password2 が入力されていない場合、チェックを行わない。
        // (初期表示時や password を入力した瞬間にエラーが出てしまうため)
        return null;
      }
      // パスワードとパスワード(確認)が一致しているか
      if (password !== password2) {
        return ["※パスワードが一致しません"];
      }
      return null;
    },
    password2InvalidChars: () => {
      // パスワードに使用できない文字が含まれていないか
      if (
        password2.replace(PASSWORD_SYMBOL_REGEX, "").replace(/[a-zA-Z\d]/g, "")
      ) {
        return ["※使用できない文字が含まれています"];
      }
      return null;
    },
    passwordsNotEmpty: () => {
      if (password === "" || password2 === "") {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
  };

  const handleClickRegister = () => {
    if (Object.values(validators).some((v) => v())) {
      // 何かしらのエラーがある場合は登録処理を行わない
      return;
    }
    axios
      .post(`${API_URL}/api/confirm_forget_password/`, {
        username: email,
        password: password,
        confirmation_code: confirmationCode,
      })
      .then((res) => {
        navigate(isSecondUser ? "/examinee/signin2" : "/examinee/signin", {
          state: {
            successMessage:
              "パスワードの再設定が完了しました。設定したパスワードでログインしてください。",
          },
        });
      })
      .catch((err) => {
        const statusCode = err.response?.status;
        const messages = err.response?.data?.error_messages;
        if (
          statusCode &&
          400 <= statusCode &&
          statusCode < 500 &&
          messages &&
          messages.length > 0
        ) {
          // 400系エラーでエラーメッセージが存在する場合はそれを表示する
          setErrorMessage(messages[0]);
        } else {
          setErrorMessage(
            "エラーが発生しました。申し訳ありませんが、少し時間をおいてから再度お試しください。"
          );
        }

        // エラーアラートが画面内に表示されるようにスクロールをリセットする
        window.scrollTo(0, 0);
      });
  };

  return (
    <>
      <Stack spacing={8} sx={{ mt: 8, mb: 12, mx: 4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            パスワードを再設定
          </Typography>

          {/* エラーメッセージ */}
          <NonFieldError>{errorMessage}</NonFieldError>

          <Typography
            variant="subtitle2"
            textAlign="justify"
            sx={{ color: "text.secondary" }}
          >
            登録したメールアドレスにパスワード再設定用の確認コードをお送りしました。確認コードと新しいパスワードを入力してください。
          </Typography>

          <TextForm
            title="確認コード"
            placeholder="数字6桁"
            value={confirmationCode}
            onChange={(e) => {
              setConfirmationCode(e.target.value);
            }}
            validator={validators.confirmationCode}
          />
          <TextForm
            title="新しいパスワード"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type="password"
            helperText={
              <Stack>
                <PasswordCheckListItem
                  text="8文字以上24文字以下にしてください"
                  validator={validators.passwordLength}
                />
                <PasswordCheckListItem
                  text="大文字と小文字の半角英字を含めてください"
                  validator={validators.passwordAlphabetLowerAndUpper}
                />
                <PasswordCheckListItem
                  text="半角数字を含めてください"
                  validator={validators.passwordDigit}
                />
                <PasswordCheckListItem
                  text="記号を含めてください"
                  validator={validators.passwordSymbol}
                />
              </Stack>
            }
            givenErrors={validators.passwordInvalidChars()}
          />
          <TextForm
            title="新しいパスワード（確認）"
            value={password2}
            onChange={(e) => {
              setPassword2(e.target.value);
            }}
            type="password"
            givenErrors={
              validators.password2InvalidChars() ||
              validators.password2SameAsPassword()
            }
          />
        </Stack>
      </Stack>
      <Stack>
        <Button
          variant="contained"
          size="large"
          sx={{ mx: 4, mb: 16 }}
          onClick={handleClickRegister}
          disabled={Object.values(validators).some((v) => v())}
        >
          設定する
        </Button>
      </Stack>
    </>
  );
};
