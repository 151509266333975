import { ExamineeFooter as Footer } from "@/components/examinee/ExamineeFooter";
import { Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ReportImg } from "../commons/ReportImg";
import ReportNavigate from "../commons/ReportNavigate";
import SubtitleTypography from "../commons/SubtitleTypography";
import { ImprovementAction } from "../types";
import { useSummaryPageContext } from "./Summary";

function ImprovementActionList(props: {
  improvement_action_list: ImprovementAction[];
}) {
  return (
    <Stack sx={{ m: "24px 0px 24px 0px" }}>
      <Stack
        sx={{
          backgroundColor: "#EAEAEA",
          borderRadius: "8px",
          p: "16px 16px 24px 16px",
        }}
      >
        <ReportImg image_name="image_report_summary_end_of_summary_binder.svg" />
        <Stack sx={{ backgroundColor: "#FFFFFF", borderRadius: "4px" }}>
          <Stack sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "primary.main",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "34px",
                pb: "16px",
              }}
            >
              改善アクションリスト
            </Typography>
          </Stack>
          {props.improvement_action_list.map((v, i) => (
            <Stack key={i} sx={{ p: "0px 16px 16px 16px" }}>
              <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                {v.name}
              </Typography>
              {v.comment != null && (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "13px",
                    color: "text.secondary",
                  }}
                >
                  {v.comment}
                </Typography>
              )}
              {v.actions.length > 0 && (
                <table>
                  <tbody>
                    {v.actions.map((v, j) => (
                      <React.Fragment key={j}>
                        <tr>
                          <td
                            style={{
                              verticalAlign: "top",
                              paddingTop: "5px",
                            }}
                          >
                            <ReportImg
                              style={{
                                width: "20px",
                                height: "20px",
                                display: "block",
                                margin: "auto",
                              }}
                              image_name="image_report_summary_end_of_summary_check.svg"
                            />
                          </td>
                          <td>
                            <Typography variant="subtitle1">
                              {v.title}
                            </Typography>
                          </td>
                        </tr>
                        {v.note && (
                          <tr>
                            <td></td>
                            <td>
                              <Typography variant="body2">{v.note}</Typography>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              )}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

/**
 * サマリーの「おわりに」ページ
 *
 * @param param0
 * @returns
 */
export default function EndOfSummary() {
  const context = useSummaryPageContext();
  const { userType, uuid } = context.props;
  const hasImprovementActionList =
    context.props.data.end_of_summary.improvement_action_list.length !== 0;
  return (
    <Stack>
      <Stack
        sx={{
          p: "24px 16px 0px 16px",
          height: "100%",
          backgroundColor: "#ffffff",
        }}
      >
        <Stack
          sx={{
            textAlign: "center",
          }}
        >
          <ReportImg
            style={{
              width: "220px",
              height: "220px",
              display: "block",
              margin: "auto",
            }}
            image_name="image_report_summary_end_of_summary_top.svg"
          />
          <Typography variant="h6" sx={{ pb: "16px" }}>
            HAVE A GOOD SLEEP
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "text.secondary", pb: "24px" }}
          >
            サマリーレポートは以上です。
            <br />
            {hasImprovementActionList
              ? "以下に今回の測定の結果、あなたが改善すべき項目をまとめました。ライフスタイルに合わせ、取り組みやすいものから行動に移してみてください。"
              : "今回の測定では改善すべき項目はありませんでした。これからも睡眠に良い生活習慣を継続しましょう。"}
          </Typography>
        </Stack>
        {hasImprovementActionList && (
          <ImprovementActionList
            improvement_action_list={
              context.props.data.end_of_summary.improvement_action_list
            }
          />
        )}
        <Stack sx={{ p: "24px 0px 80px 0px" }}>
          <Button
            variant="contained"
            component={Link}
            sx={{ boxShadow: "none" }}
            to={`/${userType}/report/${uuid}`}
          >
            レポートTOPへ戻る
          </Button>
        </Stack>
        <Divider />
        <Stack sx={{ mt: "32px" }}>
          <SubtitleTypography>より詳しく知りたい方へ</SubtitleTypography>
        </Stack>
        <ReportNavigate
          noMarginIcon
          reportUid={uuid}
          navigates={[
            {
              icon: {
                src: "/img/icon_report_quantity.svg",
                alt: "quantity",
              },
              text: "睡眠の長さ",
              link: `/${userType}/report/${uuid}/quantity`,
            },
            {
              icon: {
                src: "/img/icon_report_quality.svg",
                alt: "quality",
              },
              text: "睡眠の質",
              link: `/${userType}/report/${uuid}/quality`,
            },
            {
              icon: {
                src: "/img/icon_report_rhythm.svg",
                alt: "musical_note",
              },
              text: "睡眠のリズム",
              link: `/${userType}/report/${uuid}/rhythm`,
            },
            {
              icon: {
                src: "/img/icon_report_drowsiness.svg",
                alt: "drowsiness",
              },
              text: "日中の眠気",
              link: `/${userType}/report/${uuid}/drowsiness`,
            },
            {
              icon: {
                src: "/img/icon_report_fatigue.svg",
                alt: "fatigue",
              },
              text: "日中の疲労",
              link: `/${userType}/report/${uuid}/fatigue`,
            },
            {
              icon: {
                src: "/img/icon_report_lifestyle_and_health_conditions.svg",
                alt: "lifestyle_and_health_conditions",
              },
              text: "睡眠に影響する生活習慣",
              link: `/${userType}/report/${uuid}/lifestyle_and_health_conditions`,
            },
            {
              icon: {
                src: "/img/icon_report_measurement_details.svg",
                alt: "measurement_details",
              },
              text: "測定データの詳細",
              link: `/${userType}/report/${uuid}/measurement_details`,
            },
          ]}
        />
      </Stack>
      <Footer sx={{ pb: "16px" }} />
    </Stack>
  );
}
