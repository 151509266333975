import { ReportImg } from "@/components/report/commons/ReportImg";
import { OpenInNew } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonProps,
  Paper,
  PaperProps,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode, useCallback } from "react";
import { HeadTypography, MoveImprovement, SectionLayout } from ".";
import DaytimeConditionCriterionDialog from "../commons/dialogs/DaytimeConditionCriterionDialog";
import DiseaseRiskCriterionDialog from "../commons/dialogs/DiseaseRiskCriterionDialog";
import ImprovementActionListHelpDialog from "../commons/dialogs/ImprovementActionListHelpDialog";
import SleepConditionCriterionDialog from "../commons/dialogs/SleepConditionCriterionDialog";
import SleepImprovementNeededCriterionDialog from "../commons/dialogs/SleepImprovementNeededCriterionDialog";
import {
  EvaluationDetail,
  ImprovementComparisonType,
  SleepSummary,
} from "../commons/sleep_summaries";

interface EvaluationPaperProps extends PaperProps {
  title: string;
  result: ReactNode;
  last_result: ReactNode | null;
  improvement: ImprovementComparisonType | null | undefined;
  comment: string | null | undefined;
  button?: {
    variant?: ButtonProps["variant"];
    text: string;
    link: string | null;
  } | null;
  help: ReactNode;
}
function EvaluationPaper({
  title,
  result,
  improvement,
  last_result,
  comment,
  button,
  help,
}: EvaluationPaperProps) {
  return (
    <Paper variant="outlined" sx={{ p: "16px 24px", gap: 2 }} component={Stack}>
      <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
        <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
          {title}
        </Typography>
        {help}
      </Stack>
      <Stack direction="row" sx={{ gap: 4 }}>
        {typeof result === "string" ? (
          <Typography variant="h6">{result}</Typography>
        ) : (
          result
        )}
        <Stack direction="row" sx={{ alignItems: "center", gap: 2 }}>
          <MoveImprovement improvement={improvement} />
          {last_result != null && (
            <Typography variant="caption">前回：{last_result}</Typography>
          )}
        </Stack>
      </Stack>
      {comment != null && <Typography variant="body2">{comment}</Typography>}
      {button != null && (
        <Box flexDirection="row" justifyContent="flex-end" display="flex">
          <Button
            disableElevation
            variant={button.variant}
            onClick={() =>
              button.link != null && window.open(button.link, "_blank")
            }
            endIcon={<OpenInNew />}
          >
            {button.text}
          </Button>
        </Box>
      )}
    </Paper>
  );
}

interface EvaluationDetailPaperProps
  extends PaperProps,
    Partial<EvaluationDetail> {
  title: string;
  button?: {
    variant?: ButtonProps["variant"];
    text: string;
    link: string | null;
  };
  help: ReactNode;
}
function EvaluationDetailPaper({
  title,
  evaluation,
  last_time_evaluation,
  improvement,
  comment,
  button,
  help,
}: EvaluationDetailPaperProps) {
  return (
    <EvaluationPaper
      title={title}
      help={help}
      result={evaluation}
      last_result={last_time_evaluation}
      improvement={improvement}
      comment={comment}
      button={button}
    />
  );
}

interface MeasurementResultSectionProps {
  uuid?: string;
  measurement_result?: SleepSummary["measurement_result"];
}
/**
 * 測定結果
 *
 * @param props {@link MeasurementResultSectionProps}
 * @returns 測定結果
 */
export default function MeasurementResultSection({
  uuid,
  measurement_result,
}: MeasurementResultSectionProps) {
  const getButtonConfig = useCallback((): EvaluationPaperProps["button"] => {
    const grade = measurement_result?.comprehensive_evaluation.grade_alphabet;
    if (grade?.startsWith("E")) {
      return {
        text: "疾患リスクレポートを開く",
        variant: "contained",
        link: `${uuid == null ? "" : `/facility/report/${uuid}/disease_risk`}`,
      };
    }
    return null;
  }, [uuid, measurement_result?.comprehensive_evaluation.grade_alphabet]);
  return (
    <SectionLayout
      title="評価のまとめ"
      caption="デバイスによる睡眠の測定結果、問診による日中の状態、および睡眠時無呼吸症候群、不眠症の診断ガイドラインによる疾患リスクを組み合わせ、現在の睡眠が改善必要なのかを「睡眠改善必要度」として判定します。"
    >
      <Stack direction="row" sx={{ justifyContent: "space-between", gap: 8 }}>
        <Stack sx={{ width: "100%", gap: 2 }}>
          <HeadTypography>1. 総合評価</HeadTypography>
          <EvaluationPaper
            title="睡眠改善必要度"
            help={<SleepImprovementNeededCriterionDialog uuid={uuid} />}
            result={
              <Stack direction="row" sx={{ gap: 2, alignItems: "center" }}>
                <Typography variant="h6">
                  {measurement_result?.comprehensive_evaluation.grade_alphabet}
                </Typography>
                <Typography variant="h6">
                  {measurement_result?.comprehensive_evaluation.grade_comment}
                </Typography>
              </Stack>
            }
            improvement={
              measurement_result?.comprehensive_evaluation.improvement
            }
            last_result={
              measurement_result?.comprehensive_evaluation
                .last_time_grade_alphabet == null
                ? null
                : `${measurement_result?.comprehensive_evaluation.last_time_grade_alphabet}判定`
            }
            comment={measurement_result?.comprehensive_evaluation.comment}
            button={getButtonConfig()}
          />
        </Stack>

        <Stack sx={{ width: "100%", gap: 2 }}>
          <HeadTypography>2. 評価の内訳</HeadTypography>
          <Stack sx={{ gap: 4 }}>
            <EvaluationDetailPaper
              title="睡眠の状態"
              help={<SleepConditionCriterionDialog />}
              {...measurement_result?.evaluation_details.sleep_condition}
            />

            <EvaluationDetailPaper
              title="日中の状態"
              help={<DaytimeConditionCriterionDialog />}
              {...measurement_result?.evaluation_details.daytime_condition}
            />

            {measurement_result?.evaluation_details.disease_risk != null && (
              <EvaluationDetailPaper
                title="疾患のリスク"
                help={<DiseaseRiskCriterionDialog />}
                {...measurement_result?.evaluation_details.disease_risk}
                button={{
                  variant: "text",
                  text: "疾患リスクレポートを開く",
                  link: `${
                    uuid == null ? "" : `/facility/report/${uuid}/disease_risk`
                  }`,
                }}
              />
            )}
          </Stack>
        </Stack>

        <Stack sx={{ width: "100%", gap: 2 }}>
          <Stack flexDirection="row" sx={{ alignItems: "center", gap: 1 }}>
            <HeadTypography>3. 改善アクションリスト</HeadTypography>
            <ImprovementActionListHelpDialog />
          </Stack>
          <Paper
            variant="outlined"
            sx={{ p: "16px 24px", gap: 2 }}
            component={Stack}
          >
            <Stack sx={{ gap: 6 }}>
              {measurement_result?.improvement_action_list.length === 0 && (
                <Stack sx={{ gap: 2 }}>
                  <Typography variant="h6">課題なし</Typography>
                  <Typography variant="body2">
                    今回の測定では改善すべき項目はありませんでした。これからも睡眠に良い生活習慣を継続しましょう。
                  </Typography>
                </Stack>
              )}
              {measurement_result?.improvement_action_list.map(
                (improvementAction, improvementActionIndex) => (
                  <Stack key={improvementActionIndex} sx={{ gap: 2 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "text.secondary" }}
                    >
                      {improvementAction.name}
                    </Typography>
                    {improvementAction.comment != null && (
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        {improvementAction.comment}
                      </Typography>
                    )}
                    {improvementAction.actions.length > 0 && (
                      <>
                        {improvementAction.actions.map(
                          (action, actionIndex) => (
                            <Stack key={actionIndex} sx={{ gap: 2 }}>
                              <Stack flexDirection="row" sx={{ gap: 1 }}>
                                <ReportImg
                                  style={{ width: "29px", height: "29px" }}
                                  image_name="image_report_summary_end_of_summary_check.svg"
                                />
                                <Stack>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ color: "text.primary" }}
                                  >
                                    {action.title}
                                  </Typography>
                                  {action.note && (
                                    <Typography
                                      variant="body2"
                                      sx={{ color: "text.secondary" }}
                                    >
                                      {action.note}
                                    </Typography>
                                  )}
                                </Stack>
                              </Stack>
                            </Stack>
                          )
                        )}
                      </>
                    )}
                  </Stack>
                )
              )}
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </SectionLayout>
  );
}
