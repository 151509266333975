class ImageInfo {
  src: string;
  alt: string;

  constructor(src: string, alt: string) {
    this.src = src;
    this.alt = alt;
  }
}

const RightImageInfo = new ImageInfo("/img/answer_right.svg", "正解");
const WrongImageInfo = new ImageInfo("/img/answer_wrong.svg", "不正解");

type RightWrongImageProps = {
  isRight: boolean;
};
export function RightWrongImage({ isRight }: RightWrongImageProps) {
  const info = isRight ? RightImageInfo : WrongImageInfo;
  return (
    <img
      src={info.src}
      alt={info.alt}
      loading="lazy"
      style={{ width: 208, height: "auto" }}
    />
  );
}
