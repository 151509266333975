import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import HelpButton from "../HelpButton";
import AnnotationTypography from "./criterions/AnnotationTypography";
import CriterionTableContainer, {
  Cells,
} from "./criterions/CriterionTableContainer";

export default function QualityCriterionDialog() {
  return (
    <HelpButton
      dialogTitle="睡眠の質の判定基準"
      dialogProps={{
        maxWidth: "lg",
      }}
    >
      <CriterionTableContainer
        sx={{
          "& td.MuiTableCell-root:nth-child(2)": {
            backgroundColor: Cells.color.good,
          },
          "& td.MuiTableCell-root:nth-child(3)": {
            backgroundColor: Cells.color.caution,
          },
          "& td.MuiTableCell-root:nth-child(4)": {
            backgroundColor: Cells.color.bad,
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="subtitle2">判定の仕組み</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ boxSizing: "border-box", width: "282px" }}>
                <Typography variant="subtitle2">平均睡眠効率</Typography>
                <Typography variant="caption">推奨範囲：85%以上</Typography>
              </TableCell>
              <TableCell sx={{ boxSizing: "border-box", width: "204px" }}>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
              <TableCell sx={{ boxSizing: "border-box", width: "204px" }}>
                <Typography variant="body2">推奨範囲外</Typography>
              </TableCell>
              <TableCell sx={{ boxSizing: "border-box", width: "204px" }}>
                <Typography variant="body2">（問わない）</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  寝付きが悪かった日数（入眠困難）
                </Typography>
                <Typography variant="caption">推奨範囲：2日以下/週</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
              <TableCell rowSpan={3}>
                <Typography variant="body2">
                  どれか一つでも推奨範囲外
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  睡眠中の覚醒が多かった日数（中途覚醒）
                </Typography>
                <Typography variant="caption">推奨範囲：2日以下/週</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  早く目が覚めてしまった日数（早朝覚醒）
                </Typography>
                <Typography variant="caption">推奨範囲：2日以下/週</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">判定</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">良好</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">注意</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">警戒</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CriterionTableContainer>
      <AnnotationTypography>
        ※測定データが4日に満たない場合は判定不能。
      </AnnotationTypography>
    </HelpButton>
  );
}
