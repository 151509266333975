import { useScreenLock } from "@/components/ScreenLock";
import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { PasswordCheckListItem } from "@/sleep_compass_lite/components/target/PasswordCheckListItem";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import {
  EmailAddress,
  EmailAddressValidationError,
} from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import {
  Password,
  PasswordValidationError,
} from "@/sleep_compass_lite/domain_models/target/Password";
import { StateType as ConfirmationStateType } from "@/sleep_compass_lite/pages/target/authentication/Confirmation";
import {
  getEmailErrorMessages,
  getErrorMessage,
  getPasswordErrorMessages,
} from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { signUp } from "@/sleep_compass_lite/use_cases/target/authentication/SignUp";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  surveyInfo: string;
};

/**
 * サインアップページ
 */
export function SignUp() {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState<EmailAddress>(new EmailAddress(""));
  const [password, setPassword] = useState<Password>(new Password(""));
  const [errorMessage, setErrorMessage] = useState("");
  const [emailErrorMessages, setEmailErrorMessages] = useState<string[]>([]);
  const [passwordErrorMessages, setPasswordErrorMessages] = useState<string[]>(
    []
  );
  const [isLocked, setLock] = useScreenLock();

  const EMAIL_VALIDATION_ERROR_MESSAGES: Map<
    EmailAddressValidationError,
    string
    > = new Map([
      ["FormatError", "メールアドレスの形式が正しくありません"],
      ["LengthError", "メールアドレスは254文字以下にしてください"]
    ]);
  const PASSWORD_CHECK_ITEMS: Map<PasswordValidationError, string> = new Map([
    ["LengthError", "8文字以上24文字以下にしてください"],
    ["AlphabetLowerAndUpperError", "大文字と小文字の半角英字を含めてください"],
    ["DigitError", "半角数字を含めてください"],
    ["SymbolError", "記号を含めてください"],
  ]);

  const { surveyInfo } = (location.state as StateType) || {};
  const passwordValidationErrors = password.validate();
  const emailValidationErrors = email.validate();
  const hasErrors =
    passwordValidationErrors.length !== 0 || emailValidationErrors.length !== 0;

  const handleClickRegister = async () => {
    if (isLocked || hasErrors || surveyInfo == null) {
      // 以下のケースでは、登録処理を行わない
      // - 画面ロック中
      // - 何かしらのエラーがある
      return;
    }

    setLock(true);

    try {
      const registrationEntry = await signUp(surveyInfo, email, password);

      navigate(authenticationPath.getFullPath("Confirmation"), {
        state: {
          email: email,
          password: password,
          registrationEntryId: registrationEntry.id,
          surveyInfo: registrationEntry.survey_info,
        } as ConfirmationStateType,
      });
    } catch (e: unknown) {
      const errorMessageFromApi = getErrorMessage(e, "");
      if (errorMessageFromApi) {
        setErrorMessage(errorMessageFromApi);
      }
      const emailErrors = getEmailErrorMessages(e);
      if (emailErrors) {
        setEmailErrorMessages(emailErrors);
      }
      const passwordErrors = getPasswordErrorMessages(e);
      if (passwordErrors) {
        setPasswordErrorMessages(passwordErrors);
      }

      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    } finally {
      setLock(false);
    }
  };

  if (!surveyInfo) {
    // 遷移先がないエラーメッセージ画面を表示する。本来なら、やり直すために、Greetings画面に遷移させたいが、surveyInfoがなく、Greetings画面のURLが分からないので、遷移先をなしにする。
    return <GeneralError to="" />;
  }

  return (
    <>
      <ScrollToTop />
      <Stack spacing={12} sx={{ pt: 8, pb: 16, px: PX4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            新規アカウント登録
          </Typography>
          {/* エラーメッセージ */}
          <NonFieldError>{errorMessage}</NonFieldError>
          <Typography
            variant="subtitle2"
            textAlign="justify"
            sx={{ color: "text.secondary" }}
          >
            以下のフォームに登録確認用メールを受信できるメールアドレス、ログイン時に使用するパスワードを入力し、[登録する]ボタンを押してください。
          </Typography>
          <TextForm
            title="メールアドレス"
            placeholder="例：sleep-compass@example.com"
            value={email.value}
            onChange={(e) => {
              setEmail(new EmailAddress(e.target.value));
            }}
            validator={() =>
              emailValidationErrors.map(
                (e) => `※${EMAIL_VALIDATION_ERROR_MESSAGES.get(e)}`
              )
            }
            givenErrors={emailErrorMessages}
          />
          <TextForm
            title="新しいパスワード"
            value={password.value}
            onChange={(e) => {
              setPassword(new Password(e.target.value));
            }}
            type="password"
            helperText={
              <Stack sx={{ px: 3 }}>
                {[...PASSWORD_CHECK_ITEMS].map(([k, v]) => (
                  <PasswordCheckListItem
                    key={k}
                    text={v}
                    checked={!passwordValidationErrors.includes(k)}
                  />
                ))}
              </Stack>
            }
            givenErrors={
              passwordValidationErrors.includes("InvalidCharsError")
                ? ["※使用できない文字が含まれています"]
                : passwordErrorMessages
            }
          />
        </Stack>
        <Button
          variant="contained"
          size="large"
          sx={{ boxShadow: "none" }}
          onClick={handleClickRegister}
          disabled={hasErrors || isLocked}
        >
          登録する
        </Button>
      </Stack>
    </>
  );
}
