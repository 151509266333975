import Shadow from "@/utils/shadow";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
  Typography,
} from "@mui/material";
import { Property } from "csstype";
import { ReactNode, useEffect, useRef, useState } from "react";

/**
 * 睡眠健康測定一覧テーブルのページネーションに関するデータをまとめたクラス
 */
export class Pagination {
  readonly rowsPerPage: number;
  readonly page: number;
  readonly count: number;

  constructor(rowsPerPage: number, page: number, count: number) {
    this.rowsPerPage = rowsPerPage;
    this.page = page;
    this.count = count;
  }
}

export type ChangePageHandler = (newPage: number) => void;

export type RowsPerPageChangeHandler = (newRowsPerPage: number) => void;

interface SCTableTableProps<T> {
  headers: string[];
  list: T[];
  pagination?: Pagination;
  rowsPerPageOptions?: TablePaginationProps["rowsPerPageOptions"];
  tableRow: (item: T, index: number) => ReactNode;
  onPageChange?: ChangePageHandler;
  onRowsPerPageChange?: RowsPerPageChangeHandler;
  maxHeight?: Property.MaxHeight;
}
export function SCTable<T>({
  headers,
  list,
  pagination,
  rowsPerPageOptions,
  tableRow,
  onPageChange,
  onRowsPerPageChange,
  maxHeight,
}: SCTableTableProps<T>) {
  const tableContainerSx = maxHeight ? { maxHeight } : {};
  return (
    <Paper sx={{ boxShadow: Shadow.paper }}>
      <TableContainer sx={{ ...tableContainerSx }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((h, i) => (
                <TableCell key={i}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{list.map(tableRow)}</TableBody>
        </Table>
      </TableContainer>
      {pagination && onPageChange && onRowsPerPageChange && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={pagination.count}
          rowsPerPage={pagination.rowsPerPage}
          page={displayPage(pagination.page)}
          onPageChange={(_, newPage) => {
            onPageChange(internalPage(newPage));
          }}
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onRowsPerPageChange(parseInt(event.target.value, 10))
          }
          labelRowsPerPage="表示件数"
        />
      )}
    </Paper>
  );
}

interface SCEmptyTableProps {
  headers: string[];
  message: string;
}
export function SCEmptyTable({ headers, message }: SCEmptyTableProps) {
  // NOTE: SCTableの高さを動的に変更することになった際には HEIGHT を動的に設定する必要があります。
  const HEIGHT = 616;

  const [stackHeight, setStackHeight] = useState(0);
  const headerRow = useRef(null);

  useEffect(() => {
    if (headerRow.current != null) {
      const { clientHeight: headerHeight } = headerRow.current;
      setStackHeight(HEIGHT - headerHeight);
    }
  }, []);

  return (
    <Paper sx={{ boxShadow: Shadow.paper }}>
      <TableContainer sx={{ height: HEIGHT, maxHeight: HEIGHT }}>
        <Table stickyHeader>
          <TableHead ref={headerRow}>
            <TableRow>
              {headers.map((h, i) => (
                <TableCell key={i}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={headers.length} sx={{ border: "none", p: 0 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  alignSelf="stretch"
                  justifyContent="center"
                  spacing={2}
                  sx={{
                    color: "black.36",
                    fontSize: "20px",
                    height: stackHeight,
                    py: 0,
                    width: "100%",
                  }}
                >
                  <ErrorOutlineOutlinedIcon />
                  <Typography variant="body1">{message}</Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

/**
 * UI表示用のページ番号(1インデックス)を、内部データ用のページ番号（0インデックス）に変換する
 * @param displayPage UI表示用のページ番号
 * @returns 内部データ用のページ番号
 */
function internalPage(displayPage: number): number {
  return displayPage + 1;
}

/**
 * 内部データ用のページ番号（0インデックス）を、UI表示用のページ番号(1インデックス)に変換する
 * @param internalPage 内部データ用のページ番号
 * @returns UI表示用のページ番号
 */
function displayPage(internalPage: number): number {
  return internalPage - 1;
}
