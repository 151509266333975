import { PX4, PX6 } from "@/sleep_compass_lite/components/target/StackStyles";
import { StickyStack } from "@/sleep_compass_lite/components/target/StickyStack";
import { LiteReportHeaderProps } from "@/sleep_compass_lite/components/target/report/LiteReportMenuContext";
import { reportPath } from "@/sleep_compass_lite/domain_models/target/ReportPath";
import { SurveyInfo as LiteSurveyInfo } from "@/sleep_compass_lite/domain_models/target/SurveyInfo";
import { useLiteSurveyInfo } from "@/sleep_compass_lite/use_cases/target/interview/UseSurveyInfo";
import { useAxios } from "@/utils/axios";
import {
  Button,
  ButtonProps,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

/** 改善アクションのMean */
type Mean =
  | "ACTION_ITEM_RHYTHM" // 起床時刻をそろえる
  | "ACTION_ITEM_LONGER_SLEEP" // 睡眠時間を長くする
  | "ACTION_ITEM_NO_OVERTHINKING_IN_BED" // 寝床に入ってから考え事をしない
  | "ACTION_ITEM_DIM_LIGHTS_BEFORE_SLEEP" // 寝る前の部屋の明かりを暗めにする
  | "ACTION_ITEM_AVOID_BLUELIGHTS_BEFORE_SLEEP" // 寝る前のパソコン・スマホ利用を控える
  | "ACTION_ITEM_AVOID_ALCOHOL" // お酒を控える
  | "ACTION_ITEM_AVOID_NIGHTMEAL" // 寝る前の食事を控える
  | "ACTION_ITEM_AVOID_CAFFEINE_BEFORE_SLEEP" // カフェインを摂るタイミングに留意する
  | "ACTION_ITEM_TAKE_BATH" // 適度なタイミングで、適温の湯船につかる
  | "ACTION_ITEM_EXERCISE" // 運動をする
  | "ACTION_ITEM_AVOID_NIGHT_NAP" // 仮眠の長さ・タイミングを留意する
  | "ACTION_ITEM_NOTHING"; // 改善する物がない

/** タグに表示されるCategory */
type Category =
  | "睡眠の長さ"
  | "睡眠の質に影響する生活習慣"
  | "睡眠のリズム"
  | "none";

/**
 * Mean のカードに表示する画像の設定
 */
const CARD_IMAGES: Record<Mean, string> = {
  ACTION_ITEM_RHYTHM: "/img/image_lite_report_points_of_improvement_rhythm.png",
  ACTION_ITEM_LONGER_SLEEP:
    "/img/image_lite_report_points_of_improvement_longer_sleep.png",

  ACTION_ITEM_NO_OVERTHINKING_IN_BED:
    "/img/image_lite_report_points_of_improvement_no_overthinking_in_bed.png",
  ACTION_ITEM_DIM_LIGHTS_BEFORE_SLEEP:
    "/img/image_lite_report_points_of_improvement_dim_lights_before_sleep.png",
  ACTION_ITEM_AVOID_BLUELIGHTS_BEFORE_SLEEP:
    "/img/image_lite_report_points_of_improvement_avoid_bluelights_before_sleep.png",
  ACTION_ITEM_AVOID_ALCOHOL:
    "/img/image_lite_report_points_of_improvement_avoid_alcohol.png",
  ACTION_ITEM_AVOID_NIGHTMEAL:
    "/img/image_lite_report_points_of_improvement_avoid_nightmeal.png",
  ACTION_ITEM_AVOID_CAFFEINE_BEFORE_SLEEP:
    "/img/image_lite_report_points_of_improvement_avoid_caffeine_before_sleep.png",
  ACTION_ITEM_TAKE_BATH:
    "/img/image_lite_report_points_of_improvement_take_bath.png",
  ACTION_ITEM_EXERCISE:
    "/img/image_lite_report_points_of_improvement_exercise.png",
  ACTION_ITEM_AVOID_NIGHT_NAP:
    "/img/image_lite_report_points_of_improvement_avoid_night_nap.png",
  ACTION_ITEM_NOTHING:
    "/img/image_lite_report_points_of_improvement_nothing.png",
};

/**
 * Category のタグの色の設定
 */
const TAG_CONFIG: Record<
  Category,
  {
    color: string;
    backgroundColor: string;
  } | null
> = {
  睡眠の長さ: {
    color: "#9C27B0",
    backgroundColor: "#F3E5F5",
  },
  睡眠の質に影響する生活習慣: {
    color: "#3F51B5",
    backgroundColor: "#E8EAF6",
  },
  睡眠のリズム: {
    color: "#673AB7",
    backgroundColor: "#EDE7F6",
  },
  none: null,
};

/**
 * surveyInfoUid を使用して {@link LiteSurveyInfo サーベイ情報} を取得します。
 * @param param0 surveyInfoUid
 * @returns サーベイ情報
 */
function useSurveyInfo({
  surveyInfoUid,
}: {
  surveyInfoUid: string | undefined;
}) {
  const { getSurveyInfo: getLiteSurveyInfo } = useLiteSurveyInfo();
  const [liteSurveyInfo, setLiteSurveyInfo] = useState<LiteSurveyInfo>();
  useEffect(() => {
    (async () => {
      if (surveyInfoUid == null) return;
      const liteSurveyInfo = await getLiteSurveyInfo(surveyInfoUid);
      setLiteSurveyInfo(liteSurveyInfo);
    })();
  }, [getLiteSurveyInfo, surveyInfoUid]);
  return liteSurveyInfo;
}

/**
 * 改善アクション情報
 */
interface ImprovementInfo {
  /**
   * 改善アクションタイトル
   */
  readonly title: string;
  /**
   * 改善アクションコメント
   */
  readonly comment: string;
  /**
   * 改善アクションカテゴリ
   * {@see Category カテゴリ}
   */
  readonly category: Category;
  /**
   * 改善アクションmean
   * {@see Mean mean}
   */
  readonly mean: Mean;
}

/**
 * 改善ポイント情報
 */
interface PointsOfImprovementInfo {
  /** 改善ポイントコメント */
  readonly comment: string;
  /**
   * 改善アクション情報のリスト
   * {@see ImprovementInfo 改善アクション情報}
   */
  readonly improvements: ImprovementInfo[];
}

/**
 * surveyInfoUid を使用して {@link PointsOfImprovementInfo 改善ポイント情報} を取得します。
 * @param param0 surveyInfoUid
 * @returns 改善ポイント情報
 */
function usePointsOfImprovementInfo({
  surveyInfoUid,
}: {
  surveyInfoUid: string | undefined;
}) {
  const axios = useAxios();
  const [pointsOfImprovementInfo, setPointsOfImprovementInfo] =
    useState<PointsOfImprovementInfo>();
  useEffect(() => {
    (async () => {
      if (surveyInfoUid == null) return;
      const response: AxiosResponse<PointsOfImprovementInfo> = await axios.get(
        `/api/lite/report/${surveyInfoUid}/points_of_improvement/`
      );
      setPointsOfImprovementInfo(response.data);
    })();
  }, [axios, surveyInfoUid]);
  return pointsOfImprovementInfo;
}

/**
 * 改善ポイント画面を表示します。
 *
 * @returns 改善ポイント画面
 */
export function PointsOfImprovement({
  setLiteReportHeaderContext,
}: LiteReportHeaderProps) {
  const { surveyInfoUid } = useParams();
  const liteSurveyInfo = useSurveyInfo({ surveyInfoUid });
  const pointsOfImprovementInfo = usePointsOfImprovementInfo({ surveyInfoUid });
  const navigate = useNavigate();

  const [isNextButtonFloating, setIsNextButtonFloating] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    // メニューバーの制御
    setLiteReportHeaderContext({
      title: "改善ポイント",
      onClickBack() {
        if (surveyInfoUid == null) return;
        navigate(
          reportPath.getFullPath("DaytimeCondition", {
            ":surveyInfoUid": surveyInfoUid,
          })
        );
      },
    });
  }, [navigate, setLiteReportHeaderContext, surveyInfoUid]);

  const nextButtonSX: ButtonProps["sx"] = { my: 6 };
  if (isNextButtonFloating === false) {
    // ボタンの影は、フローティングしていないときは非表示(フローティングしているときは表示)
    nextButtonSX["boxShadow"] = "none";
  }

  // サーベイ情報または、改善ポイント情報が取得されていない場合、空白を表示
  if (
    surveyInfoUid == null ||
    liteSurveyInfo == null ||
    pointsOfImprovementInfo == null
  ) {
    return <></>;
  }
  return (
    <Stack sx={{ backgroundColor: "background.default", pb: 14 }}>
      <Stack
        spacing={10}
        sx={{ px: PX6, pt: 6, pb: 10, backgroundColor: "white" }}
      >
        <img
          src="/img/image_report_stepper_04.svg"
          alt="Points of improvement step"
          loading="lazy"
          style={{ width: "100%", height: "auto" }}
        />
        <Stack sx={{ gap: "24px" }}>
          <Typography variant="h5" sx={{ whiteSpace: "pre-wrap" }}>
            <span style={{ color: "#0056A0" }}>
              {`${liteSurveyInfo.target.userName.fullName()}`}
            </span>
            さんの
            <br></br>
            睡眠改善アクション
          </Typography>
          <Typography variant="body2">
            {pointsOfImprovementInfo.comment}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ backgroundColor: "#F6F5F4" }}>
        <Stack sx={{ px: PX4, gap: 6, pt: 10, pb: 6 }}>
          {pointsOfImprovementInfo.improvements.map((d, i) => (
            <Card key={i} sx={{ boxShadow: "0px 0px 0px 1px #E0E0E0" }}>
              <img
                src={CARD_IMAGES[d.mean]}
                alt={`${d.title}`}
                width="100%"
                height="auto"
              />
              <CardContent sx={{ p: "16px" }}>
                <Typography variant="h6" sx={{ fontSize: "20px" }}>
                  {d.title}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{
                    fontSize: "16px",
                    whiteSpace: "pre-line",
                    marginBottom: "16px",
                    textAlign: "justify",
                  }}
                >
                  {d.comment}
                </Typography>
                {TAG_CONFIG[d.category] != null && (
                  <Chip
                    label={`${d.category}`}
                    sx={{
                      color: TAG_CONFIG[d.category]?.color,
                      backgroundColor: TAG_CONFIG[d.category]?.backgroundColor,
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "18px",
                    }}
                  />
                )}
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Stack>
      <StickyStack
        sx={{
          px: PX4,
          background:
            "linear-gradient(180deg, rgba(246, 245, 244, 0.00) 0%, #F6F5F4 100%);",
        }}
        onChangeFloatingState={(isFloating) =>
          setIsNextButtonFloating(isFloating)
        }
      >
        <Button
          variant="contained"
          sx={nextButtonSX}
          component={Link}
          to={reportPath.getFullPath(
            liteSurveyInfo.status === "ReportSent" // statusが「レポート送付済」の場合、意識調査へ遷移
              ? "AttitudeEnquete"
              : "ReportEnd",
            {
              ":surveyInfoUid": surveyInfoUid,
            }
          )}
        >
          次へ
        </Button>
      </StickyStack>
    </Stack>
  );
}
