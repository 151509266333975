import { ArrowBack } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Typography,
} from "@mui/material";

type InterviewAppBarProps = {
  height: number;
  title: string;
  onClickBack?: () => void;
};
export function InterviewAppBar({
  height,
  title,
  onClickBack,
}: InterviewAppBarProps) {
  return (
    <>
      <AppBar sx={{ backgroundColor: "common.white" }} elevation={0}>
        <Toolbar>
          {onClickBack && (
            <Box position="absolute">
              <Button
                size="small"
                startIcon={<ArrowBack sx={{ width: 16, height: 16 }} />}
                onClick={onClickBack}
              >
                戻る
              </Button>
            </Box>
          )}
          <Typography variant="body1" align="center" width="100%">
            {title}
          </Typography>
        </Toolbar>
        <Divider />
      </AppBar>
      {/* Note: BoxはAppBarの下にコンポーネントが入り込むのを防止するためのスペーサー。AppBarと同じ高さにする。 */}
      <Box sx={{ height: height }} />
    </>
  );
}
