import { Box, Button, Dialog, Stack, Typography } from "@mui/material";

/**
 * {@link BeforeCheckReportDialog} のプロパティ
 */
export interface BeforeCheckReportDialogProps {
  /** ダイアログを開く */
  open: boolean;
  /** ダイアログを閉じる */
  close: () => void;
}
/**
 * レポートを確認する前にダイアログ
 * @param param0 {@link BeforeCheckReportDialogProps}
 * @returns レポートを確認する前にダイアログ
 */
export function BeforeCheckReportDialog({
  open,
  close,
}: BeforeCheckReportDialogProps) {
  return (
    <Dialog open={open} sx={{ borderRadius: "16px" }}>
      <Stack>
        <Stack
          direction="row"
          sx={{ alignItems: "center", backgroundColor: "#edf2f8", px: "16px" }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              size: "16px",
              color: "primary.dark",
              py: "35px",
            }}
          >
            レポートを確認する前に
          </Typography>
          <img
            src="/img/image_report_summary_dialog_hero.png"
            loading="lazy"
            alt="hero"
            width="112px"
            height="99px"
            style={{ marginLeft: "auto" }}
          />
        </Stack>

        <Stack sx={{ p: "16px" }}>
          <Typography variant="body2" textAlign="justify">
            本レポートで提供される情報や結果は、病気の診断や治療を意図するものではありません。
            <br />
            診断ガイドラインに基づく問診回答により、睡眠時無呼吸症候群・不眠症の疾患リスクを表示する場合がありますが、正しく問診回答が行われなかった場合、正しい結果が出ない場合があります。
            <br />
            結果に関わらず、気になることがある場合には、かかりつけ医・睡眠の専門医に相談することをお勧めします。
          </Typography>
        </Stack>

        <Box textAlign="center" sx={{ m: "16px" }}>
          <Button
            variant="outlined"
            sx={{ width: "107px", borderRadius: "18px", height: "32px" }}
            onClick={() => close()}
          >
            閉じる
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
}
