import {
  DailyInterviewInfo as DailyInterviewInfoObject,
  DailyInterviewItem as DailyInterviewItemObject,
} from "@/sleep_compass_lite/data_transfer_objects/target/DailyInterviewInfo";

/**
 * 毎日問診の日毎の情報をまとめたドメインモデル
 */
export class DailyInterviewItem {
  constructor(
    /** 毎日問診のID。実態はLiteDailyInterviewAnnounce.id */
    readonly id: number,
    /** 毎日問診日 */
    readonly date: Date | null,
    /** 毎日問診日の毎日問診を完了したか否かを示す真偽値 */
    readonly isCompleted: boolean
  ) {}

  /**
   * DailyInterviewItemオブジェクトからDailyInterviewItemモデルを生成する
   * @param obj {@link DailyInterviewInfoObject}
   * @returns DailyInterviewItemモデルのインスタンス
   */
  static create(obj: DailyInterviewItemObject): DailyInterviewItem {
    return new DailyInterviewItem(
      obj.id,
      obj.date != null ? new Date(obj.date) : null,
      obj.is_completed
    );
  }
}

/**
 * 毎日問診情報ドメインモデル
 */
export class DailyInterviewInfo {
  constructor(
    /** 毎日問診の日毎の情報。API仕様により、この配列は、毎日問診メールが送信済みの毎日問診しか含まない */
    readonly dailyInterviews: DailyInterviewItem[],
    /** 現在回答可能な毎日問診のID */
    private readonly currentDailyInterviewItem: number | null
  ) {}

  /** 現在回答可能な毎日問診 */
  get currentDailyInterview(): DailyInterviewItem | null {
    if (this.currentDailyInterviewItem == null) {
      return null;
    }

    return (
      this.dailyInterviews.find(
        (i) => i.id === this.currentDailyInterviewItem
      ) ?? null
    );
  }

  /** 毎日問診を完了した回数。同じ問診を複数回完了した場合は「1」回と数える */
  get completedCount(): number {
    return this.dailyInterviews.reduce(
      (prev, current) => (current.isCompleted ? prev + 1 : prev),
      0
    );
  }

  /**
   * DailyInterviewInfoオブジェクトからDailyInterviewInfoモデルを生成する
   * @param obj {@link DailyInterviewInfoObject}
   * @returns DailyInterviewInfoモデルのインスタンス
   */
  static create(obj: DailyInterviewInfoObject): DailyInterviewInfo {
    return new DailyInterviewInfo(
      obj.daily_interviews.map((interview) =>
        DailyInterviewItem.create(interview)
      ),
      obj.current_daily_interview_item
    );
  }
}
