import CheckBoxCheckedFilled from "@/components/common/CheckBoxCheckedFilled";
import CheckBoxUncheckedFilled from "@/components/common/CheckBoxUncheckedFilled";
import RadioButtonCheckedFilled from "@/components/common/RadioButtonCheckedFilled";
import RadioButtonUncheckedFilled from "@/components/common/RadioButtonUncheckedFilled";
import {
  CHOICE_TYPE_LABEL,
  CHOICE_TYPE_LABEL_WITH_FREE_TEXT,
  SELECTION_TYPE_1_N,
  SELECTION_TYPE_N_N,
} from "@/components/examinee/interview/Constants";
import {
  Answer,
  Choice,
  InterviewEntry,
  Question,
  QuestionAnswer,
  Screen,
} from "@/components/examinee/interview/Types";
import { PX6 } from "@/sleep_compass_lite/components/target/StackStyles";
import {
  useEnquetes,
  useScreens,
} from "@/sleep_compass_lite/components/target/enquete/hooks";
import { LiteReportHeaderProps } from "@/sleep_compass_lite/components/target/report/LiteReportMenuContext";
import { reportPath } from "@/sleep_compass_lite/domain_models/target/ReportPath";
import { useLiteSurveyInfo as useSurveyInfo } from "@/sleep_compass_lite/use_cases/target/interview/UseSurveyInfo";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

/**
 * id で検索を行う関数です。
 * ```
 * const a = array.find(byId(1));
 * ```
 * のように使用する想定です。
 *
 * @param id
 * @returns 検索する関数
 */
const byId =
  <X, T extends { id: X }>(id: X) =>
  (x: T) =>
    x.id === id;

/**
 * id と question_code で検索を行うための関数です。
 * ```
 * const a = array.find(byIdAndQuestionCode(b));
 * ```
 * のように使用する想定です。
 *
 * @param a id と question_code を持ったオブジェクト
 * @returns 検索する関数
 */
const byIdAndQuestionCode =
  <T extends { id: number; question_code: string }, A extends T, B extends T>(
    a: A
  ) =>
  (b: B) =>
    a.id === b.id && a.question_code === b.question_code;

/**
 * 意識調査用のカスタムフックです。
 *
 * @returns 意識調査用の関数が含まれたオブジェクト
 */
function useAttitudeEnquete() {
  const [interviewEntry, setInterviewEntry] = useState<InterviewEntry>();
  const [screenInfo, setScreenInfo] = useState<Screen>();
  const [questionAnswerInfos, setQuestionAnswerInfos] = useState<
    QuestionAnswerInfo[]
  >([]);
  const screens = useScreens();

  /**
   * {@link InterviewEntry} から {@link Screen} を取得します。
   */
  const setUpScreen = useCallback(
    async ({
      interviewEntry,
      finish,
    }: {
      interviewEntry: InterviewEntry;
      finish(): void;
    }) => {
      setInterviewEntry(interviewEntry);

      // screen path がnull の場合、次画面がないとして、終了処理を呼び出し
      if (interviewEntry.screen.path == null) {
        return finish();
      }
      // screen path が存在する場合、screen を取得
      const screen = await screens.get(interviewEntry.screen.path);
      setScreenInfo(screen);
    },
    [screens]
  );
  /**
   * {@link QuestionAnswerInfo} に対して、入力チェックを行い、エラーが発生しているところまでウィンドウをスクロールします。
   */
  const isValidQuestionInfos = useCallback(() => {
    questionAnswerInfos.forEach((q) => q.validate?.());
    const validates = questionAnswerInfos.filter(
      (qa) => qa.errorRect?.top != null
    );
    const isValid = validates.length === 0;
    if (!isValid) {
      const min =
        Math.min(...validates.map((r) => r!.errorRect!.top)) +
        window.scrollY -
        80;
      window.scrollTo({ top: min, behavior: "smooth" });
    }
    setQuestionAnswerInfos([...questionAnswerInfos]);
    return isValid;
  }, [questionAnswerInfos]);

  /**
   * {@link EnqueteForm} が変更された際に実行するハンドラ
   * questionAnswerInfos を更新します。
   */
  const handleOnChangeEnqueteForm = useCallback(
    (questionAnswerInfo: QuestionAnswerInfo) => {
      setQuestionAnswerInfos((questionAnswerInfos) => {
        const index = questionAnswerInfos.findIndex(
          byIdAndQuestionCode(questionAnswerInfo)
        );
        const newInfo =
          index < 0
            ? [...questionAnswerInfos, questionAnswerInfo]
            : questionAnswerInfos.map((q, i) =>
                i === index ? questionAnswerInfo : q
              );
        return newInfo;
      });
    },
    [setQuestionAnswerInfos]
  );
  /**
   * questionAnswerInfos から引数で渡した {@link Question} を検索します。
   */
  const findQuestionAnswerInfo = useCallback(
    (question: Question) => {
      const questionAnswerInfo = questionAnswerInfos.find(
        byIdAndQuestionCode(question)
      );
      return questionAnswerInfo;
    },
    [questionAnswerInfos]
  );

  return {
    interviewEntry,
    setInterviewEntry,
    screenInfo,
    setScreenInfo,
    questionAnswerInfos,
    setQuestionAnswerInfos,
    setUpScreen,
    isValidQuestionInfos,
    handleOnChangeEnqueteForm,
    findQuestionAnswerInfo,
  };
}

/**
 * 入力チェックを行うinterfaceです。
 * エラーが発生した場合、フォーカスを行う {@link DOMRect} を返却します。
 */
interface Validator {
  /**
   * 値の検証を行います。
   * エラーがある場合、{@link Validator#errorRect} に DOMRect を設定してください。
   */
  validate?(): void;
  /**
   * エラーがあった際に設定されるプロパティ
   * {@link window.scrollTo} で移動させたい箇所の座標を指定します。
   * @see {@link DOMRect}
   */
  errorRect?: DOMRect | null;
}

/**
 * 画面で保持するために {@link Answer} を拡張した情報
 */
interface AnswerInfo extends Answer, Validator {}

/**
 * 画面で保持するために {@link QuestionAnswer} を拡張した情報
 */
interface QuestionAnswerInfo extends QuestionAnswer, Validator {
  /**
   * {@link AnswerInfo} を使用するために {@link QuestionAnswer#answers} を overrideしています。
   */
  answers: AnswerInfo[];
  /**
   * 設問部分に表示させるエラーメッセージ。
   */
  errorMessage: string | null;
}

/**
 * answer を１つ以上選択しているかチェックします。
 * このメソッドは引数のinfoを書き換えます。
 * @param ans {@link QuestionAnswerInfo}
 * @param getErrorRect 対象の{@link DOMRect}
 * @returns 選択済みの場合 true
 */
function isAnySelect(
  info: QuestionAnswerInfo,
  getErrorRect: () => DOMRect | undefined
) {
  info.errorMessage = "";
  info.errorRect = null;
  if (info.answers.length === 0) {
    info.errorMessage = "※選択してください";
    info.errorRect = getErrorRect();
    return false;
  }
  return true;
}

/**
 * 複数の選択肢から１つを選ぶ意識調査コンポーネントのプロパティ
 */
interface SelectOneFromSomethingProps {
  /**
   * 選択肢
   * @see Choice
   */
  choices: Choice[];
  /**
   * 選択値 (未選択の場合、undefined)
   * @see AnswerInfo
   */
  value: AnswerInfo | undefined;
  /**
   * 選択した場合に呼び出されるハンドラ
   *
   * @param value {@link AnswerInfo}
   * @returns {void}
   */
  onChange(value: AnswerInfo): void;
}
/**
 * {@link Select} から１つだけ選択するコンポーネント
 *
 * @param0 {@link SelectOneFromSomethingProps}
 */
function SelectOneFromDropdown({
  choices,
  value,
  onChange,
}: SelectOneFromSomethingProps) {
  const handleOnChange = useCallback(
    (event: SelectChangeEvent) => {
      // choices から同一IDの物を探し出す。
      // choices からSelectを生成しているため、Non-Null Assertion Operatorを使用しています。
      const choice = choices.find(byId(parseInt(event.target.value)))!;
      onChange({
        ...choice,
        answer: choice.choice_label,
      });
    },
    [choices, onChange]
  );
  return (
    <FormControl fullWidth>
      <InputLabel>選択してください</InputLabel>
      <Select
        value={String(value?.id ?? "")}
        label="選択してください"
        sx={{ backgroundColor: "#FFFFFF" }}
        onChange={handleOnChange}
      >
        {choices.map((c, i) => (
          <MenuItem key={i} value={c.id} sx={{ whiteSpace: "pre-wrap" }}>
            {c.choice_label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

/**
 * {@link Radio} から選択するコンポーネント
 *
 * @param0 {@link SelectOneFromSomethingProps}
 */
function SelectOneFromRadio({
  choices,
  value,
  onChange,
}: SelectOneFromSomethingProps) {
  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    // choices から同一IDの物を探し出す。
    // choices からSelectを生成しているため、Non-Null Assertion Operatorを使用しています。
    const choice = choices.find(byId(parseInt(event.target.value)))!;
    onChange({
      ...choice,
      answer: choice.choice_label,
    });
  }
  return (
    <RadioGroup value={value?.id ?? ""} onChange={handleOnChange}>
      {choices.map((c, i) => (
        <FormControlLabel
          key={i}
          value={c.id}
          control={
            <Radio
              icon={<RadioButtonUncheckedFilled />}
              checkedIcon={<RadioButtonCheckedFilled />}
            />
          }
          label={<Typography variant="body2">{c.choice_label}</Typography>}
        />
      ))}
    </RadioGroup>
  );
}
/**
 * 複数選択コンポーネント用のプロパティ
 */
interface SelectManyChoiceProps {
  /**
   * 選択した {@link AnswerInfo}
   */
  value: AnswerInfo | undefined;
  /**
   * 変更があった場合に呼び出されるハンドラ
   *
   * @param append 選択値に追加する対象かどうか(trueの場合追加)
   * @param answer 対象の {@link AnswerInfo}
   * @returns {Void}
   */
  onChange(append: boolean, answer: AnswerInfo): void;
  /**
   * 対象の {@link Choice}
   */
  choice: Choice;
}
/**
 * {@link Checkbox} と {@link TextField} を選択するためのコンポーネントです。
 * Checkbox が選択されると TextField が活性化します。
 * TextFieldが活性状態の場合、以下の入力チェックが入ります。
 * * 必須チェック
 * * 文字数チェック(500文字以下)
 *
 * TextFieldに入力された文字は `${choice.choice_label}: [${text}]` の形式で、onChange を経由して呼び出し元に返却されます。
 *
 * @param0 {@link SelectManyChoiceProps}
 */
function SelectManyChoiceLabelWithFreeText({
  value,
  onChange,
  choice,
}: SelectManyChoiceProps) {
  const ref = useRef<HTMLInputElement | null>(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [checked, setChecked] = useState(value != null);
  const [text, setText] = useState(
    value?.answer.replace(/^.*?\[(.*)\]$/, "$1") ?? ""
  );
  /**
   * {@link TextField} の入力値をサーバー送信用に `${choice.choice_label}: [${text}]` の形式にフォーマットします。
   */
  const formatText = useCallback(
    (text: string) => `${choice.choice_label}: [${text}]`,
    [choice.choice_label]
  );
  /**
   * 入力チェックを行います。
   *
   * @params0 checked チェックボックス
   * @params1 text テキストフィールドの入力値
   * @return エラーメッセージ
   */
  const verify = useCallback((checked: boolean, text: string) => {
    // チェックボックスがOFFの場合、入力チェックを行わない
    if (!checked) {
      return "";
    }
    if (text.length === 0) {
      return `入力してください。`;
    }
    if (text.length > 500) {
      return `500文字以内で入力してください。`;
    }
    return "";
  }, []);
  /**
   * チェックボックス onChecked とテキストフィールドの onChange 共通のハンドラ
   * @params0 checked チェックボックスのON/OFF(ONの時true)
   * @params1 テキストフィールドの入力値
   */
  const handleEvent = useCallback(
    (checked: boolean, text: string) => {
      onChange(checked, {
        ...choice,
        answer: formatText(text),
        errorRect: null,
        validate() {
          this.errorRect = null;
          const errorMessage = verify(checked, text);
          setErrorMessage(errorMessage);
          if (errorMessage.length > 0) {
            this.errorRect = ref.current?.getBoundingClientRect();
          }
        },
      });
      setChecked(checked);
      setText(text);
    },
    [choice, formatText, onChange, verify]
  );
  /**
   * チェックボックスのonCheckedイベントハンドラ
   * @params0 {@link React.ChangeEvent<HTMLInputElement> onClickedのイベント}
   */
  const handleOnChecked = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleEvent(e.target.checked, text);
    },
    [handleEvent, text]
  );
  /**
   * テキストフィールドのonChangeイベントハンドラ
   * @params0 {@link React.ChangeEvent<HTMLInputElement> onClickedのイベント}
   */
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleEvent(checked, e.target.value);
    },
    [handleEvent, checked]
  );
  return (
    <>
      <FormControlLabel
        inputRef={ref}
        control={
          <Checkbox
            checked={checked}
            onChange={handleOnChecked}
            icon={<CheckBoxUncheckedFilled />}
            checkedIcon={<CheckBoxCheckedFilled />}
          />
        }
        label={<Typography variant="body2">{choice.choice_label}</Typography>}
      />
      <TextField
        sx={{
          color: `${checked ? "text.primary" : "text.disabled"}`,
          backgroundColor: "#FFFFFF",
        }}
        error={errorMessage.length > 0}
        helperText={errorMessage}
        value={text}
        placeholder="詳細をご記入ください。"
        disabled={!checked}
        onChange={handleOnChange}
      />
    </>
  );
}
/**
 * {@link Checkbox} から複数選択を行うためのコンポーネントです。
 * @params0 {@link SelectManyChoiceProps}
 */
function SelectManyChoiceLabel({
  value,
  onChange,
  choice,
}: SelectManyChoiceProps) {
  const handleChecked = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      onChange(checked, {
        ...choice,
        answer: e.target.value,
      });
    },
    [choice, onChange]
  );
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<CheckBoxUncheckedFilled />}
          checkedIcon={<CheckBoxCheckedFilled />}
          onChange={handleChecked}
          checked={value != null}
          value={choice.choice_label}
        />
      }
      label={<Typography variant="body2">{choice.choice_label}</Typography>}
    />
  );
}

/**
 * 複数選択用のコンポーネントで、選択対象のコンポーネントの１つを返却します。
 * このコンポーネントは {@link Choice#choice_type} によって、返却するコンポーネントを切り替えます。
 *
 * @params0 {@link SelectManyChoiceProps}
 * @throws Error 対象外の {@link Choice#choice_type} が指定された場合
 */
function SelectManyChoice(props: SelectManyChoiceProps) {
  switch (props.choice.choice_type) {
    case CHOICE_TYPE_LABEL:
      return <SelectManyChoiceLabel {...props} />;
    case CHOICE_TYPE_LABEL_WITH_FREE_TEXT:
      return <SelectManyChoiceLabelWithFreeText {...props} />;
    default:
      throw new Error(`${props.choice.choice_type} is not implemented`);
  }
}
/**
 * {@link SelectAny} のプロパティ
 */
interface SelectAnyProps {
  /**
   * 表示対象の {@link Question}
   */
  question: Question;
  /**
   * 画面の状態を保持するための {@link QuestionAnswerInfo}
   */
  value: QuestionAnswerInfo | undefined;
  /**
   * 内部のコンポーネントで、選択された場合に呼び出されるハンドラ
   *
   * @param value {@link QuestionForm}
   * @returns void
   */
  onChange(value: QuestionAnswerInfo): void;
  /**
   * エラーが発生したコンポーネントの位置を取得します。
   */
  getErrorRect(): DOMRect | undefined;
}
/**
 * 単一選択用のコンポーネント
 *
 * @params0 {@link EnqueteProps}
 */
function SelectOneFromMany({
  question,
  onChange,
  getErrorRect,
  value,
}: SelectAnyProps) {
  const handleOnChange = useCallback(
    (answer: AnswerInfo | null) => {
      onChange({
        ...question,
        answers: answer == null ? [] : [answer],
        errorMessage: "",
        errorRect: null,
        validate() {
          isAnySelect(this, getErrorRect);
        },
      });
    },
    [question, onChange, getErrorRect]
  );

  useEffect(() => {
    handleOnChange(null);
  }, [handleOnChange]);

  // 6個以上の選択肢の場合、ドロップダウンから選択
  if (question.choices.length > 5) {
    return (
      <SelectOneFromDropdown
        value={value?.answers[0]}
        choices={question.choices}
        onChange={handleOnChange}
      />
    );
  } else {
    return (
      <SelectOneFromRadio
        value={value?.answers[0]}
        choices={question.choices}
        onChange={handleOnChange}
      />
    );
  }
}
/**
 * 複数選択用のコンポーネント
 *
 * @params0 {@link EnqueteFormProps}
 */
function SelectManyFromMany({
  question,
  onChange,
  getErrorRect,
  value,
}: SelectAnyProps) {
  const handleChange = useCallback(
    (append: boolean, answer: AnswerInfo) => {
      if (value == null) return;
      const newAnswer = value.answers.filter((a) => a.id !== answer.id);
      if (append) {
        newAnswer.push(answer);
      }
      onChange({
        ...value,
        answers: [...newAnswer],
      });
    },
    [onChange, value]
  );
  useEffect(() => {
    onChange({
      ...question,
      answers: [],
      errorMessage: "",
      errorRect: null,
      validate() {
        if (!isAnySelect(this, getErrorRect)) {
          return;
        }
        // 配下のコンポーネントのvalidateメソッドを実行
        this.answers.forEach((a) => a.validate?.());
        // 配下のコンポーネントでエラーが発生しているか確認
        const ans = this.answers.filter((a) => a.errorRect?.top != null);
        if (ans.length > 0) {
          // 存在していた場合、一番上に位置している(topが最小)のerrorRectを自分のerrorRectに設定
          return (this.errorRect = ans
            .map((a) => a.errorRect)
            .reduce((p, c) => (p!.top < c!.top ? p : c)));
        }
      },
    });
  }, [question, onChange, getErrorRect]);
  if (value == null) {
    return <></>;
  }
  return (
    <FormGroup sx={{ gap: 1 }}>
      {question.choices.map((c, i) => (
        <SelectManyChoice
          key={i}
          choice={c}
          value={value.answers.find(byId(c.id))}
          onChange={handleChange}
        />
      ))}
    </FormGroup>
  );
}
/**
 * {@link Question#selection_type} によってコンポーネントを切り替えるコンポーネントです。
 *
 * @param props {@link SelectAnyProps}
 * @returns SelectAny コンポーネント
 */
function SelectAny(props: SelectAnyProps) {
  switch (props.question.selection_type) {
    case SELECTION_TYPE_1_N:
      return <SelectOneFromMany {...props} />;
    case SELECTION_TYPE_N_N:
      return <SelectManyFromMany {...props} />;
    default:
      throw new Error(`${props.question.selection_type} is not implemented`);
  }
}
/**
 * 意識調査用のプロパティ
 */
interface EnqueteFormProps {
  /**
   * 質問番号
   */
  no: number;
  /**
   * 表示対象の {@link Question}
   */
  question: Question;
  /**
   * 画面の状態を保持するための {@link QuestionAnswerInfo}
   */
  value: QuestionAnswerInfo | undefined;
  /**
   * 内部のコンポーネントで、選択された場合に呼び出されるハンドラ
   *
   * @param value {@link QuestionForm}
   * @returns void
   */
  onChange(value: QuestionAnswerInfo): void;
}
/**
 * 意識調査コンポーネント
 *
 * @params0 {@link EnqueteFormProps}
 * @throws Error 対象外の selection_type が指定された場合
 */
function EnqueteForm({ no, ...props }: EnqueteFormProps) {
  const ref = useRef<HTMLSpanElement>(null);
  const getErrorRect = useCallback(
    () => ref.current?.getBoundingClientRect(),
    []
  );
  return (
    <Stack sx={{ py: 10, gap: 4 }}>
      <Typography
        ref={ref}
        sx={{
          color: props.value?.errorRect != null ? "error.main" : "primary.main",
          fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
          fontFamily: "Helvetica Neue",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "100%",
          letterSpacing: "0.5px",
        }}
      >
        Q{no}
      </Typography>
      <Stack sx={{ gap: 1 }}>
        <Typography variant="subtitle2" sx={{ whiteSpace: "pre-wrap" }}>
          {props.question.question_text}
        </Typography>
        {props.question.question_detail != null && (
          <Typography variant="caption" sx={{ whiteSpace: "pre-wrap" }}>
            {props.question.question_detail}
          </Typography>
        )}
        {(props.value?.errorMessage?.length ?? 0) !== 0 && (
          <FormHelperText
            sx={{
              color: "error.main",
              textAlign: "justify",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: "Noto Sans JP",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "20px",
              letterSpacing: "0.4px",
            }}
          >
            {props.value?.errorMessage}
          </FormHelperText>
        )}
      </Stack>
      <SelectAny {...props} getErrorRect={getErrorRect} />
    </Stack>
  );
}

/**
 * 意識調査を表示します。
 * @returns 意識調査コンポーネント
 */
export function AttitudeEnquete({
  setLiteReportHeaderContext,
}: LiteReportHeaderProps) {
  const { surveyInfoUid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // メニューバーの制御
    setLiteReportHeaderContext({
      title: "意識調査",
      onClickBack() {
        if (surveyInfoUid == null) return;
        navigate(
          reportPath.getFullPath("PointsOfImprovement", {
            ":surveyInfoUid": surveyInfoUid,
          })
        );
      },
    });
  }, [navigate, setLiteReportHeaderContext, surveyInfoUid]);

  const {
    setUpScreen: getSetUpScreen,
    interviewEntry,
    screenInfo,
    questionAnswerInfos,
    isValidQuestionInfos,
    handleOnChangeEnqueteForm,
    findQuestionAnswerInfo,
  } = useAttitudeEnquete();

  const setUpScreen = useCallback(
    (interviewEntry: InterviewEntry) =>
      getSetUpScreen({
        interviewEntry,
        finish() {
          if (surveyInfoUid == null) return;
          return navigate(
            reportPath.getFullPath("ReportEnd", {
              ":surveyInfoUid": surveyInfoUid,
            })
          );
        },
      }),
    [getSetUpScreen, navigate, surveyInfoUid]
  );

  const surveys = useSurveyInfo();
  const enquetes = useEnquetes();

  useEffect(() => {
    (async () => {
      if (surveyInfoUid == null) return;
      const info = await surveys.getSurveyInfo(surveyInfoUid);
      if (info.status === "AttitudeEnqueteCompleted") {
        return navigate(
          reportPath.getFullPath("ReportEnd", {
            ":surveyInfoUid": surveyInfoUid,
          })
        );
      }
    })();
  }, [surveys, surveyInfoUid, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [screenInfo]);

  const handleNext = useCallback(async () => {
    if (surveyInfoUid == null || interviewEntry == null || screenInfo == null) {
      return;
    }

    if (isValidQuestionInfos()) {
      const nextInterviewEntry = await enquetes.post(surveyInfoUid, {
        id: interviewEntry.id,
        screen: {
          id: screenInfo.id,
          question_group: {
            id: screenInfo.question_group.id,
            questions: questionAnswerInfos,
          },
        },
      });
      setUpScreen(nextInterviewEntry);
    }
  }, [
    enquetes,
    interviewEntry,
    questionAnswerInfos,
    screenInfo,
    surveyInfoUid,
    setUpScreen,
    isValidQuestionInfos,
  ]);

  useEffect(() => {
    (async () => {
      if (surveyInfoUid == null) return;
      const info = await enquetes.get(surveyInfoUid);
      setUpScreen(info);
    })();
  }, [enquetes, surveyInfoUid, setUpScreen]);

  if (screenInfo == null) {
    return <></>;
  }
  return (
    <>
      <Stack sx={{ px: PX6, py: 10, gap: 6 }}>
        <Typography variant="h5">
          {screenInfo.question_group.category}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "justify" }}>
          最後に、今回の調査を経て、睡眠に対する意識がどのように変わったか/変わらなかったかを教えてください。
        </Typography>
      </Stack>

      <Stack sx={{ backgroundColor: "#F6F5F4", px: PX6, pb: 20 }}>
        {screenInfo.question_group.questions.map((q, i) => (
          <React.Fragment key={i}>
            {i !== 0 && <Divider />}
            <EnqueteForm
              key={i}
              no={i + 1}
              question={q}
              value={findQuestionAnswerInfo(q)}
              onChange={handleOnChangeEnqueteForm}
            />
          </React.Fragment>
        ))}
        <Button
          variant="contained"
          sx={{ boxShadow: "none" }}
          onClick={handleNext}
        >
          次へ
        </Button>
      </Stack>
    </>
  );
}
