import { PX6 } from "@/sleep_compass_lite/components/target/StackStyles";
import { Box, Stack, Typography } from "@mui/material";

/**
 * 期限切れ画面のプロパティ
 */
export interface ExpiredProps {
  /**
   * 期限切れ画面のタイトル
   */
  title: string;
  /**
   * 期限切れ画面のメッセージ
   */
  message: string;
}

/**
 * 事前問診・毎日問診で使用される期限切れ画面のコンポーネント
 * @param props {@link ExpiredProps 期限切れ画面のプロパティ}
 * @returns 期限切れ画面コンポーネント
 */
export default function Expired({ title, message }: ExpiredProps) {
  return (
    <Stack spacing={6} sx={{ px: PX6, pt: 8, pb: 16 }} alignItems="center">
      <Box sx={{ px: "33.5px" }}>
        <img
          src="/img/image_lite_interview_expired.png"
          alt="light daily interview "
          loading="lazy"
          style={{ width: "100%", height: "auto", maxWidth: "390px" }}
        />
      </Box>
      <Stack sx={{ gap: 4 }} alignItems="center">
        <Typography
          variant="h6"
          sx={{
            color: "text.primary",
            fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "text.primary",
            textAlign: "center",
            fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
            whiteSpace: "pre-wrap",
          }}
        >
          {message}
        </Typography>
      </Stack>
    </Stack>
  );
}
