import { LITE_TARGET_PATH } from "@/sleep_compass_lite/constants";
import { Path } from "@/utils/path";

/** レポート系ページの一覧 */
export type ReportPages =
  | "ReportTop"
  | "SleepCondition"
  | "DaytimeCondition"
  | "PointsOfImprovement"
  | "AttitudeEnquete"
  | "ReportEnd";

/** レポート系ページの親ディレクトリ */
export const REPORT_PAGES_PARENT = "/report";

/**
 * レポート系ページのパスクラスのインスタンス
 * 各ページのパスの宣言やパスの取得で、このインスタンスを使用
 * パス変更時のメンテナンス性とコーディング効率(補完やtypo防止)の向上のために作成
 */
export const reportPath = new Path<ReportPages>(
  `${LITE_TARGET_PATH}${REPORT_PAGES_PARENT}`,
  {
    ReportTop: "/top/:surveyInfoUid",
    SleepCondition: "/sleep_condition/:surveyInfoUid",
    DaytimeCondition: "/daytime_condition/:surveyInfoUid",
    PointsOfImprovement: "/points_of_improvement/:surveyInfoUid",
    AttitudeEnquete: "/enquete/:surveyInfoUid",
    ReportEnd: "/end/:surveyInfoUid",
  }
);

/**
 * URLパラメータのkey
 */
export const PARAM_KEY_OPEN_DESCRIBE_DIALOG = "open_describe_dialog";
