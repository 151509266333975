import { Fullscreen } from "@mui/icons-material";
import { Fab } from "@mui/material";
import {
  DetailedHTMLProps,
  IframeHTMLAttributes,
  useEffect,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import { SleepCheckupInfo } from "../Types";

/**
 * {@link DiseaseRiskReportFrame} のプロパティ
 */
export type DiseaseRiskReportFrameProps = Omit<
  DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>,
  "src"
> & {
  /** @see SleepCheckupInfo */
  sleepCheckupInfo: SleepCheckupInfo | null;
};
/**
 * 疾患リスクレポート表示用の iframe です。
 *
 * @param param0 {@link DiseaseRiskReportFrameProps}
 * @returns 疾患リスクレポート表示用の iframe
 */
export default function DiseaseRiskReportFrame({
  sleepCheckupInfo,
  title,
  style,
  ...props
}: DiseaseRiskReportFrameProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (sleepCheckupInfo?.unique_id == null) return;

    iframeRef.current?.contentDocument?.location.replace(
      `/facility/report/${sleepCheckupInfo.unique_id}/disease_risk`
    );
  }, [sleepCheckupInfo?.unique_id]);

  return (
    <>
      {sleepCheckupInfo != null && (
        <Fab
          color="primary"
          sx={{ position: "absolute", right: 0, top: 0 }}
          component={Link}
          to={`/facility/report/${sleepCheckupInfo.unique_id}/disease_risk`}
          target="_blank"
        >
          <Fullscreen />
        </Fab>
      )}
      <iframe
        ref={iframeRef}
        style={{ border: "none", ...style }}
        src="about:blank"
        title={title}
        {...props}
      />
    </>
  );
}
