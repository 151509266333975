import { InterviewAnswer } from "@/components/examinee/interview/Types";
import { getExamineeAxios } from "@/utils/axios";

export function getPrimaryInterview(
  sleepCheckUpId: string,
  currentPath: string
) {
  return getExamineeAxios(currentPath).get(
    getPrimaryInterviewPath(sleepCheckUpId)
  );
}

export function postPrimaryInterviewAnswer(
  sleepCheckUpId: string,
  interviewAnswer: InterviewAnswer,
  currentPath: string
) {
  return getExamineeAxios(currentPath).post(
    getPrimaryInterviewPath(sleepCheckUpId),
    interviewAnswer
  );
}

export function getDailyInterview(
  sleepCheckUpId: string,
  remindId: string,
  currentPath: string
) {
  return getExamineeAxios(currentPath).get(
    getDailyInterviewPath(sleepCheckUpId, remindId)
  );
}

export function postDailyInterviewAnswer(
  sleepCheckUpId: string,
  remindId: string,
  interviewAnswer: InterviewAnswer,
  currentPath: string
) {
  return getExamineeAxios(currentPath).post(
    getDailyInterviewPath(sleepCheckUpId, remindId),
    interviewAnswer
  );
}

export function getScreen(screen: string, currentPath: string) {
  return getExamineeAxios(currentPath).get(`/api/screen/${screen}/`);
}

function getPrimaryInterviewPath(sleepCheckUpId: string) {
  return `/api/sleep-checkup/${sleepCheckUpId}/primary-interview/`;
}

function getDailyInterviewPath(sleepCheckUpId: string, remindId: string) {
  return `/api/sleep-checkup/${sleepCheckUpId}/daily-interview/${remindId}/`;
}
