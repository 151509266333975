import { SCAppBar } from "@/components/sleep_checkup_v1/SCAppBar";
import { callMedicalFacilityUserUpdateAPI } from "@/pages/facility/users/UpdateAPI";
import { facilityTheme } from "@/theme/themeV1";
import { Box, ThemeProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function Update() {
  const location = useLocation();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const search = location.search;
    const query = new URLSearchParams(search);

    callMedicalFacilityUserUpdateAPI(query.get("token"))
      .then((res) => {
        setMessage(`メールアドレスの更新が完了しました`);
      })
      .catch((err) => {
        setMessage(
          `有効期限切れもしくは無効なリンクです。メールアドレスの更新が完了していない場合は再度施設のご担当者にご連絡ください。`
        );
      });
  }, [location]);

  return (
    <ThemeProvider theme={facilityTheme}>
      <SCAppBar minWidth={1098} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "80px 40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: "40px",
            width: "432px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0px",
              gap: "16px",
            }}
          >
            <Typography variant="h3">ユーザー更新</Typography>
            {message !== "" && (
              <Typography variant="body2" color={"text.primary"}>
                {message}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Update;
