import { getExamineeAxios } from "@/utils/axios";

export function getSleepCheckupInfo(
  sleepCheckUpId: string,
  currentPath: string
) {
  return getExamineeAxios(currentPath).get(
    `/api/sleep_checkup_info/${sleepCheckUpId}/`
  );
}
