import { InitiateAuthResult } from "@/sleep_compass_lite/data_transfer_objects/target/InitiateAuthResult";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { EmailAddress } from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import { Password } from "@/sleep_compass_lite/domain_models/target/Password";
import {
  CognitoAuthenticationResultStore,
  CognitoRefreshTokenStore,
  SleepCheckupUserStore,
  callUserTypeApi,
} from "@/utils/auth";
import { getApiServerUrl, getAxios } from "@/utils/axios";
import axios, { AxiosError, AxiosResponse } from "axios";

/**
 * 2回目以降の登録
 * サーベイエントリーAPIを呼び出すことで、調査とユーザーアカウントの紐付け、ログインを行う。また、ログインに伴い、認証情報の格納を行う。
 * @param surveyInfo サーベイ情報のUUID
 * @param email 対象者のメールアドレス
 * @param password 対象者のパスワード
 * @returns サーベイエントリーAPIのレスポンス(データ)
 */
export async function signInForSecond(
  surveyInfo: string,
  email: EmailAddress,
  password: Password
): Promise<InitiateAuthResult> {
  try {
    const response = await postSurveyEntry(surveyInfo, email, password);

    CognitoAuthenticationResultStore.setItem(
      response.data.AuthenticationResult
    );
    CognitoRefreshTokenStore.setItem({
      RefreshToken: response.data.AuthenticationResult.RefreshToken,
    });

    const userTypeResponse = await callUserTypeApi(
      getAxios(getApiServerUrl()),
      response.data.AuthenticationResult.IdToken
    );

    SleepCheckupUserStore.setItem(userTypeResponse.data);

    return response.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiError(e.response);
    } else {
      throw e;
    }
  }
}

/**
 * サーベイエントリーAPIの呼び出し
 * @param surveyInfo サーベイ情報のUUID
 * @param email 対象者のメールアドレス
 * @param password 対象者のパスワード
 * @returns サーベイエントリーAPIのレスポンス
 */
function postSurveyEntry(
  surveyInfo: string,
  email: EmailAddress,
  password: Password
): Promise<AxiosResponse<InitiateAuthResult>> {
  return axios.post<InitiateAuthResult>(
    `${getApiServerUrl()}/api/lite/survey_entry/`,
    {
      survey_info: surveyInfo,
      email: email.value,
      password: password.value,
    }
  );
}
