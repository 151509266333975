import { Alert, AlertProps } from "@mui/material";
import { ReactNode } from "react";

type SCAlertProps = {
  children: ReactNode;
  onClose?: AlertProps["onClose"];
};

export const NonFieldError = ({ children, onClose }: SCAlertProps) => {
  return (
    <AlertBase severity="error" onClose={onClose}>
      {children}
    </AlertBase>
  );
};

export const SuccessAlert = ({ children, onClose }: SCAlertProps) => {
  return (
    <AlertBase severity="success" onClose={onClose}>
      {children}
    </AlertBase>
  );
};

export const InfoAlert = ({ children, onClose }: SCAlertProps) => {
  return (
    <AlertBase severity="info" onClose={onClose}>
      {children}
    </AlertBase>
  );
};

type AlertBaseProps = {
  children: ReactNode;
  severity: AlertProps["severity"];
  onClose?: AlertProps["onClose"];
};
const AlertBase = ({ children, severity, onClose }: AlertBaseProps) => {
  if (children) {
    return (
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{
          borderRadius: 2,
          boxSizing: "border-box",
          fontWeight: 500,
          width: "100%",
          ".MuiAlert-icon": { pt: "10px" },
        }}
      >
        {children}
      </Alert>
    );
  } else {
    return <></>;
  }
};
