import { ScrollToTop } from "@/components/common/ScrollToTop";
import { TitleTypography } from "@/components/common/Typographies";
import { ITEMS_WITH_TITLE } from "@/components/elearning/chapter/constants";
import { ElearningChapterList } from "@/components/elearning/common/ElearningChapterList";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useElearningSearchParams } from "../ElearningLayout";

export default function Index() {
  const elearningQuery = useElearningSearchParams();
  return (
    <Container sx={{ backgroundColor: "#FFFFFF", padding: "0" }}>
      <ScrollToTop />
      <Stack sx={{ textAlign: "center", backgroundColor: "#00A99D" }}>
        <Typography
          sx={{ marginTop: "32px", fontSize: "24px", color: "#FFFFFF" }}
        >
          睡眠は脳と心の栄養
        </Typography>
        <Box
          component="img"
          sx={{ width: "260px", margin: "auto" }}
          alt="睡眠クイズ"
          src="/img/image_nutrition_of_brain_and_heart.png"
        />
      </Stack>
      <Stack sx={{ pt: 8, px: 4 }} spacing={14}>
        <Stack spacing={6}>
          <TitleTypography>睡眠について学ぶ</TitleTypography>
          <Typography variant="body2">
            快適な睡眠で健康な毎日を送るための秘訣を簡単にまとめました。かんたんに実践できることばかりですので、一読ください。
          </Typography>
        </Stack>
        <ElearningChapterList
          items={ITEMS_WITH_TITLE}
          elearningSearchParams={elearningQuery}
        />
      </Stack>
    </Container>
  );
}
