import { SubtitleTypography } from "@/components/common/Typographies";
import { ImageBox } from "@/components/elearning/chapter/Boxes";
import { Layout } from "@/components/elearning/chapter/Layout";
import { SubChapterStack } from "@/components/elearning/chapter/Stacks";
import { Typography } from "@mui/material";

export function Chapter01() {
  return (
    <Layout title="睡眠・覚醒リズムとは">
      <SubChapterStack>
        <SubtitleTypography>2種類の睡眠</SubtitleTypography>
        <Typography variant="body2">
          睡眠には、浅い眠りの「レム睡眠」と、深く安らかな眠りの「ノンレム睡眠」の2種類があります。
          この2つの種類の睡眠がセットになって約90分サイクルで一晩に4〜5回繰り返されます。
        </Typography>
        <ImageBox
          src="/img/image_chapter1_1.png"
          alt="レム睡眠とノンレム睡眠"
        />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>レム睡眠</SubtitleTypography>
        <Typography variant="body2">
          レム睡眠時には体は休息していますが、脳は働いており、記憶や感情の整理を行います。
        </Typography>
        <Typography variant="body2">
          例えば、徹夜で勉強をして、テストが終わったら何も覚えていなかった、という経験をお持ちの方もいらっしゃるのではないか、と思います。
          レム睡眠時に勉強した内容を脳に書き込んで、定着させているのですが、睡眠をとらなかったために記憶が定着していないからなのです。
          また、夢を見るのもレム睡眠中です。
        </Typography>
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>ノンレム睡眠</SubtitleTypography>
        <Typography variant="body2">
          ノンレム睡眠時には、脳は休息していますが体は働いています。
        </Typography>
        <Typography variant="body2">
          一晩に数回繰り返されるノンレム睡眠ですが、特に寝入りばなから3時間に深い眠りが集中し、成長ホルモンの分泌、新陳代謝、免疫機能を高める働きが活発に行われます。
        </Typography>
        <Typography variant="body2">
          ですから、寝付いたばかりの頃に、携帯電話などで目を覚ますことがないよう、電源を切るなどの工夫が必要です。
        </Typography>
      </SubChapterStack>
    </Layout>
  );
}
