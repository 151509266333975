import { Add } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { ReactNode } from "react";

/**
 * Addアイコン付きのボタン
 */
type AddButtonProps = {
  children?: ReactNode;
  size?: ButtonProps["size"];
  onClick: ButtonProps["onClick"];
  variant?: ButtonProps["variant"];
  disabled?: ButtonProps["disabled"];
};
export function AddButton({
  children,
  size,
  onClick,
  variant,
  disabled,
}: AddButtonProps) {
  return (
    <Button
      variant={variant || "contained"}
      startIcon={<Add />}
      size={size || "small"}
      onClick={onClick}
      disabled={disabled}
    >
      {children || "追加"}
    </Button>
  );
}
