import {
  Grid,
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
  TableBody as MUITableBody,
  TableBodyProps as MUITableBodyProps,
  TableContainer as MUITableContainer,
  TableContainerProps as MUITableContainerProps,
  TableRow as MUITableRow,
  TableRowProps as MUITableRowProps,
  Stack,
  SxProps,
  TableCell,
  TableCellProps,
  Typography,
} from "@mui/material";
import { Property } from "csstype";
import { ReactNode } from "react";
import {
  DiseaseInfo,
  DiseaseRiskCommonProps,
  ExamineeInfo,
  PrintPage,
  PrintPageBody,
  PrintPageHeader,
  SectionTitle,
} from "../commons";

/**
 * {@link InterviewSummaryTableRow} のプロパティ
 */
export interface InterviewSummaryTableRowProps extends DiseaseInfo {
  /**
   * コメント
   */
  note: string;
}

/**
 * 問診結果の合計行のコンポーネント
 *
 * @param props
 * @returns 問診結果の合計行のコンポーネント
 */
export function InterviewSummaryTableRow(props: InterviewSummaryTableRowProps) {
  return (
    <TableRow>
      <TableCell colSpan={4} sx={{ p: "22px 16px" }}>
        <Stack direction="row" justifyContent="end" sx={{ gap: "22px" }}>
          <Stack direction="row" sx={{ gap: "5px", alignItems: "center" }}>
            <Typography
              sx={{
                color: "#000000",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "Noto Sans JP",
                fontSize: "8px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "150%",
                letterSpacing: "0.2px",
              }}
            >
              {props.note}
            </Typography>
            {props.is_risk == null && (
              <Typography
                sx={{
                  color: "#FF3A30",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "Noto Sans JP",
                  fontSize: "8px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "100%",
                  letterSpacing: "0.2px",
                }}
              >
                ※問診回答不足のため未判定
              </Typography>
            )}
          </Stack>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Typography
              component="span"
              sx={{
                color: "#000000",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "Noto Sans JP",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "100%",
                letterSpacing: "0.2px",
                mr: "10px",
              }}
            >
              合計
            </Typography>
            <Typography
              component="span"
              sx={{
                color: `${
                  props.is_risk == null
                    ? "rgba(112, 112, 112, 0.50)"
                    : props.is_risk
                    ? "#FF3A30"
                    : "#01996D"
                }`,
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "Noto Sans JP",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "100%",
                letterSpacing: "0.2px",
                mr: "5px",
              }}
            >
              {props.score}点
            </Typography>
            <Typography
              component="span"
              sx={{
                color: "#000000",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "Noto Sans JP",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "100%",
                letterSpacing: "0.2px",
              }}
            >
              / {props.max_score}点
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

/**
 * {@link TableHeaderCell} のプロパティ
 */
export interface TableHeaderCellProps {
  /** 要素 */
  children: ReactNode;
  /** テキスト表示位置 */
  textAlign?: Property.TextAlign;
  /** 横幅 */
  width?: Property.Width;
  /** padding */
  padding?: Property.Padding;
}
/**
 * 医療機関向けレポートヘッダー用の TableCell
 * @param param0 {@link TableHeaderCellProps}
 * @returns 医療機関向けレポートヘッダー用の TableCell
 */
export function TableHeaderCell({
  children,
  textAlign,
  width,
  padding = "11px",
}: TableHeaderCellProps) {
  return (
    <TableCell
      component="th"
      sx={{
        "&.MuiTableCell-root": {
          color: "#000000",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: "Noto Sans JP",
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "150%",
          letterSpacing: "0.2px",
          padding,
          textAlign,
          width,
        },
      }}
    >
      {children}
    </TableCell>
  );
}

/**
 * 施設用疾患リスクレポート共通プロパティ
 */
export interface MedicalFacilityDiseaseRiskReportCommonProps
  extends DiseaseRiskCommonProps {
  /** ページ番号 */
  page: number;
  /** 最大ページ数 */
  maxPage: number;
}

/**
 * 疾患リスク設問と回答
 */
export interface Question {
  /** 設問 */
  question: ReactNode;
  /** 回答 */
  choices: {
    /** テキスト */
    text: string;
    /** 回答に対するポイント */
    point: number;
  }[];
}
export interface QuestionRowsProps {
  /** {@link Question} */
  question: Question;
  /** 選択項目 */
  choiceIndex: number | null;
  /** 行番号 */
  rowIndex: number;
  /** 行の高さ */
  rowHeight?: Property.Height;
  /** 選択肢の {@link SxProps} */
  choiceStyle: SxProps;
  /** 点数の {@link SxProps} */
  pointStyle: SxProps;
}
/**
 * 疾患リスク設問の行
 */
export function QuestionRows({
  question,
  choiceIndex,
  rowIndex,
  rowHeight,
  choiceStyle,
  pointStyle,
}: QuestionRowsProps) {
  const rowProps: SxProps = [
    {},
    {
      "&.MuiTableRow-root td": { backgroundColor: "rgba(234, 234, 234, 0.30)" },
    },
  ][rowIndex % 2];
  return (
    <>
      {question.choices.map((c, i) => (
        <TableRow
          key={i}
          sx={{
            height: rowHeight,
            ...rowProps,
          }}
        >
          {i === 0 && (
            <QuestionCell rowSpan={question.choices.length}>
              {question.question}
            </QuestionCell>
          )}
          <AnswerCell>
            <Checkbox checked={choiceIndex === i}></Checkbox>
          </AnswerCell>
          <ChoicesCell sx={choiceStyle}>{c.text}</ChoicesCell>
          <PointCell sx={pointStyle}>{c.point}</PointCell>
        </TableRow>
      ))}
    </>
  );
}
/**
 * {@link MedicalFacilityReportLayout} 用のプロパティ
 */
export interface MedicalFacilityReportLayoutProps {
  /** ページ番号 */
  page: number;
  /** 最大ページ番号 */
  maxPage: number;
  /** 表示要素 */
  children: ReactNode;
  /** 受診者情報 */
  examinee: ExamineeInfo;
}
/**
 * 医療機関用レポートのレイアウト
 *
 * @param param0 {@link MedicalFacilityReportLayoutProps}
 * @returns 医療機関用レポートのレイアウト
 */
export function MedicalFacilityReportLayout({
  page,
  maxPage,
  children,
  examinee,
}: MedicalFacilityReportLayoutProps) {
  return (
    <PrintPage>
      <PrintPageHeader
        color="#01996D"
        title1="睡眠健康測定"
        title2="医療機関用レポート"
        subtitle={`（${page}/${maxPage}）`}
      />
      <PrintPageBody>
        <Stack sx={{ gap: "22px" }}>
          {/* 受診者情報 */}
          <SectionTitle text="受診者情報" />
          <ExamineeInfoSection
            name={examinee.name}
            gender={examinee.gender}
            birthday={examinee.birthday}
            facility_name={examinee.facility_name}
          />
        </Stack>

        {children}
      </PrintPageBody>
    </PrintPage>
  );
}

/**
 * {@link InterviewTableBody} のプロパティ
 */
export interface InterviewTableBodyProps extends MUITableBodyProps {}
/**
 * 医療機関用レポートの問診用 TableBody
 * @param props
 * @returns 医療機関用レポートの問診用 TableBody
 */
export function InterviewTableBody({ sx, ...props }: InterviewTableBodyProps) {
  return (
    <MUITableBody
      sx={{
        "td:not([rowspan]):not([colspan])": {
          borderLeft: "1px solid #E0E0E0",
        },
        ...sx,
      }}
      {...props}
    />
  );
}

/**
 * レポート用テーブルコンテナ
 * @param param0 {@link MUITableContainerProps MUI用のTableContainerProps}
 * @returns レポート用テーブルコンテナ
 */
export function TableContainer({ sx, ...props }: MUITableContainerProps) {
  return (
    <MUITableContainer
      sx={{
        border: "1px solid #E0E0E0",
        td: {
          borderBottom: 0,
        },
        "tr:not(:last-child)": {
          borderBottom: "1px solid #E0E0E0",
        },
        "tr th": {
          backgroundColor: "#F4F4F4",
          fontWeight: "700",
          fontSize: "10px",
          p: "11px 11px 11px 16px",
        },
        ...sx,
      }}
      {...props}
    ></MUITableContainer>
  );
}

/**
 * レポート用TableRow
 * @param param0 {@link MUITableRowProps MUI用のTableRow}
 * @returns レポート用TableRow
 */
export function TableRow({ sx, ...props }: MUITableRowProps) {
  return (
    <MUITableRow
      sx={{
        "&>th.MuiTableCell-root": {
          background: "rgba(234, 234, 234, 0.50)",
        },
        ...sx,
      }}
      {...props}
    />
  );
}
/**
 * {@link Checkbox} 用のプロパティ
 */
export interface CheckboxProps extends Omit<MUICheckboxProps, "disabled"> {}
/**
 * レポート用のチェックボックス
 */
export function Checkbox({ checked, ...props }: CheckboxProps) {
  return (
    <MUICheckbox
      {...props}
      sx={{
        width: "18px",
        height: "18px",
        "&.Mui-checked": {
          color: "#01996D",
        },
        "&.MuiCheckbox-root": {
          padding: "0px",
        },
        transform: "scale(0.7)",
      }}
      readOnly
      checked={checked}
      disabled={!checked}
    />
  );
}
/**
 * {@link QuestionCell} 用のプロパティ
 */
export interface QuestionCellProps {
  /** 要素 */
  children?: ReactNode;
  /** TableCell の rowSpan */
  rowSpan: TableCellProps["rowSpan"];
}
/**
 * 質問用のTableCell
 * @param param0 {@link QuestionCellProps}
 * @returns 質問用のTableCell
 */
export function QuestionCell({ children, rowSpan }: QuestionCellProps) {
  return (
    <TableCell
      rowSpan={rowSpan}
      sx={{
        verticalAlign: "top",
        p: "8px 12px",
      }}
    >
      {children}
    </TableCell>
  );
}
/**
 * {@link PointCell} 用のプロパティ
 */
export interface PointCellProps {
  /** 要素 */
  children: ReactNode;
  /** ポイント用のTableCellの{@link SxProps} */
  sx: SxProps;
}
/**
 * ポイント用のTableCell
 *
 * @param param0 {@link PointCellProps}
 * @returns ポイント用のTableCell
 */
export function PointCell({ children, sx }: PointCellProps) {
  return (
    <TableCell
      sx={{
        p: "0px",
        textAlign: "center",
      }}
    >
      <Typography sx={sx}>{children}</Typography>
    </TableCell>
  );
}
/**
 * {@link ChoicesCell} 用のプロパティ
 */
export interface ChoicesCellProps {
  /** 要素 */
  children: ReactNode;
  /** {@link SxProps} プロパティ */
  sx: SxProps;
}
/**
 * 選択肢用の TableCell
 * @param param0 {@link ChoicesCellProps}
 * @returns 選択肢用の TableCell
 */
export function ChoicesCell({ children, sx }: ChoicesCellProps) {
  return (
    <TableCell
      sx={{
        textAlign: "left",
        p: "0px 16px",
      }}
    >
      <Typography sx={sx}>{children}</Typography>
    </TableCell>
  );
}
/**
 * {@link AnswerCell} 用のTableCell
 */
export interface AnswerCellProps {
  /** 要素 */
  children: ReactNode;
}
/**
 * 回答用のTableCell
 * @param param0 {@link AnswerCellProps}
 * @returns 回答用のTableCell
 */
export function AnswerCell({ children }: AnswerCellProps) {
  return (
    <TableCell
      sx={{
        textAlign: "center",
        p: "0px",
        justifyContent: "center",
        lineHeight: "100%",
      }}
    >
      {children}
    </TableCell>
  );
}
/**
 * {@link TableQuestionTypography} 用のプロパティ
 */
export interface TableQuestionTypographyProps {
  /** 要素 */
  children: ReactNode;
}
/**
 * 設問用の文字装飾
 */
export function TableQuestionTypography({
  children,
}: TableQuestionTypographyProps) {
  return (
    <Typography
      sx={{
        color: "#000000",
        fontFeatureSettings: " 'clig' off, 'liga' off",
        fontFamily: "Noto Sans JP",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
        letterSpacing: "0.2px",
      }}
    >
      {children}
    </Typography>
  );
}
/**
 * {@link BasisOfDetermination} のプロパティ
 */
export interface BasisOfDeterminationProps {
  /**
   * 根拠の注釈
   */
  basisAnnotations: string[];
}
/**
 * 判定の根拠の注釈
 * @param props
 */
export function BasisOfDetermination({
  basisAnnotations,
}: BasisOfDeterminationProps) {
  return (
    <table>
      <tbody>
        {basisAnnotations.map((annotation, i) => (
          <tr key={i}>
            {i === 0 && (
              <td
                rowSpan={basisAnnotations.length}
                style={{ verticalAlign: "top" }}
              >
                <Typography
                  sx={{
                    color: "#707070",
                    textAlign: "justify",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "Noto Sans JP",
                    fontSize: "8px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "170%",
                    letterSpacing: "0.15px",
                  }}
                >
                  判定の根拠：
                </Typography>
              </td>
            )}
            <td>
              <Typography
                sx={{
                  color: "#707070",
                  textAlign: "justify",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "Noto Sans JP",
                  fontSize: "8px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "170%",
                  letterSpacing: "0.15px",
                }}
              >
                {annotation}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

/**
 * {@link ExamineeInfoSection} のプロパティ
 */
export interface ExamineeInfoSectionProps extends ExamineeInfo {
  /** 受診者向けフラグ */
  forExaminee?: boolean;
}
/**
 * 受診者情報
 * @param param0 {@link ExamineeInfoSectionProps}
 * @returns 受診者情報
 */
export function ExamineeInfoSection({
  name,
  gender,
  birthday,
  facility_name,
  forExaminee,
}: ExamineeInfoSectionProps) {
  const color = forExaminee ? "primary.main" : "#000";
  return (
    <Stack
      direction="row"
      spacing="16px"
      sx={{
        ml: "16px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ExamineeInfoItem
            title="氏名"
            value={name}
            maxLength={12}
            color={color}
          />
        </Grid>
        <Grid item xs={8}>
          <ExamineeInfoItem title="生年月日" value={birthday} color={color} />
        </Grid>
        <Grid item xs={4}>
          <ExamineeInfoItem title="性別" value={gender} color={color} />
        </Grid>
        <Grid item xs={8}>
          <ExamineeInfoItem
            title="実施機関"
            value={facility_name}
            maxLength={28}
            color={color}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
/**
 * {@link ExamineeInfoItem} 用のプロパティ
 */
interface ExamineeInfoItemProps {
  /** タイトル */
  title: string;
  /** 値 */
  value: string;
  /**
   * 最大文字数
   * このプロパティを指定すると、value が指定文字数を超えた場合に文言を省略（残りを...に変換）します。
   */
  maxLength?: number;
  /**
   * 文字色
   */
  color?: Property.Color;
}
/**
 * 受診者項目
 * @param param0 {@link ExamineeInfoItemProps}
 * @returns 受診者項目
 */
function ExamineeInfoItem({
  title,
  value,
  maxLength,
  color,
}: ExamineeInfoItemProps) {
  const length = maxLength ?? value.length;
  const val =
    value.length > length ? value.substring(0, length) + "..." : value;
  return (
    <Stack direction="row" alignItems="baseline">
      <Typography
        sx={{
          fontSize: "13px",
          fontWeight: "400",
          whiteSpace: "nowrap",
          fontFeatureSettings: "'clig' off, 'liga' off",
        }}
      >
        {title}
      </Typography>
      <Typography sx={{ fontSize: "13px", ml: "6px", mr: "8px" }}>|</Typography>
      <Typography
        sx={{
          fontSize: "13px",
          fontWeight: "700",
          fontFeatureSettings: "'clig' off, 'liga' off",
          color,
        }}
      >
        {val}
      </Typography>
    </Stack>
  );
}
