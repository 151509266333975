import { BackdropIndicator } from "@/components/common/BackdropIndicator";
import { getEncodedCurrentPath } from "@/components/common/LocationService";
import { MessageAlert } from "@/components/common/MessageAlert";
import elearningConstants from "@/components/elearning/common/constants";
import {
  ErrorMessages as ErrMsg,
  getApiErrorMessage,
} from "@/components/elearning/quiz/ErrorService";
import { QuizAnswer } from "@/components/elearning/quiz/QuizAnswer";
import {
  AnswerChangeHandler,
  QuizForm,
} from "@/components/elearning/quiz/QuizForm";
import { QuestionFormInfo } from "@/components/elearning/quiz/QuizFormInfo";
import { QuizText } from "@/components/elearning/quiz/QuizText";
import TeacherImage from "@/components/elearning/quiz/TeacherImage";
import {
  getElearningApiUrl,
  getNextPath,
  getQuizScreenApiUrl,
  getResultPagePath,
} from "@/components/elearning/quiz/UrlService";
import { SCREEN_TYPE_QUESTION } from "@/components/examinee/interview/Constants";
import {
  QuestionAnswer,
  InterviewEntry as QuizEntry,
  Screen as QuizScreen,
} from "@/components/examinee/interview/Types";
import { getExamineeAxios } from "@/utils/axios";
import { Stack, Typography } from "@mui/material";
import { Axios } from "axios";
import { useEffect, useState } from "react";
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";

type QuizPageState = {
  entry: QuizEntry;
  api: string;
  answers: QuestionAnswer[];
};

function Quiz() {
  const [showIndicator, setShowIndicator] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [answer, setAnswer] = useState<QuizAnswer | null>(null);
  const [formInfo, setFormInfo] = useState<QuestionFormInfo | null>(null);
  const [elearningApi, setElearningApi] = useState<string | null>(null);
  const [prevAnswers, setPrevAnswers] = useState<QuestionAnswer[]>([]);
  const [nextEntry, setNextEntry] = useState<QuizEntry | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  // axiosインスタンスに渡すために、currentPathを保持
  const currentPath = getEncodedCurrentPath(location);

  useEffect(() => {
    const setStates = (
      api: string | null,
      entry: QuizEntry | null,
      screen: QuizScreen | null,
      prevAnswers: QuestionAnswer[],
      message: string | null
    ) => {
      if (api == null || entry == null || screen == null) {
        setElearningApi(null);
        setFormInfo(null);
        setAnswer(null);
        setPrevAnswers([]);
        setErrorMessage(message);
        return;
      }

      setElearningApi(api);
      setAnswer(new QuizAnswer(entry.id, screen));
      setFormInfo(
        new QuestionFormInfo("Question", screen.question_group.questions[0])
      );
      setPrevAnswers(prevAnswers);
      setErrorMessage(message);
    };

    setShowIndicator(true);

    const examineeAxios = getExamineeAxios(currentPath);

    getState(location, examineeAxios)
      .then((state) => {
        const quizEntry = state.entry;
        const elearningApi = state.api;
        const prevAnswers = state.answers;

        if (quizEntry.screen.path == null) {
          setStates(null, null, null, [], ErrMsg.MSG_0001);
          return;
        }

        // QuizEntryからQuizScreenを取得するためのAPIのURLを取得
        const quizScreenApi = getQuizScreenApiUrl(quizEntry.screen.path);

        // QuizScreenを取得
        examineeAxios
          .get(quizScreenApi)
          .then((res) => {
            setStates(elearningApi, quizEntry, res.data, prevAnswers, null);
          })
          .catch((err) => {
            const message = getApiErrorMessage(err);
            setStates(null, null, null, [], message);
          })
          .finally(() => {
            setShowIndicator(false);
          });
      })
      .catch((err) => {
        const message = getApiErrorMessage(err);
        setStates(null, null, null, [], message);
        setShowIndicator(false);
      });
  }, [location, currentPath]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formInfo == null) {
      return;
    }

    switch (formInfo.mode) {
      case "Question":
        if (elearningApi == null || answer == null) {
          return;
        }

        setShowIndicator(true);

        const examineeAxios = getExamineeAxios(currentPath);

        // 回答を送信
        examineeAxios
          .post(elearningApi, answer.getInterviewAnswer())
          .then((res) => {
            // 今回の回答を追記して次のページに渡す
            prevAnswers.push(answer.questionAnswer);

            const nextEntry: QuizEntry = res.data;
            setNextEntry(nextEntry);
            setFormInfo(
              new QuestionFormInfo(
                "Explanation",
                formInfo.question,
                nextEntry.screen.screen_type
              )
            );
          })
          .catch((err) => {
            setErrorMessage(`${err}`);
          })
          .finally(() => {
            setShowIndicator(false);
          });

        return;
      case "Explanation":
        if (elearningApi == null || nextEntry == null) {
          return;
        }

        // スクロールをリセット
        window.scrollTo(0, 0);

        switch (nextEntry.screen.screen_type) {
          case SCREEN_TYPE_QUESTION:
            const state = {
              entry: nextEntry,
              api: elearningApi,
              answers: prevAnswers,
            };
            toNextQuiz(navigate, state, location.search);
            return;
          default:
            toQuizResult(navigate, prevAnswers, location.search);
            return;
        }
    }
  };

  const onChange: AnswerChangeHandler = (questionAnswer) => {
    if (answer == null) {
      return;
    }

    setAnswer(new QuizAnswer(answer.entryId, answer.screen, questionAnswer));
  };

  const quizFormMB = formInfo?.mode === "Explanation" ? 18 : 16;

  return (
    <>
      <BackdropIndicator open={showIndicator} />
      {errorMessage != null && (
        <MessageAlert severity="error" message={errorMessage} sx={{ mb: 1 }} />
      )}
      {formInfo != null && answer != null && (
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              px: 4,
              backgroundColor: elearningConstants.IMAGE_BACKGROUND,
            }}
          >
            <Typography variant="h5" sx={{ color: "primary.main" }}>
              睡眠クイズ
            </Typography>
            <Stack sx={{ pt: 4 }}>
              <TeacherImage width="168px" />
            </Stack>
          </Stack>
          <QuizText
            sx={{ pt: 8, px: 4, pb: 6, background: "white" }}
            info={formInfo}
          />
          <QuizForm
            sx={{ mt: 6, mx: 4, mb: quizFormMB }}
            info={formInfo}
            answer={answer.questionAnswer}
            onChange={onChange}
            onSubmit={handleSubmit}
          />
        </Stack>
      )}
    </>
  );
}

function getState(location: Location, examineeAxios: Axios) {
  if (location.state == null) {
    // 一問目、QuizEntryをAPIから取得
    return new Promise<QuizPageState>((resolve, reject) => {
      const elearningApi = getElearningApiUrl(location.search);
      if (elearningApi == null) {
        reject(new Error("URL error"));
        return;
      }

      examineeAxios
        .get(elearningApi)
        .then((res) => {
          const quizEntry: QuizEntry = res.data;
          resolve({ entry: quizEntry, api: elearningApi, answers: [] });
        })
        .catch((err) => {
          reject(err);
        });
    });
  } else {
    // 二問目以降、QuizEntryをstateから取得
    return new Promise<QuizPageState>((resolve) => {
      const state = location.state as QuizPageState;
      resolve(state);
    });
  }
}

function toNextQuiz(
  navigate: NavigateFunction,
  state: QuizPageState,
  queryString: string
) {
  // URL引数を変更して、別URLに移動。同じURLだとリロードしたときにstateが1問目に戻ってしまうため。
  const nextPage = getNextPath(state.entry.screen.id, queryString);

  navigate(nextPage, {
    state: state,
  });
}

function toQuizResult(
  navigate: NavigateFunction,
  prevAnswers: QuestionAnswer[],
  queryString: string
) {
  const resultPagePath = getResultPagePath(queryString);

  navigate(resultPagePath, {
    state: prevAnswers,
  });
}

export default Quiz;
