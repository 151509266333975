import { getEncodedCurrentPath } from "@/components/common/LocationService";
import { NonFieldError } from "@/components/common/SCAlert";
import { Copyright } from "@/components/examinee/Copyright";
import { InterviewAppBar } from "@/components/examinee/interview/InterviewAppBar";
import { InterviewEntry } from "@/components/examinee/interview/Types";
import { Box, Container, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Params, useLocation, useNavigate, useParams } from "react-router-dom";

export class ErrorContext {
  readonly error: Error;
  readonly message: string;

  constructor(error: Error, message: string) {
    this.error = error;
    this.message = message;
  }
}

type GetInterviewEntryClosure = (
  params: Readonly<Params<string>>,
  currentPath: string
) => Promise<any>;

type GetInterviewPathClosure = (
  entry: InterviewEntry,
  params: Params
) => string;

type InterviewEntryLoaderProps = {
  getEntry: GetInterviewEntryClosure;
  getPath: GetInterviewPathClosure;
};
export function InterviewEntryLoader({
  getEntry,
  getPath,
}: InterviewEntryLoaderProps) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState<ErrorContext | null>(null);

  const FOOTER_HEIGHT = "85px";
  const BACKGROUND_COLOR = "white";
  const HEADER_HEIGHT = 56;

  useEffect(() => {
    if (params.sleepCheckupId == null) {
      throw new Error("params.sleepCheckupId is null");
    }

    getEntry(params, getEncodedCurrentPath(location))
      .then((res) => {
        navigate(getPath(res.data as InterviewEntry, params));
      })
      .catch((err) => {
        setError(createErrorContext(err));
      });
  }, [navigate, location, params, getEntry, getPath]);

  return (
    <Box sx={{ minHeight: "100vh", position: "relative" }}>
      <InterviewAppBar height={HEADER_HEIGHT} title="" />
      <Container
        maxWidth="sm"
        sx={{
          pt: 0,
          pl: 0,
          pr: 0,
          pb: FOOTER_HEIGHT,
          backgroundColor: BACKGROUND_COLOR,
        }}
      >
        {error != null && (
          <Stack spacing={8} sx={{ mx: 4, mt: 4, mb: 0 }} alignItems="center">
            <NonFieldError>{error.message}</NonFieldError>
          </Stack>
        )}
      </Container>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: FOOTER_HEIGHT,
        }}
      >
        <Copyright />
      </Stack>
    </Box>
  );
}

export function createErrorContext(err: any): ErrorContext {
  const statusCode = err.response?.status;
  const messages = err.response?.data?.detail;

  if (
    statusCode &&
    400 <= statusCode &&
    statusCode < 500 &&
    messages &&
    messages.length > 0
  ) {
    // 400系エラーでエラーメッセージが存在する場合はそれを表示する
    return new ErrorContext(err, messages);
  } else {
    return new ErrorContext(
      err,
      `エラーが発生しました。しばらく経ってから、もう一度、アクセスしてください。\n(${err.message})`
    );
  }
}
