import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function BluelightHelpDialog() {
  return (
    <HelpButton dialogTitle="ブルーライト">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "睡眠への影響",
            content:
              "スマホ・テレビ・PCから発光されるブルーライトは体内時計をずらし、寝付きを悪くします。",
          },
          {
            title: "おすすめ",
            content:
              "寝る直前にスマートフォンを見ることがストレス解消になっている場合もあるため、無理ない範囲で時間の短縮や、寝室には持ちこまない、コンテンツはゲームやSNSなど興奮するものは避ける、スリープモードの活用などをしましょう。",
          },
        ]}
      />
    </HelpButton>
  );
}
