import ErrorMessage from "@/components/ErrorMessage";
import Layout from "@/components/Layout";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Avatar, Box, Container, Typography } from "@mui/material";
import { ReactNode } from "react";

type AuthLayoutProps = {
  title: string;
  children: ReactNode;
  errorMessage?: string;
};
function AuthLayout(props: AuthLayoutProps) {
  return (
    <Layout>
      <Box
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "85vh",
          width: 1,
        }}
      >
        <ErrorMessage errorMessage={props.errorMessage ?? ""} />
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {props.title}
        </Typography>
        <Container component="main" maxWidth="xs">
          {props.children}
        </Container>
      </Box>
    </Layout>
  );
}
export default AuthLayout;
