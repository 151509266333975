import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Annotation,
  DiseaseInfo,
  DiseaseRiskCommonProps,
  DiseaseRisksInfo,
  PrintPage,
  PrintPageBody,
  PrintPageHeader,
  RecommendationExistenceInfo,
  SectionTitle,
  getDiseaseRisks,
  getRiskLabel,
} from "./commons";
import {
  ExamineeInfoSection,
  TableContainer,
} from "./medical_facility_report/commons";

/**
 * {@link ExamineeReport} 用のプロパティ
 */
export interface ExamineeReportProps extends DiseaseRiskCommonProps {
  /** {@link RecommendationExistenceInfo} */
  existence: RecommendationExistenceInfo;
}
/**
 * 受診者向け疾患リスクレポート
 * @param param0 {@link ExamineeReportProps}
 * @returns 受診者向け疾患リスクレポート
 */
export default function ExamineeReport({
  data,
  existence,
}: ExamineeReportProps) {
  return (
    <PrintPage>
      <PrintPageHeader
        color="#0056A0"
        title1="睡眠健康測定"
        title2="疾患リスクレポート"
      />
      <PrintPageBody>
        <LeadComment isRisk={existence.total} />
        <Stack sx={{ gap: "22px" }}>
          {/* 受診者情報 */}
          <SectionTitle text="受診者情報" color="#0056A0" />
          <ExamineeInfoSection
            name={data.examinee.name}
            birthday={data.examinee.birthday}
            gender={data.examinee.gender}
            facility_name={data.examinee.facility_name}
            forExaminee
          />
        </Stack>
        <Stack sx={{ gap: "22px" }}>
          {/* 疾患リスクについて */}
          <SectionTitle text="疾患リスクについて" color="#0056A0" />
          <DiseaseRiskTable data={data.disease_risks} existence={existence} />
        </Stack>
        <Stack
          direction="row"
          className="bottom-note"
          sx={{
            width: "680px",
            borderRadius: "5px",
            padding: "16px 22px",
            alignItems: "center",
            gap: "22px",
            alignSelf: "stretch",
            background: "rgba(0, 86, 160, 0.08)",
          }}
        >
          <img src="/img/image_report_disease_risk_info.svg" alt="info" />
          <ul style={{ margin: "0px", paddingLeft: "22px" }}>
            <Typography
              component="li"
              sx={{
                color: "#000000",
                textAlign: "justify",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "170%",
                letterSpacing: "0.2px",
              }}
            >
              本レポートで提供される情報や結果は、病気の診断や治療を意図するものではありません。
            </Typography>
            <Typography
              component="li"
              sx={{
                color: "#000000",
                textAlign: "justify",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "170%",
                letterSpacing: "0.2px",
              }}
            >
              診断ガイドラインに基づく問診回答により、睡眠時無呼吸症候群・不眠症の疾患リスクを表示していますが、
              <br />
              正しく問診回答が行われなかった場合、正しい結果が出ない場合があります。
            </Typography>
            <Typography
              component="li"
              sx={{
                color: "#000000",
                textAlign: "justify",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "170%",
                letterSpacing: "0.2px",
              }}
            >
              診断ガイドラインは、公知かつ信頼性のある出典元（日本国内の学会におけるガイドライン）に基づきます。
            </Typography>
            <Typography
              component="li"
              sx={{
                color: "#000000",
                textAlign: "justify",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "170%",
                letterSpacing: "0.2px",
              }}
            >
              提供される情報や結果に関わらず、気になることがある場合には、かかりつけ医・睡眠の専門医に相談することをお勧めします。
            </Typography>
          </ul>
        </Stack>
      </PrintPageBody>
    </PrintPage>
  );
}

/**
 * {@link DiseaseRiskTable} 用プロパティ
 */
interface DiseaseRiskTableProps {
  /** {@link DiseaseRisksInfo} */
  data: DiseaseRisksInfo;
  /** {@link RecommendationExistenceInfo} */
  existence: RecommendationExistenceInfo;
}
/**
 * 疾患リスクについてのテーブル
 * @param param0 {@link DiseaseRiskTableProps}
 * @returns 疾患リスクについてのテーブル
 */
function DiseaseRiskTable({ existence, data }: DiseaseRiskTableProps) {
  const rows = getDiseaseRisks({ existence, info: data });
  const allAnnotations = rows.flatMap((a) => a.basis_annotations);
  return (
    <Stack sx={{ gap: "11px" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "&>.MuiTableCell-root": {
                  color: "#000",
                  fontWeight: 500,
                  background: "rgba(234, 234, 234, 0.50)",
                },
              }}
            >
              <TableCell component="th">疾患名</TableCell>
              <TableCell component="th">判定</TableCell>
              <TableCell component="th">コメント</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((d, i) => (
              <DiseaseRiskRow
                key={i}
                data={d}
                allAnnotations={allAnnotations}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        alignItems="flex-start"
        sx={{ padding: "0px 16px", gap: "11px" }}
      >
        <Annotation text="判定の根拠" showRightBorder forExamineeReport />
        <Stack>
          {allAnnotations.map((a, i) => (
            <Annotation key={i} text={`*${i + 1}：${a}`} forExamineeReport />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
/**
 * {@link DiseaseRiskRow} のプロパティ
 */
interface DiseaseRiskRowProps {
  /** {@link DiseaseInfo} */
  data: DiseaseInfo;
  /** 注釈 */
  allAnnotations: string[];
}
/**
 * 疾患リスク情報の行
 * @param param0 {@link DiseaseRiskRowProps}
 * @returns 疾患リスク情報の行
 */
function DiseaseRiskRow({ data, allAnnotations }: DiseaseRiskRowProps) {
  return (
    <TableRow
      sx={{
        verticalAlign: "text-top",
      }}
    >
      <TableCell
        sx={{
          color: "#3d3d3d",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "150%",
          letterSpacing: "0.2px",
          whiteSpace: "nowrap",
        }}
      >
        {data.disease_name}
      </TableCell>
      <TableCell
        sx={{
          color: "#3d3d3d",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "170%",
          letterSpacing: "0.2px",
          whiteSpace: "nowrap",
        }}
      >
        {data.basis_name}
        <sup>
          {data.basis_annotations
            .map((a) => `*${allAnnotations.indexOf(a) + 1}`)
            .join("")}
        </sup>
        {data.score != null && (
          <>
            <br />
            {data.score}/{data.max_score}点
          </>
        )}
        <br />
        {getRiskLabel(data.is_risk)}
      </TableCell>
      <TableCell
        sx={{
          color: "#3d3d3d",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "170%",
          letterSpacing: "0.2px",
        }}
      >
        {data.comment}
      </TableCell>
    </TableRow>
  );
}

/**
 * {@link LeadComment} のプロパティ
 */
interface LeadCommentProps {
  /**
   * リスク有無
   */
  isRisk: boolean | null;
}
/**
 * リード文を表示します。
 * @param props {@link LeadCommentProps}
 * @returns リード文
 */
function LeadComment({ isRisk }: LeadCommentProps) {
  return (
    <Typography
      sx={{
        color: "#000000",
        textAlign: "justify",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Noto Sans JP",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "170%",
        letterSpacing: "0.2px",
      }}
    >
      {isRisk == null ? (
        <>
          今回の睡眠健康測定サービスの実施結果、下記の疾患リスクは問診回答不足により未判定です。
          <br />
          結果に関わらず、気になることがある場合は、医療機関の受診を検討してください。
        </>
      ) : isRisk ? (
        <>
          今回の睡眠健康測定サービスの実施結果、
          <Typography
            component={"span"}
            sx={{
              color: "#000000",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: "Noto Sans JP",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "170%",
              letterSpacing: "0.2px",
            }}
          >
            下記の疾患リスクがあると判定されました。
          </Typography>
          <br />
          気になる場合は、医療機関の受診を検討してください。
          <br />
          その際には添付の「睡眠健康測定｜医療機関用レポート」を提示してください。
        </>
      ) : (
        <>
          今回の睡眠健康測定サービスの実施結果、下記の疾患リスクは低いと判定されました。
          <br />
          結果に関わらず、気になることがある場合は、医療機関の受診を検討してください。
        </>
      )}
    </Typography>
  );
}
