import { SubtitleTypography } from "@/components/common/Typographies";
import { InformationOutline } from "@/sleep_compass_lite/components/target/InformationOutline";
import { Button, ButtonProps, Stack } from "@mui/material";
import { ReactNode } from "react";

type ReportContentTitleProps = {
  /** タイトルに併設するインフォメーションボタンのタイトル */
  buttonTitle?: string;
  /** インフォメーションボタンのクリックハンドラー */
  onClick?: ButtonProps["onClick"];
  /** タイトル文言 */
  children: ReactNode;
};
/**
 * Liteレポートのタイトルコンポーネント
 * タイトルにボタンを併設する場合は、onClickとbuttonTitleの両方を指定する必要があるので注意
 */
export function ReportContentTitle({
  buttonTitle,
  onClick,
  children,
}: ReportContentTitleProps) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <SubtitleTypography>{children}</SubtitleTypography>
      {buttonTitle && onClick && (
        <Button startIcon={<InformationOutline />} onClick={onClick}>
          {buttonTitle}
        </Button>
      )}
    </Stack>
  );
}
