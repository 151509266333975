import {
  SubtitleTypography,
  SupplementTypography,
} from "@/components/common/Typographies";
import { ImageBox } from "@/components/elearning/chapter/Boxes";
import { Layout } from "@/components/elearning/chapter/Layout";
import { SubChapterStack } from "@/components/elearning/chapter/Stacks";
import { Typography } from "@mui/material";

export function Chapter04() {
  return (
    <Layout title="起きた際にしっかり目覚めるためのポイント">
      <SubChapterStack>
        <SubtitleTypography>朝食をしっかり食べる</SubtitleTypography>
        <Typography variant="body2">
          朝食をしっかり噛みながら食べることで、体温を上昇させ、脳と体がすっきりと目覚めます。
          朝食には、寝ている間に消費したエネルギーを補充し、脳を活性化させ、身体を目覚めさせる効果があります。
        </Typography>
        <ImageBox src="/img/image_chapter4_1.png" alt="朝食をしっかり食べる" />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>
          陽の光の入る明るい場所で朝食をとる
        </SubtitleTypography>
        <Typography variant="body2">
          朝の光をしっかり浴びると脳の時計をリセットすることができます。朝の光を浴びるといっても、わざわざ外に出る必要はありません。晴れた日であれば、窓際でカーテンを開けるだけで十分です。太陽の光の入る明るい環境で朝食をとりましょう。
        </Typography>
        <Typography variant="body2">
          食事をしっかり噛んで食べることで感情のコントロールやうつ病に関係しているセロトニンという物質の分泌が促されます。
        </Typography>
        <SupplementTypography>
          ※ただし、日光が出ていない場合は室内を明るくして、白色蛍光など強い光を浴びるのが良いでしょう。
        </SupplementTypography>
        <ImageBox
          src="/img/image_chapter4_2.png"
          alt="陽の光の入る明るい場所で朝食をとる"
        />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>時間がない時の朝食</SubtitleTypography>
        <Typography variant="body2">
          時間がなかったり、食欲がなかったりするときは、トリプトファンとビタミンB6の両方を効率よく摂取できるバナナと牛乳・ヨーグルトの朝食をとるのがおすすめです。
        </Typography>
        <ImageBox src="/img/image_chapter4_3.png" alt="時間がない時の朝食" />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>運動する時間や人との接触をもつ</SubtitleTypography>
        <Typography variant="body2">
          ウォーキングや深呼吸などリズミカルな運動、人とのふれあいにより、更にセロトニンの分泌が促進されます。
        </Typography>
        <ImageBox
          src="/img/image_chapter4_4.png"
          alt="運動する時間や人との接触をもつ"
        />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>30分の昼寝を上手に活用</SubtitleTypography>
        <Typography variant="body2">
          昼食後、「ウトウトしてしまった…」「眠たくて集中できない」という経験はありませんか？
        </Typography>
        <Typography variant="body2">
          そのような時は、お昼休みなどを利用して、15～20分程度の仮眠をとりましょう！！
        </Typography>
        <Typography variant="body2">
          短いお昼寝は、眠気を解消するだけではなく、脳の疲労も解消させ、作業効率を高める効果があります。
        </Typography>
        <ImageBox src="/img/image_chapter4_5.png" alt="短い昼寝" />
        <ImageBox src="/img/image_chapter4_6.png" alt="眠れない時" />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>長く寝すぎてしまう時は…</SubtitleTypography>
        <Typography variant="body2">
          完全に横にならず、明るい部屋でソファやいすにもたれて寝ましょう。
          横になって寝ると体温が下がリ、深い眠りになりやすくなるためです。
          緑茶やコーヒーなど、カフェインが入ったものを飲んでから昼寝しましょう。カフェインがききだすのに20～30分くらいかかるので、深く眠りすぎません。
        </Typography>
        <ImageBox
          src="/img/image_chapter4_7.png"
          alt="長く寝てしまいそうな時"
        />
      </SubChapterStack>
    </Layout>
  );
}
