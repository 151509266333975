import { NonFieldError } from "@/components/common/SCAlert";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { PasswordCheckListItem } from "@/sleep_compass_lite/components/target/PasswordCheckListItem";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import { EmailAddress } from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import {
  EmailVerificationCode,
  EmailVerificationCodeValidationError,
} from "@/sleep_compass_lite/domain_models/target/EmailVerificationCode";
import {
  Password,
  PasswordValidationError,
} from "@/sleep_compass_lite/domain_models/target/Password";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { useConfirmForgetPassword } from "@/sleep_compass_lite/use_cases/target/authentication/UseConfirmForgetPassword";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  email: EmailAddress;
  isSecondUser: boolean; // 2回目以降の対象者かどうか
  surveyInfo: string | null;
};

export function SetNewPassword() {
  const location = useLocation();
  const navigate = useNavigate();

  const { postNewPassword } = useConfirmForgetPassword();

  const [errorMessage, setErrorMessage] = useState("");
  const [verificationCode, setVerificationCode] = useState(
    new EmailVerificationCode("")
  );
  const [password, setPassword] = useState(new Password(""));
  const [password2, setPassword2] = useState(new Password(""));

  const { email, isSecondUser, surveyInfo } =
    (location.state as StateType) || {};
  if (!email.value) {
    return (
      <GeneralError
        to={authenticationPath.getFullPath("ForgotPassword")}
        buttonText="戻る"
      />
    );
  }

  const codeValidationErrors = verificationCode.validate();
  const passwordValidationErrors = password.validate();
  const password2ValidationErrors = password2.lenientValidate();
  const isTwoPasswordsIdentical = password.value === password2.value;

  const hasErrors =
    passwordValidationErrors.length !== 0 ||
    password2ValidationErrors.length !== 0 ||
    !isTwoPasswordsIdentical;

  const passwordGivenError: string[] | null = passwordValidationErrors.includes(
    "InvalidCharsError"
  )
    ? ["※使用できない文字が含まれています"]
    : null;
  const password2GivenError: string[] | null =
    password2ValidationErrors.includes("EmptyError")
      ? null
      : !isTwoPasswordsIdentical
      ? ["※パスワードが一致しません"]
      : password2ValidationErrors.includes("InvalidCharsError")
      ? ["※使用できない文字が含まれています"]
      : null;

  const CODE_VALIDATION_ERROR_MESSAGES: Map<
    EmailVerificationCodeValidationError,
    string
  > = new Map([["FormatError", "半角数字6桁で入力してください"]]);
  const PASSWORD_CHECK_ITEMS: Map<PasswordValidationError, string> = new Map([
    ["LengthError", "8文字以上24文字以下にしてください"],
    ["AlphabetLowerAndUpperError", "大文字と小文字の半角英字を含めてください"],
    ["DigitError", "半角数字を含めてください"],
    ["SymbolError", "記号を含めてください"],
  ]);

  const handleClickRegister = async () => {
    if (hasErrors) {
      // 何かしらのエラーがある場合はパスワード再設定処理を行わない
      return;
    }

    try {
      await postNewPassword(email, password, verificationCode);
      navigate(
        isSecondUser
          ? authenticationPath.getFullPath("SignIn2")
          : authenticationPath.getFullPath("SignIn"),
        {
          state: {
            successMessage:
              "パスワードの再設定が完了しました。設定したパスワードでログインしてください。",
            surveyInfo: surveyInfo,
          },
        }
      );
    } catch (e: unknown) {
      setErrorMessage(getErrorMessage(e));
      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    }
  };

  return (
    <Stack spacing={12} sx={{ pt: 8, pb: 16, px: PX4 }}>
      <Stack spacing={6}>
        <Typography variant="h6" align="center">
          パスワードを再設定
        </Typography>
        {/* エラーメッセージ */}
        <NonFieldError>{errorMessage}</NonFieldError>
        <Typography
          variant="subtitle2"
          textAlign="justify"
          sx={{ color: "text.secondary" }}
        >
          登録したメールアドレスにパスワード再設定用の確認コードをお送りしました。確認コードと新しいパスワードを入力してください。
        </Typography>
        <TextForm
          title="確認コード"
          placeholder="数字6桁"
          value={verificationCode.value}
          onChange={(e) => {
            setVerificationCode(new EmailVerificationCode(e.target.value));
          }}
          validator={() =>
            codeValidationErrors.map(
              (e) => `※${CODE_VALIDATION_ERROR_MESSAGES.get(e)}`
            )
          }
        />
        <TextForm
          title="新しいパスワード"
          value={password.value}
          onChange={(e) => {
            setPassword(new Password(e.target.value));
          }}
          type="password"
          helperText={
            <Stack sx={{ px: 3 }}>
              {[...PASSWORD_CHECK_ITEMS].map(([k, v]) => (
                <PasswordCheckListItem
                  key={k}
                  text={v}
                  checked={!passwordValidationErrors.includes(k)}
                />
              ))}
            </Stack>
          }
          givenErrors={passwordGivenError}
        />
        <TextForm
          title="新しいパスワード（確認）"
          value={password2.value}
          onChange={(e) => {
            setPassword2(new Password(e.target.value));
          }}
          type="password"
          givenErrors={password2GivenError}
        />
      </Stack>
      <Button
        variant="contained"
        size="large"
        sx={{ boxShadow: "none" }}
        onClick={handleClickRegister}
        disabled={hasErrors}
      >
        設定する
      </Button>
    </Stack>
  );
}
