import { useCallback, useRef, useState } from "react";

// QRコードのスキャンに適切と思われるVideoStreamを取得する
export function useVideoStream() {
  // 取得中のStream
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [error, setError] = useState<Error | null>(null);
  // Streamの取得中を表すフラグ
  const isFetchingStream = useRef<boolean>(false);

  const stopTracks = useCallback((stream: MediaStream | null) => {
    if (stream == null) {
      return;
    }
    stream.getVideoTracks().forEach((track) => track.stop());
  }, []);

  const start = useCallback(() => {
    if (isFetchingStream.current === true) {
      return;
    }

    isFetchingStream.current = true;

    // 背面のオートフォーカス付きカメラで画像を取得する。
    navigator.mediaDevices
      .enumerateDevices()
      .then((deviceInfo) => getQRCodeReaderConstraints(deviceInfo))
      .then((constraints) => navigator.mediaDevices.getUserMedia(constraints))
      .then((stream) => {
        setStream((prev) => {
          stopTracks(prev);
          return stream;
        });
        setError(null);
      })
      .catch((e) => setError(e));
  }, [stopTracks]);

  const stop = useCallback(() => {
    if (stream == null) {
      // streamがnullのときは何もしない。setStream(null)をすると、新しいstateをnullで更新してしまうので。
      return;
    }
    stopTracks(stream);
    setStream((prev) => {
      if (prev?.id !== stream.id) {
        // クロージャーがキャプチャしたstreamよりも、新しいstreamがセットされていたらnullで更新しない
        return prev;
      }
      isFetchingStream.current = false;
      setError(null);
      return null;
    });
  }, [stream, stopTracks]);

  return { start, stop, stream, error };
}

/**
 * QRコードのスキャンに適切と思われるVideoStreamを取得するための、MediaStreamConstraintsを返す
 * @param deviceInfo 端末に搭載されたカメラの情報の配列
 * @returns 条件を満たすMediaStreamConstraints。条件を満たすものがない場合は、nullを返す。
 */
async function getQRCodeReaderConstraints(
  deviceInfo: MediaDeviceInfo[]
): Promise<MediaStreamConstraints> {
  // カメラの情報からvideoだけに絞る（audioをフィルターする）
  const videoDeviceInfo = deviceInfo.filter(
    (info) => info.kind === "videoinput" && info.deviceId !== "default"
  );

  // カメラの情報からカメラの機能（MediaTrackCapabilities）を取得する
  const capabilities: MediaTrackCapabilities[] = [];
  for (const info of videoDeviceInfo) {
    const constraints = constraintsWidthDeviceId(info.deviceId);
    capabilities.push(await getCapabilities(constraints));
  }

  // カメラの機能からオートフォーカスを持つカメラを探す
  const c = capabilities.find((capabilities) => hasAutoFocus(capabilities));
  const constraints =
    c != null ? constraintsWidthDeviceId(c.deviceId) : constraintsBackCamera();

  addSize(constraints);
  addFrameRate(constraints);

  return constraints;
}

function getCapabilities(
  constraints: MediaStreamConstraints
): Promise<MediaTrackCapabilities> {
  return navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
    const tracks = stream.getVideoTracks();
    const capabilities = tracks[0].getCapabilities();
    tracks.forEach((t) => t.stop());
    return capabilities;
  });
}

function hasAutoFocus(capabilities: MediaTrackCapabilities): boolean {
  // オートフォーカス付きのカメラは、focusDistance.minが0ではない（焦点距離が固定のカメラはfocusDistance.minが0）
  // refs: https://github.com/w3c/mediacapture-extensions/issues/20#issuecomment-573780240
  const c = capabilities as any;
  return (
    "focusDistance" in c &&
    c.focusDistance.min > 0 &&
    c.facingMode.includes("environment")
  );
}

function constraintsWidthDeviceId(deviceId?: string): MediaStreamConstraints {
  return {
    video: {
      deviceId: deviceId,
    },
    audio: false,
  };
}

function constraintsBackCamera() {
  return {
    video: {
      facingMode: "environment",
    },
    audio: false,
  };
}

// NOTE:
// スマホ(iOS, Androidともに)において、MediaStreamConstraints(VideoTrackConstraints)で指定したwidthとheightと、MediaStreamから流れてくる画像のwidthとheightが入れ替わるので注意。
// おそらく、スマホをランドスケープしたときの位置が、カメラにとって正位置の扱い。Constraintsで指定したwidthが画像のheightに、heightがwidthになる。
// また、MediaTrackSettingsでwidthとheightを取得したときは、iOSとAndroidで動きが異なる。iOSはMediaStreamConstraintsに設定されたwidthとheightのまま、Androidは画像のwidthとheightになっている。
function addSize(constraints: MediaStreamConstraints) {
  // 標準的なサイズ(1280x720)を指定する。が、念の為、1280x720よりもウィンドウサイズが大きな端末を考慮して、標準サイズとウィンドウサイズ(x1.2)のmaxをとる。理由は、ウィンドウサイズよりも小さなサイズで撮影すると、画像を引き伸ばすことになり、荒い画像になり、QRコードが読み取りづらくなることが懸念されるため。
  // また、撮影画面のサイズは、frameRateやQRコードの読取り精度にも関わるので、注意。frameRateは、サイズが小さい方が高い。一方で、読み取り精度は、サイズが小さすぎても大きすぎても読み取りづらくなる。直感的には、サイズが大きい方が高解像度で撮れて良さそうに思えるが、ピンボケしたりして、かえって読み取り精度が落ちる。おそらく、スマホとQRコードの距離に応じて適切な撮影サイズがありそう。
  (constraints.video as any).width = Math.max(
    1280,
    Math.ceil(window.innerHeight * 1.2)
  );
  (constraints.video as any).height = Math.max(
    720,
    Math.ceil(window.innerWidth * 1.2)
  );
}

function addFrameRate(constraints: MediaStreamConstraints) {
  // フレームレートは、撮影時間の計算に利用する。QRコードの撮影中、撮影時間に応じて、メッセージやポップアップを表示する。撮影時間は、カメラに設定されたフレームレートをもとに、画面更新回数から計算する。
  // 例えば、撮影時間が10秒に到達したことを検出するとき、30fpsなら、30(fps)X10(秒)=300回で、300回の画面更新が実施されたときに10秒に到達したと判定できる。
  // また、端末によっては、フレームレートに240などの高すぎる値がデフォルトで設定される。高すぎる値が設定されていると、canvasへの描画やQRコードの検出がボトルネックになり、実際のフレームレートは30-60fpsになることが、よくある。
  // このようなケースでは、カメラに設定されたフレームレートと実際のフレームレートに乖離ができ、前述の撮影時間の判定が実時間とずれる。現実的なフレームレートを指定して、高すぎる値が設定されるのを避け、撮影時間の判定がずれるのを防ぐ。
  (constraints.video as any).frameRate = 30;
}
