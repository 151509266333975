import { Stack } from "@mui/material";
import { Section, SectionLayout } from ".";
import { SleepSummary } from "../commons/sleep_summaries";

interface DaytimeConditionSectionProps {
  daytime_condition?: SleepSummary["daytime_condition"];
}
/**
 * 日中の状態
 * @param props {@link DaytimeConditionSectionProps}
 * @returns 日中の状態
 */
export default function DaytimeConditionSection({
  daytime_condition,
}: DaytimeConditionSectionProps) {
  return (
    <SectionLayout
      title="日中の状態"
      caption="日中の状態では、前日の睡眠の結果、日中の活動にどのような影響が現れたのかを、2つの項目で評価します。"
    >
      <Stack sx={{ gap: 10 }}>
        <DaytimeSleepiness
          daytime_sleepiness={daytime_condition?.daytime_sleepiness}
        />
        <DaytimeTiredness
          daytime_tiredness={daytime_condition?.daytime_tiredness}
        />
      </Stack>
    </SectionLayout>
  );
}

function DaytimeSleepiness({
  daytime_sleepiness,
}: {
  daytime_sleepiness?: SleepSummary["daytime_condition"]["daytime_sleepiness"];
}) {
  return (
    <Section
      title="日中の眠気"
      standardLabel="推奨範囲"
      result={daytime_sleepiness}
      data={[
        {
          title: "日中に眠気を多く感じた日数",
          ...daytime_sleepiness?.number_of_daytime_sleepiness_days,
        },
      ]}
    />
  );
}

function DaytimeTiredness({
  daytime_tiredness,
}: {
  daytime_tiredness?: SleepSummary["daytime_condition"]["daytime_tiredness"];
}) {
  return (
    <Section
      title="日中の疲労"
      standardLabel="推奨範囲"
      result={daytime_tiredness}
      data={[
        {
          title: "日中に疲労を多く感じた日数",
          ...daytime_tiredness?.number_of_daytime_tiredness_days,
        },
      ]}
    />
  );
}
