import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  CssBaseline,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  RecommendationExistenceInfo,
  hasDiseaseRiskOption,
} from "../disease_risk/commons";
import { SummaryResponse, UserType } from "../types";
import {
  INITIAL_DISPLAY_SCREEN_TYPE,
  SUMMARY_TRANSITION_CONFIGS,
  ScreenType,
} from "./constants";

/**
 * SummaryPageContext が使用できない状態で呼び出された時にthrow される Error
 */
export class IllegalStateError extends Error {
  /**
   * コンストラクタ
   * @param message
   */
  constructor(message: string) {
    super(message);
    this.name = "IllegalStateError";
  }
}

/**
 * サマリープロパティ
 */
export type SummaryProps = {
  /** レポートUUID */
  uuid: string | undefined;

  /**
   * ユーザー種別
   * @see {@link UserType}
   */
  userType: UserType;

  /**
   * 測定データの詳細データ
   *
   * @see SummaryResponse
   */
  data: SummaryResponse;

  /**
   * @see RecommendationExistenceInfo
   */
  existence: RecommendationExistenceInfo;
};

export type SummaryPageProperties = {
  props: SummaryProps;
  setScreenType: (screenType: ScreenType) => void;
  isFirstVisit: boolean;
  hasDiseaseRiskOption: boolean;
};
/**
 * コンテキスト
 */
export const SummaryPageContext = createContext<SummaryPageProperties | null>(
  null
);
/**
 * コンテキストを取得する関数
 *
 * @returns
 */
export function useSummaryPageContext() {
  const ctx = useContext(SummaryPageContext);
  if (ctx == null) {
    throw new IllegalStateError("SummaryPageContext を使用してください。");
  }
  return ctx;
}

/**
 * サマリー
 *
 * @param param0 {@link MeasurementDetailsProps}
 * @returns
 */
export function Summary(props: SummaryProps) {
  // 初期表示は総合評価
  const [screenType, setScreenType] = useState<ScreenType>(
    INITIAL_DISPLAY_SCREEN_TYPE
  );
  // 一度表示した画面では、初期表示のポップアップを表示しないため、表示済みの画面を保持しておく
  const [visited, setVisited] = useState<Set<ScreenType>>(new Set());

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setVisited(visited.add(screenType));
  }, [visited, screenType]);

  function transitionTo(screenType: ScreenType) {
    setScreenType(screenType);
    window.scrollTo(0, 0);
  }

  const transition = SUMMARY_TRANSITION_CONFIGS[screenType];
  const getPrev = () => {
    // 「総合評価」から「戻る」ボタンを押下した場合、レポートトップへ遷移する
    if (screenType === "comprehensive_evaluation")
      return () => navigate(pathname.replace("/summary", ""));
    if (transition.prev != null)
      return () => transition.prev && transitionTo(transition.prev(props));

    return null;
  };

  return (
    <Stack sx={{ height: "100vh" }}>
      <CssBaseline />
      <Stack sx={{ backgroundColor: "common.white" }}>
        <SummaryHeader prev={getPrev()}>
          {transition.title(props)}
        </SummaryHeader>
        <Stack sx={{ p: "16px" }}>
          <LinearProgress variant="determinate" value={transition.progress} />
        </Stack>
      </Stack>
      <Stack sx={{ height: "100%" }}>
        <SummaryPageContext.Provider
          value={{
            props,
            setScreenType: transitionTo,
            isFirstVisit:
              props.userType === "examinee" && !visited.has(screenType),
            get hasDiseaseRiskOption() {
              return hasDiseaseRiskOption(props.existence);
            },
          }}
        >
          {transition.component}
        </SummaryPageContext.Provider>
      </Stack>
    </Stack>
  );
}

/**
 * ヘッダー
 * @param param0
 * @returns
 */
function SummaryHeader({
  children,
  prev,
}: {
  children: string;
  prev: (() => void) | null;
}) {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        p: "13.5px 12px",
      }}
    >
      <Stack sx={{ width: "90px" }}>
        {prev && (
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() => prev()}
            sx={{ whiteSpace: "nowrap" }}
          >
            戻る
          </Button>
        )}
      </Stack>
      <Stack>
        <Typography noWrap variant="subtitle1">
          {children}
        </Typography>
      </Stack>
      <Stack sx={{ width: "90px" }}></Stack>
    </Stack>
  );
}
