export class Colors {
  static readonly turquoiseBlue = {
    light: "#33BCCD",
    main: "#00ACC1",
    dark: "#0096A7",
    contrastText: "#FFF",
  };

  static readonly scBlue = {
    light: "#4D89BD",
    main: "#0056A0",
    dark: "#003C70",
    contrastText: "#FFFFFF",
  };

  static readonly scRed = {
    light: "#FF4E45",
    main: "#FF3A30",
    dark: "#FF2419",
  };

  static readonly grey = {
    50: "#DDD8D3",
    100: "#C7C2BE",
    200: "#B1ADA9",
    300: "#9B9794",
    400: "#85827F",
    500: "#6F6C6A",
    600: "#585654",
    700: "#42413F",
    800: "#2C2B2A",
    900: "#161615",
  };

  static readonly black = {
    default: "#282828",
    100: "#000000",
    52: "#7A7A7A",
    36: "#A3A3A3",
    12: "#E0E0E0",
    8: "#EAEAEA",
    1: "#FAFAFA",
    0: "#FFFFFF",
  };

  static readonly success = {
    light: "#4DC19F",
    main: "#01A676",
    dark: "#017453",
    contrastText: "#FFF",
  };

  static readonly error = {
    light: "#FF756E",
    main: "#FF3A30",
    dark: "#B32922",
    contrastText: "#FFF",
  };

  static readonly warning = {
    light: "#FFD863",
    main: "#FFC720",
    dark: "#B38B16",
    contrastText: "#FFF",
  };

  static readonly info = {
    light: "#03A9F4",
    main: "#0288D1",
    dark: "#01579B",
    contrastText: "#FFF",
  };

  static readonly text = {
    primary: "#3D3D3D",
    secondary: "#707070",
    disabled: "#A3A3A3",
  };

  static readonly background = {
    default: "#F6F5F4",
    blankSpace: "#EBF1F7",
  };

  static readonly whiteButton = {
    main: "#FFF",
    contrastText: "#3D3D3D",
  };

  static readonly blackButton = {
    main: "#616161",
    contrastText: "#FFF",
  };
}

export const palette = {
  palette: {
    primary: Colors.scBlue,
    secondary: Colors.turquoiseBlue,
    grey: Colors.grey,
    black: Colors.black,
    error: Colors.error,
    warning: Colors.warning,
    info: Colors.info,
    success: Colors.success,
    text: Colors.text,
    background: Colors.background,
    whiteButton: Colors.whiteButton,
    blackButton: Colors.blackButton,
  },
};
