import "@/App.css";
import RouterConfig from "@/router/RouterConfig";
import { useEffect } from "react";
import ReactGA from "react-ga4";

function App() {
  useEffect(() => {
    const id = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
    if (id == null || id === undefined) {
      return;
    }

    ReactGA.initialize(id);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <>
      <RouterConfig />
    </>
  );
}

export default App;
