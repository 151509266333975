import { LineBreakText } from "@/components/common/LineBreakText";
import { NumberBox } from "@/components/elearning/quiz/NumberBox";
import { QuestionFormInfo } from "@/components/elearning/quiz/QuizFormInfo";
import { QuizRadioButtons } from "@/components/elearning/quiz/QuizRadioButtons";
import { isRightAnswer } from "@/components/elearning/quiz/QuizService";
import { RightWrongImage } from "@/components/elearning/quiz/RightWrongImage";
import { createRadioAnswer } from "@/components/examinee/interview/AnswerService";
import { Choice, QuestionAnswer } from "@/components/examinee/interview/Types";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

export type AnswerChangeHandler = (questionAnswer: QuestionAnswer) => void;

type QuizFormProps = {
  sx?: SxProps<Theme>;
  info: QuestionFormInfo;
  answer: QuestionAnswer;
  onChange: AnswerChangeHandler;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
};
export function QuizForm({
  sx,
  info,
  answer,
  onChange,
  onSubmit,
}: QuizFormProps) {
  const BUTTON_HEIGHT = 42;

  const onRadioChange = (choice: Choice) => {
    const ans = createRadioAnswer(info.question, choice);
    onChange(ans);
  };

  const explanation = info.getExplanation();

  return (
    <Paper
      component="form"
      onSubmit={onSubmit}
      sx={{ borderRadius: "16px", ...sx }}
    >
      <Stack sx={{ m: 6 }} spacing={6}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <NumberBox number={Number(info.getQuestionNumber())} />
            <Typography variant="subtitle1" color="primary.main">
              {info.getQuestionText()}
            </Typography>
          </Stack>
          <QuizRadioButtons
            choices={info.question.choices}
            answer={answer}
            disabled={info.mode === "Explanation"}
            onChange={onRadioChange}
          />
        </Stack>
        {explanation && answer && (
          <Stack spacing={6} sx={{ pb: 6 }}>
            <Divider />
            <Stack alignItems="center">
              <RightWrongImage
                isRight={isRightAnswer(answer.answers[0].answer)}
              />
            </Stack>
            <Typography>
              <LineBreakText text={explanation} />
            </Typography>
            <Typography variant="caption" color="#707070">
              監修：国家公務員共済組合連合会 虎の門病院 睡眠呼吸器科 富田康弘
            </Typography>
          </Stack>
        )}
        <Button
          sx={{ height: BUTTON_HEIGHT, borderRadius: BUTTON_HEIGHT / 2 }}
          type="submit"
          variant="contained"
          disabled={answer.answers.length === 0}
        >
          {info.getButtonTitle()}
        </Button>
      </Stack>
    </Paper>
  );
}
