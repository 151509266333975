import { ScreenLockProvider } from "@/components/ScreenLock";
import Worksheet from "@/components/worksheet";
import { facilityTheme } from "@/theme/themeV1";

import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ja } from "date-fns/locale";
import { Route, Routes } from "react-router-dom";

/**
 * 睡眠改善ワークシート用ルーター
 */
export default function WorksheetRouter() {
  return (
    <ThemeProvider theme={facilityTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <ScreenLockProvider>
          <Routes>
            <Route path="/:uuid" element={<Worksheet />} />
            <Route path="/:uuid/:tab" element={<Worksheet />} />
          </Routes>
        </ScreenLockProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
