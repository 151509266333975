import { AuthenticatedRegistrationEntry } from "@/sleep_compass_lite/data_transfer_objects/target/RegistrationEntry";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { EmailAddress } from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import { EmailVerificationCode } from "@/sleep_compass_lite/domain_models/target/EmailVerificationCode";
import { Password } from "@/sleep_compass_lite/domain_models/target/Password";
import {
  CognitoAuthenticationResultStore,
  CognitoRefreshTokenStore,
  SleepCheckupUserStore,
  callUserTypeApi,
} from "@/utils/auth";
import { getApiServerUrl, getAxios } from "@/utils/axios";
import axios, { AxiosError, AxiosResponse } from "axios";

/**
 * 初回登録
 * 対象者確認APIを呼び出すことで、メールアドレスの検証、ユーザーアカウントの作成、ログインなどを行う。また、ログインに伴い、認証情報の格納を行う。
 * @param surveyInfo サーベイ情報のUUID
 * @param email 対象者のメールアドレス
 * @param password 対象者のパスワード
 * @param registrationEntryId 登録エントリーのUUID
 * @param emailVerificationCode メールアドレスの検証コード
 * @returns 対象者確認APIのレスポンス(データ)
 */
export async function registration(
  surveyInfo: string,
  email: EmailAddress,
  password: Password,
  registrationEntryId: string,
  emailVerificationCode: EmailVerificationCode
): Promise<AuthenticatedRegistrationEntry> {
  try {
    const response = await putRegistrationEntry(
      surveyInfo,
      email,
      password,
      registrationEntryId,
      emailVerificationCode
    );

    CognitoAuthenticationResultStore.setItem(
      response.data.AuthenticationResult
    );
    CognitoRefreshTokenStore.setItem({
      RefreshToken: response.data.AuthenticationResult.RefreshToken,
    });

    const userTypeResponse = await callUserTypeApi(
      getAxios(getApiServerUrl()),
      response.data.AuthenticationResult.IdToken
    );

    SleepCheckupUserStore.setItem(userTypeResponse.data);

    return response.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiError(e.response);
    } else {
      throw e;
    }
  }
}

/**
 * 対象者確認APIの呼び出し
 * @param surveyInfo サーベイ情報のUUID
 * @param email 対象者のメールアドレス
 * @param password 対象者のパスワード
 * @param registrationEntryId 登録エントリーのUUID
 * @param emailVerificationCode メールアドレスの検証コード
 * @returns 対象者確認APIのレスポンス
 */
function putRegistrationEntry(
  surveyInfo: string,
  email: EmailAddress,
  password: Password,
  registrationEntryId: string,
  emailVerificationCode: EmailVerificationCode
): Promise<AxiosResponse<AuthenticatedRegistrationEntry>> {
  return axios.put(
    `${getApiServerUrl()}/api/lite/registration_entry/${registrationEntryId}/`,
    {
      survey_info: surveyInfo,
      email: email.value,
      password: password.value,
      cognito_verification_code: emailVerificationCode.value,
    }
  );
}
