import ACCELStarsForgotPassword from "@/pages/accelstars/ForgotPassword";
import ACCELStarsSignIn from "@/pages/accelstars/SignIn";
import DiseaseRiskPage from "@/pages/common/report/DiseaseRiskPage";
import ElearningLayout from "@/pages/elearning/ElearningLayout";
import ExamineeLayoutV1 from "@/pages/examinee/ExamineeLayoutV1";
import { DeviceIdScan } from "@/pages/examinee/activation/DeviceIdScan";
import ConnectivityCheck from "@/pages/facility/ConnectivityCheck";
import FacilityLayout from "@/pages/facility/FacilityLayout";
import FacilityForgotPassword from "@/pages/facility/ForgotPassword";
import FacilitySignIn from "@/pages/facility/SignIn";
import FacilityReportLayout from "@/pages/facility/report/ReportRouter";
import FacilityUserUpdate from "@/pages/facility/users/Update";
import WorksheetRouter from "@/pages/facility/worksheet/WorksheetRouter";
import { LITE_TARGET_PATH } from "@/sleep_compass_lite/constants";
import TargetLayout from "@/sleep_compass_lite/pages/target/TargetLayout";
import { BrowserRouter, Route, Routes } from "react-router-dom";

export default function RouterConfig() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/accelstars/signin" element={<ACCELStarsSignIn />} />
        <Route
          path="/accelstars/forgot_password"
          element={<ACCELStarsForgotPassword />}
        />
        <Route path="/facility/signin" element={<FacilitySignIn />} />
        <Route path="/facility/connectivity" element={<ConnectivityCheck />} />
        <Route
          path="/facility/forgot_password"
          element={<FacilityForgotPassword />}
        />
        <Route
          path="/facility/report/:uuid/disease_risk"
          element={<DiseaseRiskPage />}
        />
        <Route path="/facility/report/*" element={<FacilityReportLayout />} />
        <Route path="/facility/worksheet/*" element={<WorksheetRouter />} />
        <Route path="/facility/*" element={<FacilityLayout />}></Route>
        <Route path="/facility/users/update" element={<FacilityUserUpdate />} />
        <Route path="/examinee/device_id_scan" element={<DeviceIdScan />} />
        <Route
          path="/examinee/report/:uuid/disease_risk"
          element={<DiseaseRiskPage />}
        />
        <Route path="/examinee/*" element={<ExamineeLayoutV1 />} />
        <Route path="/elearning/*" element={<ElearningLayout />} />
        <Route path={`${LITE_TARGET_PATH}/*`} element={<TargetLayout />} />
      </Routes>
    </BrowserRouter>
  );
}
