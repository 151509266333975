import { ReportTop } from "@/components/report/ReportTop";
import { ReportPageBaseProps, TopResponse } from "@/components/report/types";
import { useAxios } from "@/utils/axios";
import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type ReportPageProps = ReportPageBaseProps & {
  indicator: boolean;
};

export function ReportPage({ indicator, setIndicator }: ReportPageProps) {
  const { uuid } = useParams();
  const axios = useAxios();
  const [isPdf, setPdf] = useState(false);
  const [data, setData] = useState<TopResponse>();

  useEffect(() => {
    (async () => {
      const response = await axios.get(`/api/report/${uuid}/`, {
        responseType: "arraybuffer",
      });
      const type = response.headers["content-type"];
      setPdf(type === "application/pdf");
      if (type === "application/pdf") {
        window.location.href = URL.createObjectURL(
          new Blob([response.data], { type })
        );
        setIndicator(false);
      } else if (type === "application/json") {
        setData(JSON.parse(new TextDecoder().decode(response.data)));
        setIndicator(false);
      }
    })();
  }, [axios, uuid, setIndicator]);

  if (uuid == null) return <></>;
  return (
    <>
      {!indicator && isPdf && (
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            height: "calc(100vh - 207px)",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1">
            レポートのダウンロードが完了しました。
            <br />
            ダウンロードフォルダをご確認ください。
          </Typography>
        </Stack>
      )}
      {!indicator && !isPdf && data != null && (
        <ReportTop data={data} uuid={uuid} />
      )}
    </>
  );
}
