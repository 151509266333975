import ForgotPassword from "@/components/auth/ForgotPassword";
import { getApiServerUrl, getAxios } from "@/utils/axios";
import constant from "@/utils/constants";
import SystemTheme from "@/utils/theme";
import { ThemeProvider } from "@mui/material";

function ACCELStarsForgotPassword() {
  return (
    <ThemeProvider theme={SystemTheme.getACCELStars()}>
      <ForgotPassword
        axios={getAxios(getApiServerUrl())}
        signInTo={constant.SIGNIN_URL_ACCELSTARS_USER}
      />
      ;
    </ThemeProvider>
  );
}

export default ACCELStarsForgotPassword;
