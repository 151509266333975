import { TableContainer, TableContainerProps } from "@mui/material";

/**
 * {@link ReportTableContainer} のデフォルトのスタイル
 */
export const DEFAULT_STYLE = {
  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: 0,
};

/**
 * {@link ReportTableContainer} のプロパティ
 */
export type ReportTableContainerProps = TableContainerProps;

/**
 * lite のレポート用の {@link TableContainer}
 * @see TableContainer
 */
export function ReportTableContainer(props: ReportTableContainerProps) {
  return <TableContainer sx={DEFAULT_STYLE} {...props} />;
}
