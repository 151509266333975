import { Box, Stack, Typography } from "@mui/material";

/**
 * 期限切れの画面
 *
 * @returns 期限切れコンポーネント
 */
export default function Expired() {
  return (
    <Stack>
      <Stack sx={{ alignItems: "center", gap: 6, mt: 8, mx: 4 }}>
        <Box sx={{ px: "41.5px" }}>
          <img
            src="/img/image_lite_greetings_expired.png"
            alt="url expired url"
            width="100%"
            height="auto"
            style={{ maxWidth: "390px" }}
          />
        </Box>
        <Typography
          sx={{
            color: "text.primary",
            fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
            fontFamily: "Noto Sans JP",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "170%",
            letterSpacing: "0.15px",
          }}
        >
          URLが期限切れです
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "text.secondary",
            textAlign: "center",
            fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
          }}
        >
          既に登録済みか調査期間が終了しています<br></br>
          管理者にお問い合わせください
        </Typography>
      </Stack>
    </Stack>
  );
}
