import { ScrollToTop } from "@/components/common/ScrollToTop";
import { DailyInterviewPageSummary } from "@/components/examinee/interview/PageSummary";
import {
  InterviewAnswer,
  InterviewEntry,
} from "@/components/examinee/interview/Types";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import {
  GetNextPageHandler,
  GetNextScreenHandler,
  Interview,
  PostAnswerHandler,
} from "@/sleep_compass_lite/components/target/Interview";
import { TargetAuthenticated as Authenticated } from "@/sleep_compass_lite/components/target/TargetAuthenticated";
import { interviewPath } from "@/sleep_compass_lite/domain_models/target/InterviewPath";
import { useDailyInterview } from "@/sleep_compass_lite/use_cases/target/interview/UseDailyInterview";
import { useParams } from "react-router-dom";

/**
 * 毎日問診ページ
 */
export function DailyInterview({ headerHeight }: ExamineePageProps) {
  const { surveyInfoUid, announceId, interviewId, screen } = useParams();
  const { postAnswer: postInterviewAnswer } = useDailyInterview();

  const postAnswer: PostAnswerHandler = async (
    interviewAnswer: InterviewAnswer
  ) => {
    if (surveyInfoUid == null || announceId == null) {
      throw new Error(
        `invalid param, surveyInfoUid: ${surveyInfoUid}, announceId: ${announceId}`
      );
    }

    return postInterviewAnswer(
      surveyInfoUid,
      parseInt(announceId),
      interviewAnswer
    );
  };

  const getNextScreen: GetNextScreenHandler = (entry: InterviewEntry) => {
    if (surveyInfoUid == null || announceId == null) {
      throw new Error(
        `invalid param, surveyInfoUid: ${surveyInfoUid}, announceId: ${announceId}`
      );
    }

    return Promise.resolve(
      interviewPath.getDailyInterviewPath(
        surveyInfoUid,
        parseInt(announceId),
        entry
      )
    );
  };

  const getNextPage: GetNextPageHandler = () => {
    if (surveyInfoUid == null || announceId == null) {
      throw new Error(
        `invalid param, surveyInfoUid: ${surveyInfoUid}, announceId: ${announceId}`
      );
    }

    return Promise.resolve(
      interviewPath.getFullPath("DailyInterviewCompleted", {
        ":surveyInfoUid": surveyInfoUid,
        ":announceId": announceId,
      })
    );
  };

  if (
    surveyInfoUid == null ||
    announceId == null ||
    interviewId == null ||
    screen == null
  ) {
    throw new Error(
      `invalid params, ${surveyInfoUid}, ${announceId}, ${interviewId}, ${screen}`
    );
  }

  return (
    <Authenticated>
      <ScrollToTop />
      <Interview
        pageProps={{ headerHeight: headerHeight }}
        context={{
          surveyInfoUid: surveyInfoUid,
          interviewId: interviewId,
          screenId: screen,
          pageSummaryCreator: (questionScreen) =>
            new DailyInterviewPageSummary(questionScreen),
          postAnswer: postAnswer,
          getNextScreen: getNextScreen,
          getNextPage: getNextPage,
        }}
      />
    </Authenticated>
  );
}
