import {
  InterviewAnswer,
  InterviewEntry,
  Screen,
} from "@/components/examinee/interview/Types";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { useTargetAxios as useCommonAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";

function useAxios() {
  const axios = useCommonAxios();
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error instanceof AxiosError) {
        return Promise.reject(new ApiError(error.response));
      } else {
        return Promise.reject(error);
      }
    }
  );
  return axios;
}

/**
 * {@link InterviewEntry 意識調査情報} を取得、登録するAPIを提供します。
 *
 * @returns アンケートAPI
 */
export function useEnquetes() {
  const axios = useAxios();

  const getURL = useCallback(
    (surveyInfoUid: string) => `/api/lite/${surveyInfoUid}/enquete/`,
    []
  );
  const api = useMemo(() => {
    return {
      /**
       * 意識調査取得メソッド
       *
       * @param surveyInfoUid サーベイ情報ID
       * @returns  InterviewEntry
       */
      async get(surveyInfoUid: string): Promise<InterviewEntry> {
        const response: AxiosResponse<InterviewEntry> = await axios.get(
          getURL(surveyInfoUid)
        );
        return response.data;
      },
      /**
       * 意識調査登録メソッド
       * @param surveyInfoUid サーベイ情報ID
       * @param answer {@link InterviewAnswer 意識調査結果}
       * @returns 次のインタビューオブジェクト
       */
      async post(
        surveyInfoUid: string,
        answer: InterviewAnswer
      ): Promise<InterviewEntry> {
        const response: AxiosResponse<InterviewEntry> = await axios.post(
          getURL(surveyInfoUid),
          answer
        );
        return response.data;
      },
    };
  }, [axios, getURL]);
  return api;
}

/**
 * 意識調査の {@link Screen} を取得するAPIを提供します。
 *
 * @returns Screen API
 */
export function useScreens() {
  const axios = useAxios();
  const api = useMemo(
    () => ({
      /**
       * {@link Screen} を取得します。
       *
       * @param path 取得対象のPath
       * @returns Screen
       */
      async get(path: string): Promise<Screen> {
        const response: AxiosResponse<Screen> = await axios.get(path);
        return response.data;
      },
    }),
    [axios]
  );
  return api;
}
