import { NonFieldError } from "@/components/common/SCAlert";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { UAParser } from "ua-parser-js";

export const Greetings = () => {
  const parser = new UAParser(window.navigator.userAgent);
  const parserResults = parser.getResult();
  const isSupportBrowser = ["Chrome", "Safari"].some((b) =>
    parserResults.browser.name?.includes(b)
  );

  return (
    <>
      {!isSupportBrowser && (
        <Stack spacing={8} sx={{ mx: 4, mt: 4, mb: 0 }} alignItems="center">
          <NonFieldError>
            お使いのブラウザは推奨ブラウザではありません。Google
            ChromeもしくはSafariを開いて、URL「slcp.jp」を入力してアクセスしなおしてください。
          </NonFieldError>
        </Stack>
      )}
      <Stack alignItems="center" sx={{ width: "100%" }}>
        <img
          src="/img/image_greetings_01.png"
          alt="睡眠健康測定"
          loading="lazy"
          style={{ width: 343, height: "auto" }}
        />
      </Stack>
      <Typography variant="h6" align="center" sx={{ mb: 4 }}>
        こんにちは
      </Typography>
      <Stack sx={{ mb: 6 }} alignItems="center">
        <Typography variant="body2">デバイスによる8日間の睡眠測定と</Typography>
        <Typography variant="body2">
          Web問診により、あなたの睡眠を可視化する
        </Typography>
        <Typography variant="body2">睡眠健康測定サービスです</Typography>
        {isSupportBrowser && (
          <Stack width="100%" sx={{ mb: 20 }}>
            <Stack width="100%">
              <Button
                component={RouterLink}
                to="/examinee/terms_of_service_agreement"
                variant="contained"
                size="large"
                sx={{ mx: 4, mt: 6, mb: 4 }}
                state="/examinee/signup"
              >
                睡眠健康測定をはじめる
              </Button>
            </Stack>

            <Stack width="100%" sx={{ mb: 10, gap: 2 }}>
              <Button
                color="whiteButton"
                component={RouterLink}
                to="/examinee/terms_of_service_agreement"
                variant="contained"
                size="large"
                sx={{ mx: 4, color: "primary.main" }}
              >
                ログイン
              </Button>
              <Typography
                variant="caption"
                sx={{
                  mx: 4,
                  color: "text.secondary",
                  textAlign: "center",
                  fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
                }}
              >
                ※既にアカウント（SLEEP COMPASS Lightを含む）を<br></br>
                お持ちの方は「ログイン」を押してください。
              </Typography>
            </Stack>

            <Box
              sx={{ mx: 4, background: "rgba(0, 86, 160, 0.04)" }}
              borderRadius={2}
            >
              <Stack sx={{ gap: 2, m: 4 }}>
                <Typography
                  variant="subtitle2"
                  textAlign="center"
                  color="primary"
                >
                  サービスの位置付け
                </Typography>
                <Typography
                  variant="caption"
                  textAlign="justify"
                  letterSpacing="0.4px"
                  color="text.primary"
                  sx={{
                    fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off;",
                  }}
                >
                  本サービスは、診断ガイドラインに基づき問診回答により睡眠時無呼吸症候群・不眠症の疾患リスクをレポートにて表示する場合があります。
                  医療機器ではないため、提供される情報や結果は、病気の診断や治療を意図するものではなく、参考情報となります。
                  正しく問診回答が行われなかった場合、正しい結果が出ない場合があります。
                </Typography>
              </Stack>
            </Box>
          </Stack>
        )}
      </Stack>
    </>
  );
};
