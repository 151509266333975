class Messages {
  readonly MSG_0001 =
    "エラーが発生しました。\nしばらく経ってから、もう一度、アクセスしてください。";
}

export const ErrorMessages = new Messages();

export function getApiErrorMessage(err: Error): string {
  return `エラーが発生しました。\nしばらく経ってから、もう一度、アクセスしてください。\n(${err.message})`;
}
