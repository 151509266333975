type ByteUnitName = "B" | "KiB" | "MiB" | "GiB" | "TiB" | "PiB";
const CONFIG: ReadonlyMap<ByteUnitName, number> = new Map([
  ["B", 1],
  ["KiB", 2 ** 10],
  ["MiB", 2 ** 20],
  ["GiB", 2 ** 30],
  ["TiB", 2 ** 40],
  ["PiB", 2 ** 50],
]);

/**
 * 情報の単位を表し、各単位への変換を実行するユーティリティメソッドを提供します。
 * このクラスは情報を保持せず、stateless なメソッドのみで構成されます。
 * <code>
 * ByteUnit.BYTE.convert(10, "KiB");  // 10KiB を byteに変換
 * ByteUnit.BYTE.toKibibyte(10);  // 10 byteを KiBに変換します。
 * </code>
 */
export default class ByteUnit {
  private constructor(readonly name: ByteUnitName) {}

  /**
   * 指定単位から変換を行います。
   *
   * @param value 変換対象値
   * @param from 変換対象単位
   * @returns 変換後数値
   */
  convert(value: number, from: ByteUnitName | ByteUnit) {
    const fromName = typeof from === "string" ? from : from.name;
    const config = Object.fromEntries(CONFIG);
    return value * (config[fromName] / config[this.name]);
  }

  /**
   * 指定単位へ変換を行います。
   *
   * @param to 変換対象単位
   * @param value 変換値
   * @returns 変換後値
   */
  to(to: ByteUnitName, value: number) {
    return ByteUnit.valueOf(to).convert(value, this);
  }

  /**
   * {@link  https://ja.wikipedia.org/wiki/%E3%83%90%E3%82%A4%E3%83%88_(%E6%83%85%E5%A0%B1)  byte} へ変換します。
   *
   * @param value 変換対象値
   * @returns 変換後値
   */
  toByte(value: number) {
    return this.to("B", value);
  }
  /**
   * {@link https://ja.wikipedia.org/wiki/%E3%82%AD%E3%83%93%E3%83%90%E3%82%A4%E3%83%88 KiB} へ変換します。
   *
   * @param value 変換対象値
   * @returns 変換後値
   */
  toKibibyte(value: number) {
    return this.to("KiB", value);
  }
  /**
   * {@link https://ja.wikipedia.org/wiki/%E3%83%A1%E3%83%93%E3%83%90%E3%82%A4%E3%83%88 MiB} へ変換します。
   *
   * @param value 変換対象値
   * @returns 変換後値
   */
  toMebibyte(value: number) {
    return this.to("MiB", value);
  }
  /**
   * {@link https://ja.wikipedia.org/wiki/%E3%82%AE%E3%83%93%E3%83%90%E3%82%A4%E3%83%88 GiB} へ変換します。
   *
   * @param value 変換対象値
   * @returns 変換後値
   */
  toGibibyte(value: number) {
    return this.to("GiB", value);
  }
  /**
   * {@link https://ja.wikipedia.org/wiki/%E3%83%86%E3%83%93%E3%83%90%E3%82%A4%E3%83%88 TiB} へ変換します。
   *
   * @param value 変換対象値
   * @returns 変換後値
   */
  toTebibyte(value: number) {
    return this.to("TiB", value);
  }
  /**
   * {@link https://ja.wikipedia.org/wiki/%E3%83%9A%E3%83%93%E3%83%90%E3%82%A4%E3%83%88 PiB} へ変換します。
   *
   * @param value 変換対象値
   * @returns 変換後値
   */
  toPebibyte(value: number) {
    return this.to("PiB", value);
  }

  /**
   * 指定単位名のインスタンスを返却します。
   *
   * @param name 単位
   * @returns ByteUnit
   */
  static valueOf(name: ByteUnitName) {
    return new ByteUnit(name);
  }
  /**
   * 全ての単位を取得します。
   *
   * @returns ByteUnit[]
   */
  static units() {
    return [...CONFIG.keys()].map((name) => ByteUnit.valueOf(name));
  }
  /**
   * 全ての単位名を取得します。
   * @returns 全ての単位名
   */
  static names() {
    return [...CONFIG.keys()];
  }

  /**
   * バイト
   * @see https://ja.wikipedia.org/wiki/%E3%83%90%E3%82%A4%E3%83%88_(%E6%83%85%E5%A0%B1)
   */
  static BYTE = new ByteUnit("B");
  /**
   * キビバイト
   * @see https://ja.wikipedia.org/wiki/%E3%82%AD%E3%83%93%E3%83%90%E3%82%A4%E3%83%88
   */
  static KIBIBYTE = new ByteUnit("KiB");
  /**
   * メビバイト
   * @see https://ja.wikipedia.org/wiki/%E3%83%A1%E3%83%93%E3%83%90%E3%82%A4%E3%83%88
   */
  static MEBIBYTE = new ByteUnit("MiB");
  /**
   * ギビバイト
   * @see https://ja.wikipedia.org/wiki/%E3%82%AE%E3%83%93%E3%83%90%E3%82%A4%E3%83%88
   */
  static GIBIBYTE = new ByteUnit("GiB");
  /**
   * テビバイト
   * @see https://ja.wikipedia.org/wiki/%E3%83%86%E3%83%93%E3%83%90%E3%82%A4%E3%83%88
   */
  static TEBIBYTE = new ByteUnit("TiB");
  /**
   * ペビバイト
   * @see https://ja.wikipedia.org/wiki/%E3%83%9A%E3%83%93%E3%83%90%E3%82%A4%E3%83%88
   */
  static PEBIBYTE = new ByteUnit("PiB");
}
