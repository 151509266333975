import { formatDisplayTime } from "@/utils/date";
import {
  ErrorOutline,
  ExpandMore,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import { HowToViewGraphsDialog } from "./commons/HowToViewGraphsDialog";
import ReportMark from "./commons/ReportMark";
import SleepDiaryChart from "./commons/SleepDiaryChart";
import {
  LifestyleAndHealthConditionType,
  LifestyleAndHealthConditionTypeForMeasurementDetails,
  MeasurementDetailsLifestyleAndHealthConditionsDetail,
  MeasurementDetailsResponse,
  MeasurementDetailsSleepData,
  UserType,
} from "./types";

/**
 * 分をh時間m分の形式にします。
 * 値 が null の場合 "-" を返却します。
 * @param minutes
 * @returns
 */
function formatTime(minutes: number | null) {
  if (minutes == null) {
    return "-";
  }
  return formatDisplayTime(minutes);
}

type TabType = "sleep_data" | "lifestyle_and_health_conditions";

/**
 * 測定データの詳細プロパティ
 */
export type MeasurementDetailsProps = {
  /** レポートUUID */
  uuid: string | undefined;

  /**
   * ユーザー種別
   * @see {@link UserType}
   */
  userType: UserType;

  /**
   * 測定データの詳細データ
   *
   * @see {@link MeasurementDetailsResponse}
   */
  data: MeasurementDetailsResponse;
};
/**
 * 測定データの詳細
 *
 * @param param0 {@link MeasurementDetailsProps}
 * @returns
 */
export function MeasurementDetails({
  uuid,
  userType,
  data,
}: MeasurementDetailsProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [tab, setTab] = useState<TabType>("sleep_data");
  const [howToViewGraphs, setHowToViewGraphs] = useState(false);
  return (
    <Stack>
      <HowToViewGraphsDialog
        open={howToViewGraphs}
        setOpen={setHowToViewGraphs}
      />
      <Stack sx={{ p: "40px 16px 24px 16px", gap: "8px" }}>
        <Breadcrumbs
          maxItems={2}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            href={`/${userType}/report/${uuid}`}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body2">レポートTOP</Typography>
          </Link>
          <Typography variant="body2">測定データの詳細</Typography>
        </Breadcrumbs>
        <Typography variant="h5" sx={{ color: "primary.main" }}>
          測定データの詳細
        </Typography>
      </Stack>
      <Stack sx={{ p: "0px 16px 40px 16px" }}>
        <Typography variant="subtitle2">
          7日間測定した結果および毎日の問診回答の詳細情報です。
        </Typography>
      </Stack>
      <Divider />
      <Stack sx={{ p: "24px 16px 24px 16px" }}>
        <Stack direction="row" justifyContent="end" sx={{ pb: "16px" }}>
          <Button
            startIcon={<ErrorOutline sx={{ transform: "rotateX(180deg)" }} />}
            onClick={() => {
              setHowToViewGraphs(true);
            }}
          >
            グラフの見方について
          </Button>
        </Stack>
        <Stack sx={{ height: "304px" }}>
          <SleepDiaryChart
            activeIndex={activeIndex}
            onChartClick={(index) => {
              setActiveIndex(index);
            }}
            data={data.measured_data.map((data) => ({
              date: data.date,
              bedtime: data.bedtime == null ? null : new Date(data.bedtime),
              ariseTime:
                data.arise_time == null ? null : new Date(data.arise_time),
              sleepData: (data.chart_data || []).map((d) => ({
                from: new Date(d.from),
                to: new Date(d.to),
                type: d.type,
              })),
            }))}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack
        sx={{
          p: "24px 16px 24px 16px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        direction="row"
      >
        <IconButton
          onClick={() => setActiveIndex(activeIndex - 1)}
          disabled={activeIndex <= 0}
        >
          <NavigateBefore />
        </IconButton>
        <Typography variant="subtitle1">{activeIndex + 1}日目</Typography>
        <IconButton
          onClick={() => setActiveIndex(activeIndex + 1)}
          disabled={data.measured_data.length - 1 <= activeIndex}
        >
          <NavigateNext />
        </IconButton>
      </Stack>
      <Stack
        sx={{
          p: "0px 16px 0px 16px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: "#f6f5f4",
            color: "text.secondary",
            borderRadius: 32,
            mb: "40px",
            "& .MuiGrid-item": { padding: 0 },
          }}
        >
          <Grid item xs>
            <TabButton tabType="sleep_data" currentTab={tab} setTab={setTab}>
              睡眠データ
            </TabButton>
          </Grid>
          <Grid item xs>
            <TabButton
              tabType="lifestyle_and_health_conditions"
              currentTab={tab}
              setTab={setTab}
            >
              生活習慣と健康状態
            </TabButton>
          </Grid>
        </Grid>
      </Stack>
      <Stack>
        {data.measured_data.map((d, i) => (
          <TabData key={i} data={d} tab={tab} isDisplay={i === activeIndex} />
        ))}
      </Stack>
    </Stack>
  );
}

/**
 * 睡眠データのプロパティ
 */
type SleepDataProps = {
  /**
   * 睡眠データのデータ {@link MeasurementDetailsSleepData}
   */
  data: MeasurementDetailsSleepData;
};
/**
 * 睡眠のデータ
 * @param props {@link SleepDataProps}
 * @returns
 */
function SleepData(props: SleepDataProps) {
  return (
    <Stack>
      <Stack>
        <Typography
          variant="subtitle2"
          sx={{ color: "text.secondary", paddingBottom: "8px" }}
        >
          睡眠に関する記録
        </Typography>
        <TableContainer
          sx={{
            borderTopWidth: "1px",
            borderTopColor: "rgba(0, 0, 0, 0.12)",
            borderTopStyle: "solid",
            borderRadius: "0px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>項目</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  あなたの結果
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>睡眠時間</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {formatTime(props.data.sleep_records.sleeping_time)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>睡眠の効率</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {props.data.sleep_records.sleep_efficiency == null
                    ? "-"
                    : `${props.data.sleep_records.sleep_efficiency}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>消灯した時刻</TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    textAlign: "right",
                  }}
                >
                  {props.data.sleep_records.in_bed ?? "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>寝付くまでの時間</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {formatTime(props.data.sleep_records.to_fall_asleep)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>眠りについた時刻</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {props.data.sleep_records.fell_asleep ?? "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>
                  睡眠中に覚醒していた時間
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {formatTime(props.data.sleep_records.awakening_time)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>目が覚めた時刻</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {props.data.sleep_records.awake ?? "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>
                  目が覚めてから起床までの時間
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {formatTime(props.data.sleep_records.awake_to_arise_time)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>起床した時刻</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {props.data.sleep_records.arise_time ?? "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Stack sx={{ paddingTop: "40px" }}>
        <Typography
          variant="subtitle2"
          sx={{ color: "text.secondary", paddingBottom: "8px" }}
        >
          あなた自身による記録
        </Typography>
        <TableContainer
          sx={{
            borderTopWidth: "1px",
            borderTopColor: "rgba(0, 0, 0, 0.12)",
            borderTopStyle: "solid",
            borderRadius: "0px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>項目</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  あなたの結果
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>
                  すっきり起きられたか
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {props.data.your_own_records.ease_of_wake_up}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>目覚めた時の気分</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {props.data.your_own_records.wake_up_feeing}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>
                  目覚めた時の疲れの取れ具合
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {props.data.your_own_records.fatigue_recovery}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>
                  今日の睡眠に満足したか
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {props.data.your_own_records.satisfied_sleep_today}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>日中の眠気</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {props.data.your_own_records.daytime_sleepiness}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>日中の疲労</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {props.data.your_own_records.daytime_fatigue}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ maxWidth: "50%" }}>仕事の出来</TableCell>
                <TableCell
                  sx={{
                    maxWidth: "50%",
                    textAlign: "right",
                  }}
                >
                  {props.data.your_own_records.work_performance}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
}

/**
 * 生活習慣と健康状態のタイトル
 */
const TITLES: Readonly<
  Record<LifestyleAndHealthConditionTypeForMeasurementDetails, string>
> = {
  body_condition: "体の調子",
  mind_condition: "こころの調子",
  before_sleep_mind: "寝る前の気分",
  light: "部屋の明かり",
  bluelight: "ブルーライト",
  exercise: "運動",
  bath: "入浴",
  nap: "仮眠",
  nightmeal: "夜食",
  caffeine: "カフェイン",
  alcohol: "アルコール",
};

/**
 * 生活習慣と健康状態のアコーディオンのプロパティ
 */
type LifestyleAndHealthConditionAccordionProps = {
  /**
   * アコーディオンのタイトル
   */
  title: string;
  /**
   * アコーディオンの内容
   * @see {@link MeasurementDetailsLifestyleAndHealthConditionsDetail}
   */
  content: MeasurementDetailsLifestyleAndHealthConditionsDetail;
  /**
   * {@link LifestyleAndHealthConditionType}
   */
  type: LifestyleAndHealthConditionType;
};
function LifestyleAndHealthConditionAccordion(
  props: LifestyleAndHealthConditionAccordionProps
) {
  const [open, setOpen] = useState(false);
  return (
    <Stack
      sx={{
        borderRadius: "8px",
        boxShadow: "0px 0px 0px 1px #E0E0E0",
        overflow: "hidden",
      }}
    >
      <Accordion
        expanded={open}
        onChange={() => {
          setOpen((open) => !open);
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Stack direction="row" sx={{ gap: 2, alignItems: "center" }}>
            <Typography>{props.title}</Typography>
            {props.content.is_warning && (
              <ReportMark style={{ verticalAlign: "middle" }} />
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {props.content.data.map((d, i) => (
            <Stack key={i}>
              <Stack
                sx={{ p: "12px 16px", backgroundColor: "background.default" }}
              >
                <Typography variant="body2">
                  Q{props.content.data.length > 1 ? i + 1 : ""}. {d.question}
                </Typography>
              </Stack>
              <Stack sx={{ p: "12px 16px", textAlign: "right" }}>
                {d.answers.map((a, i) => (
                  <React.Fragment key={i}>
                    <Typography variant="body2" key={i}>
                      {a}
                    </Typography>
                    {props.type === "mind_condition" && (
                      <Typography variant="caption">
                        ※本項目は事前問診の集計結果から判定しているため、毎日の結果に変動はありません。
                      </Typography>
                    )}
                  </React.Fragment>
                ))}
              </Stack>
            </Stack>
          ))}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}

/**
 * 生活習慣と健康状態のプロパティ
 */
type LifestyleAndHealthConditionProps = {
  /**
   * 生活習慣と健康状態のデータ
   */
  data: Record<
    LifestyleAndHealthConditionTypeForMeasurementDetails,
    MeasurementDetailsLifestyleAndHealthConditionsDetail
  >;

  /**
   * 画面に表示させるかどうか(表示させる場合、true)
   */
  isDisplay: boolean;
};
const LIFESTYLE_AND_HEALTH_CONDITION_ORDER: LifestyleAndHealthConditionTypeForMeasurementDetails[] =
  [
    "body_condition",
    "mind_condition",
    "before_sleep_mind",
    "light",
    "bluelight",
    "exercise",
    "bath",
    "nap",
    "nightmeal",
    "caffeine",
    "alcohol",
  ];
/**
 * 生活習慣と健康状態

 * @param props {@link LifestyleAndHealthConditionProps}
 * @returns
 */
function LifestyleAndHealthCondition(props: LifestyleAndHealthConditionProps) {
  return (
    <Stack sx={{ gap: 4, display: `${props.isDisplay ? "" : "none"}` }}>
      <Stack
        direction="row"
        sx={{ justifyContent: "flex-end", alignItems: "center", gap: 2 }}
      >
        <ReportMark />
        <Typography sx={{ color: "text.secondary" }}>
          課題が見られた項目
        </Typography>
      </Stack>
      {LIFESTYLE_AND_HEALTH_CONDITION_ORDER.map((k, i) => (
        <LifestyleAndHealthConditionAccordion
          key={i}
          type={k}
          title={TITLES[k]}
          content={props.data[k]}
        />
      ))}
    </Stack>
  );
}

/**
 * 睡眠データ／生活習慣と健康状態を切り替えるボタンのプロパティ
 */
type TabButtonProps = {
  /** 子ノード */
  children: ReactNode;
  /**
   * このボタンの{@link TabType}
   *
   * @see TabType
   */
  tabType: TabType;
  /**
   * 現在表示している{@link TabType}
   * @see TabType
   */
  currentTab: TabType;
  /**
   * ボタンが押下された場合の処理
   *
   * @param tab
   * @returns
   */
  setTab: (tab: TabType) => void;
};
/**
 * 睡眠データ／生活習慣と健康状態を切り替えるボタン
 *
 * @param props {@link TabButtonProps}
 * @returns
 */
function TabButton(props: TabButtonProps) {
  const isActive = props.currentTab === props.tabType;
  return (
    <Button
      sx={{
        borderRadius: 32,
        width: "100%",
        color: `${isActive ? "" : "text.secondary"}`,
        fontSize: "14px",
      }}
      variant={isActive ? "contained" : "text"}
      onClick={() => props.setTab(props.tabType)}
    >
      {props.children}
    </Button>
  );
}

type TabDataProps = {
  data: {
    /** 睡眠データ */
    sleep_data: MeasurementDetailsSleepData;
    /** 生活習慣と健康状態 */
    lifestyle_and_health_conditions: Record<
      LifestyleAndHealthConditionTypeForMeasurementDetails,
      MeasurementDetailsLifestyleAndHealthConditionsDetail
    >;
  };
  /** 表示タブ */
  tab: TabType;
  /** ディスプレイに表示するかどうか */
  isDisplay: boolean;
};
/**
 * 睡眠データ／生活習慣と健康状態の内容
 *
 * @param props {@link TabDataProps}
 * @returns
 */
function TabData(props: TabDataProps) {
  return (
    <Stack
      sx={{
        p: "0px 16px 80px 16px",
        display: `${props.isDisplay ? "" : "none"}`,
      }}
    >
      {props.tab === "sleep_data" && <SleepData data={props.data.sleep_data} />}
      <LifestyleAndHealthCondition
        data={props.data.lifestyle_and_health_conditions}
        isDisplay={props.tab === "lifestyle_and_health_conditions"}
      />
    </Stack>
  );
}
