import NewPasswordChallenge from "@/components/auth/NewPasswordChallenge";
import { SignInProps } from "@/components/auth/SignIn";
import { SignInState } from "@/components/auth/Types";
import {
  callCustomAuthChallengeAPI,
  callCustomInitiateAuthAPI,
  SIGNIN_TYPE,
} from "@/utils/auth";
import { getAxios, getFacilityApiServerUrl } from "@/utils/axios";
import {
  Alert,
  Box,
  Button,
  Link,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { createBrowserHistory } from "history";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { BackdropIndicator } from "../common/BackdropIndicator";
import ErrorMessage from "../ErrorMessage";
import {
  CONFIRMATION_CODE_NUMBER_VALIDATORS,
  LOGIN_PASSWORD_VALIDATORS,
  USERNAME_VALIDATORS,
  ValidatableTextField,
  Validator,
} from "./ValidatableTextField";

interface CustomSignInTextFieldProp {
  onResult?: (submittable: boolean) => void;
  type?: TextFieldProps["type"];
  id: string;
  label: string;
  name: string;
  autoComplete: string;
  autoFocus?: boolean;
  validators?: Validator[];
}
function CustomSignInTextField({
  onResult,
  ...props
}: CustomSignInTextFieldProp) {
  return (
    <ValidatableTextField
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0px",
        gap: "3px",
        flex: "none",
        order: "0",
        alignSelf: "stretch",
        flexGrow: "0",
      }}
      {...props}
      fullWidth
      onChangeErrorMessage={(message) => onResult?.(message === "")}
    />
  );
}

function CustomSignIn(props: SignInProps) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [signInType, setSignInType] = useState<SIGNIN_TYPE>("init");
  const [showIndicator, setShowIndicator] = useState(false);
  const [userName, setUsername] = useState<string>("");
  const [needReturn, setNeedReturn] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const signInState = location.state as SignInState | null | undefined;
  const query = new URLSearchParams(location.search);
  const next = query.get("next") ?? props.defaultSuccessTo;

  const clearHistory = useCallback(
    (e: any) => {
      e.preventDefault();
      /* リロード時の動作を上書きする。通常の動作だとstateが残るので、stateを削除してから、SignInページを読み込む。
      理由は、パスワード変更したあと、リロード後に「パスワードの初期化に成功しました…」メッセージを非表示にするため。 */
      const customHistory = createBrowserHistory();
      if (customHistory.location.state) {
        navigate(location.pathname, {});
      }
    },
    [location, navigate]
  );
  useEffect(() => {
    window.addEventListener("beforeunload", clearHistory);
    return () => {
      window.removeEventListener("beforeunload", clearHistory);
    };
  }, [clearHistory]);

  const onErrorMessageHandler = (message: string) => {
    setErrorMessage(t(message));
  };
  const onSetSignInTypeHandler = (signInType: SIGNIN_TYPE) => {
    setSignInType(signInType);
  };

  const SignIn = () => {
    const [userValidateResult, setUserValidateResult] = useState(false);
    const [passwordValidateResult, setPasswordValidateResult] = useState(false);
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setErrorMessage("");
      location.state = "";
      const data = new FormData(event.currentTarget);
      const username = data.get("username")?.toString() ?? "";
      const password = data.get("password")?.toString() ?? "";

      try {
        setShowIndicator(true);
        const signInType = await callCustomInitiateAuthAPI(
          props.axios,
          username,
          password
        );
        setSignInType(signInType);
        switch (signInType) {
          case "success":
            navigate(next);
            return;
          case "init":
            setErrorMessage(t("Incorrect username or password."));
            return;
          case "mfaRequired":
            setUsername(username);
            return;
          case "newPasswordRequired":
            return;
        }
      } catch (e: any) {
        const res: any = e.response?.data;
        if (res == null) {
          setErrorMessage(t("Incorrect username or password."));
          setSignInType("init");
          return;
        }

        if (Object.hasOwn(res, "cognito-error")) {
          setErrorMessage(t("Incorrect username or password."));
        } else if (res.username) {
          setErrorMessage(t(res.username[0]));
        } else if (res.password) {
          setErrorMessage(t(res.password[0]));
        } else {
          const statusCode = e.response?.status;
          const messages = res.error_messages;
          // 初期パスワードの有効期限切れの場合は、専用のエラーメッセージを表示する
          if (
            statusCode &&
            400 <= statusCode &&
            statusCode < 500 &&
            messages.length > 0 &&
            messages[0] ===
              t(
                "Temporary password has expired, please contact the administrator."
              )
          ) {
            setErrorMessage(messages[0]);
          } else {
            setErrorMessage(t("Incorrect username or password."));
          }
          setSignInType("init");
        }
      } finally {
        setShowIndicator(false);
      }
    };
    return (
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px",
          gap: "40px",
          flex: "none",
          order: "0",
          flexGrow: "0",
        }}
      >
        <Typography variant="h3" color="text.primary">
          SLEEP COMPASSへようこそ
        </Typography>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="24px"
          width="432px"
        >
          <CustomSignInTextField
            validators={USERNAME_VALIDATORS}
            id="username"
            label="ユーザー名"
            name="username"
            autoComplete="username"
            autoFocus
            onResult={setUserValidateResult}
          />
          <CustomSignInTextField
            validators={LOGIN_PASSWORD_VALIDATORS}
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
            onResult={setPasswordValidateResult}
          />
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: "24px",
            width: "432px",
            height: "90px",
            flex: "none",
            order: "2",
            flexGrow: "0",
          }}
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!(userValidateResult && passwordValidateResult)}
          >
            ログイン
          </Button>
          {props.forgotPasswordTo != null && (
            <Link
              component={RouterLink}
              to={props.forgotPasswordTo}
              sx={{ color: "primary.20" }}
            >
              パスワードを忘れた場合
            </Link>
          )}
        </Box>
      </Box>
    );
  };

  const MFACode = () => {
    const [confirmationCodeResult, setconfirmationCodeResult] = useState(false);
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setErrorMessage("");
      const data = new FormData(event.currentTarget);
      const confirmationCode = data.get("confirmationCode")?.toString() ?? "";
      try {
        setShowIndicator(true);
        const signInType = await callCustomAuthChallengeAPI(
          props.axios,
          userName,
          confirmationCode
        );
        setSignInType(signInType);
        switch (signInType) {
          case "success":
            navigate(next);
            return;
          case "mfaRequired":
            setErrorMessage(
              t("Invalid verification code provided, please try again.")
            );
            setNeedReturn(true);
            return;
          case "init":
          case "newPasswordRequired":
            setSignInType("init");
            return;
        }
      } catch (e: any) {
        const res: any = e.response?.data;
        setNeedReturn(true);

        if (res == null) {
          setErrorMessage(t("Incorrect username or password."));
          setSignInType("init");
          return;
        }

        if (res.error_messages) {
          setErrorMessage(res.error_messages[0]);
          setSignInType("init");
        } else if (res.confirmation_code) {
          setErrorMessage(t(res.confirmation_code[0]));
        } else {
          setErrorMessage(t("Incorrect username or password."));
          setSignInType("init");
        }
      } finally {
        setShowIndicator(false);
      }
    };
    const onClickBackToLogin = () => {
      setNeedReturn(false);
      setErrorMessage("");
      setSignInType("init");
    };
    return (
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px",
          gap: "40px",
          width: "432px",
          flex: "none",
          order: "0",
          flexGrow: "0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: "16px",
          }}
        >
          <Typography
            variant="h3"
            color="text.primary"
            width="305px"
            fontStyle="normal"
          >
            確認コードによる2段階認証
          </Typography>
          <Typography variant="body2" color="text.primary">
            ログインの安全性を確認するため、予め登録されたメールアドレスにお送りした6桁の確認コードを入力してください。
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "24px",
          }}
        >
          <CustomSignInTextField
            autoFocus
            name="confirmationCode"
            label="確認コード (6桁)"
            id="confirmationCode"
            autoComplete="current-confirmationCode"
            validators={CONFIRMATION_CODE_NUMBER_VALIDATORS}
            onResult={(message) => setconfirmationCodeResult(message)}
          />
          <Typography variant="caption" color="text.secondary">
            確認コードが届かない場合は、登録されたメールアドレスが間違っている可能性があります。施設のシステム管理者までお問い合わせください。
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            gap: "24px",
          }}
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!confirmationCodeResult}
          >
            確認する
          </Button>
          {needReturn && (
            <Link href="#" onClick={() => onClickBackToLogin()}>
              ログイン画面に戻る
            </Link>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <BackdropIndicator open={showIndicator} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "80px 0",
          position: "absolute",
          width: "100%",
          left: "0px",
          top: "72px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "40px",
            width: "432px",
          }}
        >
          {signInState?.message != null && (
            <Alert severity="info">{signInState?.message}</Alert>
          )}
          <ErrorMessage
            errorMessage={errorMessage ?? ""}
            sx={{ color: "error.main" }}
          />
          {signInType === "init" && <SignIn />}
          {signInType === "newPasswordRequired" && (
            <NewPasswordChallenge
              axios={getAxios(getFacilityApiServerUrl())}
              defaultSuccessTo={next}
              setErrorMessage={onErrorMessageHandler}
              setSignInType={onSetSignInTypeHandler}
            />
          )}
          {signInType === "mfaRequired" && <MFACode />}
        </Box>
      </Box>
    </>
  );
}
export default CustomSignIn;
