export function getLeftMessage(
  label: string,
  targetSelector: RegExpMatchArray,
  index: number,
  selectors: RegExpMatchArray[]
): string | null {
  // 対象のselectorがテキストの左端（文頭）の場合は、selectorの左側にテキストはない
  if (targetSelector.index === 0) {
    return null;
  }

  let start: number;
  if (index === 0) {
    // 対象selectorが最も左のselectorの場合、0が対象selectorの左側のテキストの先頭
    start = 0;
  } else {
    if (selectors[index - 1].index === undefined) {
      return null;
    }

    // 対象selectorの一つ左のselectorの終わりからが、対象selectorの左側のテキストの先頭
    start = selectors[index - 1].index! + selectors[index - 1][0].length;
  }

  return label.slice(start, targetSelector.index);
}

export function getRightMessage(
  label: string,
  targetSelector: RegExpMatchArray,
  index: number,
  selectors: RegExpMatchArray[]
): string | null {
  // 一番右のselector以外は対象にしない。right message は一番右のselectorの右側のメッセージを意味する。
  if (index !== selectors.length - 1) {
    return null;
  }

  if (targetSelector.index === undefined) {
    return null;
  }

  // selectorがテキストの右端（文末）の場合、selectorの右側にテキストはない
  if (label.length === targetSelector.index + targetSelector.length + 1) {
    return null;
  }

  return label.slice(targetSelector.index + targetSelector[0].length);
}
