/**
 * 施設ユーザー取得APIに渡すパラメータに対応するDTO
 */
export class MedicalFacilityUserSearchParams {
  username: string | null;
  last_name: string | null;
  first_name: string | null;
  email: string | null;

  constructor(params: MedicalFacilityUserSearchParams | null = null) {
    this.username = params?.username ?? null;
    this.last_name = params?.last_name ?? null;
    this.first_name = params?.first_name ?? null;
    this.email = params?.email ?? null;
  }
}
