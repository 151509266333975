import { Box, BoxProps, Stack, Typography } from "@mui/material";

type CompletionImageProps = {
  /** コンテナに適応するスタイル。paddingが指定されることを想定。 */
  sx: BoxProps["sx"];
};
/**
 * 事前問診完了ページ/毎日問診完了ページのファーストビューコンポーネント
 */
export function CompletionImage({ sx }: CompletionImageProps) {
  return (
    <Box sx={sx}>
      <img
        src="/img/image_interview_completion_01.png"
        alt="primary interview completion"
        loading="lazy"
        style={{ width: "100%", height: "auto", maxWidth: "390px" }}
      />
    </Box>
  );
}

type CompletionMessageProps = {
  completionMessageTitle: string;
  completionMessage: string;
};
/**
 * 事前問診完了メッセージ/毎日問診完了メッセージコンポーネント
 */
export function CompletionMessage({
  completionMessageTitle,
  completionMessage,
}: CompletionMessageProps) {
  return (
    <Stack spacing={4} alignItems="center">
      <Typography variant="h6">{completionMessageTitle}</Typography>
      <Typography
        variant="subtitle2"
        align="center"
        color="text.secondary"
        sx={{ whiteSpace: "pre-wrap" }}
      >
        {completionMessage}
      </Typography>
    </Stack>
  );
}
