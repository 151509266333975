import { SubtitleTypography } from "@/components/common/Typographies";
import {
  ChapterListItem,
  ElearningChapter,
} from "@/components/elearning/chapter/constants";
import { ElearningChapterListIcon } from "@/components/elearning/common/ElearningChapterListIcon";
import NavigateNext from "@mui/icons-material/NavigateNext";
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

type ElearningChapterListProps = {
  items: ChapterListItem[];
  elearningSearchParams: URLSearchParams;
};
export function ElearningChapterList({
  items,
  elearningSearchParams,
}: ElearningChapterListProps) {
  return (
    <List component="nav">
      {items.map((item, i) => (
        <React.Fragment key={i}>
          {i !== 0 && <Divider sx={{ mb: 8 }} />}
          {item.title != null && (
            <SubtitleTypography>{item.title}</SubtitleTypography>
          )}
          {item.chapters.map((c, j) => (
            <React.Fragment key={j}>
              {j !== 0 && <Divider />}
              <ListLink
                chapter={c}
                elearningSearchParams={elearningSearchParams}
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </List>
  );
}

type ListLinkProps = {
  chapter: ElearningChapter;
  elearningSearchParams: URLSearchParams;
};
function ListLink({ chapter, elearningSearchParams }: ListLinkProps) {
  return (
    <ListItemButton
      sx={{ padding: "40px 0px" }}
      component={Link}
      to={`${chapter.to}?${elearningSearchParams}`}
    >
      <ListItemIcon>
        <ElearningChapterListIcon alt={chapter.title} src={chapter.image} />
      </ListItemIcon>
      <ListItemText
        primary={<Typography variant="subtitle2">{chapter.title}</Typography>}
      />
      <NavigateNext />
    </ListItemButton>
  );
}
