import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

type MessageDialogButtonProps = {
  title: string;
  onClick: ButtonProps["onClick"];
};
type MessageDialogProps = {
  open: DialogProps["open"];
  title?: string;
  message: string;
  action: MessageDialogButtonProps;
};
export function MessageDialog({
  open,
  title,
  message,
  action,
}: MessageDialogProps) {
  const BUTTON_SX = { px: 4 };
  const contentSpacer = { px: 6, mt: title != null ? 4 : 6 };

  return (
    <Dialog open={open} maxWidth="md">
      {title != null && <DialogTitle textAlign="center">{title}</DialogTitle>}
      <DialogContent sx={{ ...contentSpacer }}>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ p: 6 }}>
        <Stack
          direction="row"
          spacing={4}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Button
            sx={BUTTON_SX}
            variant="contained"
            size="large"
            onClick={action.onClick}
            fullWidth
            disableElevation
          >
            {action.title}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
