import { StackProps } from "@mui/material";

/**
 * X方向のpaddingの定義
 *
 * 以下の定数(PX)はコンテンツエリアの左右の余白に使われる想定
 *
 * Liteの画面デザインは、スマホ画面での表示を基準に作成されている。
 * PC画面での表示では、画面のコンテナのmaxWidthをsm(600px)にして、PC画面でもスマホ画面に近いサイズとレイアウトで表示している。
 * PC画面での表示は、スマホ画面を基準に「画像リソースのサイズを1.5倍にする」「コンテンツエリアの左右の余白をスマホの2倍にする」などのルールに合わせて、作成されている。
 *
 * 前述の理由から、以下の定数(PX)では、xsの値の2倍の値がsmに指定されている。
 * また、定数名に含まれる数値(e.g. 2,4,6)はスマホ画面のときのスペースの値を示す。
 * 例えば、PX4は、スマホ画面のときのスペースが4(4x4=16px)である。また、PC画面のときは、倍の8(8x4=32px)である。
 *
 * 定数で定義した理由は、今後、より大きな画面サイズ(md, lg, xl)に対応することになった場合に、修正箇所を集約するため。
 * */
export const PX2: StackProps["px"] = { xs: 2, sm: 4 } as const;
export const PX4: StackProps["px"] = { xs: 4, sm: 8 } as const;
export const PX6: StackProps["px"] = { xs: 6, sm: 12 } as const;

/**
 * X方向のmarginの定義
 *
 * 以下の定数(MX)は、フローティングボタンなどの位置調整に使われる想定。
 *
 * paddingと同様に、PC画面ではスマホ画面の2倍のスペースを指定する。
 * 定数名についても、paddingと同様に、定数名に含まれる数値がスマホ画面のときのスペースの値を示す。
 * */
export const MX4: StackProps["mx"] = { xs: 4, sm: 8 } as const;
