import { AlertProps } from "@mui/material";

export type MessageClosure = () => SnackbarContext;

export type MessageClosureHandler = (closure: MessageClosure) => void;

/**
 * Snackbarに関する情報をまとめたクラス
 */
export class SnackbarContext {
  readonly message: string;
  readonly severity: AlertProps["severity"];

  constructor(message: string, severity: AlertProps["severity"]) {
    this.message = message;
    this.severity = severity;
  }
}
