import { BackdropIndicator } from "@/components/common/BackdropIndicator";
import { clone } from "@/components/common/ObjectService";
import { SCLink } from "@/components/common/SCLink";
import { SubtitleTypography } from "@/components/common/Typographies";
import {
  SleepCheckupGuidanceLink,
  SleepCheckupListLink,
} from "@/components/sleep_checkup_v1/BreadcrumbLink";
import { PAGE_TITLE, paths } from "@/components/sleep_checkup_v1/Path";
import {
  getSleepCheckupBasicInfoAPI,
  getSleepCheckupInfoById,
} from "@/components/sleep_checkup_v1/SleepCheckupInfoAPI";
import { SleepCheckupInfo } from "@/components/sleep_checkup_v1/Types";
import {
  DiseaseRiskInfo,
  InsomniaInterviewInfo,
  K6InterviewInfo,
  SASInterviewInfo,
  SleepCheckupBasicInfo as SleepCheckupBasicInfoData,
} from "@/components/sleep_checkup_v1/sleep_checkup_detail/sleep_checkup_basic_infos";
import {
  DAILY_INTERVIEW_STATUS_MAPPING,
  PRIMARY_INTERVIEW_STATUS_MAPPING,
} from "@/components/sleep_checkup_v1/util";
import AthensInsomniaScaleHelpDialog from "@/components/worksheet/commons/dialogs/AthensInsomniaScaleHelpDialog";
import K6HelpDialog from "@/components/worksheet/commons/dialogs/K6HelpDialog";
import StopbangHelpDialog from "@/components/worksheet/commons/dialogs/StopbangHelpDialog";
import {
  Add,
  Apartment,
  Assignment,
  Close,
  ErrorOutline,
  Face,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonProps,
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { ja } from "date-fns/locale";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

/**
 * 基礎情報用 {@link TableContainer}
 * @param param0 {@link TableContainerProps}
 * @returns 基礎情報用 {@link TableContainer}
 */
function BasicInfoTableContainer({ sx, ...props }: TableContainerProps) {
  return (
    <TableContainer
      sx={{
        boxShadow: "none",
        border: "solid 1px #E0E0E0",
        "& .MuiTableCell-root": {
          borderBottom: "initial",
        },
        "& .MuiTableHead-root .MuiTableRow-root, & .MuiTableBody-root .MuiTableRow-root:not(:last-child)":
          {
            borderBottom: "solid 1px #E0E0E0",
          },
        ...sx,
      }}
      {...props}
    ></TableContainer>
  );
}

/**
 * 開くボタン {@link Button}
 *
 * @param props {@link ButtonProps}
 * @returns 開くボタン用 {@link Button}
 */
function OpenButton({ sx, endIcon, ...props }: ButtonProps) {
  return (
    <Button disableElevation endIcon={endIcon ?? <OpenInNew />} {...props} />
  );
}

type ShowExamineeInfoButtonProps = {
  showExamineeInfo: boolean;
  setShowExamineeInfo: (showExamineeInfo: boolean) => void;
};
function ShowExamineeInfoButton({
  showExamineeInfo,
  setShowExamineeInfo,
}: ShowExamineeInfoButtonProps) {
  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowExamineeInfo(true);
  };
  return (
    <>
      {!showExamineeInfo && (
        <Button
          variant="contained"
          size="medium"
          color="primary"
          aria-label="show"
          onClick={onClickHandler}
          sx={{
            "& .MuiButton-startIcon": {
              marginRight: 0,
              marginLeft: 0,
            },
            height: "auto",
            width: "68px",
            padding: "16px",
            borderRadius: "20px 0 0 20px",
            position: "fixed",
            top: "50%",
            left: "calc(100% - 68px)",
            transform: "translateY(-50%)",
          }}
        >
          <Typography sx={{ writingMode: "vertical-rl" }}>
            <Add fontSize="small" sx={{ m: "8px" }} />受 診 者 情 報 を 見 る
          </Typography>
        </Button>
      )}
    </>
  );
}

/**
 * {@link SleepCheckupGuidance} 用プロパティ
 */
type SleepCheckupGuidanceProps = {
  minWidth: number;
};
/**
 * 睡眠指導画面
 * @param props {@link SleepCheckupGuidanceProps}
 * @returns 睡眠指導画面
 */
export function SleepCheckupGuidance({ minWidth }: SleepCheckupGuidanceProps) {
  const location = useLocation();
  const params = useParams();

  const [isError, setIsError] = useState<boolean>(false);
  const [sleepCheckupInfo, setSleepCheckupInfo] =
    useState<SleepCheckupInfo | null>(null);
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);
  const [showExamineeInfo, setShowExamineeInfo] = useState(false);
  const [sleepCheckupBasicInfo, setSleepCheckupBasicInfo] =
    useState<SleepCheckupBasicInfoData>();

  const toShowBackdrop =
    !isError && // エラーの時はバックドロップを表示しない
    isCallingAPI; // あるいはAPI呼び出し中に表示する

  const failed = (err: any) => {
    // 直打ちされたURLでレポートにアクセスできないケース等
    setIsError(true);
    setSleepCheckupInfo(null);
    setIsCallingAPI(false);
  };

  useEffect(() => {
    // sleepCheckupInfoが前画面から渡されてきた場合はそれを使う
    if (location.state) {
      const info = clone(location.state as SleepCheckupInfo);
      setSleepCheckupInfo(info);
    } else {
      // sleepCheckupInfoが未取得の場合は取得を試みる
      if (!params.sleepCheckupId) {
        throw new Error("params.sleepCheckupId is null");
      }

      getSleepCheckupInfoById(params.sleepCheckupId)
        .then(async (res: any) => {
          const info: SleepCheckupInfo = clone(res.data as SleepCheckupInfo);
          setSleepCheckupInfo(info);
          setIsError(false);
          setIsCallingAPI(false);
        })
        .catch(failed);
    }
  }, [location.state, params.sleepCheckupId]);

  useEffect(() => {
    if (sleepCheckupInfo?.unique_id == null) return;
    (async () => {
      const res = await getSleepCheckupBasicInfoAPI(sleepCheckupInfo.unique_id);
      setSleepCheckupBasicInfo(res.data);
    })();
  }, [sleepCheckupInfo?.unique_id]);

  const isReportApproved =
    sleepCheckupInfo?.sent_report === true || sleepCheckupInfo?.report != null;

  const getMeasurementPeriod = useCallback(() => {
    if (sleepCheckupBasicInfo == null) return "";
    function fmt(date: string) {
      return date.replace(/(\d+)-(\d+)-(\d+).*/, "$1年$2月$3日");
    }
    const periods = sleepCheckupBasicInfo.period;
    return `${fmt(periods[0])}〜${fmt(periods[1])}`;
  }, [sleepCheckupBasicInfo]);
  return (
    <>
      <BackdropIndicator open={toShowBackdrop} />
      <Stack
        spacing={6}
        sx={{
          mx: 10,
          my: 6,
          minWidth: minWidth,
          position: "relative",
        }}
      >
        <SleepCheckupGuidanceBreadcrumbs
          sleepCheckupId={sleepCheckupInfo?.id ?? ""}
        />
        <Stack direction="row" alignItems="center">
          <Typography variant="h3">
            {PAGE_TITLE["SleepCheckupGuidance"]}│基礎情報
          </Typography>
          {sleepCheckupInfo && (
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                ml: 2,
              }}
            >
              {` [ ${sleepCheckupInfo.medical_examinee_last_name} ${
                sleepCheckupInfo.medical_examinee_first_name
              }│測定期間：${getMeasurementPeriod()} ] `}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" spacing={8}>
          <ShowExamineeInfoButton
            showExamineeInfo={showExamineeInfo}
            setShowExamineeInfo={setShowExamineeInfo}
          />

          {isReportApproved === false && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
              sx={{
                color: "black.36",
                fontSize: "20px",
                pt: 20,
                width: "100%",
                height: "calc(100vh - 400px)",
              }}
            >
              <ErrorOutline />
              <Typography variant="body1">
                現在表示できる項目がありません
              </Typography>
            </Stack>
          )}
          {isReportApproved === true && (
            <>
              {/* ワークシート表示 */}
              <Stack spacing={8} sx={{ width: "calc(100% - 69px)" }}>
                <Tabs value={0} sx={{ width: "100%" }}>
                  <Tab label="指導用基礎情報" />
                </Tabs>
                <Stack sx={{ gap: 20 }}>
                  <Stack sx={{ gap: 4 }}>
                    <Stack sx={{ gap: 2 }}>
                      <SubtitleTypography>はじめに</SubtitleTypography>
                      <Stack>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "14px",
                            color: "error.main",
                          }}
                        >
                          このページは一部機微な内容を含みます。受診者には画面共有しないでください。
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#707070" }}>
                          このページで受診者の基本情報、主訴について確認後、以下のボタンから睡眠改善ワークシートを開き、衛生指導を実施してください。
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction="row" sx={{ gap: 4 }}>
                      <OpenButton
                        variant="contained"
                        onClick={() =>
                          sleepCheckupInfo != null &&
                          window.open(
                            `/facility/worksheet/${sleepCheckupInfo.unique_id}/sleep_summaries`,
                            "_blank"
                          )
                        }
                      >
                        ワークシートを開く
                      </OpenButton>
                      <OpenButton
                        variant="outlined"
                        onClick={() =>
                          sleepCheckupInfo != null &&
                          window.open(
                            `/facility/report/${sleepCheckupInfo.unique_id}`,
                            "_blank"
                          )
                        }
                      >
                        受診者レポートを開く
                      </OpenButton>
                    </Stack>
                  </Stack>

                  {/** 基本情報 */}
                  <Stack sx={{ gap: 10 }}>
                    <Stack>
                      <SubtitleTypography>基本情報</SubtitleTypography>
                      <Typography
                        variant="body2"
                        sx={{ color: "#707070", textAlign: "justify" }}
                      >
                        事前問診の回答内容のうち、特に重要な指標について表示しています。
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ gap: 6 }}>
                      <Stack sx={{ width: "100%", gap: 2 }}>
                        <Typography variant="subtitle1">基本情報</Typography>
                        <ExamineeInfo
                          data={sleepCheckupBasicInfo?.examinee_info}
                        />
                      </Stack>

                      <Stack sx={{ width: "100%", gap: 2 }}>
                        <Typography variant="subtitle1">
                          睡眠に関する情報
                        </Typography>
                        <InterviewInfo
                          data={sleepCheckupBasicInfo?.interview_info}
                        />
                      </Stack>
                    </Stack>
                  </Stack>

                  {/** 問診に基づく疾患のリスク */}
                  <Stack sx={{ gap: 10 }}>
                    <Stack sx={{ gap: 2 }}>
                      <SubtitleTypography>
                        問診に基づく疾患のリスク
                      </SubtitleTypography>
                      <Typography
                        variant="body2"
                        sx={{ color: "#707070", textAlign: "justify" }}
                      >
                        疾患のリスクについては、受診者が受け取るレポート上に表示されていない場合があります。
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        "& > table > tbody > tr > td": { verticalAlign: "top" },
                        "& > table > tbody > tr > td > .MuiTypography-root": {
                          whiteSpace: "nowrap",
                        },
                        "& > table > tbody > tr > td > hr": { my: 10 },
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <Typography variant="subtitle1">
                                睡眠時無呼吸症候群
                              </Typography>
                            </td>
                            <td>
                              <SASRiskTable data={sleepCheckupBasicInfo?.SAS} />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <Divider />
                            </td>
                          </tr>
                          {sleepCheckupBasicInfo?.insomnia && (
                            <>
                              <tr>
                                <td>
                                  <Typography variant="subtitle1">
                                    不眠症
                                  </Typography>
                                </td>
                                <td>
                                  <InsomniaRiskTable
                                    data={sleepCheckupBasicInfo?.insomnia}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <Divider />
                                </td>
                              </tr>
                            </>
                          )}
                          <tr>
                            <td>
                              <Typography variant="subtitle1">
                                うつ病
                              </Typography>
                            </td>
                            <td>
                              <DepressionRiskTable
                                data={sleepCheckupBasicInfo?.K6}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              {/* 受診者情報 */}
              {showExamineeInfo && (
                <Stack
                  sx={{
                    height: "calc(100vh - 320px)",
                    minHeight: "598px",
                    minWidth: "315px",
                    position: "sticky",
                    top: "80px",
                  }}
                >
                  <Card sx={{ height: "100%" }}>
                    <CardContent>
                      <Stack>
                        <Typography variant="subtitle2" sx={{ mb: "16px" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <Face />
                            <span style={{ marginLeft: "8px" }}>
                              受診者情報
                            </span>
                            <div style={{ flexGrow: 1 }}></div>
                            <IconButton
                              onClick={() => {
                                setShowExamineeInfo(false);
                              }}
                            >
                              <Close />
                            </IconButton>
                          </div>
                        </Typography>
                        <Typography variant="caption">
                          {`${
                            sleepCheckupInfo?.medical_examinee_last_name_kana ??
                            ""
                          } ${
                            sleepCheckupInfo?.medical_examinee_first_name_kana ??
                            ""
                          }`}
                        </Typography>

                        <Typography variant="h4">
                          {`${
                            sleepCheckupInfo?.medical_examinee_last_name ?? ""
                          } ${
                            sleepCheckupInfo?.medical_examinee_first_name ?? ""
                          }`}
                        </Typography>

                        <Stack sx={{ mb: "16px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body2">生年月日</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                {sleepCheckupInfo?.medical_examinee_birthday ??
                                  ""}
                                {sleepCheckupInfo?.medical_examinee_age_at_sleep_checkup !=
                                null
                                  ? `（${sleepCheckupInfo?.medical_examinee_age_at_sleep_checkup}）`
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body2">企業名</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                {sleepCheckupInfo?.corporate_name ?? ""}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body2">部署名</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                {sleepCheckupInfo?.department_name ?? ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Stack>

                        <Typography variant="subtitle2" sx={{ mb: "16px" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <Apartment />
                            <span style={{ marginLeft: "8px" }}>管理情報</span>
                          </div>
                        </Typography>

                        <Stack sx={{ mb: "16px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                デバイスID
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                {sleepCheckupInfo?.device_id ?? ""}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body2">受付日</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                {sleepCheckupInfo?.accepted_at ?? ""}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                受付担当者
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                {`${
                                  sleepCheckupInfo?.accepted_by?.user
                                    .last_name ?? ""
                                } ${
                                  sleepCheckupInfo?.accepted_by?.user
                                    .first_name ?? ""
                                }`}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                レポート発行日
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                {`${
                                  sleepCheckupInfo?.report?.approved_at == null
                                    ? ""
                                    : format(
                                        parseISO(
                                          sleepCheckupInfo?.report?.approved_at
                                        ),
                                        "yyyy-MM-dd HH:mm",
                                        { locale: ja }
                                      )
                                }`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Stack>

                        <Typography variant="subtitle2" sx={{ mb: "16px" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <Assignment />
                            <span style={{ marginLeft: "8px" }}>問診進捗</span>
                          </div>
                        </Typography>
                        <Stack sx={{ mb: "16px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Typography variant="body2">事前問診</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body2">
                                {PRIMARY_INTERVIEW_STATUS_MAPPING.get(
                                  sleepCheckupInfo?.primary_interview.status ??
                                    ""
                                ) ?? "未回答"}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body2">
                                {sleepCheckupInfo?.primary_interview.status !==
                                  "unanswered" && (
                                  <SCLink
                                    variant="caption"
                                    to={paths.getFullPath(
                                      "GuidancePrimaryInterviewResult",
                                      sleepCheckupInfo?.id
                                    )}
                                    state={sleepCheckupInfo}
                                  >
                                    詳細を見る
                                  </SCLink>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Typography variant="body2">毎日問診</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body2">
                                {DAILY_INTERVIEW_STATUS_MAPPING.get(
                                  sleepCheckupInfo?.daily_interview.status ?? ""
                                ) ?? "未回答"}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body2">
                                {sleepCheckupInfo?.daily_interview.status !==
                                  "unanswered" && (
                                  <SCLink
                                    variant="caption"
                                    to={paths.getFullPath(
                                      "GuidanceDailyInterviewResult",
                                      sleepCheckupInfo?.id
                                    )}
                                    state={sleepCheckupInfo}
                                  >
                                    詳細を見る
                                  </SCLink>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
}

type SleepCheckupGuidanceBreadcrumbsProps = {
  sleepCheckupId: string;
};
function SleepCheckupGuidanceBreadcrumbs({
  sleepCheckupId,
}: SleepCheckupGuidanceBreadcrumbsProps) {
  return (
    <Breadcrumbs>
      <SleepCheckupListLink />
      <SleepCheckupGuidanceLink
        sleepCheckupId={sleepCheckupId}
        disabled={true}
      />
    </Breadcrumbs>
  );
}
function BasicInfoTable({ rows }: { rows: string[][] }) {
  return (
    <BasicInfoTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>評価項目</TableCell>
            <TableCell>今回結果</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((v, i) => (
            <TableRow key={i}>
              <TableCell>{v[0]}</TableCell>
              <TableCell>{v[1]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </BasicInfoTableContainer>
  );
}

function getLabel(v: unknown, unit: string = "") {
  return v != null && v !== "" ? `${v}${unit}` : "-";
}

function ExamineeInfo({
  data,
}: {
  data: SleepCheckupBasicInfoData["examinee_info"] | undefined;
}) {
  const rows = [
    [
      "年齢（測定時）",
      getLabel(data?.medical_examinee_age_at_sleep_checkup, "歳"),
    ],
    ["身長", getLabel(data?.height, "cm")],
    ["体重", getLabel(data?.weight, "kg")],
    ["BMI", getLabel(data?.bmi)],
    ["性別", getLabel(data?.gender)],
    ["既往歴", getLabel(data?.disease_besides_hypertension_name)],
  ];
  return <BasicInfoTable rows={rows} />;
}

function InterviewInfo({
  data,
}: {
  data: SleepCheckupBasicInfoData["interview_info"] | undefined;
}) {
  const rows = [
    ["睡眠について気になること", getLabel(data?.concern_about_sleep_exist)],
    ["睡眠に関する既往歴", getLabel(data?.under_treatment_about_sleep)],
    ["睡眠薬の継続使用", getLabel(data?.sleep_medication)],
    ["朝型/夜型の自己認識", getLabel(data?.three_dss_morning_type)],
    [
      "子育て/介護による睡眠への影響",
      getLabel(data?.habitually_get_up_to_take_care),
    ],
    ["交代勤務の有無", getLabel(data?.shift_worker)],
  ];
  return <BasicInfoTable rows={rows} />;
}

function DiseaseRiskTable<T>({
  summary,
  interviews,
  help,
}: {
  summary: DiseaseRiskInfo<T> | undefined | null;
  interviews: [ReactNode, { answer: ReactNode; score: ReactNode }?][];
  help: ReactNode;
}) {
  const [open, setOpen] = useState(false);

  if (summary == null) return <></>;
  return (
    <Stack sx={{ gap: 2 }}>
      <Stack sx={{ gap: 4 }}>
        <BasicInfoTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>評価項目</TableCell>
                <TableCell>基準値</TableCell>
                <TableCell>今回判定</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Stack direction="row" sx={{ alignItems: "center" }}>
                    <Typography variant="body2">
                      {summary.basis_name}
                    </Typography>
                    {help}
                  </Stack>
                </TableCell>
                <TableCell>
                  {summary.standard_value}/{summary.max_value}
                  点以上でリスクあり
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: summary.is_warning ? "error.main" : "" }}
                  >
                    {summary.result}({summary.score}/{summary.max_value}点)
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </BasicInfoTableContainer>
        <Box display="flex" marginLeft="auto">
          <Button
            variant="text"
            endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            sx={{ color: "text.secondary" }}
            onClick={() => setOpen((open) => !open)}
            disableElevation
          >
            {open ? "回答結果を閉じる" : "回答結果を見る"}
          </Button>
        </Box>
      </Stack>
      <Collapse in={open}>
        <BasicInfoTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>評価項目</TableCell>
                <TableCell>回答</TableCell>
                <TableCell>点数</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {interviews.map((v, i) => (
                <TableRow key={i}>
                  <TableCell>
                    {typeof v[0] === "string" ? (
                      <Typography variant="body2">{v[0]}</Typography>
                    ) : (
                      v[0]
                    )}
                  </TableCell>
                  <TableCell>{getLabel(v[1]?.answer)}</TableCell>
                  <TableCell>{getLabel(v[1]?.score)}点</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </BasicInfoTableContainer>
      </Collapse>
    </Stack>
  );
}

function SASRiskTable({ data }: { data?: DiseaseRiskInfo<SASInterviewInfo> }) {
  if (data == null) return <></>;

  const rows: [
    ReactNode,
    keyof SASInterviewInfo,
    ((v: unknown) => ReactNode)?
  ][] = [
    [
      <>
        <Typography variant="body2">大きないびきをかく</Typography>
        <Typography variant="caption">
          ※話し声よりも大きいか、あるいは閉めた扉越しに聞こえる程度。
        </Typography>
      </>,
      "loud_snoring",
    ],
    ["日中にしばしば疲労感または眠気を感じる", "sleepy_daytime"],
    [
      "他の人から呼吸が睡眠中に停止しているのを指摘されている",
      "breathing_stopped",
    ],
    ["高血圧、あるいは現在高血圧の治療を受けている", "hypertension"],
    ["BMIが30以上", "bmi_over_30"],
    ["50歳以上", "age_over_50", (v: unknown) => `${v}歳`],
    ["首の周囲径が40cm以上", "neck_over_40_cm"],
    ["性別が男性", "gender"],
  ];
  return (
    <DiseaseRiskTable
      summary={data}
      interviews={rows.map((v) => {
        const info = data.interview_info[v[1]];
        const text =
          "question_text" in info // API返り値に質問文があればそれを使う
            ? info.question_text ?? v[0]
            : v[0];
        const converter = v[2] ?? (() => info.answer);
        return [
          text,
          {
            answer: converter(info.answer),
            score: info.score,
          },
        ];
      })}
      help={<StopbangHelpDialog />}
    />
  );
}

function InsomniaRiskTable({
  data,
}: {
  data: DiseaseRiskInfo<InsomniaInterviewInfo>;
}) {
  const rows: [ReactNode, keyof InsomniaInterviewInfo][] = [
    [
      "寝付きの問題について（布団に入って電気を消してから眠るまでに要した時間）",
      "ais_sleep_latency",
    ],
    ["夜間、睡眠途中に目が覚める問題について", "ais_awakening"],
    [
      "希望する起床時間より早く目覚め、それ以上眠れない問題について",
      "ais_wakeup_earlier",
    ],
    ["総睡眠時間について", "ais_total_sleep_quantity"],
    ["全体的な睡眠の質について", "ais_total_sleep_quality"],
    ["日中の満足感について", "ais_satisfied_daily"],
    ["日中の活動について（身体的および精神的）", "ais_daily_activity"],
    ["日中の眠気について", "ais_daily_sleepy_daytime"],
  ];
  return (
    <DiseaseRiskTable
      summary={data}
      interviews={rows.map((v) => [v[0], data?.interview_info[v[1]]])}
      help={<AthensInsomniaScaleHelpDialog />}
    />
  );
}

function DepressionRiskTable({
  data,
}: {
  data?: DiseaseRiskInfo<K6InterviewInfo>;
}) {
  const settings: [ReactNode, keyof K6InterviewInfo][] = [
    ["神経過敏に感じましたか", "k6_oversensitive"],
    ["絶望的だと感じましたか", "k6_hopeless"],
    ["そわそわしたり、落ち着きなく感じましたか", "k6_restless"],
    [
      "気分が沈み込んで、何が起こっても気が晴れないように感じましたか",
      "k6_depressed",
    ],
    ["何をするのも骨折りだと感じましたか", "k6_tired"],
    ["自分は価値のない人間だと感じましたか", "k6_worthless"],
  ];
  return (
    <DiseaseRiskTable
      summary={data}
      interviews={settings.map((v) => [v[0], data?.interview_info[v[1]]])}
      help={<K6HelpDialog />}
    />
  );
}
