import { Stack } from "@mui/material";
import { Section, SectionLayout } from ".";
import QualityCriterionDialog from "../commons/dialogs/QualityCriterionDialog";
import QuantityCriterionDialog from "../commons/dialogs/QuantityCriterionDialog";
import RhythmCriterionDialog from "../commons/dialogs/RhythmCriterionDialog";
import { SleepSummary } from "../commons/sleep_summaries";

interface SleepConditionSectionProps {
  sleep_condition?: SleepSummary["sleep_condition"];
}
/**
 * 睡眠の状態
 *
 * @param props {@link SleepConditionSectionProps}
 * @returns 睡眠の状態
 */
export default function SleepConditionSection({
  sleep_condition,
}: SleepConditionSectionProps) {
  return (
    <SectionLayout
      title="睡眠の状態"
      caption="睡眠の状態では、睡眠を「長さ」、「質」、「リズム」の3つの項目に分解して評価しています。"
    >
      <Stack sx={{ gap: 10 }}>
        <SleepQuantitySection
          sleep_quantity={sleep_condition?.sleep_quantity}
        />
        <SleepQualitySection sleep_quality={sleep_condition?.sleep_quality} />
        <SleepRhythmSection sleep_rhythm={sleep_condition?.sleep_rhythm} />
      </Stack>
    </SectionLayout>
  );
}

interface SleepRhythmSectionProps {
  sleep_rhythm?: SleepSummary["sleep_condition"]["sleep_rhythm"];
}
function SleepRhythmSection({ sleep_rhythm }: SleepRhythmSectionProps) {
  return (
    <Section
      title="睡眠のリズム"
      help={<RhythmCriterionDialog />}
      standardLabel="推奨範囲"
      result={sleep_rhythm}
      data={[
        {
          title: "平均的な起床時刻から2時間以上ずれて起床した日数",
          ...sleep_rhythm?.number_of_unhealthy_rhythm_days,
        },
      ]}
    />
  );
}

interface SleepQualitySectionProps {
  sleep_quality?: SleepSummary["sleep_condition"]["sleep_quality"];
}
function SleepQualitySection({ sleep_quality }: SleepQualitySectionProps) {
  return (
    <Section
      title="睡眠の質"
      help={<QualityCriterionDialog />}
      standardLabel="推奨範囲"
      result={sleep_quality}
      data={[
        {
          title: "平均睡眠効率",
          ...sleep_quality?.average_sleep_efficiency,
        },
        {
          title: (
            <>
              寝つきが悪かった日数<sup>※1</sup>
            </>
          ),
          ...sleep_quality?.number_of_initiating_sleep_difficulty_days,
        },
        {
          title: (
            <>
              睡眠中の覚醒が多かった日数<sup>※2</sup>
            </>
          ),
          ...sleep_quality?.number_of_awakening_days,
        },
        {
          title: (
            <>
              早く目が覚めてしまった日数<sup>※3</sup>
            </>
          ),
          ...sleep_quality?.number_of_premature_morning_awakening_days,
        },
      ]}
      captions={[
        "※1 消灯から入眠までの所要時間が1時間以上だった日数",
        "※2 睡眠中の覚醒時間の合計が1時間以上だった日数",
        "※3 睡眠終了から起床までの所要時間が1時間以上だった日数",
      ]}
    />
  );
}

interface SleepQuantitySectionProps {
  sleep_quantity?: SleepSummary["sleep_condition"]["sleep_quantity"];
}
function SleepQuantitySection({ sleep_quantity }: SleepQuantitySectionProps) {
  if (sleep_quantity == null) return <></>;
  return (
    <Section
      title="睡眠の長さ"
      help={<QuantityCriterionDialog {...sleep_quantity} />}
      standardLabel="推奨範囲"
      result={sleep_quantity}
      data={[
        {
          title: "平均睡眠時間",
          standard_value: (
            <>
              {sleep_quantity?.recommended.low}～
              {sleep_quantity?.recommended.high}時間
              <br />（{sleep_quantity?.age_range}）
            </>
          ),
          ...sleep_quantity?.average_sleep_time,
        },
        {
          title: (
            <>
              睡眠時間が短すぎる日数<sup>※</sup>
            </>
          ),
          ...sleep_quantity?.shortest_sleep_days,
        },
      ]}
      captions={[
        `※ ${sleep_quantity?.appropriate.low}時間未満（${sleep_quantity?.age_range}）`,
      ]}
    />
  );
}
