import ReportRouterConfig from "@/pages/common/report/ReportRouterConfig";
import { SummaryPage } from "@/pages/common/report/SummaryPage";
import { facilityTheme } from "@/theme/themeV1";
import { ThemeProvider } from "@emotion/react";
import { Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { Route, Routes } from "react-router-dom";

export default function FacilityReportLayout() {
  return (
    <ThemeProvider theme={facilityTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <Routes>
          <Route path="/*" element={<WebReportLayout />} />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
function WebReportLayout() {
  return (
    <Stack sx={{ width: "375px", margin: "auto" }}>
      <Routes>
        <Route path="/:uuid/summary" element={<SummaryPage />} />
        <Route path="/*" element={<ReportRouterConfig />} />
      </Routes>
    </Stack>
  );
}
