import { NavigateNext } from "@mui/icons-material";
import {
  Breadcrumbs,
  Divider,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import Judge from "./commons/Judge";
import ReportNavigate from "./commons/ReportNavigate";
import SubtitleTypography from "./commons/SubtitleTypography";
import { DrowsinessResponse, UserType } from "./types";

export function Drowsiness({
  uuid,
  userType,
  data,
}: {
  uuid: string | undefined;
  userType: UserType;
  data: DrowsinessResponse;
}) {
  return (
    <Stack>
      <img
        loading="lazy"
        src="/img/image_report_drowsiness.png"
        alt="image_report_drowsiness"
        width="100%"
      />
      <Stack sx={{ p: "40px 16px 24px 16px", gap: "8px" }}>
        <Breadcrumbs
          maxItems={2}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            href={`/${userType}/report/${uuid}`}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body2">レポートTOP</Typography>
          </Link>
          <Typography variant="body2">日中の眠気</Typography>
        </Breadcrumbs>
        <Typography variant="h5" sx={{ color: "primary.main" }}>
          日中の眠気
        </Typography>
      </Stack>
      <Stack sx={{ p: "0px 16px 40px 16px" }}>
        <Typography
          variant="subtitle2"
          sx={{ color: "text.secondary", textAlign: "justify" }}
        >
          毎日問診で眠気を感じたかどうかの回答の記録です。日中に強い眠気がある場合、睡眠が不足しているため、日々の睡眠を改善しましょう。
        </Typography>
      </Stack>
      <Stack
        sx={{
          p: "0 16px 40px 16px",
          gap: "24px",
        }}
      >
        <SubtitleTypography>測定結果</SubtitleTypography>
        <Typography variant="subtitle2">日中の眠気</Typography>
        <DrowsinessTable data={data.drowsiness} />
      </Stack>
      <Stack sx={{ p: "0px 16px 80px 16px", gap: "24px" }}>
        <SubtitleTypography>判定と評価項目</SubtitleTypography>
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="subtitle2">判定</Typography>
          <Judge
            judge={data.judge_and_evaluated_items.judge}
            title={
              data.judge_and_evaluated_items.judge === "alert"
                ? "課題あり"
                : "課題なし"
            }
          />
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ mb: "8px" }}>
            評価項目
          </Typography>
          <TableContainer
            sx={{
              borderTopWidth: "1px",
              borderTopColor: "rgba(0, 0, 0, 0.12)",
              borderTopStyle: "solid",
              borderRadius: "0px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ p: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", fontWeight: "400" }}
                    >
                      項目
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      p: "8px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", fontWeight: "400" }}
                    >
                      基準値
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", p: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", fontWeight: "400" }}
                    >
                      今回結果
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ pl: "8px", pr: "8px" }}>
                    日中に眠気を多く感じた日数
                  </TableCell>
                  <TableCell
                    sx={{
                      pl: "8px",
                      pr: "8px",
                      whiteSpace: "nowrap",
                      backgroundColor: "#f5f8fb",
                    }}
                  >
                    2日以下/週
                  </TableCell>
                  <TableCell
                    sx={{
                      pl: "8px",
                      pr: "8px",
                      whiteSpace: "nowrap",
                      color: data.judge_and_evaluated_items.evaluated_items
                        .feel_sleepy_day.is_warning
                        ? "error.main"
                        : "text.main",
                    }}
                  >
                    {
                      data.judge_and_evaluated_items.evaluated_items
                        .feel_sleepy_day.value
                    }
                    日間
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Stack sx={{ p: "0px 16px 0px 16px" }}>
        <Divider sx={{ m: "32px 0px" }} />
        <Typography variant="h6" color="text.secondary">
          あわせて読みたい
        </Typography>
        <ReportNavigate
          reportUid={uuid}
          navigates={[
            {
              icon: {
                src: "/img/icon_report_rhythm.svg",
                alt: "musical_note",
              },
              text: "睡眠のリズム",
              link: `/${userType}/report/${uuid}/rhythm`,
            },
            {
              icon: {
                src: "/img/icon_report_quantity.svg",
                alt: "quantity",
              },
              text: "睡眠の長さ",
              link: `/${userType}/report/${uuid}/quantity`,
            },
            {
              icon: {
                src: "/img/icon_report_quality.svg",
                alt: "quality",
              },
              text: "睡眠の質",
              link: `/${userType}/report/${uuid}/quality`,
            },
            {
              icon: {
                src: "/img/icon_report_fatigue.svg",
                alt: "fatigue",
              },
              text: "日中の疲労",
              link: `/${userType}/report/${uuid}/fatigue`,
            },
          ]}
        />
      </Stack>
    </Stack>
  );
}

function DrowsinessTable(props: {
  data: {
    // 測定日
    date: string;
    // 眠気　"with_drowsiness" | "partially_drowsiness" | "no_drowsiness" | "no_data"
    value: DrowsinessResponse["drowsiness"][number]["value"];
    // 注意フラグ
    is_warning: boolean;
  }[];
}) {
  return (
    <>
      <Stack direction="column" justifyContent="center">
        <Stack direction="row" justifyContent="center">
          {props.data.map((d, i) => (
            <Typography
              key={i}
              sx={{
                flex: 1,
                color: "text.secondary",
                textAlign: "center",
                p: "8px 0px 0px 0px",
                fontSize: "10px",
              }}
            >
              {format(parseISO(d.date), "M/d")}
            </Typography>
          ))}
        </Stack>
        <Stack direction="row" justifyContent="center">
          {props.data.map((d, i) => (
            <Typography
              key={i}
              sx={{
                flex: 1,
                color: "text.secondary",
                textAlign: "center",
                p: "0px 0px 8px 0px",
                fontSize: "10px",
              }}
            >
              {"日月火水木金土"[parseISO(d.date).getDay()]}
            </Typography>
          ))}
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "64px" }}
        >
          {props.data.map((d, i) => (
            <Typography
              key={i}
              sx={{
                flex: 1,
                textAlign: "center",
                p: "4px 0px",
                color: d.is_warning ? "error.main" : "text.primary",
                whiteSpace: "break-spaces",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              {drowsinessValue(d.value)}
            </Typography>
          ))}
        </Stack>
        <Divider />
      </Stack>
    </>
  );
}

function drowsinessValue(
  value: DrowsinessResponse["drowsiness"][number]["value"]
) {
  if (value === "with_drowsiness") return "あり";
  else if (value === "partially_drowsiness") return "一部\nあり";
  else if (value === "no_drowsiness") return "なし";
  else return "-";
}
