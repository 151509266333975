import { TermsOfService as Terms } from "@/sleep_compass_lite/components/target/TermsOfService";
import { Stack, Typography } from "@mui/material";

/**
 * Liteの利用規約コンポーネント
 * タイトルと規約本文で構成される。利用規約同意画面と利用規約画面で、共通部分(タイトル,規約本文)を流用するために定義。
 */
export function TermsOfServiceBase() {
  return (
    <Stack spacing={6}>
      <Typography variant="h6" align="center">
        スリープコンパスライト会員向け規約
      </Typography>
      <Terms />
    </Stack>
  );
}
