import {
  EmphasisTypography,
  SubtitleTypography,
} from "@/components/common/Typographies";
import { ImageBox } from "@/components/elearning/chapter/Boxes";
import { Layout } from "@/components/elearning/chapter/Layout";
import { SubChapterStack } from "@/components/elearning/chapter/Stacks";
import { Typography } from "@mui/material";

export function Chapter02() {
  return (
    <Layout title="体内時計について知ろう">
      <SubChapterStack>
        <SubtitleTypography>概日リズム</SubtitleTypography>
        <Typography variant="body2">
          体内時計は「概日リズム（サーカディアンリズム）」とも言います。「サーカディアン」とは”おおよそ24時間周期の”という意味です。
          <EmphasisTypography>
            地球上の殆どの生物は地球の一日より少し長い、約25時間周期（24.5時間）のリズムを持っています。
          </EmphasisTypography>
          この体内時計をもう少し詳しく見ていきます。
        </Typography>
        <ImageBox src="/img/image_chapter2_1.png" alt="概実リズム" />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>深部体温の変動と睡眠</SubtitleTypography>
        <Typography variant="body2">
          体温は1日の間に約1℃上がったり下がったりし、夕方から夜にかけて最高になり、朝方最低になります。この深部体温が下がるときに人は眠り始め、明け方、深部体温が最低点に達した後、徐々に目を覚ましていきます。
        </Typography>
        <ImageBox src="/img/image_chapter2_2.png" alt="深部体温の変動と睡眠" />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>生体リズムの重要性</SubtitleTypography>
        <Typography variant="body2">
          昼夜逆転生活や不規則な生活が続くと、様々な生体機能のリズムは秩序を保てなくなり、外界のリズムが生体時計をリセットしにくくなります。
        </Typography>
        <Typography variant="body2">
          これを内的脱同調といい、一種の時差ぼけ状態を引き起こします。日中の眠気を高め、注意や記憶・感情機能を低下させるばかりでなく、免疫機能やホルモン分泌にも影響し、健康な発育・発達にも影響を及ぼします。
        </Typography>
        <ImageBox src="/img/image_chapter2_3.png" alt="生体リズムの重要性" />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>成長ホルモン</SubtitleTypography>
        <Typography variant="body2">
          子どもたちの成長には欠かせない成長ホルモンは、寝入ってから3～4時間のノンレム睡眠時に集中的に分泌され、新陳代謝や免疫力の回復を促します。ですから、寝入りばなに携帯が鳴って何度も目を覚ましてしまっていると、成長ホルモンが十分に分泌されないということになります。
        </Typography>
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>メラトニンの分泌</SubtitleTypography>
        <Typography variant="body2">
          メラトニンは朝目覚めた後、14～15時間後に分泌され、眠りを安定させる働きがあります。メラトニンは光によって分泌が抑えられるため、夜遅くまで明るい環境にいると、メラトニンの分泌が抑制され質の良い睡眠を取りにくくなります。
        </Typography>
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>コルチゾールの分泌</SubtitleTypography>
        <Typography variant="body2">
          コルチゾールは1日のやる気を起こすホルモンで、朝目覚める時に最も多く分泌され、その後少しずつ減少します。
        </Typography>
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>お昼の眠気が起こるわけ</SubtitleTypography>
        <ImageBox
          src="/img/image_chapter2_4.png"
          alt="お昼の眠気が起こるわけ"
        />
        <Typography variant="body2">
          人間は、もともと体内時計として日中の眠気が出ます。寝不足だと日中の眠気が強くなります。
          眠気が出てきたら、働いている方は15分～20分の昼寝をおすすめします。
        </Typography>
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>
          休日の寝だめはリズムを狂わせる原因に
        </SubtitleTypography>
        <ImageBox
          src="/img/image_chapter2_5.png"
          alt="休日の寝だめはリズムを狂わせる原因に"
        />
      </SubChapterStack>
    </Layout>
  );
}
