import { NavigateNext } from "@mui/icons-material";
import {
  Breadcrumbs,
  Divider,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { differenceInCalendarDays, format, parseISO } from "date-fns";
import Highcharts, { SeriesLegendItemClickEventObject } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import { useRef } from "react";
import Judge from "./commons/Judge";
import ReportNavigate from "./commons/ReportNavigate";
import SubtitleTypography from "./commons/SubtitleTypography";
import { RhythmMeasurementResults, RhythmResponse, UserType } from "./types";

highchartsMore(Highcharts);

export function Rhythm({
  uuid,
  userType,
  data,
}: {
  uuid: string | undefined;
  userType: UserType;
  data: RhythmResponse;
}) {
  return (
    <Stack>
      <img
        loading="lazy"
        src="/img/image_report_rhythm.png"
        alt="image_report_rhythm"
        width="100%"
      />
      <Stack sx={{ p: "40px 16px 24px 16px", gap: "8px" }}>
        <Breadcrumbs
          maxItems={2}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            href={`/${userType}/report/${uuid}`}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body2">レポートTOP</Typography>
          </Link>
          <Typography variant="body2">睡眠のリズム</Typography>
        </Breadcrumbs>
        <Typography variant="h5" sx={{ color: "primary.main" }}>
          睡眠のリズム
        </Typography>
      </Stack>
      <Stack sx={{ p: "0px 16px 40px 16px" }}>
        <Typography
          variant="subtitle2"
          textAlign="justify"
          sx={{ color: "text.secondary" }}
        >
          7日間のあなたの起床時刻の規則正しさを評価します。
        </Typography>
      </Stack>
      <Stack
        sx={{
          p: "0 16px 40px 16px",
          gap: "24px",
        }}
      >
        <SubtitleTypography>測定結果</SubtitleTypography>
        <Stack sx={{ gap: "16x" }}>
          <Chart data={data.measurement_results} />
        </Stack>
      </Stack>
      <Stack sx={{ p: "0px 16px 80px 16px", gap: "24px" }}>
        <SubtitleTypography>判定と評価項目</SubtitleTypography>
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="subtitle2">判定</Typography>
          <Judge judge={data.judge_and_evaluated_items.judge} />
        </Stack>
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="subtitle2">評価項目</Typography>
          <TableContainer
            sx={{
              borderTopWidth: "1px",
              borderTopColor: "rgba(0, 0, 0, 0.12)",
              borderTopStyle: "solid",
              borderRadius: "0px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontSize: "12px", fontWeight: 400, p: "8px" }}
                  >
                    項目
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      fontWeight: 400,
                      p: "8px",
                    }}
                  >
                    基準値
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      fontWeight: 400,
                      p: "8px",
                    }}
                  >
                    今回結果
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ px: "8px" }}>
                    平均的な起床時刻から2時間以上ずれて起床した日数
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      backgroundColor: "#f5f8fb",
                      px: "8px",
                    }}
                  >
                    0日/週
                  </TableCell>
                  <TableCell
                    sx={{
                      px: "8px",
                      whiteSpace: "nowrap",
                      color: data.judge_and_evaluated_items.evaluated_items
                        .days_with_a_wake_up_time_differing_from_the_average
                        .is_warning
                        ? "error.main"
                        : "text.main",
                    }}
                  >
                    {
                      data.judge_and_evaluated_items.evaluated_items
                        .days_with_a_wake_up_time_differing_from_the_average
                        .value
                    }
                    日間
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="subtitle2">参考項目</Typography>
          <TableContainer
            sx={{
              borderTopWidth: "1px",
              borderTopColor: "rgba(0, 0, 0, 0.12)",
              borderTopStyle: "solid",
              borderRadius: "0px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontSize: "12px", fontWeight: 400, p: "8px" }}
                  >
                    項目
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      fontWeight: 400,
                      p: "8px",
                    }}
                  >
                    今回結果
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ px: "8px" }}>
                    あなたの平均的な起床時刻
                  </TableCell>
                  <TableCell sx={{ px: "8px" }}>
                    <Typography variant="body2">
                      {
                        data.judge_and_evaluated_items.referenced_items
                          .average_wake_up_time
                      }
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ px: "8px" }}>
                    あなたに推奨される起床時刻の範囲
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", px: "8px" }}>
                    <Typography variant="body2">
                      {formatDisplayTime(
                        parseHourMinutes(
                          data.judge_and_evaluated_items.referenced_items
                            .average_wake_up_time
                        ) -
                          2 * 60
                      )}
                      〜
                      {formatDisplayTime(
                        parseHourMinutes(
                          data.judge_and_evaluated_items.referenced_items
                            .average_wake_up_time
                        ) +
                          2 * 60
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Stack sx={{ p: "0px 16px 80px 16px", gap: "24px" }}>
        <Stack sx={{ gap: "16px" }}>
          <SubtitleTypography>
            {data.judge_and_evaluated_items.judge === "good"
              ? "コメント"
              : "改善のポイント"}
          </SubtitleTypography>
          <Typography variant="body1" textAlign="justify">
            {data.points_of_improvement}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ p: "0px 16px 0px 16px" }}>
        <Divider sx={{ m: "32px 0px" }} />
        <Typography variant="h6" color="text.secondary">
          あわせて読みたい
        </Typography>
        <ReportNavigate
          reportUid={uuid}
          navigates={[
            {
              icon: {
                src: "/img/icon_report_quantity.svg",
                alt: "quantity",
              },
              text: "睡眠の長さ",
              link: `/${userType}/report/${uuid}/quantity`,
            },
            {
              icon: {
                src: "/img/icon_report_quality.svg",
                alt: "quality",
              },
              text: "睡眠の質",
              link: `/${userType}/report/${uuid}/quality`,
            },
            {
              icon: {
                src: "/img/icon_report_drowsiness.svg",
                alt: "drowsiness",
              },
              text: "日中の眠気",
              link: `/${userType}/report/${uuid}/drowsiness`,
            },
            {
              icon: {
                src: "/img/icon_report_fatigue.svg",
                alt: "fatigue",
              },
              text: "日中の疲労",
              link: `/${userType}/report/${uuid}/fatigue`,
            },
          ]}
        />
      </Stack>
    </Stack>
  );
}

function Chart(props: { data: RhythmMeasurementResults }) {
  const theme = useTheme();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const min = Math.min(
    ...props.data.items
      .filter((item) => item.time_spent_in_bed_from != null)
      .map((item) => {
        const date = parseISO(item.date);
        const fromDate = parseISO(item.time_spent_in_bed_from!);

        // NOTE: 表示する日付と起床の日付が異なる場合、差の日数*24を加算する
        return (
          (fromDate.getHours() +
            Math.abs(differenceInCalendarDays(fromDate, date)) * 24) *
            60 +
          fromDate.getMinutes()
        );
      })
  );
  const max = Math.max(
    ...props.data.items
      .filter((item) => item.time_spent_in_bed_to.date != null)
      .map((item) => {
        const date = parseISO(item.date);
        const toDate = parseISO(item.time_spent_in_bed_to.date!);

        // NOTE: 表示する日付と起床の日付が異なる場合、差の日数*24を加算する
        return (
          (toDate.getHours() +
            Math.abs(differenceInCalendarDays(date, toDate)) * 24) *
            60 +
          toDate.getMinutes()
        );
      })
  );
  const averageWakeUpTimeValue = parseHourMinutes(
    props.data.average_wake_up_time
  );
  const options: Highcharts.Options = {
    chart: {
      type: "columnrange",
      marginLeft: 35,
      marginRight: 0,
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      categories: props.data.items.map((item) => {
        const date = parseISO(item.date);
        return `${format(date, "M/d")}<br>${"日月火水木金土"[date.getDay()]}`;
      }),
      opposite: true,
      lineWidth: 0,
      labels: {
        rotation: 0,
        style: {
          color: theme.palette.text.secondary,
          fontSize: "10px",
        },
      },
    },
    yAxis: {
      reversed: true,
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      // NOTE: 起床の日付の最大値 + 2時間をグラフの最大値とする
      max: max + 2 * 60,
      // NOTE: 就寝の日付の最小値 - 2時間をグラフの最小値とする
      min: min - 2 * 60,
      plotBands: [
        {
          // NOTE: チャートは最大48時間になる可能性がある為、「0〜24の間」および「24〜48の間」の「0〜24の間」の分の横軸バンド
          color: "#e0f5f3",
          from: averageWakeUpTimeValue - 2 * 60,
          to: averageWakeUpTimeValue + 2 * 60,
        },
        {
          // NOTE: チャートは最大48時間になる可能性がある為、「0〜24の間」および「24〜48の間」の「24〜48の間」の分の横軸バンド
          color: "#e0f5f3",
          from: averageWakeUpTimeValue + 24 * 60 - 2 * 60,
          to: averageWakeUpTimeValue + 24 * 60 + 2 * 60,
        },
      ],
      plotLines: [
        {
          value: 0,
          color: "#e0e0e0",
        },
        {
          value: 24 * 60,
          width: 0,
          label: {
            x: -35,
            y: 5,
            text: formatDisplayTime(24 * 60),
            style: {
              color: theme.palette.text.secondary,
              fontFamily: "Noto Sans JP",
              fontSize: "10px",
            },
          },
        },
        {
          // NOTE: チャートは最大48時間になる可能性がある為、「0〜24の間」および「24〜48の間」の「0〜24の間」の分の横軸線
          value: averageWakeUpTimeValue,
          color: theme.palette.text.secondary,
          zIndex: -1,
          width: 1,
          label: {
            x: -35,
            y: 5,
            text: formatDisplayTime(averageWakeUpTimeValue),
            style: {
              color: "#00A99D",
              fontFamily: "Noto Sans JP",
              fontSize: "10px",
            },
          },
        },
        {
          // NOTE: チャートは最大48時間になる可能性がある為、「0〜24の間」および「24〜48の間」の「24〜48の間」の分の横軸線
          value: averageWakeUpTimeValue + 24 * 60,
          color: "#00a99d",
          zIndex: 3,
          width: 1,
          label: {
            x: -35,
            y: 5,
            text: formatDisplayTime(averageWakeUpTimeValue + 24 * 60),
            style: {
              color: "#00A99D",
              fontFamily: "Noto Sans JP",
              fontSize: "10px",
            },
          },
        },
        {
          value: max + 2 * 60,
          color: "#0000001F",
          zIndex: -1,
          width: 1,
          label: {
            x: -35,
            y: 5,
            text: formatDisplayTime(max + 2 * 60),
            style: {
              color: theme.palette.text.secondary,
              fontFamily: "Noto Sans JP",
              fontSize: "10px",
            },
          },
        },
        {
          value: min - 2 * 60,
          color: "#0000001F",
          zIndex: -1,
          width: 1,
          label: {
            x: -35,
            y: 5,
            text: formatDisplayTime(min - 2 * 60),
            style: {
              color: theme.palette.text.secondary,
              fontFamily: "Noto Sans JP",
              fontSize: "10px",
            },
          },
        },
      ],
      gridLineWidth: 0,
    },
    plotOptions: {
      columnrange: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      symbolHeight: 10,
      symbolRadius: 0,
      itemStyle: {
        color: theme.palette.text.secondary,
      },
    },
    series: [
      {
        type: "columnrange",
        name: "寝床にいた時間",
        color: theme.palette.primary.light,
        data: props.data.items.map((item) => {
          if (
            item.time_spent_in_bed_from == null ||
            item.time_spent_in_bed_to.date == null
          ) {
            // 起床、または消灯時間のいずれかが null の場合、グラフを描画しない。
            return {};
          }
          const date = parseISO(item.date);
          const fromDate = parseISO(item.time_spent_in_bed_from);
          const toDate = parseISO(item.time_spent_in_bed_to.date);

          return {
            low:
              (fromDate.getHours() +
                Math.abs(differenceInCalendarDays(date, fromDate)) * 24) *
                60 +
              fromDate.getMinutes(),
            high:
              (toDate.getHours() +
                Math.abs(differenceInCalendarDays(date, toDate)) * 24) *
                60 +
              toDate.getMinutes(),
            isWarning: item.time_spent_in_bed_to.is_warning,
          };
        }),
        dataLabels: {
          enabled: true,
          formatter() {
            return (this.point as Highcharts.Point & { isWarning?: boolean })
              .isWarning && Number(this.y) === this.point.high
              ? `<span style="color: ${
                  theme.palette.error.main
                }; font-family: Noto Sans JP">${formatDisplayTime(
                  Number(this.y)
                )}</span>`
              : // NOTE: 色は施設、受診者画面共に#3D3D3Dに固定
                `<span style="color: #3D3D3D; font-family: Noto Sans JP">${formatDisplayTime(
                  Number(this.y)
                )}</span>`;
          },
          style: {
            fontWeight: "500",
          },
        },
        events: {
          legendItemClick(e: SeriesLegendItemClickEventObject) {
            e.preventDefault();
          },
        },
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
}

function parseHourMinutes(hourMinutes: string) {
  // NOTE: hh:mm形式(e.g. 8:06)の時間をDate型に変更し、getHours/getMinutesを取得する為、2020/01/01は任意の日付を使用
  const parsedHourMinutes = new Date("2020/01/01 " + hourMinutes);
  return parsedHourMinutes.getHours() * 60 + parsedHourMinutes.getMinutes();
}

function formatHours(hours: number) {
  if (hours < 0) return hours + 24;
  if (hours >= 24) return hours - 24;
  return hours;
}

function formatMinutes(minutes: number) {
  if (minutes < 10) return `0${minutes}`;
  return minutes;
}

function formatDisplayTime(time: number) {
  const hours = Math.floor(time / 60);
  const minutes = Math.floor(time % 60);

  if (hours && minutes)
    return `${formatHours(hours)}:${formatMinutes(minutes)}`;
  else if (!hours && minutes) return `0:${formatMinutes(minutes)}`;
  else if (hours && !minutes) return `${formatHours(hours)}:00`;
  else return "0:00";
}
