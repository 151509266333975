import { Stack, Table, TableHead, Typography } from "@mui/material";
import { SectionTitle } from "../commons";
import {
  BasisOfDetermination,
  InterviewSummaryTableRow,
  InterviewTableBody,
  MedicalFacilityDiseaseRiskReportCommonProps,
  MedicalFacilityReportLayout,
  Question,
  QuestionRows,
  TableContainer,
  TableHeaderCell,
  TableQuestionTypography,
  TableRow,
} from "./commons";

/**
 * {@link StopBang} 用のプロパティ
 */
export interface StopBangProps
  extends MedicalFacilityDiseaseRiskReportCommonProps {}
/**
 * STOP-Bangのレポート
 *
 * @param param0 {@link StopBangProps}
 * @returns STOP-Bangのレポート
 */
export default function StopBang({ data, page, maxPage }: StopBangProps) {
  if (data.disease_risks.SAS == null) return <></>;
  const risk = data.disease_risks.SAS;
  const questions: Question[] = [
    <>
      <TableQuestionTypography>大きないびきをかく</TableQuestionTypography>
      <Typography
        sx={{
          color: "#000000",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: "Noto Sans JP",
          fontSize: "8px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: "0.2px",
        }}
      >
        ※話し声よりも大きいか、あるいは閉めた扉越しに聞こえる程度。
      </Typography>
    </>,
    <TableQuestionTypography>
      日中にしばしば疲労感または眠気を感じる
    </TableQuestionTypography>,
    <TableQuestionTypography>
      他の人から呼吸が睡眠中に停止しているのを指摘されている
    </TableQuestionTypography>,
    <TableQuestionTypography>
      高血圧、あるいは現在高血圧の治療を受けている
    </TableQuestionTypography>,
    <TableQuestionTypography>BMIが30以上</TableQuestionTypography>,
    <TableQuestionTypography>50歳以上</TableQuestionTypography>,
    <TableQuestionTypography>首の周囲径が40cm以上</TableQuestionTypography>,
    <TableQuestionTypography>性別が男性</TableQuestionTypography>,
  ].map((q) => ({
    question: q,
    choices: [
      {
        text: "いいえ",
        point: 0,
      },
      {
        text: "はい",
        point: 1,
      },
    ],
  }));
  return (
    <MedicalFacilityReportLayout
      page={page}
      maxPage={maxPage}
      examinee={data.examinee}
    >
      <Stack sx={{ gap: "22px" }}>
        <SectionTitle text="睡眠時無呼吸症候群リスク：STOP-BANG尺度" />
        <Stack>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell padding="11px auto 11px 16px" width="312px">
                    質問
                  </TableHeaderCell>
                  <TableHeaderCell
                    textAlign="center"
                    padding="11px 0px"
                    width="55px"
                  >
                    回答
                  </TableHeaderCell>
                  <TableHeaderCell textAlign="left" width="312px">
                    選択肢
                  </TableHeaderCell>
                  <TableHeaderCell
                    textAlign="center"
                    padding="11px 0px"
                    width="50px"
                  >
                    点数
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <InterviewTableBody>
                {questions.map((q, i) => (
                  <QuestionRows
                    key={i}
                    question={q}
                    rowIndex={i}
                    choiceIndex={risk.choice_indices[i]}
                    rowHeight="41px"
                    choiceStyle={{
                      color: "#000",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Noto Sans JP",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "150%",
                      letterSpacing: "0.2px",
                    }}
                    pointStyle={{
                      color: "#000",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Noto Sans JP",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "150%",
                      letterSpacing: "0.2px",
                    }}
                  />
                ))}
                <InterviewSummaryTableRow
                  {...risk}
                  note="※合計3点以上でリスクあり"
                />
              </InterviewTableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" sx={{ m: "8px 10px" }}>
            <BasisOfDetermination basisAnnotations={risk.basis_annotations} />
          </Stack>
        </Stack>
      </Stack>
    </MedicalFacilityReportLayout>
  );
}
