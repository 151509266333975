import {
  QuestionGroup,
  Screen as QuestionScreen,
} from "@/components/examinee/interview/Types";

type PageType = "Single" | "Multiple";

export interface PageSummary {
  readonly screen: QuestionScreen;

  get pageTitle(): string;
  get progress(): number;
  get pageType(): PageType;
  get questionGroup(): QuestionGroup;
}

export class DailyInterviewPageSummary {
  readonly screen: QuestionScreen;

  get pageTitle(): string {
    return this.screen.question_group.category;
  }

  get progress(): number {
    return this.screen.progress;
  }

  get pageType(): PageType {
    const questionLength = this.screen.question_group.questions.length;
    return questionLength > 1 ? "Multiple" : "Single";
  }

  get questionGroup(): QuestionGroup {
    return this.screen.question_group;
  }

  constructor(screen: QuestionScreen) {
    this.screen = screen;
  }
}

export class PrimaryInterviewPageSummary extends DailyInterviewPageSummary {
  get progress(): number {
    return Math.round(this.screen.progress * 0.95);
  }
}
