import { SignInState } from "@/components/auth//Types";
import AuthLayout from "@/components/auth/AuthLayout";
import {
  ErrorMessageTranslator,
  standardTranslator,
} from "@/components/auth/ErrorMessageTranslator";
import NewPasswordChallenge from "@/components/auth/NewPasswordChallenge";
import { callInitiateAuthAPI, SIGNIN_TYPE } from "@/utils/auth";
import { getAxios, getFacilityApiServerUrl } from "@/utils/axios";
import { Alert, Box, Button, Grid, Link, TextField } from "@mui/material";
import { AxiosInstance } from "axios";
import { FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export type SignInProps = {
  axios: AxiosInstance;
  defaultSuccessTo: string;
  forgotPasswordTo?: string;
  usernameLabel: string;
  passwordLabel: string;
  translator?: ErrorMessageTranslator;
};
function SignIn(props: SignInProps) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [signInType, setSignInType] = useState<SIGNIN_TYPE>("init");
  const location = useLocation();
  const signInState = location.state as SignInState | null | undefined;
  const query = new URLSearchParams(location.search);
  const next = query.get("next") ?? props.defaultSuccessTo;

  const translator = props.translator ?? standardTranslator;
  const translateAndSetErrorMessage = (errorMessage: string) => {
    const translated = translator(errorMessage);
    setErrorMessage(translated);
  };
  const onSetSignInTypeHandler = (signInType: SIGNIN_TYPE) => {
    setSignInType(signInType);
  };

  const SignIn = () => {
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setErrorMessage("");
      const data = new FormData(event.currentTarget);
      const username = data.get("username")?.toString() ?? "";
      const password = data.get("password")?.toString() ?? "";
      try {
        const signInType = await callInitiateAuthAPI(
          props.axios,
          username,
          password
        );
        setSignInType(signInType);
        switch (signInType) {
          case "success":
            navigate(next);
            return;
          case "init":
            translateAndSetErrorMessage("Incorrect username or password.");
            return;
          case "newPasswordRequired":
            return;
        }
      } catch (e: any) {
        const res: any = e.response?.data;
        if (res == null) {
          translateAndSetErrorMessage("Incorrect username or password.");
          return;
        }

        if (Object.hasOwn(res, "cognito-error")) {
          translateAndSetErrorMessage("Incorrect username or password.");
        } else if (res.username) {
          translateAndSetErrorMessage(res.username[0]);
        } else if (res.password) {
          translateAndSetErrorMessage(res.password[0]);
        } else {
          translateAndSetErrorMessage("Incorrect username or password.");
        }
      }
    };

    return (
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label={props.usernameLabel}
          name="username"
          autoComplete="username"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label={props.passwordLabel}
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          ログイン
        </Button>

        {props.forgotPasswordTo != null && (
          <Grid container>
            <Grid item xs>
              <Link href={props.forgotPasswordTo} variant="body2">
                パスワードを忘れた場合
              </Link>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };

  return (
    <AuthLayout
      title={
        signInType === "init"
          ? "ログイン"
          : signInType === "newPasswordRequired"
          ? "パスワード設定"
          : "MFA Code"
      }
      errorMessage={errorMessage}
    >
      {signInState?.message != null && (
        <Alert severity="info">{signInState?.message}</Alert>
      )}
      {signInType === "init" ? (
        <SignIn />
      ) : (
        <NewPasswordChallenge
          axios={getAxios(getFacilityApiServerUrl())}
          defaultSuccessTo={next}
          setErrorMessage={translateAndSetErrorMessage}
          setSignInType={onSetSignInTypeHandler}
        />
      )}
    </AuthLayout>
  );
}
export default SignIn;
