import { Quality } from "@/components/report/Quality";
import {
  QualityResponse,
  ReportPageBaseProps,
} from "@/components/report/types";
import { useAxios } from "@/utils/axios";
import { usePageUserType } from "@/utils/router";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function QualityPage({ setIndicator }: ReportPageBaseProps) {
  const { uuid } = useParams();
  const userType = usePageUserType();
  const axios = useAxios();
  const [data, setData] = useState<QualityResponse | null>(null);
  useEffect(() => {
    (async () => {
      const response = await axios.get(`/api/report/${uuid}/quality`);
      setData(response.data);
      setIndicator(false);
    })();
  }, [axios, uuid, setIndicator]);

  if (uuid == null || data == null) return <></>;

  return <Quality data={data} userType={userType} uuid={uuid} />;
}
