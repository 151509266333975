import { ExternalLinkProps } from "@/components/examinee/ExternalLink";
import { Footer } from "@/components/examinee/Footer";
import { SxProps, Theme } from "@mui/material";

type ExamineeFooterProps = {
  sx?: SxProps<Theme>;
};
export function ExamineeFooter({ sx }: ExamineeFooterProps) {
  const FOOTER_LINKS: ExternalLinkProps[] = [
    {
      children: "利用規約",
      href: "/examinee/terms_of_service",
      target: "_blank",
    },
    {
      children: "サービスの位置付け",
      href: "/examinee/service_positioning",
      target: "_blank",
    },
    {
      children: "お問合せ",
      href: "https://docs.google.com/forms/d/e/1FAIpQLSfFP5qmDil0qpYu7Fjsy7Iw9-GrH7e-2vOLykBw5tRhzcGSnQ/viewform",
      target: "_blank",
    },
  ];

  return <Footer sx={sx} links={FOOTER_LINKS} />;
}
