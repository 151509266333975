import { useAxios } from "@/utils/axios";
import { useMemo } from "react";
import {
  RecommendationExistenceInfo,
  RecommendationInfo,
  TotalGradeInfo,
} from "./commons";

/**
 * 疾患リスクレポートに関連する API
 * @see https://www.notion.so/accelstars/API-72246d44cbac4ec68593281ee55e3dea?pvs=4#e6744e6f5a9c4c8c83f54bf4403fd6e5
 * @returns 疾患リスクレポートAPI
 */
export function useRecommendationAPI() {
  const axios = useAxios();
  return useMemo(
    () => ({
      /**
       * レポートに記載する情報を取得するAPI
       * @param uuid 取得対象UUID
       * @returns RecommendationInfo
       * @see https://www.notion.so/accelstars/API-72246d44cbac4ec68593281ee55e3dea?pvs=4#e6744e6f5a9c4c8c83f54bf4403fd6e5
       */
      get: async (uuid: string) =>
        await axios.get<RecommendationInfo>(`/api/recommendation/${uuid}/`),
    }),
    [axios]
  );
}

/**
 * 受診勧奨レポートを利用するかどうかを取得するAPI
 * @see https://www.notion.so/accelstars/API-72246d44cbac4ec68593281ee55e3dea?pvs=4#6a71625326d04420bc1a840817e8176e
 * @returns 受診勧奨レポートを利用するかどうかを取得するAPI
 */
export function useRecommendationExistenceAPI() {
  const axios = useAxios();
  return useMemo(
    () => ({
      /**
       * 受診勧奨レポートを利用するかどうかを取得するAPI
       *
       * @param uuid 取得対象UUID
       * @returns RecommendationExistenceInfo
       * @see https://www.notion.so/accelstars/API-72246d44cbac4ec68593281ee55e3dea?pvs=4#6a71625326d04420bc1a840817e8176e
       */
      get: async (uuid: string) =>
        await axios.get<RecommendationExistenceInfo>(
          `/api/recommendation_existence/${uuid}/`
        ),
    }),
    [axios]
  );
}

/**
 * 総合判定リスト API
 * @see https://www.notion.so/accelstars/backend-Web-TOP-0541822c06fb45fe9370212511d283dc
 * @returns Web レポート総合判定の判定一覧を取得する API
 */
export function useSleepImprovementNeededListAPI() {
  const axios = useAxios();
  return useMemo(
    () => ({
      /**
       * 総合判定リスト API
       *
       * @param uuid 取得対象UUID
       * @returns TotalGradeInfo[]
       * @see https://www.notion.so/accelstars/backend-Web-TOP-0541822c06fb45fe9370212511d283dc
       */
      get: async (uuid: string) =>
        await axios.get<TotalGradeInfo[]>(
          `/api/sleep_improvement_needed_list/${uuid}/`
        ),
    }),
    [axios]
  );
}
