import ExamineeReport from "./ExamineeReport";
import MedicalFacilityReport from "./MedicalFacilityReport";
import {
  DiseaseRiskCommonProps,
  RecommendationExistenceInfo,
  hasDiseaseRisk,
  hasDiseaseRiskOption,
} from "./commons";

/**
 * {@link DiseaseRiskReport} のプロパティ
 */
export interface DiseaseRiskReportProps extends DiseaseRiskCommonProps {
  /** {@link RecommendationExistenceInfo} */
  existence: RecommendationExistenceInfo;
}
/**
 * 疾患リスクレポート
 *
 * @param param0 {@link DiseaseRiskReportProps}
 * @returns 疾患リスクレポート
 */
export default function DiseaseRiskReport({
  data,
  existence,
}: DiseaseRiskReportProps) {
  // 疾患リスクレポートを表示しない場合、非表示
  if (!hasDiseaseRiskOption(existence)) return <></>;
  const hasRisk = hasDiseaseRisk(existence);
  return (
    <>
      <ExamineeReport data={data} existence={existence} />
      {/** いずれかの疾患リスクがある場合、医療機関用レポートを表示 */}
      {hasRisk && <MedicalFacilityReport data={data} existence={existence} />}
    </>
  );
}
