import { ImportCSVButton } from "@/pages/facility/sleep_checkup_v1/SleepCheckupList";
import { ButtonProps, Stack, Typography } from "@mui/material";
import { AddButton } from "../common/AddButton";

interface TutorialProps {
  minWidth: number;
  onClickAddNewSleepCheckup: () => void;
  onClickImportCSV: ButtonProps["onClick"];
}
export const Tutorial = ({
  minWidth,
  onClickAddNewSleepCheckup,
  onClickImportCSV,
}: TutorialProps) => {
  return (
    <Stack spacing={10} sx={{ my: 20, minWidth: minWidth }}>
      <Typography variant="h3" align="center" sx={{ mb: 6 }}>
        SLEEP COMPASSへようこそ！
        <br />
        さっそく最初の睡眠健康測定を登録しましょう
      </Typography>
      <Stack alignItems="center">
        <AddButton size="large" onClick={onClickAddNewSleepCheckup}>
          新しい測定を登録
        </AddButton>
      </Stack>
      <Stack alignItems="center">
        <Typography variant="body1" align="center">
          測定が登録されると、現在の画面に睡眠健康測定の一覧が表示されます
        </Typography>
        <img
          src="/img/tutorial_main_image.png"
          alt="画面イメージ"
          loading="lazy"
          style={{ width: minWidth }}
        />
      </Stack>
      <Stack spacing={4}>
        <Typography variant="body1" align="center">
          CSVを読み込むことで、複数の測定をまとめて登録することもできます
        </Typography>
        <Stack alignItems="center">
          <ImportCSVButton onClick={onClickImportCSV} size="large" />
        </Stack>
      </Stack>
    </Stack>
  );
};
