import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import HelpButton from "../HelpButton";
import CriterionTableContainer, {
  Cells,
} from "./criterions/CriterionTableContainer";

export default function DaytimeConditionCriterionDialog() {
  return (
    <HelpButton
      dialogTitle="日中の状態の判定基準"
      dialogProps={{
        maxWidth: "lg",
      }}
    >
      <Stack sx={{ mb: 6 }}>
        <Typography variant="subtitle1">日中の状態</Typography>
        <Typography variant="body1">
          前日の睡眠の結果、日中の活動にどのような影響が現れたのかを、眠気・疲労を組み合わせて評価します。
        </Typography>
      </Stack>
      <CriterionTableContainer
        sx={{
          "& td.MuiTableCell-root:nth-child(2)": {
            backgroundColor: Cells.color.good,
          },
          "& td.MuiTableCell-root:nth-child(3)": {
            backgroundColor: Cells.color.bad,
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="subtitle2">判定の仕組み</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ boxSizing: "border-box", width: "200px" }}>
                <Typography variant="subtitle2">眠気</Typography>
                <Typography variant="caption">課題なし/課題あり</Typography>
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{ boxSizing: "border-box", width: "348px" }}
              >
                <Typography variant="body2">
                  2項目とも「課題なし」の場合
                </Typography>
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{ boxSizing: "border-box", width: "348px" }}
              >
                <Typography variant="body2">
                  1項目以上が「課題あり」の場合
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">疲労</Typography>
                <Typography variant="caption">課題なし/課題あり</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">判定</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">課題なし</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">課題あり</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CriterionTableContainer>
    </HelpButton>
  );
}
