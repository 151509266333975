import { useVideoStream } from "@/components/activation/useVideoStream";
import jsQR, { QRCode } from "jsqr";
import { Point } from "jsqr/dist/locator";
import { CanvasHTMLAttributes, useEffect, useRef } from "react";

export type Wrap = {
  lineWidth: number;
  strokeStyle: string | CanvasGradient | CanvasPattern;
  lineDash: Iterable<number>;
};

export type QRCodeScannerProps = CanvasHTMLAttributes<HTMLCanvasElement> & {
  // 読み取りON/OFF
  scan: boolean;
  // scan試行時間(秒)
  scanTime: number;
  // 検知した際にQRコードを装飾するスタイル
  wrap?: Wrap;
  // QRコードを検知した際に呼び出される関数
  onDetect: (data: string) => void;
  // エラー時に呼び出される関数
  onError?: (error: any) => void;
  // scan試行時間scanを試行するたびに呼び出される関数
  onTime: (round: number) => void;
};
export default function QRCodeScanner({
  scan,
  scanTime,
  wrap,
  onDetect,
  onError,
  onTime,
  ...canvasAttribute
}: QRCodeScannerProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const requestAnimationFrameId = useRef<number | null>(null);

  const { start, stop, stream, error: streamError } = useVideoStream();

  useEffect(() => {
    if (scan) {
      start();
    } else {
      stop();
    }
    return stop;
  }, [start, stop, scan]);

  useEffect(() => {
    if (streamError != null) {
      onError?.(streamError);
      return;
    }

    if (stream == null) {
      return;
    }

    if (videoRef.current == null || canvasRef.current == null) {
      return;
    }
    const videoElement = videoRef.current;
    const canvasElement = canvasRef.current;

    const ctx = getContext(canvasElement);
    if (ctx == null) {
      return;
    }

    const stopScan = () => {
      videoElement.srcObject = null;
      if (requestAnimationFrameId.current != null) {
        cancelAnimationFrame(requestAnimationFrameId.current);
        requestAnimationFrameId.current = null;
      }
    };

    const videoTrack = stream.getVideoTracks()[0];
    const settings = videoTrack.getSettings();
    const scanCount = calcScanCount(scanTime, settings.frameRate);

    let counter = 0;
    const scanning = () => {
      ++counter;
      if (scanCount > 0 && counter % scanCount === 0) {
        onTime(Math.floor(counter / scanCount));
        requestAnimationFrameId.current = requestAnimationFrame(scanning);
        return;
      }

      const imageData = render(ctx, videoElement);

      // 画像情報をライブラリに渡して、QRコードの読み取りを実施
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "invertFirst",
      });

      if (code != null && code.data.length > 0) {
        if (wrap != null) {
          drawWrap(wrap, ctx, code, imageData);
        }

        onDetect(code.data);
      }
      requestAnimationFrameId.current = requestAnimationFrame(scanning);
    };

    videoElement.srcObject = stream;
    videoElement.oncanplay = () => {
      videoElement.play();
      requestAnimationFrameId.current = requestAnimationFrame(scanning);
    };

    return stopScan;
  }, [stream, streamError, scanTime, wrap, onDetect, onTime, onError]);

  return (
    <>
      <video ref={videoRef} muted autoPlay playsInline hidden />
      <canvas {...canvasAttribute} ref={canvasRef} />
    </>
  );
}

function calcScanCount(
  scanTime: number,
  // frameRateのデフォルト引数は、MediaTrackSettingsからframeRateを取得できなかったケースを想定して指定。frameRate(fps)を取得できなかったときは、30にする。
  frameRate: number = 30
): number {
  return frameRate * scanTime;
}

function render(
  ctx: CanvasRenderingContext2D,
  src: HTMLVideoElement
): ImageData {
  const [ctxWidth, ctxHeight] = [ctx.canvas.width, ctx.canvas.height];
  const [srcWidth, srcHeight] = [src.videoWidth, src.videoHeight];

  if (ctxWidth > srcWidth || ctxHeight > srcHeight) {
    throw Error(
      `Not supported, ctx: ${ctxWidth} x ${ctxHeight}, src: ${srcWidth} x ${srcHeight}`
    );
  }

  const [sw, sh] = getAspectFitSize(srcWidth, srcHeight, ctxWidth, ctxHeight);
  const [sx, sy] = getDisplacement(srcWidth, srcHeight, sw, sh);

  // 動画情報をcanvasへ描画
  ctx.drawImage(src, sx, sy, sw, sh, 0, 0, ctxWidth, ctxHeight);

  const [targetWidth, targetHeight] = [
    Math.ceil(ctxWidth * 0.6),
    Math.ceil(ctxHeight * 0.4),
  ];

  const [x, y] = getDisplacement(
    ctxWidth,
    ctxHeight,
    targetWidth,
    targetHeight
  );

  // canvas から中心付近の画像を切り出し、QRコードを検収する。画像が小さい方が検出にかかる時間が短くなるため。
  return ctx.getImageData(x, y, targetWidth, targetHeight);
}

// dw,dhのアスペクト比を保ちながら、sw,shの中で最大のサイズを返す
function getAspectFitSize(
  sw: number,
  sh: number,
  dw: number,
  dh: number
): [number, number] {
  const sAspectRatio = sh / sw;
  const dAspectRatio = dh / dw;

  if (sAspectRatio >= dAspectRatio) {
    // 幅に合わせてwidth,heightを求める
    return [sw, Math.floor(sw * dAspectRatio)];
  } else {
    // 高さに合わせてwidth,heightを求める
    return [Math.floor(sh / dAspectRatio), sh];
  }
}

function getContext(
  canvas: HTMLCanvasElement
): CanvasRenderingContext2D | null {
  const ctx = canvas.getContext("2d", { willReadFrequently: true });

  if (ctx == null) {
    return null;
  }

  ctx.setTransform(1, 0, 0, 1, 0, 0);
  const SCALE = 1;
  ctx.scale(SCALE, SCALE);

  return ctx;
}

function drawWrap(
  wrap: Wrap,
  ctx: CanvasRenderingContext2D,
  code: QRCode,
  imageData: ImageData
) {
  setWrapStyleToContext(ctx, wrap);

  const [dx, dy] = getDisplacement(
    ctx.canvas.width,
    ctx.canvas.height,
    imageData.width,
    imageData.height
  );

  const codeRectOnCtx = moveRect({ ...code.location }, dx, dy);

  wrapLine(ctx, codeRectOnCtx);
}

function setWrapStyleToContext(ctx: CanvasRenderingContext2D, wrap: Wrap) {
  ctx.setLineDash(wrap.lineDash);
  ctx.lineWidth = wrap.lineWidth;
  ctx.strokeStyle = wrap.strokeStyle;
}

interface Rect {
  topLeftCorner: Point;
  topRightCorner: Point;
  bottomLeftCorner: Point;
  bottomRightCorner: Point;
}

function wrapLine(ctx: CanvasRenderingContext2D, rect: Rect) {
  const drawLine = (from: Point, to: Point) => {
    ctx.beginPath();
    ctx.moveTo(from.x, from.y);
    ctx.lineTo(to.x, to.y);
    ctx.stroke();
  };
  drawLine(rect.topLeftCorner, rect.topRightCorner);
  drawLine(rect.topRightCorner, rect.bottomRightCorner);
  drawLine(rect.bottomRightCorner, rect.bottomLeftCorner);
  drawLine(rect.bottomLeftCorner, rect.topLeftCorner);
}

function getDisplacement(
  width: number,
  height: number,
  lWidth: number,
  lHeight: number
): [number, number] {
  const [wDiff, hDiff] = [Math.abs(width - lWidth), Math.abs(height - lHeight)];
  return [Math.floor(wDiff / 2), Math.floor(hDiff / 2)];
}

function moveRect(rect: Rect, dx: number, dy: number): Rect {
  return {
    topLeftCorner: movePoint(rect.topLeftCorner, dx, dy),
    topRightCorner: movePoint(rect.topRightCorner, dx, dy),
    bottomRightCorner: movePoint(rect.bottomRightCorner, dx, dy),
    bottomLeftCorner: movePoint(rect.bottomLeftCorner, dx, dy),
  };
}

function movePoint(point: Point, dx: number, dy: number): Point {
  return { x: point.x + dx, y: point.y + dy };
}
