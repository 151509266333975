import { Stack, Typography } from "@mui/material";
import { JudgeType } from "../types";

const CONFIG: Readonly<
  Record<
    JudgeType,
    {
      defaultTitle: string;
      message: string;
      backgroundColor: string;
      color: string;
    }
  >
> = {
  alert: {
    defaultTitle: "警戒",
    message: "改善に取り組みましょう",
    backgroundColor: "#ffe7e6",
    color: "error.main",
  },
  caution: {
    defaultTitle: "注意",
    message: "気をつけて過ごしましょう",
    backgroundColor: "#fffaf1",
    color: "#ff910b",
  },
  good: {
    defaultTitle: "良好",
    message: "この調子で続けましょう",
    backgroundColor: "#ebf2f8",
    color: "#0056a0",
  },
  unknown: {
    defaultTitle: "判定不能",
    message: "測定データ不足です",
    backgroundColor: "#0000001F",
    color: "#707070",
  },
};

export default function Judge({
  judge,
  title,
}: {
  judge: JudgeType;
  title?: string;
}) {
  const config = CONFIG[judge];
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        height: "58px",
        borderRadius: "50px",
        backgroundColor: config.backgroundColor,
        alignItems: "center",
        p: "0px 32px 0px 32px",
      }}
    >
      <Typography variant="h6" sx={{ color: config.color }}>
        {title ?? config.defaultTitle}
      </Typography>
      <Typography variant="body1" sx={{ color: config.color }}>
        {config.message}
      </Typography>
    </Stack>
  );
}
