import { NonFieldError, SuccessAlert } from "@/components/common/SCAlert";
import { SCLink } from "@/components/common/SCLink";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import {
  EmailAddress,
  EmailAddressValidationError,
} from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import {
  Password,
  PasswordLenientValidationError,
} from "@/sleep_compass_lite/domain_models/target/Password";
import { StateType as ForgotPasswordState } from "@/sleep_compass_lite/pages/target/authentication/ForgotPassword";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { signIn } from "@/sleep_compass_lite/use_cases/target/authentication/SignIn";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  successMessage: string;
};

export function SignIn() {
  const location = useLocation();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState<EmailAddress>(new EmailAddress(""));
  const [password, setPassword] = useState<Password>(new Password(""));

  const EMAIL_VALIDATION_ERROR_MESSAGES: Map<
    EmailAddressValidationError,
    string
  > = new Map([["FormatError", "メールアドレスの形式が正しくありません"]]);
  const PASSWORD_VALIDATION_ERROR_MESSAGES: Map<
    PasswordLenientValidationError,
    string
  > = new Map([["InvalidCharsError", "使用できない文字が含まれています"]]);

  const passwordValidationErrors = password.lenientValidate();
  const emailValidationErrors = email.validate();
  const hasErrors =
    passwordValidationErrors.length !== 0 || emailValidationErrors.length !== 0;

  const { successMessage } = (location.state as StateType) || {};
  const query = new URLSearchParams(location.search);
  const nextPath =
    query.get("next") ?? authenticationPath.getFullPath("SignInSucceeded");

  const handleClickLogin = async () => {
    if (hasErrors) {
      // 何かしらのエラーがある場合はログイン処理を行わない
      return;
    }

    try {
      await signIn(email, password);
      navigate(nextPath);
    } catch (e: unknown) {
      setErrorMessage(getErrorMessage(e));
      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    }
  };

  return (
    <Stack spacing={12} sx={{ pt: 8, pb: 16, px: PX4 }}>
      <Stack spacing={6}>
        <Typography variant="h6" align="center">
          ログイン
        </Typography>
        {/* パスワード再設定直後に表示されるメッセージ */}
        <SuccessAlert>{successMessage}</SuccessAlert>
        {/* エラーメッセージ */}
        <NonFieldError>{errorMessage}</NonFieldError>
        <TextForm
          title="メールアドレス"
          placeholder="例：sleep-compass@example.com"
          value={email.value}
          onChange={(e) => {
            setEmail(new EmailAddress(e.target.value));
          }}
          validator={() =>
            emailValidationErrors.map(
              (e) => `※${EMAIL_VALIDATION_ERROR_MESSAGES.get(e)}`
            )
          }
        />
        <TextForm
          title="パスワード"
          value={password.value}
          onChange={(e) => {
            setPassword(new Password(e.target.value));
          }}
          type="password"
          givenErrors={passwordValidationErrors
            .filter((e) => e !== "EmptyError")
            .map((e) => `※${PASSWORD_VALIDATION_ERROR_MESSAGES.get(e)}`)}
        />
      </Stack>
      <Stack spacing={4}>
        <Button
          variant="contained"
          size="large"
          sx={{ boxShadow: "none" }}
          onClick={handleClickLogin}
          disabled={hasErrors}
        >
          ログイン
        </Button>
        <SCLink
          variant="body2"
          to={authenticationPath.getFullPath("ForgotPassword")}
          state={
            { isSecondUser: false, surveyInfo: null } as ForgotPasswordState
          }
          sx={{ color: "text.secondary" }}
        >
          パスワードを忘れた場合
        </SCLink>
      </Stack>
    </Stack>
  );
}
