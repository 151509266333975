import { LifestyleAndHealthConditionType } from "./types";

export const LIFESTYLE_AND_HEALTH_CONDITION_CONFIG: Record<
  LifestyleAndHealthConditionType,
  { title: string; image: string }
> = {
  body_condition: {
    title: "体の調子",
    image: "/img/image_report_lifestyle_detail_body_condition.png",
  },
  mind_condition: {
    title: "こころの調子",
    image: "/img/image_report_lifestyle_detail_mind_condition.png",
  },
  before_sleep_mind: {
    title: "寝る前の気分",
    image: "/img/image_report_lifestyle_detail_before_sleep_mind.png",
  },
  light: {
    title: "部屋の明かり",
    image: "/img/image_report_lifestyle_detail_light.png",
  },
  bluelight: {
    title: "ブルーライト",
    image: "/img/image_report_lifestyle_detail_bluelight.png",
  },
  bath: {
    title: "入浴",
    image: "/img/image_report_lifestyle_detail_bath.png",
  },
  nap: {
    title: "仮眠",
    image: "/img/image_report_lifestyle_detail_nap.png",
  },
  exercise: {
    title: "運動",
    image: "/img/image_report_lifestyle_detail_exercise.png",
  },
  nightmeal: {
    title: "夜食",
    image: "/img/image_report_lifestyle_detail_nightmeal.png",
  },
  caffeine: {
    title: "カフェイン",
    image: "/img/image_report_lifestyle_detail_caffeine.png",
  },
  alcohol: {
    title: "アルコール",
    image: "/img/image_report_lifestyle_detail_alcohol.png",
  },
  nothing: {
    title: "課題無し",
    image: "/img/image_report_lifestyle_detail_nothing.png",
  },
};
