import HelpButton from "../HelpButton";
import { Reference } from "./commons/Reference";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function K6HelpDialog() {
  return (
    <HelpButton dialogTitle="K6の説明">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "K6とは",
            content:
              "抑うつ・不安を測定する6項目、5件法の尺度であり、過去1ヵ月間の抑うつ、不安症状を評価します。スコア範囲は0~24点であり、重症精神障害を予測するカットオフ点として 13 点以上 が、また一般住民の心理ストレスを評価するカットオフ点として 5 点以上が提案されています。",
            additionalContent: (
              <Reference>
                うつ病スクリーニング（日本プライマリ・ケア連合学会）
              </Reference>
            ),
          },
        ]}
      />
    </HelpButton>
  );
}
