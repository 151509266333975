import { Copyright } from "@/components/examinee/Copyright";
import { Button, Stack } from "@mui/material";
import LifestyleAndHealthConditionCard from "../commons/LifestyleAndHealthConditionCard";
import { ReportImg } from "../commons/ReportImg";
import { useSummaryPageContext } from "./Summary";
import { TeacherComment } from "./commons";

/**
 * 睡眠の長さ
 *
 * @param param0
 * @returns
 */
export default function PointsOfImprovement() {
  const context = useSummaryPageContext();
  return (
    <Stack
      sx={{
        height: "100%",
      }}
    >
      <Stack
        sx={{
          background: "linear-gradient(#00A99D, #F6F5F4)",
          p: "0px 16px",
          gap: "24px",
        }}
      >
        <ReportImg image_name="image_report_summary_points_of_improvement.svg" />
        {context.props.data.points_of_improvement?.map((d, i) => {
          return (
            <LifestyleAndHealthConditionCard
              key={i}
              type={d.type}
              comment={d.comment}
            />
          );
        })}
      </Stack>
      <Stack sx={{ p: "16px" }}>
        <TeacherComment>
          まずはこれらの項目を改善することから始めてみましょう。
        </TeacherComment>
      </Stack>
      <Stack
        sx={{
          pt: "16px",
          px: "16px",
          pb: "10px",
          mb: "80px",
          position: "sticky",
          bottom: 0,
          zIndex: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{ boxShadow: "none" }}
          onClick={() => {
            context.setScreenType("sleep_rhythm");
          }}
        >
          次へ
        </Button>
      </Stack>
      <Stack
        sx={{
          p: "32px 16px 32px 16px",
          position: "sticky",
          alignItems: "center",
        }}
      >
        <Copyright />
      </Stack>
    </Stack>
  );
}
