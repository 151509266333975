export class Path<PageType extends string> {
  readonly parent: string;
  readonly table: Record<PageType, string>;

  constructor(parent: string, table: Record<PageType, string>) {
    this.parent = parent;
    this.table = table;
  }

  getPath(key: PageType, param?: Record<string, string>): string {
    let path = this.table[key];
    if (param != null) {
      for (const [k, v] of Object.entries(param)) {
        path = path.replace(k, v);
      }
      return path;
    } else {
      return path;
    }
  }

  getFullPath(
    key: PageType,
    param?: Record<string, string>,
    urlParams?: URLSearchParams
  ): string {
    const path = `${this.parent}${this.getPath(key, param)}`;
    if (urlParams == null) {
      return path;
    }
    return `${path}?${urlParams.toString()}`;
  }
}
