import { SuccessAlert } from "@/components/common/SCAlert";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { Box } from "@mui/material";

export function SignInSucceeded() {
  return (
    <Box sx={{ pt: 8, px: PX4 }}>
      <SuccessAlert>
        ログインに成功しました。適切なURLにアクセスしてください。
      </SuccessAlert>
    </Box>
  );
}
