import {
  Button,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TotalGradeInfo } from "../disease_risk/commons";
import ReportDialog from "./ReportDialog";

/**
 * 判定結果一覧ダイアログ
 */
export function ResultListDialog({
  open,
  setOpen,
  totalGrandeInfos,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  totalGrandeInfos: TotalGradeInfo[];
}) {
  return (
    <ReportDialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <Stack sx={{ width: "320px" }}>
        <Stack sx={{ alignItems: "center", p: "16px" }}>
          <Typography variant="h6" sx={{ color: "primary.dark" }}>
            判定一覧
          </Typography>
        </Stack>
        <Stack sx={{ p: "0 16px 24px 16px" }}>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ textAlign: "center", width: "48px", p: "8px" }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ color: "text.primary" }}
                    >
                      判定
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: "8px", px: "16px" }}>
                    <Typography
                      variant="caption"
                      sx={{ color: "text.primary" }}
                    >
                      睡眠改善必要度
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {totalGrandeInfos.map((gradeInfo, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography
                          variant="body2"
                          sx={{ color: "text.primary" }}
                        >
                          {gradeInfo.grade}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          sx={{ color: "text.primary" }}
                        >
                          {gradeInfo.comment}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack sx={{ pt: "24px" }}>
            <Typography variant="subtitle2">
              判定に*がついている場合：
            </Typography>
            <Typography variant="caption">
              睡眠に関して治療中のため、かかりつけの医師のもとで治療を継続しましょう。本レポートをかかりつけ医に共有しても問題ありませんが、本計測は日常的に良質な睡眠がとれているかを測定しているため、あくまで参考情報となります。治療はかかりつけ医の指示に従ってください。
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack sx={{ p: "16px" }}>
          <Button
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            閉じる
          </Button>
        </Stack>
      </Stack>
    </ReportDialog>
  );
}
