import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, Collapse, Divider, Stack } from "@mui/material";
import { ReactNode, useState } from "react";

/**
 * {@link ReportShowMore} のプロパティ
 */
export type ReportShowMoreProps = {
  /** 表示、非表示を切り替える対象の {@link ReactNode} */
  children: ReactNode;
};
/**
 * レポートの表示／非表示を切り替えるコンポーネントです。
 *
 * @param param0 {@link ReportShowMoreProps}
 * @returns レポート表示／非表示コンポーネント
 */
export function ReportShowMore({ children }: ReportShowMoreProps) {
  const [open, setOpen] = useState(false);
  return (
    <Stack>
      <Button
        size="small"
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        sx={{ color: "#707070", justifyContent: "left", p: 0 }}
        onClick={() => setOpen((open) => !open)}
      >
        {open ? "表示を減らす" : "さらに表示"}
      </Button>
      <Collapse in={open}>
        <Divider sx={{ my: 4, border: "1px dashed rgba(0, 0, 0, 0.12)" }} />
        {children}
      </Collapse>
    </Stack>
  );
}
