import { ImgHTMLAttributes } from "react";

export type ReportImgProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  "src"
> & {
  image_name: string;
};

/**
 * レポート用のimageタグ
 * @param param0
 * @returns
 */
export function ReportImg({ image_name, ...props }: ReportImgProps) {
  return (
    <img
      loading="lazy"
      src={`/img/${image_name}`}
      alt={props.alt ? props.alt : image_name}
      {...props}
    />
  );
}
