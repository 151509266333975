import {
  EmphasisTypography,
  SubtitleTypography,
  SupplementTypography,
} from "@/components/common/Typographies";
import { ImageBox } from "@/components/elearning/chapter/Boxes";
import { Layout } from "@/components/elearning/chapter/Layout";
import { SubChapterStack } from "@/components/elearning/chapter/Stacks";
import { Box, Stack, Typography } from "@mui/material";

export function Chapter05() {
  return (
    <Layout title="スムーズに入眠しやすくするためのポイント">
      <SubChapterStack>
        <SubtitleTypography>体温下げて眠りの準備</SubtitleTypography>
        <Typography variant="body2">
          サーカディアンリズム機構は体温と深い関係があります。
          夜になるにつれて、脳や体内部の温度が下がって、眠る準備を始めます。
        </Typography>
        <Typography variant="body2">
          明け方に最低体温に達した後、徐々に体温が上がり始め、活動の準備を始めます。
        </Typography>
        <Typography variant="body2">
          眠る直前に熱いお風呂に入ると、体温が上がり交感神経が優位になり、スムーズな入眠を妨げます。「熱いお風呂がいい！」という場合は、寝る3時間前までに入リましょう。
        </Typography>
        <ImageBox src="/img/image_chapter5_1.png" alt="体温下げて眠りの準備" />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>眠くなってから寝床に入る</SubtitleTypography>
        <Typography variant="body2">
          眠れないのに眠ろうとすると、眠れないことがストレスになってしまいます。眠れないときは、音楽・香りを活用し、眠くなるのを待って寝床に入りましょう。また、睡眠時間を8時間にこだわらない事が大事です。
        </Typography>
        <ImageBox
          src="/img/image_chapter5_2.png"
          alt="眠くなってから寝床に入る"
        />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>寝床で悩み事をしない</SubtitleTypography>
        <Typography variant="body2">
          夜、疲れている時に悩んでも良い考えは浮かびません。「悩みごと帳」に「明日、○○について考える…」と書き込んで、ゆっくり休みましょう。
        </Typography>
        <ImageBox src="/img/image_chapter5_3.png" alt="寝床で悩み事をしない" />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>カフェインを摂らない</SubtitleTypography>
        <Typography variant="body2">
          体内に入ったカフェインは分解されるのに5～7時間かかります。
          コーヒー、栄養ドリンク剤、チョコ、コーラを楽しむのは、寝る5～7時間前までにしましょう。
        </Typography>
        <ImageBox src="/img/image_chapter5_4.png" alt="カフェインを摂らない" />
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>
          寝る1時間前は部屋の明かりを少し落とす
        </SubtitleTypography>
        <ImageBox src="/img/image_chapter5_5.png" alt="短い昼寝" />
        <Typography variant="body2">
          夜になっても明るい光を浴びていると、脳や交感神経活動が高まり、スムーズな入眠を妨げます。また、体が夜を感じると「メラトニン（眠りを安定させる睡眠物質）」が分泌されます。
        </Typography>
        <Typography variant="body2">
          このメラトニンという物質は、起床から14~15時間後に分泌されますが、夜遅くまで明るい環境等に居ると、光によってメラトニンが出にくくなリ、睡眠が得られなくなります。
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            component="img"
            src="/img/image_chapter5_6.png"
            alt="パソコン・携帯電話・スマホ等の使用"
            loading="lazy"
            sx={{
              width: "80px",
              height: "auto",
              margin: "auto",
              display: "block",
            }}
          />
        </Stack>
        <Typography variant="body2">
          寝る1時間前は、パソコン・携帯電話・スマホ等の使用を減らし、明るい場所への外出は控えましょう。
        </Typography>
        <ImageBox src="/img/image_chapter5_7.png" alt="短い昼寝" />
        <Typography variant="body2">
          ブルーライト（白色・青色LED照明）を控え、暖色照明・間接照明等で部屋の明かりを半分ほどに抑えましょう。
        </Typography>
        <SupplementTypography>
          ※スマートフォンなどから出るブルーライトは角膜や水晶体で吸収されずに目の奥まで届く強い光です。
        </SupplementTypography>
        <EmphasisTypography>
          朝にブルーライト（太陽の光にも含まれます。）を目に取り込むと、スッキリと目覚めることができます。
        </EmphasisTypography>
      </SubChapterStack>
      <SubChapterStack>
        <SubtitleTypography>
          就寝時間の8時間前からは居眠り・仮眠をしない！
        </SubtitleTypography>
        <Typography variant="body2">
          取りたい睡眠時間と同じだけ、就床前は、起きておくことが大事です。
          「23時に就床して、8時間ぐっすり眠りたい」場合は、23時から8時間前の15時以降はしっかり起き続けましょう。
        </Typography>
        <ImageBox
          src="/img/image_chapter5_8.png"
          alt="就寝時間の8時間前からは居眠り・仮眠をしない！"
        />
      </SubChapterStack>
    </Layout>
  );
}
