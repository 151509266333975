import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  IconButtonProps,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { Property } from "csstype";
import { MouseEvent, ReactNode } from "react";

/**
 * 検索バー。文字列を直接入力できないように制御がかかっているので注意。ポップアップを表示するためのボタン代わりに使われる想定。
 */
type SearchBarProps = {
  sx?: SxProps<Theme>;
  value?: TextFieldProps["value"];
  color: Property.Color;
  onClick: () => void;
  onClickCancel?: () => void;
};
export function SearchBar({
  sx,
  value,
  color,
  onClick,
  onClickCancel,
}: SearchBarProps) {
  const cancelHandler = (event: MouseEvent<HTMLButtonElement>) => {
    // Note: キャンセルボタンが押された時に、テキストフィールドのonClickイベントが発火しないように、イベントの伝搬をstopする。
    event.stopPropagation();

    if (onClickCancel == null) {
      return;
    }
    onClickCancel();
  };

  const endAdornment: ReactNode =
    onClickCancel != null ? <CancelButton onClick={cancelHandler} /> : null;

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: color }} />
          </InputAdornment>
        ),
        endAdornment: endAdornment,
        readOnly: true,
      }}
      sx={{
        backgroundColor: "black.1",
        "& .MuiInputBase-root": {
          fontSize: "14px",
          lineHeight: "24px",
          color: color,
        },
        ...sx,
      }}
      onClick={onClick}
      value={value}
    />
  );
}

type CancelButtonProps = {
  onClick: IconButtonProps["onClick"];
};
function CancelButton({ onClick }: CancelButtonProps) {
  return (
    <IconButton onClick={onClick}>
      <CancelIcon sx={{ color: "black.36" }} />
    </IconButton>
  );
}
