import { getExamineeAxios } from "@/utils/axios";

export const REGISTRATION_METHOD_SCAN = 0;
export const REGISTRATION_METHOD_INPUT = 1;

export function postDeviceIdRegistration(
  deviceId: string,
  registrationMethod: number,
  sleepCheckUpId: string,
  currentPath: string
) {
  const param = {
    device_id: deviceId,
    registration_method: registrationMethod,
    sleep_checkup_info: sleepCheckUpId,
  };

  return getExamineeAxios(currentPath).post(
    `/api/device_id_registration/`,
    param
  );
}
