import { getPrimaryInterview } from "@/components/examinee/interview/InterviewAPI";
import { InterviewEntryLoader } from "@/components/examinee/interview/InterviewEntryLoader";
import { paths } from "@/components/examinee/interview/Path";
import { InterviewEntry } from "@/components/examinee/interview/Types";
import { Params } from "react-router-dom";

export function PrimaryInterviewEntryLoader() {
  return <InterviewEntryLoader getEntry={getEntry} getPath={getPath} />;
}

function getEntry(params: Readonly<Params<string>>, currentPath: string) {
  if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
    throw new Error("sleepCheckupId is not assigned");
  }

  return getPrimaryInterview(params.sleepCheckupId, currentPath);
}

function getPath(entry: InterviewEntry, params: Params) {
  if (params.sleepCheckupId == null || params.sleepCheckupId === undefined) {
    throw new Error("sleepCheckupId is not assigned");
  }
  if (entry.screen.id == null) {
    throw new Error("remindId is not assigned");
  }

  return paths.getFullPath("PrimaryInterview", {
    ":sleepCheckupId": params.sleepCheckupId,
    ":interviewId": String(entry.id),
    ":screen": String(entry.screen.id),
  });
}
