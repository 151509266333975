export function toStringYYYYMMdd(date: Date, separator: string): string {
  const Y = date.getFullYear().toString().padStart(4, "0");
  const M = padding(date.getMonth() + 1);
  const d = padding(date.getDate());
  return `${Y}${separator}${M}${separator}${d}`;
}

export function toStringMMdd(date: Date, separator: string): string {
  const M = padding(date.getMonth() + 1);
  const d = padding(date.getDate());
  return `${M}${separator}${d}`;
}

/**
 * 引数dtを月日を示す文字列にして返す(toStringMMddとの違いは、パティングの有無)
 * @param date 文字列に変換する対象日
 * @param separator 文字列に変換する際に月と日の間に入れる文字
 * @returns 月日を示す文字列
 */
export function toStringMd(date: Date, separator: string): string {
  return `${date.getMonth() + 1}${separator}${date.getDate()}`;
}

export function toStringDayOfWeek(date: Date): string {
  return ["日", "月", "火", "水", "木", "金", "土"][date.getDay()]; // day of weak
}

export function toStringJP(date: Date): string {
  const m = date.getMonth() + 1;
  const d = date.getDate();
  return `${m}月${d}日`;
}

export function toIsoString(date: Date): string {
  const M = padding(date.getMonth() + 1);
  const d = padding(date.getDate());
  const h = padding(date.getHours());
  const m = padding(date.getMinutes());
  const s = padding(date.getSeconds());
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const tzh = padding(Math.floor(Math.abs(tzo) / 60));
  const tzm = padding(Math.abs(tzo) % 60);
  return `${date.getFullYear()}-${M}-${d}T${h}:${m}:${s}${dif}${tzh}:${tzm}`;
}

export function padding(num: number) {
  return (num < 10 ? "0" : "") + num;
}

/**
 * 与えられた YYYY-mm-dd あるいは YYYY/mm/dd 形式の文字列が日付として正しいかどうかをチェックする。
 * 正しければ true を返す。
 */
export const isValidDateString = (s: string): boolean => {
  const match = s.match(/^(\d{4})[-/](\d{2})[-/](\d{1,2})$/); // dayは1桁でもOK
  if (match == null) {
    return false;
  }
  const [, year, month, day] = match.map((n) => parseInt(n));
  const date = new Date(year, month - 1, day);
  if (
    date.getFullYear() !== year ||
    date.getMonth() + 1 !== month ||
    date.getDate() !== day
  ) {
    return false;
  }
  return true;
};

/**
 * YYYYmmdd形式の日付が誕生日として正しいかどうかをチェックする。
 * 正しくない場合はエラーメッセージを返す。
 */
export const validateBirthday = (birthday: string): string[] | null => {
  if (!birthday.match(/^\d{8}$/)) {
    return ["※西暦から半角数字8桁で入力してください"];
  }
  const year = parseInt(birthday.slice(0, 4));
  const month = parseInt(birthday.slice(4, 6));
  const day = parseInt(birthday.slice(6, 8));
  const date = new Date(year, month - 1, day);
  if (
    date.getFullYear() !== year ||
    date.getMonth() + 1 !== month ||
    date.getDate() !== day
  ) {
    return ["※正しい日付を入力してください"];
  }
  const now = new Date();
  if (now <= date) {
    return ["※未来の日付は入力できません"];
  }
  return null;
};

/**
 * Invalid Date か判定します。
 *
 * @param date
 * @returns Invalid Date の場合、true
 */
export function isInvalidDate(date: Date) {
  return Number.isNaN(date.getTime());
}

/**
 * 分を時間+分の単位に変更する
 *
 * @param time
 * @returns
 */
export function formatDisplayTime(time: number) {
  const hours = Math.floor(time / 60);
  const minutes = Math.floor(time % 60);

  if (hours && minutes) return `${hours}時間${minutes}分`;
  else if (!hours && minutes) return `${minutes}分`;
  else if (hours && !minutes) return `${hours}時間`;
  else return "0分";
}
