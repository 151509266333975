import { SleepCheckupUserStore, signOut } from "@/utils/auth";
import constants from "@/utils/constants";
import { Add, AssignmentOutlined, Close, Face } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Link,
  Stack,
  Tab,
  TableContainerProps,
  Tabs,
  Typography,
  TypographyProps,
} from "@mui/material";
import { format, parse } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TableContainer } from "../report/disease_risk/medical_facility_report/commons";
import {
  AccountMenuButton,
  AccountMenuList,
  SCAppBar,
} from "../sleep_checkup_v1/SCAppBar";
import FactAndComments from "./FactAndComments";
import Plans from "./Plans";
import Retrospectives from "./Retrospectives";
import SleepSummaries from "./SleepSummaries";
import { useUserInfo } from "./apis";
import { UserInfo } from "./commons/user_infos";

const MIN_WIDTH = 1098;

/** タブ種別 */
type TabType =
  | "sleep_summaries"
  | "retrospectives"
  | "fact_and_comments"
  | "plan";

/**
 * ワークシート画面
 * @returns ワークシート画面
 */
export default function Worksheet() {
  const { uuid, tab = "sleep_summaries" } = useParams<{
    uuid: string;
    tab: string;
  }>();
  // URL から該当タブのキーを取得
  const tabContent = (
    {
      sleep_summaries: () => <SleepSummaries />,
      retrospectives: () => <Retrospectives />,
      fact_and_comments: () => <FactAndComments />,
      plan: () => <Plans />,
    }[tab] ?? (() => null)
  )();

  const api = useUserInfo();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [showExamineeInfo, setShowExamineeInfo] = useState(false);
  const navigate = useNavigate();

  const onChangeTab = useCallback(
    (_: React.SyntheticEvent, newTab: TabType) => {
      navigate(`/facility/worksheet/${uuid}/${newTab}`);
    },
    [uuid, navigate]
  );

  const getMeasurementPeriod = useCallback(() => {
    const currentMeasurement = userInfo?.measurement_history[0];
    if (currentMeasurement == null) return "";

    function fmt(date: string) {
      return date.replace(/(\d+)-(\d+)-(\d+).*/, "$1年$2月$3日");
    }

    return `${fmt(currentMeasurement.period_start_date)} 〜 ${fmt(
      currentMeasurement.period_end_date
    )}`;
  }, [userInfo]);

  useEffect(() => {
    (async () => {
      if (uuid == null) return;
      const res = await api.get(uuid);
      setUserInfo(res.data);
    })();
  }, [uuid, api]);

  return (
    <>
      <AppBar />
      <Stack
        sx={{
          position: "sticky",
          backgroundColor: "#FFFFFF",
          top: "68px",
          px: 10,
          py: 6,
          minWidth: MIN_WIDTH,
          zIndex: 10,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Typography variant="h3">睡眠改善ワークシート</Typography>
          {userInfo && (
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                ml: 2,
              }}
            >
              {` [ ${
                userInfo?.medical_examinee_name
              }│測定期間：${getMeasurementPeriod()} ] `}
            </Typography>
          )}
        </Stack>

        <Stack>
          <Tabs value={tab} onChange={onChangeTab}>
            <Tab value="sleep_summaries" label="睡眠評価"></Tab>
            <Tab value="retrospectives" label="原因分析"></Tab>
            <Tab value="fact_and_comments" label="週間グラフ"></Tab>
            <Tab value="plan" label="改善目標"></Tab>
          </Tabs>
        </Stack>
      </Stack>

      <Stack
        sx={{
          mx: 10,
          mb: 20,
          minWidth: MIN_WIDTH,
        }}
      >
        <Stack>
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              gap: 8,
            }}
          >
            {/* タブ内容 */}
            <Stack sx={{ width: "100%" }}>{tabContent}</Stack>
            {/* 受診者情報 */}
            <Stack>
              {!showExamineeInfo && (
                <ShowExamineeInfoButton
                  onClick={() => setShowExamineeInfo(true)}
                />
              )}
              {showExamineeInfo && (
                <ExamineeInfoCard
                  userInfo={userInfo}
                  onClickClose={() => setShowExamineeInfo(false)}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

const fmt = (ymd: string) =>
  format(parse(ymd, "yyyy-MM-dd", new Date()), "yyyy年M月d日");

function AppBar() {
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const navigate = useNavigate();
  const user = SleepCheckupUserStore.getItem();

  useEffect(() => {
    if (user == null) {
      // 他施設のログイン画面（サブドメ）にアクセスできないように、未ログインの場合はログイン画面にリダイレクトする
      navigate("/facility/signin");
    }
  }, [user, navigate]);

  const handleAccountMenuOpen = () => {
    setAccountMenuOpen(true);
  };

  const handleAccountMenuClose = () => {
    setAccountMenuOpen(false);
  };

  const handleLogout = () => {
    signOut();
    navigate(constants.SIGNIN_URL_MEDICAL_FACILITY_USER);
  };

  return (
    <SCAppBar
      accountMenuButton={
        <AccountMenuButton
          accountMenuList={<AccountMenuList onClickLogout={handleLogout} />}
          openMenu={accountMenuOpen}
          onClick={handleAccountMenuOpen}
          onClose={handleAccountMenuClose}
        >
          {user?.username}
        </AccountMenuButton>
      }
      minWidth={MIN_WIDTH}
    />
  );
}

interface ShowExamineeInfoButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
function ShowExamineeInfoButton({ onClick }: ShowExamineeInfoButtonProps) {
  return (
    <Button
      variant="contained"
      size="medium"
      color="primary"
      aria-label="show"
      onClick={onClick}
      sx={{
        "& .MuiButton-startIcon": {
          marginRight: 0,
          marginLeft: 0,
        },
        height: "auto",
        width: "68px",
        padding: "16px",
        borderRadius: "20px 0 0 20px",
        position: "fixed",
        top: "50%",
        left: "calc(100% - 68px)",
        transform: "translateY(-50%)",
      }}
    >
      <Typography sx={{ writingMode: "vertical-rl" }}>
        <Add fontSize="small" sx={{ m: "8px" }} />受 診 者 情 報 を 見 る
      </Typography>
    </Button>
  );
}

function ExamineeInfoSection({
  userInfo,
  onClickClose,
}: ExamineeInfoCardProps) {
  return (
    <Stack>
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Stack
          direction="row"
          sx={{
            gap: 2,
            alignItems: "center",
          }}
        >
          <Face />
          <Typography variant="subtitle2" sx={{ color: "#7A7A7A" }}>
            受診者情報
          </Typography>
        </Stack>
        <IconButton onClick={onClickClose}>
          <Close />
        </IconButton>
      </Stack>

      <Stack>
        <LabelTypography variant="caption">
          {userInfo?.medical_examinee_name_kana}
        </LabelTypography>
        <LabelTypography variant="h4">
          {userInfo?.medical_examinee_name}
        </LabelTypography>
        <table>
          <tbody>
            <tr>
              <td>
                <Typography variant="body2" sx={{ color: "#7A7A7A" }}>
                  生年月日
                </Typography>
              </td>
              <td>
                <LabelTypography variant="body2">
                  {`${userInfo?.medical_examinee_birthday}(${userInfo?.medical_examinee_age_at_sleep_checkup}歳)`}
                </LabelTypography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" sx={{ color: "#7A7A7A" }}>
                  企業名
                </Typography>
              </td>
              <td>
                <LabelTypography variant="body2">
                  {userInfo?.corporate_name}
                </LabelTypography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" sx={{ color: "#7A7A7A" }}>
                  部署名
                </Typography>
              </td>
              <td>
                <LabelTypography variant="body2">
                  {userInfo?.department_name}
                </LabelTypography>
              </td>
            </tr>
          </tbody>
        </table>
      </Stack>
    </Stack>
  );
}

function InterviewInfoSection({ userInfo }: UserInfoProps) {
  return (
    <Stack>
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Stack
          direction="row"
          sx={{
            gap: 2,
            alignItems: "center",
          }}
        >
          <AssignmentOutlined />
          <Typography variant="subtitle2" sx={{ color: "#7A7A7A" }}>
            問診情報
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          "&>table>tbody>tr>td:first-of-type": { width: "121.5px" },
          "&>table>tbody>tr>td:nth-of-type(2)": { verticalAlign: "top" },
        }}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <Typography variant="body2" sx={{ color: "#7A7A7A" }}>
                  既往歴
                </Typography>
              </td>
              <td>
                <LabelTypography variant="body2">
                  {userInfo?.interview_info.disease_besides_hypertension_name}
                </LabelTypography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" sx={{ color: "#7A7A7A" }}>
                  睡眠に関する既往歴
                </Typography>
              </td>
              <td>
                <LabelTypography variant="body2">
                  {userInfo?.interview_info.under_treatment_about_sleep}
                </LabelTypography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" sx={{ color: "#7A7A7A" }}>
                  睡眠薬の継続使用
                </Typography>
              </td>
              <td>
                <LabelTypography variant="body2">
                  {userInfo?.interview_info.sleep_medication}
                </LabelTypography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" sx={{ color: "#7A7A7A" }}>
                  朝型/夜型の自己認識
                </Typography>
              </td>
              <td>
                <LabelTypography variant="body2">
                  {userInfo?.interview_info.three_dss_morning_type}
                </LabelTypography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" sx={{ color: "#7A7A7A" }}>
                  子育て/介護による睡眠への影響
                </Typography>
              </td>
              <td>
                <LabelTypography variant="body2">
                  {userInfo?.interview_info.habitually_get_up_to_take_care}
                </LabelTypography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" sx={{ color: "#7A7A7A" }}>
                  睡眠について気になること
                </Typography>
              </td>
              <td>
                <LabelTypography variant="body2">
                  {userInfo?.interview_info.concern_about_sleep}
                </LabelTypography>
              </td>
            </tr>
          </tbody>
        </table>
      </Stack>
    </Stack>
  );
}

function MeasurementHistory({ userInfo }: UserInfoProps) {
  return (
    <Stack sx={{ gap: 2 }}>
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Stack
          direction="row"
          sx={{
            gap: 2,
            alignItems: "center",
          }}
        >
          <AssignmentOutlined />
          <Typography variant="subtitle2" sx={{ color: "#7A7A7A" }}>
            測定履歴
          </Typography>
        </Stack>
      </Stack>
      {userInfo?.measurement_history.map((history, i) => (
        <Stack key={i} sx={{ "& *": { whiteSpace: "nowrap" } }}>
          {i === 0 ? (
            <Typography variant="body2">
              {fmt(history.period_start_date)}〜{fmt(history.period_end_date)}
              （今回）
            </Typography>
          ) : (
            <Stack direction="row">
              {history.is_worksheet_openable ? (
                <Link
                  href={`/facility/worksheet/${history.sleep_checkup_info_uuid}/sleep_summaries`}
                  target="_blank"
                  variant="body2"
                >
                  {fmt(history.period_start_date)}〜
                  {fmt(history.period_end_date)}
                </Link>
              ) : (
                <Typography variant="body2">
                  {fmt(history.period_start_date)}〜
                  {fmt(history.period_end_date)}
                </Typography>
              )}
              {i === 1 && <Typography variant="body2">（前回）</Typography>}
            </Stack>
          )}
        </Stack>
      ))}
    </Stack>
  );
}

interface UserInfoProps {
  userInfo: UserInfo | undefined;
}
interface ExamineeInfoCardProps extends UserInfoProps {
  onClickClose: () => void;
}
function ExamineeInfoCard({ userInfo, onClickClose }: ExamineeInfoCardProps) {
  return (
    <Card sx={{ position: "sticky", top: "203px" }}>
      <CardContent>
        <Stack sx={{ gap: 6 }}>
          {/** 受診者情報 */}
          <ExamineeInfoSection
            userInfo={userInfo}
            onClickClose={onClickClose}
          />
          {/** 問診情報 */}
          <InterviewInfoSection userInfo={userInfo} />
          {/** 測定履歴 */}
          <MeasurementHistory userInfo={userInfo} />
        </Stack>
      </CardContent>
    </Card>
  );
}

interface LabelTypographyProps extends TypographyProps {
  children: string | null | undefined;
}
function LabelTypography({ children, ...props }: LabelTypographyProps) {
  return <Typography {...props}>{children ?? "-"}</Typography>;
}

export interface BorderlessTableContainerProps extends TableContainerProps {}
/**
 * 睡眠評価用のテーブルコンテナ
 * @param props {@link BorderlessTableContainer}
 * @returns 睡眠評価用のテーブルコンテナ
 */
export function BorderlessTableContainer({
  sx,
  ...props
}: BorderlessTableContainerProps) {
  return (
    <TableContainer
      sx={{
        boxShadow: "none",
        border: "solid 1px #E0E0E0",
        "& .MuiTableCell-root": {
          borderBottom: "initial",
        },
        "& .MuiTableHead-root .MuiTableRow-root, & .MuiTableBody-root .MuiTableRow-root:not(:last-child)":
          {
            borderBottom: "solid 1px #E0E0E0",
          },
        ...sx,
      }}
      {...props}
    ></TableContainer>
  );
}
