import QRCodeScanner from "@/components/QRCodeScanner";
import {
  REGISTRATION_METHOD_SCAN,
  postDeviceIdRegistration,
} from "@/components/activation/DeviceIdRegistrationAPI";
import { deviceIdValidator } from "@/components/activation/DeviceIdService";
import { getEncodedCurrentPath } from "@/components/common/LocationService";
import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { ConfirmationDialog } from "@/components/examinee/ConfirmationDialog";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import { examineeTheme } from "@/theme/themeV1";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  sleepCheckupUuid: string;
};

type ScanState = "scanning" | "success" | "cancelling";

class PageState {
  scanState: ScanState;
  guideMessage: string[];
  deviceId: string | null;

  constructor(
    scanState: ScanState,
    guideMessage: string[] = [],
    deviceId: string | null = null
  ) {
    this.scanState = scanState;
    this.guideMessage = guideMessage;
    this.deviceId = deviceId;
  }
}

const GUIDE_MESSAGE_01 = ["デバイスのQRコードを", "読み取ってください。"];
const GUIDE_MESSAGE_02 = ["デバイスから10cm程度", "離して撮影しましょう。"];

export function DeviceIdScan() {
  const [pageState, setPageState] = useState<PageState>(
    new PageState("scanning", GUIDE_MESSAGE_01)
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { sleepCheckupUuid } = (location.state as StateType) || {};

  const onDetectHandler = useCallback((data: string) => {
    if (deviceIdValidator(data) != null) {
      // デバイス以外のQRコードが読み取られたら無視する
      return;
    }

    setPageState((state) => new PageState("success", state.guideMessage, data));
  }, []);

  const onRegisterDeviceIdHandler = () => {
    if (pageState.deviceId == null) {
      return;
    }

    postDeviceIdRegistration(
      pageState.deviceId,
      REGISTRATION_METHOD_SCAN,
      sleepCheckupUuid,
      getEncodedCurrentPath(location)
    )
      .then(() => {
        navigate("/examinee/registration_completed", {
          state: {
            sleepCheckupUuid: sleepCheckupUuid,
          },
        });
      })
      .catch((e) => {
        setPageState((state) => new PageState("scanning", state.guideMessage));
        setErrorMessage(
          `エラーが発生しました。申し訳ありませんが、少し時間をおいてから再度お試しください。エラーが解消されない場合は、読み取り中止ボタンからデバイスIDを登録してください\n(${e.message})`
        );
      });
  };

  const onTimeHandler = useCallback((round: number) => {
    // ガイドメッセージを4回切り替え（約1分経過）ても読み取れていなかったら、ポップアップを表示する
    if (round >= 5) {
      setPageState((state) => new PageState("cancelling", state.guideMessage));
    } else {
      setPageState((state) => {
        const message =
          state.guideMessage === GUIDE_MESSAGE_01
            ? GUIDE_MESSAGE_02
            : GUIDE_MESSAGE_01;
        return new PageState("scanning", message);
      });
    }
  }, []);

  const onResumeHandler = () => {
    setPageState((state) => new PageState("scanning", state.guideMessage));
  };

  const onErrorHandler = useCallback((e: any) => {
    setErrorMessage(
      `エラーが発生しました。申し訳ありませんが、少し時間をおいてから再度お試しください。エラーが解消されない場合は、読み取り中止ボタンからデバイスIDを登録してください\n(${e.message})`
    );
  }, []);

  if (!sleepCheckupUuid) {
    return (
      <GeneralError
        to="/examinee/signup"
        buttonText="アカウント登録をやり直す"
      />
    );
  }

  return (
    <ThemeProvider theme={examineeTheme}>
      <ScrollToTop />
      <Box
        component="div"
        sx={{
          width: window.innerWidth,
          height: window.innerHeight,
          backgroundColor: "black",
          overflow: "hidden",
        }}
      >
        {errorMessage && <NonFieldError>{errorMessage}</NonFieldError>}
        {errorMessage == null && (
          <>
            {/* Note: 理論上、5秒毎にガイドメッセージが変わるように、scanTimeに5を設定している。が、実際は、端末のスペックなどによって描画に時間がかかったりするため、メッセージが切り替わる時間にはバラつきがあるので注意 */}
            <QRCodeScanner
              scan={pageState.scanState === "scanning"}
              width={window.innerWidth}
              height={window.innerHeight}
              scanTime={5}
              onDetect={onDetectHandler}
              onTime={onTimeHandler}
              onError={onErrorHandler}
            />
            <Stack
              sx={{ width: "100%", height: "100%" }}
              position="absolute"
              top={0}
              left={0}
              alignItems="center"
              justifyContent="center"
            >
              <ScanGuide messages={pageState.guideMessage} />
            </Stack>
          </>
        )}
        <Stack
          alignItems="center"
          position="absolute"
          bottom={24}
          sx={{ width: "100%" }}
        >
          <AbortButton
            onClick={() =>
              navigate(
                `/examinee/device_id_scan_introduction/${sleepCheckupUuid}`
              )
            }
          />
        </Stack>
      </Box>
      <ScanCancelDialog
        open={pageState.scanState === "cancelling"}
        onClick={() =>
          navigate("/examinee/device_id_input", {
            state: { sleepCheckupUuid: sleepCheckupUuid },
          })
        }
        onClickClose={onResumeHandler}
      />
      <ScanSuccessfulDialog
        deviceId={pageState.deviceId}
        onClick={onRegisterDeviceIdHandler}
        onClickClose={onResumeHandler}
      />
    </ThemeProvider>
  );
}

type ScanGuideProps = GuideMessageProps;
function ScanGuide({ messages }: ScanGuideProps) {
  return (
    <Stack spacing={4} sx={{ pb: "70px" }}>
      <GuideMessage messages={messages} />
      <ScanFrame />
    </Stack>
  );
}

type GuideMessageProps = {
  messages: string[];
};
function GuideMessage({ messages }: GuideMessageProps) {
  return (
    <Stack>
      {messages.map((m, i) => (
        <Typography
          key={i}
          variant="subtitle2"
          color="white"
          textAlign="center"
        >
          {m}
        </Typography>
      ))}
    </Stack>
  );
}

function ScanFrame() {
  return (
    <img
      src="/img/image_scan_frame.svg"
      alt="QRコードの読み取り"
      loading="lazy"
      style={{ width: "200px", height: "auto" }}
    />
  );
}

type AbortButtonProps = {
  onClick: ButtonProps["onClick"];
};
function AbortButton({ onClick }: AbortButtonProps) {
  return (
    <Button
      variant="contained"
      size="medium"
      color="blackButton"
      sx={{
        borderRadius: "32px",
      }}
      startIcon={<Close />}
      onClick={onClick}
    >
      読み取り中止
    </Button>
  );
}

type ScanCancelDialogProps = {
  open: DialogProps["open"];
  onClick: ButtonProps["onClick"];
  onClickClose: () => void;
};
function ScanCancelDialog({
  open,
  onClick,
  onClickClose,
}: ScanCancelDialogProps) {
  return (
    <ConfirmationDialog
      open={open}
      title="お困りですか？"
      message="QRコードがうまく読み取れない場合、手入力によってデバイスIDを登録できます。"
      action={{ title: "手入力する", onClick: onClick }}
      onClickClose={onClickClose}
    />
  );
}

type ScanSuccessfulDialogProps = {
  deviceId: string | null;
  onClick: ButtonProps["onClick"];
  onClickClose: () => void;
};
function ScanSuccessfulDialog({
  deviceId,
  onClick,
  onClickClose,
}: ScanSuccessfulDialogProps) {
  const BUTTON_SX = { px: 4 };

  return (
    <Dialog
      open={deviceId != null}
      maxWidth="md"
      onClose={onClickClose}
      PaperProps={{ sx: { m: 4 } }}
    >
      <DialogTitle textAlign="center">読み取り成功</DialogTitle>
      <DialogContent sx={{ px: 6, mt: 4 }}>
        <Typography variant="body1">
          以下のデバイスIDが見つかりました。
        </Typography>
        <Typography variant="body2" color="primary">
          {deviceId}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 6 }}>
        <Stack
          direction="row"
          spacing={4}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Button
            sx={BUTTON_SX}
            variant="outlined"
            size="large"
            onClick={onClickClose}
            fullWidth
          >
            キャンセル
          </Button>
          <Button
            sx={BUTTON_SX}
            variant="contained"
            size="large"
            onClick={onClick}
            fullWidth
            disableElevation
          >
            登録する
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
