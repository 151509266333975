import { Copyright } from "@/components/examinee/Copyright";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import RangeChart from "../commons/RangeChart";
import ReportDialog from "../commons/ReportDialog";
import { ReportImg } from "../commons/ReportImg";
import { useSummaryPageContext } from "./Summary";
import { TeacherComment } from "./commons";

/**
 * 睡眠のリズム
 *
 * @param param0
 * @returns
 */
export default function SleepRhythm() {
  const context = useSummaryPageContext();
  const [openInitDialog, setOpenInitDialog] = useState(context.isFirstVisit);
  const theme = useTheme();

  function next() {
    context.setScreenType("daytime_result");
  }
  return (
    <Stack
      sx={{
        p: "24px 16px 80px 16px",
        gap: "24px",
        height: "100%",
      }}
    >
      <SleepRhythmDialog open={openInitDialog} setOpen={setOpenInitDialog} />
      <Stack
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          p: "16px",
          gap: "16px",
        }}
      >
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="h6">睡眠のリズム</Typography>
          <Stack direction="row" sx={{ marginLeft: "auto" }}>
            <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
              <ReportImg
                style={{ width: "8px", height: "8px" }}
                image_name="image_report_summary_chart_position_mark.svg"
              />
            </Box>
            <Typography variant="caption">あなたの結果</Typography>
            <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
              <ReportImg
                style={{ marginLeft: "16px", width: "8px", height: "8px" }}
                image_name="image_report_summary_chart_reference_area.svg"
              />
            </Box>
            <Typography variant="caption">推奨範囲</Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              評価項目
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="justify"
              sx={{ color: "text.primary" }}
            >
              あなたの平均的な起床時刻から2時間以上ずれて起床した日
            </Typography>
            <Stack>
              <RangeChart
                min={{
                  value: 0,
                  label: "0日間",
                  attr: { fill: "#00A99D" },
                }}
                max={{
                  value: 7,
                  label: "7日間",
                }}
                plot={{
                  value: context.props.data.sleep_rhythm.evaluation_1,
                  label: `${context.props.data.sleep_rhythm.evaluation_1}日間`,
                  attr: (() =>
                    context.props.data.sleep_rhythm.evaluation_1 === 0
                      ? {}
                      : { fill: theme.palette.error.main })(),
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <TeacherComment>
          {context.props.data.sleep_rhythm.comment}
        </TeacherComment>
      </Stack>
      <Stack
        sx={{
          pt: "24px",
          pb: "10px",
          mb: "80px",
          position: "sticky",
          bottom: 0,
          zIndex: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{ boxShadow: "none" }}
          onClick={() => {
            next();
          }}
        >
          次へ
        </Button>
      </Stack>
      <Stack
        sx={{
          p: "32px 0px",
          position: "sticky",
          alignItems: "center",
        }}
      >
        <Copyright />
      </Stack>
    </Stack>
  );
}

type SleepQuantityDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

/**
 * 睡眠のリズムの初期表示ダイアログ
 *
 * @param param0
 * @returns
 */
function SleepRhythmDialog({ open, setOpen }: SleepQuantityDialogProps) {
  return (
    <ReportDialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <Stack>
        <Stack sx={{ p: "16px" }}>
          <Stack direction="row" sx={{ gap: "8px" }}>
            <Typography variant="subtitle1" sx={{ color: "primary.dark" }}>
              睡眠のリズムとは
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ background: "#EBF1F7", p: "16px" }}>
          <ReportImg image_name="image_report_summary_what_is_rhythm.svg" />
        </Stack>
        <Stack sx={{ p: "16px 16px 16px 16px", gap: "8px" }}>
          <Typography variant="body2" textAlign="justify">
            あなたが日々規則正しい睡眠がとれているかを評価します。
          </Typography>
          <Typography variant="caption" textAlign="justify">
            休日に寝だめをして起床時刻が遅れると、リズムが崩れてしまい、かえって翌週の眠気につながることがあります。
          </Typography>
        </Stack>
        <Stack sx={{ p: " 16px" }}>
          <Box textAlign="center" sx={{ pt: "16px" }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "18px",
                p: "6px 32px 6px 32px",
                boxShadow: "none",
              }}
              onClick={() => setOpen(false)}
            >
              結果を見る
            </Button>
          </Box>
        </Stack>
      </Stack>
    </ReportDialog>
  );
}
