import {
  ComprehensiveEvaluation as ComprehensiveEvaluationObject,
  DaytimeCondition as DaytimeConditionObject,
  DetailAssessment as DetailAssessmentObject,
  SleepCondition as SleepConditionObject,
  TotalAssessment as TotalAssessmentObject,
} from "@/sleep_compass_lite/data_transfer_objects/target/report/ComprehensiveEvaluation";

/** 星の個数を算出する際の基数 */
const STARS_DIVISOR = 60;

/**
 * 星の個数を小数点第1位までの少数で算出する
 * @param value 星の個数に60をかけた値
 * @returns 星の個数
 */
function calcStarsCount(value: number): number {
  return Math.round((value / STARS_DIVISOR) * 10) / 10;
}

/**
 * 総合評価と詳細評価を束ねるモデル
 */
export class ComprehensiveEvaluation {
  constructor(
    /** 総合評価 */
    readonly totalAssessment: TotalAssessment,
    /** 詳細評価 */
    readonly detailAssessments: DetailAssessment
  ) {}

  /**
   * ComprehensiveEvaluationオブジェクト(DTO)からComprehensiveEvaluationモデルを生成する
   * @param obj {@link ComprehensiveEvaluationObject}
   * @returns ComprehensiveEvaluationモデルのインスタンス
   */
  static create(obj: ComprehensiveEvaluationObject): ComprehensiveEvaluation {
    return new ComprehensiveEvaluation(
      TotalAssessment.create(obj.total_assessment),
      DetailAssessment.create(obj.detail_assessments)
    );
  }
}

/**
 * 総合評価
 */
class TotalAssessment {
  constructor(
    /** 総合評価(e.g. 大きな課題なし) */
    readonly result: string,
    /** 総合評価の概要(e.g. 睡眠の状態、日中の状態共に良好) */
    readonly summary: string,
    /** 総合評価の詳細(e.g. 睡眠の状態も良好で、日中の…（略）…習慣を継続しましょう。) */
    readonly detail: string
  ) {}

  /**
   * TotalAssessmentオブジェクト(DTO)からTotalAssessmentモデルを生成する
   * @param obj TotalAssessmentオブジェクト
   * @returns TotalAssessmentモデルのインスタンス
   */
  static create(obj: TotalAssessmentObject): TotalAssessment {
    return new TotalAssessment(obj.result, obj.summary, obj.detail);
  }
}

/**
 * 詳細評価、個別の評価を束ねるモデル
 */
class DetailAssessment {
  constructor(
    /** 睡眠の状態 */
    readonly sleepCondition: SleepCondition,
    /** 日中の状態 */
    readonly daytimeCondition: DaytimeCondition
  ) {}

  /**
   * DetailAssessmentオブジェクト(DTO)からDetailAssessmentモデルを生成する
   * @param obj DetailAssessmentオブジェクト
   * @returns DetailAssessmentモデルのインスタンス
   */
  static create(obj: DetailAssessmentObject): DetailAssessment {
    return new DetailAssessment(
      SleepCondition.create(obj.sleep_condition),
      DaytimeCondition.create(obj.daytime_condition)
    );
  }
}

/**
 * 睡眠の状態
 */
class SleepCondition {
  constructor(
    /** 睡眠の状態 良好／やや課題あり／課題あり */
    readonly result: string,
    /** 睡眠の長さの星の数に60を掛けた値 */
    readonly quantityStars: number,
    /** 睡眠の質の星の数に60を掛けた値 */
    readonly qualityStars: number,
    /** 睡眠のリズムの星の数に60を掛けた値 */
    readonly rhythmStars: number
  ) {}

  get quantityStarsCount(): number {
    return calcStarsCount(this.quantityStars);
  }
  get qualityStarsCount(): number {
    return calcStarsCount(this.qualityStars);
  }
  get rhythmStarsCount(): number {
    return calcStarsCount(this.rhythmStars);
  }

  /**
   * DetailAssessmentオブジェクト(DTO)からDetailAssessmentモデルを生成する
   * @param obj DetailAssessmentオブジェクト
   * @returns DetailAssessmentモデルのインスタンス
   */
  static create(obj: SleepConditionObject): SleepCondition {
    return new SleepCondition(
      obj.result,
      obj.quantity_stars,
      obj.quality_stars,
      obj.rhythm_stars
    );
  }
}

/**
 * 日中の状態
 */
class DaytimeCondition {
  constructor(
    /** 日中の状態 課題なし／課題あり */
    readonly result: string,
    /** 日中の眠気の星の数に60を掛けた値 */
    readonly daytimeSleepinessStars: number,
    /** 日中の疲労感の星の数に60を掛けた値 */
    readonly daytimeTirednessStars: number
  ) {}

  get sleepinessStarsCount(): number {
    return calcStarsCount(this.daytimeSleepinessStars);
  }
  get tirednessStarsCount(): number {
    return calcStarsCount(this.daytimeTirednessStars);
  }

  /**
   * DaytimeConditionオブジェクト(DTO)からDaytimeConditionモデルを生成する
   * @param obj DaytimeConditionオブジェクト
   * @returns DaytimeConditionモデルのインスタンス
   */
  static create(obj: DaytimeConditionObject): DaytimeCondition {
    return new DaytimeCondition(
      obj.result,
      obj.daytime_sleepiness_stars,
      obj.daytime_tiredness_stars
    );
  }
}
