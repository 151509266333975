import { Stack, Typography, TypographyProps } from "@mui/material";
import { ReactNode } from "react";

/**
 * アイコン付きラベル
 */
type SCTypographyProps = {
  children: ReactNode;
  startIcon: ReactNode;
  variant?: TypographyProps["variant"];
};
export function SCTypography({
  children,
  startIcon,
  variant,
}: SCTypographyProps) {
  return (
    <Stack direction="row" spacing={2}>
      {startIcon}
      <Typography variant={variant} color="primary">
        {children}
      </Typography>
    </Stack>
  );
}
