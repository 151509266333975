import { useSleepSummary } from "@/components/worksheet/apis";
import { SleepSummary } from "@/components/worksheet/commons/sleep_summaries";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DaytimeConditionSection from "./sleep_summaries/DaytimeConditionSection";
import DiseaseRisksSection from "./sleep_summaries/DiseaseRisksSection";
import MeasurementResultSection from "./sleep_summaries/MeasurementResultSection";
import SleepConditionSection from "./sleep_summaries/SleepConditionSection";

/**
 * 睡眠評価ページ
 * @returns 睡眠評価ページ
 */
export default function SleepSummaries() {
  const { uuid } = useParams<{ uuid: string }>();
  const sleepSummaryApi = useSleepSummary();
  const [sleepSummary, setSleepSummary] = useState<SleepSummary>();

  useEffect(() => {
    if (uuid == null) return;
    (async () => {
      const res = await sleepSummaryApi.get(uuid);
      setSleepSummary(res.data);
    })();
  }, [uuid, sleepSummaryApi]);

  return (
    <Stack sx={{ gap: 20 }}>
      <MeasurementResultSection
        uuid={uuid}
        measurement_result={sleepSummary?.measurement_result}
      />
      <SleepConditionSection sleep_condition={sleepSummary?.sleep_condition} />
      <DaytimeConditionSection
        daytime_condition={sleepSummary?.daytime_condition}
      />
      <DiseaseRisksSection disease_risks={sleepSummary?.disease_risks} />
    </Stack>
  );
}
