import { ContentCopy } from "@mui/icons-material";
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import { useState } from "react";

type TooltipMessageType = "copy" | "copied" | "failed";
type TooltipMessages = Record<TooltipMessageType, string>;
export const DEFAULT_TOOLTIP_MESSAGES: Readonly<TooltipMessages> = {
  copy: "クリップボードにコピー",
  copied: "コピーしました！",
  failed: "コピー出来ませんでした。",
};

type CopyToClipboardButtonProps = Omit<IconButtonProps, "children"> & {
  /** クリップボードに書き込むテキスト */
  writeText: string;
  /** ツールチップに表示するメッセージ */
  tooltipMessages?: TooltipMessages;
  /** メッセージを戻す時間 (msec) */
  revertTime?: number;
  /** tooltipのプロパティ */
  tooltipProps?: Omit<TooltipProps, "title" | "children">;
};

function CopyToClipboardButton({
  writeText,
  tooltipMessages = DEFAULT_TOOLTIP_MESSAGES,
  revertTime = 1000,
  tooltipProps,
  ...props
}: CopyToClipboardButtonProps) {
  const [copyMessageType, setCopyMessageType] =
    useState<TooltipMessageType>("copy");

  async function onClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    try {
      await navigator.clipboard.writeText(writeText);
      setCopyMessageType("copied");
    } catch (e) {
      setCopyMessageType("failed");
    }
    setTimeout(() => {
      // 指定秒後にメッセージを戻す。
      setCopyMessageType("copy");
    }, revertTime);
    props.onClick?.(event);
  }
  return (
    <Tooltip {...tooltipProps} title={tooltipMessages[copyMessageType]}>
      <IconButton {...props} onClick={onClick}>
        <ContentCopy />
      </IconButton>
    </Tooltip>
  );
}

export default CopyToClipboardButton;
