import { isHigherPriority } from "@/components/examinee/daily_interview_entrance/DailyInterviewRemindService";
import {
  DailyInterviewRemind,
  SleepCheckupInfo,
} from "@/components/examinee/sleep_checkup/Types";
import { toStringJP } from "@/utils/date";

export class DailyInterviewInfo {
  private readonly sleepCheckupInfo: SleepCheckupInfo;

  get examineeName(): string {
    return `${this.sleepCheckupInfo.medical_examinee_last_name} ${this.sleepCheckupInfo.medical_examinee_first_name}`;
  }

  getRemindInfo(remindId: number): RemindInfo {
    const index = this.sleepCheckupInfo.daily_interview_reminds.findIndex(
      (r) => r.id === remindId
    );

    if (index === -1) {
      throw new Error(`remind is not found (remindId: ${remindId})`);
    }

    return new RemindInfo(
      this.sleepCheckupInfo.daily_interview_reminds[index],
      index
    );
  }

  getUnansweredRemind(currentRemindId: number): RemindInfo | null {
    const currentIndex =
      this.sleepCheckupInfo.daily_interview_reminds.findIndex(
        (r) => r.id === currentRemindId
      );
    if (currentIndex === -1) {
      throw new Error("currentRemind is not found");
    }

    const prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      return null;
    }

    const prevRemind = this.sleepCheckupInfo.daily_interview_reminds[prevIndex];
    if (!isHigherPriority(prevRemind, new Date())) {
      return null;
    }

    return new RemindInfo(prevRemind, prevIndex);
  }

  constructor(sleepCheckupInfo: SleepCheckupInfo) {
    this.sleepCheckupInfo = sleepCheckupInfo;
  }
}

export class RemindInfo {
  readonly remind: DailyInterviewRemind;
  readonly index: number;

  get times(): string {
    return `${this.index + 1}回目`;
  }

  get date(): string {
    const d = new Date(this.remind.remind_datetime);
    return toStringJP(d);
  }

  constructor(remind: DailyInterviewRemind, index: number) {
    this.remind = remind;
    this.index = index;
  }
}
