import { Stack } from "@mui/material";
import { ReactNode } from "react";

type StackProps = {
  children: ReactNode;
};

export function SubChapterStack({ children }: StackProps) {
  return (
    <Stack sx={{ margin: "32px 0 80px 0", gap: "16px" }}>{children}</Stack>
  );
}
