import i18n from "@/i18n/configs";

export type ErrorMessageTranslator = (message: string) => string;

export const standardTranslator: ErrorMessageTranslator = (message: string) => {
  return i18n.t(message);
};

export const emailSigninTranslator: ErrorMessageTranslator = (
  message: string
) => {
  switch (message) {
    case "Missing required parameter USERNAME":
    case "Username cannot be empty":
    case "2 validation errors detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'username' failed to satisfy constraint: Member must have length greater than or equal to 1":
    case "2 validation errors detected: Value at 'username' failed to satisfy constraint: Member must have length greater than or equal to 1; Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+":
      return "メールアドレスは必須です。入力してください。";
    case "Incorrect username or password.":
      return "メールアドレスまたはパスワードが違います。";
    default:
      return i18n.t(message);
  }
};
