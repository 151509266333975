import { RegistrationEntry } from "@/sleep_compass_lite/data_transfer_objects/target/RegistrationEntry";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { getApiServerUrl } from "@/utils/axios";
import axios, { AxiosError, AxiosResponse } from "axios";

/**
 * 確認コードの再送
 * @param surveyInfo サーベイ情報のUUID
 * @param registrationEntryId 登録エントリーのUUID
 */
export async function resendEmailVerificationCode(
  surveyInfo: string,
  registrationEntryId: string
) {
  try {
    await patchRegistrationEntry(surveyInfo, registrationEntryId);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new ApiError(e.response);
    } else {
      throw e;
    }
  }
}

/**
 * 確認コード再送APIの呼び出し
 * @param surveyInfo サーベイ情報のUUID
 * @param registrationEntryId 登録エントリーのUUID
 * @returns 確認コード再送APIのレスポンス
 */
function patchRegistrationEntry(
  surveyInfo: string,
  registrationEntryId: string
): Promise<AxiosResponse<RegistrationEntry>> {
  return axios.patch(
    `${getApiServerUrl()}/api/lite/registration_entry/${registrationEntryId}/`,
    {
      survey_info: surveyInfo,
    }
  );
}
