import { Colors, palette } from "@/theme/palette";

const SPACING = 4;

const common = {
  // Note: TextFieldとSelectの両方でMuiFormControlが使われる。共通の部分をMuiFormControlで設定する
  MuiFormControl: {
    styleOverrides: {
      root: ({ ownerState }: any) => ({
        ...((ownerState.size === "medium" || ownerState.size === undefined) &&
          ownerState.variant === "outlined" && {
            // Note: MuiFormControlとMuiInputBase-rootで、heightとborderRadiusに共通の値を設定する必要があるので注意
            "& .MuiInputBase-root": {
              height: "48px",
              borderRadius: "8px",
            },
            height: "48px",
            borderRadius: "8px",
            "& fieldset": {
              borderColor: palette.palette.black[12],
            },
            // Note: Selectのラベルのフォントサイズの指定
            "& .MuiInputLabel-root": {
              fontSize: "14px",
            },
            "& .MuiInputBase-multiline": {
              height: "auto",
            },
          }),
        "& .MuiInputLabel-root": {
          "& .MuiInputLabel-asterisk": {
            color: Colors.scRed.main,
          },
        },
      }),
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ ownerState }: any) => ({
        ...((ownerState.size === "medium" || ownerState.size === undefined) &&
          ownerState.variant === "outlined" && {
            "& .MuiInputBase-root": {
              padding: "0px 24px",
            },
          }),
        ...((ownerState.size === "medium" || ownerState.size === undefined) && {
          "& .MuiInputBase-root": {
            fontSize: "16px",
            lineHeight: "28px",
          },
        }),
      }),
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        "& .MuiSelect-select": {
          fontSize: "14px",
        },
      },
    },
  },
  MuiLink: {
    defaultProps: {
      variant: "caption",
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "16px",
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: "16px 24px 24px 24px",
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        // Note: StickyHeaderのヘッダに背景色を適応するため、各CellにbackgroundColorを指定する。StickyHeaderの場合、MuiTableHeadのルートに指定しても適応されない。
        "& .MuiTableCell-root": {
          color: palette.palette.text.secondary,
          backgroundColor: palette.palette.black[1],
        },
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        // Note: MuiPaperのborderRadiusと同じ値を設定するように注意。
        borderRadius: "8px",
      },
    },
  },
  // Note: ポップアップメニュー内（ユーザーメニュー）のディバイダーのレイアウトはsxプロパティで指定できないので、styleOverridesで指定する
  MuiMenu: {
    styleOverrides: {
      root: {
        "& .MuiMenu-paper": {
          "& .MuiMenu-list": {
            "& .MuiList-root": {
              "& .MuiDivider-root": {
                margin: "8px 16px 8px 16px",
              },
            },
          },
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${palette.palette.black[12]}`,
        button: {
          fontWeight: "400",
          marginRight: "24px",
          minWidth: "0",
          paddingBottom: "8px",
          paddingLeft: "0",
          paddingRight: "0",
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: "0px 32px",
        marginTop: "16px",
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: "40px 32px 24px 32px",
        // Note: ダイアログ上に設置されたボタンには影をつけない
        "& .MuiButton-contained": {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        "& .MuiAlert-root": {
          padding: "8px 16px",
          "& .MuiAlert-action": {
            padding: "4px 0px 0px 20px",
          },
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
      },
    },
  },
};

export const examineeComponents = {
  // Note: デザインルールでは、スペースの最小単位は8だが、今後、例外が発生する可能性を考慮して、最小単位を4にする
  spacing: SPACING,
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          ...(ownerState.size === "large" && {
            fontSize: "15px",
            lineHeight: "26px",
          }),
          ...(ownerState.size === "medium" && {
            fontSize: "14px",
            lineHeight: "24px",
          }),
          ...(ownerState.size === "small" && {
            fontSize: "13px",
            lineHeight: "22px",
          }),
          ...((ownerState.variant === "outlined" ||
            ownerState.variant === "contained") && {
            padding: "8px 16px",
            borderRadius: "8px",
          }),
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "16px",
          margin: "16px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "24px 24px 0px 24px",
        },
      },
      defaultProps: {
        variant: "h6",
        color: "primary",
      },
    },
    ...common,
  },
};

export const facilityComponents = {
  // Note: デザインルールでは、スペースの最小単位は8だが、今後、例外が発生する可能性を考慮して、最小単位を4にする
  spacing: SPACING,
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          ...((ownerState.variant === "outlined" ||
            ownerState.variant === "contained") &&
            ownerState.size === "medium" && {
              padding: "8px 24px",
              fontSize: "16px",
            }),
          ...((ownerState.variant === "outlined" ||
            ownerState.variant === "contained") &&
            ownerState.size === "small" && {
              padding: "8px 16px",
              fontSize: "14px",
            }),
          ...((ownerState.variant === "outlined" ||
            ownerState.variant === "contained") && {
            borderRadius: "8px",
          }),
          ...(ownerState.variant === "text" &&
            ownerState.size === "small" && {
              fontSize: "14px",
            }),
          ...(ownerState.variant === "text" &&
            ownerState.size === "medium" && {
              fontSize: "16px",
            }),
          lineHeight: "160%",
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "32px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "16px 32px",
        },
      },
      defaultProps: {
        variant: "h4",
        color: "primary",
      },
    },
    ...common,
  },
};

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    whiteButton: true;
    blackButton: true;
  }
}
