import { HelpOutline } from "@mui/icons-material";
import { SxProps } from "@mui/material";
import { ReactNode, useState } from "react";
import WorksheetDialog, { WorksheetDialogProps } from "./WorksheetDialog";

export default function HelpButton({
  dialogTitle,
  icon,
  iconSx,
  children,
  dialogProps,
}: {
  dialogTitle: string;
  icon?: ReactNode;
  iconSx?: SxProps;
  children?: ReactNode;
  dialogProps?: Omit<WorksheetDialogProps, "title" | "onClose" | "open">;
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {icon ?? (
        <HelpOutline
          sx={{
            color: "black.36",
            cursor: "pointer",
            ml: 1,
            fontSize: "16px",
            ...iconSx,
          }}
          onClick={(e) => {
            setOpen(true);
            e.stopPropagation();
          }}
        />
      )}
      <WorksheetDialog
        title={dialogTitle}
        onClose={() => setOpen(false)}
        open={open}
        {...dialogProps}
      >
        {children}
      </WorksheetDialog>
    </>
  );
}
