import { Copyright } from "@/components/examinee/Copyright";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import RangeChart from "../commons/RangeChart";
import ReportDialog from "../commons/ReportDialog";
import { ReportImg } from "../commons/ReportImg";
import { useSummaryPageContext } from "./Summary";
import { TeacherComment } from "./commons";

/**
 * 日中の眠気と疲労感
 *
 * @param param0
 * @returns
 */
export default function DaytimeResult() {
  const context = useSummaryPageContext();
  const [openInitDialog, setOpenInitDialog] = useState(context.isFirstVisit);
  const theme = useTheme();

  function next() {
    if (context.hasDiseaseRiskOption) {
      return context.setScreenType("disease_risk");
    }
    return context.setScreenType("end_of_summary");
  }
  return (
    <Stack
      sx={{
        p: "24px 16px 80px 16px",
        gap: "24px",
        height: "100%",
      }}
    >
      <DaytimeResultDialog open={openInitDialog} setOpen={setOpenInitDialog} />
      <Stack
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          p: "16px",
          gap: "16px",
        }}
      >
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="h6">日中の眠気</Typography>
          <Stack direction="row" sx={{ marginLeft: "auto" }}>
            <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
              <ReportImg
                style={{ width: "8px", height: "8px" }}
                image_name="image_report_summary_chart_position_mark.svg"
              />
            </Box>
            <Typography variant="caption">あなたの結果</Typography>
            <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
              <ReportImg
                style={{ width: "8px", height: "8px", marginLeft: "16px" }}
                image_name="image_report_summary_chart_reference_area.svg"
              />
            </Box>
            <Typography variant="caption">推奨範囲</Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              評価項目
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: "text.primary" }}
              textAlign="justify"
            >
              日中に眠気を多く感じた日数
            </Typography>
            <Stack>
              <RangeChart
                min={{
                  value: 0,
                  label: "0日間",
                  attr: { fill: "#00A99D" },
                }}
                max={{
                  value: 7,
                  label: "7日間",
                }}
                recommendFrom={{
                  value: 0,
                }}
                recommendTo={{
                  value: 2,
                  label: "2日間",
                }}
                plot={{
                  value: context.props.data.daytime_result.drowsiness,
                  label: `${context.props.data.daytime_result.drowsiness}日間`,
                  attr: (() =>
                    context.props.data.daytime_result.drowsiness > 2
                      ? { fill: theme.palette.error.main }
                      : {})(),
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          p: "16px",
          gap: "16px",
        }}
      >
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="h6">日中の疲労</Typography>
          <Stack direction="row" sx={{ marginLeft: "auto" }}>
            <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
              <ReportImg
                style={{ width: "8px", height: "8px" }}
                image_name="image_report_summary_chart_position_mark.svg"
              />
            </Box>
            <Typography variant="caption">あなたの結果</Typography>
            <Box sx={{ alignItems: "center", display: "flex", mr: "8px" }}>
              <ReportImg
                style={{ marginLeft: "16px", width: "8px", height: "8px" }}
                image_name="image_report_summary_chart_reference_area.svg"
              />
            </Box>
            <Typography variant="caption">推奨範囲</Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              評価項目
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="justify"
              sx={{ color: "text.primary" }}
            >
              日中まで疲労感が残った日数
            </Typography>
            <Stack>
              <RangeChart
                min={{
                  value: 0,
                  label: "0日間",
                  attr: { fill: "#00A99D" },
                }}
                max={{
                  value: 7,
                  label: "7日間",
                }}
                recommendFrom={{
                  value: 0,
                }}
                recommendTo={{
                  value: 2,
                  label: "2日間",
                }}
                plot={{
                  value: context.props.data.daytime_result.fatigue,
                  label: `${context.props.data.daytime_result.fatigue}日間`,
                  attr: (() =>
                    context.props.data.daytime_result.fatigue > 2
                      ? { fill: theme.palette.error.main }
                      : {})(),
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <TeacherComment>
          {context.props.data.daytime_result.comment}
        </TeacherComment>
      </Stack>
      <Stack
        sx={{
          pt: "24px",
          pb: "10px",
          mb: "80px",
          position: "sticky",
          bottom: 0,
          zIndex: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{ boxShadow: "none" }}
          onClick={() => {
            next();
          }}
        >
          次へ
        </Button>
      </Stack>
      <Stack
        sx={{
          p: "32px 0px",
          position: "sticky",
          alignItems: "center",
        }}
      >
        <Copyright />
      </Stack>
    </Stack>
  );
}

type SleepQuantityDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

/**
 * 日中の眠気と疲労感の初期表示ダイアログ
 *
 * @param param0
 * @returns
 */
function DaytimeResultDialog({ open, setOpen }: SleepQuantityDialogProps) {
  return (
    <ReportDialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <Stack>
        <Stack sx={{ p: "16px" }}>
          <Stack direction="row" sx={{ gap: "8px" }}>
            <Typography variant="subtitle1" sx={{ color: "primary.dark" }}>
              日中の眠気と疲労とは
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ background: "#EBF1F7", p: "16px" }}>
          <ReportImg image_name="image_report_summary_what_is_daytime.png" />
        </Stack>
        <Stack sx={{ p: "16px 16px 16px 16px", gap: "8px" }}>
          <Typography variant="body2" textAlign="justify">
            睡眠の結果、あなたが翌日の日中にどう感じたのか、を評価する項目です。
          </Typography>
          <Typography variant="caption" textAlign="justify">
            必要な睡眠には個人差があるため、これらの項目の結果が悪い場合は、あなたにとっては睡眠が不十分な可能性があります。
          </Typography>
        </Stack>
        <Stack sx={{ p: " 16px" }}>
          <Box textAlign="center" sx={{ pt: "16px" }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "18px",
                p: "6px 32px 6px 32px",
                boxShadow: "none",
              }}
              onClick={() => setOpen(false)}
            >
              結果を見る
            </Button>
          </Box>
        </Stack>
      </Stack>
    </ReportDialog>
  );
}
