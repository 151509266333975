import { LineBreakText } from "@/components/common/LineBreakText";
import { Alert, AlertColor } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

export type MessageAlertProps = {
  severity: AlertColor;
  message: string;
  sx?: SxProps<Theme>;
};
export function MessageAlert({ severity, message, sx }: MessageAlertProps) {
  return (
    <Alert severity={severity} sx={sx}>
      <LineBreakText text={message} />
    </Alert>
  );
}
