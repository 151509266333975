import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import HelpButton from "../HelpButton";
import AnnotationTypography from "./criterions/AnnotationTypography";
import CriterionTableContainer, {
  Cells,
} from "./criterions/CriterionTableContainer";

export default function RhythmCriterionDialog() {
  return (
    <HelpButton
      dialogTitle="睡眠のリズムの判定基準"
      dialogProps={{
        maxWidth: "lg",
      }}
    >
      <CriterionTableContainer
        sx={{
          "& td.MuiTableCell-root:nth-child(2)": {
            backgroundColor: Cells.color.good,
          },
          "& td.MuiTableCell-root:nth-child(3)": {
            backgroundColor: Cells.color.caution,
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="subtitle2">判定の仕組み</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ boxSizing: "border-box", width: "200px" }}>
                <Typography variant="subtitle2">
                  平均的な起床時刻から2時間以上ずれて起床した日
                </Typography>
                <Typography variant="caption">推奨範囲：0日/週</Typography>
              </TableCell>
              <TableCell sx={{ boxSizing: "border-box", width: "348px" }}>
                <Typography variant="body2">推奨範囲</Typography>
              </TableCell>
              <TableCell sx={{ boxSizing: "border-box", width: "348px" }}>
                <Typography variant="body2">推奨範囲外</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">判定</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">良好</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">注意</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CriterionTableContainer>
      <AnnotationTypography>
        ※測定データが4日に満たない場合は判定不能。
      </AnnotationTypography>
    </HelpButton>
  );
}
