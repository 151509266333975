import { createTheme, Theme } from "@mui/material";

class ThemeColors {
  readonly green = {
    light: "#68B36B",
    main: "#43A047",
    dark: "#388E3D",
    contrastText: "#FFF",
  };

  readonly yellow = {
    light: "#FFC233",
    main: "#FFB300",
    dark: "#FFA000",
    contrastText: "#FFF",
  };

  readonly turquoiseBlue = {
    light: "#33BCCD",
    main: "#00ACC1",
    dark: "#0096A7",
    contrastText: "#FFF",
  };

  readonly blue = {
    light: "#4791DB",
    main: "#1976D2",
    dark: "#1564C0",
    contrastText: "#FFF",
  };

  readonly navyblue = {
    light: "#4B5193",
    main: "#1E2678",
    dark: "#151a54",
    contrastText: "#FFF",
  };

  readonly purple = {
    light: "#D7A8CB",
    main: "#CE93BF",
    dark: "#906685",
    contrastText: "#FFF",
  };

  readonly grey = {
    50: "#DDD8D3",
    100: "#C7C2BE",
    200: "#B1ADA9",
    300: "#9B9794",
    400: "#85827F",
    500: "#6F6C6A",
    600: "#585654",
    700: "#42413F",
    800: "#2C2B2A",
    900: "#161615",
  };

  readonly black = {
    default: "#282828",
    100: "#000000",
    52: "#7A7A7A",
    36: "#A3A3A3",
    12: "#E0E0E0",
    8: "#EAEAEA",
    1: "#FAFAFA",
    0: "#FFFFFF",
  };

  readonly whiteButton = {
    main: "#FFF",
    contrastText: "#3D3D3D",
  };
}

class Palettes {
  readonly colors = new ThemeColors();

  readonly primaryGreen = {
    palette: {
      primary: this.colors.green,
      secondary: this.colors.blue,
      grey: this.colors.grey,
      black: this.colors.black,
    },
  };

  readonly primaryYellow = {
    palette: {
      primary: this.colors.yellow,
      secondary: this.colors.turquoiseBlue,
      grey: this.colors.grey,
      black: this.colors.black,
    },
  };

  readonly primaryTurquoiseBlue = {
    palette: {
      primary: this.colors.turquoiseBlue,
      secondary: this.colors.yellow,
      grey: this.colors.grey,
      black: this.colors.black,
    },
  };

  readonly primaryBlue = {
    palette: {
      primary: this.colors.blue,
      secondary: this.colors.green,
      grey: this.colors.grey,
      black: this.colors.black,
    },
  };

  readonly primaryNavyblue = {
    palette: {
      primary: this.colors.navyblue,
      secondary: this.colors.purple,
      grey: this.colors.grey,
      black: this.colors.black,
    },
  };
}

class ThemeSet {
  readonly dev: Theme;
  readonly demo: Theme;
  readonly prd: Theme;

  constructor(dev: any, demo: any, prd: any) {
    this.dev = createTheme(dev);
    this.demo = createTheme(demo);
    this.prd = createTheme(prd);
  }

  getTheme(env: string | undefined): Theme {
    if (env == null || env === undefined) {
      return this.dev;
    }

    switch (env) {
      case "dev":
      case "stg":
        return this.dev;
      case "demo":
        return this.demo;
      case "prd":
        return this.prd;
      default:
        return this.dev;
    }
  }
}

class SystemTheme {
  private palettes = new Palettes();

  private facility = new ThemeSet(
    this.palettes.primaryYellow,
    this.palettes.primaryGreen,
    this.palettes.primaryTurquoiseBlue
  );

  private examinee = new ThemeSet(
    this.palettes.primaryYellow,
    this.palettes.primaryGreen,
    this.palettes.primaryBlue
  );

  private accelstars = new ThemeSet(
    this.palettes.primaryYellow,
    this.palettes.primaryGreen,
    this.palettes.primaryNavyblue
  );

  getFacility(): Theme {
    return this.facility.getTheme(process.env.REACT_APP_ENV);
  }

  getExaminee(): Theme {
    return this.examinee.getTheme(process.env.REACT_APP_ENV);
  }

  getACCELStars(): Theme {
    return this.accelstars.getTheme(process.env.REACT_APP_ENV);
  }
}

export default new SystemTheme();
