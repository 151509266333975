import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function BodyConditionHelpDialog() {
  return (
    <HelpButton dialogTitle="体の調子">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "睡眠への影響",
            content:
              "かゆみや痛みなどから睡眠が阻害され、不眠をもたらすことがあります。",
          },
          {
            title: "対処方法",
            content:
              "疾患によるかゆみや痛みが睡眠を妨害している場合は主治医に相談しましょう。",
          },
        ]}
      />
    </HelpButton>
  );
}
