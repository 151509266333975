import { Remind } from "@/components/examinee/daily_interview_remind/Types";
import { getExamineeAxios } from "@/utils/axios";

export function getReminds(sleepCheckupId: string | null, currentPath: string) {
  return getExamineeAxios(currentPath).get(getPath(sleepCheckupId));
}

export function postReminds(reminds: Remind[], currentPath: string) {
  return getExamineeAxios(currentPath).post(getPath(), reminds);
}

function getPath(sleepCheckupId: string | null = null) {
  let path = "/api/reminds/";
  if (sleepCheckupId != null) {
    path += `?SleepCheckupInfoUid=${sleepCheckupId}`;
  }

  return path;
}
