// APIに送るパラメータだけに絞ったSleepCheckupInfo
export class SleepCheckupInfoEdit {
  id?: string;
  // 施設内ユーザーID
  medical_examinee_id_in_facility: string = "";
  // 受診者姓
  medical_examinee_last_name: string = "";
  // 受診者名
  medical_examinee_first_name: string = "";
  // 受診者姓カナ
  medical_examinee_last_name_kana: string = "";
  // 受診者名カナ
  medical_examinee_first_name_kana: string = "";
  // 受診者生年月日
  medical_examinee_birthday: string | null = null;
  // デバイスID
  device_id: string = "";
  // デバイス貸出日
  date_device_sent: string | null = null;
  // デバイス返却日
  date_device_returned: string | null = null;
  // 企業名
  corporate_name: string = "";
  // 部署名
  department_name: string = "";
  // 施設
  medical_facility: string = "";
  // 受信者アクティベート済み
  activated: boolean = false;
  // プロジェクト
  project: CheckupProject | null = null;
}
export interface MedicalFacility {
  id: string;
  name: string;
  zip_code: string;
  address: string;
}

export interface acceptedBy {
  id: number;
  phone: string;
  user: {
    email: string;
    first_name: string;
    last_name: string;
    user_type: number;
    username: string;
  };
}

export type InterviewType = "primary" | "daily";

export type ReportStatus = "CHECKINGUP" | "APPROVED" | "SENT";

export class SleepCheckupInfo {
  id: string = "";
  // 施設内ユーザーID
  medical_examinee_id_in_facility: string = "";
  // 受診者姓
  medical_examinee_last_name: string = "";
  // 受診者名
  medical_examinee_first_name: string = "";
  // 受診者姓カナ
  medical_examinee_last_name_kana: string = "";
  // 受診者名カナ
  medical_examinee_first_name_kana: string = "";
  // 受診者生年月日
  medical_examinee_birthday: string | null = null;
  // 年齢
  medical_examinee_age_at_sleep_checkup: string | null = null;
  // デバイス貸出日
  date_device_sent: string | null = null;
  // デバイス返却日
  date_device_returned: string | null = null;
  // 企業名
  corporate_name: string = "";
  // 部署名
  department_name: string | null = null;
  // デバイスID
  device_id: string = "";
  // レポート送付済み
  sent_report: boolean = false;
  // 受付日
  accepted_at: string | null = null;
  // 受付者
  accepted_by: acceptedBy | null = null;
  // 施設
  medical_facility: number | null = null;
  // 受診者
  medical_examinee: string = "";
  // 事前問診回答
  primary_interview_answer_count: number = 0;
  // 毎日問診回答
  daily_interview_answer_count: number = 0;
  // ユニークID
  unique_id: string = "";
  // リマインドID
  reminds: Record<string, any>[] = [];
  // レポート
  report: ReportInfo | null = null;
  // 受診者の登録有無
  activated: boolean = false;
  // 事前問診の進捗等の情報
  primary_interview: InterviewInfo = { status: "" };
  // 毎日問診の進捗等の情報
  daily_interview: InterviewInfo = { status: "" };
  // SleepCheckupInfoに紐付いたReportContextがあるかどうか
  device_id_related_report_context: boolean = false;
  // プロジェクト（測定をグルーピングする目的などで使用される）
  project: CheckupProject | null = null;
  // 睡眠改善ワークシートを表示可能か
  is_worksheet_openable: boolean = false;
}

interface InterviewInfo {
  status: string;
}

export interface ReportInfo {
  id: string;
  // レポートの発行日（承認日）
  approved_at: string;
  // レポート取得APIのURL
  url: string;
}

export type boolString = "0" | "1" | "";
export class SearchParams {
  medical_facility: string | null = null;
  medical_examinee_id_in_facility: string | null = null;
  corporate_name: string | null = null;
  department_name: string | null = null;
  medical_examinee_last_name: string | null = null;
  medical_examinee_first_name: string | null = null;
  medical_examinee_last_name_kana: string | null = null;
  medical_examinee_first_name_kana: string | null = null;
  medical_examinee_birthday: string | null = null;
  date_device_sent_from: string | null = null;
  date_device_sent_to: string | null = null;
  date_device_returned_from: string | null = null;
  date_device_returned_to: string | null = null;
  accepted_at_from: string | null = null;
  accepted_at_to: string | null = null;
  accepted_by: string | null = null;
  report_approved: boolString = "";
  sent_report: boolString = "";
  device_id: string | null = null;
}

export interface Remind {
  days?: number;
  remind_datetime: string;
  reminded: boolean;
}
export interface Question {
  text: string;
  detail: string;
}
export interface Choice {
  text: string;
}

export interface InterviewAnswer {
  answered_at: string;
  text: string;
  remind: Remind;
  question: Question;
  choice: Choice;
}

export interface CheckupProject {
  id: string | null;
  name: string;
}
