import { RootPages } from "@/components/sleep_checkup_v1/Path";
import { Group } from "@/utils/auth";

/**
 * 施設ユーザーの権限と表示メニューのテーブル
 */
export const PAGE_TABLE: Record<Group, RootPages[]> = {
  facility_report_confirmor: ["SleepCheckupList"],
  facility_member: ["SleepCheckupList"],
  facility_admin: ["SleepCheckupList", "UserList"],
  accelstars: [],
};

/**
 * 指定されたグループに対して表示するメニューを返す
 * @param groups 対象のグループ
 * @returns メニューのリスト
 */
export function getPages(groups: Group[]): RootPages[] {
  const set = new Set<RootPages>();
  for (const group of groups) {
    const pages = PAGE_TABLE[group];
    pages.forEach((p) => set.add(p));
  }
  return [...set];
}
