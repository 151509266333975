import "@/pages/examinee/service_positioning/CounterStyle.css";
import {
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  ListProps,
  Stack,
  Typography,
} from "@mui/material";

/**
 * サービスの位置付けページのリストに記載する文言
 */
type ListText = {
  /** 親リストに記載する文言 */
  text: string;
  /** 子リストに記載する文言 */
  subTexts?: string[];
};

/**
 * サービスの位置付けページ
 */
export function ServicePositioning() {
  // List(親リスト, 子リスト)の共通部分のsx
  const commonSX: ListProps["sx"] = {
    // 行頭の文字幅だけ字下げするため、左マージンに1文字分の幅を指定
    ml: "1rem",
    p: 0,
  };
  // List(親リスト)のsx
  const listSX: ListProps["sx"] = {
    // 行頭に「・」をつけるためにdiscを指定
    listStyle: "disc",
    "& li.MuiListItem-root": {
      // デフォルトのパディングを0で上書き
      padding: 0,
    },
    ...commonSX,
  };
  // List(子リスト)のsx
  const subListSX: ListProps["sx"] = {
    // 行頭に「1：」をつけるためにcounter-with-colonを指定
    listStyle: "counter-with-colon",
    // 行頭の番号のフォントを指定
    "& li.MuiListItem-root::marker": {
      fontSize: "0.75rem",
      color: "text.secondary",
    },
    ...commonSX,
  };
  // ListItemのsx
  const listItemSX: ListItemProps["sx"] = {
    // 行頭に「・」や「1：」をつけるためにlist-itemを指定(list-itemでないと::markerがレイアウトされない)
    display: "list-item",
  };
  // ListItemTextのprimaryTypographyProps
  const typographyProps: ListItemTextProps["primaryTypographyProps"] = {
    variant: "caption",
    textAlign: "justify",
    color: "text.secondary",
  };
  // ListItemTextのsx
  const listItemTextSX: ListItemTextProps["sx"] = {
    // ListItemText間のスペース
    my: 2,
  };

  // リストで表示するテキスト
  const texts: ListText[] = [
    {
      text: "本サービスでは、診断ガイドラインに基づき問診回答により睡眠時無呼吸症候群・不眠症の疾患リスクをレポートにて表示する場合があります。",
    },
    {
      text: "本サービスは医療機器ではないため、提供される情報や結果は、病気の診断や治療を意図するものではなく、参考情報となります。",
    },
    {
      text: "医師や他の医療専門家に代わるものではないため、提供される情報や結果に基づいて医学的判断を下したり、薬の服用などを行ったり中止したりしないでください。",
    },
    {
      text: "本サービスで使用する診断ガイドラインは、公知かつ信頼性のある出典元（日本国内学会におけるガイドライン）に基づきます。",
      subTexts: [
        "睡眠時無呼吸症候群（SAS）の診療ガイドライン 2020 ：日本呼吸器学会",
        "臨床睡眠検査マニュアル(日本睡眠学会)",
      ],
    },
    {
      text: "正しく問診回答が行われなかった場合、正しい結果が出ない場合があります。",
    },
    {
      text: "提供される情報や結果に関わらず、気になることがある場合には、かかりつけ医・睡眠の専門医に相談することをお勧めします。",
    },
  ];

  return (
    <Stack sx={{ gap: 6, mx: 4, mt: 8, mb: 20 }}>
      <Typography variant="h6" textAlign="center">
        サービスの位置付け
      </Typography>
      <Stack sx={{ gap: 4 }}>
        <Typography variant="body2" textAlign="justify" color="text.secondary">
          睡眠健康測定サービスSLEEP
          COMPASS（以下「本サービス」）のご利用に際しては、以下の内容を必ずご確認ください。
        </Typography>
        <List sx={listSX}>
          {texts.map((listText, i) => {
            return (
              <ListItem key={i} sx={listItemSX}>
                <ListItemText
                  primaryTypographyProps={typographyProps}
                  sx={listItemTextSX}
                >
                  {listText.text}
                </ListItemText>
                {listText.subTexts != null && (
                  <List sx={subListSX}>
                    {listText.subTexts.map((subText, i) => (
                      <ListItem key={i} sx={listItemSX}>
                        <ListItemText
                          primaryTypographyProps={typographyProps}
                          sx={listItemTextSX}
                        >
                          {subText}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                )}
              </ListItem>
            );
          })}
        </List>
      </Stack>
    </Stack>
  );
}
