import {
  ReportStatus,
  SleepCheckupInfo,
} from "@/components/sleep_checkup_v1/Types";
import { periodText } from "@/components/sleep_checkup_v1/util";

const NULL_EXP = "-";

/**
 * SleepCheckupInfoから受信者の姓名を取得し、連結して、返す
 * @param info 対象のSleepCheckupInfo
 * @returns 受信者のフルネーム
 */
export function examineeFullName(info: SleepCheckupInfo): string {
  return `${info.medical_examinee_last_name} ${info.medical_examinee_first_name}`;
}

/**
 * SleepCheckupInfoから受信者の姓名(カナ)を取得し、連結して、返す
 * @param info 対象のSleepCheckupInfo
 * @returns 受信者のフルネーム(カナ)
 */
export function examineeFullNameKana(info: SleepCheckupInfo): string {
  return `${info.medical_examinee_last_name_kana} ${info.medical_examinee_first_name_kana}`;
}

/**
 * SleepCheckupInfoからデバイス貸出期間を取得して、「~」を使った形式で返す
 * @param info 対象のSleepCheckupInfo
 * @returns 「~」を使った形式で表されたデバイス貸出期間
 */
export function deviceLendingPeriod(info: SleepCheckupInfo): string {
  if (info.date_device_sent == null && info.date_device_returned == null) {
    return NULL_EXP;
  }

  return periodText(
    info.date_device_sent ?? "",
    info.date_device_returned ?? ""
  );
}

/**
 * SleepCheckupInfoからレポート状況を取得する
 * @param info 対象のSleepCheckupInfo
 * @returns レポート状況
 */
export function reportStatus(info: SleepCheckupInfo): ReportStatus {
  if (info.sent_report) {
    return "SENT";
  }

  return info.report == null ? "CHECKINGUP" : "APPROVED";
}

/**
 * SleepCheckupInfoからレポート発行日を取得する
 * @param info 対象のSleepCheckupInfo
 * @returns レポート発行日
 */
export function reportApprovedAt(info: SleepCheckupInfo): string {
  if (info.report == null) {
    return NULL_EXP;
  }

  const date = new Date(info.report.approved_at);
  const year: string = date.getFullYear().toString();
  const month: string = (date.getMonth() + 1).toString().padStart(2, "0");
  const day: string = date.getDate().toString();
  const hour: string = date.getHours().toString();
  const minute: string = date.getMinutes().toString().padStart(2, "0");
  return `${year}/${month}/${day} ${hour}:${minute}`;
}

/**
 * SleepCheckupInfoから受付担当者の姓名を取得し、連結して、返す
 * @param info 対象のSleepCheckupInfo
 * @returns レポート発行日
 */
export function medicalUserFullName(info: SleepCheckupInfo): string {
  if (info.accepted_by == null) {
    return NULL_EXP;
  }

  return userFullName(info.accepted_by.user);
}

function userFullName(user: any): string {
  return `${user.last_name} ${user.first_name}`;
}

/**
 * SleepCheckupInfoから受信者の企業名を取得する
 * @param info 対象のSleepCheckupInfo
 * @returns 受信者の企業名
 */
export function examineeCorporateName(info: SleepCheckupInfo): string {
  return info.corporate_name ?? NULL_EXP;
}

/**
 * SleepCheckupInfoから受信者の部署名を取得する
 * @param info 対象のSleepCheckupInfo
 * @returns 受信者の部署名
 */
export function examineeDepartmentName(info: SleepCheckupInfo): string {
  return info.department_name ?? NULL_EXP;
}

/**
 * SleepCheckupInfoからデバイスIDを取得する
 * @param info 対象のSleepCheckupInfo
 * @returns デバイスID
 */
export function deviceId(info: SleepCheckupInfo): string {
  return info.device_id ?? NULL_EXP;
}

/**
 * SleepCheckupInfoからプロジェクト名を取得する
 * @param info 対象のSleepCheckupInfo
 * @returns プロジェクト名
 */
export function projectName(info: SleepCheckupInfo): string {
  return info.project?.name ?? NULL_EXP;
}
