import { removeValue } from "@/components/elearning/quiz/QuizService";
import { Choice, QuestionAnswer } from "@/components/examinee/interview/Types";
import { FormControlLabel, Radio, Stack, Typography } from "@mui/material";

export type RadioChangeHandler = (choice: Choice) => void;

type QuizRadioButtonsProps = {
  choices: Choice[];
  answer: QuestionAnswer | null;
  disabled: boolean;
  onChange: RadioChangeHandler;
};
export function QuizRadioButtons({
  choices,
  answer,
  disabled,
  onChange,
}: QuizRadioButtonsProps) {
  return (
    <Stack direction="row" justifyContent="space-between">
      {choices.map((c) => {
        const checked =
          answer != null &&
          answer.answers.find((a) => {
            return a.id === c.id;
          }) !== undefined;

        return (
          <FormControlLabel
            key={c.id}
            sx={{ pl: 2, pr: "12%" }}
            value={c.choice_label}
            control={<Radio />}
            checked={checked}
            disabled={disabled}
            label={
              <Typography variant="body1" lineHeight="190%">
                {removeValue(c.choice_label)}
              </Typography>
            }
            onChange={() => {
              onChange(c);
            }}
          />
        );
      })}
    </Stack>
  );
}
