import {
  Box,
  BoxProps,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";

type InterviewRecordProps = {
  /** アイコン上部に表示するタイトル */
  title: string;
  /** 問診完了日、また、未回答の場合は「未回答」が指定される */
  completedDate: string | null;
  /** アイコンの幅 */
  recordIconWidth: BoxProps["width"];
  /** 未完了のときのテキスト */
  incompleteTitle?: string;
};
/**
 * 問診タイトル(事前問診,X回目問診)とその進捗を示すアイコンをまとめたコンポーネント
 */
export function InterviewRecord({
  title,
  completedDate,
  recordIconWidth,
  incompleteTitle = "未回答",
}: InterviewRecordProps) {
  const [recordTitle, recordTitleColor, iconImage] =
    completedDate != null
      ? [
          completedDate,
          "#A08B5F",
          {
            src: "/img/icon_interview_completed_circle.svg",
            alt: "completed",
          },
        ]
      : [
          incompleteTitle,
          "#FFF",
          {
            src: "/img/icon_interview_incomplete_circle.svg",
            alt: "incomplete",
          },
        ];

  return (
    <Stack spacing={2} alignItems="center">
      <Typography
        fontFamily="Montserrat"
        fontSize="10px"
        fontWeight="700"
        color="text.disabled"
      >
        {title}
      </Typography>
      <RecordIcon
        recordTitle={recordTitle}
        recordTitleColor={recordTitleColor}
        recordIconWidth={recordIconWidth}
        iconImage={iconImage}
      />
    </Stack>
  );
}

type IconImageProps = {
  /** アイコン画像のパス */
  src: string;
  /** アイコン画像の代替文 */
  alt: string;
};
type RecordIconProps = {
  /** アイコンの上に表示するタイトル */
  recordTitle: string;
  /** 問診完了日のカラー */
  recordTitleColor: TypographyProps["color"];
  /** アイコンの幅 */
  recordIconWidth: BoxProps["width"];
  /** アイコン画像のプロパティ */
  iconImage: IconImageProps;
};
/**
 * 問診の進捗を示すアイコン
 * (Box(div)の領域にテキストと画像を重ねる構成)
 */
function RecordIcon({
  recordTitle,
  recordTitleColor,
  recordIconWidth,
  iconImage,
}: RecordIconProps) {
  return (
    <Box
      position="relative"
      width={recordIconWidth}
      sx={{ aspectRatio: "1/1" }}
      // NOTE: overflow: "hidden" を設定しないと、Boxのheightがwidthより、大きくなってしまう。
      // 子要素のimgを削除すると現象が解消される。imgが関わっているようだが(おそらく、imgタグの描画サイズの計算が関わっている)、詳細は不明。
      overflow="hidden"
    >
      <Stack
        position="absolute"
        top={0}
        left={0}
        sx={{ width: "100%", height: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <RecordTypography color={recordTitleColor}>
          {recordTitle}
        </RecordTypography>
      </Stack>
      <img
        src={iconImage.src}
        alt={iconImage.alt}
        loading="lazy"
        style={{ width: "100%", height: "auto" }}
      />
    </Box>
  );
}

type RecordTypographyProps = {
  /** メッセージのカラー */
  color: TypographyProps["color"];
  /** 子要素。アイコンに表示するメッセージ、10/16のような日付を想定。 */
  children: TypographyProps["children"];
};
/**
 * 問診の進捗を示すアイコンに重ねて表示する文字列(X月Y日,未完了)用のTypography
 */
function RecordTypography({ color, children }: RecordTypographyProps) {
  return (
    <Typography
      fontFamily="Montserrat"
      color={color}
      fontWeight="700"
      lineHeight="125%"
      letterSpacing="0.15px"
      sx={{
        fontFeatureSettings: `'clig' off, 'liga' off;`,
        letterSpacing: "0.15px;",
        fontSize: { xs: "0.75rem", sm: "1rem" },
      }}
    >
      {children}
    </Typography>
  );
}
