import { Fullscreen } from "@mui/icons-material";
import { Fab } from "@mui/material";
import {
  DetailedHTMLProps,
  IframeHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { getReportPDF } from "./ReportAPI";
import { SleepCheckupInfo } from "./Types";

export type SleepCheckupReportFrameProps = Omit<
  DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>,
  "src"
> & {
  /** iframe の title 属性 */
  title: string;
  /** @see SleepCheckupInfo */
  sleepCheckupInfo?: SleepCheckupInfo | null;
  /** 専門家からのコメント */
  specialistComment: string | null;
};
const defaultStyle = { border: "none" };
export default function SleepCheckupReportFrame({
  sleepCheckupInfo,
  title,
  style,
  specialistComment,
  ...props
}: SleepCheckupReportFrameProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  // reportTypeはレポートAPIレスポンスのcontent-type。全画面表示ボタンの表示制御に使うためstateで保持する。
  // NOTE: 全画面表示はWebレポートのみ対応(PDFレポートは非対応)
  const [reportType, setReportType] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (sleepCheckupInfo?.report?.url == null) return;
      const url = sleepCheckupInfo?.report?.url;
      const response = await getReportPDF(url);
      const type = response.headers["content-type"];
      setReportType(type);

      // 返却値がPDFの場合、データ URL を表示、それ以外の場合、json としてWebレポートのURLを表示
      const iframeSrc =
        type === "application/pdf"
          ? URL.createObjectURL(new Blob([response.data], { type }))
          : `/facility/report/${sleepCheckupInfo.unique_id}`;
      iframeRef.current?.contentDocument?.location.replace(iframeSrc);
    })();
  }, [sleepCheckupInfo, specialistComment, setReportType]);

  return (
    <>
      {reportType === "application/json" && sleepCheckupInfo != null && (
        <Fab
          color="primary"
          sx={{ position: "absolute", right: 0, top: 0 }}
          component={Link}
          to={`/facility/report/${sleepCheckupInfo.unique_id}`}
          target="_blank"
        >
          <Fullscreen />
        </Fab>
      )}
      <iframe
        ref={iframeRef}
        style={{ ...defaultStyle, ...style }}
        title={title}
        src="about:blank"
        {...props}
      />
    </>
  );
}
