import { Icon, IconProps } from "@mui/material";

export type CheckBoxUncheckedFilledProps = IconProps;
/**
 * 背景が塗りつぶされている、チェックされていないチェックボックスのアイコン
 * @param props {@link IconProps}
 * @returns 背景が塗りつぶされている、チェックされていないチェックボックスのアイコン
 */
export default function CheckBoxUncheckedFilled(
  props: CheckBoxUncheckedFilledProps
) {
  return (
    <Icon {...props}>
      <img
        src={`/img/icon_CheckBoxUncheckedFilled.svg`}
        alt="CheckBoxUncheckedFilled"
      />
    </Icon>
  );
}
