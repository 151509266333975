import { Icon, IconProps } from "@mui/material";

export type RadioButtonUncheckedFilledProps = IconProps;
/**
 * 背景が塗りつぶされている、チェックされていないラジオボタンのコンポーネント
 * @param props {@link IconProps}
 * @returns 背景が塗りつぶされている、チェックされていないラジオボタンのコンポーネント
 */
export default function RadioButtonUncheckedFilled(
  props: RadioButtonUncheckedFilledProps
) {
  return (
    <Icon {...props}>
      <img
        src={`/img/icon_RadioButtonUncheckedFilled.svg`}
        alt="RadioButtonUncheckedFilled"
        loading="lazy"
      />
    </Icon>
  );
}
