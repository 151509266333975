import { Icon, IconProps } from "@mui/material";

export type CheckBoxCheckedFilledProps = IconProps;
/**
 * 背景が塗りつぶされている、チェックされているチェックボックスのアイコン
 * @param props {@link IconProps}
 * @returns 背景が塗りつぶされている、チェックされているチェックボックスのアイコン
 */
export default function CheckBoxCheckedFilled(
  props: CheckBoxCheckedFilledProps
) {
  return (
    <Icon {...props}>
      <img
        src={`/img/icon_CheckBoxCheckedFilled.svg`}
        alt="CheckBoxCheckedFilled"
      />
    </Icon>
  );
}
