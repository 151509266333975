import { Stack, Typography } from "@mui/material";
import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function ExerciseHelpDialog() {
  function ExerciseItem({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) {
    return (
      <Stack flexDirection="row" alignItems="flex-start" gap={1}>
        <Typography
          variant="body2"
          sx={{
            color: "#0056A0",
            fontSize: "14px",
            fontWeight: "500",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "14px" }}>
          {description}
        </Typography>
      </Stack>
    );
  }

  return (
    <HelpButton dialogTitle="運動">
      <Stack sx={{ gap: 6 }}>
        <SimpleParagraphs
          paragraphs={[
            {
              title: "睡眠への影響",
              content:
                "適度な運動習慣で日中に体をしっかり動かすことは、寝付きを良くし中途覚醒の減少を通じて睡眠の質を高めます。運動習慣が無い人は睡眠休養感が低いことがわかっています。",
            },
            {
              title: "おすすめ",
              content:
                "強度は中～高強度が主観的な睡眠の質などを改善しますが、まずは軽い運動から始めるのがよいでしょう。運動の頻度は週1回よりも複数回が効果的ですが、まずは運動習慣を確立することが大切です。",
            },
          ]}
        />
        <Stack
          sx={{ backgroundColor: "#F2EBDD", borderRadius: 2, gap: 2, p: 6 }}
        >
          <ExerciseItem
            title="低強度："
            description="家の中で歩く、ストレッチ、ヨガ、洗濯物の片付け、買い物、植物の水やりなど"
          />
          <ExerciseItem
            title="中強度："
            description="歩く（やや速めに）、軽い筋力トレーニング、水中歩行、太極拳、パワーヨガ、ピラティス、掃除機をかける、洗車など"
          />
          <ExerciseItem
            title="高強度："
            description="ジョギング、水泳、エアロビクス、サッカー、登山など"
          />
        </Stack>
      </Stack>
    </HelpButton>
  );
}
