import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { InterviewAppBar } from "@/components/examinee/interview/InterviewAppBar";
import { ProgressBar } from "@/components/examinee/interview/ProgressBar";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import { canBack } from "@/sleep_compass_lite/components/target/Interview";
import {
  CompletionImage,
  CompletionMessage,
} from "@/sleep_compass_lite/components/target/InterviewCompleted";
import { InterviewRecordCard } from "@/sleep_compass_lite/components/target/InterviewRecordCard";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { TargetAuthenticated as Authenticated } from "@/sleep_compass_lite/components/target/TargetAuthenticated";
import { TargetFooter as Footer } from "@/sleep_compass_lite/components/target/TargetFooter";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { createInterviewRecordInfoArray } from "@/sleep_compass_lite/presentation_lib/InterviewRecordInfo";
import { useDailyInterviewInfo } from "@/sleep_compass_lite/use_cases/target/interview/UseDailyInterviewInfo";
import { usePrimaryInterviewInfo } from "@/sleep_compass_lite/use_cases/target/interview/UsePrimaryInterviewInfo";
import { Box, Container, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * 事前問診完了ページ
 */
export function PrimaryInterviewCompleted({ headerHeight }: ExamineePageProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const [primaryInterviewInfo, primaryInterviewError] =
    usePrimaryInterviewInfo();
  const [dailyInterviewInfo, dailyInterviewError] = useDailyInterviewInfo();

  const error = [primaryInterviewError, dailyInterviewError].find(
    (e) => e != null
  );
  if (error != null) {
    return <NonFieldError>{getErrorMessage(error)}</NonFieldError>;
  }

  const handleBack = () => {
    navigate(-1);
  };

  const FOOTER_HEIGHT = "151px";
  const BACKGROUND_COLOR = "background.default";
  const DAILY_INTERVIEW_LEN = 3; // 毎日問診の規定の回答回数

  // 問診の記録の表示用データを作成する(APIレスポンスを取得できていないときは空のデータをセットする)
  const interviewRecord =
    primaryInterviewInfo != null && dailyInterviewInfo != null
      ? createInterviewRecordInfoArray(
          primaryInterviewInfo,
          dailyInterviewInfo,
          DAILY_INTERVIEW_LEN
        )
      : [];

  return (
    <Authenticated>
      <ScrollToTop />
      <Box
        sx={{
          minHeight: "100vh",
          position: "relative",
          backgroundColor: "background.blankSpace",
        }}
      >
        <InterviewAppBar
          height={headerHeight}
          title="回答完了"
          onClickBack={canBack(location) ? handleBack : undefined}
        />
        <Container
          maxWidth="sm"
          sx={{
            p: 0,
            pt: 0,
            pl: { xs: 0, sm: 0 },
            pr: { xs: 0, sm: 0 },
            pb: FOOTER_HEIGHT,
            backgroundColor: BACKGROUND_COLOR,
          }}
        >
          <Box sx={{ backgroundColor: "white" }}>
            <Stack sx={{ py: 6, px: PX4 }} alignItems="center">
              <ProgressBar sx={{ width: "100%" }} value={100} />
              <CompletionImage sx={{ pt: 12, pb: 6, px: "41.5px" }} />
              <CompletionMessage
                completionMessageTitle="おつかれさまでした！"
                completionMessage={`ご回答ありがとうございました\n事前問診は以上です`}
              />
            </Stack>
          </Box>
          <InterviewRecordCard
            sx={{ mx: PX4, mt: 8, mb: 16 }}
            interviewRecordCardTitle="これからの流れ"
            interviewRecordDescription="本日から、勤務中の状態と前日の睡眠について回答いただくためのメールが配信されます。配信期間は1週間となり、そのうち勤務日に3日間回答すると、今回の調査は完了です。"
            interviewRecordInfo={interviewRecord}
          />
        </Container>
        <Footer
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: FOOTER_HEIGHT,
          }}
        />
      </Box>
    </Authenticated>
  );
}
