import { useScreenLock } from "@/components/ScreenLock";
import {
  EMAIL_ADDRESS_REGEX,
  PASSWORD_SYMBOL_REGEX,
} from "@/components/activation/Constants";
import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { StateType as ConfirmationStateType } from "@/pages/examinee/activation/Confirmation";
import { getApiServerUrl } from "@/utils/axios";
import { validateBirthday } from "@/utils/date";
import { CheckCircle, Circle } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const SignUp = () => {
  const API_URL = getApiServerUrl();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [emailErrorMessages, setEmailErrorMessages] = useState<string[]>([]);
  const [passwordErrorMessages, setPasswordErrorMessages] = useState<string[]>(
    []
  );
  const [birthdayErrorMessages, setBirthdayErrorMessages] = useState<string[]>(
    []
  );
  const [confirmationCodeErrorMessages, setConfirmationCodeErrorMessages] =
    useState<string[]>([]);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLocked, setLock] = useScreenLock();

  const validators: Record<string, () => string[] | null> = {
    // validatorは引数を受け取らず実行され、エラーが無ければ何もreturnしない。
    // エラーがあった場合はエラーメッセージの配列を返す。
    confirmationCode: () => {
      if (!confirmationCode.match(/^\d{6}$/)) {
        return ["※半角数字6桁で入力してください"];
      }
      return null;
    },
    birthday: () => validateBirthday(birthday),
    email: () => {
      const errors: string[] = [];
      if (!email.match(EMAIL_ADDRESS_REGEX)) {
        errors.push("※メールアドレスの形式が正しくありません");
      }
      if (email.length > 254) {
        errors.push("※メールアドレスは254文字以下にしてください");
      }
      return errors.length ? errors : null;
    },
    passwordLength: () => {
      if (password.length < 8 || 24 < password.length) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordAlphabetLowerAndUpper: () => {
      if (!(password.match(/[a-z]/) && password.match(/[A-Z]/))) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordDigit: () => {
      if (!password.match(/\d/)) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordSymbol: () => {
      if (!password.match(PASSWORD_SYMBOL_REGEX)) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordInvalidChars: () => {
      if (
        password.replace(PASSWORD_SYMBOL_REGEX, "").replace(/[a-zA-Z\d]/g, "")
      ) {
        return ["※使用できない文字が含まれています"];
      }
      return null;
    },
  };

  const handleClickRegister = () => {
    if (isLocked) {
      // 画面ロック中の場合、登録処理を行わない
      return;
    }
    setLock(true);
    if (Object.values(validators).some((v) => v())) {
      // 何かしらのエラーがある場合は登録処理を行わない
      return;
    }
    const birthdayWithHyphen = birthday.replace(
      /(\d{4})(\d{2})(\d{2})/,
      "$1-$2-$3"
    );
    axios
      .post(`${API_URL}/api/registration_entry/`, {
        email: email,
        password: password,
        birthday: birthdayWithHyphen,
        confirmation_code: confirmationCode,
        cognito_verification_code: null,
      })
      .then((res) => {
        navigate("/examinee/confirmation", {
          state: {
            email,
            password,
            birthdayWithHyphen: birthdayWithHyphen,
            confirmationCode,
            registrationEntryId: res.data.id,
            sleepCheckupUuid: res.data.sleep_checkup,
            medicalFacilityId: res.data.medical_facility,
          } as ConfirmationStateType,
        });
      })
      .catch((err) => {
        setLock(false); // エラー時はロックを解放する。
        const statusCode = err.response?.status;
        const messages = err.response?.data?.error_messages;
        let isKnownError = false;
        if (messages && messages.length > 0) {
          isKnownError = true;
          // 400系エラーでエラーメッセージが存在する場合はそれを表示する
          setErrorMessage(messages[0]);
        }

        const emailErrors = err.response?.data?.email;
        if (emailErrors) {
          isKnownError = true;
          setEmailErrorMessages(emailErrors);
        }
        const passwordErrors = err.response?.data?.password;
        if (passwordErrors) {
          isKnownError = true;
          setPasswordErrorMessages(passwordErrors);
        }
        const birthdayErrors = err.response?.data?.birthday;
        if (birthdayErrors) {
          isKnownError = true;
          setBirthdayErrorMessages(birthdayErrors);
        }
        const confirmationCodeErrors = err.response?.data?.confirmation_code;
        if (confirmationCodeErrors) {
          isKnownError = true;
          setConfirmationCodeErrorMessages(confirmationCodeErrors);
        }

        if (
          statusCode &&
          400 <= statusCode &&
          statusCode < 500 &&
          !isKnownError
        ) {
          setErrorMessage(
            "エラーが発生しました。申し訳ありませんが、少し時間をおいてから再度お試しください。"
          );
        }

        // エラーアラートが画面内に表示されるようにスクロールをリセットする
        window.scrollTo(0, 0);
      });
  };

  return (
    <>
      <ScrollToTop />
      <Stack spacing={8} sx={{ mt: 8, mb: 12, mx: 4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            新規アカウント登録
          </Typography>

          {/* エラーメッセージ */}
          <NonFieldError>{errorMessage}</NonFieldError>

          <Typography
            variant="subtitle2"
            textAlign="justify"
            sx={{ color: "text.secondary" }}
          >
            以下のフォームに本人確認用の受付番号、生年月日、登録確認用メールを受信できるメールアドレス、ログイン時に使用するパスワードを入力し、[登録する]ボタンを押してください。
          </Typography>

          <TextForm
            title="受付番号"
            placeholder="数字6桁"
            value={confirmationCode}
            onChange={(e) => {
              setConfirmationCode(e.target.value);
            }}
            validator={validators.confirmationCode}
            helperText="※実施機関から届いた「アカウント登録のご案内」に記載のある数字6桁をご記入ください"
            givenErrors={confirmationCodeErrorMessages}
          />
          <TextForm
            title="生年月日"
            placeholder="例：20230301"
            value={birthday}
            onChange={(e) => {
              setBirthday(e.target.value);
            }}
            validator={validators.birthday}
            givenErrors={birthdayErrorMessages}
          />
          <TextForm
            title="メールアドレス"
            placeholder="例：sleep-compass@example.com"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            validator={validators.email}
            helperText="※測定期間中(休日を含む)、毎日確認ができるメールアドレスを入力してください"
            givenErrors={emailErrorMessages}
          />
          <TextForm
            title="新しいパスワード"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type="password"
            helperText={
              <Stack>
                <PasswordCheckListItem
                  text="8文字以上24文字以下にしてください"
                  validator={validators.passwordLength}
                />
                <PasswordCheckListItem
                  text="大文字と小文字の半角英字を含めてください"
                  validator={validators.passwordAlphabetLowerAndUpper}
                />
                <PasswordCheckListItem
                  text="半角数字を含めてください"
                  validator={validators.passwordDigit}
                />
                <PasswordCheckListItem
                  text="記号を含めてください"
                  validator={validators.passwordSymbol}
                />
              </Stack>
            }
            validator={validators.passwordInvalidChars}
            givenErrors={passwordErrorMessages}
          />
        </Stack>
      </Stack>
      <Stack>
        <Button
          variant="contained"
          size="large"
          sx={{ mx: 4, mb: 16 }}
          onClick={handleClickRegister}
          disabled={Object.values(validators).some((v) => v()) || isLocked}
        >
          登録する
        </Button>
      </Stack>
    </>
  );
};

type PasswordCheckListProps = {
  text: string;
  validator: () => string[] | null;
};
export const PasswordCheckListItem = ({
  text,
  validator,
}: PasswordCheckListProps) => {
  const getPasswordCheckListItemColor = (validationResult: string[] | null) => {
    if (validationResult) {
      return "primary.main";
    }
    return "text.disabled";
  };

  const getPasswordCheckListItemIcon = (validationResult: string[] | null) => {
    if (validationResult) {
      return <Circle fontSize="inherit" />;
    }
    return <CheckCircle fontSize="inherit" />;
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={"9px"}
        sx={{
          color: getPasswordCheckListItemColor(validator()),
        }}
      >
        {getPasswordCheckListItemIcon(validator())}
        <Typography
          variant="caption"
          sx={{
            color: getPasswordCheckListItemColor(validator()),
          }}
        >
          {text}
        </Typography>
      </Stack>
    </>
  );
};
