import { Property } from "csstype";

type TeacherImageProps = {
  width: Property.Width;
};
export default function TeacherImage({ width }: TeacherImageProps) {
  return (
    <img
      src="/img/image_quiz_teacher.png"
      alt="睡眠クイズ"
      loading="lazy"
      style={{ width: width, height: "auto" }}
    />
  );
}
