import { Icon, IconProps } from "@mui/material";

export type RadioButtonCheckedFilledProps = IconProps;
/**
 * 背景が塗りつぶされている、チェックされているラジオボタンのコンポーネント
 * @param props {@link IconProps}
 * @returns 背景が塗りつぶされている、チェックされているラジオボタンのコンポーネント
 */
export default function RadioButtonCheckedFilled(
  props: RadioButtonCheckedFilledProps
) {
  return (
    <Icon {...props}>
      <img
        src={`/img/icon_RadioButtonCheckedFilled.svg`}
        alt="RadioButtonCheckedFilled"
        loading="lazy"
      />
    </Icon>
  );
}
