import { QuestionFormInfo } from "@/components/elearning/quiz/QuizFormInfo";
import { Box, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

type QuizTextProps = {
  sx?: SxProps<Theme>;
  info: QuestionFormInfo;
};
export function QuizText({ sx, info }: QuizTextProps) {
  return (
    <Box sx={sx}>
      <Typography component="span" variant="body2">
        設問が正しいと思う場合は「
        {info.getTrueText()}
        」を、間違っていると思う場合は「
        {info.getFalseText()}
        」を選んで、「
        {info.questionButtonTitle}
        」ボタンを押してください。
      </Typography>
    </Box>
  );
}
