import {
  getLeftMessage,
  getRightMessage,
} from "@/components/examinee/interview/ChoiceLabelService";
import {
  LABEL_CONTAINED_VALUE_REGEX,
  SELECT_CHOICE_DELIMITER,
} from "@/components/examinee/interview/Constants";

export class NumberSelectItem {
  leftMessage: string | null;
  rightMessage: string | null;
  optionValues: string[];
  selectedValueIndex: number;
  index: number;

  constructor(
    leftMessage: string | null,
    rightMessage: string | null,
    optionValues: string[],
    selectedValueIndex: number,
    index: number
  ) {
    this.leftMessage = leftMessage;
    this.rightMessage = rightMessage;
    this.optionValues = optionValues;
    this.selectedValueIndex = selectedValueIndex;
    this.index = index;
  }
}

export function getNumberSelectItems(
  label: string,
  noSelect: string,
  answerText: string,
  startIndex: number
): NumberSelectItem[] {
  const selectors: RegExpMatchArray[] = [
    ...label.matchAll(LABEL_CONTAINED_VALUE_REGEX),
  ];

  const answerSelectors: RegExpMatchArray[] = [
    ...answerText.matchAll(LABEL_CONTAINED_VALUE_REGEX),
  ];

  return selectors.map((s, i, selectors) => {
    const optionValues = getOptionValues(s, noSelect);

    const selectedValueIndex = getSelectedValueIndex(
      optionValues,
      answerSelectors[i],
      noSelect
    );

    return new NumberSelectItem(
      getLeftMessage(label, s, i, selectors),
      getRightMessage(label, s, i, selectors),
      optionValues,
      selectedValueIndex,
      startIndex + i
    );
  });
}

function getOptionValues(
  selector: RegExpMatchArray,
  noSelect: string
): string[] {
  // オプションは、例えば、[0:60:10] のとき、「0」以上「60」未満の数値を「10」刻みで列挙という意味で、0,10,20,30,40,50になる。刻み幅はオプショナルで、指定がない場合は1。
  // 先頭と末尾の文字（[]）を削除して（:）で分割。selectorの形式は start:end:[step] で、stepの指定がない場合は1にする。
  const splitted = selector[0]
    .slice(1, -1)
    .split(SELECT_CHOICE_DELIMITER)
    .map(Number);
  const first = splitted[0];
  const last = splitted[1];
  const step = splitted.length === 3 ? splitted[2] : 1;
  // 選択肢の先頭には未選択を意味する文字列（e.g. 「--」）を追加する
  const optionValues = [
    noSelect,
    ...Array.from(Array((last - first) / step), (_, i) =>
      String(first + i * step)
    ),
  ];

  return optionValues;
}

function getSelectedValueIndex(
  optionValues: string[],
  selector: RegExpMatchArray,
  noSelect: string
): number {
  const value: string = selector[0].slice(1, -1);
  const index = optionValues.indexOf(value);
  if (index === -1) {
    return optionValues.indexOf(noSelect);
  }
  return index;
}

export function hasNumberChoice(label: string): boolean {
  return label.match(LABEL_CONTAINED_VALUE_REGEX) != null;
}
