import { Stack, StackProps } from "@mui/material";
import { ReactNode, useCallback, useEffect, useRef } from "react";

/** フローティング状態の変更が通知されるイベントハンドラの型定義 */
type OnChangeFloatingState = (isFloating: boolean) => void;

type StickyStackProps = {
  /** フローティングする際の画面下部からの位置 */
  bottom?: number;
  /** Stackに適応するスタイル */
  sx?: StackProps["sx"];
  /** Stackの子コンポーネント */
  children: ReactNode;
  /** フローティング状態の変更が通知されるイベントハンドラ */
  onChangeFloatingState?: OnChangeFloatingState;
};
/**
 * フローティングボタン用のStackコンポーネント
 */
export function StickyStack({
  bottom = 0,
  sx,
  children,
  onChangeFloatingState,
}: StickyStackProps) {
  const stickyStackRef = useRef<HTMLDivElement>(null);
  // フローティング中か否かのフラグ。コンポーネントが描画されるまでフローティング中か否かは不明なので、初期値はnull
  const isFloatingRef = useRef<boolean | null>(null);

  // フローティング中か否かの判定
  const getIsFloating = useCallback(() => {
    if (stickyStackRef.current == null) {
      return false;
    }
    // Stackが画面最下部に配置されていたらフローティング中とする
    const stackRect = stickyStackRef.current.getBoundingClientRect();
    return stackRect.bottom === document.documentElement.clientHeight - bottom;
  }, [stickyStackRef, bottom]);

  // フローティング中か否かの判定を実施するために、スクロールイベントをハンドリングする
  const handleScroll = useCallback(() => {
    const current = getIsFloating();
    if (isFloatingRef.current !== current) {
      isFloatingRef.current = current;
      onChangeFloatingState?.(current);
    }
  }, [getIsFloating, onChangeFloatingState]);

  // スクロールイベントのハンドラーの登録と解除
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Stack ref={stickyStackRef} position="sticky" bottom={bottom} sx={sx}>
      {children}
    </Stack>
  );
}
