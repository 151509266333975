import {
  MedicalFacilityUser,
  MedicalFacilityUserEdit,
} from "@/components/sleep_checkup_v1/MedicalFacilityUser";
import { MedicalFacilityUserSearchParams as SearchParams } from "@/components/sleep_checkup_v1/MedicalFacilityUserSearchParams";
import { facilityAxios as axios } from "@/utils/axios";

/**
 * 施設ユーザー取得APIを呼び出し、APIのレスポンスを返す
 * @param params 検索パラメータ
 * @param page ページ
 * @param sort 並び順
 * @param rowsPerPage ページあたりの施設ユーザーの個数
 * @returns APIのレスポンス
 */
export function getMedicalFacilityUser(
  params: SearchParams,
  page: number,
  sort: string,
  rowsPerPage: number
) {
  return axios.get("/api/facility/medical_facility_user/", {
    params: {
      ...(Object.keys(params) as (keyof SearchParams)[])
        .filter((key) => params[key] != null && params[key] !== "")
        .reduce((result: any, key) => {
          result[key] = params[key];
          return result;
        }, {}),
      page: page,
      ordering: sort,
      page_size: rowsPerPage,
    },
  });
}

/**
 * 施設ユーザー取得APIを呼び出し、APIのレスポンスを返す
 * @param facilityUser 作成する施設ユーザー
 * @returns APIのレスポンス
 */
export function createMedicalFacilityUser(facilityUser: MedicalFacilityUser) {
  return axios.post(`/api/facility/medical_facility_user/`, facilityUser);
}

/**
 * 施設ユーザー編集APIを呼び出し、APIのレスポンスを返す
 * @param facilityUser 編集する施設ユーザー
 * @returns APIのレスポンス
 */
export function editMedicalFacilityUser(facilityUser: MedicalFacilityUser) {
  if (facilityUser.id == null) {
    return Promise.reject(new Error("facilityUser.id can not be null"));
  }
  const param = new MedicalFacilityUserEdit(facilityUser);
  return axios.patch(
    `/api/facility/medical_facility_user/${facilityUser.id}/`,
    param
  );
}

/**
 * 施設ユーザー取得APIを呼び出し、APIのレスポンスを返す。getMedicalFacilityUserとの違いはパラメータとレスポンス。こちらはパラメータがなく、レスポンスも小さくなっていて、一括取得向け。
 * @returns APIのレスポンス
 */
export function getSimpleMedicalFacilityUser() {
  return axios.get("/api/facility/user_facility_users/");
}
