import { Tab, Tabs, TabsProps } from "@mui/material";
import { ReactNode, SyntheticEvent } from "react";

/**
 * 表示対象のタブのキー
 */
export type TabKey = "sleep_report" | "disease_risk_report";
const TAB_TYPES: Record<TabKey, ReactNode> = {
  sleep_report: "睡眠レポート",
  disease_risk_report: "疾患リスクレポート",
} as const;

/**
 * {@link SleepCheckupDetailTabs 測定詳細のタブ} のプロパティ
 */
export interface SleepCheckupDetailTabsProps
  extends Omit<TabsProps, "value" | "onChange" | "children"> {
  /** 表示するタブ */
  tabs: TabKey[];
  /** 選択されているタブ */
  value: TabKey;
  /** 変更された際に呼び出されるリスナー */
  onChange: (event: SyntheticEvent<Element, Event>, tabKey: TabKey) => void;
}

/**
 * 測定詳細のタブです。
 * 測定詳細では一部のタブが表示／非表示切り替わるため、index ではなく {@link TabKey} で状態を管理します。
 *
 * @param param0 {@link SleepCheckupDetailTabsProps}
 * @returns 測定詳細タブ
 */
export default function SleepCheckupDetailTabs({
  tabs,
  value,
  onChange,
  ...props
}: SleepCheckupDetailTabsProps) {
  return (
    <Tabs indicatorColor="primary" value={value} onChange={onChange} {...props}>
      {tabs.map((v, i) => (
        <Tab key={i} value={v} label={TAB_TYPES[v]} />
      ))}
    </Tabs>
  );
}
