import { ScrollToTop } from "@/components/common/ScrollToTop";
import {
  BASIC_KNOWLEDGE_CHAPTERS,
  CIRCADIAN_RHYTHM_TIPS,
  ChapterListItem,
} from "@/components/elearning/chapter/constants";
import { ElearningChapterList } from "@/components/elearning/common/ElearningChapterList";
import { useElearningSearchParams } from "@/pages/elearning/ElearningLayout";
import { Container, Divider, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import {
  SupervisionTypography,
  TitleTypography,
} from "../../common/Typographies";

export function Layout({
  children,
  title,
}: {
  children: ReactNode;
  title: ReactNode;
}) {
  const location = useLocation();
  const elearningSearchParams = useElearningSearchParams();

  const listItems = createChapterListItems(location.pathname);

  return (
    <Container
      sx={{ backgroundColor: "#FFFFFF", padding: "32px 16px 0px 16px" }}
    >
      <ScrollToTop />
      <TitleTypography>{title}</TitleTypography>
      <SupervisionTypography>
        監修：広島国際大学 健康科学部 心理学科 田中秀樹
      </SupervisionTypography>
      <Divider />
      {children}
      <Divider />
      <Typography variant="h6" sx={{ marginTop: "33px" }}>
        あわせて読みたい
      </Typography>
      <ElearningChapterList
        items={listItems}
        elearningSearchParams={elearningSearchParams}
      />
    </Container>
  );
}

function createChapterListItems(exclude: string): ChapterListItem[] {
  const chapters = [
    ...BASIC_KNOWLEDGE_CHAPTERS,
    ...CIRCADIAN_RHYTHM_TIPS,
  ].filter((chapter) => chapter.to !== exclude);
  return [new ChapterListItem(null, chapters)];
}
