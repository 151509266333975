import { SimpleMedicalFacilityUser as FacilityUser } from "@/components/sleep_checkup_v1/MedicalFacilityUser";

/**
 * 施設ユーザーのフルネーム(last_nameとfirst_nameを連結した文字列)を返す
 * @param facilityUser 対象の施設ユーザー
 * @returns フルネーム
 */
export function fullName(facilityUser: FacilityUser): string {
  return `${facilityUser.user.last_name} ${facilityUser.user.first_name}`;
}
