import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function BedtimeEnvironmentHelpDialog() {
  return (
    <HelpButton dialogTitle="就寝環境">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "睡眠への影響",
            content: "光・音・温度・湿度など就寝環境も睡眠に影響を与えます。",
          },
          {
            title: "おすすめ",
            content:
              "明るさや音が気になる場合は、カーテンを防音や遮光機能のあるものに取り換えるとよいでしょう。温度・湿度は朝まで適温を保つようにしましょう。",
          },
        ]}
      />
    </HelpButton>
  );
}
