import { getPages } from "@/components/sleep_checkup_v1/GroupService";
import { PageSwitch } from "@/components/sleep_checkup_v1/PageSwitch";
import { paths } from "@/components/sleep_checkup_v1/Path";
import {
  AccountMenuButton,
  AccountMenuList,
  SCAppBar,
} from "@/components/sleep_checkup_v1/SCAppBar";
import { DailyInterviewResult } from "@/pages/facility/sleep_checkup_v1/DailyInterviewResult";
import { PrimaryInterviewResult } from "@/pages/facility/sleep_checkup_v1/PrimaryInterviewResult";
import { SleepCheckupDetail } from "@/pages/facility/sleep_checkup_v1/SleepCheckupDetail";
import { SleepCheckupList } from "@/pages/facility/sleep_checkup_v1/SleepCheckupList";
import { UserList } from "@/pages/facility/sleep_checkup_v1/UserList";
import { facilityTheme } from "@/theme/themeV1";
import { SleepCheckupUserStore, signOut } from "@/utils/auth";
import constants from "@/utils/constants";
import { ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ja from "date-fns/locale/ja";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { SleepCheckupGuidance } from "./sleep_checkup_v1/SleepCheckupGuidance";

export default function FacilityLayout() {
  const [accountMenuOpen, setAccountMenuOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const MIN_WIDTH = 1098;

  const user = SleepCheckupUserStore.getItem();

  useEffect(() => {
    if (user == null) {
      // 他施設のログイン画面（サブドメ）にアクセスできないように、未ログインの場合はログイン画面にリダイレクトする
      navigate("/facility/signin");
    }
  }, [user, navigate]);

  const handleAccountMenuOpen = () => {
    setAccountMenuOpen(true);
  };

  const handleAccountMenuClose = () => {
    setAccountMenuOpen(false);
  };

  const handleLogout = () => {
    signOut();
    navigate(constants.SIGNIN_URL_MEDICAL_FACILITY_USER);
  };

  return (
    <ThemeProvider theme={facilityTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <SCAppBar
          toolbarItem={
            user != null ? (
              <PageSwitch
                pages={getPages(user.group_names)}
                selected={paths.getRootPageFull(location.pathname)}
              />
            ) : (
              <></>
            )
          }
          accountMenuButton={
            <AccountMenuButton
              accountMenuList={<AccountMenuList onClickLogout={handleLogout} />}
              openMenu={accountMenuOpen}
              onClick={handleAccountMenuOpen}
              onClose={handleAccountMenuClose}
            >
              {user != null ? user.username : ""}
            </AccountMenuButton>
          }
          minWidth={MIN_WIDTH}
        />
        <Routes>
          <Route
            path={paths.getPath("SleepCheckupList")}
            element={<SleepCheckupList minWidth={MIN_WIDTH} />}
          />
          <Route
            path={paths.getPath("SleepCheckupDetail")}
            element={<SleepCheckupDetail minWidth={MIN_WIDTH} />}
          />
          <Route
            path={paths.getPath("UserList")}
            element={<UserList minWidth={MIN_WIDTH} />}
          />
          <Route
            path={paths.getPath("PrimaryInterviewResult")}
            element={<PrimaryInterviewResult minWidth={MIN_WIDTH} />}
          />
          <Route
            path={paths.getPath("DailyInterviewResult")}
            element={<DailyInterviewResult minWidth={MIN_WIDTH} />}
          />
          <Route
            path={paths.getPath("SleepCheckupGuidance")}
            element={<SleepCheckupGuidance minWidth={MIN_WIDTH} />}
          />
          <Route
            path={paths.getPath("GuidancePrimaryInterviewResult")}
            element={
              <PrimaryInterviewResult
                minWidth={MIN_WIDTH}
                breadcrumbType="guidance"
              />
            }
          />
          <Route
            path={paths.getPath("GuidanceDailyInterviewResult")}
            element={
              <DailyInterviewResult
                minWidth={MIN_WIDTH}
                breadcrumbType="guidance"
              />
            }
          />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
