import { NavigateNext } from "@mui/icons-material";
import {
  Breadcrumbs,
  Card,
  CardContent,
  CardMedia,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { formatDisplayTime } from "@/utils/date";
import { format, parse } from "date-fns";
import Highcharts, { SeriesLegendItemClickEventObject } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useRef } from "react";
import ReportNavigate from "./commons/ReportNavigate";
import {
  SleepCharacteristicsDetailData,
  SleepCharacteristicsDetailResponse,
  UserType,
} from "./types";

export function SleepCharacteristicsDetail(props: {
  data: SleepCharacteristicsDetailResponse;
  userType: UserType;
  reportUid: string;
}) {
  const theme = useTheme();
  const navigates = [
    {
      icon: {
        src: "/img/icon_report_rhythm.svg",
        alt: "rhythm",
      },
      text: "睡眠のリズム",
      link: `/${props.userType}/report/${props.reportUid}/rhythm`,
    },
    {
      icon: {
        src: "/img/icon_report_quantity.svg",
        alt: "quantity",
      },
      text: "睡眠の長さ",
      link: `/${props.userType}/report/${props.reportUid}/quantity`,
    },
    {
      icon: {
        src: "/img/icon_report_quality.svg",
        alt: "quality",
      },
      text: "睡眠の質",
      link: `/${props.userType}/report/${props.reportUid}/quality`,
    },
    {
      icon: {
        src: "/img/icon_report_drowsiness.svg",
        alt: "drowsiness",
      },
      text: "日中の眠気",
      link: `/${props.userType}/report/${props.reportUid}/drowsiness`,
    },
    {
      icon: {
        src: "/img/icon_report_fatigue.svg",
        alt: "fatigue",
      },
      text: "日中の疲労",
      link: `/${props.userType}/report/${props.reportUid}/fatigue`,
    },
  ];
  return (
    <Stack>
      <Stack sx={{ p: "40px 16px 24px 16px", gap: "8px" }}>
        <Breadcrumbs
          maxItems={2}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            href={`/${props.userType}/report/${props.reportUid}`}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body2">レポートTOP</Typography>
          </Link>
          <Link
            href={`/${props.userType}/report/${props.reportUid}/quality`}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body2">睡眠の質</Typography>
          </Link>
          <Typography variant="body2">睡眠の特徴詳細</Typography>
        </Breadcrumbs>
        <Typography variant="h5" sx={{ color: "primary.main" }}>
          睡眠の特徴詳細
        </Typography>
      </Stack>
      <Stack sx={{ p: "0px 16px 40px 16px" }}>
        <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
          睡眠の質を下げる原因の3種類を分解して確認しましょう。
        </Typography>
      </Stack>
      <Stack
        sx={{
          backgroundColor: "background.default",
          p: "40px 16px 80px 16px",
          gap: "24px",
        }}
      >
        <Card sx={{ boxShadow: "0px 0px 0px 1px #E0E0E0" }}>
          <CardMedia
            component="img"
            image="/img/image_sleep_characteristics_detail_initiating_sleep_difficulty.png"
            alt="initiating_sleep_difficulty"
          />
          <CardContent sx={{ px: "16px" }}>
            <Stack sx={{ gap: "24px" }}>
              <Stack>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ fontSize: "20px" }}
                >
                  寝付きが悪い（特徴A）
                </Typography>
              </Stack>
              <Stack>
                <Chart
                  color={theme.palette.primary.light}
                  name="寝付くまでの時間"
                  warningName="寝付けなくて困った日"
                  referenceValue={
                    props.data.initiating_sleep_difficulty.reference_value
                  }
                  data={
                    props.data.initiating_sleep_difficulty.time_to_fall_asleep
                  }
                />
                <CharacteristicRow
                  data={
                    props.data.initiating_sleep_difficulty.time_to_fall_asleep
                  }
                />
              </Stack>
              <Stack>
                <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                  {props.data.initiating_sleep_difficulty.comment}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        <Card sx={{ boxShadow: "0px 0px 0px 1px #E0E0E0" }}>
          <CardMedia
            component="img"
            image="/img/image_sleep_characteristics_detail_awakenings.png"
            alt="awakenings"
          />
          <CardContent sx={{ px: "16px" }}>
            <Stack sx={{ gap: "24px" }}>
              <Stack>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ fontSize: "20px" }}
                >
                  睡眠中の覚醒が多い (特徴B)
                </Typography>
              </Stack>
              <Stack>
                <Chart
                  color={theme.palette.primary.main}
                  name="覚醒時間(合計)"
                  warningName="途中で起きて困った日"
                  referenceValue={props.data.awakenings.reference_value}
                  data={props.data.awakenings.wakeup_times}
                />
                <CharacteristicRow data={props.data.awakenings.wakeup_times} />
              </Stack>
              <Stack>
                <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                  {props.data.awakenings.comment}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        <Card sx={{ boxShadow: "0px 0px 0px 1px #E0E0E0" }}>
          <CardMedia
            component="img"
            image="/img/image_sleep_characteristics_detail_premature_morning_awakening.png"
            alt="premature_morning_awakening"
          />
          <CardContent sx={{ px: "16px" }}>
            <Stack sx={{ gap: "24px" }}>
              <Stack>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ fontSize: "20px" }}
                >
                  早く起きてしまう(特徴C)
                </Typography>
              </Stack>
              <Stack>
                <Chart
                  color={theme.palette.primary.dark}
                  name="目が覚めてから起床までの時間"
                  warningName="早く目が覚めて困った日"
                  referenceValue={
                    props.data.premature_morning_awakening.reference_value
                  }
                  data={props.data.premature_morning_awakening.time_to_wake_up}
                />
                <CharacteristicRow
                  data={props.data.premature_morning_awakening.time_to_wake_up}
                />
              </Stack>
              <Stack>
                <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                  {props.data.premature_morning_awakening.comment}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <Stack sx={{ p: "32px 16px 0px 16px" }}>
        <Typography variant="h6" sx={{ color: "text.secondary" }}>
          あわせて読みたい
        </Typography>
        <ReportNavigate reportUid={props.reportUid} navigates={navigates} />
      </Stack>
    </Stack>
  );
}

function CharacteristicRow(props: { data: SleepCharacteristicsDetailData[] }) {
  return (
    <Stack direction="row" ml={"32px"} mr={"10px"}>
      {props.data.map((d, i) => (
        <Typography
          key={i}
          sx={{
            flex: 1,
            textAlign: "center",
            color: d.have_trouble ? "error.main" : "text.secondary",
          }}
        >
          {d.have_trouble ? (
            <img
              src="/img/red_dot.svg"
              loading="lazy"
              alt="red_dot"
              width="12px"
              height="12px"
            />
          ) : (
            <img
              src="/img/dot.svg"
              loading="lazy"
              alt="dot"
              width="12px"
              height="12px"
            />
          )}
        </Typography>
      ))}
    </Stack>
  );
}

interface ChartProps {
  color: string | Highcharts.GradientColorObject | Highcharts.PatternObject;
  name: string;
  warningName: string;
  referenceValue: number;
  data: SleepCharacteristicsDetailData[];
}

function Chart(props: ChartProps) {
  const theme = useTheme();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const max = Math.max(...props.data.map(({ value }) => value));
  const options: Highcharts.Options = {
    chart: {
      type: "column",
      marginLeft: 32,
      marginBottom: 6,
      style: {
        fontFamily: "Noto Sans JP",
      },
      height: "200px",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: props.data.map((d) => {
        const date = parse(d.date, "yyyy-MM-dd", new Date());
        return `${format(date, "M/d")}<br>${"日月火水木金土"[date.getDay()]}`;
      }),
      lineWidth: 0,
      opposite: true,
      offset: 0,
      margin: 0,
      labels: {
        rotation: 0,
        style: {
          fontSize: "10px",
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      // NOTE: カラムデータの最大値が推奨時間より小さい場合（次行の理由より、正確にはreferenceValue + 20より小さい場合）、推奨時間のy軸線が非表示となってしまう為、表示する様にしている
      //       カラムデータのラベルがカラム自身と重ならない様にする為、referenceValueに20加算している
      max: max < props.referenceValue + 20 ? props.referenceValue + 20 : max,
      gridLineWidth: 0,
      plotLines: [
        {
          value: 0,
          color: "#e0e0e0",
        },
        {
          color: "#00a99d",
          value: props.referenceValue,
          width: 2,
          label: {
            x: -32,
            y: 2.5,
            text: "1時間",
            style: {
              color: "#00A99D",
              fontFamily: "Noto Sans JP",
              fontSize: "10px",
            },
          },
        },
      ],
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      symbolHeight: 10,
      symbolRadius: 0,
    },
    series: [
      {
        type: "column",
        color: props.color,
        name: props.name,
        dataLabels: {
          enabled: true,
          formatter() {
            return props.data[this.point.index].is_warning
              ? `<span style="color: ${
                  theme.palette.error.main
                }; font-weight: bold;">${formatDisplayTime(
                  Number(this.y)
                )}</span>`
              : `<span style="background-color: #FFFFFF; text-shadow: .4px .4px 0 #FFFFFF, .4px -.4px 0 #FFFFFF, -.4px .4px 0 #FFFFFF, -.4px -.4px 0 #FFFFFF, .4px 0px 0 #FFFFFF, 0px .4px 0 #FFFFFF, -.4px 0px 0 #FFFFFF, 0px -.4px 0 #FFFFFF;">${formatDisplayTime(
                  Number(this.y)
                )}<span>`;
          },
          style: {
            fontSize: "8px",
            fontWeight: "normal",
            fontFamily: "Noto Sans JP",
            textOutline: "none",
          },
        },
        events: {
          legendItemClick(e: SeriesLegendItemClickEventObject) {
            e.preventDefault();
          },
        },
        data: props.data.map((d) => d.value),
      },
      {
        type: "scatter",
        marker: {
          radius: 5,
        },
        color: theme.palette.error.main,
        name: props.warningName,
        data: undefined,
        stack: 0,
        events: {
          legendItemClick(e: SeriesLegendItemClickEventObject) {
            e.preventDefault();
          },
        },
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
}
