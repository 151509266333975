import { formatDisplayTime } from "@/utils/date";
import { NavigateNext } from "@mui/icons-material";
import {
  Breadcrumbs,
  Divider,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { format, parse } from "date-fns";
import Highcharts, { SeriesLegendItemClickEventObject } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useRef } from "react";
import Judge from "./commons/Judge";
import ReportNavigate from "./commons/ReportNavigate";
import SubtitleTypography from "./commons/SubtitleTypography";
import { QuantityResponse, UserType } from "./types";

export function Quantity({
  uuid,
  userType,
  data,
}: {
  uuid: string | undefined;
  userType: UserType;
  data: QuantityResponse;
}) {
  return (
    <Stack>
      <img
        loading="lazy"
        src="/img/image_report_quantity.png"
        alt="image_report_quantity"
        width="100%"
      />
      <Stack sx={{ p: "40px 16px 24px 16px", gap: "8px" }}>
        <Breadcrumbs
          maxItems={2}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            href={`/${userType}/report/${uuid}`}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body2">レポートTOP</Typography>
          </Link>
          <Typography variant="body2">睡眠の長さ</Typography>
        </Breadcrumbs>
        <Typography variant="h5" sx={{ color: "primary.main" }}>
          睡眠の長さ
        </Typography>
      </Stack>
      <Stack sx={{ p: "0px 16px 40px 16px" }}>
        <Typography
          variant="subtitle2"
          textAlign="justify"
          sx={{ color: "text.secondary" }}
        >
          あなたが眠りについてから目が覚めるまでの時間を評価します。
        </Typography>
      </Stack>
      <Stack
        sx={{
          p: "0 16px 40px 16px",
          gap: "24px",
        }}
      >
        <SubtitleTypography>測定結果</SubtitleTypography>
        <Chart
          standard_value={
            data.judge_and_evaluated_items.evaluated_items.average_time_of_sleep
              .standard_value
          }
          data={data.measurement_results}
        />
      </Stack>
      <Stack sx={{ p: "0px 16px 80px 16px", gap: "24px" }}>
        <SubtitleTypography>判定と評価項目</SubtitleTypography>
        <Stack sx={{ gap: "8px" }}>
          <Typography variant="subtitle2">判定</Typography>
          <Judge judge={data.judge_and_evaluated_items.judge} />
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ mb: "8px" }}>
            評価項目
          </Typography>
          <TableContainer
            sx={{
              borderTopWidth: "1px",
              borderTopColor: "rgba(0, 0, 0, 0.12)",
              borderTopStyle: "solid",
              borderRadius: "0px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontSize: "12px", fontWeight: 400, p: "8px" }}
                  >
                    項目
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      fontWeight: 400,
                      p: "8px",
                    }}
                  >
                    基準値
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      fontWeight: 400,
                      p: "8px",
                    }}
                  >
                    今回結果
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ px: "8px" }}>平均睡眠時間</TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      backgroundColor: "#f5f8fb",
                      px: "8px",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px" }}>
                      {Math.floor(
                        data.judge_and_evaluated_items.evaluated_items
                          .average_time_of_sleep.standard_value.from / 60
                      )}
                      ~
                      {Math.floor(
                        data.judge_and_evaluated_items.evaluated_items
                          .average_time_of_sleep.standard_value.to / 60
                      )}
                      時間
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      ※
                      {
                        data.judge_and_evaluated_items.evaluated_items
                          .average_time_of_sleep.age
                      }
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      px: "8px",
                      whiteSpace: "nowrap",
                      color: data.judge_and_evaluated_items.evaluated_items
                        .average_time_of_sleep.is_warning
                        ? "error.main"
                        : "text.main",
                    }}
                  >
                    {formatDisplayTime(
                      data.judge_and_evaluated_items.evaluated_items
                        .average_time_of_sleep.value
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ px: "8px" }}>
                    <Typography sx={{ fontSize: "14px" }}>
                      睡眠時間が短すぎる
                      <Typography
                        variant="overline"
                        fontSize="0.6em"
                        style={{
                          verticalAlign: "top",
                        }}
                      >
                        ※
                      </Typography>
                      日
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      ※
                      {formatDisplayTime(
                        data.judge_and_evaluated_items.evaluated_items
                          .too_short_sleep.appropriate_from
                      )}
                      未満 （
                      {
                        data.judge_and_evaluated_items.evaluated_items
                          .average_time_of_sleep.age
                      }
                      ）
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      backgroundColor: "#f5f8fb",
                      px: "8px",
                    }}
                  >
                    0日/週
                  </TableCell>
                  <TableCell
                    sx={{
                      px: "8px",
                      whiteSpace: "nowrap",
                      color: data.judge_and_evaluated_items.evaluated_items
                        .too_short_sleep.is_warning
                        ? "error.main"
                        : "text.main",
                    }}
                  >
                    {
                      data.judge_and_evaluated_items.evaluated_items
                        .too_short_sleep.value
                    }
                    日間
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Stack sx={{ p: "0px 16px 80px 16px", gap: "24px" }}>
        <Stack sx={{ gap: "16px" }}>
          <SubtitleTypography>
            {data.judge_and_evaluated_items.judge === "good"
              ? "コメント"
              : "改善のポイント"}
          </SubtitleTypography>
          <Typography variant="body1" textAlign="justify">
            {data.points_of_improvement.comment}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ p: "0px 16px 0px 16px" }}>
        <Divider sx={{ m: "32px 0px" }} />
        <Typography variant="h6" color="text.secondary">
          あわせて読みたい
        </Typography>
        <ReportNavigate
          reportUid={uuid}
          navigates={[
            {
              icon: {
                src: "/img/icon_report_rhythm.svg",
                alt: "musical_note",
              },
              text: "睡眠のリズム",
              link: `/${userType}/report/${uuid}/rhythm`,
            },
            {
              icon: {
                src: "/img/icon_report_quality.svg",
                alt: "quality",
              },
              text: "睡眠の質",
              link: `/${userType}/report/${uuid}/quality`,
            },
            {
              icon: {
                src: "/img/icon_report_drowsiness.svg",
                alt: "drowsiness",
              },
              text: "日中の眠気",
              link: `/${userType}/report/${uuid}/drowsiness`,
            },
            {
              icon: {
                src: "/img/icon_report_fatigue.svg",
                alt: "fatigue",
              },
              text: "日中の疲労",
              link: `/${userType}/report/${uuid}/fatigue`,
            },
          ]}
        />
      </Stack>
    </Stack>
  );
}

function Chart(props: {
  standard_value: {
    from: number;
    to: number;
  };
  data: {
    // 測定日
    date: string;
    // 睡眠の長さ
    value: number;
    // 注意フラグ
    is_warning: boolean;
  }[];
}) {
  const theme = useTheme();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options: Highcharts.Options = {
    chart: {
      type: "column",
      height: 300,
      marginLeft: 35,
      marginRight: 0,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      labels: {
        rotation: 0,
        style: {
          color: theme.palette.text.secondary,
          fontSize: "10px",
        },
      },
      categories: props.data.map((d) => {
        const date = parse(d.date, "yyyy-MM-dd", new Date());
        return `${format(date, "M/d")}<br>${"日月火水木金土"[date.getDay()]}`;
      }),
      opposite: true,
      lineWidth: 0,
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      max: Math.max(...props.data.map((d) => d.value)) + 4 * 60,
      min: 0,
      plotBands: [
        {
          color: "#e0f5f3",
          from: props.standard_value.from,
          to: props.standard_value.to,
        },
      ],
      plotLines: [
        {
          value: 0,
          color: "#e0e0e0",
        },
        {
          value: props.standard_value.from,
          width: 0,
          label: {
            x: -35,
            y: 5,
            text: formatDisplayTime(props.standard_value.from),
            style: {
              color: "#00A99D",
              fontFamily: "Noto Sans JP",
              fontSize: "10px",
            },
          },
        },
        {
          value: props.standard_value.to,
          width: 0,
          label: {
            x: -35,
            y: 5,
            text: formatDisplayTime(props.standard_value.to),
            style: {
              color: "#00A99D",
              fontFamily: "Noto Sans JP",
              fontSize: "10px",
            },
          },
        },
      ],
      gridLineWidth: 0,
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      symbolHeight: 10,
      symbolRadius: 0,
      itemStyle: {
        color: theme.palette.text.secondary,
      },
    },
    plotOptions: {
      column: {
        pointWidth: 32,
        borderRadius: 0,
      },
      series: {
        borderWidth: 0,
      },
    },
    series: [
      {
        type: "column",
        color: theme.palette.primary.main,
        name: "睡眠時間(実際に眠っていた時間)",
        data: props.data.map((d) => ({ y: d.value, isWarning: d.is_warning })),
        dataLabels: {
          enabled: true,
          formatter() {
            const displayedTime = formatDisplayTime(Number(this.y)).replace(
              "時間",
              "時間<br />"
            );
            return (this.point as Highcharts.Point & { isWarning: boolean })
              .isWarning
              ? `<span style="color: #FF3A30; font-weight: 400; font-family: Noto Sans JP;">${displayedTime}</span>`
              : `<span style="font-weight: 400; font-family: Noto Sans JP;">${displayedTime}</span>`;
          },
          // NOTE: 「text-anchor: middle」で中央揃えにし、基準を「align: left」で左に揃え、「x: 10」で左に寄ってしまったラベルを右方向へ移動させてカラムの中央真上に移動させている
          x: 10,
          align: "left",
          style: {
            textAnchor: "middle",
          },
        },
        events: {
          legendItemClick(e: SeriesLegendItemClickEventObject) {
            e.preventDefault();
          },
        },
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
}
