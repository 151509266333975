import { ScreenLockProvider } from "@/components/ScreenLock";
import { useRecommendationExistenceAPI } from "@/components/report/disease_risk/apis";
import { RecommendationExistenceInfo } from "@/components/report/disease_risk/commons";
import { Summary } from "@/components/report/summary/Summary";
import { SummaryResponse } from "@/components/report/types";
import { useAxios } from "@/utils/axios";
import { usePageUserType } from "@/utils/router";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function SummaryPage() {
  const { uuid } = useParams();
  const userType = usePageUserType();
  const axios = useAxios();
  const recommendationExistenceAPI = useRecommendationExistenceAPI();
  const [data, setData] = useState<SummaryResponse | null>(null);
  const [recommendationExistence, setRecommendationExistence] =
    useState<RecommendationExistenceInfo>();

  useEffect(() => {
    (async () => {
      const response = await axios.get(`/api/report/${uuid}/summary`);
      setData(response.data);
    })();
  }, [axios, uuid]);

  useEffect(() => {
    (async () => {
      if (uuid == null) return;
      const response = await recommendationExistenceAPI.get(uuid);
      setRecommendationExistence(response.data);
    })();
  }, [recommendationExistenceAPI, uuid]);

  if (uuid == null || data == null || recommendationExistence == null)
    return <></>;

  return (
    <ScreenLockProvider>
      <Summary
        data={data}
        userType={userType}
        uuid={uuid}
        existence={recommendationExistence}
      />
    </ScreenLockProvider>
  );
}
