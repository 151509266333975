import { Dialog, DialogProps } from "@mui/material";

export type ReportDialogProps = DialogProps;
export default function ReportDialog({ onClose, ...props }: ReportDialogProps) {
  const handleClose = (
    event: React.MouseEvent<HTMLInputElement>,
    reason: "backdropClick"
  ) => {
    if (reason === "backdropClick") return;
    onClose?.(event, reason);
  };
  return <Dialog {...props} onClose={handleClose}></Dialog>;
}
