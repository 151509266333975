type LiteLogoProps = {
  /** ロゴの幅 */
  width: number;
};
/**
 * Sleep Compass Lightロゴ
 */
export function LiteLogo({ width }: LiteLogoProps) {
  return (
    <img
      src="/img/logo_lite.png"
      alt="SLEEP COMPASS Light"
      loading="lazy"
      style={{ width: width, height: "auto" }}
    />
  );
}
