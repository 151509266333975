import HelpButton from "../HelpButton";
import { Reference } from "./commons/Reference";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function StopbangHelpDialog() {
  return (
    <HelpButton dialogTitle="STOP-BANG尺度の説明">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "STOP-BANG尺度とは",
            content:
              "閉塞性睡眠時無呼吸症候群を対象とした質問紙です。8つの質問から構成されています。\n各問0~1点で合計得点を算出し、3点以上をカットオフ点としています。\n質問紙のみでは診断・スクリーニングを行うことはできませんが、臨床的には多数の情報が得られるため、診断検査を行うかどうかの補助として使用することは有用とされています。",
            additionalContent: (
              <Reference>
                睡眠時無呼吸症候群（SAS）の診療ガイドライン 2020
                ：日本呼吸器学会
              </Reference>
            ),
          },
        ]}
      />
    </HelpButton>
  );
}
