import { DailyInterviewRemind } from "@/components/examinee/sleep_checkup/Types";
import { toStringMMdd } from "@/utils/date";

export type IconVariant =
  | "Completed"
  | "Incompleted"
  | "Day1"
  | "Day2"
  | "Day3"
  | "Day4"
  | "Day5"
  | "Day6"
  | "Day7"
  | "Day8";

const DAY_ICONS: IconVariant[] = [
  "Day1",
  "Day2",
  "Day3",
  "Day4",
  "Day5",
  "Day6",
  "Day7",
  "Day8",
];

export class InterviewRecordInfo {
  readonly remind: DailyInterviewRemind;
  readonly index: number;
  readonly referenceDate: Date;

  private get remindDate(): Date {
    return new Date(this.remind.remind_datetime);
  }

  getDate(): string {
    return toStringMMdd(this.remindDate, "/");
  }

  getIconVariant(): IconVariant {
    switch (this.remind.status) {
      case "done":
        return "Completed";
      case "inprogress":
      case "unanswered":
        if (this.referenceDate <= this.remindDate) {
          return DAY_ICONS[this.index];
        } else {
          return "Incompleted";
        }
    }
  }

  constructor(
    remind: DailyInterviewRemind,
    index: number,
    referenceDate: Date
  ) {
    this.remind = remind;
    this.index = index;
    this.referenceDate = referenceDate;
  }
}
