import { EMAIL_ADDRESS_REGEX } from "@/components/activation/Constants";
import { NonFieldError } from "@/components/common/SCAlert";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { getApiServerUrl } from "@/utils/axios";
import { Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  isSecondUser: boolean; // 2回目以降の受診者かどうか
};

export const ForgotPassword = () => {
  const API_URL = getApiServerUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");

  const { isSecondUser } = (location.state as StateType) || {};

  const validators: Record<string, () => string[] | null> = {
    // validatorは引数を受け取らず実行され、エラーが無ければ何もreturnしない。
    // エラーがあった場合はエラーメッセージの配列を返す。
    email: () => {
      if (!email.match(EMAIL_ADDRESS_REGEX)) {
        return ["※メールアドレスの形式が正しくありません"];
      }
      return null;
    },
  };

  const handleClickSubmit = () => {
    if (Object.values(validators).some((v) => v())) {
      // 何かしらのエラーがある場合は処理を行わない
      return;
    }
    axios
      .post(`${API_URL}/api/reset_user_password/`, {
        username: email,
      })
      .then((res) => {
        navigate("/examinee/set_new_password", {
          state: { email, isSecondUser },
        });
      })
      .catch((err) => {
        const statusCode = err.response?.status;
        const messages = err.response?.data?.error_messages;
        if (
          statusCode &&
          400 <= statusCode &&
          statusCode < 500 &&
          messages &&
          messages.length > 0
        ) {
          // 400系エラーでエラーメッセージが存在する場合はそれを表示する
          setErrorMessage(messages[0]);
        } else {
          setErrorMessage(
            "エラーが発生しました。申し訳ありませんが、少し時間をおいてから再度お試しください。"
          );
        }

        // エラーアラートが画面内に表示されるようにスクロールをリセットする
        window.scrollTo(0, 0);
      });
  };

  return (
    <>
      <Stack spacing={8} sx={{ mt: 8, mb: 12, mx: 4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            パスワードを忘れた場合
          </Typography>

          {/* エラーメッセージ */}
          <NonFieldError>{errorMessage}</NonFieldError>

          <Typography variant="subtitle2" textAlign="justify">
            登録したメールアドレスにパスワード再設定用の確認コードをお送りします。メールアドレスを入力してください。
          </Typography>

          <TextForm
            title="メールアドレス"
            placeholder="例：sleep-compass@example.com"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            validator={validators.email}
          />
        </Stack>
      </Stack>
      <Stack width="100%" sx={{ mb: 16 }}>
        <Button
          variant="contained"
          size="large"
          sx={{ mx: 4, mb: 4 }}
          onClick={handleClickSubmit}
          disabled={Object.values(validators).some((v) => v())}
        >
          送信する
        </Button>
      </Stack>
    </>
  );
};
