import {
  SearchParams,
  SleepCheckupInfo,
  SleepCheckupInfoEdit,
} from "@/components/sleep_checkup_v1/Types";
import { facilityAxios as axios } from "@/utils/axios";
import { AxiosResponse } from "axios";
import { SleepCheckupBasicInfo } from "./sleep_checkup_detail/sleep_checkup_basic_infos";

/**
 * 睡眠健康測定情報取得APIを呼び出し、APIのレスポンスを返す
 * @param searchParam 検索パラメータ
 * @param page ページ
 * @param sort 並び順
 * @param rowsPerPage ページあたりの睡眠健康測定情報の個数
 * @returns APIのレスポンス
 */
export function getSleepCheckupInfo(
  searchParam: SearchParams,
  page: number,
  sort: string,
  rowsPerPage: number
) {
  return axios.get("/api/facility/sleep_checkup_info/", {
    params: {
      ...(Object.keys(searchParam) as (keyof SearchParams)[])
        .filter((key) => searchParam[key] != null && searchParam[key] !== "")
        .reduce((result: any, key) => {
          result[key] = searchParam[key];
          return result;
        }, {}),
      page: page,
      ordering: sort,
      page_size: rowsPerPage,
    },
  });
}

/**
 * 睡眠健康測定取得APIを呼び出し、APIのレスポンスを返す
 * @param sleepCheckupId 取得する睡眠健康測定のID
 * @returns APIのレスポンス
 */
export function getSleepCheckupInfoById(sleepCheckupId: string) {
  return axios.get(`/api/facility/sleep_checkup_info/${sleepCheckupId}/`);
}

/**
 * 専門家からのコメント取得APIを呼び出し、APIのレスポンスを返す
 * @param reportContextId
 * @returns APIのレスポンス
 */
export function getSpecialistComment(reportContextId: string) {
  return axios.get(
    `/api/facility/report_context/${reportContextId}/specialist_comment/`
  );
}

/**
 * 専門家からのコメント更新APIを呼び出し、APIのレスポンスを返す
 * @param reportContextId ReportContextのID
 * @param specialistComment 専門家からのコメント
 * @returns APIのレスポンス
 */
export function updateSpecialistComment(
  reportContextId: string, // 健診情報のidではなくReportContextのidであることに注意
  specialistComment: string
) {
  return axios.put(
    `/api/facility/report_context/${reportContextId}/specialist_comment/`,
    {
      specialist_comment: specialistComment,
    }
  );
}

/**
 * 事前問診の回答を取得するAPIを呼び出し、APIのレスポンスを返す
 * @param info SleepCheckupInfo
 * @returns APIのレスポンス
 */
export const getPrimaryInterviewResult = async (info: SleepCheckupInfo) => {
  return axios.get(`/api/facility/primary_interview/${info.id}/`);
};

/**
 * 毎日問診の回答を取得するAPIを呼び出し、APIのレスポンスを返す
 * @param info SleepCheckupInfo
 * @returns APIのレスポンス
 */
export const getDailyInterviewResult = async (info: SleepCheckupInfo) => {
  return axios.get(`/api/facility/daily_interview/${info.id}/`);
};

/**
 * 睡眠健康測定作成APIを呼び出し、APIのレスポンスを返す
 * @param info 作成する睡眠健康測定
 * @returns APIのレスポンス
 */
export function createSleepCheckupInfo(info: SleepCheckupInfoEdit) {
  return axios.post("/api/facility/sleep_checkup_info/", info);
}

/**
 * 睡眠健康測定編集APIを呼び出し、APIのレスポンスを返す
 * @param info 編集する睡眠健康測定
 * @returns APIのレスポンス
 */
export function editSleepCheckupInfo(info: SleepCheckupInfoEdit) {
  if (info.id == null) {
    return Promise.reject(new Error("info.id can not be null"));
  }
  return axios.put(`/api/facility/sleep_checkup_info/${info.id}/`, info);
}

/**
 * 睡眠健康測定削除APIを呼び出し、APIのレスポンスを返す
 * @param info 削除する睡眠健康測定
 * @returns APIのレスポンス
 */
export function deleteSleepCheckupInfo(info: SleepCheckupInfo) {
  if (!info.id) {
    return Promise.reject(new Error("info.id can not be empty"));
  }
  return axios.delete(`/api/facility/sleep_checkup_info/${info.id}/`);
}

/**
 * 施設ユーザーCSVインポートAPIを呼び出し、APIのレスポンスを返す
 * @param medicalFacilityId インポート対象の健診施設ID
 * @param csv インポートするCSVファイル
 * @returns APIのレスポンス
 */
export function importCSV(medicalFacilityId: string, csv: File) {
  const formData = new FormData();
  formData.append("medical_facility", medicalFacilityId);
  formData.append("file", csv);

  return axios.post("/api/facility/sleep_checkup_info_csv_import/", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function updateSendState(info: SleepCheckupInfo) {
  if (!info.id) {
    return Promise.reject(new Error("info.id can not be empty"));
  }
  return axios.patch(
    `/api/facility/sleep_checkup_info_sent_report/${info.id}/`
  );
}

/**
 * 受診者登録用APIを呼び出し、APIのレスポンスを返す
 * @param info 睡眠健康測定
 * @returns APIのレスポンス
 */
export function getRegistrationPDF(info: SleepCheckupInfo, version: string) {
  if (!info.id) {
    return Promise.reject(new Error("info.id can not be empty"));
  }

  return axios.get(`/api/facility/registration/pdf/${info.id}/${version}/`, {
    responseType: "blob",
  });
}

/**
 * 企業名取得APIを呼び出し、APIのレスポンスを返す
 * @param name 検索名
 * @returns APIのレスポンス
 */
export function getCorporateName(name?: string) {
  return axios.get<{}, AxiosResponse<string[], {}>, {}>(
    `/api/facility/corporate_name/`,
    {
      params: { name },
    }
  );
}

/**
 * 受付を行った施設ユーザーの一覧を取得する
 * @returns APIのレスポンス
 */
export function getAcceptedFacilityUsers() {
  return axios.get("/api/facility/accepted_facility_users/");
}

/**
 * プロジェクト取得APIを呼び出し、APIのレスポンスを返す
 * @param name 検索用のプロジェクト名キー
 * @returns APIのレスポンス
 */
export function getCheckupProjects(name?: string) {
  return axios.get("/api/facility/checkup_projects/", { params: { name } });
}

type DiseaseKey = "SAS" | "insomnia";
/**
 * {@link getRecommendationExistence} のレスポンス
 */
interface RecommendationExistenceResponse {
  /** 疾患リスクオプションの有無 */
  recommendation_option: Record<DiseaseKey, boolean>;
  /** 結果としての疾患リスクの有無 */
  recommendation_existence: Record<DiseaseKey, boolean>;
}
/**
 * 疾患リスクレポート使用確認
 * @param uuid sleep checkup id の unique id
 * @returns APIのレスポンス
 */
export function getRecommendationExistence(uuid: string) {
  return axios.get<{}, AxiosResponse<RecommendationExistenceResponse>>(
    `/api/recommendation_existence/${uuid}/`
  );
}

/**
 * 指導用基礎情報API
 * @see https://www.notion.so/accelstars/API-Interface-9cc9f3444d7a435494fd3e3431048572?pvs=4#04ff65347260498f8a437dca01704fbd
 * @returns 指導用基礎情報API
 */
export function getSleepCheckupBasicInfoAPI(uuid: string) {
  return axios.get<SleepCheckupBasicInfo>(
    `/api/facility/sleep_checkup_basic_info/${uuid}/`
  );
}
