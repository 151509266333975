import { AddButton } from "@/components/common/AddButton";
import { BackdropIndicator } from "@/components/common/BackdropIndicator";
import { clone } from "@/components/common/ObjectService";
import { SCLink } from "@/components/common/SCLink";
import {
  SleepCheckupDetailLink,
  SleepCheckupListLink,
} from "@/components/sleep_checkup_v1/BreadcrumbLink";
import { EditButton } from "@/components/sleep_checkup_v1/EditButton";
import { PAGE_TITLE, paths } from "@/components/sleep_checkup_v1/Path";
import { SCDialogTitle } from "@/components/sleep_checkup_v1/SCDialogTitle";
import { SCSnackbar } from "@/components/sleep_checkup_v1/SCSnackbar";
import { SaveButton } from "@/components/sleep_checkup_v1/SaveButton";
import {
  getRecommendationExistence,
  getSleepCheckupInfoById,
  getSpecialistComment,
  updateSendState,
  updateSpecialistComment,
} from "@/components/sleep_checkup_v1/SleepCheckupInfoAPI";
import SleepCheckupReportFrame from "@/components/sleep_checkup_v1/SleepCheckupReport";
import { SleepCheckupInfo } from "@/components/sleep_checkup_v1/Types";
import RecommendReportFrame from "@/components/sleep_checkup_v1/sleep_checkup_detail/DiseaseRiskReportFrame";
import SleepCheckupDetailTabs, {
  TabKey,
} from "@/components/sleep_checkup_v1/sleep_checkup_detail/SleepCheckupDetailTabs";
import {
  DAILY_INTERVIEW_STATUS_MAPPING,
  PRIMARY_INTERVIEW_STATUS_MAPPING,
} from "@/components/sleep_checkup_v1/util";
import {
  Add,
  Apartment,
  Assignment,
  Close,
  EditOutlined,
  ErrorOutline,
  Face,
  SendOutlined,
} from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  ButtonProps,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { ja } from "date-fns/locale";
import React, {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";

type SentReportEditButtonProps = ButtonProps & {
  onEdit: ButtonProps["onClick"];
};
function SentReportEditButton({
  onClick,
  onEdit,
  ...props
}: SentReportEditButtonProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const onClickHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpenDialog(true);
    onClick?.(event);
  };
  const onEditHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpenDialog(false);
    onEdit?.(event);
  };
  return (
    <>
      <Button onClick={onClickHandler} {...props}></Button>
      <Dialog open={openDialog} fullWidth maxWidth="xs">
        <DialogTitle>送付済みのレポートを編集</DialogTitle>
        <DialogContent>
          このレポートは受診者に送付済みです。本当にコメントを編集しますか？
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>キャンセル</Button>
          <Button onClick={onEditHandler} variant="contained">
            編集する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

type SendReportButtonProps = Omit<ButtonProps, "children"> & {
  sleepCheckupInfo: SleepCheckupInfo | null;
  onSentReport?: () => void;
  onSentError: (e: any) => void;
};
function SendReportButton({
  sleepCheckupInfo,
  startIcon = <SendOutlined />,
  disabled,
  onClick,
  onSentReport,
  onSentError,

  ...props
}: SendReportButtonProps) {
  const [sentReport, setSentReport] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const onClickSendReportHandler = async () => {
    setOpenDialog(true);
  };
  const onSendMailHandler = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (sleepCheckupInfo != null) {
      try {
        const res = await updateSendState(sleepCheckupInfo);
        sleepCheckupInfo.sent_report = true;
        setSentReport(res.data.sent_report);
        onSentReport?.();
      } catch (e) {
        onSentError?.(e);
      }
    }
    onClick?.(event);
    setOpenDialog(false);
  };
  return (
    <>
      <Button
        onClick={onClickSendReportHandler}
        startIcon={startIcon}
        disabled={disabled || sentReport}
        {...props}
      >
        {disabled || sentReport ? "レポート送付済み" : "レポートを送付"}
      </Button>
      <Dialog open={openDialog} fullWidth maxWidth="xs">
        <DialogTitle>レポートを送付</DialogTitle>
        <DialogContent>受診者にレポートを送付しますか？</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>キャンセル</Button>
          <Button onClick={onSendMailHandler} variant="contained">
            送付する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

type ShowExamineeInfoButtonProps = {
  showExamineeInfo: boolean;
  setShowExamineeInfo: (showExamineeInfo: boolean) => void;
};
function ShowExamineeInfoButton({
  showExamineeInfo,
  setShowExamineeInfo,
}: ShowExamineeInfoButtonProps) {
  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowExamineeInfo(true);
  };
  return (
    <>
      {!showExamineeInfo && (
        <Button
          variant="contained"
          size="medium"
          color="primary"
          aria-label="show"
          onClick={onClickHandler}
          sx={{
            "& .MuiButton-startIcon": {
              marginRight: 0,
              marginLeft: 0,
            },
            height: "auto",
            padding: "16px",
            borderRadius: "20px 0 0 20px",
            position: "absolute",
            top: "50%",
            left: "calc(100% - 28px)",
            transform: "translateY(-50%)",
          }}
        >
          <Typography sx={{ writingMode: "vertical-rl" }}>
            <Add fontSize="small" sx={{ m: "8px" }} />受 診 者 情 報 を 見 る
          </Typography>
        </Button>
      )}
    </>
  );
}

type SleepCheckupDetailProps = {
  minWidth: number;
};
export function SleepCheckupDetail({ minWidth }: SleepCheckupDetailProps) {
  const MAX_COMMENT_LENGTH = 200;

  const location = useLocation();
  const params = useParams();

  const [tabKey, setTabKey] = useState<TabKey>("sleep_report");
  const [tabKeys, setTabKeys] = useState<TabKey[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [sleepCheckupInfo, setSleepCheckupInfo] =
    useState<SleepCheckupInfo | null>(null);
  const [specialistComment, setSpecialistComment] = useState<string | null>(
    null // 専門家からのコメントは最初はNULLで、APIから取得した結果をセットする
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingComment, setEditingComment] = useState<string | null>(null);
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);
  const [snackbarText, setSnackbarText] = useState<string>("");
  const [errorSnackbarText, setErrorSnackbarText] = useState("");
  const [showExamineeInfo, setShowExamineeInfo] = useState(false);

  const toShowBackdrop =
    !isError && // エラーの時はバックドロップを表示しない
    isCallingAPI; // あるいはAPI呼び出し中に表示する

  const failed = (err: any) => {
    // 直打ちされたURLでレポートにアクセスできないケース等
    setIsError(true);
    setSpecialistComment(null);
    setSleepCheckupInfo(null);
    setIsCallingAPI(false);
  };

  // 疾患リスクレポートを表示するか判定
  const shouldShowDiseaseRiskReport = useCallback(async (uniqueId: string) => {
    const response = await getRecommendationExistence(uniqueId);
    const { SAS: isSAS, insomnia: isInsomnia } =
      response.data.recommendation_option;
    // SAS または insomnia が true の場合、True
    return [isSAS, isInsomnia].some((f) => f === true);
  }, []);

  const setUpTabs = useCallback(async () => {
    if (sleepCheckupInfo?.unique_id == null) return;

    const tabKeys: TabKey[] = ["sleep_report"];
    // 疾患リスクレポートのタブを表示するか判定
    if (await shouldShowDiseaseRiskReport(sleepCheckupInfo.unique_id)) {
      tabKeys.push("disease_risk_report");
    }

    setTabKeys(tabKeys);
  }, [sleepCheckupInfo?.unique_id, shouldShowDiseaseRiskReport]);

  useEffect(() => {
    // 表示するタブを設定
    setUpTabs();
  }, [setUpTabs]);

  useEffect(() => {
    // sleepCheckupInfoが前画面から渡されてきた場合はそれを使う
    if (location.state) {
      const info = clone(location.state as SleepCheckupInfo);
      setSleepCheckupInfo(info);
      if (info.report !== null) {
        getSpecialistComment(info.report.id)
          .then((res: any) => {
            setSpecialistComment(res.data.specialist_comment);
            setIsError(false);
            setIsCallingAPI(false);
          })
          .catch(failed);
      }
    } else {
      // sleepCheckupInfoが未取得の場合は取得を試みる
      if (!params.sleepCheckupId) {
        throw new Error("params.sleepCheckupId is null");
      }

      getSleepCheckupInfoById(params.sleepCheckupId)
        .then(async (res: any) => {
          const info: SleepCheckupInfo = clone(res.data as SleepCheckupInfo);
          setSleepCheckupInfo(info);
          if (info !== null && info.report !== null) {
            const comment = await getSpecialistComment(info.report.id);
            setSpecialistComment(comment.data.specialist_comment);
          }
          setIsError(false);
          setIsCallingAPI(false);
        })
        .catch(failed);
    }
  }, [location.state, params.sleepCheckupId]);

  // 問診進捗／睡眠レポートのタブ遷移時の処理
  const handleTabChanged = (
    event: SyntheticEvent<Element, Event>,
    tabKey: TabKey
  ) => {
    setTabKey(tabKey);
  };

  // 「コメントを追加」「コメントを編集」ボタンクリック時の処理
  const handleClickEditDialog = () => {
    setEditingComment(specialistComment);
    setIsEditing(true);
  };

  // 編集用ダイアログを閉じた時の処理
  const handleCloseEditingDialog = () => {
    setIsEditing(false);
  };

  // 編集用ダイアログのテキストフィールドの値が変更された時の処理
  const handleChangeEditingComment = (e: any) => {
    setEditingComment(e.target.value);
  };

  // 編集用ダイアログの「追加する」「編集する」ボタンクリック時（確定時）の処理
  const handleUpdateComment = (e: any) => {
    handleCloseEditingDialog(); // まずダイアログを閉じる

    if (!sleepCheckupInfo?.report?.id) {
      return;
    }
    setIsCallingAPI(true);
    updateSpecialistComment(sleepCheckupInfo.report.id, editingComment || "")
      .then((res) => {
        if (specialistComment) {
          setSnackbarText("コメントが編集されました");
        } else {
          setSnackbarText("コメントが追加されました");
        }
        setSpecialistComment(editingComment);
        handleCloseEditingDialog();
      })
      .finally(() => {
        setIsCallingAPI(false);
      });
  };
  const isReportApproved =
    sleepCheckupInfo?.sent_report === true || sleepCheckupInfo?.report != null;

  const tabContent: Record<TabKey, ReactNode> = {
    sleep_report: (
      <SleepCheckupReportFrame
        title="睡眠レポート"
        sleepCheckupInfo={sleepCheckupInfo}
        width="100%"
        height="100%"
        specialistComment={specialistComment}
      />
    ),
    disease_risk_report: (
      <RecommendReportFrame
        title="疾患リスクレポート"
        sleepCheckupInfo={sleepCheckupInfo}
        width="100%"
        height="100%"
      />
    ),
  };
  return (
    <>
      <BackdropIndicator open={toShowBackdrop} />
      <Stack
        spacing={6}
        sx={{ mx: 10, my: 6, minWidth: minWidth, position: "relative" }}
      >
        <SleepCheckupDetailBreadcrumbs
          sleepCheckupId={sleepCheckupInfo?.id ?? ""}
        />
        <Stack direction="row" alignItems="center">
          <Typography variant="h3">
            {PAGE_TITLE["SleepCheckupDetail"]}
          </Typography>
          {sleepCheckupInfo && (
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                ml: 2,
              }}
            >
              ({sleepCheckupInfo.medical_examinee_last_name}
              {sleepCheckupInfo.medical_examinee_first_name} / id:
              {sleepCheckupInfo.medical_examinee_id_in_facility})
            </Typography>
          )}
        </Stack>
        <SleepCheckupDetailTabs
          tabs={tabKeys}
          value={tabKey}
          onChange={handleTabChanged}
        />
        <Stack direction="row" spacing={8}>
          {isReportApproved === false && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
              sx={{
                color: "black.36",
                fontSize: "20px",
                pt: 20,
                width: "100%",
                height: "calc(100vh - 400px)",
              }}
            >
              <ErrorOutline />
              <Typography variant="body1">
                現在表示できる項目がありません
              </Typography>
            </Stack>
          )}
          {isReportApproved === true && (
            <>
              {/* レポート表示 */}
              <Stack
                direction="row"
                spacing={8}
                sx={{ width: "calc(100% - 63px)" }}
              >
                <Stack
                  // position: "relative"を指定する理由は、
                  // 子要素(SleepCheckupReportFrame)内にiframeとフローティングボタンを重ねて表示するため
                  position="relative"
                  sx={{
                    minWidth: "781px",
                    width: "100%",
                    height: "calc(100vh - 330px)",
                  }}
                >
                  {tabContent[tabKey]}
                </Stack>

                {/* 専門家からのコメント */}
                <Stack sx={{ maxWidth: "497px" }}>
                  <Stack>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                      専門家からのコメント
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 6 }}>
                      レポート内に施設からのコメントを追加できます
                    </Typography>
                    <TextField
                      placeholder="レポート内に専門家からのコメントを追加できます"
                      multiline
                      rows={4}
                      sx={{
                        minHeight: "144px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#707070",
                        },
                      }}
                      value={specialistComment ?? ""}
                      disabled
                    />
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      sx={{ mt: 6 }}
                    >
                      {sleepCheckupInfo?.sent_report === true ? (
                        <SentReportEditButton
                          startIcon={<EditOutlined />}
                          variant="outlined"
                          size="small"
                          disabled={
                            sleepCheckupInfo === null ||
                            specialistComment === null
                          }
                          onEdit={handleClickEditDialog}
                        >
                          コメントを編集
                        </SentReportEditButton>
                      ) : specialistComment ? (
                        <EditButton
                          variant="outlined"
                          onClick={handleClickEditDialog}
                          disabled={
                            sleepCheckupInfo === null ||
                            specialistComment === null
                          }
                        >
                          コメントを編集
                        </EditButton>
                      ) : (
                        <AddButton
                          variant="outlined"
                          onClick={handleClickEditDialog}
                          disabled={
                            sleepCheckupInfo === null ||
                            specialistComment === null
                          }
                        >
                          コメントを追加
                        </AddButton>
                      )}
                    </Stack>
                  </Stack>
                  <Stack sx={{ mb: 12 }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                      睡眠レポートの送付
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                      レポート確認用のリンクが記載されたメールが受診者に送信されます。
                    </Typography>
                    <Stack direction="row" justifyContent="flex-end">
                      <SendReportButton
                        sleepCheckupInfo={sleepCheckupInfo}
                        variant="contained"
                        size="small"
                        disabled={
                          sleepCheckupInfo === null ||
                          specialistComment === null ||
                          sleepCheckupInfo.sent_report
                        }
                        onSentReport={() =>
                          setSnackbarText("レポートが送付されました。")
                        }
                        onSentError={() => {
                          setErrorSnackbarText(
                            "レポートの送付に失敗しました。"
                          );
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </>
          )}
          {/* 受診者情報 */}
          {showExamineeInfo && (
            <Stack
              sx={{
                height: "calc(100vh - 320px)",
                minHeight: "598px",
                minWidth: "315px",
              }}
            >
              <Card sx={{ height: "100%" }}>
                <CardContent>
                  <Stack>
                    <Typography variant="subtitle2" sx={{ mb: "16px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Face />
                        <span style={{ marginLeft: "8px" }}>受診者情報</span>
                        <div style={{ flexGrow: 1 }}></div>
                        <IconButton
                          onClick={() => {
                            setShowExamineeInfo(false);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </div>
                    </Typography>
                    <Typography variant="caption">
                      {`${
                        sleepCheckupInfo?.medical_examinee_last_name_kana ?? ""
                      } ${
                        sleepCheckupInfo?.medical_examinee_first_name_kana ?? ""
                      }`}
                    </Typography>

                    <Typography variant="h4">
                      {`${sleepCheckupInfo?.medical_examinee_last_name ?? ""} ${
                        sleepCheckupInfo?.medical_examinee_first_name ?? ""
                      }`}
                    </Typography>

                    <Stack sx={{ mb: "16px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body2">生年月日</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            {sleepCheckupInfo?.medical_examinee_birthday ?? ""}
                            {sleepCheckupInfo?.medical_examinee_age_at_sleep_checkup !=
                            null
                              ? `（${sleepCheckupInfo?.medical_examinee_age_at_sleep_checkup}）`
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body2">企業名</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            {sleepCheckupInfo?.corporate_name ?? ""}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body2">部署名</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            {sleepCheckupInfo?.department_name ?? ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>

                    <Typography variant="subtitle2" sx={{ mb: "16px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Apartment />
                        <span style={{ marginLeft: "8px" }}>管理情報</span>
                      </div>
                    </Typography>

                    <Stack sx={{ mb: "16px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body2">デバイスID</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            {sleepCheckupInfo?.device_id ?? ""}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body2">受付日</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            {sleepCheckupInfo?.accepted_at ?? ""}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body2">受付担当者</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            {`${
                              sleepCheckupInfo?.accepted_by?.user.last_name ??
                              ""
                            } ${
                              sleepCheckupInfo?.accepted_by?.user.first_name ??
                              ""
                            }`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            レポート発行日
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            {`${
                              sleepCheckupInfo?.report?.approved_at == null
                                ? ""
                                : format(
                                    parseISO(
                                      sleepCheckupInfo?.report?.approved_at
                                    ),
                                    "yyyy-MM-dd HH:mm",
                                    { locale: ja }
                                  )
                            }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>

                    <Typography variant="subtitle2" sx={{ mb: "16px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Assignment />
                        <span style={{ marginLeft: "8px" }}>問診進捗</span>
                      </div>
                    </Typography>
                    <Stack sx={{ mb: "16px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="body2">事前問診</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            {PRIMARY_INTERVIEW_STATUS_MAPPING.get(
                              sleepCheckupInfo?.primary_interview.status ?? ""
                            ) ?? "未回答"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            {sleepCheckupInfo?.primary_interview.status !==
                              "unanswered" && (
                              <SCLink
                                variant="caption"
                                to={paths.getFullPath(
                                  "PrimaryInterviewResult",
                                  sleepCheckupInfo?.id
                                )}
                                state={sleepCheckupInfo}
                              >
                                詳細を見る
                              </SCLink>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="body2">毎日問診</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            {DAILY_INTERVIEW_STATUS_MAPPING.get(
                              sleepCheckupInfo?.daily_interview.status ?? ""
                            ) ?? "未回答"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            {sleepCheckupInfo?.daily_interview.status !==
                              "unanswered" && (
                              <SCLink
                                variant="caption"
                                to={paths.getFullPath(
                                  "DailyInterviewResult",
                                  sleepCheckupInfo?.id
                                )}
                                state={sleepCheckupInfo}
                              >
                                詳細を見る
                              </SCLink>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          )}
        </Stack>
        <ShowExamineeInfoButton
          showExamineeInfo={showExamineeInfo}
          setShowExamineeInfo={setShowExamineeInfo}
        />
      </Stack>

      <SCSnackbar
        open={!!snackbarText}
        severity="success"
        onClose={() => {
          setSnackbarText("");
        }}
      >
        {snackbarText}
      </SCSnackbar>

      <SCSnackbar
        open={!!errorSnackbarText}
        severity="error"
        onClose={() => {
          setErrorSnackbarText("");
        }}
      >
        {errorSnackbarText}
      </SCSnackbar>

      <Dialog
        maxWidth="md"
        open={isEditing && specialistComment !== null}
        onClose={handleCloseEditingDialog}
      >
        <SCDialogTitle onClickClose={handleCloseEditingDialog}>
          {"専門家からのコメントを" + (specialistComment ? "編集" : "追加")}
        </SCDialogTitle>
        <DialogContent>
          <TextField
            id="specialist_comment"
            multiline
            rows={4}
            label="コメント"
            sx={{
              mt: 4,
              minHeight: "144px",
              width: 600,
            }}
            defaultValue={specialistComment}
            onChange={handleChangeEditingComment}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ color: "text.secondary", mr: 2, mt: "2px" }}
          >
            <Typography
              sx={{
                mr: "2px",
                fontSize: 12,
                fontWeight: 500,
                color:
                  MAX_COMMENT_LENGTH < (editingComment?.length ?? 0)
                    ? "error.main"
                    : "text.secondary",
              }}
            >
              {editingComment?.length || 0}
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              /{MAX_COMMENT_LENGTH}文字
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ pt: 8 }}>
          <Stack direction="row" justifyContent="flex-end">
            {specialistComment && (
              <SaveButton
                onClick={handleUpdateComment}
                disabled={MAX_COMMENT_LENGTH < (editingComment?.length ?? 0)}
              >
                変更を保存
              </SaveButton>
            )}
            {!specialistComment && (
              <AddButton
                onClick={handleUpdateComment}
                disabled={MAX_COMMENT_LENGTH < (editingComment?.length ?? 0)}
              >
                追加する
              </AddButton>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}

type SleepCheckupDetailBreadcrumbsProps = {
  sleepCheckupId: string;
};
function SleepCheckupDetailBreadcrumbs({
  sleepCheckupId,
}: SleepCheckupDetailBreadcrumbsProps) {
  return (
    <Breadcrumbs>
      <SleepCheckupListLink />
      <SleepCheckupDetailLink sleepCheckupId={sleepCheckupId} disabled={true} />
    </Breadcrumbs>
  );
}
