import HelpButton from "../HelpButton";
import SimpleParagraphs from "./commons/SimpleParagraphs";

export default function NightmealHelpDialog() {
  return (
    <HelpButton dialogTitle="夜食">
      <SimpleParagraphs
        paragraphs={[
          {
            title: "睡眠への影響",
            content:
              "就寝に近い時間の夕食や夜食は、体内時計を後退させ、翌朝の睡眠休養感や睡眠の質を低下させます。遅い夕食や夜食は朝食欠食にもつながり、睡眠・覚醒リズムを乱す悪循環を招きます。",
          },
          {
            title: "おすすめ",
            content:
              "就寝2時間以内の食事は避け、仕事などで夕食が遅くなる時は分食(主食を夕方に、おかずなどの副食を遅い時間に軽く）にしましょう。",
          },
        ]}
      />
    </HelpButton>
  );
}
