import SignIn from "@/components/auth/SignIn";
import Copyright from "@/components/Copyright";
import { getApiServerUrl, getAxios } from "@/utils/axios";
import SystemTheme from "@/utils/theme";
import { Box, ThemeProvider } from "@mui/material";

function ACCELStarsSignIn() {
  return (
    <ThemeProvider theme={SystemTheme.getACCELStars()}>
      <SignIn
        axios={getAxios(getApiServerUrl())}
        defaultSuccessTo="/accelstars/"
        forgotPasswordTo="/accelstars/forgot_password"
        usernameLabel="ユーザー名"
        passwordLabel="パスワード"
      />
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
        }}
      >
        <Copyright />
      </Box>
    </ThemeProvider>
  );
}

export default ACCELStarsSignIn;
