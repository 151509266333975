import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { EmailAddress } from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import { useTargetAxios as useAxios } from "@/sleep_compass_lite/use_cases/target/common/UseTargetAxios";
import { AxiosError } from "axios";
import { useCallback } from "react";

/** useResetUserPassword PostResetUserPassword 型宣言 */
type PostResetUserPassword = (email: EmailAddress) => Promise<void>;

/**
 * パスワード再設定APIを呼び出すクロージャーを返すカスタムフック。カスタムフックで実装している理由は、useAxios(カスタムフック)を利用するため。
 */
export function useResetUserPassword() {
  const axios = useAxios();

  /**
   * パスワード再設定のためにメールアドレス確認メールを送信する
   *
   * @param email サーベイ情報のUUID
   */
  const verifyEmailAddress: PostResetUserPassword = useCallback(
    async (email) => {
      try {
        await axios.post("/api/lite/reset_user_password/", {
          username: email.value,
        });
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          throw new ApiError(e.response);
        } else {
          throw e;
        }
      }
    },
    [axios]
  );

  return { verifyEmailAddress };
}
