import { CognitoAuthenticationResultStore } from "@/utils/auth";
import { ReactElement, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

export interface PrivateRouteProps {
  children: ReactNode;
  redirectTo: string;
}
function Authenticated(props: PrivateRouteProps): ReactElement {
  const cognitoAuthenticationResult =
    CognitoAuthenticationResultStore.getItem();
  const location = useLocation();
  const next = encodeURIComponent(`${location.pathname}${location.search}`);
  return cognitoAuthenticationResult != null ? (
    <>{props.children}</>
  ) : (
    <Navigate to={`${props.redirectTo}?next=${next}`} replace />
  );
}
export default Authenticated;
