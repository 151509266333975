import { LifestyleAndHealthCondition } from "@/components/report/LifestyleAndHealthCondition";
import {
  LifestyleAndHealthConditionResponse,
  ReportPageBaseProps,
} from "@/components/report/types";
import { useAxios } from "@/utils/axios";
import { usePageUserType } from "@/utils/router";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function LifestyleAndHealthConditionNapPage({
  setIndicator,
}: ReportPageBaseProps) {
  const { uuid } = useParams();
  const userType = usePageUserType();
  const axios = useAxios();
  const [data, setData] = useState<LifestyleAndHealthConditionResponse | null>(
    null
  );
  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `/api/report/${uuid}/lifestyle_and_health_conditions/nap`
      );
      setData(response.data);
      setIndicator(false);
    })();
  }, [axios, uuid, setIndicator]);

  if (!uuid) return null;
  if (!data) return null;
  return (
    <LifestyleAndHealthCondition
      data={data}
      userType={userType}
      reportUid={uuid}
      type={"nap"}
    />
  );
}
