const BYTES = ["Byte", "KB", "MB", "GB"];

export function getFileSize(file: File): string {
  let size = file.size;
  let count = 0;
  while (size > 1024) {
    size /= 1024;
    count++;
  }
  // Note: ファイルサイズは小数点1桁で四捨五入
  return `${round(size, 1)}${BYTES[count]}`;
}

function round(num: number, digits: number): number {
  const d = Math.pow(10, digits);
  return Math.round(num * d) / d;
}
