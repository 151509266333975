import { Copyright } from "@/components/examinee/Copyright";
import { HelpOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import ReportDialog from "../commons/ReportDialog";
import { ReportImg } from "../commons/ReportImg";
import { ResultListDialog } from "../commons/ResultListDialog";
import { useSleepImprovementNeededListAPI } from "../disease_risk/apis";
import {
  DiseaseRiskTag,
  RecommendationExistenceInfo,
  TotalGradeInfo,
  hasDiseaseRiskOption,
} from "../disease_risk/commons";
import {
  JudgeType,
  SummaryComprehensiveEvaluation,
  SummaryResponse,
} from "../types";
import { SummaryPageProperties, useSummaryPageContext } from "./Summary";
import {
  ResultBedtimeIcon,
  ResultDaytimeIcon,
  ResultDiseaseRiskIcon,
  TeacherComment,
} from "./commons";

/**
 * 測定データの詳細プロパティ
 */
export type ComprehensiveEvaluationProps = {
  /**
   * 測定データの詳細データ
   *
   * @see {@link SummaryResponse}
   */
  data: SummaryResponse;

  /**
   * 次画面
   * @returns
   */
  next: () => void;
  /**
   * 前画面
   * @returns
   */
  prev?: () => void;

  firstVisit: boolean;
};

/**
 * {@link SummaryJudge} のプロパティ
 */
interface SummaryJudgeProps {
  /** 画像ID */
  imageId: string;
}

/**
 *  サマリーページの総合評価を表示します。
 * @param param0 {@link SummaryJudgeProps}
 * @returns サマリーページの総合評価
 */
function SummaryJudge({ imageId }: SummaryJudgeProps) {
  const imageGrade = imageId.split("_")[0];
  return (
    <Stack sx={{ gap: "24px" }}>
      <Stack sx={{ alignItems: "center" }}>
        <ReportImg
          image_name={`image_report_summary_result_${imageGrade}.png`}
          width="auto"
          height="140px"
        />
      </Stack>
      <Stack sx={{ width: "100%" }}>
        <ReportImg
          image_name={`image_report_summary_result_${imageId}_slider.png`}
          width="100%"
          height="auto"
        />
      </Stack>
    </Stack>
  );
}

/**
 * 睡眠の状態
 *
 * @param param0
 * @returns
 */
function SleepJudge({ type }: { type: JudgeType }) {
  const config = {
    alert: {
      text: "課題あり",
      backgroundColor: "#FF3A301F",
      color: "#FF3A30",
    },
    caution: {
      text: "やや課題あり",
      backgroundColor: "#fff7ea",
      color: "#ff910b",
    },
    good: {
      text: "良好",
      backgroundColor: "#e3e9f3",
      color: "#33529d",
    },
    unknown: {
      text: "判定不能",
      backgroundColor: "#0000001F",
      color: "#707070",
    },
  }[type];
  return (
    <Typography
      variant="subtitle2"
      sx={{
        backgroundColor: config.backgroundColor,
        color: config.color,
        p: "0 16px",
        borderRadius: "24px",
      }}
    >
      {config.text}
    </Typography>
  );
}

/**
 * 日中の状態
 *
 * @param param0
 * @returns
 */
function DaytimeJudge({ problem }: { problem: boolean }) {
  const config = [
    {
      text: "課題あり",
      backgroundColor: "#FF3A301F",
      color: "#FF3A30",
    },
    {
      text: "課題なし",
      backgroundColor: "#e3e9f3",
      color: "#33529d",
    },
  ][problem ? 0 : 1];
  return (
    <Typography
      variant="subtitle2"
      sx={{
        backgroundColor: config.backgroundColor,
        color: config.color,
        p: "0 16px",
        borderRadius: "24px",
      }}
    >
      {config.text}
    </Typography>
  );
}

/**
 * 総合評価
 *
 * @param param0
 * @returns
 */
export default function ComprehensiveEvaluation() {
  const context = useSummaryPageContext();
  const [openInitDialog, setOpenInitDialog] = useState(context.isFirstVisit);
  const [openJudgeList, setOpenJudgeList] = useState(false);
  const [totalGradeInfos, setTotalGradeInfos] = useState<TotalGradeInfo[]>();
  const sleepImprovementNeededListAPI = useSleepImprovementNeededListAPI();
  useEffect(() => {
    const uuid = context.props.uuid;
    uuid &&
      (async () => {
        const res = await sleepImprovementNeededListAPI.get(uuid);
        setTotalGradeInfos(res.data);
      })();
  }, [sleepImprovementNeededListAPI, context.props.uuid]);

  return (
    <Stack
      sx={{
        p: "24px 16px 80px 16px",
        gap: "24px",
        height: "100%",
      }}
    >
      <SummaryComprehensiveEvaluationDialog
        open={openInitDialog}
        setOpen={setOpenInitDialog}
        properties={context}
      />
      {totalGradeInfos && (
        <ResultListDialog
          open={openJudgeList}
          setOpen={setOpenJudgeList}
          totalGrandeInfos={totalGradeInfos}
        />
      )}
      <Stack
        sx={{
          backgroundColor: "common.white",
          borderRadius: "8px",
        }}
      >
        <Stack sx={{ p: "16px" }}>
          <Stack
            direction="row"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Typography variant="h6">睡眠改善必要度</Typography>
            <IconButton
              onClick={() => {
                setOpenJudgeList(true);
              }}
            >
              <HelpOutline />
            </IconButton>
          </Stack>

          <Stack sx={{ width: "100%" }}>
            <SummaryJudge
              imageId={context.props.data.comprehensive_evaluation.image_id}
            />
          </Stack>
        </Stack>

        <Divider sx={{ m: "0 16px" }} />
        {context.hasDiseaseRiskOption ? (
          <ManyResult
            comprehensive_evaluation={
              context.props.data.comprehensive_evaluation
            }
            existence={context.props.existence}
          />
        ) : (
          <>
            <Divider sx={{ m: "0 16px" }} />
            <SimpleResult
              comprehensive_evaluation={
                context.props.data.comprehensive_evaluation
              }
            />
          </>
        )}
      </Stack>
      <Stack sx={{ mb: "48px" }}>
        <TeacherComment>
          {context.props.data.comprehensive_evaluation.comment}
        </TeacherComment>
      </Stack>
      <Stack
        sx={{
          pb: "10px",
          mb: "80px",
          position: "sticky",
          bottom: 0,
          zIndex: 2,
          background:
            "linear-gradient(180deg, rgba(246, 245, 244, 0.00) 0%, #F6F5F4 100%);",
        }}
      >
        <Button
          variant="contained"
          onClick={() => context.setScreenType("sleep_and_daytime_record")}
          sx={{ fontSize: "15px", boxShadow: "none" }}
        >
          次へ
        </Button>
      </Stack>
      <Stack
        sx={{
          position: "sticky",
          alignItems: "center",
          p: "32px 16px",
        }}
      >
        <Copyright />
      </Stack>
    </Stack>
  );
}

/**
 * サマリーのダイアログ１ページ目
 * @param param0
 * @returns
 */
function SummaryComprehensiveEvaluationFirstDialog({
  next,
}: {
  next: () => void;
}) {
  function SummaryDialogStepRow({
    step,
    title,
    caption,
    comment,
  }: {
    step: number;
    title: string;
    caption: string;
    comment: string;
  }) {
    return (
      <Stack>
        <Stack>
          <Stack direction="row" sx={{ gap: "8px" }}>
            <Stack>
              <img
                src={`/img/image_report_summary_dialog_step${step}.svg`}
                loading="lazy"
                alt={`step${step}`}
                width="24px"
                height="24px"
              />
            </Stack>
            <Typography variant="subtitle2">{title}</Typography>
            <Typography variant="caption">{caption}</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Stack direction="row" sx={{ gap: "8px" }}>
            {step === 3 ? (
              <Box sx={{ width: "32px" }} />
            ) : (
              <Stack sx={{ justifyContent: "center" }}>
                <img
                  src="/img/image_report_summary_dialog_arrow.svg"
                  loading="lazy"
                  alt="arrow"
                  width="24px"
                  height="24px"
                />
              </Stack>
            )}

            <Typography variant="caption" textAlign="justify">
              {comment}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack>
      <Stack
        direction="row"
        sx={{ alignItems: "center", backgroundColor: "#edf2f8" }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            size: "16px",
            color: "primary.dark",
            p: "35.5px 34px 35.5px 16px",
          }}
        >
          サマリーレポートの流れ
        </Typography>
        <img
          src="/img/image_report_summary_dialog_hero.png"
          loading="lazy"
          alt="hero"
          width="112px"
          height="100px"
          style={{ marginLeft: "auto" }}
        />
      </Stack>

      <Stack sx={{ p: "16px" }}>
        <Typography variant="body2" textAlign="justify">
          サマリーレポートへようこそ。測定結果を簡単にまとめました。以下の流れに沿って、あなたの測定結果を解説していきます。
        </Typography>
        <Stack sx={{ p: "16px 0 8px 0" }}>
          <SummaryDialogStepRow
            step={1}
            title="総合評価"
            caption="（睡眠改善必要度）"
            comment="今回の結果を「睡眠改善必要度」という形式で判定します。"
          />

          <SummaryDialogStepRow
            step={2}
            title="評価の詳細"
            caption="（睡眠と日中の状態）"
            comment="総合評価の理由について、項目別の評価と合わせて解説します。"
          />

          <SummaryDialogStepRow
            step={3}
            title="項目ごとの結果"
            caption=""
            comment="各項目の測定結果と、あなたの改善すべきポイントについて解説します。"
          />
        </Stack>
      </Stack>

      <Box textAlign="center" sx={{ p: "16px" }}>
        <Button
          variant="outlined"
          sx={{ width: "107px", borderRadius: "18px", height: "32px" }}
          onClick={() => next()}
        >
          つぎへ
        </Button>
      </Box>
    </Stack>
  );
}

/**
 * 初期表示ダイアログの２ページ目
 *
 * @param param0
 * @returns
 */
function SummaryComprehensiveEvaluationSecondDialog({
  next,
  properties,
}: {
  next: () => void;
  properties: SummaryPageProperties;
}) {
  return (
    <Stack>
      <Stack>
        <Typography
          variant="subtitle1"
          sx={{ p: "16px", color: "primary.dark" }}
        >
          睡眠改善必要度とは
        </Typography>
      </Stack>
      <Stack
        sx={{
          backgroundColor: "#EBF1F7",
          p: "16px 0 16px 0",
          alignItems: "center",
        }}
      >
        {properties.hasDiseaseRiskOption ? (
          <img
            src="/img/image_report_summary_dialog_explanation_with_disease_risk.svg"
            loading="lazy"
            alt="explanation"
            width="100%"
          />
        ) : (
          <img
            src="/img/image_report_summary_dialog_explanation.svg"
            loading="lazy"
            alt="explanation"
            width="311px"
          />
        )}
      </Stack>
      <Stack sx={{ p: "16px" }}>
        <Typography variant="body2" textAlign="justify">
          {properties.hasDiseaseRiskOption
            ? "あなたの現在の睡眠を「デバイスによる睡眠の測定結果」、「問診による日中の状態」、および「睡眠時無呼吸症候群、不眠症の診断ガイドラインによる疾患リスク」の組み合わせから評価した結果です。"
            : "あなたの現在の睡眠を改善する必要があるのかを、デバイスで測定した睡眠の状態と、日中の状態を組み合わせて「睡眠改善必要度」として判定します。"}
        </Typography>
      </Stack>
      <Box textAlign="center" sx={{ p: "16px" }}>
        <Button
          variant="contained"
          sx={{
            borderRadius: "18px",
            p: "6px 32px 6px 32px",
            boxShadow: "none",
          }}
          onClick={() => next()}
        >
          結果を見る
        </Button>
      </Box>
    </Stack>
  );
}

type SummaryReportDialogProps = {
  /**
   * ダイアログ
   */
  open: boolean;
  /**
   * ダイアログを閉じる際に呼び出される関数です。
   *
   * @param open 設定されるプロパティ
   * @returns
   */
  setOpen: (open: boolean) => void;
  /**
   * @see RecommendationExistenceInfo
   */
  properties: SummaryPageProperties;
};

/**
 * 総合評価初期表示のダイアログ
 *
 * @param param0
 * @returns
 */
function SummaryComprehensiveEvaluationDialog({
  open,
  setOpen,
  properties,
}: SummaryReportDialogProps) {
  const [display, setDisplay] = useState(0);
  return (
    <ReportDialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      {
        [
          <SummaryComprehensiveEvaluationFirstDialog
            next={() => setDisplay((display) => display + 1)}
          />,
          <SummaryComprehensiveEvaluationSecondDialog
            next={() => setOpen(false)}
            properties={properties}
          />,
        ][display]
      }
    </ReportDialog>
  );
}

function SimpleResult({
  comprehensive_evaluation,
}: {
  comprehensive_evaluation: SummaryComprehensiveEvaluation;
}) {
  return (
    <Stack direction="row" sx={{ p: "16px", justifyContent: "space-evenly" }}>
      <Stack sx={{ gap: "8px" }}>
        <Stack sx={{ alignItems: "center" }}>
          <ResultBedtimeIcon width="24px" height="24px" />
          <Typography variant="subtitle2">睡眠の状態</Typography>
        </Stack>
        <Stack sx={{ alignItems: "center" }}>
          <SleepJudge type={comprehensive_evaluation.sleep_condition} />
        </Stack>
      </Stack>

      <Divider orientation="vertical" flexItem />

      <Stack sx={{ gap: "8px" }}>
        <Stack sx={{ alignItems: "center" }}>
          <ResultDaytimeIcon width="24px" height="24px" />
          <Typography variant="subtitle2">日中の状態</Typography>
        </Stack>
        <Stack sx={{ alignItems: "center" }}>
          <DaytimeJudge problem={comprehensive_evaluation.daytime_problem} />
        </Stack>
      </Stack>
    </Stack>
  );
}

function ManyResult({
  comprehensive_evaluation,
  existence,
}: {
  comprehensive_evaluation: SummaryComprehensiveEvaluation;
  existence: RecommendationExistenceInfo;
}) {
  function Container({ children }: { children: ReactNode }) {
    return (
      <Stack
        direction="row"
        sx={{ p: "16px 8px", justifyContent: "space-between" }}
      >
        {children}
      </Stack>
    );
  }
  function DiseaseRisk() {
    return (
      <>
        <Divider />
        <Container>
          <Stack direction="row">
            <ResultDiseaseRiskIcon
              width="24px"
              height="24px"
              style={{ marginRight: "3.5px" }}
            />
            <Typography variant="subtitle2">疾患リスク</Typography>
          </Stack>
          <Stack>
            <DiseaseRiskTag risk={existence.total} />
          </Stack>
        </Container>
      </>
    );
  }
  return (
    <Stack sx={{ mx: "16px" }}>
      <Divider />
      <Container>
        <Stack direction="row">
          <ResultBedtimeIcon
            width="24px"
            height="24px"
            style={{ marginRight: "3.5px" }}
          />
          <Typography variant="subtitle2">睡眠の状態</Typography>
        </Stack>
        <Stack>
          <SleepJudge type={comprehensive_evaluation.sleep_condition} />
        </Stack>
      </Container>
      <Divider />
      <Container>
        <Stack direction="row">
          <ResultDaytimeIcon
            width="24px"
            height="24px"
            style={{ marginRight: "3.5px" }}
          />
          <Typography variant="subtitle2">日中の状態</Typography>
        </Stack>
        <Stack>
          <DaytimeJudge problem={comprehensive_evaluation.daytime_problem} />
        </Stack>
      </Container>
      {hasDiseaseRiskOption(existence) && <DiseaseRisk />}
    </Stack>
  );
}
