import {
  createLabelContainedAnswer,
  createLabelContainedValue,
} from "@/components/examinee/interview/AnswerService";
import {
  FreeTextItem,
  getFreeTextItems,
  hasFreeText,
} from "@/components/examinee/interview/FreeTextChoiceService";
import {
  AnswerChangeHandler,
  Choice,
  Question,
  QuestionAnswer,
} from "@/components/examinee/interview/Types";
import { Stack, TextField, Typography } from "@mui/material";
import React from "react";

type FreeTextChoiceProps = {
  question: Question;
  questionAnswer: QuestionAnswer;
  onChange: AnswerChangeHandler;
};
export function FreeTextChoice({
  question,
  questionAnswer,
  onChange,
}: FreeTextChoiceProps) {
  // フリーテキストでは、選択肢がひとつしかないタイプ（selection_type が 1/1）のみ対応。
  const choice = question.choices[0];

  const answerText = getAnswerText(questionAnswer, choice);

  // フリーテキストが変更されたときの処理
  const onTextChange: TextChangeHandler = (
    itemIndex: number,
    value: string
  ) => {
    const ans = createLabelContainedAnswer(
      question,
      choice,
      itemIndex,
      value,
      answerText
    );

    onChange(ans);
  };

  // 身長[]cm, 体重[]kgのようなブロックに分ける。ブロックのセパレーターは2つ続きの改行
  const blockItems = choice.choice_label.split("\n\n");
  const answerItems = answerText.split("\n\n");

  // フリーテキスト入力フォームをカウントする
  let counter = 0;

  return (
    <Stack spacing={6}>
      {blockItems.map((block, i) => {
        const answerLines = answerItems[i].split("\n");
        return (
          <Stack key={i} spacing={1}>
            {/* ブロックを１行づつコンポーネントに置き換える */}
            {block.split("\n").map((line, j) => {
              if (hasFreeText(line)) {
                const freeTextItems = getFreeTextItems(
                  line,
                  answerLines[j],
                  counter
                );

                counter += freeTextItems.length;

                return (
                  <FreeTextComponent
                    key={j}
                    items={freeTextItems}
                    onChange={onTextChange}
                  />
                );
              } else {
                return (
                  <Typography key={j} variant="subtitle2">
                    {line}
                  </Typography>
                );
              }
            })}
          </Stack>
        );
      })}
    </Stack>
  );
}

type TextChangeHandler = (index: number, value: string) => void;

type FreeTextComponentProps = {
  items: FreeTextItem[];
  onChange: TextChangeHandler;
};
function FreeTextComponent({ items, onChange }: FreeTextComponentProps) {
  return (
    <Stack direction="row" alignItems="flex-end" spacing={2}>
      {items.map((item, i) => {
        const textFieldWidth = item.hasMessage ? "100px" : "100%";

        return (
          <React.Fragment key={i}>
            {item.leftMessage && (
              <Typography variant="subtitle2">{item.leftMessage}</Typography>
            )}
            <TextField
              size="small"
              sx={{
                width: textFieldWidth,
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  borderRadius: 2,
                },
              }}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => onChange(item.index, event.target.value)}
              value={item.value}
              placeholder={
                textFieldWidth === "100%" ? "詳細をご記入ください" : ""
              }
            />
            {item.rightMessage && (
              <Typography variant="subtitle2">{item.rightMessage}</Typography>
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );
}

function getAnswerText(questionAnswer: QuestionAnswer, choice: Choice): string {
  return (
    questionAnswer.answers[0]?.answer ??
    createLabelContainedValue(choice.choice_label, "")
  );
}
