/**
 * ユーザー名(氏名)ドメインモデル
 */
export class UserName {
  constructor(
    /** 名 */
    readonly firstName: string,
    /** 姓 */
    readonly lastName: string
  ) {
  }

  /**
   * ユーザーネームからフルネーム(文字列)を取得
   * @param separator 姓名を区切る文字列
   * @returns フルネーム
   */
  fullName(separator: string = " "): string {
    return `${this.lastName}${separator}${this.firstName}`;
  }
}
