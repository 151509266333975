import { Box, Paper, Typography } from "@mui/material";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useRef } from "react";

export type DetailAssessmentRadarChartProps = {
  /** レーダーチャートのタイトル */
  radarChartTitle: string;
  /** レーダーチャートに表示する項目 */
  radarChartItems: DetailAssessmentRadarChartItem[];
};
export function DetailAssessmentRadarChart({
  radarChartTitle,
  radarChartItems,
}: DetailAssessmentRadarChartProps) {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const options: Highcharts.Options = createHighchartsOption(radarChartItems);

  return (
    <Paper sx={{ boxShadow: "0px 0px 0px 1px #E0E0E0;", overflow: "hidden" }}>
      <Box
        sx={{
          bgcolor: "background.default",
          borderBottom: 1,
          borderColor: "black.12",
        }}
      >
        <Typography sx={{ p: 3 }} variant="subtitle2">
          {radarChartTitle}
        </Typography>
      </Box>
      <Box sx={{ height: "271px", overflow: "hidden" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
        />
      </Box>
    </Paper>
  );
}

export type DetailAssessmentRadarChartItem = {
  /** 各項目に表示するラベル */
  chartItemTitle: string;
  /** 各項目の値 (指定可能な範囲: 0 <= value <= 3) */
  chartItemValue: number;
};
/**
 * 睡眠評価をレーダーチャート(Highchartsのpolar)に表示するためのオプションを生成する
 * @see https://api.highcharts.com/highcharts/
 *
 * @param items レーダーチャートに表示する項目
 * @returns レーダーチャートに表示するデータや表示に関する設定値 {@link Highcharts.Options}
 */
function createHighchartsOption(
  items: DetailAssessmentRadarChartItem[]
): Highcharts.Options {
  return {
    chart: {
      polar: true,
    },
    credits: {
      // Highcharts.comの表記を非表示にするため、credits.enabledをfalseにする
      enabled: false,
    },
    legend: {
      // 凡例を非表示にするため、legend.enabledをfalseにする
      enabled: false,
    },
    pane: {
      // ラベルに星の数を表記できない分、レーダーチャートのサイズをデザインよりも大きくする
      // デザイン上のサイズ 152px の 120% で 182px
      size: "182px",
      // 五角形の中心がコンテナ(Box)の中心になるように値を設定
      // (垂直方向は五角形の下部に余白が多く取られるので、ペインの中心よりも高めに設定する)
      center: ["50%", "36%"],
    },
    plotOptions: {
      series: {
        marker: {
          radius: 2.5,
        },
      },
      area: {
        fillOpacity: 0.5,
      },
    },
    series: [
      {
        // 塗りつぶしの色
        color: "#0056A0",
        // 各項目の値(星の数)はdataにセットする
        data: items.map((i) => i.chartItemValue),
        // レーダーチャートをマウスでホバーしたときのアクションを無効化する
        enableMouseTracking: false,
        // チャートの最初の項目を0度の位置に配置するため、pointPlacementをonにする
        pointPlacement: "on",
        // レーダーチャートを塗りつぶすのでareaタイプ
        type: "area",
      },
    ],
    title: {
      // Highcharts標準のタイトルを非表示にするため、textをundefinedにする
      text: undefined,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      // 項目名はxAxis.categoriesにセットする
      categories: items.map((i) => i.chartItemTitle),
      gridLineColor: "#DBDBDB",
      labels: {
        useHTML: true,
        format: `<p style="color: #000; font-family: Inter; font-size: 10px; font-style: normal; font-weight: 400; line-height: normal;">{value}</p>`,
      },
      // チャート外側の丸枠を非表示にするため、lineWidthを0にする
      lineWidth: 0,
      // 軸を0度から並べるために、tickmarkPlacementをonにする
      tickmarkPlacement: "on",
    },
    yAxis: {
      gridLineColor: "#DBDBDB",
      // 目盛り線を多角形で表示するため、gridLineInterpolationをpolygonにする
      gridLineInterpolation: "polygon",
      labels: {
        enabled: false,
      },
      // 各項目の値(星の数)の範囲に合わせてmin, maxを設定する
      min: 0,
      max: 3,
      // 目盛りを1単位(星1つ単位)で表示するため、tickIntervalを1にする
      tickInterval: 1,
    },
  };
}
