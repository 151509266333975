import App from "@/App";
import "@/i18n/configs";
import "@/index.css";
import reportWebVitals from "@/reportWebVitals";
import React from "react";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
