import CloseIcon from "@mui/icons-material/Close";
import {
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";

/**
 * ポップアップのタイトル。DialogTitleに閉じるボタンが付いたもの。
 */
type SCDialogTitleProps = {
  children: ReactNode;
  onClickClose: () => void;
  noCloseButton?: boolean;
};
export function SCDialogTitle({
  children,
  onClickClose,
  noCloseButton = false,
}: SCDialogTitleProps) {
  return (
    <Stack>
      <DialogTitle>
        <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
          <Typography variant="h4" sx={{ width: "100%" }}>
            {children}
          </Typography>
          {!noCloseButton && (
            <IconButton
              sx={{ width: "24px", height: "24px" }}
              onClick={onClickClose}
            >
              <CloseIcon color="primary" />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      <Divider />
    </Stack>
  );
}
