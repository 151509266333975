import { LinearProgress, LinearProgressProps } from "@mui/material";

type ProgressBarProps = {
  sx?: LinearProgressProps["sx"];
  value: LinearProgressProps["value"];
};
export function ProgressBar({ sx, value }: ProgressBarProps) {
  return (
    <LinearProgress
      sx={{ height: "6px", borderRadius: "3px", ...sx }}
      color="primary"
      variant="determinate"
      value={value}
    />
  );
}
