import { examineeComponents, facilityComponents } from "@/theme/components";
import { palette } from "@/theme/palette";
import { examineeTypography, facilityTypography } from "@/theme/typography";
import { ThemeOptions, createTheme } from "@mui/material";

export const examineeTheme = createTheme({
  ...palette,
  ...examineeTypography,
  ...examineeComponents,
} as ThemeOptions);

export const facilityTheme = createTheme({
  ...palette,
  ...facilityTypography,
  ...facilityComponents,
} as ThemeOptions);
