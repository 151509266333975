// デバイスID設定ルール(デバイスID割り当てルール)  Version 1 に対応する正規表現
// ref. https://docs.google.com/spreadsheets/d/1EHNlIZVRrAa3iIlFy8Xm9FOeETCoSxbI/edit#gid=1027682449&range=A1
const deviceIdRegex = /^[A-Z]{2}[0-9][A-Z][0-9]{4}$/;
// デバイスID設定ルール Version 2 に対応する正規表現
// ref. https://docs.google.com/spreadsheets/d/161aOJvolkgjAwruj4ss2vOUCh-CEh5RwpRxa4V0-rec/edit#gid=630397657&range=A1
const deviceIdRegexV2 = /^[A-Z][0-9][0-9][A-Z][0-9A-F]{4}$/;

const invalidCharRegex = /[^A-Z0-9]/;

export function deviceIdValidator(deviceId: string): string[] | null {
  // 形式を満たしていたらエラーなし
  if (deviceIdRegex.test(deviceId) || deviceIdRegexV2.test(deviceId)) {
    return null;
  }

  const errorMessages: string[] = [];

  if (deviceId.length !== 8) {
    errorMessages.push("※8文字で入力してください。");
  }

  if (invalidCharRegex.test(deviceId)) {
    errorMessages.push("※半角英数字で入力してください。");
  }

  // 有効な文字種のみが使用されているが形式が不正な場合
  if (errorMessages.length === 0) {
    errorMessages.push("※デバイスIDが間違っています。");
  }

  return errorMessages;
}
