import { ReactNode } from "react";
import { hasDiseaseRiskOption } from "../disease_risk/commons";
import ComprehensiveEvaluation from "./ComprehensiveEvaluation";
import DaytimeResult from "./DaytimeResult";
import DiseaseRiskResult from "./DiseaseRiskResult";
import EndOfSummary from "./EndOfSummary";
import PointsOfImprovement from "./PointsOfImprovement";
import SleepAndDaytimeRecords from "./SleepAndDaytimeRecords";
import SleepQuality from "./SleepQuality";
import SleepQuantity from "./SleepQuantity";
import SleepRhythm from "./SleepRhythm";
import { SummaryProps } from "./Summary";
import {
  getDetailsOfMeasurementResultsCount,
  hasPointsOfImprovement,
} from "./commons";

export type ScreenType =
  | "comprehensive_evaluation" // 総合評価
  | "sleep_and_daytime_record" // 睡眠と日中の記録
  | "sleep_quantity" // 測定結果の詳細(1/n)
  | "sleep_quality" // 測定結果の詳細(2/n)
  | "points_of_improvement" // 睡眠の質の改善ポイント (改善のポイントがない場合もある)
  | "sleep_rhythm" // 測定結果の詳細(3/n)
  | "daytime_result" // 測定結果の詳細(4/n)
  | "disease_risk" // 測定結果の詳細(5/n)
  | "end_of_summary"; // おわりに

/**
 * サマリの初期表示の{@link ScreenType}
 */
export const INITIAL_DISPLAY_SCREEN_TYPE = "comprehensive_evaluation";

type TransitionConfig = {
  title: (props: SummaryProps) => string;
  component: ReactNode;
  progress: number;
  prev?: (props: SummaryProps) => ScreenType;
};

export const SUMMARY_TRANSITION_CONFIGS: Readonly<
  Record<ScreenType, TransitionConfig>
> = {
  comprehensive_evaluation: {
    title: () => "総合評価",
    component: <ComprehensiveEvaluation />,
    progress: 10,
  },
  sleep_and_daytime_record: {
    title: () => "睡眠と日中の記録",
    component: <SleepAndDaytimeRecords />,
    progress: 20,
    prev: () => "comprehensive_evaluation",
  },
  sleep_quantity: {
    title: (props) =>
      `測定結果の詳細(1/${getDetailsOfMeasurementResultsCount(props)})`,
    component: <SleepQuantity />,
    progress: 30,
    prev: () => "sleep_and_daytime_record",
  },
  sleep_quality: {
    title: (props) =>
      `測定結果の詳細(2/${getDetailsOfMeasurementResultsCount(props)})`,
    component: <SleepQuality />,
    progress: 40,
    prev: () => "sleep_quantity",
  },
  points_of_improvement: {
    title: () => "睡眠の質の改善ポイント",
    component: <PointsOfImprovement />,
    progress: 50,
    prev: () => "sleep_quality",
  },
  sleep_rhythm: {
    title: (props) =>
      `測定結果の詳細(3/${getDetailsOfMeasurementResultsCount(props)})`,
    component: <SleepRhythm />,
    progress: 60,
    prev: ({ data }) =>
      hasPointsOfImprovement(data.points_of_improvement) // 「睡眠の質の改善ポイント」が存在する場合、「睡眠の質の改善ポイント」へ遷移、ない場合は「測定結果の詳細(2/4)」へ遷移する
        ? "points_of_improvement"
        : "sleep_quality",
  },
  daytime_result: {
    title: (props) =>
      `測定結果の詳細(4/${getDetailsOfMeasurementResultsCount(props)})`,
    component: <DaytimeResult />,
    progress: 70,
    prev: () => "sleep_rhythm",
  },
  disease_risk: {
    title: (props) =>
      `測定結果の詳細(5/${getDetailsOfMeasurementResultsCount(props)})`,
    component: <DiseaseRiskResult />,
    progress: 90,
    prev: () => "daytime_result",
  },
  end_of_summary: {
    title: () => "おわりに",
    component: <EndOfSummary />,
    progress: 100,
    prev: ({ existence }) =>
      hasDiseaseRiskOption(existence) ? "disease_risk" : "daytime_result",
  },
} as const;
