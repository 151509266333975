export interface ElearningChapter {
  readonly image: string;
  readonly title: string;
  readonly to: string;
}
export const BASIC_KNOWLEDGE_CHAPTERS: Readonly<ElearningChapter[]> = [
  {
    image: "/img/icon_chapter01.png",
    title: "睡眠・覚醒リズムとは",
    to: "/elearning/knowledge_and_tips/chapter01",
  },
  {
    image: "/img/icon_chapter02.png",
    title: "体内時計について知ろう",
    to: "/elearning/knowledge_and_tips/chapter02",
  },
  {
    image: "/img/icon_chapter03.png",
    title: "セロトニンとメラトニン",
    to: "/elearning/knowledge_and_tips/chapter03",
  },
];

export const CIRCADIAN_RHYTHM_TIPS: Readonly<ElearningChapter[]> = [
  {
    image: "/img/icon_chapter04.png",
    title: "しっかりと目覚めるためのポイント",
    to: "/elearning/knowledge_and_tips/chapter04",
  },
  {
    image: "/img/icon_chapter05.png",
    title: "スムーズに入眠しやすくするポイント",
    to: "/elearning/knowledge_and_tips/chapter05",
  },
];

export class ChapterListItem {
  readonly title: string | null;
  readonly chapters: Readonly<ElearningChapter[]>;

  constructor(title: string | null, chapters: Readonly<ElearningChapter[]>) {
    this.title = title;
    this.chapters = chapters;
  }
}

export const ITEMS_WITH_TITLE: ChapterListItem[] = [
  new ChapterListItem("睡眠の基本情報", BASIC_KNOWLEDGE_CHAPTERS),
  new ChapterListItem("体内時計を調整するTips", CIRCADIAN_RHYTHM_TIPS),
];
