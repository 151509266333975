export function getElearningApiUrl(queryString: string | null): string | null {
  if (queryString == null) {
    return null;
  }

  const query = new URLSearchParams(queryString);

  const sleepCheckUpId: string | null = query.get("id");
  if (sleepCheckUpId == null) {
    return null;
  }

  return `/api/sleep-checkup/${sleepCheckUpId}/elearning/`;
}

export function getNextPath(
  screenId: number | null,
  queryString: string
): string {
  const quizPagePath = "/elearning/quiz";

  const query = new URLSearchParams(queryString);
  query.set("screen", String(screenId));

  return `${quizPagePath}?${query.toString()}`;
}

export function getResultPagePath(queryString: string): string {
  const quizResultPath = "/elearning/quiz_result";

  const query = new URLSearchParams(queryString);
  const id = query.get("id");
  if (id == null || id === undefined) {
    return quizResultPath;
  }

  const quizResultQuery = new URLSearchParams();
  quizResultQuery.set("id", id);

  return `${quizResultPath}?${quizResultQuery.toString()}`;
}

export function getQuizScreenApiUrl(path: string): string {
  return `${path}`;
}
