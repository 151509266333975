import ForgotPassword from "@/components/auth/FacilityForgotPassword";
import { SCAppBar } from "@/components/sleep_checkup_v1/SCAppBar";
import { facilityTheme } from "@/theme/themeV1";
import { getAxios, getFacilityApiServerUrl } from "@/utils/axios";
import constants from "@/utils/constants";
import { ThemeProvider } from "@mui/material";

function FacilityForgotPassword() {
  return (
    <ThemeProvider theme={facilityTheme}>
      <SCAppBar minWidth={1098} />
      <ForgotPassword
        axios={getAxios(getFacilityApiServerUrl())}
        signInTo={constants.SIGNIN_URL_MEDICAL_FACILITY_USER}
      />
    </ThemeProvider>
  );
}

export default FacilityForgotPassword;
