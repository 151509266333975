/**
 * searchParamsTextのbuilder用のクロージャー
 */
export type TextBuilder = (key: string, label: string, value: any) => string;

/**
 * paramsにセットされた値を文字列で返す
 * @param params 対象のオブジェクト
 * @param table パラメータをテキストに変換するテーブル
 * @param separator パラメータ間のセパレーター
 * @param builder text作成用のクロージャー
 * @returns paramsにセットされた値を表す文字列
 */
export function searchParamsText(
  params: any,
  table: Record<string, string>,
  separator: string,
  builder: TextBuilder = (_, label, value) => `${label}:${value}`
): string {
  let text = "";
  for (const [key, label] of Object.entries(table)) {
    const value = params[key];
    if (value == null) {
      continue;
    }

    const s = text === "" ? "" : separator;
    const currentText = builder(key, label, value);
    text += `${s}${currentText}`;
  }
  return text;
}

/**
 * maxLen以内に丸めたテキストを返す
 * @param text 丸める対象のテキスト
 * @param maxLen 丸めるテキストの最大文字数
 * @param omitter 丸めに用いる文字列
 * @param separator テキストに用いられているセパレーター
 * @returns 丸められたテキスト
 */
export function omitTextIfNeeded(
  text: string,
  maxLen: number,
  omitter: string,
  separator: string
): string {
  if (text.length <= maxLen) {
    return text;
  }

  const maxLenText = text.slice(0, maxLen - omitter.length);
  const p = maxLenText.lastIndexOf(separator);
  if (p === -1) {
    return `${maxLenText.slice(0, maxLen - 1)}${omitter}`;
  } else {
    return `${maxLenText.slice(0, p + 1)}${omitter}`;
  }
}
