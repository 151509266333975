import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

type GeneralErrorProps = {
  to: string;
  buttonText?: string;
};
export const GeneralError = ({ to, buttonText }: GeneralErrorProps) => {
  return (
    <Stack spacing={8} sx={{ mt: 8, mb: 12, mx: 4 }}>
      <Stack spacing={6}>
        <img
          src="/img/error_icon.svg"
          alt="エラー"
          loading="lazy"
          style={{ height: 50 }}
        />
        <Typography variant="h6" align="center">
          エラーが発生しました
        </Typography>
      </Stack>
      <Stack>
        <Typography
          variant="subtitle2"
          align="center"
          sx={{ color: "text.secondary" }}
        >
          何らかのエラーが発生しました
        </Typography>
        <Typography
          variant="subtitle2"
          align="center"
          sx={{ color: "text.secondary" }}
        >
          もう一度最初からやり直してください
        </Typography>
      </Stack>
      {to && buttonText && (
        <Button
          variant="contained"
          size="large"
          sx={{ mx: 4, mb: 16 }}
          component={Link}
          to={to}
        >
          {buttonText}
        </Button>
      )}
    </Stack>
  );
};
