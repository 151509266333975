import { LineBreakText } from "@/components/common/LineBreakText";
import {
  createRadioAnswer,
  validateForAnswers,
} from "@/components/examinee/interview/AnswerService";
import { AppendableNumberSelectChoice } from "@/components/examinee/interview/AppendableNumberSelectChoice";
import { CheckboxChoice } from "@/components/examinee/interview/CheckboxChoice";
import {
  FREE_TEXT_CHOICE_DEFAULT_VALUE,
  SELECT_CHOICE_DEFAULT_VALUE,
} from "@/components/examinee/interview/Constants";
import { FreeTextChoice } from "@/components/examinee/interview/FreeTextChoice";
import { NumberSelectChoice } from "@/components/examinee/interview/NumberSelectChoice";
import { SingleQuestionGroup } from "@/components/examinee/interview/SingleQuestionGroup";
import {
  AnswerChangeHandler,
  Choice,
  Question,
  QuestionAnswer,
  QuestionGroup,
  QuestionGroupAnswer,
} from "@/components/examinee/interview/Types";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  ButtonProps,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { Property } from "csstype";
import { ReactNode, useEffect, useState } from "react";

type SingleQuestionGroupPageProps = {
  /** 問診の進行度 */
  progress: ReactNode;
  /** 質問の回答(e.g. 選択肢)部の背景色 */
  backgroundColor: Property.BackgroundColor;
  /** 質問グループ */
  questionGroup: QuestionGroup;
  /** 回答 */
  questionGroupAnswer: QuestionGroupAnswer;
  /** 直前の質問グループ */
  prevQuestionGroup?: QuestionGroup;
  /** 質問文の親コンテナ(Stack)に適応するスタイル */
  questionStackSx?: StackProps["sx"];
  /** 質問の回答部の親コンテナ(Stack)に適応するスタイル */
  stackSx?: StackProps["sx"];
  /** 回答入力イベントのハンドラー。回答の複数選択が可能な質問で呼ばれる。 */
  onChange: AnswerChangeHandler;
  /** 次の質問への遷移イベントのハンドラー */
  onNext: () => void;
  /** 回答選択イベントのハンドラー。回答をひとつしか選択できない質問で呼ばれる。onChangeと別れている理由は、それぞれのイベントで、回答の保持方法が違うことから、別れている方が実装しやすかったため。 */
  onSelect: AnswerChangeHandler;
};
/**
 * 1画面1問の形式の質問のベースコンポーネント
 */
export function SingleQuestionGroupPage({
  progress,
  backgroundColor,
  questionGroup,
  questionGroupAnswer,
  prevQuestionGroup,
  questionStackSx = { mx: 4, my: 6 },
  stackSx = { mx: 4, my: 6 },
  onChange,
  onNext,
  onSelect,
}: SingleQuestionGroupPageProps) {
  const singleQuestionGroup = new SingleQuestionGroup(
    questionGroup,
    prevQuestionGroup
  );

  // 前処理でQuestionに対応するQuestionAnswerが作成されているので、findでundefinedになることはない
  const questionAnswer: QuestionAnswer = questionGroupAnswer.questions.find(
    (a: QuestionAnswer) =>
      a.question_code === singleQuestionGroup.question.question_code
  )!;

  const type = singleQuestionGroup.type;
  const leadText = singleQuestionGroup.leadText;
  const detailText = singleQuestionGroup.detail;

  return (
    <Stack>
      <Box sx={{ backgroundColor: "white" }}>
        <Stack spacing={6} sx={questionStackSx}>
          {progress}
          <Stack spacing={4}>
            {leadText && (
              <Typography variant="body2" textAlign="justify">
                {leadText}
              </Typography>
            )}
            <Stack spacing={2}>
              <Typography variant="subtitle1">
                {singleQuestionGroup.text}
              </Typography>
              {detailText && (
                <Typography variant="caption">
                  <LineBreakText text={detailText} />
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ backgroundColor: backgroundColor }}>
        {type === "FreeText" && (
          <FreeTextForm
            stackSx={stackSx}
            question={singleQuestionGroup.question}
            questionAnswer={questionAnswer}
            onChange={onChange}
            onNext={onNext}
          />
        )}
        {type === "NumberChoice" && (
          <NumberChoiceForm
            stackSx={stackSx}
            question={singleQuestionGroup.question}
            questionAnswer={questionAnswer}
            validateInput={true}
            onChange={onChange}
            onNext={onNext}
          />
        )}
        {type === "AppendableNumberChoice" && (
          <AppendableNumberChoiceForm
            stackSx={stackSx}
            question={singleQuestionGroup.question}
            questionAnswer={questionAnswer}
            onChange={onChange}
            onNext={onNext}
          />
        )}
        {type === "SelectOne" && (
          <SelectOneForm
            stackSx={stackSx}
            question={singleQuestionGroup.question}
            questionAnswer={questionAnswer}
            onSelect={onSelect}
          />
        )}
        {type === "SelectMany" && (
          <SelectManyForm
            stackSx={stackSx}
            question={singleQuestionGroup.question}
            questionAnswer={questionAnswer}
            onChange={onChange}
            onNext={onNext}
          />
        )}
        {type === "SkippableNumberChoice" && (
          <NumberChoiceForm
            stackSx={stackSx}
            question={singleQuestionGroup.question}
            questionAnswer={questionAnswer}
            validateInput={false}
            onChange={onChange}
            onNext={onNext}
          />
        )}
      </Box>
    </Stack>
  );
}

type FreeTextFormProps = {
  /** コンテナに適用されるスタイル */
  stackSx: StackProps["sx"];
  /** 質問 */
  question: Question;
  /** 回答 */
  questionAnswer: QuestionAnswer;
  /** 回答入力イベントのハンドラー */
  onChange: AnswerChangeHandler;
  /** 次の質問への遷移イベントのハンドラー */
  onNext: () => void;
};
/**
 * フリーテキストで回答する質問のための回答フォーム
 * 身長/体重の質問で使用
 */
function FreeTextForm({
  stackSx,
  question,
  questionAnswer,
  onChange,
  onNext,
}: FreeTextFormProps) {
  return (
    <Stack spacing={12} sx={stackSx}>
      <FreeTextChoice
        question={question}
        questionAnswer={questionAnswer}
        onChange={onChange}
      />
      <Button
        variant="contained"
        size="large"
        disabled={
          !validateForAnswers(
            questionAnswer.answers,
            question.choices,
            FREE_TEXT_CHOICE_DEFAULT_VALUE
          )
        }
        onClick={onNext}
      >
        次へ
      </Button>
    </Stack>
  );
}

type NumberChoiceFormProps = {
  /** コンテナに適用されるスタイル */
  stackSx: StackProps["sx"];
  /** 質問 */
  question: Question;
  /** 回答 */
  questionAnswer: QuestionAnswer;
  /** 回答をバリデーションするか否か */
  validateInput: boolean;
  /** 回答入力イベントのハンドラー */
  onChange: AnswerChangeHandler;
  /** 次の質問への遷移イベントのハンドラー */
  onNext: () => void;
};
/**
 * 数値選択ドロップダウンの問診回答フォーム
 * 以下2つのSingleQuestionGroupTypeに適用される
 * 1. NumberChoice
 * 2. SkippableNumberChoice
 * 違いは「次へ」ボタンの有効/無効判定
 * 前者は全ての数値選択ドロップダウンに回答が入力されないと有効にならない。対して、後者は回答が未入力でも有効。
 * コード上での違いは、回答をバリデーション(validateForAnswers)するか否か
 * また、コンポーネントを使う側はvalidateInputプロパティでバリデーションするか否かを選択する必要がある
 * - validateInput = true / NumberChoice
 * - validateInput = false / SkippableNumberChoice
 */
function NumberChoiceForm({
  stackSx,
  question,
  questionAnswer,
  validateInput,
  onChange,
  onNext,
}: NumberChoiceFormProps) {
  const buttonDisabled = validateInput
    ? !validateForAnswers(
        questionAnswer.answers,
        question.choices,
        SELECT_CHOICE_DEFAULT_VALUE
      )
    : false;

  return (
    <Stack spacing={12} sx={stackSx}>
      <NumberSelectChoice
        question={question}
        noSelect={SELECT_CHOICE_DEFAULT_VALUE}
        questionAnswer={questionAnswer}
        onChange={onChange}
      />
      <Button
        variant="contained"
        size="large"
        disabled={buttonDisabled}
        onClick={onNext}
      >
        次へ
      </Button>
    </Stack>
  );
}

type AppendableNumberChoiceFormProps = {
  /** コンテナに適用されるスタイル */
  stackSx: StackProps["sx"];
  /** 質問 */
  question: Question;
  /** 回答 */
  questionAnswer: QuestionAnswer;
  /** 回答入力イベントのハンドラー */
  onChange: AnswerChangeHandler;
  /** 次の質問への遷移イベントのハンドラー */
  onNext: () => void;
};
/**
 * 数値選択、かつ、任意の数の回答が可能な質問のための回答フォーム
 * 昼寝の質問などで使用
 */
function AppendableNumberChoiceForm({
  stackSx,
  question,
  questionAnswer,
  onChange,
  onNext,
}: AppendableNumberChoiceFormProps) {
  return (
    <Stack spacing={12} sx={stackSx}>
      <AppendableNumberSelectChoice
        question={question}
        noSelect={SELECT_CHOICE_DEFAULT_VALUE}
        questionAnswer={questionAnswer}
        onChange={onChange}
      />
      <Button
        variant="contained"
        size="large"
        disabled={
          !validateForAnswers(
            questionAnswer.answers,
            question.choices,
            SELECT_CHOICE_DEFAULT_VALUE
          )
        }
        onClick={onNext}
      >
        次へ
      </Button>
    </Stack>
  );
}

type SelectOneFormProps = {
  /** コンテナに適用されるスタイル */
  stackSx: StackProps["sx"];
  /** 質問 */
  question: Question;
  /** 回答 */
  questionAnswer: QuestionAnswer;
  /** 回答選択イベントのハンドラー */
  onSelect: AnswerChangeHandler;
};
/**
 * 選択肢からひとつの回答を選ぶ質問のための回答フォーム
 * 性別の質問など色々な質問で使用
 */
function SelectOneForm({
  stackSx,
  question,
  questionAnswer,
  onSelect,
}: SelectOneFormProps) {
  const [answer, setAnswer] = useState<QuestionAnswer>(questionAnswer);

  // Note: 質問が切り替わったとき（プロパティのquestion, questionAnswerが変わる）、直前の質問への回答を破棄する
  useEffect(() => {
    setAnswer(questionAnswer);
  }, [questionAnswer]);

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    question: Question,
    choice: Choice
  ) => {
    if (answer?.answers[0] != null) {
      // 回答選択済みのときはリターンする。重複して回答を送信しないように。
      return;
    }

    const questionAnswer = createRadioAnswer(question, choice);
    setAnswer(questionAnswer);
    // 画面遷移するまでに、少し時間を置く。回答選択時にボタンにチェックマークが付くことをユーザーに認識してもらうため。画面遷移が早すぎると、ユーザーが認識できないかもしれないので。
    setTimeout(() => onSelect(questionAnswer), 400);
  };

  const a = answer?.answers[0];

  return (
    <Stack spacing={4} sx={stackSx}>
      {question.choices.map((c, i) => (
        <ChoiceButton
          key={`${question.id}-${c.id}`}
          selected={a?.id === c.id}
          onClick={(event) => onClick(event, question, c)}
        >
          {c.choice_label}
        </ChoiceButton>
      ))}
    </Stack>
  );
}

type ChoiceButtonProps = {
  /** 子要素。選択肢の本文が指定されることを想定 */
  children: ReactNode;
  /** 選択状態 */
  selected: boolean;
  /** ボタンクリックイベントのハンドラー */
  onClick?: ButtonProps["onClick"];
};
/**
 * 単一選択用の回答ボタン
 */
function ChoiceButton({ children, selected, onClick }: ChoiceButtonProps) {
  const iconColor = selected ? undefined : "white";
  const buttonColor = selected ? "primary.main" : undefined;

  return (
    <Button
      startIcon={<CheckIcon sx={{ color: iconColor, width: 20, height: 20 }} />}
      variant="contained"
      size="medium"
      color="whiteButton"
      // prは、回答ボタンに表示するテキストを、ボタン中央に調整するためのpadding
      sx={{ pr: 12, color: buttonColor }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

type SelectManyFormProps = {
  stackSx: StackProps["sx"];
  question: Question;
  questionAnswer: QuestionAnswer;
  onChange: AnswerChangeHandler;
  onNext: () => void;
};
/**
 * 選択肢から複数の回答を選ぶ質問のための回答フォーム
 * 睡眠の状態についての質問などで使用
 */
function SelectManyForm({
  stackSx,
  question,
  questionAnswer,
  onChange,
  onNext,
}: SelectManyFormProps) {
  return (
    <Stack spacing={12} sx={stackSx}>
      <CheckboxChoice
        question={question}
        questionAnswer={questionAnswer}
        onChange={onChange}
      />
      <Button
        variant="contained"
        size="large"
        disabled={
          !validateForAnswers(
            questionAnswer.answers,
            question.choices,
            FREE_TEXT_CHOICE_DEFAULT_VALUE
          )
        }
        onClick={onNext}
      >
        次へ
      </Button>
    </Stack>
  );
}
